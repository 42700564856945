import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Constants from "../../Constants";
import MainModal from "../MainModal";
import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import Dropzone from "react-dropzone-uploader";
import {
  onInputResetAction,
  attendanceActionModalFailed,
  toggleAttendanceActionModal,
  normalRequestActionAttempt,
  unpaidRequestActionAttempt,
  emergencyRequestActionAttempt,
  permissionRequestActionAttempt,
  halfdayRequestActionAttempt,
  homeExchangeRequestActionAttempt,
  homeFlexRequestActionAttempt,
  upsertClaimsRequestAction,
  onInputChangeAction,
  upsertSickRequestAction,
  onInputResetWithValueAction,
  upsertCustomRequestAction,
  showErrorToast,
  showSuccessToast,
} from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import * as REQUESTS from "../../Constants/Requests";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import {
  applicableWorktimingsQuery,
  employeeBalanceQuery,
  employeeClaimsQuery,
  employeeProfileQuery,
  employeeRequestsQuery,
  getEmployeeProfile,
  myBalanceQuery,
  myClaimsQuery,
  myProfileQuery,
  myRequestsQuery,
  relevantAssignmentsQuery,
} from "../../Graphql/query";
import {
  ignoreExceptionMutation,
  unpaidHalfDayMutation,
} from "../../Graphql/mutation";
import swal from "sweetalert";
import { RadioboxForm } from "../../Builder/Form";

const formName = "attendanceActionModal";
const formNameValidation = "attendanceActionModalValidation";
const userFormServerValidation = "requestFormServerValidation";

const FormProps = {
  formName,
  formNameValidation,
};

const handleDateTimePickerForm = (
  isSubmiting,
  name,
  placeholder,
  type,
  requestFormat = "YYYY-MM-DD",
  label
) => (
  <DateTimePickerForm
    {...FormProps}
    name={name}
    placeholder={placeholder}
    validateBy="textRequired"
    formSubmitting={isSubmiting}
    containerStyle="my-1"
    inputContainerStyle=" "
    {...{ [type]: true }}
    reducer="user"
    validationName={`input.${name}`}
    formServerValidation={userFormServerValidation}
    rootStyle="w-100"
    requestFormat={requestFormat}
    {...(label && { label })}
  />
);

const AttendanceActionModal = ({ isEmployee, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [generalExceptionMessage, setGeneralExceptionMessage] = useState("");

  const {
    action_type,
    from,
    to,
    assignment_id,
    half_day_will_attend,
    requestFrom,
    requestTo,
  } = useSelector((state) => state.user.attendanceActionModal);

  const selectedProfile = useSelector(
    (state) => state?.super?.selectedEmployeeProfile
  );

  const userProfile = useSelector((state) => state.user.userProfile);

  const employeeProfile = isEmployee ? userProfile : selectedProfile;

  const employeeId = employeeProfile?.id;

  const [
    fetchEmployeeAttendanceProfile,
    { data, loading: attendanceProfileLoading },
  ] = useLazyQuery(getEmployeeProfile, {
    variables: {
      employeeID: employeeId,
      date: requestFrom,
    },
    onError: (error) => {
      dispatch(showErrorToast(error?.message));
    },
  });

  const employeeAttendanceProfile = data?.getEmployeeProfile ?? {};

  const employeeRelevantAttendanceTypeConfiguration =
    data?.employee?.relevantAttendanceTypeConfiguration;

  const employeeProbationEndDate =
    data?.employee?.probationPeriod?.end_of_probation;

  const [applicableWorkTimings, setApplicableWorkTimings] = useState([]);
  const [requestable, setRequestable] = useState(null);

  const myRequestsList = useSelector((state) => state.user.myRequestsList);
  const myBalanceList = useSelector((state) => state.user.myBalanceList);
  const myClaimsList = useSelector((state) => state.user.myClaimsList);

  const employeeRequestsList = useSelector(
    (state) => state.super.employeeRequestsList
  );
  const employeeBalanceList = useSelector(
    (state) => state.super.employeeBalanceList
  );
  const employeeClaimsList = useSelector(
    (state) => state.super.employeeProfileClaimsList
  );

  useEffect(() => {
    setGeneralExceptionMessage("");

    if (!requestTo) {
      dispatch(onInputResetWithValueAction(formName, "requestTo", requestFrom));
    }

    if (action_type === REQUESTS.IGNORE_ASSIGNMENT && requestFrom) {
      setAssignmentsOptions([]);
      fetchRelevantAssignments();
    }
  }, [requestFrom]);

  useEffect(() => {
    setGeneralExceptionMessage("");

    if (!to) {
      dispatch(onInputResetWithValueAction(formName, "to", from));
    }
  }, [from]);

  const [assignmentsOptions, setAssignmentsOptions] = useState([]);
  const [
    ignoreAssignmentExceptionMessage,
    setIgnoreAssignmentExceptionMessage,
  ] = useState("");

  const [isSubmiting, setIsSubmiting] = useState(false);

  const [requestFiles, setRequestFiles] = useState([]);

  const serializedAttachemnts = requestFiles?.map(({ file, extension }) => ({
    file,
    extension,
  }));

  const isModalVissible = props.modalActions.isVissible;
  const modalMessage = props.modalActions.modalMessage;
  const isLoading = props.modalActions.isLoading;

  const employeeRefetchQueries = [
    {
      query: myProfileQuery,
    },
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: myRequestsQuery,
            variables: {
              rows: 10,
              page: 1,
              field: myRequestsList.sorting.key,
              order: myRequestsList.sorting.dir.toUpperCase(),
              status: myRequestsList.statusFilter,
              month: myRequestsList.monthFilter
                ? myRequestsList.monthFilter
                : null,
              year: myRequestsList.yearFilter
                ? myRequestsList.yearFilter
                : null,
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_LEAVE_BALANCE_HISTORY],
      allowBP: true,
    })
      ? [
          {
            query: myBalanceQuery,
            variables: {
              rows: 10,
              page: 1,
              field: myBalanceList.sorting.key,
              order: myBalanceList.sorting.dir.toUpperCase(),
              type: myBalanceList.type,
              year: myBalanceList.yearFilter,
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.APPLY_FOR_CLAIM_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: myClaimsQuery,
            variables: {
              claimRows: 10,
              claimPage: 1,
              claimStatus: myClaimsList.status,
              claimMonth: myClaimsList.month ? myClaimsList.month : null,
              claimYear: myClaimsList.year ? myClaimsList.year : null,
            },
          },
        ]
      : []),
  ];

  const hasActiveSubscription = useSelector(
    (state) => state?.auth?.userProfile?.company?.hasActiveSubscription
  );

  const managerRefetchQueries = [
    {
      query: employeeProfileQuery,
      variables: {
        id: employeeId,
        isExpired: !hasActiveSubscription,
      },
    },
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: employeeRequestsQuery,
            variables: {
              id: employeeId,
              rows: 10,
              page: 1,
              field: employeeRequestsList.sorting.key,
              order: employeeRequestsList.sorting.dir.toUpperCase(),
              status: employeeRequestsList.statusFilter,
              month: employeeRequestsList.monthFilter
                ? employeeRequestsList.monthFilter
                : null,
              year: employeeRequestsList.yearFilter,
              route: "employee_profile",
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT],
      allowBP: true,
    })
      ? [
          {
            query: employeeBalanceQuery,
            variables: {
              id: employeeId,
              rows: 10,
              page: 1,
              field: employeeBalanceList?.sorting.key,
              order: employeeBalanceList?.sorting.dir.toUpperCase(),
              type: employeeBalanceList?.type,
              year: employeeBalanceList?.yearFilter,
            },
          },
        ]
      : []),

    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_CLAIMS],
      allowBP: true,
    })
      ? [
          {
            query: employeeClaimsQuery,
            variables: {
              claimRows: 10,
              claimPage: 1,
              id: employeeId,
              claimStatus: employeeClaimsList?.status,
              claimMonth: employeeClaimsList?.month
                ? employeeClaimsList?.month
                : null,
              claimYear: employeeClaimsList?.year,
            },
          },
        ]
      : []),
  ];

  const [
    fetchRelevantAssignments,
    { loading: fetchRelevantAssignmentsLoading },
  ] = useLazyQuery(relevantAssignmentsQuery, {
    variables: {
      input: {
        from: moment(requestFrom).format("YYYY-MM-DD"),
        to: moment(requestFrom).format("YYYY-MM-DD"),
        employee_id: employeeId,
      },
    },
    onCompleted: (data) => {
      setAssignmentsOptions(data?.relevantAssignments?.data ?? []);
    },
  });

  const [
    fetchApplicableWorkTimings,
    { loading: fetchApplicableWorkTimingsLoading },
  ] = useLazyQuery(applicableWorktimingsQuery);

  const handleRefetchingIncaseOfSuccess = () => {
    dispatch(
      onInputResetWithValueAction("attendanceModalActions", "isVissible", false)
    );
    dispatch(showSuccessToast());
  };

  const [
    ignoreException,
    { loading: ignoreExceptionLoading, error: ignoreExceptionError },
  ] = useMutation(ignoreExceptionMutation, {
    errorPolicy: "all",
    variables: {
      input: {
        assignment_id:
          assignmentsOptions?.length === 1
            ? assignmentsOptions[0]?.id
            : assignment_id,
        date: moment(requestFrom).format("YYYY-MM-DD"),
        employee_id: employeeId,
        files: serializedAttachemnts,
      },
    },
    onCompleted: (data) => {
      if (data) {
        if (data?.ignoreException?.__typename === "GeneralException") {
          return setIgnoreAssignmentExceptionMessage(
            data?.ignoreException?.message ?? ""
          );
        }
        if (data?.ignoreException?.__typename === "PaidPayrollException") {
          return setIgnoreAssignmentExceptionMessage(
            data?.ignoreException?.message ?? ""
          );
        }
        handleRefetchingIncaseOfSuccess();
      }
    },
    refetchQueries: isEmployee ? employeeRefetchQueries : managerRefetchQueries,
    onError: (error) => {
      dispatch(showErrorToast(error?.message));
    },
  });

  useEffect(() => {
    if (ignoreExceptionError) {
      dispatch(
        showErrorToast(
          ignoreExceptionError?.graphQLErrors?.[0]?.message ||
            ignoreExceptionError?.message
        )
      );
    }
  }, [ignoreExceptionError]);

  const [submitUnpaidHalfDay, { loading: unpaidHalfDayLoading }] = useMutation(
    unpaidHalfDayMutation,
    {
      onCompleted: (data) => {
        if (
          data?.unpaidHalfDay?.__typename === "GeneralException" ||
          data?.unpaidHalfDay?.__typename === "PaidPayrollException"
        ) {
          return setGeneralExceptionMessage(data?.unpaidHalfDay?.message ?? "");
        }

        if (data?.unpaidHalfDay) {
          handleRefetchingIncaseOfSuccess();
        } else {
          dispatch(showErrorToast());
        }
      },
      onError: (error) => {
        dispatch(showErrorToast(error?.graphQLErrors[0]));
      },
      refetchQueries: isEmployee
        ? employeeRefetchQueries
        : managerRefetchQueries,
    }
  );

  const handleIgnoreException = () => {
    if (fetchRelevantAssignmentsLoading) return;
    if (assignmentsOptions?.length === 0) {
      setIgnoreAssignmentExceptionMessage(
        t("you have no assignments at this date")
      );
      return;
    }
    ignoreException();
  };

  const canRequestLeaves = () => {
    if (
      (isEmployee &&
        HelperFns.checkPrivileges({
          privileges: [Privilages.APPLY_FOR_LEAVE_REQUESTS],
        })) ||
      !isEmployee
    ) {
      if (
        !employeeProfile?.employee?.on_probation ||
        (employeeProfile?.employee?.on_probation &&
          (employeeAttendanceProfile?.permission_leaves_break_setting
            ?.probationPeriodConfiguration?.request_leaves ||
            moment(requestFrom).diff(moment(employeeProbationEndDate), "days") >
              0))
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    setRequestable(null);
    props.onInputResetAction(formName, "from");
    props.onInputResetAction(formName, "to");
    props.onInputResetAction(formName, "deducted_from_emergency");
    props.onInputResetAction(formName, "half_day_will_attend");
    props.onInputResetAction(formName, "home_day");
    props.onInputResetAction(formName, "amount");
    props.onInputResetAction(formName, "reason");
    props.onInputResetAction(formName, "assignment_id");
    setIgnoreAssignmentExceptionMessage("");
    props.attendanceActionModalFailed("");
    setRequestFiles([]);
    // reset dropzone content
    const btn = document.querySelector(".dzu-submitButton");
    if (btn) {
      btn.click();
    }
    // reset dropzone content
    setIsSubmiting(false);
    setApplicableWorkTimings([]);
    setGeneralExceptionMessage("");

    if (action_type === REQUESTS.IGNORE_ASSIGNMENT && requestFrom) {
      setAssignmentsOptions([]);
      fetchRelevantAssignments();
    }
  }, [action_type]);

  useEffect(() => {
    setGeneralExceptionMessage("");
    setRequestable(null);
    setApplicableWorkTimings([]);
    setIsSubmiting(false);
  }, [requestFrom, requestTo]);

  useEffect(() => {
    if (requestFrom) {
      fetchEmployeeAttendanceProfile();
    }
  }, [requestFrom]);

  const fetchApplicableWorkTimingsFunc = (date, requestFor) => {
    setApplicableWorkTimings([]);
    setRequestable(null);
    return new Promise((resolve, reject) => {
      fetchApplicableWorkTimings({
        variables: {
          employee_id: employeeId,
          date: moment(date).format("YYYY-MM-DD"),
          for: requestFor,
        },
        onCompleted: (data) => {
          let options = [
            ...(data?.employee?.applicableWorkTimings?.default ?? []),
            ...(data?.employee?.applicableWorkTimings?.exceptions ?? []),
          ];
          let normalizedOptions = [];

          options?.forEach((applicable) => {
            switch (applicable?.__typename) {
              case "AttendanceProfileWorkTiming":
                normalizedOptions.push({
                  id: applicable?.id,
                  name: applicable?.work_timing?.name,
                  typename: applicable?.__typename,
                });
                break;
              case "ScheduleDetailEmployee":
                normalizedOptions.push({
                  id: applicable?.id,
                  name: applicable?.ScheduleDetail?.WorkTiming?.name,
                  typename: applicable?.__typename,
                });
                break;
              case "Exception":
                normalizedOptions.push({
                  id: applicable?.id,
                  name: applicable?.normalWorkTiming?.name,
                  typename: applicable?.__typename,
                });
                break;
              default:
                return;
            }
          });

          resolve(normalizedOptions);
        },
        onError: (error) => {
          reject(error);
        },
      });
    });
  };

  useEffect(() => {
    props.onInputResetAction(formName, "action_type");
    setIsSubmiting(false);
    setRequestFiles([]);

    return () => {
      props.onInputResetAction(formName, "requestFrom");
      props.onInputResetAction(formName, "requestTo");
    };
  }, [isModalVissible]);

  // initail vlaue for requestFiles set to empty array

  // function to add object to requestFiles array
  const addRequestFileObject = (file) => {
    setRequestFiles((requestFiles) => [...requestFiles, file]);
  };

  // function to remove an object from an requestFiles array
  const removeRequestFileObject = (id) => {
    setRequestFiles((requestFiles) =>
      requestFiles.filter((file) => file?.id !== id)
    );
  };

  // handle request image change
  const handleImageChange = ({ file, meta }, status) => {
    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result?.split(",");
      let fileType = file?.type?.split("/");

      if (status == "done") {
        let target = {
          file: base64[1],
          extension: fileType[1],
          id: meta?.id,
        };
        addRequestFileObject(target);
      }
    };

    if (status == "removed") {
      removeRequestFileObject(meta?.id);
    }
    return;
  };

  // handel text area change
  const handleTextArea = (e) => {
    props.onInputChangeAction(formName, e);
  };

  const handleModalTypes = () => {
    switch (props?.data?.action_type) {
      case REQUESTS.NORMAL_LEAVES:
        // case REQUESTS.EMERGENCY_LEAVES:
        return (
          <div className="row align-items-start">
            {employeeAttendanceProfile?.permission_leaves_break_setting
              ?.annualLeaveSetting?.allowed_emergency_leaves ? (
              <div className="col-sm-6 mt-2">
                <CheckboxBooleanForm
                  {...FormProps}
                  options={["Treat as an emergency"]}
                  name="deducted_from_emergency"
                  type="checkbox"
                  reducer="user"
                />
              </div>
            ) : null}
          </div>
        );
      case REQUESTS.UNPAID_LEAVES:
        return null;

      case REQUESTS.HOME:
        return (
          <div className="row align-items-start">
            {employeeRelevantAttendanceTypeConfiguration?.workRemotelySettings
              ?.can_ex_days == 1 ? (
              <>
                <div className="col-sm-6">
                  <BSelect
                    {...FormProps}
                    name="home_day"
                    placeholder="In exchange for"
                    options={employeeRelevantAttendanceTypeConfiguration?.workRemotelySettings?.home_days
                      ?.split(",")
                      ?.map((day) => ({ value: day }))}
                    optionLabel="value"
                    optionValue="value"
                    getOptionLabel={(option) => t(option.value?.toLowerCase())}
                    validateBy="textRequired"
                    keepDefaultStyle
                    dependOn="action_type"
                    reducer="user"
                    icon="calendar"
                  />
                </div>
              </>
            ) : null}
          </div>
        );

      case REQUESTS.NORMAL_HALF_DAY:
        // case REQUESTS.EMERGENCY_HALF_DAY:
        return (
          <>
            <div className="row align-items-start">
              <div className="col-sm-6">
                <BSelect
                  {...FormProps}
                  name="half_day_will_attend"
                  placeholder={t("Which Half Would You Attend")}
                  options={Constants.HalfDay}
                  optionLabel="label"
                  optionValue="value"
                  validateBy="textRequired"
                  formSubmitting={isSubmiting}
                  keepDefaultStyle
                  reducer="user"
                  icon="calendar"
                />
              </div>
            </div>
            {employeeAttendanceProfile?.permission_leaves_break_setting
              ?.annualLeaveSetting?.allowed_emergency_leaves ? (
              <div className="col-sm-6 mt-2">
                <CheckboxBooleanForm
                  {...FormProps}
                  options={["Treat as an emergency"]}
                  name="deducted_from_emergency"
                  type="checkbox"
                  reducer="user"
                />
              </div>
            ) : null}
          </>
        );
      case REQUESTS.UNPAID_HALF_DAY:
        return (
          <>
            <div className="row align-items-start">
              <div className="col-sm-6">
                <BSelect
                  {...FormProps}
                  name="half_day_will_attend"
                  placeholder={t("Which Half Would You Attend")}
                  options={Constants.HalfDay}
                  optionLabel="label"
                  optionValue="value"
                  validateBy="textRequired"
                  formSubmitting={isSubmiting}
                  keepDefaultStyle
                  reducer="user"
                  icon="calendar"
                />
              </div>
            </div>
            {/* {employeeAttendanceProfile
              ?.permission_leaves_break_setting?.annualLeaveSetting
              ?.allowed_emergency_leaves ? (
              <div className="col-sm-6 mt-2">
                <CheckboxBooleanForm
                  {...FormProps}
                  options={["Treat as an emergency"]}
                  name="deducted_from_emergency"
                  type="checkbox"
                  reducer="user"
                />
              </div>
            ) : null} */}
          </>
        );
      case REQUESTS.PERMISSIONS:
        return (
          <div className="row align-items-start">
            <div className="col-sm-6">
              {handleDateTimePickerForm(
                isSubmiting,
                "from",
                "from",
                "dateTime",
                "YYYY-MM-DD HH:mm",
                "from"
              )}
            </div>
            <div className="col-sm-6">
              {handleDateTimePickerForm(
                isSubmiting,
                "to",
                "to",
                "dateTime",
                "YYYY-MM-DD HH:mm",
                "to"
              )}
            </div>
          </div>
        );

      case REQUESTS.EXPENSE_CLAIM:
        return (
          <HasPrivileges reqireMain={[Privilages.APPLY_FOR_CLAIM_REQUESTS]}>
            <div>
              <div className="row">
                <div className="col-12">
                  <InputForm
                    name="amount"
                    label={"amount"}
                    labelStyle="mb-2"
                    placeholder={t("amount")}
                    reducer="user"
                    {...FormProps}
                    validationName={`input.amount`}
                    formServerValidation={userFormServerValidation}
                    formSubmitting={isSubmiting}
                    containerStyle=""
                    inputContainerStyle="w-100"
                    type="number"
                    validateBy="textRequired"
                  />
                </div>
              </div>

              <div className="row my-2">
                <div
                  className={`col-12 ${
                    "input.reason" in props?.userServerVaildation
                      ? "invalid-container-style"
                      : ""
                  }`}
                >
                  <label className="mb-2 validity-label-style">{t("reason")}</label>
                  <textarea
                    rows="4"
                    name="reason"
                    placeholder={t("state reason")}
                    onChange={handleTextArea}
                    value={props?.attendanceActionModal?.reason}
                  />

                  <span className="validity-msg-style">
                    {"input.reason" in props?.userServerVaildation
                      ? props?.userServerVaildation["input.reason"]
                      : null}
                  </span>
                </div>
              </div>

              {/* <div className="row">
                <div
                  className={`col-12 mt-1 documents_dropzone_style ${
                    "input.attachments" in props?.userServerVaildation
                      ? "invalid-container-style"
                      : ""
                  }`}
                >
                  <label className="mb-2 validity-label-style">{t("files")}</label>
                  <Dropzone
                    onChangeStatus={handleImageChange}
                    canRemove
                    accept="image/*"
                    name="attachments"
                    multiple={true}
                    inputContent={t("face_placeholder")}
                  />
                  <span className="validity-msg-style">
                    {"input.attachments" in props?.userServerVaildation
                      ? props?.userServerVaildation["input.attachments"]
                      : null}
                  </span>
                </div>
              </div> */}
            </div>
          </HasPrivileges>
        );
      case REQUESTS.SICK_LEAVES:
        return (
          <div>
            {/* <div className="row">
              <div
                className={`col-12 mt-1 documents_dropzone_style ${
                  "input.attachments" in props?.userServerVaildation
                    ? "invalid-container-style"
                    : ""
                }`}
              >
                <label className="mb-2 validity-label-style">{t("files")}</label>
                <Dropzone
                  onChangeStatus={handleImageChange}
                  canRemove
                  accept="image/*"
                  name="attachments"
                  multiple={true}
                  inputContent={t("face_placeholder")}
                />
                <span className="validity-msg-style">
                  {"input.attachments" in props?.userServerVaildation
                    ? props?.userServerVaildation["input.attachments"]
                    : null}
                </span>
              </div>
            </div> */}
          </div>
        );
      case REQUESTS.IGNORE_ASSIGNMENT:
        return (
          <>
            {assignmentsOptions?.length > 1 ? (
              <BSelect
                {...FormProps}
                name="assignment_id"
                label={t("assignment")}
                labelStyle="mb-2"
                keepDefaultStyle
                placeholder={t("select option")}
                options={assignmentsOptions}
                validateBy="textRequired"
                formSubmitting={isSubmiting}
                reducer="user"
                isLoading={fetchRelevantAssignmentsLoading}
              />
            ) : null}
          </>
        );
      case REQUESTS.BEREAVEMENT:
      case REQUESTS.MATERNITY_PATERNITY_LEAVE:
      case REQUESTS.HAJJ_LEAVE:
      case REQUESTS.STUDY_LEAVE:
      case REQUESTS.SABBATICAL_LEAVE:
      case REQUESTS.MARRIAGE_LEAVE:
        return (
          <div>
            <div className="row align-items-start mb-2"></div>

            <div>
              <CheckboxBooleanForm
                {...FormProps}
                formSubmitting={isSubmiting}
                options={[
                  "Include Holidays and Weekends When Calculating Leave Durations",
                ]}
                name="include_weekends_and_holidays_in_custom_requests"
                validationName="input.include_weekends_and_holidays_in_custom_requests"
                type="checkbox"
                reducer="user"
              />

              <RadioboxForm
                reducer="user"
                {...FormProps}
                formSubmitting={isSubmiting}
                containerStyle="custom-tardiness-container justify-content-start"
                // labelStyle="d-flex mr-3"
                optionsContainerStyle="penalty-days-style fit-width flex-md-row"
                optionItemStyle="d-flex align-items-center mr-2"
                optionLabelStyle="mb-0 mr-3"
                optionInputStyle=" "
                // label="deduct from"
                options={[
                  { label: "Paid", value: "paid" },
                  { label: "Unpaid", value: "unpaid" },
                ]}
                validateBy="textRequired"
                name="payment_flag"
                validationName="input.payment_flag"
              />
            </div>
            {/* <div className="row">
              <div
                className={`col-12 mt-1 mb-2 documents_dropzone_style ${
                  "input.attachments" in props?.userServerVaildation
                    ? "invalid-container-style"
                    : ""
                }`}
              >
                <label className="mb-2 validity-label-style">{t("files")}</label>
                <Dropzone
                  onChangeStatus={handleImageChange}
                  canRemove
                  accept="image/*"
                  name="attachments"
                  multiple={true}
                  inputContent={t("face_placeholder")}
                />
                <span className="validity-msg-style">
                  {"input.attachments" in props?.userServerVaildation
                    ? props?.userServerVaildation["input.attachments"]
                    : null}
                </span>
              </div>
            </div> */}
          </div>
        );
      default:
        return;
    }
  };

  const applicableFor =
    props?.data?.action_type === REQUESTS.NORMAL_LEAVES ||
    props?.data?.action_type === REQUESTS.UNPAID_LEAVES ||
    props?.data?.action_type === REQUESTS.SICK_LEAVES ||
    props?.data?.action_type === REQUESTS.BEREAVEMENT ||
    props?.data?.action_type === REQUESTS.MATERNITY_PATERNITY_LEAVE ||
    props?.data?.action_type === REQUESTS.HAJJ_LEAVE ||
    props?.data?.action_type === REQUESTS.STUDY_LEAVE ||
    props?.data?.action_type === REQUESTS.SABBATICAL_LEAVE ||
    props?.data?.action_type === REQUESTS.MARRIAGE_LEAVE
      ? "Leave"
      : props?.data?.action_type === REQUESTS.PERMISSIONS
      ? "Permission"
      : props?.data?.action_type === REQUESTS.HOME
      ? "Home"
      : "HalfDay";

  const handleFetchApplicablesThenSubmit = async (requestFunction) => {
    if (requestable !== null) {
      setIsSubmiting(true);
      requestFunction(requestable);
      return;
    }

    if (
      Constants.possibleDateRangeRequests.includes(action_type) &&
      moment(requestFrom).format("YYYY-MM-DD") !==
        moment(requestTo).format("YYYY-MM-DD")
    ) {
      setIsSubmiting(true);
      requestFunction(null);
      return;
    }

    try {
      const applicables = await fetchApplicableWorkTimingsFunc(
        requestFrom,
        applicableFor
      );

      if (applicables?.length === 0) {
        setIsSubmiting(true);
        setGeneralExceptionMessage(
          "There are no shifts that you can submit this request on"
        );
        return;
      }

      if (applicables?.length > 1) {
        setApplicableWorkTimings(applicables);
        return;
      }

      if (applicables?.length === 1) {
        setIsSubmiting(true);
        requestFunction(applicables[0]);
        return;
      }
    } catch (error) {
      setRequestable(null);
      setApplicableWorkTimings([]);
      setGeneralExceptionMessage(error?.message ?? t("something went wrong"));
    }
  };

  const handleNormalLeaveRequest = (requestable) => {
    props.normalRequestActionAttempt(
      requestFrom,
      requestTo,
      !!props?.data?.deducted_from_emergency,
      isEmployee ? null : employeeId,
      requestFrom && requestTo && requestFrom === requestTo
        ? {
            requestableId: requestable?.id,
            requestableType: requestable?.typename,
          }
        : null,
      serializedAttachemnts ?? [],
      isEmployee ? employeeRefetchQueries : managerRefetchQueries
    );
  };

  const handleUnpaidLeaveRequest = (requestable) => {
    props.unpaidRequestActionAttempt(
      requestFrom,
      requestTo,
      isEmployee ? null : employeeId,
      requestFrom && requestTo && requestFrom === requestTo
        ? {
            requestableId: requestable?.id,
            requestableType: requestable?.typename,
          }
        : null,
      serializedAttachemnts,
      isEmployee ? employeeRefetchQueries : managerRefetchQueries
    );
  };

  const handlePermissionRequest = (requestable) => {
    props.permissionRequestActionAttempt(
      from,
      to,
      isEmployee ? null : employeeId,
      moment(requestFrom).format("YYYY-MM-DD"),
      requestable,
      serializedAttachemnts,
      isEmployee ? employeeRefetchQueries : managerRefetchQueries
    );
  };

  const handleNormalHalfDay = (requestable) => {
    props.halfdayRequestActionAttempt(
      requestFrom,
      props?.data?.half_day_will_attend,
      !!props?.data?.deducted_from_emergency,
      isEmployee ? null : employeeId,
      requestable,
      serializedAttachemnts,
      isEmployee ? employeeRefetchQueries : managerRefetchQueries
    );
  };

  const handleHomeRequest = (requestable) => {
    if (
      employeeRelevantAttendanceTypeConfiguration?.workRemotelySettings
        ?.can_ex_days
    ) {
      return props.homeExchangeRequestActionAttempt(
        requestFrom,
        props?.data?.home_day,
        isEmployee ? null : employeeId,
        requestable,
        serializedAttachemnts,
        isEmployee ? employeeRefetchQueries : managerRefetchQueries
      );
    } else {
      return props.homeFlexRequestActionAttempt(
        requestFrom,
        isEmployee ? null : employeeId,
        requestable,
        serializedAttachemnts,
        isEmployee ? employeeRefetchQueries : managerRefetchQueries
      );
    }
  };

  const handleSickLeaveRequest = (requestable) => {
    // to send file and extenstion only without id key in the array

    props.upsertSickRequestAction({
      ref: "attendanceModalActions",
      formName,
      allData: {
        ...props?.data,
        employee_id: isEmployee ? null : employeeId,
        time_from: requestFrom,
        time_to: requestTo,
        attachments: serializedAttachemnts,
        requestable:
          requestFrom && requestTo && requestFrom === requestTo
            ? {
                requestableId: requestable?.id,
                requestableType: requestable?.typename,
              }
            : null,
      },
      refetchQueries: isEmployee
        ? employeeRefetchQueries
        : managerRefetchQueries,
    });
  };

  const handleUnpaidHalfDay = (requestable) => {
    submitUnpaidHalfDay({
      variables: {
        input: {
          employeeId: employeeId,
          requestDay: moment(requestFrom).format("YYYY-MM-DD"),
          addInfo: half_day_will_attend?.toLowerCase(),
          requestable: {
            requestableId: requestable?.id,
            requestableType: requestable?.typename,
          },
          files: serializedAttachemnts,
        },
      },
    });
  };

  const handleCustomLeaveRequest = (requestable) => {
    dispatch(
      upsertCustomRequestAction({
        ref: "attendanceModalActions",
        formName,
        allData: {
          employee_id: employeeId,
          ...props.data,
          files: serializedAttachemnts,
          from: requestFrom,
          to: requestTo,
          request_type_id: action_type,
          requestable:
            requestFrom && requestTo && requestFrom === requestTo
              ? {
                  requestableId: requestable?.id,
                  requestableType: requestable?.typename,
                }
              : null,
        },
        refetchQueries: isEmployee
          ? employeeRefetchQueries
          : managerRefetchQueries,
      })
    );
  };

  const handleSubmitBtn = () => {
    if (props?.attendanceValidation?.length) {
      setIsSubmiting(true);
      return;
    }

    setGeneralExceptionMessage("");

    switch (props?.data?.action_type) {
      case REQUESTS.NORMAL_LEAVES:
        handleFetchApplicablesThenSubmit(handleNormalLeaveRequest);
        return;

      case REQUESTS.UNPAID_LEAVES:
        handleFetchApplicablesThenSubmit(handleUnpaidLeaveRequest);
        return;

      case REQUESTS.EMERGENCY_LEAVES:
        return props.emergencyRequestActionAttempt(requestFrom, requestTo);

      case REQUESTS.PERMISSIONS:
        handleFetchApplicablesThenSubmit(handlePermissionRequest);
        return;

      case REQUESTS.NORMAL_HALF_DAY:
        handleFetchApplicablesThenSubmit(handleNormalHalfDay);
        return;

      case REQUESTS.UNPAID_HALF_DAY:
        handleFetchApplicablesThenSubmit(handleUnpaidHalfDay);
        return;

      case REQUESTS.HOME:
        handleFetchApplicablesThenSubmit(handleHomeRequest);
        return;

      case REQUESTS.EXPENSE_CLAIM:
        // to send file and extenstion only without id key in the array

        return props.upsertClaimsRequestAction({
          ref: "attendanceModalActions",
          formName,
          allData: { ...props?.data, attachments: serializedAttachemnts },
          refetchQueries: isEmployee
            ? employeeRefetchQueries
            : managerRefetchQueries,
        });

      case REQUESTS.SICK_LEAVES:
        handleFetchApplicablesThenSubmit(handleSickLeaveRequest);
        return;

      case REQUESTS.IGNORE_ASSIGNMENT:
        return handleIgnoreException();

      case REQUESTS.BEREAVEMENT:
      case REQUESTS.MATERNITY_PATERNITY_LEAVE:
      case REQUESTS.HAJJ_LEAVE:
      case REQUESTS.STUDY_LEAVE:
      case REQUESTS.SABBATICAL_LEAVE:
      case REQUESTS.MARRIAGE_LEAVE:
        handleFetchApplicablesThenSubmit(handleCustomLeaveRequest);
        return;
      default:
        return;
    }
  };

  const checkRequestAbility = (key) => {
    const val = employeeAttendanceProfile?.permission_leaves_break_setting?.submitRequestAbility?.[key];
    if (isEmployee) {
      return val === 'both'
    } else {
      return val === 'both' || val === 'manager'
    }
  }

  const checkRemoteRequestAbility = () => {
    const { can_work_home = true, employee_can_request, flexible_home, can_ex_days } = employeeRelevantAttendanceTypeConfiguration?.workRemotelySettings || {};
    if (!can_work_home) return false;
    if (isEmployee) {
      return employee_can_request && (flexible_home || can_ex_days);
    } else {
      return flexible_home || can_ex_days;
    }
  };

  const selectTypesOptions = [
    ...(canRequestLeaves()
      ? (employeeAttendanceProfile?.permission_leaves_break_setting
          ?.annualLeaveSetting?.allow_annual_leaves && checkRequestAbility('annual_emergency_leave'))
        ? [
            { label: "Annual Leaves", value: REQUESTS.NORMAL_LEAVES },
            // { label: "Emergency Leaves", value: REQUESTS.EMERGENCY_LEAVES },
          ]
        : []
      : []),
    ...(checkRemoteRequestAbility() ? [{ label: "Home", value: REQUESTS.HOME }] : []),

    ...((employeeAttendanceProfile?.permission_leaves_break_setting
      ?.unpaidLeaveSetting?.unpaid_leaves || employeeProfile?.employee?.on_probation) && checkRequestAbility('unpaid_leave') 
      ? [{ label: "Unpaid Leaves", value: REQUESTS.UNPAID_LEAVES }]
      : []),
    ...(canRequestLeaves() && employeeAttendanceProfile?.can_request_half_day && 
        employeeAttendanceProfile?.permission_leaves_break_setting?.annualLeaveSetting?.allow_annual_leaves && checkRequestAbility('annual_emergency_half_day')
      ? [
          { label: "Half Day", value: REQUESTS.NORMAL_HALF_DAY },
          // { label: "Emergency Half Day", value: REQUESTS.EMERGENCY_HALF_DAY },
        ]
      : []),
    ...((employeeProfile?.employee?.on_probation ||
    (employeeAttendanceProfile?.can_request_half_day &&
      employeeAttendanceProfile?.permission_leaves_break_setting
        ?.unpaidLeaveSetting?.unpaid_leaves) && checkRequestAbility('unpaid_half_day'))
      ? [{ label: "Unpaid Half Day", value: REQUESTS.UNPAID_HALF_DAY }]
      : []),
    ...((+employeeAttendanceProfile?.permission_leaves_break_setting
      ?.permissionSetting?.allow_permissions && checkRequestAbility('permission'))
      ? [{ label: "Permissions", value: REQUESTS.PERMISSIONS }]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.APPLY_FOR_CLAIM_REQUESTS],
    })
      ? [{ label: "expense claim", value: REQUESTS.EXPENSE_CLAIM }]
      : []),
    ...(canRequestLeaves() &&
    (employeeAttendanceProfile?.permission_leaves_break_setting?.sickLeaveSetting
      ?.sick_leaves && checkRequestAbility('sick_leave'))
      ? [{ label: "Sick Leaves", value: REQUESTS.SICK_LEAVES }]
      : []),
    ...(isEmployee && HelperFns.checkPrivileges({ allowBP: false, privileges: [Privilages.IGNORE_ASSIGNMENT] }) ? [{ label: "ignore assignment", value: REQUESTS.IGNORE_ASSIGNMENT }] : []),
    ...(!isEmployee ? [{ label: "ignore assignment", value: REQUESTS.IGNORE_ASSIGNMENT }] : []),
    ...(canRequestLeaves() && !isEmployee
      ? [
          {
            label: "Bereavement",
            value: REQUESTS.BEREAVEMENT,
          },
          {
            label: "Maternity/Paternity Leave",
            value: REQUESTS.MATERNITY_PATERNITY_LEAVE,
          },

          {
            label: "Hajj Leave",
            value: REQUESTS.HAJJ_LEAVE,
          },
          {
            label: "Study Leave",
            value: REQUESTS.STUDY_LEAVE,
          },
          {
            label: "Sabbatical Leave",
            value: REQUESTS.SABBATICAL_LEAVE,
          },
          {
            label: "Marriage Leave",
            value: REQUESTS.MARRIAGE_LEAVE,
          },
        ]
      : []),
  ];

  const shouldRenderApplicableWorkTimings =
    (action_type === REQUESTS.PERMISSIONS ||
      action_type === REQUESTS.HOME ||
      action_type === REQUESTS.NORMAL_HALF_DAY ||
      action_type === REQUESTS.UNPAID_HALF_DAY ||
      action_type === REQUESTS.UNPAID_LEAVES ||
      action_type === REQUESTS.NORMAL_LEAVES ||
      action_type === REQUESTS.SICK_LEAVES ||
      action_type === REQUESTS.BEREAVEMENT ||
      action_type === REQUESTS.MATERNITY_PATERNITY_LEAVE ||
      action_type === REQUESTS.HAJJ_LEAVE ||
      action_type === REQUESTS.STUDY_LEAVE ||
      action_type === REQUESTS.SABBATICAL_LEAVE ||
      action_type === REQUESTS.MARRIAGE_LEAVE) &&
    applicableWorkTimings?.length > 1;

  const requestFromLabel = () => {
    switch (action_type) {
      case REQUESTS.NORMAL_LEAVES:
      case REQUESTS.SICK_LEAVES:
      case REQUESTS.UNPAID_LEAVES:
      case REQUESTS.BEREAVEMENT:
      case REQUESTS.MATERNITY_PATERNITY_LEAVE:
      case REQUESTS.HAJJ_LEAVE:
      case REQUESTS.STUDY_LEAVE:
      case REQUESTS.SABBATICAL_LEAVE:
      case REQUESTS.MARRIAGE_LEAVE:
        return "from";
      case REQUESTS.EXPENSE_CLAIM:
        return "incurred at";
      default:
        return "date";
    }
  };

  const removeAllSelectedFiles = (_, allFiles) => {
    allFiles?.forEach((f) => f?.remove());
  };

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={props.toggleAttendanceActionModal}
      modalTitle={t("new request")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={
        +isLoading ||
        ignoreExceptionLoading ||
        unpaidHalfDayLoading ||
        fetchApplicableWorkTimingsLoading
      }
      btnLabel={t("save")}
      disableSubmitButton={fetchApplicableWorkTimingsLoading}
    >
      <div className="d-flex align-items-center">
        <DateTimePickerForm
          {...FormProps}
          name="requestFrom"
          label={requestFromLabel()}
          placeholder="select date"
          // validateBy="textRequired"
          formSubmitting={isSubmiting}
          containerStyle="my-1"
          inputContainerStyle=" "
          reducer="user"
          rootStyle="w-100 mb-3 mr-3"
          requestFormat="YYYY-MM-DD"
          validationName={
            action_type == REQUESTS.EXPENSE_CLAIM
              ? "input.incurred_at"
              : "input.from"
          }
          formServerValidation={userFormServerValidation}
        />

        {Constants.possibleDateRangeRequests.includes(action_type) ? (
          <DateTimePickerForm
            {...FormProps}
            name="requestTo"
            label="to"
            placeholder="select date"
            validateBy="textRequired"
            formSubmitting={isSubmiting}
            containerStyle="my-1"
            inputContainerStyle=" "
            reducer="user"
            rootStyle="w-100 mb-3"
            requestFormat="YYYY-MM-DD"
            // validationName="input.requestFrom"
            // formServerValidation={userFormServerValidation}
          />
        ) : null}
      </div>
      {/* (Start) Type Select */}

      <div className="mb-2">
        <BSelect
          {...FormProps}
          name="action_type"
          label={t("request type")}
          labelStyle="mb-2"
          keepDefaultStyle
          placeholder={t("select option")}
          options={attendanceProfileLoading ? [] : selectTypesOptions}
          optionValue="value"
          validateBy="textRequired"
          formSubmitting={isSubmiting}
          reducer="user"
          getOptionLabel={(option) => t(option.label?.toLowerCase())}
          icon="type"
          isLoading={attendanceProfileLoading}
          isDisabled={attendanceProfileLoading}
        />
      </div>

      {/* (End) Type Select */}

      {/* (Start) Modal Content */}
      {handleModalTypes()}

      {!!props?.data?.action_type ? (
        <div className="row">
          <div
            className={`col-12 mt-1 documents_dropzone_style ${
              "input.attachments" in props?.userServerVaildation
                ? "invalid-container-style"
                : ""
            }`}
          >
            <label className="mb-2 validity-label-style">{t("files")}</label>
            <Dropzone
              onChangeStatus={handleImageChange}
              canRemove
              accept="image/*"
              name="attachments"
              multiple={true}
              inputContent={t("face_placeholder")}
              onSubmit={removeAllSelectedFiles}
            />
            <span className="validity-msg-style">
              {"input.attachments" in props?.userServerVaildation
                ? props?.userServerVaildation["input.attachments"]
                : null}
            </span>
          </div>
        </div>
      ) : null}
      {/* (End) Modal Content */}

      {/* applicable work timings for requesting */}
      {shouldRenderApplicableWorkTimings ? (
        <div className="col-12 px-0">
          <BSelect
            {...FormProps}
            name="requestable"
            label={t("requestable")}
            labelStyle="mb-2"
            keepDefaultStyle
            placeholder={t("select option")}
            options={applicableWorkTimings}
            validateBy="textRequired"
            formSubmitting={isSubmiting}
            reducer="user"
            isLoading={fetchApplicableWorkTimingsLoading}
            onChange={(value) => setRequestable(value)}
            value={requestable}
          />
        </div>
      ) : null}

      {/* (Start) Form server validation message */}
      <span className="warnig-msg-style">
        {props.userServerVaildation[`input.description`] ?? " "}
      </span>
      {/* (End) Form server validation message */}

      {/* (Start) Error Message */}
      {modalMessage && isSubmiting && (
        <div className="warnig-msg-style">{modalMessage}</div>
      )}
      {/* (End) Error Message */}
      {ignoreAssignmentExceptionMessage?.length ? (
        <div className="invalid-container-style">
          <p className="mb-0 mt-2 validity-msg-style">
            {ignoreAssignmentExceptionMessage}
          </p>
        </div>
      ) : null}
      <div className="invalid-container-style">
        <div className="validity-msg-style">{t(generalExceptionMessage)}</div>
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  data: state.user.attendanceActionModal,
  attendanceValidation: state.user.attendanceActionModalValidation,
  modalActions: state.user.attendanceModalActions,
  userProfile: state.user.userProfile,
  userServerVaildation: state.user[userFormServerValidation],
});

export default connect(mapStateToProps, {
  onInputResetAction,
  normalRequestActionAttempt,
  unpaidRequestActionAttempt,
  emergencyRequestActionAttempt,
  attendanceActionModalFailed,
  permissionRequestActionAttempt,
  halfdayRequestActionAttempt,
  homeExchangeRequestActionAttempt,
  homeFlexRequestActionAttempt,
  toggleAttendanceActionModal,
  upsertClaimsRequestAction,
  onInputChangeAction,
  upsertSickRequestAction,
})(AttendanceActionModal);
