import React from "react";
import { connect } from "react-redux";
import { CheckboxBooleanForm, InputForm } from "form-builder";
import { useTranslation } from "react-i18next";

const EditAttOvertime = (props) => {
  const { formProps } = props;

  const { t } = useTranslation();

  return (
    <div
      className={+props.data[formProps?.formName]?.sign_in_req ? " " : "d-none"}
    >
      <h5 className="my-2 sub-title-style">{t("overtime")}</h5>
      <div className="">
        <CheckboxBooleanForm
          dependOn="sign_in_req"
          dependancyType="equal"
          dependancyValue={[1]}
          {...formProps}
          options={["Allow Overtime"]}
          name="overtime_permissible"
          validationName="input.overtime_permissible"
          type="checkbox"
        />
        <div className="sub-container-style">
          <InputForm
            {...formProps}
            dependOn="overtime_permissible"
            dependancyType="equal"
            dependancyValue={[1]}
            hasSuffix
            suffixTitle="Minutes"
            validateBy="textRequired"
            label="Calculate Overtime After calculated sign out time by"
            name="min_overtime_cuttoff"
            validationName="input.min_overtime_cuttoff"
            type="text"
            inputContainerStyle="m-start-auto"
          />

          {/* <InputForm
            {...formProps}
            dependOn="overtime_permissible"
            dependancyType="equal"
            dependancyValue={[1]}
            stepInput
            validateBy="textRequired"
            label="Overtime Hour Factor"
            name="overtime_payment_factor"
            validationName="input.overtime_payment_factor"
            type="text"
          /> */}

          <CheckboxBooleanForm
            dependOn="overtime_permissible"
            dependancyType="equal"
            dependancyValue={[1]}
            {...formProps}
            options={["Apply Overtime Limits"]}
            optionLabelStyle="content-header-sub-label"
            name="overtime_limit"
            validationName="input.overtime_limit"
            type="checkbox"
          />
          <InputForm
            {...formProps}
            dependOn="overtime_limit"
            dependancyType="equal"
            dependancyValue={[1]}
            stepInput
            validateBy="textRequired"
            label="Max. Overtime Hours Per Day"
            name="max_overtime_per_day"
            validationName="input.max_overtime_per_day"
            type="text"
            inputContainerStyle="m-start-auto"
          />

          {/* <InputForm
            {...formProps}
            dependOn="overtime_limit"
            dependancyType="equal"
            dependancyValue={[1]}
            stepInput
            validateBy="textRequired"
            label="Max. Overtime Hours Per Month"
            name="max_overtime_per_month"
            validationName="input.max_overtime_per_month"
            type="text"
          /> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
  };
};

export default connect(mapStateToProps)(EditAttOvertime);
