import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import useCustomQuery from "../../Helpers/Hooks/useCustomQuery";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import {
  subStatusOptions,
  leadStatusOptions,
  activityTypeOptions,
} from "../../Constants/CRM";
import {
  storeActivityMutation,
  changeActivityMutation,
} from "../../Graphql/mutation";
import {
  buildOption,
  formatOptions,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import moment from "moment";
import { activityFormQuery, fetchLeadQuery } from "../../Graphql/query";
import { clearCRMValidations, setCRMValidations } from "../../Store/Actions";

import Loader from "../Loader";
import Box from "@mui/material/Box";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import ContactModal from "./ContactModal";
import Dropzone from "react-dropzone-uploader";
import { InputForm, BSelect, DateTimePickerForm } from "form-builder";
import { EditIconButton, TimesIconButton } from "../IconButtonWithTooltip";

const utcOffsetMin = moment().utcOffset();
const formNameValidation = "activityClientValidation";
const formServerValidation = "activityServerValidation";
const contactModalInitState = { isOpen: false, isEdit: false, data: null };
const subscriptionTypes = [
  "2+1 Month Free",
  "50% Off",
  "Annual Plan",
  "Discounted Annual Plan",
];

const ActivityModal = ({
  data,
  isLeadList,
  isLeadProfile,
  leadProfileData,
  onClose,
  refetchList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEdit = Boolean(data) && !isLeadList;

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [contactModalState, setContactModalState] = useState(
    contactModalInitState
  );
  const [isFetchingInputDepend, setIsFetchingInputDepend] = useState(isEdit);
  const [formData, setFormData] = useState({
    lead: isLeadList ? data : leadProfileData || null,
    activity: null,
    date: moment(),
    notes: "",
    status: null,
    contact: null,
    subStatus: null,
    videoLink: "",
    attachments: [],
    meetingTime: "",
    subscription: "",
    empNO: "",
    plans: [],
  });
  const [options, setOptions] = useState({
    leads: [],
    plans: [],
    subscriptions: [],
  });

  // Reducer State
  const activityClientValidation = useSelector(
    (state) => state.crm[formNameValidation]
  );

  // Server State
  const [getLead, { loading: isLeadUpdate }] = useLazyQuery(fetchLeadQuery);
  const [storeActivity, { loading: isStoreLoading }] = useCustomMutation(
    storeActivityMutation
  );
  const [changeActivity, { loading: isChangeLoading }] = useCustomMutation(
    changeActivityMutation
  );
  const { loading: isDataLoading } = useCustomQuery(activityFormQuery, {
    variables: {
      id: isLeadList ? undefined : data?.id,
      isActivitySkip: !isEdit,
      isLeadList,
    },
    onCompleted: ({ activity, leads, plans, subscriptions }) => {
      const dateToMoment = (date) => (date ? moment(date) : "");

      // Data
      if (isEdit) {
        setFormData({
          id: activity.id,
          attachments: [],
          lead: activity.lead,
          contact: activity?.contact,
          notes: activity?.notes || "",
          savedAttachments: activity.attachments,
          empNO: JSON.parse(activity.type)?.empNO || "",
          plans: JSON.parse(activity.type)?.plans || [],
          videoLink: JSON.parse(activity.type)?.videoLink || "",
          status: buildOption(JSON.parse(activity?.status)?.[0]),
          activity: buildOption(JSON.parse(activity?.type)?.name),
          subStatus: buildOption(JSON.parse(activity?.status)?.[1]),
          date: moment(activity?.date).add(utcOffsetMin, "minutes"),
          meetingTime: dateToMoment(JSON.parse(activity?.type)?.meetingTime),
          subscription: dateToMoment(JSON.parse(activity?.type)?.subscription),
        });
        setIsFetchingInputDepend(false);
      }

      // Options
      setOptions((prev) => ({
        ...prev,
        leads: leads?.data || [],
        plans: plans?.data || [],
        subscriptions: subscriptions?.data || [],
      }));
    },
  });

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    !isFetchingInputDepend &&
      setFormData((prev) => ({ ...prev, contact: null }));
  }, [formData.lead?.id]);

  React.useEffect(() => {
    !isFetchingInputDepend &&
      setFormData((prev) => ({ ...prev, subStatus: null }));
  }, [formData.status?.id]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCRMValidations(formServerValidation));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const getType = () => {
    let type = { name: formData.activity?.name };

    switch (formData.activity?.id) {
      case "Meeting":
        type = { ...type, meetingTime: formData.meetingTime };
        break;
      case "Demo":
        type = { ...type, videoLink: formData.videoLink };
        break;
      case "Proposal":
        type = {
          ...type,
          empNO: formData.empNO,
          plans: formData.plans,
          subscription: formData.subscription,
        };
        break;
      default:
        break;
    }

    return JSON.stringify(type);
  };

  const handleAddContactModal = () => {
    setContactModalState({
      isOpen: true,
      isEdit: false,
      data: { lead_id: formData.lead?.id },
    });
  };
  const handleEditContactModal = () => {
    setContactModalState({
      isOpen: true,
      isEdit: true,
      data: { lead_id: formData.lead?.id, ...formData.contact },
    });
  };

  const onUpsertContact = () => {
    getLead({
      variables: { id: formData.lead.id },
      onCompleted: ({ lead = {} }) => {
        setFormData((prev) => ({
          ...prev,
          lead,
          contact: prev.contact
            ? lead.contacts.find((contact) => contact.id === prev.contact.id)
            : null,
        }));
        setOptions((prev) => ({
          ...prev,
          leads: prev.leads.map((l) => (l.id === lead.id ? lead : l)),
        }));
      },
    });
  };

  const handleTextChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectChange = (val, { name: key }) => {
    setFormData((prev) => ({ ...prev, [key]: val }));
  };

  const handleDateChange = (name, val) => {
    setFormData((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const handleAttachmentChange = ({ file }, status) => {
    if (status === "done") {
      setFormData((prev) => ({
        ...prev,
        attachments: [...prev?.attachments, file],
      }));
    }
  };

  const handleSavedAttachmentRemove = (idx) => {
    setFormData((prev) => ({
      ...prev,
      savedAttachments: prev.savedAttachments.filter((_, i) => i !== idx),
    }));
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (activityClientValidation.length) return;

    const upsert = isEdit ? changeActivity : storeActivity;
    const {
      lead,
      empNO,
      notes,
      plans,
      status,
      contact,
      activity,
      subStatus,
      videoLink,
      meetingTime,
      attachments,
      subscription,
      savedAttachments = [],
      date,
      ...rest
    } = formData;

    upsert({
      refetchList,
      onCloseModal: onClose,
      variables: {
        notes,
        attachments,
        type: getType(),
        lead_id: lead?.id || undefined,
        ...formatSendingInputs({ ...rest }),
        contact_id: contact?.id || undefined,
        status: [status?.name, subStatus?.name],
        date: date
          .subtract(utcOffsetMin, "minutes")
          .format("YYYY-MM-D HH:mm:ss"),
        ...(isEdit && {
          attachmentIds: savedAttachments.map((a) => +a.id),
        }),
      },
      onError: (err) => {
        err[0]?.extensions?.validation &&
          dispatch(
            setCRMValidations({
              ref: formServerValidation,
              validations: err[0]?.extensions?.validation,
            })
          );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      modalTitle={t(`${isEdit ? "edit" : "new"} activity`)}
      btnSubmitLoading={isStoreLoading || isChangeLoading}
    >
      {(isEdit && isDataLoading) || isLeadUpdate ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex flex-column gap-10">
        {isLeadList || isLeadProfile ? (
          <>
            <strong>{t("lead")}</strong>
            <span>{formData.lead.name}</span>
          </>
        ) : (
          <BSelect
            name="lead"
            label="lead"
            icon="employee"
            keepDefaultStyle
            isDisabled={isEdit}
            value={formData.lead}
            options={options?.leads}
            onChange={handleSelectChange}
            isLoading={isDataLoading}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        )}
        <BSelect
          label="activity"
          name="activity"
          icon="employee"
          keepDefaultStyle
          labelStyle="w-100"
          value={formData.activity}
          onChange={handleSelectChange}
          options={formatOptions(activityTypeOptions)}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
        />
        <div>
          <div className="d-flex align-items-center gap-20 mb-2">
            {t("lead contact")}
            <AddButton
              isIconOnly
              onClick={handleAddContactModal}
              disabled={!Boolean(formData.lead?.id) ? true : undefined}
            />
            <EditIconButton
              onClick={handleEditContactModal}
              disabled={!Boolean(formData.contact?.id)}
              tooltipProps={{ isDisable: !Boolean(formData.contact?.id) }}
            />
          </div>
          <BSelect
            name="contact"
            icon="employees"
            keepDefaultStyle
            value={formData.contact}
            onChange={handleSelectChange}
            options={formData.lead?.contacts || []}
            isDisabled={!Boolean(formData.lead?.name)}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        </div>
        <DateTimePickerForm
          hasIcon
          dateTime
          label="date"
          labelStyle="w-100"
          value={formData.date}
          datePickerContainer="w-100"
          onSelect={(val) => handleDateChange("date", val)}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          validationName="date"
          reducer="crm"
        />

        {formData.activity?.name === "Meeting" ? (
          <DateTimePickerForm
            hasIcon
            dateTime
            labelStyle="w-100"
            label="meeting time"
            value={formData.meetingTime}
            datePickerContainer="w-100"
            onSelect={(val) => handleDateChange("meetingTime", val)}
          />
        ) : null}
        {formData.activity?.name === "Demo" ? (
          <InputForm
            name="videoLink"
            label="video link"
            labelStyle="w-100"
            onChange={handleTextChange}
            inputContainerStyle="w-100"
            value={formData.videoLink}
          />
        ) : null}
        {formData.activity?.name === "Proposal" ? (
          <>
            <div className="d-flex align-items-end gap-10">
              <BSelect
                name="subscription"
                icon="employees"
                keepDefaultStyle
                label="subscription type"
                rootStyle="flex-fill"
                value={formData.subscription}
                onChange={handleSelectChange}
                options={formatOptions(subscriptionTypes)}
                classNamePrefix="b-select-style"
              />
              <InputForm
                type="number"
                name="empNO"
                containerStyle=" "
                label="number Of employees"
                rootStyle="flex-fill"
                value={formData.empNO}
                onChange={handleTextChange}
              />
            </div>
            <BSelect
              isMulti
              name="plans"
              label="plans"
              icon="employees"
              keepDefaultStyle
              value={formData.plans}
              options={options.plans}
              isLoading={!options.plans.length}
              classNamePrefix="b-select-style"
              onChange={handleSelectChange}
            />
          </>
        ) : null}

        <label className="w-100 mt-2">
          <span>{t("Notes")}</span>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleTextChange}
            style={{ resize: "none" }}
          />
        </label>
        <span>{t("Attachments")}</span>
        {isEdit ? (
          <div>
            {formData?.savedAttachments?.map((attachment, i) => (
              <div key={i} className="d-flex gap-20 align-items-center py-2">
                {attachment?.name}
                <TimesIconButton
                  onClick={() => handleSavedAttachmentRemove(i)}
                />
              </div>
            ))}
          </div>
        ) : null}
        <Box
          className="documents_dropzone_style"
          sx={{
            ".dzu-dropzone .dzu-previewContainer": {
              height: 60,
              justifyContent: "center",
              minHeight: "initial !important",
              ".dzu-previewStatusContainer": { position: "static" },
            },
          }}
        >
          <Dropzone onChangeStatus={handleAttachmentChange} />
        </Box>

        <div className="d-flex gap-10">
          <BSelect
            name="status"
            icon="employee"
            label="lead status"
            keepDefaultStyle
            rootStyle="flex-fill"
            value={formData.status}
            onChange={handleSelectChange}
            options={formatOptions(leadStatusOptions)}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
          {Object.keys(subStatusOptions).includes(formData.status?.name) ? (
            <BSelect
              name="subStatus"
              icon="employee"
              label="lead sub status"
              keepDefaultStyle
              rootStyle="flex-fill"
              onChange={handleSelectChange}
              value={formData.subStatus}
              classNamePrefix="b-select-style"
              options={formatOptions(subStatusOptions[formData.status.name])}
              validateBy="textRequired"
              formSubmitting={formSubmitting}
              formNameValidation={formNameValidation}
            />
          ) : null}
        </div>
      </div>

      {contactModalState.isOpen ? (
        <ContactModal
          state={contactModalState}
          onUpsertContact={onUpsertContact}
          onClose={() => setContactModalState(contactModalInitState)}
        />
      ) : null}
    </MainModal>
  );
};

export default ActivityModal;
