import React, { useEffect } from "react";
import BalanceLogsList from "../../Containers/BalanceLogsList";
import Bonus from "../../Containers/Bonus";
import Deductions from "../../Containers/Deductions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import { useHistory, useLocation } from "react-router-dom";
import AdjustmentsTabs from "./AdjustmentsTabs";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ViewAdjustments = (props) => {
  let query = useQuery();
  const activeValue = query.get("tab");
  const history = useHistory();
  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT],
        allowBP: true,
      })
    ) {
      return history.push(`adjustments?tab=leave-adjustments`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS],
        allowBP: true,
      })
    ) {
      return history.push(`adjustments?tab=bonus`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS],
        allowBP: true,
      })
    ) {
      return history.push(`adjustments?tab=deductions`);
    }
    return null;
  };

  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);
  const renderSelectedTab = () => {
    switch (activeValue) {
      case "leave-adjustments":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT]}
            allowBP
          >
            <BalanceLogsList />
          </HasPrivileges>
        );
      case "bonus":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS]}
            allowBP
          >
            <Bonus />
          </HasPrivileges>
        );
      case "deductions":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS]}
            allowBP
          >
            <Deductions />
          </HasPrivileges>
        );
      default:
        break;
    }
  };
  return (
    <div className="content pt-3">
      <AdjustmentsTabs />
      <div className="pt-4">{renderSelectedTab()}</div>
    </div>
  );
};

export default ViewAdjustments;
