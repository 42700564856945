import { SET_CRM_VALIDATION, CLEAR_CRM_VALIDATION } from "./types";

export const setCRMValidations = (payload) => ({
  type: SET_CRM_VALIDATION,
  payload,
});

export const clearCRMValidations = (ref) => ({
  type: CLEAR_CRM_VALIDATION,
  payload: { ref },
});
