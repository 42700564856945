import React from "react";
import moment from "moment";
import { weekdays } from "moment/moment";

import { useSelector, useDispatch } from "react-redux";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  CheckboxBooleanForm,
  DateTimePickerForm,
  InputForm,
  BSelect,
  RadioboxForm,
} from "form-builder";
import { childInputChange } from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Constants from "../../Constants";

const HourlySectionDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { FormProps } = props;

  const salaryConfigForm = useSelector((state) => state.super.salaryConfigForm);
  const serverValidation = useSelector(
    (state) => state.super?.[FormProps.formServerValidation]
  );
  const handleChildInputChange = (event, childName, index) => {
    dispatch(
      childInputChange(
        FormProps.formName,
        childName,
        event.target.name,
        index,
        event.target.value
      )
    );
  };

  const handleChildDateInputChange = (value, name, index, childName) => {
    if (moment(value).isValid()) {
      dispatch(
        childInputChange(
          FormProps.formName,
          childName,
          name,
          index,
          moment(value).format("HH:mm")
        )
      );
    } else {
      dispatch(
        childInputChange(FormProps.formName, childName, name, index, null)
      );
    }
  };

  const handleAddHourlyIntervalInput = (day = "Everyday") => {
    dispatch({
      type: "ADD_HOURLY_INTERVAL",
      payload: { day },
    });
  };
  const handleRemoveHourlyIntervalInput = (index, day = "Everyday") => {
    dispatch({
      type: "REMOVE_HOURLY_INTERVAL",
      payload: {
        day,
        index,
      },
    });
  };

  const handleApplyEveryDayChange = (e) => {
    // return;
    if (e.target.checked) {
      dispatch({
        type: "APPLY_EVERY_DAY",
      });
    } else {
      let seperateWorkDays = {};
      Constants.Days.forEach((day) => {
        seperateWorkDays[day] = [
          {
            from: "00:00",
            to: "00:00",
            day,
            rate: "0",
          },
        ];
      });
      dispatch({
        type: "APPLY_SEPERATE_DAYS",
        payload: { seperateWorkDays },
      });
    }
  };
  return (
    <>
      {salaryConfigForm?.apply_hourly_rate_on_every_day ? (
        <>
          <div className="my-2">
            <button
              className="btn add_new_btn_style"
              type="button"
              onClick={() => handleAddHourlyIntervalInput()}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span className="mx-1">{t("Addional Intervals")}</span>
            </button>
          </div>
          {salaryConfigForm?.Everyday?.map((hourly, index, data) => {
            const fromKey = data[index - 1]?.to;
            return (
              <div className="d-flex gap-10 justify-content-between">
                <InputForm
                  name="rate"
                  type="number"
                  onWheelCapture={(e) => {
                    e.target.blur();
                  }}
                  {...FormProps}
                  label={t("rate per hour")}
                  placeholder={t("rate per hour")}
                  labelStyle="my-0 font-weight-bold"
                  containerStyle=""
                  inputContainerStyle="w-100"
                  validateBy="textRequired"
                  validationName={`input.user_salary_config_input.salary_detail.hourly_rating_setting.${index}.rate`}
                  icon="money"
                  dependOn="calculate_salary_by"
                  dependancyType="equal"
                  dependancyValue={["hourly"]}
                  value={hourly?.rate}
                  onChange={(e) => handleChildInputChange(e, "Everyday", index)}
                />

                {index == 0 ? (
                  <DateTimePickerForm
                    containerStyle="flex-column"
                    inputStyle="date-picker-input-default rc-picker validity-input-style w-200"
                    timeOnly
                    disabled={index == 0}
                    requestFormat="HH:mm"
                    {...FormProps}
                    name={"from"}
                    labelStyle="mx-1"
                    label="from"
                    onChange={(val) =>
                      handleChildDateInputChange(val, "from", index, "Everyday")
                    }
                    onSelect={(val) =>
                      handleChildDateInputChange(val, "from", index, "Everyday")
                    }
                    value={
                      index == 0
                        ? moment(hourly?.from, "HH:mm")
                        : Boolean(hourly?.from)
                        ? moment(hourly?.from, "HH:mm")
                        : null
                    }
                    validationName={`input.user_salary_config_input.salary_detail.hourly_rating_setting.${index}.from`}
                  />
                ) : (
                  <DateTimePickerForm
                    containerStyle="flex-column"
                    inputStyle="date-picker-input-default rc-picker validity-input-style w-200"
                    timeOnly
                    requestFormat="HH:mm"
                    {...FormProps}
                    name="to"
                    labelStyle="mx-1"
                    label="from"
                    validationName={`input.user_salary_config_input.salary_detail.hourly_rating_setting.${
                      index - 1
                    }.to`}
                    onChange={(val) =>
                      handleChildDateInputChange(
                        val,
                        "to",
                        index - 1,
                        "Everyday"
                      )
                    }
                    onSelect={(val) =>
                      handleChildDateInputChange(
                        val,
                        "to",
                        index - 1,
                        "Everyday"
                      )
                    }
                    value={Boolean(fromKey) ? moment(fromKey, "HH:mm") : null}
                  />
                )}

                <DateTimePickerForm
                  containerStyle="flex-column"
                  disabled={index == data.length - 1}
                  inputStyle="date-picker-input-default rc-picker validity-input-style w-200"
                  timeOnly
                  requestFormat="HH:mm"
                  {...FormProps}
                  name="to"
                  labelStyle="mx-1"
                  label="to"
                  validationName={`input.user_salary_config_input.salary_detail.hourly_rating_setting.${index}.to`}
                  onChange={(val) =>
                    handleChildDateInputChange(val, "to", index, "Everyday")
                  }
                  onSelect={(val) =>
                    handleChildDateInputChange(val, "to", index, "Everyday")
                  }
                  value={
                    index == data.length - 1
                      ? moment("00:00", "HH:mm")
                      : Boolean(hourly?.to)
                      ? moment(hourly?.to, "HH:mm")
                      : null
                  }
                />

                {salaryConfigForm?.Everyday?.length > 1 ? (
                  <button
                    className="btn delete_btn_style"
                    type="button"
                    onClick={() => handleRemoveHourlyIntervalInput(index)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                ) : null}
              </div>
            );
          })}
          {serverValidation?.[
            "input.user_salary_config_input.salary_detail.hourly_rating_setting"
          ] ? (
            <span className="error_message_style">
              {
                serverValidation?.[
                  "input.user_salary_config_input.salary_detail.hourly_rating_setting"
                ]
              }
            </span>
          ) : null}
        </>
      ) : null}

      <CheckboxBooleanForm
        {...FormProps}
        options={["Apply on every work day"]}
        name="apply_hourly_rate_on_every_day"
        type="checkbox"
        labelStyle="checkbox"
        containerStyle="d-flex align-items-center justify-content-start my-1"
        optionsContainerStyle="d-flex flex-column"
        optionItemStyle="optionItemStyle mb-2"
        optionInputStyle="optionInputStyle containerStyle checkbox w-100"
        optionLabelStyle="optionLabelStyle ml-1 mt-1"
        optionLabelActiveStyle="optionLabelActiveStyle"
        validationName={`input.user_salary_config_input.salary_config.salary_management_type`}
        onInterceptInputOnChange={handleApplyEveryDayChange}
      />

      {salaryConfigForm?.apply_hourly_rate_on_every_day ? null : (
        <>
          {Constants.Days?.map((day) => (
            <div className="my-2">
              <div className="align-items-baseline mx-0 row">
                <h4 className="font-18 gray-color w-101">{t(day)}</h4>
                <button
                  className="btn add_new_btn_style mx-2"
                  type="button"
                  onClick={() => handleAddHourlyIntervalInput(day)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="mx-1">{t("Addional Intervals")}</span>
                </button>
              </div>

              {salaryConfigForm?.[day]?.map((hourly, index, data) => {
                let fromKey = data[index - 1]?.to;
                return (
                  <div className="d-flex gap-10 justify-content-between">
                    <InputForm
                      name="rate"
                      type="number"
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                      {...FormProps}
                      label={t("rate per hour")}
                      placeholder={t("rate per hour")}
                      labelStyle="my-0 font-weight-bold"
                      containerStyle=""
                      inputContainerStyle="w-100"
                      validateBy="textRequired"
                      validationName={`input.user_salary_config_input.salary_detail.hourly_rating_setting.${index}.rate`}
                      icon="money"
                      dependOn="calculate_salary_by"
                      dependancyType="equal"
                      dependancyValue={["hourly"]}
                      value={hourly?.rate}
                      onChange={(e) => handleChildInputChange(e, day, index)}
                    />

                    <div className="align-items-center col row mx-1">
                      {index == 0 ? (
                        <DateTimePickerForm
                          containerStyle="flex-column"
                          inputStyle="date-picker-input-default rc-picker validity-input-style w-200"
                          timeOnly
                          disabled={index == 0}
                          requestFormat="HH:mm"
                          {...FormProps}
                          name={"from"}
                          labelStyle="mx-1"
                          label="from"
                          onChange={(val) =>
                            handleChildDateInputChange(val, "from", index, day)
                          }
                          onSelect={(val) =>
                            handleChildDateInputChange(val, "from", index, day)
                          }
                          value={
                            index == 0
                              ? moment("00:00", "HH:mm")
                              : Boolean(hourly?.from)
                              ? moment(hourly?.from, "HH:mm")
                              : null
                          }
                          validationName={`input.user_salary_config_input.salary_calculations.${index}.hourlySettings.from`}
                        />
                      ) : (
                        <DateTimePickerForm
                          containerStyle="flex-column"
                          inputStyle="date-picker-input-default rc-picker validity-input-style w-200"
                          timeOnly
                          requestFormat="HH:mm"
                          {...FormProps}
                          name="to"
                          labelStyle="mx-1"
                          label="from"
                          validationName={`input.user_salary_config_input.salary_calculations.${
                            index - 1
                          }.hourlySettings.to`}
                          onChange={(val) =>
                            handleChildDateInputChange(
                              val,
                              "to",
                              index - 1,
                              day
                            )
                          }
                          onSelect={(val) =>
                            handleChildDateInputChange(
                              val,
                              "to",
                              index - 1,
                              day
                            )
                          }
                          value={
                            Boolean(fromKey) ? moment(fromKey, "HH:mm") : null
                          }
                        />
                      )}

                      <DateTimePickerForm
                        containerStyle="flex-column"
                        disabled={index == data.length - 1}
                        inputStyle="date-picker-input-default rc-picker validity-input-style w-200"
                        timeOnly
                        requestFormat="HH:mm"
                        {...FormProps}
                        name="to"
                        labelStyle="mx-1"
                        label="to"
                        validationName={`input.user_salary_config_input.salary_calculations.${index}.hourlySettings.to`}
                        onChange={(val) =>
                          handleChildDateInputChange(val, "to", index, day)
                        }
                        onSelect={(val) =>
                          handleChildDateInputChange(val, "to", index, day)
                        }
                        value={
                          index == data.length - 1
                            ? moment("00:00", "HH:mm")
                            : Boolean(hourly?.to)
                            ? moment(hourly?.to, "HH:mm")
                            : null
                        }
                      />

                      {salaryConfigForm?.[day]?.length > 1 ? (
                        <button
                          className="btn delete_btn_style"
                          type="button"
                          onClick={() =>
                            handleRemoveHourlyIntervalInput(index, day)
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
          {serverValidation?.[
            "input.user_salary_config_input.salary_detail.hourly_rating_setting"
          ] ? (
            <span className="error_message_style">
              {
                serverValidation?.[
                  "input.user_salary_config_input.salary_detail.hourly_rating_setting"
                ]
              }
            </span>
          ) : null}
        </>
      )}

      <CheckboxBooleanForm
        {...FormProps}
        options={[
          t(
            "Calculate payable work hours even when they exceed employee's default day work hours"
          ),
        ]}
        name="calculate_all_work_hours"
        type="checkbox"
        labelStyle="checkbox"
        containerStyle="d-flex align-items-center justify-content-start my-1"
        optionsContainerStyle="d-flex flex-column"
        optionItemStyle="optionItemStyle mb-2"
        optionInputStyle="optionInputStyle containerStyle checkbox w-100"
        optionLabelStyle="optionLabelStyle ml-1 mt-1"
        optionLabelActiveStyle="optionLabelActiveStyle"
        validationName={`input.user_salary_config_input.salary_detail.calculateAllWorkHours`}
        dependOn="calculate_salary_by"
        dependancyType="equal"
        dependancyValue={["hourly"]}
        setWithValue={true}
        checkedValue={true}
        unCheckedValue={false}
      />
      <div className="d-flex">
        <InputForm
          name="hours_per_paid_leave"
          {...FormProps}
          placeholder={t("Number of hours")}
          label="Number of hours per paid leave"
          inputStyle="flex-fill my-0 text-left pl-2"
          labelStyle="mb-0"
          containerStyle=""
          rootStyle="flex-fill pr-4"
          inputContainerStyle="w-100"
          validateBy="textRequired"
          validationName={`input.user_salary_config_input.salary_detail.hours_per_paid_leave`}
          dependOn="calculate_salary_by"
          dependancyType="equal"
          dependancyValue={["hourly"]}
          suffixTitle="hour"
          hasSuffix
        />
        <InputForm
          name="paid_leave_hourly_rate"
          {...FormProps}
          placeholder={t("rate per hour")}
          label="hourly rate for paid leaves"
          inputStyle="flex-fill my-0 text-left pl-2"
          labelStyle="mb-0"
          containerStyle=""
          rootStyle="flex-fill"
          inputContainerStyle="w-100"
          validateBy="textRequired"
          validationName={`input.user_salary_config_input.salary_detail.paid_leave_hourly_rate`}
          dependOn="calculate_salary_by"
          dependancyType="equal"
          dependancyValue={["hourly"]}
          suffixTitle="rate"
          hasSuffix
        />
      </div>
    </>
  );
};

export default HourlySectionDetails;
