import React, { useEffect } from "react";
import MainModal from "../../Components/MainModal";
import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { getpositionsOrDepatmentsQuery } from "../../Graphql/query";
import { useTranslation } from "react-i18next";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import Select from "react-select";
import { Alert, AlertTitle } from '@mui/material';
import "./employeesActionsStyles.scss";
import { positionOrDepartmentMutation } from "../../Graphql/mutation";

const PosDepModal = (props) => {
  const { t } = useTranslation();
  const initState = {
    department_id: null,
    position_id: null,
  }
  const [input, setInput] = useState(initState);

  //  get positions or Departments query
  const { loading:selectLoading, data: selectData } = useQuery(getpositionsOrDepatmentsQuery, {
    variables: {
      isDepartment:props.posDepModal.isDepartment
    },
    fetchPolicy: "network-only",
  });

  // position and department mutation
  const [positionOrDepartment, { loading: submitLoading, submitError }] =
    useMutation(positionOrDepartmentMutation, {
      variables: {
        employees_ids: props.selectedEmployees
      },
      onError: (err) => {
        console.log({err});
        if(err?.graphQLErrors[0]?.extensions?.validation){
          let validation = err?.graphQLErrors[0]?.extensions?.validation
          setValid(prev=>({isValid:false, msg:Object.values(validation).toString()}))
        } 
        else showToast("error", err?.graphQLErrors[0]?.extensions?.reason || err.message)
      },
      onCompleted: (res) => {
        if(res?.addDepartementToEmployees?.status==="success"||res?.addPostionToEmployees?.status==="success"){
          props.resetSelection()
          props.refetch()
          props.closePDModal()
        }
        if(props.posDepModal.isDepartment){
          showToast(
            res?.addDepartementToEmployees?.status,
            res?.addDepartementToEmployees.message
          );
        } else {
          showToast(
            res?.addPostionToEmployees?.status,
            res?.addPostionToEmployees.message
          );}
      },
    });


  // validate direct manager
  const [valid, setValid] = useState({isValid:true, msg:null}) ;
  const validate = () => {
    if(props.posDepModal.isDepartment){ 
      setValid({isValid:Boolean(input.department_id), msg:"This filed cannot be empty"})
      return Boolean(input.department_id) 
    }
    setValid({isValid:Boolean(input.position_id), msg:"This filed cannot be empty"})
    return Boolean(input.position_id) 
  }

  useEffect(() => {
    return () => {
      setValid({isValid:true, msg:null})
      setInput(initState);
    };
  }, [props.posDepModal.isOpen]);
  
  const handleSave = () => {
    if(!validate()) return
    HelperFns.checkPassword(
      "defaut_warning_messsage",
      "",
      "Confirm",
      "",
      () => submit()
    );
  };

  const submit = () => {
    if (props.posDepModal.isDepartment) {
     positionOrDepartment({ 
        variables: {
          isDepartment:true,
          department_id: input.department_id
        }});
        return;
    }
    positionOrDepartment({ 
        variables: {
          isDepartment:false,
          position_id: input.position_id
        }});
  };

  const handleCancle = () => {
    props.closePDModal()
  };

  const handleSelect = (val) => {
    if (props?.posDepModal.isDepartment) {
      setInput((prev) => ({
        ...prev,
        department_id: val ? val?.id : null,
      }));
    } else {
      setInput((prev) => ({
        ...prev,
        position_id: val ? val?.id : null,
      }));
    }
  };

  const selectValue = props?.posDepModal.isDepartment
    ? selectData?.company_departments?.data.find(
        (opt) => opt?.id === input.department_id
      ) || null
    : selectData?.company_positions.data?.find(
      (opt) => opt?.id === input.position_id
    ) || null;

  return (
    <MainModal
      isOpen={props.posDepModal.isOpen}
      size="lg"
      modalTitle={t("Bulk Actions")}
      btnSubmitLoading={submitLoading}
      btnOnClick={handleSave}
      toggle={handleCancle}
      className="p-5 "
    >
      <div className="p-2">
        <Alert severity="info">
                <AlertTitle>
                  {props.posDepModal.isDepartment
                  ? t("changing the department")
                  : t("changing the position")}
                </AlertTitle>
                <strong>{t("Employees will be effected", { count: props.selectedEmployees.length })}</strong>
          </Alert>
      </div>

      <div className={"h-80px p-2 "+(valid.isValid? "" : "invalid-container-style")}>
          <label
            className={"select-def-label mb-2 validity-label-style"}
          >
            {props.posDepModal.isDepartment? t("departments") : t("positions") }
          </label>
          <form action="">
        <Select
          className={"select-def-input-containe b-select-style flex-grow-1 px-0"}
          classNamePrefix={"b-select-style"}
          value={selectValue}
          onChange={handleSelect}
          getOptionLabel={(opt) => opt?.name}
          getOptionValue={(opt) => opt?.id}
          isLoading={selectLoading}
          isClearable={true}
          isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
          isSearchable
          placeholder={props.posDepModal.isDepartment? t("select department"):t("select position")}
          options={selectData?.company_positions?.data || selectData?.company_departments?.data || []}
        /></form>
        <div className="validity-msg-style text-left">
          {t(valid.msg)}
        </div>
      </div>
        <p className="text-secondary mt-4 px-2">
          {props.posDepModal.isDepartment ? 
          t("Please note that when changing departments, the assignments applied on the previous department will no longer be applied on these employees and the assignments of the new department will be applied on the employees") 
          : 
          null}
        </p>
    </MainModal>
  );
};

export default PosDepModal;