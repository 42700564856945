import React from "react";
import { useQuery } from "@apollo/client";

const useCustomQuery = (query, customOptions = {}) => {
  const { isOnErrorRefetch = true, ...options } = customOptions;

  const results = useQuery(query, {
    ...options,
    onCompleted: (data) => {
      if (!results?.error) {
        options?.onCompleted && options?.onCompleted(data);
      } else {
        options?.onError && options?.onError(results?.error);
        isOnErrorRefetch && results?.refetch();
      }
    },
  });

  return results;
};

export default useCustomQuery;
