import React from "react";
import { BSelect, InputForm, DateTimePickerForm } from "form-builder";
import { useSelector, useDispatch } from "react-redux";
import {
  addOverTimeSettingAction,
  childInputChange,
  removeOverTimeSettingAction,
  resetOvertimeSettingsAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import PaymentFactors from "../../Constants/PaymentFactors";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { numbersRegex } from "../../Constants";

const childName = "salary_calculations";

const OvertimeCalculation = ({
  i,
  FormProps,
  calculation,
  totalGross,
  totalNet,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const salaryConfigForm = useSelector((state) => state.super.salaryConfigForm);
  const paymentFactors = useSelector((state) => state.super.paymentFactors);
  const salaryConfigFormValidation = useSelector(
    (state) => state.super?.[FormProps?.formServerValidation]
  );

  const renderCalcValue = (calculation, overtime_factor) => {
    let base = +salaryConfigForm?.base_salary || 0;
    let gross = totalGross();
    let net = totalNet();
    if (salaryConfigForm?.calculate_salary_by == "hourly") {
      return "";
    }
    switch (calculation.payment_factor_id) {
      case PaymentFactors.BASE_PERCENTAGE:
        return (
          "=" + ((base / +calculation.value) * overtime_factor).toFixed(2) || 0
        );
      case PaymentFactors.GROSS_PERCENTAGE:
        return (
          "=" + ((gross / +calculation.value) * overtime_factor).toFixed(2) || 0
        );
      case PaymentFactors.NET_PERCENTAGE:
        return (
          "=" + ((net / +calculation.value) * overtime_factor).toFixed(2) || 0
        );
      default:
        return "=" + (calculation.value * overtime_factor).toFixed(2) || 0;
    }
  };

  const handleSelectInputChange = (val, event, childName, index) => {
    dispatch(
      childInputChange(
        FormProps.formName,
        childName,
        event.name,
        index,
        val ? val?.value : ""
      )
    );
  };

  const handleChildInputChange = (event, childName, index) => {
    dispatch(
      childInputChange(
        FormProps.formName,
        childName,
        event.target.name,
        index,
        event.target.value
      )
    );
  };

  const handleChildDateInputChange = (value, name, index) => {
    let childName = "overtimeSettings";
    if (moment(value).isValid()) {
      dispatch(
        childInputChange(
          FormProps.formName,
          childName,
          name,
          index,
          moment(value).format("HH:mm")
        )
      );
    } else {
      dispatch(
        childInputChange(FormProps.formName, childName, name, index, null)
      );
    }
  };

  const handleAddOvertimeSettingInput = () => {
    dispatch(addOverTimeSettingAction());
  };
  const handleDeleteOvertimeSettingInput = (index) => {
    dispatch(removeOverTimeSettingAction(index));
  };

  const resetOvertimeSettings = () => {
    dispatch(resetOvertimeSettingsAction());
  };

  const handleAdvancedOvertime = () => {
    if (salaryConfigForm?.overtimeSettings?.length > 1) {
      resetOvertimeSettings();
    } else {
      handleAddOvertimeSettingInput();
    }
  };

  if (
    salaryConfigForm?.calculate_all_work_hours &&
    salaryConfigForm?.calculate_salary_by == "hourly"
  ) {
    return null;
  }

  return (
    <>
      <div className="d-flex flex-column flex-fill order-last">
        <div className="d-flex flex-fill flex-row align-items-end">
          <div
            className={`col p-0 ${
              salaryConfigForm?.calculate_salary_by == "hourly" ? "d-none" : ""
            }`}
          >
            <BSelect
              {...FormProps}
              label="Overtime Hour"
              labelStyle="font-weight-bold mb-0"
              name="payment_factor_id"
              optionLabel="label"
              optionValue="value"
              options={paymentFactors}
              keepDefaultStyle
              containerStyle="my-0"
              placeholder={t("Select Payment Factor")}
              inputContainerStyle="w-100"
              validateBy="textRequired"
              optDependType="equal"
              optDependKey="calculation"
              optDependValue={["1", 1]}
              value={paymentFactors?.find(
                (opt) => opt.value == calculation?.payment_factor_id
              )}
              onChange={(e, val) =>
                handleSelectInputChange(e, val, "salary_calculations", i)
              }
              validationName={`input.user_salary_config_input.salary_calculations.${i}.payment_factor_id`}
              isDisabled={
                salaryConfigForm?.calculate_salary_by !== "payment_interval"
              }
              icon="money"
            />
          </div>
          <div className="col-8">
            <div className="d-flex align-items-end">
              {salaryConfigForm?.calculate_salary_by == "hourly" ? (
                <label className="font-weight-bold mb-0 mr-2 my-2">
                  {t("Overtime Hour")}
                </label>
              ) : (
                <>
                  {calculation.payment_factor_id !==
                  PaymentFactors.FIXED_AMOUNT ? (
                    <span className="min-w-85">
                      {calculation.payment_factor_id ==
                      PaymentFactors.BASE_PERCENTAGE
                        ? `${t("base_salary")} /`
                        : calculation.payment_factor_id ==
                          PaymentFactors.GROSS_PERCENTAGE
                        ? `${t("gross_salary")} /`
                        : `${t("net_salary")} /`}
                    </span>
                  ) : null}
                  <div className="col px-0">
                    <InputForm
                      name="value"
                      type="number"
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                      {...FormProps}
                      label={
                        calculation.payment_factor_id ==
                        PaymentFactors.FIXED_AMOUNT
                          ? t("amount")
                          : null
                      }
                      placeholder={
                        calculation.payment_factor_id ==
                        PaymentFactors.FIXED_AMOUNT
                          ? t("amount")
                          : t("month hours")
                      }
                      labelStyle="mb-0"
                      containerStyle=""
                      inputContainerStyle="overtime_hours_wrapper_input_style w-100"
                      validateBy="textRequired"
                      onChange={(e) => handleChildInputChange(e, childName, i)}
                      value={calculation?.value}
                      validationName={`input.user_salary_config_input.salary_calculations.${i}.value`}
                      icon={
                        calculation.payment_factor_id ==
                        PaymentFactors.FIXED_AMOUNT
                          ? "money"
                          : null
                      }
                    />
                  </div>
                </>
              )}
              {salaryConfigForm?.overtimeSettings?.length > 1 ? null : (
                <>
                  <div className="col px-0 mx-2">
                    <InputForm
                      name="overtime_payment_factor"
                      type="text"
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                      {...FormProps}
                      placeholder={t("overtime payment factor")}
                      label={t("factor")}
                      labelStyle="mb-0"
                      containerStyle=""
                      inputContainerStyle="w-100"
                      validateBy="textRequired"
                      value={
                        salaryConfigForm?.overtimeSettings?.[0]
                          ?.overtime_payment_factor
                      }
                      onChange={(e) => {
                        if (
                          e.target.value != "" &&
                          !numbersRegex.test(e.target.value)
                        ) {
                          return;
                        }
                        handleChildInputChange(e, "overtimeSettings", 0);
                      }}
                      validationName={`input.user_salary_config_input.salary_calculations.${0}.overtimeSettings.overtime_payment_factor`}
                    />
                  </div>
                  <div>
                    <span className="font-weight-bold mx-2">
                      {renderCalcValue(
                        calculation,
                        salaryConfigForm?.overtimeSettings?.[0]
                          ?.overtime_payment_factor
                      )}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <label
          className={`col fit-width ${
            salaryConfigForm?.overtimeSettings?.length > 1
              ? "blue-color"
              : "gray-color"
          } h5 my-2`}
          role="button"
          onClick={handleAdvancedOvertime}
        >
          {t("Advanced Overtime Settings")}
        </label>

        {salaryConfigForm?.overtimeSettings?.length > 1 ? (
          <>
            <div className="mx-3 mt-1">
              <div className="d-flex align-items-baseline">
                <div className="mr-3 mb-2">
                  <p className="dark-color fa-1x h5">
                    {t("Overtime Hour Factor Time Range")}
                  </p>
                </div>

                <div>
                  <button
                    className="btn add_new_btn_style bg-dark"
                    type="button"
                    onClick={handleAddOvertimeSettingInput}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
              {salaryConfigForm?.overtimeSettings?.map(
                (overtimeSetting, index, data) => {
                  // const fromIndex = index == 0 ? index : index - 1;
                  const fromKey = data[index - 1]?.to;
                  return (
                    <div className="row align-items-center">
                      <div className="col">
                        <InputForm
                          name="overtime_payment_factor"
                          type="text"
                          onWheelCapture={(e) => {
                            e.target.blur();
                          }}
                          {...FormProps}
                          placeholder={t("overtime payment factor")}
                          labelStyle="mb-0"
                          containerStyle=""
                          inputContainerStyle="w-100"
                          validateBy="textRequired"
                          value={overtimeSetting?.overtime_payment_factor}
                          onChange={(e) => {
                            if (
                              e.target.value != "" &&
                              !numbersRegex.test(e.target.value)
                            ) {
                              return;
                            }
                            handleChildInputChange(
                              e,
                              "overtimeSettings",
                              index
                            );
                          }}
                          validationName={`input.user_salary_config_input.salary_calculations.${index}.overtimeSettings.overtime_payment_factor`}
                        />
                      </div>
                      <div className="align-items-center col row">
                        {index == 0 ? (
                          <div className="w-185">
                            <DateTimePickerForm
                              timeOnly
                              requestFormat="HH:mm"
                              {...FormProps}
                              name={"from"}
                              labelStyle="mx-1"
                              label="from"
                              onChange={(val) =>
                                handleChildDateInputChange(val, "from", index)
                              }
                              onSelect={(val) =>
                                handleChildDateInputChange(val, "from", index)
                              }
                              value={
                                Boolean(overtimeSetting?.from)
                                  ? moment(overtimeSetting?.from, "HH:mm")
                                  : null
                              }
                              validationName={`input.user_salary_config_input.salary_calculations.${index}.overtimeSettings.from`}
                            />
                          </div>
                        ) : (
                          <div className="w-185">
                            <DateTimePickerForm
                              timeOnly
                              requestFormat="HH:mm"
                              {...FormProps}
                              name="to"
                              labelStyle="mx-1"
                              label="from"
                              validationName={`input.user_salary_config_input.salary_calculations.${
                                index - 1
                              }.overtimeSettings.to`}
                              onChange={(val) =>
                                handleChildDateInputChange(val, "to", index - 1)
                              }
                              onSelect={(val) =>
                                handleChildDateInputChange(val, "to", index - 1)
                              }
                              value={
                                Boolean(fromKey)
                                  ? moment(fromKey, "HH:mm")
                                  : null
                              }
                            />
                          </div>
                        )}

                        <div className="w-185">
                          <DateTimePickerForm
                            timeOnly
                            requestFormat="HH:mm"
                            {...FormProps}
                            name="to"
                            labelStyle="mx-1"
                            label="to"
                            validationName={`input.user_salary_config_input.salary_calculations.${index}.overtimeSettings.to`}
                            onChange={(val) =>
                              handleChildDateInputChange(val, "to", index)
                            }
                            onSelect={(val) =>
                              handleChildDateInputChange(val, "to", index)
                            }
                            value={
                              Boolean(overtimeSetting?.to)
                                ? moment(overtimeSetting?.to, "HH:mm")
                                : null
                            }
                          />
                        </div>

                        <span className="font-weight-bold mx-2">
                          {renderCalcValue(
                            calculation,
                            overtimeSetting?.overtime_payment_factor
                          )}
                        </span>

                        {salaryConfigForm?.overtimeSettings?.length > 1 ? (
                          <div className="mx-1">
                            <button
                              className="btn delete_btn_style"
                              type="button"
                              onClick={() =>
                                handleDeleteOvertimeSettingInput(index)
                              }
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </>
        ) : null}
        {salaryConfigFormValidation?.[
          `input.user_salary_config_input.salary_calculations.${i}.overtimeSettings`
        ] ? (
          <span className="error-color">
            {
              salaryConfigFormValidation?.[
                `input.user_salary_config_input.salary_calculations.${i}.overtimeSettings`
              ]
            }
          </span>
        ) : null}
      </div>
    </>
  );
};

export default OvertimeCalculation;
