import React from "react";
import MyLoans from "../../Containers/MyLoans";

const MyLoansTab = (props) => {
  return (
    <>
      <MyLoans />
    </>
  );
};

export default MyLoansTab;
