import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchAssignmentsAttempt,
  deleteAssignmentAttempt,
  changeAssignmentDateAction,
} from "../../Store/Actions";
import moment from "moment";
import swal from "sweetalert";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { fetchAssignmentFormAction } from "../../Store/Actions";
import { addEditDeleteAssignmentPrivileges } from "../../Constants";

import { BSelect } from "../../Builder/Form";
import Loader from "../../Components/Loader";
import AssignmentCard from "./AssignmentCard";
import AssignmentPreview from "./AssignmentPreview";
import { AddButton } from "../../Components/Buttons";
import ChangeAssignmentModal from "./ChangeAssignmentModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import UpsertAssignmentModal from "./UpsertAssignmentModal";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import IconButtonWithTooltip from "../../Components/IconButtonWithTooltip";
import AssignmentCards from "./AssignmentCards";

const modalInitState = { isOpen: false, data: null };
const formFilterProps = {
  formName: "assignmentFilters",
  reducer: "assignments",
};

const generateWeekDays = (start) => {
  let week = [];
  for (let i = 0; i < 7; i++) {
    week.push({
      day: moment(start, "DD-MM-YYYY").add(i, "days").format("dddd"),
      date: moment(start, "DD-MM-YYYY").add(i, "days").format("DD-MM-YYYY"),
    });
  }
  return week;
};

const Assignments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [changeAssignmentModal, setChangeAssignmentModal] =
    React.useState(modalInitState);

  // Reducer State

  const employees = useSelector(
    (state) => state.assignments.assignmentFilters?.employees
  );

  const work_timings = useSelector(
    (state) => state.assignments.assignmentFilters?.work_timings
  );

  const locations = useSelector(
    (state) => state.assignments.assignmentFilters?.locations
  );

  const assignmentFrom = useSelector(
    (state) => state.assignments.assignmentFilters?.from
  );

  const assignments = useSelector((state) => state.assignments?.assignments);

  const fetchAssignmentsLoading = useSelector(
    (state) => state.assignments?.fetchAssignmentsLoading
  );

  const deleteAssignmentLoading = useSelector(
    (state) => state.assignments?.deleteAssignmentLoading
  );

  const options = useSelector(
    (state) => state.assignments?.assignmentFiltersData
  );

  const upsertAssignmentModal = useSelector(
    (state) => state.assignments.upsertAssignmentModal
  );

  const assignmentPreview = useSelector(
    (state) => state.assignments.assignmentPreview
  );

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    // if (assignmentFrom) {
    dispatch(
      fetchAssignmentsAttempt({
        from: moment(assignmentFrom, "DD-MM-YYYY").format("YYYY-MM-DD"),
        to: moment(assignmentFrom, "DD-MM-YYYY")
          .add(6, "days")
          .format("YYYY-MM-DD"),
        employeeIds: employees.map((employee) => +employee),
        locationIds: locations.map((location) => +location),
        workTimingsIds: work_timings.map((workTiming) => +workTiming),
      })
    );
    // }
  }, [assignmentFrom, employees, locations, work_timings]);

  /* ↓ Helpers ↓ */

  const handleAddNewAssignmentButton = () => {
    // dispatch(toggleModalDisplayAction(true));
    dispatch(fetchAssignmentFormAction({ includeInputs: true }));
  };

  const handleOpenChangeAssignmentModal = (data) => {
    setChangeAssignmentModal({ isOpen: true, data });
  };

  const handleEdit = (id, isChangeShifts) => {
    isChangeShifts
      ? handleOpenChangeAssignmentModal({ isEdit: true, id })
      : dispatch(
          fetchAssignmentFormAction({
            includeInputs: true,
            includeAssignment: true,
            assignmentId: id,
          })
        );
  };

  const handleDelete = (id) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteAssignmentAttempt(id));
      }
    });
  };

  const handlePrevButton = () => {
    let newDate = moment(assignmentFrom, "DD-MM-YYYY")
      .subtract(7, "days")
      .format("DD-MM-YYYY");
    dispatch(changeAssignmentDateAction(newDate));
  };

  const handleNextButton = () => {
    let newDate = moment(assignmentFrom, "DD-MM-YYYY")
      .add(7, "days")
      .format("DD-MM-YYYY");
    dispatch(changeAssignmentDateAction(newDate));
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-10">
        <BSelect
          {...formFilterProps}
          name="employees"
          label="employees"
          placeholder={t("select employee")}
          rootStyle="w-100"
          options={HelperFns.filterUsersBy(
            "active",
            options?.users_by_role?.data
          )}
          isMulti
          icon="person"
        />
        <BSelect
          {...formFilterProps}
          name="work_timings"
          label="work timings"
          placeholder={t("select work timing")}
          rootStyle="w-100"
          options={options?.work_timings?.data}
          isMulti
          icon="calendar"
        />
        <BSelect
          {...formFilterProps}
          name="locations"
          label="locations"
          placeholder={t("select location")}
          rootStyle="w-100"
          containerStyle=" "
          options={options?.locations?.data}
          isMulti
          icon="location"
        />
      </div>

      {/* Actions */}
      <div className="d-flex justify-content-end gap-10 mt-3">
        <HasPrivileges allowBP reqireMain={[Privilages.CHANGE_SHIFT]}>
          <AddButton
            prefix="change"
            label="shifts"
            variant="outlined"
            onClick={handleOpenChangeAssignmentModal}
          />
        </HasPrivileges>
        <HasPrivileges allowBP reqireMain={addEditDeleteAssignmentPrivileges}>
          <AddButton onClick={handleAddNewAssignmentButton} />
        </HasPrivileges>
      </div>

      {/* Table */}
      <div className="assignments-table-container">
        {deleteAssignmentLoading ||
        fetchAssignmentsLoading ||
        assignmentPreview.isLoading ||
        upsertAssignmentModal.isLoading ? (
          <div className="assignments-loader">
            <Loader />
          </div>
        ) : (
          ""
        )}

        <div className="assignments-main-header">
          <IconButtonWithTooltip
            label="previous week"
            onClick={handlePrevButton}
            buttonProps={{ size: "medium" }}
            icon={<ChevronLeft fontSize="large" style={{ color: "black" }} />}
          />
          <div className="assignments-main-header-date">
            <span className="calendar-date-text">
              {HelperFns.getDayOfTheWeek(
                assignmentFrom,
                "first",
                "dddd DD-MM-YYYY"
              )}
            </span>
            <span className="mx-3">-</span>
            <span className="calendar-date-text">
              {HelperFns.getDayOfTheWeek(
                assignmentFrom,
                "last",
                "dddd DD-MM-YYYY"
              )}
            </span>
          </div>
          <IconButtonWithTooltip
            label="next week"
            onClick={handleNextButton}
            buttonProps={{ size: "medium" }}
            icon={<ChevronRight fontSize="large" style={{ color: "black" }} />}
          />
        </div>

        <div className="w-100 overflow-auto">
          <div className="assignemnt-days-container">
            <div className="assignments-table-header mb-2">
              {generateWeekDays(assignmentFrom)?.map((date, i) => (
                <div key={i} className="assignment-day">
                  <span className="assignment-header-day mb-2">{date.day}</span>
                  <span className="assignment-header-date">{date.date}</span>
                </div>
              ))}
            </div>
            <div className="assignments-table-row">
              {assignments?.length ? (
                <AssignmentCards
                  assignments={assignments}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              ) : (
                <span style={{ gridColumnStart: 4 }}>
                  {t("no data available")}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      {assignmentPreview?.isOpen ? (
        <AssignmentPreview onEdit={handleEdit} onDelete={handleDelete} />
      ) : null}
      {upsertAssignmentModal?.isVissible ? (
        <UpsertAssignmentModal isAssignemntsList />
      ) : null}
      {changeAssignmentModal?.isOpen ? (
        <ChangeAssignmentModal
          data={changeAssignmentModal?.data}
          onClose={() => setChangeAssignmentModal(false)}
        />
      ) : null}
    </>
  );
};

export default Assignments;
