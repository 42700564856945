import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useCustomQuery from "../../Helpers/Hooks/useCustomQuery";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import { assignLeadsFormQuery } from "../../Graphql/query";
import { assignLeadsMutation } from "../../Graphql/mutation";

import MainModal from "../MainModal";
import { BSelect } from "form-builder";

const formNameValidation = "assignLeadsClientValidation";

const AssignLeadsModal = ({ data, selectedRows, onClose, refetchList, handleUnselectRows }) => {
  const { t } = useTranslation();

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [options, setOptions] = useState({ agents: [], leads: [] });
  const [formData, setFormData] = useState({
    agent: null,
    leads: selectedRows.length ? selectedRows : [],
  });

  // Server State
  const [assign, { loading: isAssignLoading }] =
    useCustomMutation(assignLeadsMutation);
  const { loading: isOptionsLoading } = useCustomQuery(assignLeadsFormQuery, {
    onCompleted: ({ leads, users }) => {
      setOptions({
        leads: leads?.data || [],
        agents: users?.data || [],
      });
    },
  });

  // Reducer State
  const assignLeadsClientValidation = useSelector(
    (state) => state.crm[formNameValidation]
  );

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (data) {
      setFormData((prev) => ({ ...prev, leads: [...prev.leads, data] }));
    }
  }, [Boolean(data)]);

  /* ↓ Helpers ↓ */

  const handleChange = (val, { action, name, ...rest }) => {
    if (action === "clear") {
      setFormData((prev) => ({ ...prev, [name]: [] }));
    } else {
      const isRemove = action.includes("remove");
      setFormData((prev) => ({
        ...prev,
        [name]: isRemove
          ? prev[name].filter((p) => p.id !== rest.removedValue.id)
          : val,
      }));
    }
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (assignLeadsClientValidation.length) return;

    assign({
      refetchList,
      onCloseModal: onClose,
      variables: {
        user_id: +formData.agent.id,
        lead_ids: formData.leads.map(({ id }) => +id),
      },
      onCompleted: () => {
        handleUnselectRows(formData.leads)
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      btnSubmitLoading={isAssignLoading}
      modalTitle={t("assign leads to agents")}
    >
      <BSelect
        name="agent"
        icon="employees"
        label="assign to"
        value={formData.agent}
        onChange={handleChange}
        options={options.agents}
        isLoading={isOptionsLoading}
        rootStyle="mb-4"
        validateBy="textRequired"
        formSubmitting={formSubmitting}
        formNameValidation={formNameValidation}
      />
      <BSelect
        isMulti
        name="leads"
        label="leads"
        icon="employees"
        value={formData.leads}
        onChange={handleChange}
        options={options.leads}
        isLoading={isOptionsLoading}
        validateBy="textRequired"
        formSubmitting={formSubmitting}
        formNameValidation={formNameValidation}
      />
    </MainModal>
  );
};

export default AssignLeadsModal;
