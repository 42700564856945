import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Constants from "../../Helpers/Constants";
import { BSelect } from "form-builder";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import ExportModal from "./ExportModal";
import {
  fetchYearlyReportListAction,
  onInputResetAction,
  showExportYearlyReportModal,
} from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";

import ToggleModal from "./ToggleModal";

import { useTranslation } from "react-i18next";
import { AgGridTable } from "../../Components/AgGridTable";
import HelperFns from "../../Helpers/HelperFns";
import { BarsIconButton } from "../../Components/IconButtonWithTooltip";

const dataTableRef = "yearlyReport";
const YearlyReport = () => {
  const [isToggleModalVissible, setIsToggleModalVissible] = useState(false);
  const gridRef = useRef();

  // Redux Hooks
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.userProfile);
  const companyDepartments = useSelector(
    (state) => state.super.allDepartmentsByCompany
  );
  const extraEmployeesFilter = useSelector(
    (state) => state.super.extraEmployeesFilter
  );
  const tableState = useSelector((state) => state.super[dataTableRef]);
  const isColumnActive = useSelector(
    (state) => state.monthlyReport.yearlyReportColumns
  );

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      fetchYearlyReportListAction({
        ref: dataTableRef,
        [dataTableRef]: tableState,
      })
    );

    return () => {
      dispatch(onInputResetAction(dataTableRef, "year"));
      dispatch(onInputResetAction(dataTableRef, "user_id"));
      dispatch(onInputResetAction(dataTableRef, "office_id"));
      dispatch(onInputResetAction(dataTableRef, "department_id"));
    };
  }, []);

  const handleFilter = () => {
    dispatch(
      fetchYearlyReportListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...tableState,
          pagination: {
            ...tableState?.pagination,
            currentPage: 1,
          },
        },
      })
    );
  };

  const handleFilterOffice = () => {
    if (tableState?.selectedUserId) {
      dispatch(onInputResetAction(dataTableRef, "user_id"));
    } else {
      dispatch(
        fetchYearlyReportListAction({
          ref: dataTableRef,
          [dataTableRef]: {
            ...tableState,
            pagination: {
              ...tableState?.pagination,
              currentPage: 1,
            },
          },
        })
      );
    }
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    tableState?.year,
    tableState?.user_id,
    tableState?.department_id,
  ]);

  const useDidOfficeFilterUpdate = useDidUpdateEffect(handleFilterOffice, [
    tableState?.office_id,
  ]);

  const handlePaginate = (page = tableState?.pagination?.currentPage) => {
    dispatch(
      fetchYearlyReportListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...tableState,
          pagination: {
            ...tableState?.pagination,
            currentPage: page,
          },
        },
      })
    );
  };

  const columnDefs = useMemo(() => {
    return [
      // {
      //   headerValueGetter: () => t("name"),
      //   field: "name",
      //   minWidth: 220,
      //   pinned: user?.lng === "ar-EG" ? "right" : "left",
      //   lockPinned: true,
      //   valueFormatter: (cell) => (cell.value ? cell.value : null),
      // },
      {
        headerValueGetter: () =>
          isColumnActive?.emp_code ? t("employee") : null,
        pinned: user?.lng === "ar-EG" ? "right" : "left",
        hide: true,
        children: [
          {
            headerValueGetter: () => t("name"),
            field: "name",
            minWidth: 220,
            valueFormatter: (cell) => (cell.value ? cell.value : null),
            pinned: "left",
          },
          {
            headerValueGetter: () => t("employee code"),
            field: "emp_code",
            minWidth: 100,
            hide: isColumnActive?.emp_code ? false : true,
            valueFormatter: (cell) => (cell.value ? cell.value : "---"),
          },
        ],
      },
      {
        headerValueGetter: () => t("office"),
        field: "office",
        minWidth: 200,
        hide: isColumnActive?.office ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },
      {
        headerValueGetter: () => t("department"),
        field: "department",
        minWidth: 200,
        hide: isColumnActive?.department ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },
      {
        headerValueGetter: () => t("position"),
        field: "position",
        minWidth: 200,
        hide: isColumnActive?.position ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : null),
      },
      {
        headerValueGetter: () => t("holidays"),
        field: "holidays",
        hide: isColumnActive?.holidays ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("Days Off"),
        field: "weekends",
        hide: isColumnActive?.weekends ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("workdays"),
        hide: isColumnActive?.attended_days ? false : true,
        headerClass: "workdays_header_group",
        children: [
          {
            headerValueGetter: () => t("attended"),
            field: "attended_days",
            hide: isColumnActive?.attended_days ? false : true,
          },
          {
            headerValueGetter: () => t("ontime"),
            headerClass: "ontime_header_group",
            hide:
              isColumnActive?.attended_days && isColumnActive?.ontime
                ? false
                : true,
            children: [
              {
                headerValueGetter: () => t("total"),
                field: "ontime_total",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_total
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("normal"),
                field: "ontime_normal",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_normal
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("half-days"),
                field: "ontime_half",
                hide:
                  isColumnActive?.ontime && isColumnActive?.ontime_half
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
            ],
          },
          {
            headerValueGetter: () => t("late"),
            headerClass: "late_header_group",
            hide:
              isColumnActive?.attended_days && isColumnActive?.late
                ? false
                : true,
            children: [
              {
                headerValueGetter: () => t("total"),
                field: "late_total",
                hide:
                  isColumnActive?.late && isColumnActive?.late_total
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("normal"),
                field: "late_normal",
                hide:
                  isColumnActive?.late && isColumnActive?.late_normal
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("half-days"),
                field: "late_half",
                hide:
                  isColumnActive?.late && isColumnActive?.late_half
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
              {
                headerValueGetter: () => t("duration"),
                field: "late_duration",
                hide:
                  isColumnActive?.late && isColumnActive?.late_duration
                    ? false
                    : true,
                valueFormatter: (cell) => (cell.value ? cell.value : 0),
              },
            ],
          },
        ],
      },
      {
        headerValueGetter: () => t("absent"),
        field: "absent_total",
        hide: isColumnActive?.absent_total ? false : true,
        valueFormatter: (cell) => (cell.value ? cell.value : 0),
      },
      {
        headerValueGetter: () => t("leaves"),
        headerClass: "leaves_header_group",
        hide:
          isColumnActive?.leaves &&
          (isColumnActive?.normal_leaves ||
            isColumnActive?.emergency_leaves ||
            isColumnActive?.sick_leaves ||
            isColumnActive?.unpaid_leaves ||
            isColumnActive?.half_days)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("normal"),
            field: "normal_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.normal_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("emergency"),
            field: "emergency_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.emergency_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("sick"),
            field: "sick_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.sick_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("unpaid"),
            field: "unpaid_leaves",
            hide:
              isColumnActive?.leaves && isColumnActive?.unpaid_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("half-days"),
            field: "half_days",
            hide:
              isColumnActive?.leaves && isColumnActive?.half_days
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("remaining leaves"),
        headerClass: "remaining_leaves_header_group",
        hide: isColumnActive?.remaningLeaves ? false : true,
        children: [
          {
            headerValueGetter: () => t("annual"),
            minWidth: 120,
            field: "remaining_annual_leaves",
            hide:
              isColumnActive?.remaningLeaves &&
              isColumnActive?.remaining_annual_leaves
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("emergency"),
            minWidth: 120,
            field: "remaining_emergency_credit",
            hide:
              isColumnActive?.remaningLeaves &&
              isColumnActive?.remaining_emergency_credit
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("Check-in Hours"),
        headerClass: "checkin_header_group",
        hide: isColumnActive?.total_checkIn_hours_secs ? false : true,
        children: [
          {
            headerValueGetter: () => t("duration"),
            minWidth: 120,
            field: "total_checkIn_hours_secs",
            hide: isColumnActive?.total_checkIn_hours_secs ? false : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
        ],
      },
      {
        headerValueGetter: () => t("overtime"),
        headerClass: "overtime_header_group",
        hide:
          isColumnActive?.overtime &&
          (isColumnActive?.overtime_days || isColumnActive?.overtime_hours)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("days"),
            field: "overtime_days",
            hide:
              isColumnActive?.overtime && isColumnActive?.overtime_days
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("duration"),
            field: "overtime_hours",
            hide:
              isColumnActive?.overtime && isColumnActive?.overtime_hours
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("permissions"),
        headerClass: "permissions_header_group",
        hide:
          isColumnActive?.permissionsColumn &&
          (isColumnActive?.permissions || isColumnActive?.permissions_time)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("permissions"),
            field: "permissions",
            hide:
              isColumnActive?.permissionsColumn && isColumnActive?.permissions
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("duration"),
            field: "permissions_time",
            hide:
              isColumnActive?.permissionsColumn &&
              isColumnActive?.permissions_time
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("breaks"),
        headerClass: "breaks_header_group",
        hide:
          isColumnActive?.breaksColumn &&
          (isColumnActive?.breaks || isColumnActive?.breaks_time)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("number"),
            field: "breaks",
            hide:
              isColumnActive?.breaksColumn && isColumnActive?.breaks
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
          {
            headerValueGetter: () => t("duration"),
            field: "breaks_time",
            hide:
              isColumnActive?.breaksColumn && isColumnActive?.breaks_time
                ? false
                : true,
            valueFormatter: (cell) => (cell.value ? cell.value : 0),
          },
        ],
      },
      {
        headerValueGetter: () => t("penalties"),
        headerClass: "work_hours_header_group",
        hide:
          !isColumnActive?.total_early_sign_out_time &&
          !isColumnActive?.total_penalties_as_days &&
          !isColumnActive?.total_penalties_as_fixed_amount &&
          !isColumnActive?.total_penalties_as_hours,
        children: [
          {
            headerValueGetter: () => t("total early sign out time"),
            field: "total_early_sign_out_sec",
            width: 160,
            hide: isColumnActive?.total_early_sign_out_time ? false : true,
          },
          {
            headerValueGetter: () => t("total penalties as days"),
            field: "total_penalties_as_days",
            width: 160,
            hide: isColumnActive?.total_penalties_as_days ? false : true,
          },
          {
            headerValueGetter: () => t("total penalties as fixed amount"),
            field: "total_penalties_as_fixed_amount",
            width: 160,
            hide: isColumnActive?.total_penalties_as_fixed_amount
              ? false
              : true,
          },
          {
            headerValueGetter: () => t("total penalties as hours"),
            field: "total_penalties_as_hours",
            width: 160,
            hide: isColumnActive?.total_penalties_as_hours ? false : true,
          },
        ],
      },
      {
        headerValueGetter: () => t("work hours"),
        headerClass: "work_hours_header_group",
        hide:
          isColumnActive?.workHours &&
          (isColumnActive?.total_hours_secs ||
            isColumnActive?.expected_hours_secs)
            ? false
            : true,
        children: [
          {
            headerValueGetter: () => t("total hrs worked"),
            field: "total_hours_secs",
            width: 120,
            hide:
              isColumnActive?.workHours && isColumnActive?.total_hours_secs
                ? false
                : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
          {
            headerValueGetter: () => t("expected work hrs"),
            field: "expected_hours_secs",
            width: 120,
            hide:
              isColumnActive?.workHours && isColumnActive?.expected_hours_secs
                ? false
                : true,
            valueFormatter: (cell) => HelperFns.secToTime(cell.value),
          },
        ],
      },
      {
        headerValueGetter: () => t("total overtime hours"),
        field: "total_over_time_hours",
        width: 160,
        hide: isColumnActive?.total_over_time_hours ? false : true,
      },
    ];
  }, [tableState, isColumnActive, user?.lng, t]);

  useEffect(() => {
    gridRef?.current?.api?.sizeColumnsToFit({
      defaultMinWidth: 110,
    });
  }, [tableState, isColumnActive, user?.lng, t]);

  return (
    <div className="content payroll_wrapper_style yearly-report-style no-padding-first-child position-relative">
      <div className="d-lg-flex align-items-center mb-3 mx-0">
        <div className="col pl-0 mr-5 d-lg-flex pr-0">
          <div className="w-100 mr-lg-4">
            <BSelect
              formName={dataTableRef}
              name="office_id"
              optionLabel="name"
              optionValue="id"
              options={user?.offices}
              keepDefaultStyle
              isClearable
              placeholder={t("all offices")}
              icon="office"
            />
          </div>
          <div className="w-100 mr-lg-4">
            <BSelect
              formName={dataTableRef}
              name="department_id"
              optionLabel="name"
              optionValue="id"
              options={companyDepartments}
              keepDefaultStyle
              isClearable
              placeholder={t("all departments")}
              icon="department"
            />
          </div>
          <div className="w-100 mr-lg-4">
            <BSelect
              reducer="super"
              name="user_id"
              formName={dataTableRef}
              options={extraEmployeesFilter}
              keepDefaultStyle
              optionLabel="name"
              optionValue="id"
              isClearable
              placeholder={t("select_employee")}
              icon="person"
            />
          </div>
          <div className="w-100 mr-lg-4">
            <BSelect
              name="year"
              optionLabel="value"
              optionValue="value"
              options={Constants.YearData}
              formName={dataTableRef}
              keepDefaultStyle
              containerStyle="year-picker my-1"
              icon="calendar"
            />
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end">
          <HasPrivileges
            reqireMain={[Privilages.EXPORT_ATTENDANCE_MONTHLY_REPORT]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <button className="btn py-0 yearly-export mx-2" type="button">
                <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                {t("export")}
              </button>
            }
          >
            <button
              className="btn py-0 yearly-export mx-2"
              type="button"
              onClick={() =>
                dispatch(
                  showExportYearlyReportModal({
                    ref: "exportYearlyReportModal",
                  })
                )
              }
            >
              <FontAwesomeIcon className="mr-2" icon={faFileExport} />
              {t("export")}
            </button>
          </HasPrivileges>

          <BarsIconButton onClick={() => setIsToggleModalVissible(true)} />
        </div>
      </div>

      {tableState?.isLoading ? (
        <div className="loader_wrapper_style position-absolute">
          <Loader />
        </div>
      ) : null}

      <AgGridTable
        key={user?.lng}
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={tableState?.data}
        className="yearlyreport-table"
        enableSorting={true}
        tableFetcherAction={fetchYearlyReportListAction}
        dataTableRef={dataTableRef}
        tableState={tableState}
        gridOptions={{
          enableRtl: user?.lng === "ar-EG" ? true : false,
        }}
      />

      <Pagination
        tableRef={dataTableRef}
        styleWraper=""
        onPaginate={handlePaginate}
        reducer="super"
      />

      {/* Start of Toggled Modal */}
      <ToggleModal
        isModalVissible={isToggleModalVissible}
        toggleModal={() => setIsToggleModalVissible(!isToggleModalVissible)}
      />
      {/* End of Toggled Modal  */}

      {/* Start of Export Modal */}
      <ExportModal
        column={tableState?.orderBy?.column}
        order={tableState?.orderBy?.order}
      />
      {/* End of Export Modal  */}
    </div>
  );
};

export default YearlyReport;
