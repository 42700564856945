import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  onInputChangeAction,
  deleteUserDocumentAttachmentAction,
} from "../../Store/Actions";

import { InputForm, DateTimePickerForm, BSelect } from "form-builder";
import { RemoveIconButton } from "../../Components/IconButtonWithTooltip";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";

const textFields = ["4", "5", "7", "9"];
const dateFields = ["2", "1"];
const filesFields = ["3"];

const UpsertDocumentsModalForm = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    docFields,
    formName,
    formNameValidation,
    isSubmitting,
    selectFields,
    countryOpts,
  } = props;

  // Reducer State
  const upsertDocumentsModalFiles = useSelector(
    (state) => state.super[formName]?.files
  );
  const userDocumentID = useSelector(
    (state) => state.super.upsertDocumentsModalActions?.user_document_id
  );

  /* ↓ Helpers ↓ */

  const handleFilesChange = ({ file, meta }, status) => {
    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result.split(",");
      let fileType = file?.type.split("/");

      if (status === "done") {
        let e = {
          target: {
            name: "3",
            value: {
              file: base64[1],
              extension: fileType[1],
            },
          },
        };
        dispatch(onInputChangeAction(formName, e));
        // addRequestFileObject(target);
      }
    };

    if (status === "removed") {
      let e = {
        target: {
          name: "3",
          value: null,
        },
      };
      dispatch(onInputChangeAction(formName, e));
    }
    return;
  };

  const handleDeleteAttchment = (id) => {
    swal({
      title: t("are you sure"),
      text: t("default_warning_suspension_message"),
      icon: "warning",
      dangerMode: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("OK")],
    }).then((confirmDelete) => {
      if (confirmDelete) {
        dispatch(deleteUserDocumentAttachmentAction(id, userDocumentID));
      }
    });
  };

  return (
    <>
      {docFields?.length &&
        docFields?.map((field) =>
          textFields?.includes(field?.id) ? (
            <div className="row mb-2">
              <div className="col-12">
                <InputForm
                  name={field?.id}
                  placeholder={t(field?.name.toLowerCase())}
                  label={t(field?.name.toLowerCase())}
                  formName={formName}
                  formNameValidation={formNameValidation}
                  formSubmitting={isSubmitting}
                  labelStyle="mb-2"
                  containerStyle=""
                  inputContainerStyle="w-100"
                  validateBy="textRequired"
                  icon="fingerprint"
                />
              </div>
            </div>
          ) : null
        )}

      <div className="row">
        <div className="col-sm-12 col">
          <div className="row mb-2">
            {docFields?.length &&
              docFields
                ?.sort((a, b) => b?.id - a?.id)
                ?.map((field) =>
                  dateFields?.includes(field?.id) ? (
                    <div className="col">
                      <DateTimePickerForm
                        requestFormat="YYYY-MM-DD"
                        name={field?.id}
                        label={t(field?.name.toLowerCase())}
                        labelStyle="mb-2"
                        formName={formName}
                        formNameValidation={formNameValidation}
                        formSubmitting={isSubmitting}
                        containerStyle="mb-2"
                        datePickerContainer="w-100"
                        validateBy="textRequired"
                        hasIcon
                      />
                    </div>
                  ) : null
                )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col">
          <div className="row mb-2">
            {docFields?.length &&
              docFields?.map((field) =>
                selectFields?.includes(field?.id) ? (
                  <div className="col">
                    <BSelect
                      name={field?.id}
                      label={field?.name}
                      placeholder={t(field?.name)}
                      labelStyle="mb-2"
                      formName={formName}
                      options={countryOpts}
                      formNameValidation={formNameValidation}
                      formSubmitting={isSubmitting}
                      containerStyle="mb-2"
                      datePickerContainer="w-100"
                      validateBy="textRequired"
                      hasIcon
                      icon="globe"
                    />
                  </div>
                ) : null
              )}
          </div>
        </div>
      </div>

      <div className="attachment_wrapper_style">
        {upsertDocumentsModalFiles?.map((attachement) => (
          <div className="my-2 d-flex">
            <div>
              <a
                role={"button"}
                href={attachement?.path}
                target="_blank"
                className="attachment_label_style mb-1"
              >
                <FontAwesomeIcon icon={faPaperclip} />
                <span className="mx-2">{`attachment-${attachement?.id}`}</span>
              </a>
            </div>

            <RemoveIconButton
              onClick={() => handleDeleteAttchment(attachement.id)}
            />
          </div>
        ))}

        {docFields?.length &&
          docFields?.map((field) =>
            filesFields?.includes(field?.id) ? (
              <>
                <div className="row">
                  <label className="px-3 my-2">
                    {t(field?.name.toLowerCase())}
                  </label>
                  <div className="col-12 documents_dropzone_style">
                    <Dropzone
                      onChangeStatus={handleFilesChange}
                      canRemove
                      // accept anything that smells like an MS Word document & all images types & pdf documents
                      accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      name={field?.id}
                      multiple={false}
                      maxFiles={1}
                      LayoutComponent={null}
                    />
                  </div>
                </div>
              </>
            ) : null
          )}
      </div>
    </>
  );
};

export default UpsertDocumentsModalForm;
