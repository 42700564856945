import React, { useEffect } from "react";
import { connect } from "react-redux";
import Constants from "../../Helpers/Constants";
import moment from "moment";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  showAddNewDeductionsModalAction,
  showEditDeductionsModalAction,
  fetchUsersDeductionsAction,
  onInputResetAction,
  deleteDeductionAction,
} from "../../Store/Actions";
import { BSelect, RadioboxForm } from "form-builder";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import AddNewDeductionsModal from "./AddNewDeductionsModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import PaymentFactors from "../../Constants/PaymentFactors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";

const dataTableRef = "DeductionsList";

const Deductions = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchUsersDeductionsAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
    return () => {
      props.onInputResetAction(dataTableRef, "status");
      props.onInputResetAction(dataTableRef, "month");
      props.onInputResetAction(dataTableRef, "year");
      props.onInputResetAction(dataTableRef, "user_id");
    };
  }, []);

  const handleFilter = () => {
    props.fetchUsersDeductionsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].status,
    props.data[dataTableRef].month,
    props.data[dataTableRef].year,
    props.data[dataTableRef].user_id,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchUsersDeductionsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  // for add new deductions modal
  const handleAddDeductions = () => {
    props.showAddNewDeductionsModalAction({
      ref: "AddNewDeductionsModalActions",
      formName: "AddNewDeductionsModal",
    });
  };

  // for edit deductions modal
  const handleEditDeductionsModal = (data) => {
    let editRecord = {
      name: data?.name,
      user_id: data?.user?.id,
      payment_factor_id: data?.payment_factor_id,
      value: data?.value,
      payroll_at: data?.payroll_at,
      description: data?.description,
      id: data?.id,
    };
    props.showEditDeductionsModalAction(editRecord);
  };

  // to delete deduction record
  const handleDeleteDeduction = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteDeductionAction(row?.id);
      }
    });
  };

  // to approximate the numbers
  function roundToTwo(num) {
    return (+num)?.toFixed(2);
  }

  const columns = [
    {
      name: t("employee name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 2.2,
      cell: (row) => (
        <CanViewEmployeeProfile
          allowBP
          directManger={row?.user?.manager?.id}
          copiedManagers={row?.user?.copied_managers?.map((cp) => cp?.id)}
          altChildren={<div className="col pl-0">{row?.user?.name}</div>}
        >
          <div className="col pl-0">
            <Link
              className="employee-name"
              to={`/employees/employee-profile/${row?.user?.id}`}
            >
              {row?.user?.name}
            </Link>
          </div>
        </CanViewEmployeeProfile>
      ),
    },
    {
      name: t("amount"),
      wrap: true,
      selector: "amount",
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <div className="d-flex">
          {row?.amount > 0 ? (
            <div>
              <span className="pr-1">{t(row?.currency?.name)}</span>
              {roundToTwo(row?.amount)}
            </div>
          ) : (
            <div>
              {roundToTwo(row?.value)}{" "}
              <span className="pl-1">
                {row?.payment_factor_id === PaymentFactors.FIXED_AMOUNT ? (
                  row?.currency ? (
                    t(row?.currency?.name)
                  ) : (
                    <FontAwesomeIcon icon={faCoins} />
                  )
                ) : (
                  t("days")
                )}
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      name: t("deductible name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1.2,
    },
    {
      name: t("created by"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1.2,
      cell: (row) => <>{row?.createdBy?.name}</>,
    },
    {
      name: t("payable at"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) =>
        row?.payroll_at != null
          ? moment(row?.month_year_payroll, "MM-YYYY").format("MMMM YYYY")
          : "",
    },
    {
      name: t("actions"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) =>
        row?.payment_status?.id === "4" && (
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div className="cards_table_actions">
                <EditIconButton />
                <RemoveIconButton />
              </div>
            }
          >
            <div className="cards_table_actions">
              <EditIconButton onClick={() => handleEditDeductionsModal(row)} />
              <RemoveIconButton onClick={() => handleDeleteDeduction(row)} />
            </div>
          </HasPrivileges>
        ),
    },
  ];

  return (
    <div className="extra_deductions_wrapper_style">
      <div className="row my-3 align-items-baseline">
        <div className="col-12 col-lg-5">
          <RadioboxForm
            formName={dataTableRef}
            options={Constants.DeductionsStatusFilter?.map(
              ({ label, value }) => ({ label: t(label), value })
            )}
            name="status"
            type="radio"
            containerStyle="my-2 my-lg-0"
            labelStyle="label-style"
            optionsContainerStyle="optionsContainerStyle"
            optionItemStyle="optionItemStyle"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
          />
        </div>

        <div className="col-12 col-lg-7">
          <div className="row align-items-baseline">
            <div className="col-12 col-lg-2 px-1">
              <BSelect
                name="month"
                optionLabel="value"
                optionValue="key"
                options={Constants.AllMonthData}
                formName={dataTableRef}
                keepDefaultStyle
                containerStyle="month-picker my-2 my-lg-0"
                getOptionLabel={(option) => t(option.value)}
                icon="calendar"
              />
            </div>

            <div className="col-12 col-lg-2 px-1">
              <BSelect
                name="year"
                optionLabel="value"
                optionValue="value"
                options={Constants.YearData}
                formName={dataTableRef}
                keepDefaultStyle
                containerStyle="year-picker mb-2 mb-lg-0"
                icon="calendar"
              />
            </div>

            <div className="d-flex align-items-baseline col-12 col-lg-8 px-1 d-flex align-items-center flex-column flex-md-row">
              <div className="col px-0 mr-3">
                <BSelect
                  reducer="super"
                  name="user_id"
                  formName={dataTableRef}
                  options={props?.data?.extraEmployeesFilter}
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  containerStyle="my-0 mb-2 mb-lg-0"
                  isClearable
                  placeholder={t("employee")}
                  icon="person"
                />
              </div>

              <HasPrivileges
                reqireMain={[Privilages.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div>
                    <button
                      className="btn add_new_btn_style"
                      type="button"
                      disabled
                    >
                      {t("add new deductibles")}
                    </button>
                  </div>
                }
              >
                <div>
                  <button
                    className="btn add_new_btn_style"
                    type="button"
                    onClick={handleAddDeductions}
                  >
                    {t("add new deductibles")}
                  </button>
                </div>
              </HasPrivileges>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4">{t("no_records")}</div>}
        className="cards_table"
        columns={columns}
        data={props.data[dataTableRef].data}
        noHeader
        persistTableHead
        sortServer
        defaultSortAsc={props.data[dataTableRef].sorting.dir == "asc"}
        defaultSortField={props.data[dataTableRef].sorting.key}
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="super"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data[dataTableRef].isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of  Add new deductions modal */}
      <AddNewDeductionsModal />
      {/* End of  Add new deductions modal */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
  };
};

export default connect(mapStateToProps, {
  showAddNewDeductionsModalAction,
  showEditDeductionsModalAction,
  fetchUsersDeductionsAction,
  onInputResetAction,
  deleteDeductionAction,
})(Deductions);
