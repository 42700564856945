const FETCH_SYSTEM_PLANS_LIST_ATTEMPT = "FETCH_SYSTEM_PLANS_LIST_ATTEMPT";
const FETCH_SYSTEM_PLANS_LIST_SUCCESS = "FETCH_SYSTEM_PLANS_LIST_SUCCESS";
const FETCH_SYSTEM_PLANS_LIST_FAILED = "FETCH_SYSTEM_PLANS_LIST_FAILED";

const FETCH_SYSTEM_PRIVILEGES_ATTEMPT = "FETCH_SYSTEM_PRIVILEGES_ATTEMPT";
const FETCH_SYSTEM_PRIVILEGES_SUCCESS = "FETCH_SYSTEM_PRIVILEGES_SUCCESS";
const FETCH_SYSTEM_PRIVILEGES_FAILED = "FETCH_SYSTEM_PRIVILEGES_FAILED";

const RESET_SYSTEM_PLAN_FORM = "RESET_SYSTEM_PLAN_FORM";

const SHOW_SYSTEM_ROLE_MODAL = "SHOW_SYSTEM_ROLE_MODAL";
const DISMISS_SYSTEM_ROLE_MODAL = "DISMISS_SYSTEM_ROLE_MODAL";

const FETCH_PLAN_PRIVILEGES_ATTEMPT = "FETCH_PLAN_PRIVILEGES_ATTEMPT";
const FETCH_PLAN_PRIVILEGES_SUCCESS = "FETCH_PLAN_PRIVILEGES_SUCCESS";
const FETCH_PLAN_PRIVILEGES_FAILED = "FETCH_PLAN_PRIVILEGES_FAILED";

const FETCH_PLAN_ROLES_ATTEMPT = "FETCH_PLAN_ROLES_ATTEMPT";
const FETCH_PLAN_ROLES_SUCCESS = "FETCH_PLAN_ROLES_SUCCESS";
const FETCH_PLAN_ROLES_FAILED = "FETCH_PLAN_ROLES_FAILED";

const UPDTAE_PLAN_FETAURE_INPUT = "UPDTAE_PLAN_FETAURE_INPUT";

const UPSERT_SYSTEM_PLAN_ATTEMPT = "UPSERT_SYSTEM_PLAN_ATTEMPT";
const UPSERT_SYSTEM_PLAN_SUCCESS = "UPSERT_SYSTEM_PLAN_SUCCESS";
const UPSERT_SYSTEM_PLAN_FAILED = "UPSERT_SYSTEM_PLAN_FAILED";

const UPSERT_ROLE_ATTEMPT = "UPSERT_ROLE_ATTEMPT";
const UPSERT_ROLE_SUCCESS = "UPSERT_ROLE_SUCCESS";
const UPSERT_ROLE_FAILED = "UPSERT_ROLE_FAILED";

const FETCH_SYSTEM_PLANE_PAGE_ATTEMPT = "FETCH_SYSTEM_PLANE_PAGE_ATTEMPT";
const FETCH_SYSTEM_PLANE_PAGE_SUCCESS = "FETCH_SYSTEM_PLANE_PAGE_SUCCESS";
const FETCH_SYSTEM_PLANE_PAGE_FAILED = "FETCH_SYSTEM_PLANE_PAGE_FAILED";

const DELETE_SYSTEM_PLAN_ATTEMPT = "DELETE_SYSTEM_PLAN_ATTEMPT";
const DELETE_SYSTEM_PLAN_SUCCESS = "DELETE_SYSTEM_PLAN_SUCCESS";
const DELETE_SYSTEM_PLAN_FAILED = "DELETE_SYSTEM_PLAN_FAILED";

const DELETE_ROLE_ATTEMPT = "DELETE_ROLE_ATTEMPT";
const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
const DELETE_ROLE_FAILED = "DELETE_ROLE_FAILED";

const EDIT_SYSTEM_PLAN_ATTEMPT = "EDIT_SYSTEM_PLAN_ATTEMPT";
const EDIT_SYSTEM_PLAN_SUCCESS = "EDIT_SYSTEM_PLAN_SUCCESS";
const EDIT_SYSTEM_PLAN_FAILED = "EDIT_SYSTEM_PLAN_FAILED";

const UPDTAE_PLAN_STATUS_ATTEMPT = "UPDTAE_PLAN_STATUS_ATTEMPT";
const UPDTAE_PLAN_STATUS_SUCCESS = "UPDTAE_PLAN_STATUS_SUCCESS";
const UPDTAE_PLAN_STATUS_FAILED = "UPDTAE_PLAN_STATUS_FAILED";

const TOOGLE_VIEW_PLAN_PRIVILEGES_MODAL = "TOOGLE_VIEW_PLAN_PRIVILEGES_MODAL";

const FETCH_EDIT_ROLE_ATTEMPT = "FETCH_EDIT_ROLE_ATTEMPT";
const FETCH_EDIT_ROLE_SUCCESS = "FETCH_EDIT_ROLE_SUCCESS";
const FETCH_EDIT_ROLE_FAILED = "FETCH_EDIT_ROLE_FAILED";

const DISMISS_EDIT_ROLE_MODAL = "DISMISS_EDIT_ROLE_MODAL";

// fetch holidays list actions
const FETCH_ADMIN_HOLIDAYS_LIST_ATTEMPT = "FETCH_ADMIN_HOLIDAYS_LIST_ATTEMPT";
const FETCH_ADMIN_HOLIDAYS_LIST_SUCCESS = "FETCH_ADMIN_HOLIDAYS_LIST_SUCCESS";
const FETCH_ADMIN_HOLIDAYS_LIST_FAILED = "FETCH_ADMIN_HOLIDAYS_LIST_FAILED";

// fetch holidays countries filter option
const FETCH_ADMIN_HOLIDAYS_COUNTRIES_OPTIONS =
  "FETCH_ADMIN_HOLIDAYS_COUNTRIES_OPTIONS";

// handle admin holiday modal actions
const SHOW_ADMIN_HOLIDAYS_MODAL = "SHOW_ADMIN_HOLIDAYS_MODAL";
const HIDE_ADMIN_HOLIDAYS_MODAL = "HIDE_ADMIN_HOLIDAYS_MODAL";
const UPSERT_ADMIN_HOLIDAYS_ATTEMPT = "UPSERT_ADMIN_HOLIDAYS_ATTEMPT";
const UPSERT_ADMIN_HOLIDAYS_SUCCESS = "UPSERT_ADMIN_HOLIDAYS_SUCCESS";
const UPSERT_ADMIN_HOLIDAYS_FAILED = "UPSERT_ADMIN_HOLIDAYS_FAILED";

// handle delete admin holidays action
const DELETE_ADMIN_HOLIDAYS_ATTEMPT = "DELETE_ADMIN_HOLIDAYS_ATTEMPT";
const DELETE_ADMIN_HOLIDAYS_SUCCESS = "DELETE_ADMIN_HOLIDAYS_SUCCESS";
const DELETE_ADMIN_HOLIDAYS_FAILED = "DELETE_ADMIN_HOLIDAYS_FAILED";

const GENERATE_DEMO_ATTEMPT = "GENERATE_DEMO_ATTEMPT";
const GENERATE_DEMO_SUCCESS = "GENERATE_DEMO_SUCCESS";
const GENERATE_DEMO_FAILED = "GENERATE_DEMO_FAILED";

export default {
  FETCH_SYSTEM_PLANS_LIST_ATTEMPT,
  FETCH_SYSTEM_PLANS_LIST_SUCCESS,
  FETCH_SYSTEM_PLANS_LIST_FAILED,

  FETCH_SYSTEM_PRIVILEGES_ATTEMPT,
  FETCH_SYSTEM_PRIVILEGES_SUCCESS,
  FETCH_SYSTEM_PRIVILEGES_FAILED,

  FETCH_PLAN_PRIVILEGES_ATTEMPT,
  FETCH_PLAN_PRIVILEGES_SUCCESS,
  FETCH_PLAN_PRIVILEGES_FAILED,

  FETCH_PLAN_ROLES_ATTEMPT,
  FETCH_PLAN_ROLES_SUCCESS,
  FETCH_PLAN_ROLES_FAILED,

  UPSERT_ROLE_ATTEMPT,
  UPSERT_ROLE_SUCCESS,
  UPSERT_ROLE_FAILED,

  SHOW_SYSTEM_ROLE_MODAL,
  DISMISS_SYSTEM_ROLE_MODAL,

  RESET_SYSTEM_PLAN_FORM,

  UPDTAE_PLAN_FETAURE_INPUT,

  UPSERT_SYSTEM_PLAN_ATTEMPT,
  UPSERT_SYSTEM_PLAN_SUCCESS,
  UPSERT_SYSTEM_PLAN_FAILED,

  DELETE_SYSTEM_PLAN_ATTEMPT,
  DELETE_SYSTEM_PLAN_SUCCESS,
  DELETE_SYSTEM_PLAN_FAILED,

  EDIT_SYSTEM_PLAN_ATTEMPT,
  EDIT_SYSTEM_PLAN_SUCCESS,
  EDIT_SYSTEM_PLAN_FAILED,

  UPDTAE_PLAN_STATUS_ATTEMPT,
  UPDTAE_PLAN_STATUS_SUCCESS,
  UPDTAE_PLAN_STATUS_FAILED,

  FETCH_SYSTEM_PLANE_PAGE_ATTEMPT,
  FETCH_SYSTEM_PLANE_PAGE_SUCCESS,
  FETCH_SYSTEM_PLANE_PAGE_FAILED,

  DELETE_ROLE_ATTEMPT,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILED,

  TOOGLE_VIEW_PLAN_PRIVILEGES_MODAL,

  FETCH_EDIT_ROLE_ATTEMPT,
  FETCH_EDIT_ROLE_SUCCESS,
  FETCH_EDIT_ROLE_FAILED,
  DISMISS_EDIT_ROLE_MODAL,

  // fetch holidays list actions
  FETCH_ADMIN_HOLIDAYS_LIST_ATTEMPT,
  FETCH_ADMIN_HOLIDAYS_LIST_SUCCESS,
  FETCH_ADMIN_HOLIDAYS_LIST_FAILED,

  // fetch holidays countries filter option
  FETCH_ADMIN_HOLIDAYS_COUNTRIES_OPTIONS,

  // handle admin holiday modal actions
  SHOW_ADMIN_HOLIDAYS_MODAL,
  HIDE_ADMIN_HOLIDAYS_MODAL,
  UPSERT_ADMIN_HOLIDAYS_ATTEMPT,
  UPSERT_ADMIN_HOLIDAYS_SUCCESS,
  UPSERT_ADMIN_HOLIDAYS_FAILED,

  // handle delete admin holidays action
  DELETE_ADMIN_HOLIDAYS_ATTEMPT,
  DELETE_ADMIN_HOLIDAYS_SUCCESS,
  DELETE_ADMIN_HOLIDAYS_FAILED,
  
  GENERATE_DEMO_ATTEMPT,
  GENERATE_DEMO_SUCCESS,
  GENERATE_DEMO_FAILED,
};
