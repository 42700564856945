import gql from "graphql-tag";

import {
  leadFragment,
  trialFragment,
  commentFragment,
  contactFragment,
  activityFragment,
  reminderFragment,
  paginatorFragment,
} from "../fragments";

/* Leads */

export const fetchLeadQuery = gql`
  query fetchLead($id: ID) {
    lead(id: $id) {
      ...lead
      contacts {
        ...contact
      }
    }
  }
  ${leadFragment}
  ${contactFragment}
`;

export const leadsListQuery = gql`
  query leadsList(
    $to: Date
    $page: Int
    $from: Date
    $first: Int!
    $name: String
    $type: String
    $source: String
    $country: String
    $status: [String]
    $assigned_to: Int
    $last_activity_at: Date
    $isOptionsSkip: Boolean!
    $orderBy: [OrderByClause!]
  ) {
    leads(
      to: $to
      from: $from
      name: $name
      page: $page
      type: $type
      first: $first
      status: $status
      source: $source
      country: $country
      orderBy: $orderBy
      assigned_to: $assigned_to
      last_activity_at: $last_activity_at
    ) {
      data {
        ...lead
        contacts {
          ...contact
        }
        activities {
          ...activity
        }
        latestActivity {
          ...activity
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
    # Options
    countries: g_countries @skip(if: $isOptionsSkip)
    users: saas_names(first: 9999, haveLeads: 1) @skip(if: $isOptionsSkip) {
      data {
        id
        name
      }
    }
  }
  ${leadFragment}
  ${contactFragment}
  ${activityFragment}
  ${paginatorFragment}
`;

export const leadFormQuery = gql`
  query leadForm($id: ID, $isLeadSkip: Boolean!) {
    lead(id: $id) @skip(if: $isLeadSkip) {
      ...lead
      contacts {
        ...contact
      }
    }

    # Options
    industries
    countries: g_countries
    users: saas_names(first: 9999) {
      data {
        id
        name
      }
    }
  }
  ${leadFragment}
  ${contactFragment}
`;

export const assignLeadsFormQuery = gql`
  query fetchActivitiesFiltersOptions {
    users: saas_names(first: 9999) {
      data {
        id
        name
      }
    }
    leads: lead_names(first: 9999, type: "Leads") {
      data {
        id
        name
      }
    }
  }
`;

/* Activities */

export const activitiesListQuery = gql`
  query activitiesList(
    $page: Int
    $to: Date
    $from: Date
    $first: Int!
    $lead_id: Int
    $type: String
    $status: String
    $f_type: String
    $assigned_to: Int
    $isOptionsSkip: Boolean!
  ) {
    activities(
      to: $to
      from: $from
      page: $page
      type: $type
      first: $first
      status: $status
      f_type: $f_type
      lead_id: $lead_id
      assigned_to: $assigned_to
    ) {
      data {
        ...activity
        contact {
          ...contact
        }
        comments {
          ...comment
        }
        user {
          name
        }
      }
      paginatorInfo {
        ...paginator
      }
    }

    # Options
    users: saas_names(first: 9999, haveLeads: 1) @skip(if: $isOptionsSkip) {
      data {
        id
        name
      }
    }
    leads: lead_names(first: 9999, type: "Activities")
      @skip(if: $isOptionsSkip) {
      data {
        id
        name
      }
    }
  }
  ${commentFragment}
  ${contactFragment}
  ${activityFragment}
  ${paginatorFragment}
`;

export const activityFormQuery = gql`
  query activityForm(
    $id: ID
    $isActivitySkip: Boolean!
    $isLeadList: Boolean! = false
  ) {
    activity(id: $id) @skip(if: $isActivitySkip) {
      ...activity
      contact {
        ...contact
      }
    }

    # Options
    leads: lead_names(first: 9999, type: "Activities") @skip(if: $isLeadList) {
      data {
        id
        name
        contacts {
          ...contact
        }
      }
    }
    plans: plan_names(first: 9999) {
      data {
        id
        name
      }
    }
  }
  ${contactFragment}
  ${activityFragment}
`;

/* Reminders */

export const remindersListQuery = gql`
  query remindersList(
    $to: Date
    $page: Int
    $from: Date
    $first: Int!
    $lead_ids: [ID]
    $type: String
    $f_type: String
    $ff_type: String
    $assigned_to: ID
    $isOptionsSkip: Boolean!
  ) {
    reminders(
      to: $to
      from: $from
      type: $type
      page: $page
      first: $first
      f_type: $f_type
      ff_type: $ff_type
      lead_ids: $lead_ids
      assigned_to: $assigned_to
    ) {
      data {
        ...reminder
        user {
          name
        }
        contact {
          ...contact
        }
      }
      paginatorInfo {
        ...paginator
      }
    }

    # Options
    users: saas_names(first: 9999, haveLeads: 1) @skip(if: $isOptionsSkip) {
      data {
        id
        name
      }
    }
  }
  ${contactFragment}
  ${reminderFragment}
  ${paginatorFragment}
`;

export const reminderFormQuery = gql`
  query reminderForm(
    $id: ID
    $isReminderSkip: Boolean!
    $isLeadList: Boolean! = false
  ) {
    reminder(id: $id) @skip(if: $isReminderSkip) {
      ...reminder
      contact {
        ...contact
      }
    }

    # Options
    leads: lead_names(first: 9999, type: "Reminders") @skip(if: $isLeadList) {
      data {
        id
        name
        contacts {
          id
          name
        }
      }
    }
  }
  ${contactFragment}
  ${reminderFragment}
`;

/* Trials */

export const trialsListQuery = gql`
  query trialsList(
    $to: Date
    $page: Int
    $from: Date
    $first: Int!
    $user_id: ID
    $lead_ids: [ID]
    $status: String
    $company: String
    $isOptionsSkip: Boolean!
  ) {
    trials(
      to: $to
      from: $from
      page: $page
      first: $first
      status: $status
      company: $company
      user_id: $user_id
      lead_ids: $lead_ids
    ) {
      data {
        ...trial
      }
      paginatorInfo {
        ...paginator
      }
    }

    # Options
    users: saas_names(first: 9999, haveLeads: 1) @skip(if: $isOptionsSkip) {
      data {
        id
        name
      }
    }
  }
  ${trialFragment}
  ${paginatorFragment}
`;

export const trialFormQuery = gql`
  query trialForm($id: ID, $isTrialSkip: Boolean!) {
    trial(id: $id) @skip(if: $isTrialSkip) {
      ...trial
      lead {
        id
        name
      }
    }

    # Options
    leads: lead_names(first: 9999, type: "Trials") {
      data {
        id
        name
        contacts {
          id
          name
        }
      }
    }
    plans: plan_names(first: 9999) {
      data {
        id
        name
      }
    }
    managers: saas_names(first: 9999, type: "Trial") {
      data {
        id
        name
      }
    }
  }
  ${trialFragment}
`;

/* Calendar */

export const calendarQuery = gql`
  query calendar($from: Date, $to: Date, $assigned_to: ID) {
    crm_calendar(from: $from, to: $to, assigned_to: $assigned_to) {
      type
      info
      date
    }

    # Options
    users: saas_names(first: 9999, haveLeads: 1) {
      data {
        id
        name
      }
    }
  }
`;

export const calendarEventQuery = gql`
  query calendarEvent(
    $id: ID
    $isTrial: Boolean! = false
    $isActivity: Boolean! = false
    $isReminders: Boolean! = false
  ) {
    activity(id: $id) @include(if: $isActivity) {
      status
      contact {
        name
      }
      lead {
        assignedTo {
          name
        }
      }
    }
    reminder(id: $id) @include(if: $isReminders) {
      status
      due_date
      contact {
        name
      }
      lead {
        assignedTo {
          name
        }
      }
    }
    trial(id: $id) @include(if: $isTrial) {
      subscription {
        end_date
        start_date
        limit: employees_limit
        plan {
          name
        }
        company {
          businessPartners {
            user {
              name
            }
          }
        }
      }
    }
  }
`;

/* Dashboard */

export const dashboardCRMQuery = gql`
  query dashboardCRM(
    $to: Date
    $from: Date
    $activityScope: String = "All"
    $remindersScope: String = "All"
    $isReminders: Boolean! = false
    $isActivities: Boolean! = false
  ) {
    activities(first: 9999, f_type: $activityScope, from: $from, to: $to)
      @include(if: $isActivities) {
      data {
        id
        type
        date
        lead {
          name
          assignedTo {
            name
          }
        }
        contact {
          ...contact
        }
      }
    }
    reminders(first: 9999, ff_type: $remindersScope, from: $from, to: $to)
      @include(if: $isReminders) {
      data {
        id
        type
        status
        due_date
        lead {
          name
          assignedTo {
            name
          }
        }
        contact {
          ...contact
        }
      }
    }
  }
  ${contactFragment}
`;

/* Others */

export const getCitiesCountriesQuery = gql`
  query getCitiesCountries($country: String) {
    cities: g_cities(name: $country)
  }
`;
