import React from "react";
import { BSelect, InputForm } from "form-builder";
import { useSelector, useDispatch } from "react-redux";
import { childInputChange } from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import PaymentFactors from "../../Constants/PaymentFactors";
import { filterSalaryCofigPercentageOptions } from "../../Helpers/HelperFns";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

const childName = "salary_calculations";

const BonusCalculation = ({
  i,
  FormProps,
  calculation,
  totalGross,
  totalNet,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const salaryConfigForm = useSelector((state) => state.super.salaryConfigForm);
  const paymentFactors = useSelector((state) => state.super.paymentFactors);
  const monthOptions = useSelector((state) => state.super.monthOptions);

  const skipFirstRender = useDidUpdateEffect(() => {
    dispatch(
      childInputChange(FormProps.formName, childName, "month_option_id", i, "")
    );
  }, [salaryConfigForm.payment_interval]);

  const renderCalcValue = (calculation) => {
    let base = +salaryConfigForm?.base_salary || 0;
    let gross = totalGross();
    let net = totalNet();

    // NOD : number of days
    const NOD = {
      1: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate(),
      2: 30,
      3: 26,
      4: 22,
      5: +calculation?.value,
      6: 7,
      7: 5,
    };

    switch (calculation.payment_factor_id) {
      case PaymentFactors.BASE_PERCENTAGE:
        return (base / NOD[calculation.month_option_id]).toFixed(2);
      case PaymentFactors.GROSS_PERCENTAGE:
        return (gross / NOD[calculation.month_option_id]).toFixed(2);
      case PaymentFactors.NET_PERCENTAGE:
        return (net / NOD[calculation.month_option_id]).toFixed(2);
      default:
        return null;
    }
  };

  const handleSelectInputChange = (val, event, childName, index) => {
    dispatch(
      childInputChange(
        FormProps.formName,
        childName,
        event.name,
        index,
        val ? val?.value : ""
      )
    );
  };

  const handleChildInputChange = (event, childName, index) => {
    dispatch(
      childInputChange(
        FormProps.formName,
        childName,
        event.target.name,
        index,
        event.target.value
      )
    );
  };
  return (
    <div className="d-flex flex-fill order-two align-items-end">
      <div className="col p-0">
        <BSelect
          {...FormProps}
          label="bonus day"
          labelStyle="font-weight-bold mb-2"
          name="payment_factor_id"
          optionLabel="label"
          optionValue="value"
          options={paymentFactors}
          keepDefaultStyle
          containerStyle="my-0"
          placeholder={t("Select Payment Factor")}
          inputContainerStyle="w-100"
          validateBy="textRequired"
          optDependType="equal"
          optDependKey="calculation"
          optDependValue={["1", 1]}
          value={paymentFactors?.find(
            (opt) => opt.value == calculation?.payment_factor_id
          )}
          onChange={(e, val) =>
            handleSelectInputChange(e, val, "salary_calculations", i)
          }
          validationName={`input.user_salary_config_input.salary_calculations.${i}.payment_factor_id`}
          icon="money"
          isDisabled={
            salaryConfigForm?.calculate_salary_by !== "payment_interval"
          }
        />
      </div>

      <div className="col-8 col-md">
        {calculation.payment_factor_id == PaymentFactors.FIXED_AMOUNT ? (
          <InputForm
            name="value"
            type="number"
            onWheelCapture={(e) => {
              e.target.blur();
            }}
            {...FormProps}
            label="amount"
            placeholder={t("amount")}
            labelStyle="mb-2"
            containerStyle=""
            inputContainerStyle="w-100"
            validateBy="textRequired"
            onChange={(e) => handleChildInputChange(e, childName, i)}
            value={calculation?.value}
            validationName={`input.user_salary_config_input.salary_calculations.${i}.value`}
            icon="money"
          />
        ) : (
          <div className="d-flex align-items-baseline">
            <span className="min-w-85">
              {calculation.payment_factor_id == PaymentFactors.BASE_PERCENTAGE
                ? `${t("base_salary")} /`
                : calculation.payment_factor_id ==
                  PaymentFactors.GROSS_PERCENTAGE
                ? `${t("gross_salary")} /`
                : `${t("net_salary")} /`}
            </span>
            <div className="col pr-lg-1 pl-lg-3">
              <BSelect
                {...FormProps}
                name="month_option_id"
                optionLabel="label"
                optionValue="value"
                options={filterSalaryCofigPercentageOptions(
                  monthOptions,
                  salaryConfigForm.payment_interval
                )}
                keepDefaultStyle
                containerStyle=""
                placeholder="Select Days"
                inputContainerStyle="w-100"
                validateBy="textRequired"
                value={
                  monthOptions?.find(
                    (opt) => opt.value == calculation.month_option_id
                  ) ?? ""
                }
                onChange={(e, val) =>
                  handleSelectInputChange(e, val, childName, i)
                }
                validationName={`input.user_salary_config_input.salary_calculations.${i}.month_option_id`}
                icon="calendar"
              />
            </div>
            {calculation.month_option_id == "5" ? (
              <InputForm
                name="value"
                type="number"
                onWheelCapture={(e) => {
                  e.target.blur();
                }}
                {...FormProps}
                placeholder="Days"
                labelStyle="mb-0"
                containerStyle=""
                inputContainerStyle="days_wrapper_input_style"
                validateBy="textRequired"
                value={calculation?.value}
                onChange={(e) => handleChildInputChange(e, childName, i)}
                validationName={`input.user_salary_config_input.salary_calculations.${i}.value`}
              />
            ) : null}

            {calculation.month_option_id == "1" ? null : (
              <span className="font-weight-bold ml-1">
                {!isNaN(renderCalcValue(calculation))
                  ? `= ${renderCalcValue(calculation)}`
                  : `= 0`}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BonusCalculation;
