import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import { Add, Remove } from "@mui/icons-material";
import { CheckboxBooleanForm } from "form-builder";

// const AllowancesChildrenTreeData = [
//   {
//     Id: "1",
//     optionLabel: "Housing Allowances",
//     optionName: "housing_allowances",
//   },
//   {
//     Id: "2",
//     optionLabel: "Travel Allowances",
//     optionName: "travel_allowances",
//   },
// ];

const ExportModal = (props) => {
  return (
    <div>
      <Modal
        isOpen={props?.modal_isOpen}
        toggle={props?.modal_toggle}
        centered
        fade={false}
        className="export_tables_view_tree_wrapper_style"
      >
        <ModalHeader>export tables</ModalHeader>

        <ModalBody>
          <TreeView
            defaultCollapseIcon={<Remove />}
            defaultExpandIcon={<Add />}
            defaultExpanded={["2", "3", "6", "10", "15"]}
          >
            <TreeItem
              nodeId="1"
              label={
                <CheckboxBooleanForm
                  formName={props?.dataTableRef}
                  options={["basic salary"]}
                  name="basic_salary"
                  type="checkbox"
                  labelStyle="checkbox"
                  containerStyle="containerStyle"
                  optionsContainerStyle="w-100"
                  optionItemStyle="optionItemStyle mb-2"
                  optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                  optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                  optionLabelActiveStyle="optionLabelActiveStyle"
                />
              }
            ></TreeItem>

            <TreeItem
              nodeId="2"
              label={
                <CheckboxBooleanForm
                  formName={props?.dataTableRef}
                  options={["additions"]}
                  name="additions"
                  type="checkbox"
                  labelStyle="checkbox"
                  containerStyle="containerStyle"
                  optionsContainerStyle="d-flex flex-column"
                  optionItemStyle="optionItemStyle mb-2 w-60px"
                  optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                  optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                  optionLabelActiveStyle="optionLabelActiveStyle"
                />
              }
            >
              <TreeItem
                nodeId="3"
                label={
                  <CheckboxBooleanForm
                    formName={props?.dataTableRef}
                    options={["allowances"]}
                    name="allowances"
                    type="checkbox"
                    labelStyle="checkbox"
                    containerStyle="containerStyle"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle mb-2 w-60px"
                    optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                    optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                }
              >
                {/* {AllowancesChildrenTreeData?.map((allowancesChild) => (
                  <TreeItem
                    key={allowancesChild?.Id}
                    nodeId={allowancesChild?.Id}
                    label={
                      <CheckboxBooleanForm
                        formName={props?.dataTableRef}
                        options={[allowancesChild?.optionLabel]}
                        name={allowancesChild?.optionName}
                        type="checkbox"
                        labelStyle="checkbox"
                        containerStyle="containerStyle"
                        optionsContainerStyle="d-flex flex-column"
                        optionItemStyle="optionItemStyle mb-2 w-60px"
                        optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                        optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                        optionLabelActiveStyle="optionLabelActiveStyle"
                      />
                    }
                  />
                ))} */}

                <TreeItem
                  nodeId="4"
                  label={
                    <CheckboxBooleanForm
                      formName={props?.dataTableRef}
                      options={["Housing Allowances"]}
                      name="housing_allowances"
                      type="checkbox"
                      labelStyle="checkbox"
                      containerStyle="containerStyle"
                      optionsContainerStyle="d-flex flex-column"
                      optionItemStyle="optionItemStyle mb-2 w-60px"
                      optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                      optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                      optionLabelActiveStyle="optionLabelActiveStyle"
                    />
                  }
                />

                <TreeItem
                  nodeId="5"
                  label={
                    <CheckboxBooleanForm
                      formName={props?.dataTableRef}
                      options={["Travel Allowances"]}
                      name="travel_allowances"
                      type="checkbox"
                      labelStyle="checkbox"
                      containerStyle="containerStyle"
                      optionsContainerStyle="d-flex flex-column"
                      optionItemStyle="optionItemStyle mb-2 w-60px"
                      optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                      optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                      optionLabelActiveStyle="optionLabelActiveStyle"
                    />
                  }
                />
              </TreeItem>

              <TreeItem
                nodeId="6"
                label={
                  <CheckboxBooleanForm
                    formName={props?.dataTableRef}
                    options={["bonuses"]}
                    name="bonuses"
                    type="checkbox"
                    labelStyle="checkbox"
                    containerStyle="containerStyle"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle mb-2 w-60px"
                    optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                    optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                }
              >
                <TreeItem
                  nodeId="7"
                  label={
                    <CheckboxBooleanForm
                      formName={props?.dataTableRef}
                      options={["extra bonus 1"]}
                      name="extra_bonus_1"
                      type="checkbox"
                      labelStyle="checkbox"
                      containerStyle="containerStyle"
                      optionsContainerStyle="d-flex flex-column"
                      optionItemStyle="optionItemStyle mb-2 w-60px"
                      optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                      optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                      optionLabelActiveStyle="optionLabelActiveStyle"
                    />
                  }
                />

                <TreeItem
                  nodeId="8"
                  label={
                    <CheckboxBooleanForm
                      formName={props?.dataTableRef}
                      options={["extra bonus 2"]}
                      name="extra_bonus_2"
                      type="checkbox"
                      labelStyle="checkbox"
                      containerStyle="containerStyle"
                      optionsContainerStyle="d-flex flex-column"
                      optionItemStyle="optionItemStyle mb-2 w-60px"
                      optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                      optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                      optionLabelActiveStyle="optionLabelActiveStyle"
                    />
                  }
                />
              </TreeItem>

              <TreeItem
                nodeId="9"
                label={
                  <CheckboxBooleanForm
                    formName={props?.dataTableRef}
                    options={["overtime"]}
                    name="overtime"
                    type="checkbox"
                    labelStyle="checkbox"
                    containerStyle="containerStyle"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle mb-2 w-60px"
                    optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                    optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                }
              ></TreeItem>
            </TreeItem>

            <TreeItem
              nodeId="10"
              label={
                <CheckboxBooleanForm
                  formName={props?.dataTableRef}
                  options={["deductions"]}
                  name="deductions"
                  type="checkbox"
                  labelStyle="checkbox"
                  containerStyle="containerStyle"
                  optionsContainerStyle="d-flex flex-column"
                  optionItemStyle="optionItemStyle mb-2 w-60px"
                  optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                  optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                  optionLabelActiveStyle="optionLabelActiveStyle"
                />
              }
            >
              <TreeItem
                nodeId="11"
                label={
                  <CheckboxBooleanForm
                    formName={props?.dataTableRef}
                    options={["penalties"]}
                    name="penalties"
                    type="checkbox"
                    labelStyle="checkbox"
                    containerStyle="containerStyle"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle mb-2 w-60px"
                    optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                    optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                }
              ></TreeItem>

              <TreeItem
                nodeId="12"
                label={
                  <CheckboxBooleanForm
                    formName={props?.dataTableRef}
                    options={["social insurance"]}
                    name="social_insurance"
                    type="checkbox"
                    labelStyle="checkbox"
                    containerStyle="containerStyle"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle mb-2 w-60px"
                    optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                    optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                }
              ></TreeItem>

              <TreeItem
                nodeId="13"
                label={
                  <CheckboxBooleanForm
                    formName={props?.dataTableRef}
                    options={["medical insurance"]}
                    name="medical_insurance"
                    type="checkbox"
                    labelStyle="checkbox"
                    containerStyle="containerStyle"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle mb-2 w-60px"
                    optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                    optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                }
              ></TreeItem>

              <TreeItem
                nodeId="14"
                label={
                  <CheckboxBooleanForm
                    formName={props?.dataTableRef}
                    options={["taxes"]}
                    name="taxes"
                    type="checkbox"
                    labelStyle="checkbox"
                    containerStyle="containerStyle"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle mb-2 w-60px"
                    optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                    optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                }
              ></TreeItem>

              <TreeItem
                nodeId="15"
                label={
                  <CheckboxBooleanForm
                    formName={props?.dataTableRef}
                    options={["other deductions"]}
                    name="other_deductions"
                    type="checkbox"
                    labelStyle="checkbox"
                    containerStyle="containerStyle"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle mb-2 w-60px"
                    optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                    optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                }
              >
                <TreeItem
                  nodeId="16"
                  label={
                    <CheckboxBooleanForm
                      formName={props?.dataTableRef}
                      options={["Recurring Deduction"]}
                      name="recurring_deduction"
                      type="checkbox"
                      labelStyle="checkbox"
                      containerStyle="containerStyle"
                      optionsContainerStyle="d-flex flex-column"
                      optionItemStyle="optionItemStyle mb-2 w-60px"
                      optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                      optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                      optionLabelActiveStyle="optionLabelActiveStyle"
                    />
                  }
                />

                <TreeItem
                  nodeId="17"
                  label={
                    <CheckboxBooleanForm
                      formName={props?.dataTableRef}
                      options={["One time Deduction"]}
                      name="one_time_deduction"
                      type="checkbox"
                      labelStyle="checkbox"
                      containerStyle="containerStyle"
                      optionsContainerStyle="d-flex flex-column"
                      optionItemStyle="optionItemStyle mb-2 w-60px"
                      optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                      optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                      optionLabelActiveStyle="optionLabelActiveStyle"
                    />
                  }
                />
              </TreeItem>
            </TreeItem>

            <TreeItem
              nodeId="18"
              label={
                <CheckboxBooleanForm
                  formName={props?.dataTableRef}
                  options={["others"]}
                  name="others"
                  type="checkbox"
                  labelStyle="checkbox"
                  containerStyle="containerStyle"
                  optionsContainerStyle="d-flex flex-column"
                  optionItemStyle="optionItemStyle mb-2 w-60px"
                  optionInputStyle="optionInputStyle mr-2 containerStyle checkbox"
                  optionLabelStyle="optionLabelStyle ml-4 pt-1 min-width-200"
                  optionLabelActiveStyle="optionLabelActiveStyle"
                />
              }
            ></TreeItem>
          </TreeView>
        </ModalBody>

        <ModalFooter>
          <button
            className="btn add_new_btn_style export_table_modal_btn_style"
            type="button"
          >
            Export
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
  };
};

export default connect(mapStateToProps)(ExportModal);
