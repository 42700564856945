import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Avatar from "@mui/material/Avatar";
import Helper from "../../Helpers/HelperFns";
import Loader from "../../Components/Loader"
import { Home } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { dashboardUsersQuery } from "../../Graphql/query";
import { useQuery } from "@apollo/client";

const ShowAllModal = (props) => {

  const statusTypes ={
    "Absent":["Absent"],
    "Not signed in":["Not signed in"],
    "Day Off":["Day Off", "holiday"],
    "Present":["Present"],
    "Late":["Late"],
    "Leave":["Leave"],
    "All":null

    };

  const input = {
    status: props.status,
    working_status:statusTypes[props.status],
    page_flag: "daily_status_list",
    office_id:props.office_id
  }

  const {
    data,
    loading,
  } = useQuery(
    dashboardUsersQuery, {
    variables:{ input },
    fetchPolicy: "network-only",
  });

  const { t } = useTranslation();
  return (
    <Modal
      isOpen={props?.isOpen}
      toggle={() => props?.modal_toggle(props.status)}
      centered
      fade={false}
      className="show_all_modal_wrapper_style"
    >
      <ModalHeader
        // className={props.bgImageClass ?? " "}
        toggle={() => props?.modal_toggle(props.status)}
      >
        {props?.modal_title}
      </ModalHeader>

      <ModalBody>
        <div className="row">
          {loading ? 
            <div className="w-100 d-flex align-items-center justify-content-center">
              <Loader/>
            </div>
             : 
             data?.users?.data?.map((emp) => (
            <div className="col-12 col-md-6 my-2">
              <div className="d-flex align-items-center show_all_modal_avatar_style user-img">
                <Avatar
                  src={emp?.profile_picture?.path}
                  className="rounded-circle blackish-avatar"
                >
                  {Helper.getIntials(emp?.name)}
                </Avatar>

                {props?.showWorkingStatus == "1" ? (
                  <>
                    <span className="align-middle pl-2">{emp?.name}</span>
                    {emp?.working_status == "Day Off" ? (
                      <span className="align-middle ml-3 Weekend_status_style">
                        {t("Day Off")}
                      </span>
                    ) : (
                      ""
                    )}

                    {emp?.working_status == "Holiday" ? (
                      <span className="align-middle ml-3 Holiday_status_style">
                        {t("Holiday")}
                      </span>
                    ) : (
                      ""
                    )}

                    {emp?.from_home ? (
                      <Home className="ml-2 dashboard-home-icon" />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <span className="align-middle pl-2">{emp?.name}</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShowAllModal;
