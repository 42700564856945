import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { BSelect, InputForm } from "form-builder";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  dismissDepartmentModalAction,
  updateDepartmentAction,
  createDepartmentAction,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import { managersQuery } from "../../Graphql/query";

const formName = "departmentModal";
const formNameValidation = "departmentModalValidation";

const DepartmentModal = (props) => {
  const { t } = useTranslation();
  const [getEmployees, { data: managers, loading: isManagersLoading }] =
    useLazyQuery(managersQuery);

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }
    if (props.departmentModal?.type == "edit") {
      props.updateDepartmentAction({
        data: props.departmentModal,
        ref: "departmentModalActions",
      });
    }
    if (props.departmentModal?.type == "add") {
      props.createDepartmentAction({
        data: props.departmentModal,
        ref: "departmentModalActions",
      });
    }
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissDepartmentModalAction({
      ref: "departmentModalActions",
      formName: "departmentModal",
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
    isModalVissible && getEmployees();
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t(`${props.departmentModal?.type} department`)}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
    >
      {isManagersLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}
      <div>
        <InputForm
          name="name"
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          placeholder={t("name")}
          label="name"
          labelStyle="mb-0"
          containerStyle=" "
          inputContainerStyle="my-2"
          validateBy="textRequired"
          icon="person"
        />
        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>

      <div className="mt-3">
        <BSelect
          isClearable
          keepDefaultStyle
          placeholder={t("manager")}
          name="manager_id"
          label="manager"
          formName={formName}
          formSubmitting={isSubmitting}
          formNameValidation={formNameValidation}
          validationName="input.manager_id"
          options={managers?.company_users.data}
          icon="person"
        />
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.departmentModalActions,
  modalValidation: state.super[formNameValidation],
  departmentModal: state.super[formName],
  user: state.auth.userProfile,
});

export default connect(mapStateToProps, {
  dismissDepartmentModalAction,
  updateDepartmentAction,
  createDepartmentAction,
})(DepartmentModal);
