import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import MainModal from "../../MainModal";
import SignInSection from "./SignInSection";
import TardinessPolicySection from "./TardinessPolicySection";
import SignOutSection from "./SignOutSection";
import OvertimeSection from "./OvertimeSection";
import NoShowDeductionSection from "./NoShowDeductionSection";
import SignInOutSettingsSection from "./SignInOutSettingsSection";
import {
  CopyIconButton,
  EditIconButton,
  RemoveIconButton,
} from "../../IconButtonWithTooltip";
import swal from "sweetalert";

import {
  cloneWorkTimeAction,
  deleteWorkTimeAction,
  dismissViewWorkTime,
  editFetchWorkTimeAction,
} from "../../../Store/Actions";
import Privilages from "../../../Constants/Privilages";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";

const EditWorkTimePreview = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const viewWorkTime = useSelector((state) => state.super.viewWorkTime);
  const viewWorkTimeModal = useSelector(
    (state) => state.super.viewWorkTimeModal
  );

  return (
    <MainModal
      isOpen={viewWorkTimeModal?.isVissible}
      toggle={() => dispatch(dismissViewWorkTime())}
      hasModalFooter={false}
      modalTitle=" "
      size="lg"
    >
      <div className="px-2">
        {/* Modal Header */}
        <div className="d-flex justify-content-between">
          <h2 className="page-title-bold border-0 mb-3 pt-2">
            {`${viewWorkTime?.name} - ${
              viewWorkTime?.work_timing_profile_type === "half"
                ? t("half day")
                : t("normal")
            }`}
          </h2>

          {props?.isWorkTimingList ? (
            <div className="d-flex gap-10 align-items-baseline mt-2">
              <HasPrivileges
                reqireMain={[Privilages.ADD_EDIT_CLONE_WORK_TIMINGS]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={<CopyIconButton />}
              >
                <CopyIconButton
                  onClick={() => {
                    dispatch(dismissViewWorkTime());
                    dispatch(
                      cloneWorkTimeAction({
                        id: viewWorkTime?.id,
                        ref: "modal",
                      })
                    );
                  }}
                />
              </HasPrivileges>

              <HasPrivileges
                reqireMain={[Privilages.ADD_EDIT_CLONE_WORK_TIMINGS]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={<EditIconButton />}
              >
                <EditIconButton
                  onClick={() => {
                    dispatch(dismissViewWorkTime());
                    dispatch(editFetchWorkTimeAction(viewWorkTime?.id));
                  }}
                />
              </HasPrivileges>

              <HasPrivileges
                reqireMain={[Privilages.DELETE_WORK_TIMINGS]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={<RemoveIconButton />}
              >
                <RemoveIconButton
                  onClick={() => {
                    swal({
                      width: "auto",
                      title: t("are you sure"),
                      text: t("defaut_warning_messsage"),
                      icon: "warning",
                      className: "swal-warning-style",
                      dangerMode: true,
                      buttons: [
                        t("Cancel"),
                        { text: t("OK"), closeModal: false },
                      ],
                    }).then((willDelete) => {
                      if (willDelete) {
                        dispatch(dismissViewWorkTime());
                        dispatch(deleteWorkTimeAction(viewWorkTime?.id));
                      }
                    });
                  }}
                />
              </HasPrivileges>
            </div>
          ) : null}
        </div>

        {/* ↓ Sections ↓ */}

        {viewWorkTime?.sign_in_req ? (
          <>
            {/* Start of Sign in section */}
            <SignInSection {...viewWorkTime} />
            <hr />
            {/* End of Sign in section */}

            {viewWorkTime?.apply_minor_tardiness ? (
              <>
                {/* Start of tardiness policy section */}
                <TardinessPolicySection {...viewWorkTime} />
                <hr />
                {/* End of tardiness policy section */}
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {/* Start of no show deduction section */}
        <NoShowDeductionSection {...viewWorkTime} />
        <hr />
        {/* End of no show deduction section */}

        {viewWorkTime?.sign_in_req ? (
          <>
            {viewWorkTime?.auto_sign_out ||
            viewWorkTime?.apply_missing_sign_out ||
            viewWorkTime?.allow_early_signout ? (
              <>
                {/* Start of sign out section */}
                <SignOutSection {...viewWorkTime} />
                <hr />
                {/* End of sign out section */}
              </>
            ) : (
              ""
            )}

            {viewWorkTime?.overtime_permissible ? (
              <>
                {/* Start of overtime section */}
                <OvertimeSection {...viewWorkTime} />
                <hr />
                {/* End of overtime section */}
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {/* Start of Sign In/Out Settings section */}
        <SignInOutSettingsSection {...viewWorkTime} />
        {/* End of Sign In/Out Settings section */}
      </div>
    </MainModal>
  );
};

export default EditWorkTimePreview;
