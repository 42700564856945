import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { Section, Unit } from "./SectionBase";

const SignOutSection = (props) => {
  const { t } = useTranslation();

  return (
    <Section
      title="sign out"
      units={[
        <Unit
          isBoolVal
          boolVal={props?.allow_early_signout}
          isHidden={props?.allow_early_signout}
          colStyles="col-12 col-lg-6 mb-2"
          label="Allow Early Sign Out"
        />,
        <Unit
          isBoolVal
          boolVal={props?.apply_missing_sign_out}
          isHidden={props?.apply_missing_sign_out}
          colStyles="col-12 col-lg-6 mb-2"
          label="Allow missing sign out"
        />,
        <Unit
          colStyles="col-12 mb-2"
          label="Auto Sign Out"
          isHidden={!props?.auto_sign_out}
          val={`${t("Automatically Sign Employees Out at")} ${moment(
            props?.auto_sign_out_time,
            "HH:mm"
          ).format("hh:mm A")}`}
        />,
      ]}
    >
      {/* Early Sign Out */}
      {!props?.allow_early_signout ? null : (
        <Section
          subSection
          title="Early Sign Out"
          violations={props?.early_sign_out_violations}
          units={[
            <Unit
              label="deduct from"
              val={t(props?.early_signout_deduction_type)}
            />,
            <Unit
              label="reset every"
              val={t(props?.late_reset_period)}
              isHidden={!props?.early_signout_reset_period}
            />,
            <Unit
              label="tardiness type"
              val={t(props?.tardiness_type_sign_out?.replace("_", " "))}
            />,
            <Unit
              label="annual leave hours"
              val={t(props?.annual_leave_hours_sign_in)}
              isHidden={!props?.annual_leave_hours_sign_in}
            />,
          ]}
        />
      )}

      {/* Missing Sign Out */}
      {!props?.apply_missing_sign_out ? null : (
        <>
          <hr />
          <Section
            subSection
            title="Missing Sign Out"
            violations={props?.missing_sign_out_violations}
            units={[
              <Unit
                label="deduct from"
                val={t(props?.missing_sign_out_deduction_type)}
              />,
              <Unit
                label="reset every"
                val={t(props?.late_reset_period)}
                isHidden={!props?.missing_sign_out_reset_period}
              />,
            ]}
          />
        </>
      )}
    </Section>
  );
};

export default SignOutSection;
