import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxForm, BSelect,CheckboxBooleanForm } from "form-builder";
import moment from "moment";
import { connect, useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import Constants from "../../Helpers/Constants";
import * as REQUESTS from "../../Constants/Requests";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import swal from "sweetalert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  acceptInboundRequestAction,
  rejectInboundRequestAction,
  fetchInboundRequestsSuccess,
  showOvertimeModalAction,
  showViewAttRequestModal,
  fetchEditAccpetedOvertimeRequestDataAction,
  showRequestCommentsModalAction,
  onFormResetAction,
  cancelRequestAction,
  showEditLeavesModalAction,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import Privilages from "../../Constants/Privilages";
import { useLocation, Link } from "react-router-dom";
import OvertimeModal from "./OvertimeModal";

import HelperFns from "../../Helpers/HelperFns";
import AttRequestInfoModal from "./AttRequestInfoModal";
import RequestCommentsModal from "../../Components/RequestCommentsModal";
import { Comment, ErrorOutline } from "@mui/icons-material";
import IconButtonWithTooltip, {
  EditIconButton,
  AcceptIconButton,
  TimesIconButton,
  DownloadFileIconButton,
} from "../../Components/IconButtonWithTooltip";
import FloatingMenu from "../../Components/FloatingMenu";
import Box from "@mui/material/Box";
import EditLeavesModal from "../../Components/EditLeavesModal";
import { inboundRequestsQuery } from "../../Graphql/query";
import ShowMoreText from "react-show-more-text";
import { Tooltip } from "@mui/material";

const InboundRequests = ({
  inboundRequestsData,
  employeesFilterOptions,
  officesFilterOptions,
  inboundRequestsLoading,
  refetchQueries,
  pagination,
  onPaginate,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { dataTableRef, title } = props;
  const currentRoute = useLocation();
  useEffect(() => {
    return () => {
      props.onFormResetAction(dataTableRef);
    };
  }, []);

  // (Start) Attendance Request Modal Info
  const handleShowAttRequestInfo = (row) => {
    props?.showViewAttRequestModal(row);
  };
  // (End) Attendance Request Modal Info

  // for overtime modal
  const handleAcceptOvertimeRequest = (data) => {
    let payable_at =
      data?.status?.toLowerCase() === "pending"
        ? data?.req_day
        : data?.payable_at;
    let acceptOvertimeData = {
      id: data?.id,
      name: data?.employee?.user?.name,
      interval_in_time: data?.interval_in_time,
      created_at: payable_at,
      req_day: data?.req_day,
      page_flag: props.pageFlag,
    };
    props.showOvertimeModalAction({
      ref: "overtimeModalActions",
      formName: "overtimeModal",
      acceptOvertimeData,
    });
  };

  // edit overtime modal for approved request case
  const handleEditAcceptedOvertimeRequest = (data) => {
    let acceptOvertimeData = {
      id: data?.id,
      name: data?.employee?.user?.name,
      req_day: data?.req_day,
      page_flag: props?.pageFlag,
      modalType: "Edit",
    };
    props.showOvertimeModalAction({
      ref: "overtimeModalActions",
      formName: "overtimeModal",
      acceptOvertimeData,
    });
    props.fetchEditAccpetedOvertimeRequestDataAction(data?.id);
  };

  // handle Request comments modal
  const handleRequestComments = (row) => {
    let data = {
      id: row?.id,
    };
    props.showRequestCommentsModalAction({
      ref: "requestCommentModalActions",
      formName: "requestCommentModal",
      data,
    });
  };

  const getRequestablesWorkTimingsNamesFromRequestObject = (request) => {
    let { requestables = [] } = request;

    let workTimingsNames = [];

    requestables?.forEach(({ requestable }) => {
      switch (requestable?.__typename) {
        case "AttendanceProfileWorkTiming":
          if (workTimingsNames.includes(requestable?.work_timing?.name)) {
            return;
          }
          return workTimingsNames.push(requestable?.work_timing?.name);

        case "Exception":
          if (workTimingsNames.includes(requestable?.normalWorkTiming?.name)) {
            return;
          }
          return workTimingsNames.push(requestable?.normalWorkTiming?.name);

        case "ScheduleDetailEmployee":
          if (
            workTimingsNames.includes(
              requestable?.SCheduleDetail?.WorkTiming?.name
            )
          ) {
            return;
          }
          return workTimingsNames.push(
            requestable?.SCheduleDetail?.WorkTiming?.name
          );
      }
    });

    return workTimingsNames?.join(" - ");
  };

  const hasActiveSubscription = useSelector(
    (state) => state.auth?.userProfile?.company?.hasActiveSubscription
  );

  const handleCancelRequest = (row) => {
    if (!hasActiveSubscription) {
      return;
    }

    if (row?.requestType?.id == REQUESTS.PERMISSIONS) {
      swal({
        title: t("default_warning_cancel_message"),
        icon: "warning",
        className: "swal-warning-style",
        dangerMode: true,
        buttons: [t("Cancel"), t("OK")],
      }).then((willCancel) => {
        if (willCancel) {
          dispatch(
            cancelRequestAction(
              row?.id,
              "inbound-requests",
              null,
              row?.employee?.user?.id,
              refetchQueries
            )
          );
        }
      });
      return;
    }

    if (
      moment(row?.time_from).format("yyyy-MM-DD") <=
      moment(new Date()).format("yyyy-MM-DD") &&
      moment(new Date()).format("yyyy-MM-DD") <=
      moment(row?.time_to).format("yyyy-MM-DD") &&
      row?.time_from != null &&
      row?.time_to != null
    ) {
      // Start of handling edit leaves modal
      let data = {
        id: row?.id,
        time_from:
          row?.time_from != null
            ? moment(row?.time_from).format("yyyy-MM-DD")
            : null,
        time_to:
          row?.time_to != null
            ? moment(row?.time_to).format("yyyy-MM-DD")
            : null,
        employeeId: row?.employee?.user?.id,
      };
      dispatch(
        showEditLeavesModalAction({
          data,
          ref: "editLeavesModalActions",
          formName: "editLeavesModal",
        })
      );
      // End of handling edit leaves modal
    } else {
      swal({
        title: t("default_warning_cancel_message"),
        icon: "warning",
        className: "swal-warning-style",
        dangerMode: true,
        buttons: [t("Cancel"), t("OK")],
      }).then((willCancel) => {
        if (willCancel) {
          dispatch(
            cancelRequestAction(
              row?.id,
              "inbound-requests",
              null,
              row?.employee?.user?.id,
              refetchQueries
            )
          );
        }
      });
    }
  };

  const columns = [
    {
      name: t("employee"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <CanViewEmployeeProfile
          allowBP
          directManger={row?.employee?.user?.manager?.id}
          copiedManagers={row?.employee?.user?.copied_managers?.map(
            (cp) => cp?.id
          )}
          altChildren={
            <div className="col pl-0">{row?.employee?.user?.name}</div>
          }
        >
          <div className="col pl-0">
            <Link
              className="employee-name"
              to={`/employees/employee-profile/${row?.employee?.user?.id}`}
            >
              {row?.employee?.user?.name}
            </Link>
          </div>
        </CanViewEmployeeProfile>
      ),
    },
    {
      name: t("type"),
      wrap: true,
      selector: "request_type_id",
      sortable: true,
      grow: 1.3,
      cell: (row) => (
        <>
          {t(`${row.requestType?.name?.toLowerCase()}`)}
          {row?.applicable?.__typename === "Exception"
            ? `(${row?.applicable?.assignment?.name})`
            : ""}

          <Tooltip
            arrow
            title={moment(row?.created_at).format("DD-MM-YYYY hh:mm A")}
          >
            <ErrorOutline fontSize="small" />
          </Tooltip>
        </>
      ),
    },
    {
      name: t("requested for"),
      selector: "time_from",
      wrap: true,
      cell: (row) => {
        switch (row?.requestType?.id) {
          case REQUESTS.NORMAL_LEAVES:
          case REQUESTS.EMERGENCY_LEAVES:
          case REQUESTS.UNPAID_LEAVES:
          case REQUESTS.SICK_LEAVES:
            return (
              <div>
                <div className="request-date-container my-1">
                  <p className="request-date-title">{t("from")}</p>{" "}
                  <p className="request-date-text">
                    {moment(row?.time_from).format("DD/MM/yyyy")}
                  </p>
                </div>
                <div className="request-date-container my-1">
                  <p className="request-date-title">{t("to")}</p>{" "}
                  <p className="request-date-text">
                    {moment(row?.time_to).format("DD/MM/yyyy")}
                  </p>
                </div>
                <p className="p-0 m-0 font-weight-normal my-1">
                  {getRequestablesWorkTimingsNamesFromRequestObject(row)}
                </p>
              </div>
            );

          case REQUESTS.BEREAVEMENT:
          case REQUESTS.MATERNITY_PATERNITY_LEAVE:
          case REQUESTS.HAJJ_LEAVE:
          case REQUESTS.STUDY_LEAVE:
          case REQUESTS.SABBATICAL_LEAVE:
          case REQUESTS.MARRIAGE_LEAVE:
            return (
              <div className="p-0 m-0">
                <div className="p-0 m-0">
                  <div className="request-date-container p-0 m-0">
                    <p className="request-date-title">{t("from")}</p>{" "}
                    <p className="request-date-text">
                      {moment(row?.time_from).format("DD/MM/yyyy")}
                    </p>
                  </div>
                  <div className="request-date-container p-0 m-0">
                    <span className="request-date-title">{t("to")}</span>{" "}
                    <span className="request-date-text">
                      {moment(row?.time_to).format("DD/MM/yyyy")}
                    </span>
                  </div>
                </div>
                <div className="badge-info-border my-1 text-center">
                  <p className="m-0">{t(row?.payment_flag)}</p>
                </div>
                <p className="p-0 m-0 font-weight-normal">
                  {getRequestablesWorkTimingsNamesFromRequestObject(row)}
                </p>
              </div>
            );

          case REQUESTS.OVERTIME:
            return (
              <div>
                <div className="request-date-container">
                  <p className="request-date-title request-date-title-overtime">
                    {t("date")}
                  </p>{" "}
                  <p className="request-date-text">
                    {moment(row?.req_day).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="request-date-container">
                  <p className="request-date-title request-date-title-overtime">
                    {t("overtime_interval")}
                  </p>{" "}
                  <p className="request-date-text">
                    {row?.status?.toLowerCase() === "accepted"
                      ? moment(
                        row?.payable_interval_in_time,
                        "HH:mm:ss"
                      ).format("HH:mm")
                      : moment(row?.interval_in_time, "HH:mm:ss").format(
                        "HH:mm"
                      )}
                  </p>
                </div>
                <p className="p-0 m-0 font-weight-normal">
                  {getRequestablesWorkTimingsNamesFromRequestObject(row)}
                </p>
              </div>
            );

          case REQUESTS.HOME:
            return (
              <div>
                {`${moment(row?.req_day).format("DD/MM/yyyy")}`}
                <p className="p-0 m-0 font-weight-normal">
                  {getRequestablesWorkTimingsNamesFromRequestObject(row)}
                </p>
              </div>
            );

          case REQUESTS.NORMAL_HALF_DAY:
          case REQUESTS.UNPAID_HALF_DAY:
          case REQUESTS.EMERGENCY_HALF_DAY:
            return (
              <div className="p-0 m-0">
                <p className="p-0 m-0 font-weight-normal mb-1">
                  {t(`${row?.add_info?.toLocaleLowerCase()}_half`)}{" "}
                </p>
                <p className="p-0 m-0 font-weight-normal mb-1">
                  {moment(row?.req_day).format("DD/MM/YYYY")}
                </p>
                <p className="p-0 m-0 font-weight-normal">
                  {getRequestablesWorkTimingsNamesFromRequestObject(row)}
                </p>
              </div>
            );

          case REQUESTS.EDIT_ATTENDANCE:
          case REQUESTS.IGNORE_ASSIGNMENT:
            return (
              <p className="p-0 m-0 font-weight-normal">
                {moment(row?.req_day).format("DD/MM/yyyy")}
              </p>
            );

          case REQUESTS.PERMISSIONS:
            return (
              <div>
                <div className="request-date-container my-1">
                  <p className="request-date-title">{t("from")}</p>{" "}
                  <p className="request-date-text">
                    {moment(row?.time_from).format("DD/MM/YYYY hh:mm a")}
                  </p>
                </div>
                <div className="request-date-container my-1">
                  <span className="request-date-title">{t("to")}</span>{" "}
                  <span className="request-date-text">
                    {moment(row?.time_to).format("DD/MM/YYYY hh:mm a")}
                  </span>
                </div>
                <p className="p-0 m-0 font-weight-normal my-1">
                  {getRequestablesWorkTimingsNamesFromRequestObject(row)}
                </p>
              </div>
            );

          default:
            return (
              <div className="p-0 m-0">
                F
                <p className="p-0 m-0">
                  {moment(row?.time_from).format("DD/MM/yyyy")}
                </p>
                <p className="p-0 m-0">
                  {t("from")} {moment(row?.time_from).format("hh:mm a")}{" "}
                  {t("to")} {` `}
                  {moment(row?.time_to).format("hh:mm a")}
                </p>
                <p className="p-0 m-0 font-weight-normal">
                  {getRequestablesWorkTimingsNamesFromRequestObject(row)}
                </p>
              </div>
            );
        }
      },
      sortable: true,
      grow: 1.75,
    },
    {
      name: t("reviewed by"),
      wrap: true,
      sortable: false,
      grow: 1.3,
      cell: (row) =>
        row?.status?.toLowerCase() !== "pending" && row?.action_by == null ? (
          t("By System")
        ) : row?.action_by?.user_type?.toLowerCase() !== "owner" ? (
          <CanViewEmployeeProfile
            allowBP
            directManger={row?.action_by?.manager?.id}
            copiedManagers={row?.action_by?.copied_managers?.map(
              (cp) => cp?.id
            )}
            altChildren={<div className="col pl-0">{row?.action_by?.name}</div>}
          >
            <div className="col pl-0">
              <Link
                className="employee-name"
                to={`/employees/employee-profile/${row?.action_by?.id}`}
              >
                {row?.action_by?.name}
              </Link>
            </div>
          </CanViewEmployeeProfile>
        ) : (
          <div className="col pl-0">{row?.action_by?.name}</div>
        ),
    },
    {
      name: t("status"),
      wrap: true,
      selector: "status",
      sortable: true,
      grow: 1,
      cell: (row) => {
        switch (row?.status) {
          case "Pending":
            return (
              <span className="request-badge request-pending-status">
                {t("pending")}
              </span>
            );
          case "Rejected":
            return (
              <span className="request-badge request-rejected-status">
                {t("rejected")}
              </span>
            );
          case "Accepted":
            return (
              <div>
                <span className="request-badge request-approved-status mb-1">
                  {t("accepted")}
                </span>
                {row?.requestType?.id == REQUESTS.OVERTIME ? (
                  <div>
                    {moment(
                      row?.subModel?.overtime_payable_at,
                      "MM-YYYY"
                    ).format("MMMM YYYY")}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );

          default:
            break;
        }
      },
    },
    {
      name: t("comments"),
      wrap: true,
      grow: 0.5,
      center: true,
      selector: "comments",
      ignoreRowClick: true,
      omit: HelperFns.checkPrivileges({
        allowBP: true,
        privileges: [Privilages.VIEW_COMMENTS_ON_REQUESTS],
      })
        ? false
        : true,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.VIEW_COMMENTS_ON_REQUESTS]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="d-flex align-items-baseline request_comments_in_list_wrapper_style">
              <div
                className={
                  row?.number_of_comments !== 0
                    ? "my-requests-action comments_on_request_style"
                    : "my-requests-action"
                }
              >
                <IconButtonWithTooltip
                  label="show comments"
                  icon={
                    <Comment
                      className={row?.number_of_comments !== 0 ? "" : " mx-2"}
                    />
                  }
                />
              </div>

              {row?.number_of_comments !== 0 ? (
                <div className="text-nowrap">{row?.number_of_comments}</div>
              ) : (
                ""
              )}
            </div>
          }
        >
          <div className="d-flex align-items-baseline request_comments_in_list_wrapper_style">
            <div
              className={
                row?.number_of_comments !== 0
                  ? "my-requests-action comments_on_request_style"
                  : "my-requests-action"
              }
            >
              <IconButtonWithTooltip
                label="show comments"
                onClick={() => handleRequestComments(row)}
                icon={<Comment />}
              />
            </div>

            {row?.number_of_comments !== 0 ? (
              <div className="text-nowrap">{row?.number_of_comments}</div>
            ) : (
              ""
            )}
          </div>
        </HasPrivileges>
      ),
    },
    {
      name: t("notes"),
      wrap: true,
      grow: 0.5,
      center: true,
      selector: "layer_notes",
      ignoreRowClick: true,
      width: "250px",
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={250}
        >
          <div className="py-2">
            {(row?.layer_approvals ?? [])?.map((layer) => (
              <p className="mb-1">
                {layer?.acceptor
                  ? layer?.isApproved
                    ? t("layer-level-approval", {
                      level: layer?.level,
                      suffix: HelperFns.getOrderSuffix(layer?.level),
                      acceptor: layer?.acceptor?.name,
                    })
                    : t("layer-level-rejection", {
                      level: layer?.level,
                      suffix: HelperFns.getOrderSuffix(layer?.level),
                      acceptor: layer?.acceptor?.name,
                    })
                  : t("layer-level-pending", {
                    level: layer?.level,
                    suffix: HelperFns.getOrderSuffix(layer?.level),
                  })}
              </p>
            ))}
          </div>
        </ShowMoreText>
      ),
    },
    {
      name: "",
      wrap: true,
      center: true,
      selector: "action",
      grow: 0.75,
      cell: (row) => {
        switch (row?.status?.toLowerCase()) {
          case "pending":
            // request has no layers or it has layers and auth if one of pending acceptors
            if (
              row?.pending_acceptors?.length === 0 ||
              (row?.pending_acceptors?.length > 0 &&
                row?.pending_acceptors?.includes(+props?.authUserId))
            ) {
              return (
                <HasPrivileges
                  reqireMain={[
                    currentRoute.pathname.includes("requests")
                      ? Privilages.MANAGE_EMPLOYEE_REQUESTS
                      : Privilages.VIEW_EMPLOYEE_REQUESTS,
                  ]}
                  allowBP
                  avalibleOnExpire={false}
                  altExpireChildren={
                    <div className="cards_table_actions">
                      {row?.type === "Edit Attendance" ? (
                        <span className="btn">
                          <IconButtonWithTooltip
                            label="show request"
                            icon={
                              <FontAwesomeIcon
                                size="sm"
                                color="999"
                                icon={faEye}
                              />
                            }
                          />
                        </span>
                      ) : (
                        <>
                          <AcceptIconButton />
                          <TimesIconButton />
                        </>
                      )}
                    </div>
                  }
                >
                  <div className="cards_table_actions">
                    {props?.requstsLoader.includes(row.id) ? (
                      <Spinner />
                    ) : row?.requestType?.id === REQUESTS.EDIT_ATTENDANCE ? (
                      <IconButtonWithTooltip
                        label="show request"
                        onClick={() => handleShowAttRequestInfo(row)}
                        icon={
                          <FontAwesomeIcon size="sm" color="999" icon={faEye} />
                        }
                      />
                    ) : (
                      <>
                        <AcceptIconButton
                          onClick={
                            row?.requestType?.id === REQUESTS.OVERTIME
                              ? () => handleAcceptOvertimeRequest(row)
                              : () => handleAcceptRequest(row)
                          }
                        />
                        <TimesIconButton
                          label="reject"
                          onClick={() => handleRejectRequest(row)}
                        />
                      </>
                    )}
                    {row?.documents?.map((document) => (
                      <DownloadFileIconButton
                        key={document?.file?.id}
                        href={document?.file?.path}
                      />
                    ))}
                  </div>
                </HasPrivileges>
              );
            } else {
              break;
            }

          case "accepted":
            return (
              <HasPrivileges
                reqireMain={[
                  currentRoute.pathname.includes("requests")
                    ? Privilages.MANAGE_EMPLOYEE_REQUESTS
                    : Privilages.VIEW_EMPLOYEE_REQUESTS,
                ]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="cards_table_actions">
                    {row?.type?.toLowerCase() === "overtime" &&
                      row?.subModel?.can_edit_overtime_request ? (
                      <EditIconButton />
                    ) : (
                      ""
                    )}
                  </div>
                }
              >
                <div className="cards_table_actions">
                  {props?.requstsLoader.includes(row.id) ? (
                    <Spinner />
                  ) : HelperFns.canCancelRequest(row) &&
                    row?.requestType?.id != REQUESTS.OVERTIME ? (
                    <TimesIconButton
                      label="Cancel"
                      onClick={() => handleCancelRequest(row)}
                    />
                  ) : null}

                  {row?.requestType?.id === REQUESTS.OVERTIME &&
                    row?.subModel?.can_edit_overtime_request ? (
                    <EditIconButton
                      onClick={() => handleEditAcceptedOvertimeRequest(row)}
                    />
                  ) : (
                    ""
                  )}
                </div>

                {row?.documents?.map((document, i) => (
                  <DownloadFileIconButton
                    key={document?.file?.id}
                    href={document?.file?.path}
                  />
                ))}
              </HasPrivileges>
            );

          case "rejected":
            return (
              <HasPrivileges
                reqireMain={[
                  currentRoute.pathname.includes("requests")
                    ? Privilages.MANAGE_EMPLOYEE_REQUESTS
                    : Privilages.VIEW_EMPLOYEE_REQUESTS,
                ]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={<></>}
              >
                {row?.documents?.map((document, i) => (
                  <DownloadFileIconButton
                    key={document?.file?.id}
                    href={document?.file?.path}
                  />
                ))}
              </HasPrivileges>
            );
          default:
            break;
        }
      },
      ignoreRowClick: true,
    },
  ];

  const handleAcceptRequest = (row) => {
    swal({
      title: t("default_warning_accept_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        if (row?.requestType?.id !== REQUESTS.OVERTIME) {
          props.acceptInboundRequestAction(
            row?.id,
            props.employeeId,
            props.pageFlag,
            false,
            refetchQueries
          );
          return;
        }
      }
    });
  };

  const handleRejectRequest = (row) => {
    swal({
      title: t("default_warning_reject_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.rejectInboundRequestAction(
          row?.id,
          props.employeeId,
          props.pageFlag,
          false,
          refetchQueries
        );
      }
    });
  };

  return (
    <div className="my-requests text-left">
      {props?.editAcceptedOvertimeRequestLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}
      <div className="side-title">{title}</div>
      <div className="mb-2">
        <div className="row align-items-baseline d-flex justify-content-between m-0 p-0 mb-3 flex-wrap">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <FloatingMenu
              list={
                <Box sx={{ width: 300, px: 1.5, py: 0.5 }}>
                  <CheckboxForm
                    formName={dataTableRef}
                    options={Constants.requestsStatusFilter?.map(
                      ({ label, value }) => ({
                        label: t(`${label.toLocaleLowerCase()}`),
                        value,
                      })
                    )}
                    name="statusFilter"
                    type="checkbox"
                    containerStyle="mb-2 mt-2 pt-1"
                    optionsContainerStyle="optionsContainerStyle"
                    optionItemStyle="optionItemStyle w-60px"
                    optionInputStyle=" "
                    optionLabelStyle="optionLabelStyle"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                </Box>
              }
            />

            <BSelect
              reducer="super"
              name="employees"
              formName={dataTableRef}
              options={employeesFilterOptions}
              keepDefaultStyle
              optionLabel="name"
              rootStyle="mr-2"
              optionValue="id"
              isMulti={true}
              containerStyle="w-mobile-256px custom-employees-requests-filter-input-style pl-3 pt-2"
              placeholder={t("employees")}
              icon="person"
            />
            <BSelect
              isClearable
              reducer="super"
              name="office_id"
              formName={dataTableRef}
              options={officesFilterOptions}
              keepDefaultStyle
              optionLabel="name"
              rootStyle="mr-2"
              optionValue="id"
              containerStyle="w-mobile-256px custom-employees-requests-filter-input-style pl-3 pt-2"
              placeholder={t("office")}
              icon="office"
            />
            <CheckboxBooleanForm
              formName={dataTableRef}
              name="directManaged"
              options={[t("Only show employees I manage directly")]}
              type="checkbox"
              containerStyle="mt-1"
              setWithValue
            />
          </div>
          <div className="d-flex align-items-center ml-0 ml-lg-auto">
            <div className="d-flex flex-wrap align-items-center">
              <BSelect
                name="type"
                optionLabel="label"
                optionValue="value"
                options={REQUESTS.InboundRequestTypeOptions}
                formName={dataTableRef}
                keepDefaultStyle
                containerStyle="custom-types-requests-filter-input-style"
                isClearable
                getOptionLabel={(option) =>
                  t(`${option.label.toLocaleLowerCase()}`)
                }
                placeholder={t("type")}
                icon="type"
              />
              <BSelect
                name="month"
                optionLabel="value"
                optionValue="key"
                options={Constants.AllMonthData}
                formName={dataTableRef}
                keepDefaultStyle
                containerStyle="month-picker mx-2"
                getOptionLabel={(option) => t(option.value)}
                placeholder={t("month")}
                icon="calendar"
              />
              <BSelect
                name="payable_month"
                label="payable at"
                optionLabel="value"
                optionValue="key"
                options={Constants.MonthData}
                formName={dataTableRef}
                keepDefaultStyle
                containerStyle="month-picker mx-2 w-185"
                getOptionLabel={(option) => t(option.value)}
                placeholder={t("payable at month")}
                icon="calendar"
                dependOn="type"
                dependancyType="equal"
                dependancyValue={[REQUESTS.OVERTIME]}
                isClearable
              />
              <BSelect
                name="year"
                optionLabel="value"
                optionValue="value"
                options={Constants.YearData}
                formName={dataTableRef}
                keepDefaultStyle
                containerStyle="year-picker w-mobile-114px w-110"
                placeholder={t("year")}
                isClearable
                icon="calendar"
              />
            </div>
          </div>
        </div>
      </div>
      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        responsive={true}
        data={inboundRequestsData}
        noHeader
        persistTableHead
        sortServer
        onSort={props.onSorting}
        defaultSortAsc={props.data[dataTableRef].sorting.dir == "asc"}
        defaultSortField={props.data[dataTableRef].sorting.key}
        pagination={false}
        paginationServer={false}
        progressPending={inboundRequestsLoading}
        progressComponent={<Loader />}
        customStyles={{ tableWrapper: { style: { minWidth: 1100 } } }}
      />
      <Pagination
        styleWraper=""
        onPaginate={onPaginate}
        customPaginator={pagination}
      />
      {/* Start Overtime modal */}
      <OvertimeModal refetchQueries={refetchQueries} />
      {/* End Overtime modal */}

      {/* Start View Att Request modal */}
      <AttRequestInfoModal
        employeeId={props.employeeId}
        pageFlag={props.pageFlag}
        inboundRequests
        refetchQueries={refetchQueries}
      />
      {/* End View Att Request modal */}

      {/* Start of Request Comments modal */}
      <RequestCommentsModal isInboundRequestsPage />
      {/* End of Request Comments modal */}

      <EditLeavesModal inboundRequests={true} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    authUserId: state.auth.userProfile.id,
    requstsLoader: state.super.requstsLoader,
    editAcceptedOvertimeRequestLoading:
      state.super.editAcceptedOvertimeRequestLoading,
  };
};

export default connect(mapStateToProps, {
  acceptInboundRequestAction,
  rejectInboundRequestAction,
  fetchInboundRequestsSuccess,
  showOvertimeModalAction,
  showViewAttRequestModal,
  fetchEditAccpetedOvertimeRequestDataAction,
  showRequestCommentsModalAction,
  onFormResetAction,
})(InboundRequests);
