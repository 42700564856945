import * as Yup from "yup";
import React, { useState, useEffect } from "react";


const TypesWithOptions = [
    "dropList",
    "checkbox",
    "radio"

];

export const FormBuilderFieldScheme = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.mixed().when("apply_description", {
        is: true,
        then: Yup.string().required(),
    }),
    validation_type_ids: Yup.mixed().when("apply_validation", {
        is: true,
        then: Yup.array().required(),
    }),
    grid: Yup.string(),
    order: Yup.string(),
    type: Yup.string().required(),
    selection_type: Yup.mixed().when("type", {
        is: 'dropList',
        then: Yup.string().required(),
    }),
    dependent_option_names: Yup.mixed().when("parent_field_id", {
        is: (parent_field_id) => !!parent_field_id,
        then: Yup.array().required().of(
            Yup.object({
                label: Yup.string().required(),
                value: Yup.string().required(),
            })
        ),
    }),
    options: Yup.mixed().when("type", {
        is: (type) => TypesWithOptions?.includes(type),
        then: Yup.array().required().of(
            Yup.object({
                label: Yup.string().required(),
                value: Yup.string().required(),
            })
        ),
    }),

});

export const recruitmentProcessFormScheme = Yup.object().shape({
    stages: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required(),
            form: Yup.object().shape({
                fields: Yup.array().of(
                    Yup.object().shape({
                        name: Yup.string().required(),
                        description: Yup.mixed().when("apply_description", {
                            is: true,
                            then: Yup.string().required(),
                        }),
                        validation_type_ids: Yup.mixed().when("apply_validation", {
                            is: true,
                            then: Yup.array().required(),
                        }),
                        grid: Yup.string(),
                        order: Yup.string(),
                        type: Yup.string().required(),
                        selection_type: Yup.mixed().when("type", {
                            is: 'dropList',
                            then: Yup.string().required(),
                        }),
                        dependent_option_names: Yup.mixed().when("parent_field_id", {
                            is: (parent_field_id) => !!parent_field_id,
                            then: Yup.array().required().of(
                                Yup.object({
                                    label: Yup.string().required(),
                                    value: Yup.string().required(),
                                })
                            ),
                        }),
                        options: Yup.mixed().when("type", {
                            is: (type) => TypesWithOptions?.includes(type),
                            then: Yup.array().required().of(
                                Yup.object({
                                    label: Yup.string().required(),
                                    value: Yup.string().required(),
                                })
                            ),
                        }),
                    })
                ),
            }),
        })
    ),
});

export const runValidation = (inputField) => {
    console.log("inputField", inputField);
    return FormBuilderFieldScheme.isValidSync(inputField, { abortEarly: true })

}


export const useFormBuilderFieldValidation = (inputField, prefixValidationName = "") => {
    const [isValid, setIsValid] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    useEffect(() => {
        if (inputField) {
            runValidation(inputField, prefixValidationName)
        }
    }, [inputField]);

    const runValidation = (inputField, prefixValidationName) => {
        FormBuilderFieldScheme.validate(inputField, { abortEarly: false })
            .then((valid, data) => {
                setIsValid(true);
                setValidationErrors({})
            })
            .catch((err) => {
                setIsValid(false);
                setValidationErrors(err.inner.reduce((acc, curr) => {
                    acc[`${prefixValidationName}.${curr.path}`] = curr.message;
                    return acc;
                }, {}));
                console.log({ err })
            });
    }

    return { isValid, validationErrors };
}

