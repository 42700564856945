import React from "react";

import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Privilages from "../../Constants/Privilages";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

const LeadNameCell = ({ lead = {}, isLeadProfile }) =>
  isLeadProfile ? (
    <Name name={lead?.name} />
  ) : (
    <HasPrivileges
      reqireMain={[Privilages.VIEW_LEAD_PROFILE]}
      altChildren={<Name name={lead?.name} />}
    >
      <Tooltip title={lead?.name}>
        <Link className="text-truncate" to={`crm/lead/${lead?.id}`}>
          {lead?.name}
        </Link>
      </Tooltip>
    </HasPrivileges>
  );

export default LeadNameCell;

const Name = ({ name }) => (
  <Tooltip title={name}>
    <strong className="text-truncate">{name}</strong>
  </Tooltip>
);
