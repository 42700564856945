import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import {
  onFormResetAction,
  toggleAssignmentPreviewModal,
} from "../../Store/Actions";
import moment from "moment";
import Privileges from "../../Constants/Privilages";
import { addEditDeleteAssignmentPrivileges } from "../../Constants";

import {
  AssignmentPreviewNamePart,
  AssignmentPreviewCanEditPart,
} from "./shared";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import MainModal from "../../Components/MainModal";
import DateRangeIcon from "@mui/icons-material/DateRange";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

const AssignmentPreview = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer State
  const {
    id,
    to,
    from,
    name,
    canEditTo,
    canEditFrom,
    change_shift = false,
  } = useSelector((state) => state.assignments.upsertAssignmentForm);

  /* ↓ Helpers ↓ */

  const handleClosingAssignmentPreviewModal = () => {
    dispatch(toggleAssignmentPreviewModal(false));
    dispatch(onFormResetAction("upsertAssignmentForm"));
  };

  const handleEdit = () => {
    props?.onEdit(id, change_shift);
  };

  const handleDelete = () => {
    props.onDelete(id);
  };

  return (
    <MainModal
      isOpen
      size="lg"
      modalTitle={t("assignment preview")}
      toggle={handleClosingAssignmentPreviewModal}
      className="assignments-modal"
    >
      <div className="preview-modal-info-container">
        {/* assignment name section start */}
        <div className="d-flex flex-column align-items-start justiyf-content-center mt-3">
          <AssignmentPreviewNamePart name={name} />
        </div>
        {/* assignment name section end */}

        {/* from & to section start */}
        <div className="d-flex align-items-center justify-content-between">
          <div className="w-100">
            <h5 className="mb-2 font-weight-bold">{t("from")}</h5>
            <div className="d-flex align-items-center justify-content-start">
              <DateRangeIcon
                sx={{
                  fontSize: 16,
                  color: "rgb(121 121 121 / 69%)",
                  marginInline: 10,
                }}
              />
              <span>{moment(from).format("DD-MM-YYYY")}</span>
            </div>
          </div>
          <div className="w-100">
            <h5 className="mb-2 font-weight-bold">{t("to")}</h5>
            <div className="d-flex align-items-center justify-content-start">
              <DateRangeIcon
                sx={{
                  fontSize: 16,
                  color: "rgb(121 121 121 / 69%)",
                  marginInline: 10,
                }}
              />
              <span>{moment(to).format("DD-MM-YYYY")}</span>
            </div>
          </div>
        </div>
        {/* from & to section end */}

        {/* assignment preview can edit part start */}
        <AssignmentPreviewCanEditPart />
        {/* assignment preview can edit part end */}

        {/* edit and delete section start */}
        <div className="edit-delete-icons-container">
          {canEditFrom || canEditTo ? (
            <HasPrivileges
              allowBP
              reqireMain={
                change_shift
                  ? [Privileges.CHANGE_SHIFT]
                  : addEditDeleteAssignmentPrivileges
              }
            >
              <RemoveIconButton onClick={handleDelete} />
            </HasPrivileges>
          ) : (
            ""
          )}
          {canEditFrom || canEditTo ? (
            <HasPrivileges
              allowBP
              reqireMain={
                change_shift
                  ? [Privileges.CHANGE_SHIFT]
                  : addEditDeleteAssignmentPrivileges
              }
            >
              <EditIconButton onClick={handleEdit} />
            </HasPrivileges>
          ) : (
            ""
          )}
        </div>
        {/* edit and delete section end */}
      </div>
    </MainModal>
  );
};

export default AssignmentPreview;
