import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import {
  updateValueAction,
  setServerValidationAction,
} from "../../Store/Actions";
import { upsertCheckInOutFormMutation } from "../../Graphql/mutation";

import MainModal from "../MainModal";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { RemoveIconButton } from "../IconButtonWithTooltip";
import { CheckboxBooleanForm, InputForm } from "form-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const fieldInitState = { id: null, name: "", type: "text", canDelete: true };
const formInitState = {
  id: null,
  name: "",
  checkInOutFormFields: [fieldInitState],
};

const MultipleCheckInOutForm = ({
  state,
  onClose,
  formName,
  formsData,
  refetchFormsData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [form, setForm] = useState(formInitState);
  const [isAttachment, setIsAttachment] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Server State
  const [
    upsertCheckInOutForm,
    { loading: upsertCheckInOutLoading, error: upsertError, reset },
  ] = useCustomMutation(upsertCheckInOutFormMutation);

  /* ↓ State Effects ↓ */

  useEffect(() => {
    if (upsertError) {
      handleSetServerError(upsertError);
    } else {
      reset();
      handleSetServerError();
    }
  }, [upsertError]);

  useEffect(() => {
    if (state.isOpen) {
      if (state?.isEdit) {
        const form = formsData?.find((d) => d?.id === state?.formID);
        setForm({
          ...form,
          checkInOutFormFields: form?.checkInOutFormFields?.filter(
            (f) => f?.name !== "attachment"
          ),
        });
        setIsAttachment(
          Boolean(
            form?.checkInOutFormFields?.find((f) => f?.name === "attachment")
          )
        );
      }
    } else {
      setForm(formInitState);
      setIsAttachment(false);
      setIsSubmitting(false);
      handleSetServerError();
    }
  }, [state.isOpen]);

  /* ↓ Helpers ↓ */

  const handleAddLocalField = () => {
    setForm((prev) => ({
      ...prev,
      checkInOutFormFields: [...prev.checkInOutFormFields, fieldInitState],
    }));
  };
  const handleRemoveLocalField = (i) => {
    setForm((prev) => ({
      ...prev,
      checkInOutFormFields: prev.checkInOutFormFields.filter(
        (_, ind) => ind !== i
      ),
    }));
  };

  const handleFormNameChange = (e) => {
    setForm((prev) => ({ ...prev, name: e.target.value }));
  };
  const handleFormNameFieldChange = (e, i) => {
    setForm((prev) => ({
      ...prev,
      checkInOutFormFields: prev.checkInOutFormFields?.map((field, ind) =>
        i === ind ? { ...field, name: e.target.value } : field
      ),
    }));
  };

  const handleSetServerError = (err) => {
    dispatch(
      setServerValidationAction(
        err?.graphQLErrors?.[0]?.extensions?.validation || {}
      )
    );
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    const attachment = form.checkInOutFormFields?.find(
      (f) => f.name === "attachment"
    ) || {
      id: null,
      name: "attachment",
      type: "attachment",
    };
    const fields = form.checkInOutFormFields?.map(
      ({ canDelete, ...field }) => field
    );

    upsertCheckInOutForm({
      variables: {
        input: {
          ...form,
          checkInOutFormFields: isAttachment ? [...fields, attachment] : fields,
        },
      },
      onCompleted: ({ upsert_checkInOut_form }) => {
        dispatch(
          updateValueAction(
            formName,
            `check_${state?.type}_form_id`,
            upsert_checkInOut_form?.id
          )
        );
        refetchFormsData();
        onClose();
      },
      onError: (error) => {
        handleSetServerError(error);
      },
    });
  };

  return (
    <MainModal
      isOpen={state.isOpen}
      modalTitle={t(
        `${state?.isEdit ? "Edit" : "New"} Check-${state?.type} Form`
      )}
      toggle={onClose}
      btnOnClick={handleSubmit}
      btnOnCancelClick={onClose}
      btnSubmitLoading={upsertCheckInOutLoading}
      className="edit_attendance_profile"
    >
      <InputForm
        required
        name="name"
        label="name"
        labelStyle="w-100"
        inputContainerStyle="w-100"
        value={form.name}
        validationName="input.name"
        formSubmitting={isSubmitting}
        onChange={handleFormNameChange}
        formServerValidation="checkInOutFormServerValidations"
      />

      <div className="d-flex align-items-center mt-2 mb-3">
        <span>{t("fields")}</span>
        <button
          type="button"
          className="btn add_new_btn_style"
          onClick={handleAddLocalField}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
      <CheckboxBooleanForm
        options={["Add field for attachments"]}
        containerStyle="mb-3"
        checked={isAttachment}
        onChange={() => setIsAttachment((prev) => !prev)}
      />
      {form.checkInOutFormFields?.map((field, i) => (
        <React.Fragment key={i}>
          <strong>
            {t("field")} {i + 1}
          </strong>
          <div className="d-flex gap-10">
            <div className="flex-fill">
              <InputForm
                label="name"
                labelStyle="w-100"
                value={field.name}
                inputContainerStyle="w-100"
                formSubmitting={isSubmitting}
                formServerValidation="checkInOutFormServerValidations"
                validationName={`input.checkInOutFormFields.${i}.name`}
                onChange={(e) => handleFormNameFieldChange(e, i)}
              />
            </div>
            {state.isEdit ? (
              field.canDelete
            ) : form.checkInOutFormFields.length > 1 ? (
              <RemoveIconButton onClick={() => handleRemoveLocalField(i)} />
            ) : null}
          </div>
        </React.Fragment>
      ))}
    </MainModal>
  );
};

export default MultipleCheckInOutForm;
