import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import MainModal from "../../Components/MainModal";
import Loader from "../../Components/Loader";

import {
  CheckboxBooleanForm,
  InputForm,
  RadioboxForm,
  BSelect,
  DateTimePickerForm,
} from "form-builder";
import * as REQUESTS from "../../Constants/Requests";

import {
  onInputResetAction,
  dismissRequestRuleAction,
  upsertRequestRulesAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHands } from "@fortawesome/free-solid-svg-icons";
import LocationCityIcon from "@mui/icons-material/LocationCity";

const formName = "requestRule";
const formNameValidation = "requestRuleValidation";
const formServerValidation = "requestRuleServerValidation";

const RequestRule = (props) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { t } = useTranslation();
  const formProps = {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
  };

  const handledepartmentUpdated = () => {
    props.onInputResetAction(formName, "employees_ids");
  };

  useEffect(() => {
    return () => {
      setFormSubmitting(false);
    };
  }, [props.requestRuleModal.isVissible]);

  const onDepartmentUpdate = useDidUpdateEffect(handledepartmentUpdated, [
    props.requestRule.department_id,
  ]);

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (props.requestRuleValidation?.length) {
      return;
    }
    props.upsertRequestRulesAction(props.requestRule);
  };

  return (
    <MainModal
      parentClassName="boxContainer boxContainer_shadow_style p-4"
      isOpen={props.requestRuleModal.isVissible}
      btnSubmitLoading={props.requestRuleModal.isLoading}
      toggle={() => props.dismissRequestRuleAction()}
      btnOnClick={handleSubmit}
      modalTitle={t(`Request Rule`)}
      size="lg"
    >
      <>
        {props.loadingRequestRulesUsers ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}
        <div className="px-3 py-2">
          <RadioboxForm
            {...formProps}
            validateBy="textRequired"
            labelStyle="h5 mb-1"
            containerStyle="mb-0"
            optionsContainerStyle="d-flex my-2 flex-row"
            optionItemStyle="mr-5"
            name="status_id"
            validationName="input.status_id"
            label="action"
            optionInputStyle
            options={[
              {
                label: "accept",
                value: "1",
              },
              {
                label: "reject",
                value: "5",
              },
              {
                label: "deny",
                value: "6",
              },
            ]}
          />
          <div className="my-3">
            <label className="h5 mb-1">{t("select requests")}</label>
            <BSelect
              {...formProps}
              name="request_types_ids"
              placeholder={t("select requests")}
              validationName="input.request_types_ids"
              keepDefaultStyle
              options={REQUESTS.RequestTypeOptions}
              optionLabel="label"
              optionValue="value"
              validateBy="arrayRequired"
              containerStyle="custom-tardiness-container align-items-baseline"
              inputContainerStyle="w-100"
              isMulti
              icon={<FontAwesomeIcon icon={faHands} />}
            />
          </div>
          <div className="my-3">
            <label className="h5 mb-1">{t("select department")}</label>
            <BSelect
              {...formProps}
              name="department_id"
              placeholder={t("select department")}
              validationName="input.department"
              keepDefaultStyle
              options={props?.departmentsOptions}
              optionLabel="name"
              optionValue="id"
              // validateBy="textRequired"
              containerStyle="custom-tardiness-container align-items-baseline"
              inputContainerStyle="w-100 mt-1"
              isDisabled={
                !HelperFns.checkPrivileges({
                  privileges: [
                    Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS,
                  ],
                  allowBP: true,
                })
              }
              icon={<LocationCityIcon />}
            />
          </div>
          <div className="mt-3 mb-2">
            <label className="h5 mb-1">{t("employees")}</label>
            <CheckboxBooleanForm
              {...formProps}
              options={["Apply on All Employees in the department"]}
              name="all_department"
              validationName="input.all_department"
              type="checkbox"
            />
            <BSelect
              {...formProps}
              dependOn="all_department"
              dependancyType="equal"
              dependancyValue={[0, false]}
              name="employees_ids"
              placeholder={t("select department")}
              validationName="input.employees_ids"
              keepDefaultStyle
              options={[
                ...props?.requestRulesUsersOpts?.map((opt) => ({
                  ...opt,
                  department_id: opt?.department?.id,
                })),
              ]}
              optDependType={"equal"}
              optDependValue={[props?.requestRule?.department_id]}
              optDependKey="department_id"
              validateBy="arrayRequired"
              containerStyle="custom-tardiness-container align-items-baseline h-auto w-100"
              inputContainerStyle="w-100"
              isMulti
              icon={<LocationCityIcon />}
            />
          </div>
          <DateTimePickerForm
            {...formProps}
            label="apply starting from"
            name="start_date"
            validationName="input.start_date"
            validateBy="textRequired"
          />

          <span className="notes-style mt-2 d-inline-block">
            <strong>{t("Note")}:</strong>{" "}
            {t(
              "This Will Be applied On All Pending Requests Starting From This Date"
            )}
          </span>
        </div>
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    isViewWorkTimeLoading: state.super.isViewWorkTimeLoading,
    requestRuleModal: state.super.requestRuleModalActions,
    requestRule: state.super.requestRule,
    requestRuleValidation: state.super.requestRuleValidation,
    loadingRequestRulesUsers: state.super.loadingRequestRulesUsers,
    requestRulesUsersOpts: state.super.requestRulesUsersOpts,
    departmentsOptions: state.super.departmentsOptions,
  };
};

export default connect(mapStateToProps, {
  dismissRequestRuleAction,

  onInputResetAction,
  upsertRequestRulesAction,
})(RequestRule);
