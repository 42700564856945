import moment from "moment";
import Types from "../Actions/types";

export const initState = {
  pettyCashList: {
    emp_id: "",
    currency_id: "",
    office_id: "",
    from: "",
    to: "",
    type: ["add", "deduct"],
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },
  pettyCashEmployees: [],
  avaliablePettyCashEmployeesFilterOptions: [],

  modifyPettyCashBalanceModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  modifyPettyCashBalanceModal: { emp_id: "", currency_id: "" },
  modifyPettyCashBalanceModalValidation: [],
  modifyPettyCashBalanceModalServerValidation: {},

  deletePettyCashFileLoading: false,

  employeeCurrentBalanceLoading: false,
  employeeCurrentBalanceData: {},

  employeePettyCashOfficeCurrency: [],
  employeeCurrenciesInPettyCashLoading: false,

  companySettingsList: {
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },

  companySettingsModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  companySettingsModal: {},
  companySettingsModalValidation: [],
  companySettingsModalServerValidation: {},

  deleteCompanySettingsLoading: false,

  companyExpensesList: {
    emp_id: "",
    currency_id: "",
    category_id: "",
    sub_category_id: "",
    office_id: "",
    year: new Date().getFullYear(),
    month: moment().format("MM"),
    from: "",
    to: "",
    type: ["expense", "office_expense"],
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },

  currencyExpenseFilterOptions: [],
  companyExpenseCategoryData: [],
  companyExpenseSubCategoryData: [],

  companyExpensesModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  companyExpensesModal: {
    emp_id: "",
    currency_id: "",
    category_id: "",
    sub_category_id: "",
    office_id: "",
  },
  companyExpensesModalValidation: [],
  companyExpensesModalServerValidation: {},

  companyExpensesDataLoading: false,
  expenseFormSubCatgeoriesData: [],
  expenseFormCurrencyData: [],
  expenseFormEmployeesData: [],

  expenseTotalBalanceData: [],

  exportCompanyExpensesLoading: false,

  deleteCompanyExpenseLoading: false,

  pettyCashCurrenciesOptionsData: [],
};

export const financesReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.FETCH_PETTY_CASH_LIST_ATTEMPT:
    case Types.FETCH_COMPANY_SETTINGS_LIST_ATTEMPT:
    case Types.FETCH_COMPANY_EXPENSE_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
        },
      };
    case Types.FETCH_PETTY_CASH_LIST_SUCCESS:
    case Types.FETCH_COMPANY_SETTINGS_LIST_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
        },
      };
    case Types.FETCH_COMPANY_EXPENSE_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
        },
      };
    case Types.FETCH_PETTY_CASH_LIST_FAILED:
    case Types.FETCH_COMPANY_SETTINGS_LIST_FAILED:
    case Types.FETCH_COMPANY_EXPENSE_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: [],
        },
      };
    case Types.FETCH_PETTY_CASH_EMPLOYEES:
      return {
        ...state,
        pettyCashEmployees: action?.payload?.data,
      };
    case Types.FETCH_AVALIABLE_PETTY_CASH_EMPLOYEES_FILTER_OPTIONS:
      return {
        ...state,
        avaliablePettyCashEmployeesFilterOptions: action?.payload,
      };
    case Types.SHOW_PETTY_CASH_BALANCE_MODAL:
    case Types.SHOW_COMPANY_SETTINGS_MODAL:
    case Types.SHOW_COMPANY_EXPENSE_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data,
        },
      };
    case Types.HIDE_PETTY_CASH_BALANCE_MODAL:
    case Types.HIDE_COMPANY_SETTINGS_MODAL:
    case Types.HIDE_COMPANY_EXPENSE_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
        companyExpensesModalServerValidation: {},
        modifyPettyCashBalanceModalServerValidation: {},
        companySettingsModalServerValidation: {},
      };
    case Types.UPSERT_PETTY_CASH_BALANCE_ATTEMPT:
      return {
        ...state,
        modifyPettyCashBalanceModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.UPSERT_PETTY_CASH_BALANCE_SUCCESS:
      return {
        ...state,
        modifyPettyCashBalanceModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };
    case Types.UPSERT_COMPANY_SETTINGS_ATTEMPT:
      return {
        ...state,
        companySettingsModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.UPSERT_COMPANY_SETTINGS_SUCCESS:
      return {
        ...state,
        companySettingsModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };
    case Types.UPSERT_COMPANY_EXPENSE_ATTEMPT:
      return {
        ...state,
        companyExpensesModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.UPSERT_COMPANY_EXPENSE_SUCCESS:
      return {
        ...state,
        companyExpensesModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };
    case Types.UPSERT_PETTY_CASH_BALANCE_FAILED:
    case Types.UPSERT_COMPANY_SETTINGS_FAILED:
    case Types.UPSERT_COMPANY_EXPENSE_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload?.modalMessage ?? "",
        },
        [action.payload.serverRef]: action.payload?.errors ?? {},
      };
    case Types.FETCH_EMPLOYEE_CURRENT_BALANCE_ATTEMPT:
      return {
        ...state,
        employeeCurrentBalanceData: {},
        employeeCurrentBalanceLoading: true,
      };
    case Types.FETCH_EMPLOYEE_CURRENT_BALANCE_SUCCESS:
      return {
        ...state,
        employeeCurrentBalanceData: action?.payload,
        employeeCurrentBalanceLoading: false,
      };
    case Types.FETCH_EMPLOYEE_CURRENT_BALANCE_FAILED:
      return {
        ...state,
        employeeCurrentBalanceData: {},
        employeeCurrentBalanceLoading: false,
      };
    case Types.DELETE_COMPANY_SETTINGS_ATTEMPT:
      return {
        ...state,
        deleteCompanySettingsLoading: true,
      };
    case Types.DELETE_COMPANY_SETTINGS_SUCCESS:
    case Types.DELETE_COMPANY_SETTINGS_FAILED:
      return {
        ...state,
        deleteCompanySettingsLoading: false,
      };
    case Types.FETCH_COMPANY_EXPENSES_CATEGORIES:
      return {
        ...state,
        companyExpenseCategoryData: action?.payload?.data,
      };
    case Types.FETCH_COMPANY_EXPENSES_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        companyExpenseSubCategoryData: action?.payload?.data,
      };
    case Types.FETCH_COMPANY_EXPENSES_SUBCATEGORIES_FAILED:
      return {
        ...state,
        companyExpenseSubCategoryData: [],
      };
    case Types.FETCH_EXPENSE_TOTAL_BALANCE_SUCCESS:
      return {
        ...state,
        expenseTotalBalanceData: action?.payload,
      };
    case Types.FETCH_EXPENSE_TOTAL_BALANCE_FAILED:
      return {
        ...state,
        expenseTotalBalanceData: [],
      };
    case Types.EXPORT_COMPANY_EXPENSES_ATTEMPT:
      return {
        ...state,
        exportCompanyExpensesLoading: true,
      };
    case Types.EXPORT_COMPANY_EXPENSES_SUCCESS:
    case Types.EXPORT_COMPANY_EXPENSES_FAILED:
      return {
        ...state,
        exportCompanyExpensesLoading: false,
      };
    case Types.FETCH_EXPENSE_FORM_SUBCATGERORIES_ATTEMPT:
      return {
        ...state,
        companyExpensesDataLoading: true,
      };
    case Types.FETCH_EXPENSE_FORM_SUBCATGERORIES_SUCCESS:
      return {
        ...state,
        expenseFormSubCatgeoriesData: action?.payload?.data,
        companyExpensesDataLoading: false,
      };
    case Types.FETCH_EXPENSE_FORM_SUBCATGERORIES_FAILED:
      return {
        ...state,
        expenseFormSubCatgeoriesData: [],
        companyExpensesDataLoading: false,
      };
    case Types.FETCH_EXPENSE_FORM_CUREENCIES_ATTEMPT:
      return {
        ...state,
        companyExpensesDataLoading: true,
      };
    case Types.FETCH_EXPENSE_FORM_CUREENCIES_SUCCESS:
      return {
        ...state,
        expenseFormCurrencyData: action?.payload,
        companyExpensesModal: {
          ...state.companyExpensesModal,
          currency_id:
            action?.payload.length === 1
              ? action?.payload[0].id
              : state?.companyExpensesModal?.currency_id,
        },
        companyExpensesDataLoading: false,
      };
    case Types.FETCH_EXPENSE_FORM_CUREENCIES_FAILED:
      return {
        ...state,
        expenseFormCurrencyData: [],
        companyExpensesDataLoading: false,
      };
    case Types.FETCH_EXPENSE_FORM_EMPLOYEES_ATTEMPT:
      return {
        ...state,
        companyExpensesDataLoading: true,
      };
    case Types.FETCH_EXPENSE_FORM_EMPLOYEES_SUCCESS:
      return {
        ...state,
        expenseFormEmployeesData: action?.payload,
        companyExpensesDataLoading: false,
      };
    case Types.FETCH_EXPENSE_FORM_EMPLOYEES_FAILED:
      return {
        ...state,
        expenseFormEmployeesData: [],
        companyExpensesDataLoading: false,
      };
    case Types.DELETE_COMPANY_EXPENSE_ATTEMPT:
      return {
        ...state,
        deleteCompanyExpenseLoading: true,
      };
    case Types.DELETE_COMPANY_EXPENSE_SUCCESS:
    case Types.DELETE_COMPANY_EXPENSE_FAILED:
      return {
        ...state,
        deleteCompanyExpenseLoading: false,
      };
    case Types.DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_ATTEMPT:
      return {
        ...state,
        companyExpensesDataLoading: true,
      };
    case Types.DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_SUCCESS:
      return {
        ...state,
        companyExpensesDataLoading: false,
        companyExpensesModal: {
          id: action?.payload?.id,
          emp_id: action?.payload?.emp_id,
          office_id: action?.payload?.office?.id,
          category_id: action?.payload?.category?.id,
          sub_category_id: action?.payload?.sub_category?.id,
          details: action?.payload?.details,
          type: action?.payload?.type,
          date: action?.payload?.date,
          amount: action?.payload?.amount.toString(),
          currency_id: action?.payload?.currency?.id,
          on_behalf_of: action?.payload?.type === "expense" ? 1 : 0,
          attachments: action?.payload?.files,
        },
      };
    case Types.DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_FAILED:
      return {
        ...state,
        companyExpensesDataLoading: false,
      };
    case Types.DELETE_PETTY_CASH_ATTACHMENT_FILE_ATTEMPT:
      return {
        ...state,
        deletePettyCashFileLoading: true,
      };
    case Types.DELETE_PETTY_CASH_ATTACHMENT_FILE_SUCCESS:
      return {
        ...state,
        deletePettyCashFileLoading: false,
        modifyPettyCashBalanceModal: {
          id: action?.payload?.id,
          emp_id: action?.payload?.emp_id,
          type: action?.payload?.type,
          date: action?.payload?.date,
          amount: action?.payload?.amount.toString(),
          currency_id: action?.payload?.currency?.id,
          attachments: action?.payload?.files,
        },
      };
    case Types.DELETE_PETTY_CASH_ATTACHMENT_FILE_FAILED:
      return {
        ...state,
        deletePettyCashFileLoading: false,
      };
    case Types.FETCH_PETTY_CASH_CURRENCIES_SUCCESS:
      return {
        ...state,
        pettyCashCurrenciesOptionsData: action?.payload,
      };
    case Types.FETCH_PETTY_CASH_CURRENCIES_FAILED:
      return {
        ...state,
        pettyCashCurrenciesOptionsData: [],
      };
    case Types.FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_ATTEMPT:
      return {
        ...state,
        employeeCurrenciesInPettyCashLoading: true,
      };
    case Types.FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_SUCCESS:
      return {
        ...state,
        employeePettyCashOfficeCurrency: action?.payload,
        modifyPettyCashBalanceModal: {
          ...state.modifyPettyCashBalanceModal,
          currency_id:
            action?.payload.length === 1
              ? action?.payload[0].id
              : state?.modifyPettyCashBalanceModal?.currency_id,
        },
        employeeCurrenciesInPettyCashLoading: false,
      };
    case Types.FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_FAILED:
      return {
        ...state,
        employeePettyCashOfficeCurrency: [],
        employeeCurrenciesInPettyCashLoading: false,
      };

    case Types.LOG_OUT:
      return { ...initState };

    default:
      return state;
  }
};
