import HelperFns from "../../Helpers/HelperFns";
import Types from "../Actions/types";

export const initState = {
  systemPlansList: {
    type: "active",
    name: "",
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },

  systemPlanActions: {
    isVissible: false,
    isLoading: false,
    message: "",
  },

  systemPlan: {
    id: null,
    name: "",
    features: [
      { id: "1", limit: null },
      { id: "2", limit: null },
      { id: "5", limit: null },
      { id: "6", limit: null },
    ],
    trial_value: null,
    is_free: 0,
    selectedPrivileges: [],
  },
  systemPlanValidation: [],
  systemPlanServerValidation: {},

  system_privileges: null,

  newPlanRoles: [
    {
      name: "manger",
      id: "1",
    },
  ],
  loadingNewPlanRoles: false,

  roleForm: {
    id: null,
    name: "",
    managed: [],
    all: [],
    main: [],
  },
  roleFormValidation: [],
  roleServerValidation: {},

  plan_privileges: null,

  loadingPlanPrivileges: false,

  systemRolePlanActions: {
    isVissible: false,
    isEditVissible: false,
    isLoading: false,
    message: "",
  },

  fetchEditRole: false,

  loadingEditPlan: false,

  viewedPlan: null,
  loadingPlanPage: false,

  viewPlanPrivilegesModal: {
    isVissible: false,
  },

  planActionLoading: false, //for Delete && Status

  adminHolidaysList: {
    year: new Date().getFullYear(),
    name: "",
    country_id: null,
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },

  holidaysCountriesFilterOptions: [],

  adminHolidaysModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  adminHolidaysModal: {
    name: "",
    start_date: "",
    end_date: "",
    countries_ids: [],
  },
  adminHolidaysModalValidation: [],
  adminHolidaysModalServerValidation: {},

  deleteAdminHolidaysLoading: false,

  isGenerateDemoLoading: false,

  taxFormClientValidations: [],
};

export const adminReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.FETCH_SYSTEM_PLANS_LIST_ATTEMPT:
    case Types.FETCH_ADMIN_HOLIDAYS_LIST_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
        },
      };
    case Types.FETCH_SYSTEM_PLANS_LIST_SUCCESS:
    case Types.FETCH_ADMIN_HOLIDAYS_LIST_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
          isLoading: false,
        },
      };
    case Types.FETCH_SYSTEM_PLANS_LIST_FAILED:
    case Types.FETCH_ADMIN_HOLIDAYS_LIST_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
        },
      };

    case Types.RESET_SYSTEM_PLAN_FORM:
      return {
        ...state,
        systemPlanActions: {
          ...initState.systemPlanActions,
        },
        systemPlan: {
          ...initState.systemPlan,
          features: [
            { id: "1", limit: null },
            { id: "2", limit: null },
            { id: "5", limit: null },
            { id: "6", limit: null },
          ],
        },
        system_privileges: null,
        systemPlanServerValidation: {},
        systemPlanValidation: [],
      };

    case Types.EDIT_SYSTEM_PLAN_ATTEMPT:
      return {
        ...state,
        loadingEditPlan: true,
      };
    case Types.EDIT_SYSTEM_PLAN_FAILED:
      return {
        ...state,
        loadingEditPlan: false,
      };
    case Types.EDIT_SYSTEM_PLAN_SUCCESS:
      return {
        ...state,
        systemPlanActions: {
          ...initState.systemPlanActions,
        },
        systemPlan: {
          id: action.payload?.plan?.id,
          name: action.payload?.plan?.name,
          features: action.payload?.plan?.features?.map((feat) => ({
            id: feat.id,
            limit: feat?.pivot?.limits,
          })),
          trial_value: action.payload?.plan?.trial_value,
          is_free: action.payload?.plan?.trial_value == null ? 1 : 0,
          selectedPrivileges: action.payload?.plan?.privileges?.map(
            (privilege) => privilege?.id
          ),
        },
        system_privileges: action?.payload?.system_privileges,
        loadingEditPlan: false,
      };

    case Types.UPDTAE_PLAN_FETAURE_INPUT:
      let modifiedFeatures = [...state.systemPlan.features];
      modifiedFeatures[action?.payload?.index].limit =
        action.payload.event.target.value == "unlimted"
          ? null
          : action.payload.event.target.value;

      return {
        ...state,
        systemPlan: { ...state.systemPlan, features: [...modifiedFeatures] },
      };

    case Types.UPSERT_SYSTEM_PLAN_ATTEMPT:
      return {
        ...state,
        systemPlanActions: {
          ...state.systemPlanActions,
          isLoading: true,
        },
        systemPlanServerValidation: {},
        systemPlanValidation: [],
      };
    case Types.UPSERT_SYSTEM_PLAN_SUCCESS:
      return {
        ...state,
        systemPlan: {
          ...initState.systemPlan,
          features: [
            { id: "1", limit: null },
            { id: "2", limit: null },
            { id: "5", limit: null },
            { id: "6", limit: null },
          ],
        },
        systemPlanActions: {
          ...initState.systemPlanActions,
        },
        systemPlanServerValidation: {},
        systemPlanValidation: [],
      };
    case Types.UPSERT_SYSTEM_PLAN_FAILED:
      return {
        ...state,
        systemPlanActions: {
          ...state.systemPlanActions,
          isLoading: false,
        },
        systemPlanServerValidation: action?.payload,
      };

    case Types.FETCH_SYSTEM_PRIVILEGES_ATTEMPT:
      return {
        ...state,
        systemPlanLoading: true,
      };
    case Types.FETCH_SYSTEM_PRIVILEGES_SUCCESS:
      return {
        ...state,
        systemPlanLoading: false,
        system_privileges: action?.payload,
      };
    case Types.FETCH_SYSTEM_PRIVILEGES_FAILED:
      return {
        ...state,
        systemPlanLoading: false,
      };

    case Types.UPDATE_PRIVILEGES_RADIO:
      if (state[action.payload.formName]) {
        switch (action.payload?.name) {
          case "all":
            return {
              ...state,
              [action.payload.formName]: {
                ...state[action.payload.formName],
                managed: state[action.payload.formName]?.managed?.filter(
                  (val) => val != action.payload?.value
                ),
                all: [
                  ...state[action.payload.formName]?.all,
                  action.payload?.value,
                ],
              },
            };
          case "managed":
            return {
              ...state,
              [action.payload.formName]: {
                ...state[action.payload.formName],
                all: state[action.payload.formName]?.all?.filter(
                  (val) => val != action.payload?.value
                ),
                managed: [
                  ...state[action.payload.formName]?.managed,
                  action.payload?.value,
                ],
              },
            };
          default:
            return {
              ...state,
              [action.payload.formName]: {
                ...state[action.payload.formName],
                all: state[action.payload.formName]?.all?.filter(
                  (item) => !action.payload?.value.includes(+item)
                ),
                managed: state[action.payload.formName]?.managed?.filter(
                  (item) => !action.payload?.value.includes(+item)
                ),
              },
            };
        }
      }
      return state;

    case Types.FETCH_PLAN_PRIVILEGES_ATTEMPT:
      return {
        ...state,
        loadingPlanPrivileges: true,
      };

    case Types.FETCH_PLAN_PRIVILEGES_SUCCESS:
      let mainPrivileges = HelperFns.getAllBasicPrivileges(
        JSON.parse(action.payload)
      );
      return {
        ...state,
        roleForm: {
          ...state.roleForm,
          main: [...mainPrivileges],
        },
        loadingPlanPrivileges: false,
        plan_privileges: action.payload,
      };

    case Types.FETCH_ROLE_PRESET_PRIVILEGS_SUCCESS:
      return {
        ...state,
        roleForm: {
          ...state.roleForm,
          managed: action?.payload?.managed,
          all: action?.payload?.all,
          main: action?.payload?.main,
        },
      };
    case Types.FETCH_PLAN_PRIVILEGES_FAILED:
      return {
        ...state,
        loadingPlanPrivileges: false,
        plan_privileges: null,
      };

    case Types.SHOW_SYSTEM_ROLE_MODAL:
      return {
        ...state,
        systemRolePlanActions: {
          ...initState.systemRolePlanActions,
          isVissible: true,
        },
        roleForm: action.payload?.roleForm
          ? {
              ...action.payload.roleForm,
            }
          : { ...initState.roleForm },
      };
    case Types.DISMISS_SYSTEM_ROLE_MODAL:
      return {
        ...state,
        systemRolePlanActions: {
          ...initState.systemRolePlanActions,
        },
        roleForm: { ...initState.roleForm },
        roleFormValidation: [],
        roleServerValidation: {},
      };

    case Types.UPSERT_ROLE_ATTEMPT:
      return {
        ...state,
        systemRolePlanActions: {
          ...state.systemRolePlanActions,
          isLoading: true,
        },
        roleFormValidation: [],
        roleServerValidation: {},
      };
    case Types.UPSERT_ROLE_SUCCESS:
      return {
        ...state,
        systemRolePlanActions: {
          ...initState.systemRolePlanActions,
        },
        roleForm: { ...initState.roleForm },
        roleFormValidation: [],
        roleServerValidation: {},
      };
    case Types.UPSERT_ROLE_FAILED:
      return {
        ...state,
        systemRolePlanActions: {
          ...state.systemRolePlanActions,
          isLoading: false,
        },
        roleFormValidation: [],
        roleServerValidation: action?.payload,
      };

    case Types.FETCH_PLAN_ROLES_ATTEMPT:
      return {
        ...state,
        loadingNewPlanRoles: true,
      };
    case Types.FETCH_PLAN_ROLES_SUCCESS:
      return {
        ...state,
        loadingNewPlanRoles: false,
        newPlanRoles: {
          ...action.payload.roles,
        },
      };
    case Types.FETCH_PLAN_ROLES_FAILED:
      return {
        ...state,
        loadingNewPlanRoles: false,
      };

    case Types.FETCH_SYSTEM_PLANE_PAGE_ATTEMPT:
      return {
        ...state,
        loadingPlanPage: true,
        viewedPlan: null,
      };

    case Types.FETCH_SYSTEM_PLANE_PAGE_FAILED:
      return {
        ...state,
        loadingPlanPage: false,
        viewedPlan: null,
      };
    case Types.FETCH_SYSTEM_PLANE_PAGE_SUCCESS:
      return {
        ...state,
        loadingPlanPage: false,

        viewedPlan: {
          id: action.payload?.plan?.id,
          name: action.payload?.plan?.name,
          features: action.payload?.plan?.features?.map((feat) => ({
            id: feat.id,
            limit: feat?.pivot?.limits,
          })),
          trial_value: action.payload?.plan?.trial_value,
          is_free: action.payload?.plan?.trial_value == null ? 1 : 0,
          roles: action?.payload?.plan?.roles,
          status: action?.payload?.plan?.status,
          activeSubscribtions: action?.payload?.plan?.activeSubscribtions,
        },
      };

    case Types.TOOGLE_VIEW_PLAN_PRIVILEGES_MODAL:
      return {
        ...state,
        viewPlanPrivilegesModal: {
          isVissible: !state.viewPlanPrivilegesModal.isVissible,
        },
      };

    case Types.UPDTAE_PLAN_STATUS_ATTEMPT:
    case Types.DELETE_SYSTEM_PLAN_ATTEMPT:
    case Types.DELETE_ROLE_ATTEMPT:
      return {
        ...state,
        planActionLoading: true,
      };
    case Types.UPDTAE_PLAN_STATUS_SUCCESS:
    case Types.UPDTAE_PLAN_STATUS_FAILED:
    case Types.DELETE_SYSTEM_PLAN_SUCCESS:
    case Types.DELETE_SYSTEM_PLAN_FAILED:
    case Types.DELETE_ROLE_SUCCESS:
    case Types.DELETE_ROLE_FAILED:
      return {
        ...state,
        planActionLoading: false,
      };

    case Types.FETCH_EDIT_ROLE_ATTEMPT:
      return {
        ...state,
        fetchEditRole: true,
      };
    case Types.FETCH_EDIT_ROLE_FAILED:
    case Types.DISMISS_EDIT_ROLE_MODAL:
      return {
        ...state,
        fetchEditRole: false,
        systemRolePlanActions: { ...initState.systemRolePlanActions },
      };
    case Types.FETCH_EDIT_ROLE_SUCCESS:
      return {
        ...state,
        fetchEditRole: false,
        systemRolePlanActions: {
          ...state.systemRolePlanActions,
          isEditVissible: true,
        },
        plan_privileges: action?.payload?.plan_privileges,
        roleForm: { ...action.payload?.role },
      };

    case Types.FETCH_ADMIN_HOLIDAYS_COUNTRIES_OPTIONS:
      return {
        ...state,
        holidaysCountriesFilterOptions: action?.payload,
      };

    case Types.SHOW_ADMIN_HOLIDAYS_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data,
        },
      };
    case Types.HIDE_ADMIN_HOLIDAYS_MODAL:
      return {
        ...state,
        adminHolidaysModalServerValidation: {},
        adminHolidaysModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        adminHolidaysModal: initState.adminHolidaysModal,
      };

    case Types.UPSERT_ADMIN_HOLIDAYS_ATTEMPT:
      return {
        ...state,
        adminHolidaysModalServerValidation: {},
        adminHolidaysModalActions: {
          ...state.adminHolidaysModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.UPSERT_ADMIN_HOLIDAYS_SUCCESS:
      return {
        ...state,
        adminHolidaysModalServerValidation: {},
        adminHolidaysModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        adminHolidaysModal: initState.adminHolidaysModal,
      };
    case Types.UPSERT_ADMIN_HOLIDAYS_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload?.modalMessage ?? "",
        },
        [action.payload.serverRef]: action.payload?.errors ?? {},
      };

    case Types.DELETE_ADMIN_HOLIDAYS_ATTEMPT:
      return {
        ...state,
        deleteAdminHolidaysLoading: true,
      };
    case Types.DELETE_ADMIN_HOLIDAYS_SUCCESS:
    case Types.DELETE_ADMIN_HOLIDAYS_FAILED:
      return {
        ...state,
        deleteAdminHolidaysLoading: false,
      };

    case Types.GENERATE_DEMO_ATTEMPT:
      return {
        ...state,
        isGenerateDemoLoading: true,
      };
    case Types.GENERATE_DEMO_SUCCESS:
    case Types.GENERATE_DEMO_FAILED:
      return {
        ...state,
        isGenerateDemoLoading: false,
      };

    case Types.LOG_OUT:
      return { ...initState };

    default:
      return state;
  }
};
