import Constants from "../../Constants";

// DayOffExceptionConfigInput
export const serializeUpsertDayOffException = (data) => {
  const {
    day_off_exception_id,
    compensation_type,
    compensation_quantity,
    workplace_setting,
    workplace_setting_identical,
    additional_work_places,
    workplace_setting_signout,
    additional_work_places_signout,
    allow_half_days,
    allow_work_on_weekend_or_holiday,
    ...dayOff
  } = data;

  return Constants.attendanceTypes.SHIFT_BASED &&
    allow_work_on_weekend_or_holiday
    ? {
        ...dayOff,
        id: day_off_exception_id,

        ...(dayOff?.apply_compensation && {
          compensation_type,
          compensation_quantity: parseFloat(compensation_quantity),
        }),
        workplace_setting,
        workplace_setting_identical,
        additional_work_places,
        workplace_setting_signout: workplace_setting_identical
          ? workplace_setting
          : workplace_setting_signout,
        additional_work_places_signout: workplace_setting_identical
          ? additional_work_places
          : additional_work_places_signout,
      }
    : undefined;
};

// workRemotelySettings
export const serializeUpsertWorkRemotely = (data) => {
  const { maxRemoteDaysPerWeek, canWorkRemote, attendanceType } = data || {};
  const isOfficeBased =
    attendanceType === Constants.attendanceTypes.OFFICE_BASED;

  return {
    canWorkRemote: !!canWorkRemote,
    ...(canWorkRemote
      ? {
          employee_can_request: data?.employee_can_request,
          maxRemoteDaysPerWeek: +maxRemoteDaysPerWeek,
          flexibleRemote: isOfficeBased ? data?.flexibleRemote : canWorkRemote,
          canExchangeDays:
            isOfficeBased && data?.flexibleRemote
              ? data?.canExchangeDays
              : false,
          remoteDays:
            isOfficeBased && data?.flexibleRemote ? data?.remoteDays : [],
        }
      : {
          employee_can_request: false,
          maxRemoteDaysPerWeek: 0,
          flexibleRemote: false,
          canExchangeDays: false,
          remoteDays: [],
        }),
  };
};
export const serializeFetchWorkRemotely = (input) => {
  return {
    canWorkRemote: input?.can_work_home,
    employee_can_request: input?.employee_can_request,
    maxRemoteDaysPerWeek: input?.max_homeDays_per_week,
    flexibleRemote: input?.flexible_home,
    canExchangeDays: input?.can_ex_days,
    remoteDays: input?.home_days?.split(","),
  };
};

// UpdateOrCreateAttendanceTypeConfigurationInput
export const serializeUpsertAttType = (data) => {
  const {
    att_type_id,
    attendanceType,
    user_id,
    start,
    end,
    attendanceProfileId,
    permissionLeavesBreakSettingId,
    workGroupId,
    workTeamId,
    joiningFrom,
    allowMultipleCheckIns,
    check_in_form_id,
    check_out_form_id,
    weekStartDay,
    save_with,
    skip_work_timing_changed,
    allow_work_on_weekend_or_holiday,

    // Work Remotely
    canWorkRemote,
    employee_can_request,
    maxRemoteDaysPerWeek,
    flexibleRemote,
    canExchangeDays,
    remoteDays,

    // Day off exception
    day_off_exception_id,
    normal_work_timing_id,
    allow_half_days,
    first_half_work_timing_id,
    second_half_work_timing_id,
    weight,
    apply_compensation,
    compensation_type,
    compensation_quantity,
    treat_as_normal,
    allow_permission,
    allow_overtime,
    offices_ids,
    additional_work_places,
    additional_work_places_signout,
    workplace_setting,
    workplace_setting_signout,
    workplace_setting_identical,
  } = data;

  return {
    id: att_type_id,
    type: attendanceType,
    user_id,
    start,
    end,
    attendanceProfileId,
    permissionLeavesBreakSettingId,
    workGroupId,
    workTeamId,
    joiningFrom,
    allowMultipleCheckIns: !!allowMultipleCheckIns,
    checkInFormId: check_in_form_id,
    checkOutFormId: check_out_form_id,
    weekStartDay: parseInt(weekStartDay),
    save_with,
    skip_work_timing_changed,
    allow_work_on_weekend_or_holiday,

    // Work Remotely
    workRemotelySettings: serializeUpsertWorkRemotely({
      attendanceType,
      canWorkRemote,
      employee_can_request,
      maxRemoteDaysPerWeek,
      remoteDays,
      flexibleRemote,
      canExchangeDays,
    }),

    // Day off exception
    day_off_exception_config: serializeUpsertDayOffException({
      allow_work_on_weekend_or_holiday, // for check
      day_off_exception_id,
      normal_work_timing_id,
      allow_half_days,
      first_half_work_timing_id,
      second_half_work_timing_id,
      weight,
      apply_compensation,
      compensation_type,
      compensation_quantity,
      treat_as_normal,
      allow_permission,
      allow_overtime,
      offices_ids,
      additional_work_places,
      additional_work_places_signout,
      workplace_setting,
      workplace_setting_signout,
      workplace_setting_identical,
    }),
  };
};
