import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useCustomQuery from "../../Helpers/Hooks/useCustomQuery";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import {
  storeTrialMutation,
  changeTrialMutation,
} from "../../Graphql/mutation";
import moment from "moment";
import { trialFormQuery } from "../../Graphql/query";
import { formatSendingInputs } from "../../Helpers/HelperFns";
import { clearCRMValidations, setCRMValidations, showErrorToast } from "../../Store/Actions";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
} from "form-builder";
import Loader from "../Loader";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import { Box } from "@mui/material";
import IntlTelInput from "react-intl-tel-input";
import { RemoveIconButton } from "../IconButtonWithTooltip";

const formNameValidation = "trialClientValidation";
const formServerValidation = "trialServerValidation";
const businessPartnerInitState = {
  name: "",
  phone: "",
  email: "",
  phone_country_code: "",
  country_short_name: "",
};

const TrialModal = ({
  data,
  onClose,
  isLeadProfile,
  leadProfileData,
  refetchList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [options, setOptions] = useState({
    leads: [],
    plans: [],
    managers: [],
  });
  const [formData, setFormData] = useState({
    sass: [],
    name: "",
    from: "",
    limit: "",
    plan: null,
    period: "W1",
    lead: leadProfileData || null,
    business_partners: [businessPartnerInitState],
  });

  // Reducer State
  const trialClientValidation = useSelector(
    (state) => state.crm[formNameValidation]
  );
  const trialServerValidation = useSelector(
    (state) => state.crm[formServerValidation]
  );

  // Server State
  const [storeTrial, { loading: isStoreLoading }] =
    useCustomMutation(storeTrialMutation);
  const [changeTrial, { loading: isChangeLoading }] =
    useCustomMutation(changeTrialMutation);
  const { loading: isDataLoading } = useCustomQuery(trialFormQuery, {
    variables: { id: data?.id, isTrialSkip: !Boolean(data) },
    onCompleted: ({ trial = {}, leads, managers, plans }) => {
      // Data
      if (Boolean(data)) {
        const { company, limit, plan, start_date } = trial?.subscription;
        setFormData({
          plan,
          limit,
          id: trial?.id,
          lead: trial?.lead,
          period: trial?.period,
          from: moment(start_date),
          sass: company?.saasUsers?.length ? company?.saasUsers : [],
          business_partners: company?.businessPartners?.length
            ? company?.businessPartners.map((b) => b?.user)
            : [businessPartnerInitState],
        });
      }

      // Options
      setOptions((prev) => ({
        ...prev,
        plans: plans?.data || [],
        leads: leads?.data || [],
        managers: managers?.data || [],
      }));
    },
  });

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    return () => {
      dispatch(clearCRMValidations(formServerValidation));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleAddBusinessPartner = () => {
    setFormData((prev) => ({
      ...prev,
      business_partners: [...prev.business_partners, businessPartnerInitState],
    }));
  };

  const handleRemoveBusinessPartner = (index) => {
    setFormData((prev) => ({
      ...prev,
      business_partners: prev.business_partners.filter((_, i) => i !== index),
    }));
  };

  const handlePhoneChange = (input, partnerIdx) => {
    if (input?.isPhone && isNaN(input?.num)) return;
    setFormData((prev) => ({
      ...prev,
      business_partners: prev.business_partners.map((b, i) =>
        i === partnerIdx
          ? {
              ...b,
              phone: input?.num,
              phone_country_code: input?.dialCode,
              country_short_name: input?.iso2,
            }
          : b
      ),
    }));
  };

  const handleChangeBusinessPartner = (event, index) => {
    setFormData((prev) => ({
      ...prev,
      business_partners: prev.business_partners.map((partner, i) =>
        index === i
          ? { ...partner, [event.target.name]: event.target.value }
          : partner
      ),
    }));
  };

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectChange = (val, { name: key }) => {
    setFormData((prev) => ({ ...prev, [key]: val }));
  };

  const handleDateChange = (name, val) => {
    setFormData((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (trialClientValidation.length) return;

    const upsert = data ? changeTrial : storeTrial;
    const { lead, plan, sass, contact, limit, business_partners, ...rest } =
      formData;
    const businessPartners = business_partners.map(
      ({
        name,
        email,
        phone,
        phone_country_code,
        country_short_name,
        ...rest
      }) => ({
        ...rest,
        name,
        email,
        phone: phone
          ? [phone_country_code, phone, country_short_name]
          : ["", "", ""],
      })
    );

    upsert({
      refetchList,
      onCloseModal: onClose,
      variables: {
        business_partners: businessPartners,
        lead_id: lead?.id || undefined,
        limit: limit ? +limit : undefined,
        contact_id: contact?.id || undefined,
        ...formatSendingInputs({
          ...rest,
          plan_id: plan,
          saas_ids: sass,
        }),
      },
      onError: (err) => {
        if (err[0]?.message && !err[0]?.extensions?.validation) {
          dispatch(showErrorToast(err[0]?.message));
          return;
        }
        err[0]?.extensions?.validation &&
          dispatch(
            setCRMValidations({
              ref: formServerValidation,
              validations: err[0]?.extensions?.validation,
            })
          );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      modalTitle={t(`${data ? "edit" : "new"} trial`)}
      btnSubmitLoading={isStoreLoading || isChangeLoading}
    >
      {data && isDataLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex flex-column gap-20">
        {isLeadProfile ? (
          <>
            <strong>{t("lead")}</strong>
            <span>{formData.lead.name}</span>
          </>
        ) : (
          <BSelect
            name="lead"
            label="lead"
            icon="employee"
            keepDefaultStyle
            value={formData.lead}
            options={options.leads}
            isLoading={isDataLoading}
            onChange={handleSelectChange}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        )}
        <InputForm
          name="name"
          label="company name"
          value={formData.name}
          onChange={handleChange}
          validateBy="textRequired"
          inputContainerStyle="w-100"
          formServerValidation={formServerValidation}
          formSubmitting={formSubmitting}
          validationName="name"
          reducer="crm"
        />
        <BSelect
          isMulti
          name="sass"
          icon="employees"
          keepDefaultStyle
          label="account managers"
          value={formData.sass}
          onChange={handleSelectChange}
          options={options.managers}
          isLoading={isDataLoading}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
        />

        {/* Business Partners */}
        <div className="d-flex align-items-center gap-20">
          <strong className="text-16">{t("Business Partners")}</strong>
          <AddButton isIconOnly onClick={handleAddBusinessPartner} />
        </div>
        {formData.business_partners.map((businessPartner, i) => (
          <div key={i} className="d-flex flex-wrap gap-10">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <label>{t("name")}</label>
              {formData.business_partners.length > 1 && (
                <RemoveIconButton
                  onClick={() => handleRemoveBusinessPartner(i)}
                />
              )}
            </div>
            <InputForm
              name="name"
              icon="employee"
              rootStyle="w-100"
              labelStyle="w-100"
              inputContainerStyle="w-100"
              value={businessPartner.name}
              onChange={(e) => handleChangeBusinessPartner(e, i)}
              validateBy="textRequired"
              formSubmitting={formSubmitting}
              formNameValidation={formNameValidation}
            />
            <Box className="w-50" sx={{ ".intl-tel-input": { width: 1 } }}>
              <label
                className={`d-block${
                  trialServerValidation[`business_partners.${i}.phone.0`]
                    ? " red-color"
                    : ""
                }`}
              >
                {t("phone")}
              </label>

              <IntlTelInput
                value={businessPartner.phone}
                separateDialCode
                autoComplete="tel"
                defaultCountry={businessPartner.country_short_name || "eg"}
                inputClassName="input-style-default"
                onSelectFlag={(num, { dialCode, iso2 }) =>
                  handlePhoneChange({ dialCode, num, iso2 }, i)
                }
                onPhoneNumberChange={(_, num, { dialCode, iso2 }) =>
                  handlePhoneChange({ dialCode, num, iso2, isPhone: true }, i)
                }
              />
              {trialServerValidation[`business_partners.${i}.phone.0`] ? (
                <p className="my-1 text-12 red-color">
                  {t(trialServerValidation[`business_partners.${i}.phone`])}
                </p>
              ) : null}
            </Box>
            <InputForm
              name="email"
              icon="mail"
              label="email"
              rootStyle="flex-fill"
              containerStyle="d-block"
              value={businessPartner.email}
              onChange={(e) => handleChangeBusinessPartner(e, i)}
              formServerValidation={formServerValidation}
              formSubmitting={formSubmitting}
              validationName={`business_partners.${i}.email`}
              reducer="crm"
            />
          </div>
        ))}

        {/* Trial Info */}
        <strong className="text-16">{t("trial info")}</strong>
        <div className="d-flex gap-10">
          <BSelect
            name="plan"
            label="plan"
            icon="document"
            keepDefaultStyle
            rootStyle="w-50"
            inputContainerStyle="w-100"
            value={formData.plan}
            options={options.plans}
            isLoading={isDataLoading}
            onChange={handleSelectChange}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
          <InputForm
            type="number"
            name="limit"
            icon="employee"
            label="max. number of employees"
            rootStyle="flex-fill"
            containerStyle="d-block"
            labelStyle="mb-2 pb-1"
            value={formData.limit}
            onChange={handleChange}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        </div>
        <div className="d-flex gap-10">
          <DateTimePickerForm
            hasIcon
            label="start date"
            rootStyle="flex-fill"
            value={formData.from}
            datePickerContainer="w-100"
            onChange={(val) => handleDateChange("from", val)}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
          <div className="flex-fill">
            <RadioboxForm
              name="period"
              label="trial Period"
              value={formData.period}
              labelStyle="mb-3"
              optionInputStyle=" "
              containerStyle="mb-0"
              optionItemStyle="d-inline-block mx-2"
              optionsContainerStyle="d-flex gap-10"
              options={[
                {
                  label: "1 week",
                  value: "W1",
                  optProps: { onChange: handleChange },
                },
                {
                  label: "2 week",
                  value: "W2",
                  optProps: { onChange: handleChange },
                },
              ]}
            />
          </div>
        </div>
      </div>
    </MainModal>
  );
};

export default TrialModal;
