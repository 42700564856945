import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import CompanyProfileCard from "../../Components/CompanyProfileCard";
import SubscriptionList from "../../Components/SubscriptionList";
import {
  fetchCompanyProfileAction,
  resetCompanyProfileViewAction,
  fetchCompanyProfileSubscriptionsAction,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import { KeyboardBackspace } from "@mui/icons-material";

const companySubscriptionsRef = "companyProfileSubscriptionList";

const CompanyProfile = (props) => {
  const { companyId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchCompanyProfileAction({
      id: companyId,
      [companySubscriptionsRef]: props.data[companySubscriptionsRef],
    });
    return () => {
      props.resetCompanyProfileViewAction();
    };
  }, []);

  const handleCompanySubscritpionsPaginate = (
    page = props.data[companySubscriptionsRef].pagination.currentPage
  ) => {
    props.fetchCompanyProfileSubscriptionsAction({
      [companySubscriptionsRef]: {
        ...props.data[companySubscriptionsRef],
        pagination: {
          ...props.data[companySubscriptionsRef].pagination,
          currentPage: page,
        },
      },
      ref: companySubscriptionsRef,
      id: companyId,
    });
  };

  useEffect(() => {
    return () => {};
  }, [props.isLoading]);
  if (props.isLoading) {
    return <Loader />;
  }

  return (
    <div className="content pt-2 company_profile_wrapper_style">
      {props?.isActivateCompanyLoading ||
      props?.isDeleteCompanyLoading ||
      props?.isSuspendCompanyLoading ||
      props?.fetchedBuissPartnersDataIsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex mb-3">
        <Link to="/admin-panel?tab=companies">
          <div className="back-btn d-flex align-items-center position-sticky ">
            <KeyboardBackspace className="back-icon" />
            <span>{t("back")}</span>
          </div>
        </Link>
      </div>

      <div>
        <CompanyProfileCard {...props.companyProfile} />
      </div>

      <HasPrivileges
        reqireMain={[Privilages.SUPER_PRIVILEGE, Privilages.VIEW_SUBSCRIPTIONS]}
      >
        <div>
          <SubscriptionList
            companyId={companyId}
            reducer="super"
            dataTableRef={companySubscriptionsRef}
            onPaginate={handleCompanySubscritpionsPaginate}
          />
        </div>
      </HasPrivileges>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companyProfile: state.super.selectedCompanyProfile,
    data: state.super,
    isLoading: state?.super?.companyProfileLoading,
    isActivateCompanyLoading: state?.super?.activiateCompanyLoading,
    isDeleteCompanyLoading: state?.super?.deleteCompanyLoading,
    isSuspendCompanyLoading: state?.super?.suspendCompanyLoading,
    fetchedBuissPartnersDataIsLoading:
      state?.super?.fetchedBuissPartnersDataIsLoading,
  };
};

export default connect(mapStateToProps, {
  fetchCompanyProfileAction,
  resetCompanyProfileViewAction,
  fetchCompanyProfileSubscriptionsAction,
})(CompanyProfile);
