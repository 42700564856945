import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AttendanceHistoryChart from "../../Components/AttendanceHistoryChart";
import OvertimeChart from "../../Components/OvertimeChart";
import DepartmentChart from "../../Components/DepartmentChart";
import InboundRequests from "../InboundRequests";
import { connect } from "react-redux";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import { BSelect } from "form-builder";
import Constants from "../../Helpers/Constants";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Skeleton from "@mui/material/Skeleton";
import ManagerDashboardAlertContinueSetupWizard from "./ManagerDashboardAlertContinueSetupWizard";
import EmployeesTurnover from "../../Components/EmployeesTurnover";
import OpenPayrollMonths from "../../Components/OpenPayrollMonths";
import ToggleModal from "./ToggleModal";


import {
  fetchInboundRequestsAction,
  setDataTableSortingAction,
  fetchDashboardViewAction,
  fetchDepartmentsChartAction,
  fetchDashboardUsersAction,
  dismissDashboardUsersAction,
  onFormResetAction,
} from "../../Store/Actions";
import {
  PiechartPlaceholder,
} from "../../LoadingSection/Dashboard";
import HelperFns from "../../Helpers/HelperFns";
import { useHistory } from "react-router-dom";
import ExpiredDashboardComponent from "./ExpiredDashboardComponent";
import ViewExpensesChart from "../ExpensesChart";
import { Link } from "react-router-dom";

// Icons
import { BarsIconButton } from "../../Components/IconButtonWithTooltip";
import { Box } from "@mui/material";
import DashboardWidgets from "../DashboardWidgets";

const ManagerDashboard = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  


  // use state for toggle modal
  const [isToggleModalVissible, setIsToggleModalVissible] = useState(false);

  const inboundRequestsRef = "inboundRequestsList";

  useEffect(() => {
    props.fetchDashboardViewAction({
      inboundRequestsList: {
        [inboundRequestsRef]: props.data[inboundRequestsRef],
        ref: inboundRequestsRef,
      },
      attendanceChart: {
        attendanceChart: props.data["attendanceChart"],
        ref: "attendanceChart",
      },
      departmentsChart: {
        departmentsChart: props.data["departmentsChart"],
        ref: "departmentsChart",
      },
      overtimeChart: {
        overtimeChart: props.data["overtimeChart"],
        ref: "overtimeChart",
      },
      requiredDocuments: {
        company_id: props.company_id,
        page_flag: "dashboard",
        hasDocumentsPrivileges: HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_MISSING_EXPIRED_DOCUMENT],
        }),
      },
    });
    return () => {
      props.onFormResetAction("attendanceChart");
      props.onFormResetAction("overtimeChart");
      props.onFormResetAction("departmentsChart");
    };
  }, []);

  const handleRequestsFilter = () => {
    props.fetchInboundRequestsAction({
      [inboundRequestsRef]: {
        ...props.data[inboundRequestsRef],
        pagination: {
          ...props.data[inboundRequestsRef].pagination,
          currentPage: 1,
        },
        page: "inbound_requests_list",
      },
      ref: inboundRequestsRef,
    });
  };

  const handleDepartmentsChartFilter = () => {
    props.fetchDepartmentsChartAction(props.data.departmentsChart);
  };

  const didRequestsUpdtate = useDidUpdateEffect(handleRequestsFilter, [
    props.data[inboundRequestsRef].statusFilter,
    props.data[inboundRequestsRef].month,
    props.data[inboundRequestsRef].year,
    props.data[inboundRequestsRef].employees,
    props.data[inboundRequestsRef].type,
  ]);

  const didDepartmentsChartUpdtate = useDidUpdateEffect(
    handleDepartmentsChartFilter,
    [props.data["departmentsChart"].month, props.data["departmentsChart"].year]
  );

  const handleRequestsSorting = (field, dir) => {
    props.fetchInboundRequestsAction({
      [inboundRequestsRef]: {
        ...props.data[inboundRequestsRef],
        pagination: {
          ...props.data[inboundRequestsRef].pagination,
          currentPage: 1,
        },
        sorting: {
          dir,
          key: field.selector,
        },
      },
      page: "inbound_requests_list",
      ref: inboundRequestsRef,
    });
    props.setDataTableSortingAction(inboundRequestsRef, field.selector, dir);
  };

  const handleRequestsPaginate = (
    page = props.data[inboundRequestsRef].pagination.currentPage
  ) => {
    props.fetchInboundRequestsAction({
      [inboundRequestsRef]: {
        ...props.data[inboundRequestsRef],
        pagination: {
          ...props.data[inboundRequestsRef].pagination,
          currentPage: page,
        },
        page: "inbound_requests_list",
      },
      ref: inboundRequestsRef,
    });
  };

  if (
    props.profile?.company &&
    !props.profile?.company?.hasActiveSubscription
  ) {
    return <ExpiredDashboardComponent />;
  }

  if (props?.setupWizardRemainingSteps > 0 && !props.firstLoad) {
    return <ManagerDashboardAlertContinueSetupWizard />;
  }

  return (
    <div className="content dashboard_wrapper_style">
      <Box
        sx={{ borderBottom: "1px solid #313030", pb: 2, mb: 2.5 }}
        className="d-flex justify-content-between align-items-center"
      >
        <h4 style={{ fontSize: 20 }} className="page-title border-0 mb-0 pb-0">
          {t("dashboard")}
        </h4>
        <BarsIconButton onClick={() => setIsToggleModalVissible(true)} />
      </Box>

      {/* (Start) Employee Status Block */}
      <HasPrivileges
        reqireMain={[Privilages.VIEW_EMPLOYEE_DAILY_STATUSES]}
        allowBP
      >
        {props?.dashboardChartsColumns?.view_employee_daily_statuses ? (
          <DashboardWidgets/>
        ) : (
          ""
        )}
      </HasPrivileges>
      {/* (End) Employee Status Block */}

      {/* (Start) Attendance Chart Block */}
      <HasPrivileges
        reqireMain={[Privilages.VIEW_EMPLOYEE_ATTENDANCE_HISTORY_CHART]}
        allowBP
        avalibleOnExpire={false}
      >
        {props?.dashboardChartsColumns
          ?.view_employee_attendance_history_chart ? (
          <div className="row">
            <div className="col-12">
              <div className="card">
                  <div className="card-body">
                    <AttendanceHistoryChart/>
                  </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </HasPrivileges>
      {/* (End) Attendance Chart Block */}

      {/* (Start) OverTime and Departments Charts Blocks */}
      <div className="row">
        <HasPrivileges
          reqireMain={[Privilages.VIEW_EMPLOYEE_OVERTIME_CHART]}
          allowBP
          avalibleOnExpire={false}
        >
          {props?.dashboardChartsColumns?.view_employee_overtime_chart ? (
            <div className="col-12 col-xl-6">
              <div className="card">
                  <div className="card-body">
                    <OvertimeChart />
                  </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </HasPrivileges>

        <HasPrivileges
          reqireMain={[Privilages.VIEW_EMPLOYEES_PER_DEPARTMENT_CHART]}
          allowBP
          avalibleOnExpire={false}
        >
          {props?.dashboardChartsColumns
            ?.view_employees_per_department_chart ? (
            <div className="col-12 col-xl-6">
              <div className="card">
                {props.departmentsChartloading || props.firstLoad ? (
                  <PiechartPlaceholder />
                ) : (
                  <div className="card-body chart_card_body_style">
                    <div className="d-flex flex-column flex-md-row justify-content-between chart-title">
                      <h4 className="ml-0 mb-0 pt-1">
                        {t("department_chart")}
                      </h4>
                      <div className="d-flex mr-0">
                        <BSelect
                          name="month"
                          optionLabel="value"
                          optionValue="key"
                          options={Constants.AllMonthData}
                          formName="departmentsChart"
                          keepDefaultStyle
                          containerStyle="month-picker mr-2"
                          getOptionLabel={(option) => t(option.value)}
                          icon="calendar"
                        />

                        <BSelect
                          name="year"
                          optionLabel="value"
                          optionValue="value"
                          options={Constants.YearData}
                          formName="departmentsChart"
                          keepDefaultStyle
                          containerStyle="year-picker"
                          icon="calendar"
                        />
                      </div>
                    </div>
                    <DepartmentChart />
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </HasPrivileges>
      </div>
      {/* (End) OverTime and Departments Charts Blocks */}

      {/* (Start) Expense and Turn Over Charts Blocks */}
      <div className="row">
        <HasPrivileges
          reqireMain={[Privilages.VIEW_THE_EXPENSES_CHART_OF_MY_OFFICE]}
          allowBP
          avalibleOnExpire={false}
        >
          {props?.dashboardChartsColumns
            ?.view_the_expenses_chart_of_my_office ? (
            <div className="col-xl-6 col">
              <ViewExpensesChart />
            </div>
          ) : (
            ""
          )}
        </HasPrivileges>

        <HasPrivileges
          reqireMain={[Privilages.VIEW_TURNOVER_CHART]}
          allowBP
          avalibleOnExpire={false}
        >
          {props?.dashboardChartsColumns?.view_turnover_chart ? (
            <div className="col-xl-6 col">
              <EmployeesTurnover />
            </div>
          ) : (
            ""
          )}
        </HasPrivileges>
      </div>
      {/* (End) Expense and Turn Over Charts Blocks */}

      {/* (Start) Open payroll Months Block */}
      <HasPrivileges
        reqireMain={[Privilages.VIEW_OPEN_PAYROLL_MONTHS]}
        allowBP
        avalibleOnExpire={false}
      >
        {props?.dashboardChartsColumns?.view_open_payroll_months ? (
          <div className="row">
            <div className="col-sm-12 col">
              <OpenPayrollMonths />
            </div>
          </div>
        ) : (
          ""
        )}
      </HasPrivileges>
      {/* (End) Open payroll Months Block */}

      {/* (Start) Documents Block */}
      <HasPrivileges
        reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
        allowBP
        avalibleOnExpire={false}
      >
        {props?.dashboardChartsColumns?.VIEW_MISSING_EXPIRED_DOCUMENT ? (
          <div className="mb-4">
            <div className="side-title">
              {props?.requiredDocumentsLoading ? (
                <Skeleton variant="text" width={160} height={30} />
              ) : (
                t("documents")
              )}
            </div>
            <div className="row justify-content-between">
              {props?.requiredDocumentsLoading ? (
                <div className="col">
                  <Skeleton variant="rectangular" width="100%" height={90} />
                </div>
              ) : (
                <div className="col d-flex align-items-center justify-content-between documents-block">
                  <div>
                    <div className="side-title mb-0">
                      {t("missing documents")}
                    </div>
                    <HasPrivileges
                      reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
                      allowBP
                    >
                      <Link className="mt-2" to="/documents">
                        {t("show_all")}
                      </Link>
                    </HasPrivileges>
                  </div>

                  <HasPrivileges
                    reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
                    allowBP
                    altChildren={
                      <p>{props?.missingDocuments?.number_of_documents}</p>
                    }
                  >
                    <Link to="/documents">
                      <p>{props?.missingDocuments?.number_of_documents}</p>
                    </Link>
                  </HasPrivileges>
                </div>
              )}
              {props?.requiredDocumentsLoading ? (
                <div className="col">
                  <Skeleton variant="rectangular" width="100%" height={90} />
                </div>
              ) : (
                <div className="col d-flex align-items-center justify-content-between documents-block">
                  <div>
                    <div className="side-title mb-0">
                      {t("expired documents")}
                    </div>
                    <HasPrivileges
                      reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
                      allowBP
                    >
                      <Link className="mt-2" to="/documents">
                        {t("show_all")}
                      </Link>
                    </HasPrivileges>
                  </div>
                  <HasPrivileges
                    reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
                    allowBP
                    altChildren={
                      <p>{props?.expiredDocuments?.number_of_documents}</p>
                    }
                  >
                    <Link to="/documents">
                      <p>{props?.expiredDocuments?.number_of_documents}</p>
                    </Link>
                  </HasPrivileges>
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </HasPrivileges>
      {/* (End) Documents Block */}

      {/* (Start) Inbound Requests Block */}
      {/* <HasPrivileges reqireMain={[Privilages.VIEW_EMPLOYEE_REQUESTS]} allowBP>
        {props?.dashboardChartsColumns?.VIEW_EMPLOYEE_REQUESTS ? (
          <div className="profile-detials dashboard_inbound_requests_wrapper_style">
            <div className="col-12 px-0">
              {props.firstLoad && props.data[inboundRequestsRef].isLoading ? (
                <TablePlaceholder columns={5} rows={5} />
              ) : (
                <InboundRequests
                  dataTableRef={inboundRequestsRef}
                  title={t("inbound_requests")}
                  onSorting={handleRequestsSorting}
                  onFilter={handleRequestsFilter}
                  onPaginate={handleRequestsPaginate}
                  pageFlag="dashboard"
                />
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </HasPrivileges> */}
      {/* (End) Inbound Requests Block */}

      

      {/* Start of Toggle Modal */}
      <ToggleModal
        isModalVissible={isToggleModalVissible}
        toggleModal={() => setIsToggleModalVissible(!isToggleModalVissible)}
      />
      {/* End of Toggle Modal  */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.user.userProfile,
    data: state.super,
    departmentsFilter: state.super.dashboardDepartments,
    departmentsChartloading: state.super.departmentsChart.isLoading,
    firstLoad: state.super.dashboardViewLoading,
    company_id: state.auth?.userProfile?.company?.id,
    missingDocuments: state.documents.required_documents?.missingDocuments,
    expiredDocuments: state.documents.required_documents?.expiredDocuments,
    requiredDocumentsLoading: state.documents.required_documents?.isLoading,
    setupWizardRemainingSteps: state.wizard.setupWizardRemainingSteps,
    setupWizardEssentialInfo: state.wizard.setupWizardEssentialInfo,
    dashboardChartsColumns: state.dashboardUi.dashboardChartsColumns,
  };
};

export default connect(mapStateToProps, {
  fetchInboundRequestsAction,
  setDataTableSortingAction,
  fetchDashboardViewAction,
  fetchDepartmentsChartAction,
  fetchDashboardUsersAction,
  dismissDashboardUsersAction,
  onFormResetAction,
})(ManagerDashboard);
