import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import FormField from "../../models/FormField";
import FormBuilderField from "../FormBuilderField";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProcessStageAction,
  onRecruitemtProccessFormFieldsChangeAction,
  onRecruitemtProccessFormChangeAction,
} from "../../Store/Actions";
import { runValidation } from "../../Helpers/Validations/formbuilderInputValidation";
import FieldError from "../FormBuilderField/FieldError";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import PreviewProcessStage from "./PreviewProcessStage";
import { ArrowForwardIos } from "@mui/icons-material";
import { useParams } from "react-router-dom";

function isFieldValid(prefixValidationName, objectServerValidation) {
  for (let key in objectServerValidation) {
    let matchText = new RegExp(prefixValidationName, "g");
    if (key?.match(matchText)) {
      return false;
    }
  }
  return true;
}

const ProcessForm = (props) => {
  const { stage } = props;
  const { id: stageId } = props.stage;
  const { t } = useTranslation();

  const { processId } = useParams();
  const [isOpen, setIsOpen] = useState(processId ? false : true);

  const [serverValidations, setServerValidations] = useState({});
  const [clientValidations, setClientValidations] = useState({});
  const [isPreview, setIsPreview] = useState(false);

  const formServerValidation = useSelector(
    (state) => state.recruitment?.recruitmentProcessFormServerValidation
  );

  const createRecruitmentProcessLoading = useSelector(
    (state) => state.recruitment.upsertRecruitmentProcessLoading
  );

  const handleUpdateServerValidation = () => {
    if (
      Object.keys(formServerValidation)
        ?.toString()
        .includes(`input.stages.${props.status}`)
    ) {
      setIsOpen(true);
    }
    Object.keys(formServerValidation)?.map((key) => {
      if (key.includes(`input.stages.${props.status}.name`)) {
        setServerValidations((prevState) => ({
          ...prevState,
          [`${props.stage.id}.name`]: formServerValidation[key],
        }));
      }
    });
  };

  const useDidServerValidationUpdate = useDidUpdateEffect(
    handleUpdateServerValidation,
    [formServerValidation]
  );

  const vaildContainerClassName = (name) => {
    let inputValidationName = props?.stage?.id + "." + name;
    let isInValid =
      !!clientValidations[inputValidationName] ||
      !!serverValidations[inputValidationName];
    if (isInValid) {
      return "invalid-container-style";
    }
    return "mb-3";
  };

  const recruitmentProcessesForm = useSelector(
    (state) => state.recruitment.recruitmentProcessesForm
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (createRecruitmentProcessLoading) {
      console.log("preview");
      setIsPreview(false);
    }
  }, [createRecruitmentProcessLoading]);

  const addField = () => {
    if (processFormOpenFields?.length) {
      let openFieldsStatus = processFormOpenFields?.map((opField) => {
        let openFieldStage = recruitmentProcessesForm?.stages?.find(
          (stage) => stage.id === opField.stageId
        );
        let openField = openFieldStage?.form?.fields?.find(
          (field) => field.id === opField.fieldId
        );
        console.log("add@openField", openField);
        if (!!openField?.id) {
          dispatch({
            type: "SUBMITED_FIELD",
            payload: openField?.id,
          });
          return runValidation(openField);
        } else {
          return true;
        }
      });

      console.log("openFieldsStatus", openFieldsStatus);

      if (openFieldsStatus?.includes(false)) {
        return;
      } else {
        let newFieldOrder = stage?.form?.fields?.length ?? 0 + 1;
        let newField = new FormField(null, newFieldOrder);
        // console.log("newField", stage?.form?.fields, newField);
        let updatedFields = [...(stage?.form?.fields ?? []), newField];

        dispatch(
          onRecruitemtProccessFormFieldsChangeAction(stageId, updatedFields)
        );
        dispatch({
          type: "OPEN_FIELD",
          payload: { fieldId: newField.id, stageId },
        });
        setIsPreview(false);
      }
    } else {
      let newFieldOrder = stage?.form?.fields?.length ?? 0 + 1;
      let newField = new FormField(null, newFieldOrder);
      let updatedFields = [...(stage?.form?.fields ?? []), newField];
      dispatch(
        onRecruitemtProccessFormFieldsChangeAction(stageId, updatedFields)
      );
      dispatch({
        type: "OPEN_FIELD",
        payload: { fieldId: newField.id, stageId },
      });
      setIsPreview(false);
    }
  };

  const handleAddChildField = (parent_field_id, ancestors) => {
    if (processFormOpenFields?.length) {
      let openFieldsStatus = processFormOpenFields?.map((opField) => {
        let openFieldStage = recruitmentProcessesForm?.stages?.find(
          (stage) => stage.id === opField.stageId
        );
        let openField = openFieldStage?.form?.fields?.find(
          (field) => field.id === opField.fieldId
        );
        console.log("add@openField", openField);
        if (!!openField?.id) {
          dispatch({
            type: "SUBMITED_FIELD",
            payload: openField?.id,
          });
          return runValidation(openField);
        } else {
          return true;
        }
      });

      console.log("openFieldsStatus", openFieldsStatus);

      if (openFieldsStatus?.includes(false)) {
        return;
      } else {
        let newFieldOrder = stage?.form?.fields?.length ?? 0 + 1;
        let newAncestors = [...(ancestors ?? []), parent_field_id];
        let newField = new FormField(
          parent_field_id,
          newFieldOrder,
          newAncestors
        );
        let updatedFields = [...(stage?.form?.fields ?? []), newField];

        dispatch(
          onRecruitemtProccessFormFieldsChangeAction(stageId, updatedFields)
        );
        dispatch({
          type: "OPEN_FIELD",
          payload: { fieldId: newField.id, stageId },
        });
        setIsPreview(false);
      }
    } else {
      let newFieldOrder = stage?.form?.fields?.length ?? 0 + 1;
      let newAncestors = [...(ancestors ?? []), parent_field_id];
      let newField = new FormField(
        parent_field_id,
        newFieldOrder,
        newAncestors
      );
      let updatedFields = [...(stage?.form?.fields ?? []), newField];

      dispatch(
        onRecruitemtProccessFormFieldsChangeAction(stageId, updatedFields)
      );
      dispatch({
        type: "OPEN_FIELD",
        payload: { fieldId: newField.id, stageId },
      });
      setIsPreview(false);
    }
  };

  const handleStageCheckboxChange = (e, stageId) => {
    const { name, value, checked } = e.target;

    if (name === "formId") {
      if (!checked) {
        dispatch(onRecruitemtProccessFormFieldsChangeAction(stageId, []));
      } else {
        addField();
        setIsPreview(false);
      }
      return;
    }
    dispatch(
      onRecruitemtProccessFormChangeAction(stageId, { [name]: checked })
    );
  };

  const handleStageInputChange = (e, stageId) => {
    const { name, value } = e.target;

    dispatch(onRecruitemtProccessFormChangeAction(stageId, { [name]: value }));

    return;
  };

  const handleFieldDelete = (fieldId) => {
    // return;
    let updatedFields = _.filter(
      stage?.form?.fields,
      (field) => !(field.id === fieldId || field?.ancestors?.includes(fieldId))
    );
    dispatch(
      onRecruitemtProccessFormFieldsChangeAction(stageId, updatedFields)
    );
    return;
  };

  const handleUpdateFormBuilder = (updatedfield, fieldId) => {
    let updatedFields = stage?.form?.fields.map((field) =>
      field.id === fieldId ? { ...updatedfield } : field
    );

    console.log("updatedFields", updatedFields);

    dispatch(
      onRecruitemtProccessFormFieldsChangeAction(stageId, updatedFields)
    );
  };

  const processFormOpenFields = useSelector(
    (state) => state.recruitment?.processFormOpenFields
  );
  const processFormSubmittedFields = useSelector(
    (state) => state.recruitment?.processFormSubmittedFields
  );

  const handleOpenField = (fieldId, skipValidation = false) => {
    console.log("fieldId", fieldId);
    const getOpenField = processFormOpenFields.find(
      (field) => field?.stageId === stageId
    );
    const openField = _.find(
      stage?.form?.fields,
      (field) => field?.id === getOpenField?.fieldId
    );
    console.log("getOpenField", getOpenField, openField);
    //no open field found in this stage
    if (skipValidation) {
      dispatch({
        type: "OPEN_FIELD",
        payload: { fieldId, stageId },
        keepOthers: skipValidation,
      });
    }

    if (!!!openField?.id) {
      dispatch({
        type: "OPEN_FIELD",
        payload: { fieldId, stageId },
      });
    }
    console.log("processFormOpenFields", runValidation(openField ?? {}));

    //open field found in this stage && is valid
    if (!!openField && runValidation(openField)) {
      dispatch({
        type: "OPEN_FIELD",
        payload: { fieldId, stageId },
      });
    } else {
      dispatch({
        type: "SUBMITED_FIELD",
        payload: getOpenField?.fieldId,
      });
    }
  };

  const handleCloseField = (fieldId) => {
    const getOpenField = processFormOpenFields?.find(
      (field) => field?.stageId === stageId
    );

    console.log("fieldId", fieldId);
    dispatch({
      type: "SUBMITED_FIELD",
      payload: fieldId,
    });
    dispatch({
      type: "CLOSE_FIELD",
      payload: { fieldId, stageId },
    });
  };

  const handleUpdatePreview = (updatedFields) => {
    dispatch(
      onRecruitemtProccessFormFieldsChangeAction(stageId, updatedFields)
    );
  };

  return (
    <div className="recruitment-process-stage_wrapper_style my-3">
      <Accordion
        expanded={isOpen}
        onChange={() => setIsOpen(!isOpen)}
        key={stage.id}
        disableGutters
        elevation={0}
        square
        {...props}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // {...props}
        >
          <h1>{!!stage.name ? stage?.name : `status  ${props?.status + 1}`}</h1>

          <div className="d-flex">
            {stage.form?.fields?.length ? (
              <span
                role="button"
                className="preview-btn mx-1"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isPreview) {
                    setIsPreview(true);
                    setIsOpen(true);
                    return;
                  }
                  setIsPreview(!isPreview);
                }}
              >
                {!isPreview ? t("preview form") : t("edit form")}
              </span>
            ) : null}
            {stage?.name?.toLowerCase() === "hired" ? null : (
              <span
                role="button"
                className="mx-2"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(deleteProcessStageAction(stageId, props.status));
                }}
              >
                <FontAwesomeIcon icon={faTrash} color="#fe6e6f" />
              </span>
            )}
          </div>
        </AccordionSummary>

        <>
          {isPreview ? (
            <AccordionDetails>
              <PreviewProcessStage
                onUpdatePreview={handleUpdatePreview}
                data={props.stage?.form?.fields}
              />
            </AccordionDetails>
          ) : (
            <AccordionDetails>
              <div className="px-4">
                <div className={vaildContainerClassName("name")}>
                  <input
                    name="name"
                    disabled={stage?.name?.toLowerCase() === "hired"}
                    value={stage.name}
                    onChange={(e) => handleStageInputChange(e, stage.id)}
                    placeholder="name"
                    className="text-input w-100 validity-input-style mb-0"
                  />
                  <FieldError
                    name="name"
                    prefixValidationName={stageId}
                    submitted={true}
                    serverValidations={serverValidations}
                    clientValidations
                  />
                </div>

                <div className="mb-2 px-4 mx-2">
                  <div className="row py-1">
                    <>
                      <label
                        className={
                          "checkbox col-lg-4 col-md-5 col-sm-6 col-xs-12"
                        }
                      >
                        <input
                          type="checkbox"
                          name={"inactivate_application"}
                          checked={stage.inactivate_application}
                          onChange={(e) =>
                            handleStageCheckboxChange(e, stage.id)
                          }
                        />
                        <span></span>

                        <div className={`booleanInputLabelStyle`}>
                          {t("close application")}
                          <span
                            style={{ marginInlineStart: "-20px" }}
                            className="note d-block my-2"
                          >
                            (
                            {t(
                              "this will make the application inactive when set to this status"
                            )}
                            )
                          </span>
                        </div>
                      </label>
                    </>
                    <label className={"radio"}>
                      <input
                        type="radio"
                        name={"initial_flag"}
                        // value={stage.initial_flag}
                        checked={stage.initial_flag === true}
                        onChange={(e) => handleStageCheckboxChange(e, stage.id)}
                      />
                      <span></span>

                      <div className={`booleanInputLabelStyle`}>
                        {t("Set this status as the initial status")}
                      </div>
                    </label>
                  </div>
                  <div className="row py-1">
                    <label
                      className={
                        "checkbox col-lg-4 col-md-5 col-sm-6 col-xs-12"
                      }
                    >
                      <input
                        type="checkbox"
                        name={"formId"}
                        // value={}
                        checked={!!stage.form?.fields.length}
                        onChange={(e) => handleStageCheckboxChange(e, stage.id)}
                      />
                      <span></span>

                      <div className={`booleanInputLabelStyle`}>
                        {t("add form")}
                      </div>
                    </label>

                    <label className={"checkbox"}>
                      <input
                        type="checkbox"
                        name={"quiz"}
                        disabled
                        onChange={(e) => handleStageCheckboxChange(e, stage.id)}
                      />
                      <span></span>

                      <div className={`booleanInputLabelStyle`}>
                        {t("include quiz")}
                      </div>
                    </label>
                  </div>
                </div>
                {stage.form?.fields?.length ? (
                  <div className="px-3 mx-1">
                    <div className="d-flex flex-row align-items-baseline my-3">
                      <h5 className="mr-2 d-flex field-title">
                        {t("form fields")}
                      </h5>
                      <button
                        className="d-flex add-button btn px-1"
                        role="button"
                        onClick={() => addField(stage.id)}
                      >
                        <FontAwesomeIcon icon={faPlus} color="#fff" />
                      </button>
                    </div>
                  </div>
                ) : null}

                <>
                  {stage?.form?.fields
                    ?.filter(({ parent_field_id }) => !!!parent_field_id)
                    ?.map((firstParent, index) => (
                      <>
                        <FormBuilderField
                          field={firstParent}
                          serverValidation={
                            "recruitmentProcessFormServerValidation"
                          }
                          handleAddChildField={handleAddChildField}
                          onDeleteField={handleFieldDelete}
                          onUpdateFormBuilder={handleUpdateFormBuilder}
                          key={firstParent.id}
                          id={firstParent.id}
                          data={stage?.form?.fields}
                          validationName={`input.stages.${props?.status}.form.fields.${index}`}
                          openFields={processFormOpenFields.map(
                            (field) => field.fieldId
                          )}
                          onOpenField={handleOpenField}
                          onCloseField={handleCloseField}
                          submittedFields={processFormSubmittedFields}
                        />
                      </>
                    ))}
                </>
              </div>
            </AccordionDetails>
          )}
        </>
      </Accordion>
    </div>
  );
};

export default ProcessForm;
