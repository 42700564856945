import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { LocationOffOutlined, Room } from "@mui/icons-material";
import {
  faCheckSquare,
  faEye,
  faFileDownload,
  faIdCard,
  faMagic,
  faPenAlt,
  faPenSquare,
  faPencilAlt,
  faSquare,
  faUserLock,
  faUser,
  faUsers,
  faBuilding,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { CheckboxBooleanForm, BSelect } from "form-builder";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Loader from "../../Components/Loader";
import { AddButton } from "../../Components/Buttons";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Button, Alert, AlertTitle } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChangeManagersModal from "./ChangeManagersModal";
import MainModal from "../../Components/MainModal";
import Select from "react-select";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { showPreviewRolePrivilagesModalAction } from "../../Store/Actions";
import PreviewRolePrivilagesModal from "../../Components/PreviewRolePrivilagesModal";
import _ from "lodash";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import { Link } from "react-router-dom";
import WorkRemotelyModal from "./WorkRemotelyModal";
import PosDepModal from "./PosDepModal";
import BatchActionModal from "./BatchActionModal";
import DayOffModal from "./DayOffModal";
import Tooltip from "@mui/material/Tooltip";
import ChangeCheckInsModal from "./ChangeCheckInsModal";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import VerifyActivate from "./VerifyActivate";

const dataTableRef = `batchEmployeesActions`;

const EMPLOYESS_QUERY = gql`
  {
    work_groups_menu {
      id
      name
    }
    managers {
      id
      name
    }
    company_offices(first: 9999) {
      data {
        id
        name
      }
    }
    company_positions(first: 9999) {
      data {
        id
        name
      }
    }
    company_departments(first: 9999) {
      data {
        id
        name
      }
    }
    users_by_role(input: { status: ["Active"] }, page: 1) {
      data {
        id
        name
        employee {
          on_probation
          relevantAttendanceTypeConfiguration {
            type
            __typename
            workGroup {
              id
            }
          }
        }
        department {
          id
          name
        }
        position {
          id
          name
        }
        office {
          id
          name
        }
        manager {
          id
          name
        }
        copied_managers {
          id
          name
        }
      }
    }
  }
`;

const FETCH_ROLES = gql`
  {
    profile {
      company {
        roles {
          id
          name
          roleable {
            type: __typename
          }
        }
        currentSubscription {
          plan {
            id
            roles {
              id
              name
              roleable {
                type: __typename
              }
            }
          }
        }
      }
    }
  }
`;

// const BatchActionModal = (props) => {
//   const toggle = () => {
//     props?.dismiss();
//     setValue(null);
//     setAccessLevelsOptions([]);
//   };

//   const { isOpen, type } = props?.batchActions;
//   const [value, setValue] = useState(null);
//   const [accessLevelsOptions, setAccessLevelsOptions] = useState([]);
//   const { t } = useTranslation();
//   const dispatch = useDispatch();

//   const isPrivilagesModalOpen = useSelector(
//     (state) => state.super.previewRolePrivilagesModalActions.isVissible
//   );

//   const { loading, error, data } = useQuery(FETCH_ROLES, {
//     skip: !isOpen,
//     fetchPolicy: "network-only",
//     onCompleted: (res) => {
//       let planRoles = res.profile.company.currentSubscription.plan.roles || [];
//       let companyRoles = res.profile.company.roles || [];
//       console.log(planRoles, companyRoles);
//       setAccessLevelsOptions([...planRoles, ...companyRoles]);
//     },
//   });
//   const ASSIGN_ROLE = gql`
//     mutation Mutation($input: assignRoleTemplateToEmployeesInput) {
//       assignRoleTemplateToEmployees(input: $input) {
//         status
//         message
//       }
//     }
//   `;
//   const [
//     assignRole,
//     { loading: submitLoading, data: submitData, error: submitError, reset },
//   ] = useMutation(ASSIGN_ROLE, {
//     onCompleted(res) {
//       if (res?.assignRoleTemplateToEmployees?.status === "success") {
//         props?.resetSelection();
//         toggle();
//       }
//       showToast(
//         res?.assignRoleTemplateToEmployees?.status,
//         res?.assignRoleTemplateToEmployees.message
//       );
//     },
//     onError(error) {
//       // do something
//       console.log({ error });
//       showToast("error", error?.graphQLErrors?.extensions?.reason);
//     },
//   });

//   const handleSubmit = () => {
//     reset();
//     if (!!value) {
//       HelperFns.checkPassword(
//         "defaut_warning_messsage",
//         "",
//         t(`Confirm, assign Role to employee`, {
//           count: props?.selectedEmployees?.length,
//         }),
//         "",
//         () =>
//           assignRole({
//             variables: {
//               input: {
//                 employeesIds: props?.selectedEmployees,
//                 role_template_id: value,
//               },
//             },
//           })
//       );
//     }
//   };

//   const handleShowPreviewRolePrivilagesModal = () => {
//     let payload = {
//       planId: data?.profile?.company?.currentSubscription?.plan?.id,
//       roleId: value,
//     };
//     dispatch(
//       showPreviewRolePrivilagesModalAction({
//         data: payload,
//         ref: "previewRolePrivilagesModalActions",
//         formName: "previewRolePrivilagesModal",
//       })
//     );
//   };

//   return (
//     <MainModal
//       isOpen={isOpen}
//       toggle={toggle}
//       className="leaves_breaks_modal"
//       btnOnClick={handleSubmit}
//       btnSubmitLoading={submitLoading}
//       btnOnCancelClick={toggle}
//       modalTitle={`${t("Bulk Actions")} - ${t("Assign Access Level")}`}
//     >
//       <Alert severity="info">
//         <AlertTitle>{t("Assigning Access Level")}</AlertTitle>
//         <strong>
//           {t("Employees will be effected", {
//             count: props?.selectedEmployees?.length,
//           })}
//         </strong>
//       </Alert>
//       <h5 className="mt-3">{t("Access Level")}</h5>
//       <div className="d-flex flex-row justify-content-between align-items-center mt-1">
//         <Select
//           className={
//             "select-def-input-containe b-select-style flex-grow-1 px-0"
//           }
//           classNamePrefix={"b-select-style"}
//           value={accessLevelsOptions?.find((opt) => opt?.id === value) || null}
//           onChange={(val) => {
//             setValue(val ? val?.id : null);
//           }}
//           getOptionLabel={(opt) => opt?.name}
//           getOptionValue={(opt) => opt?.id}
//           isLoading={loading}
//           isClearable={true}
//           isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
//           isSearchable
//           placeholder={t("select option")}
//           options={accessLevelsOptions || []}
//         />
//         {value ? (
//           <PreviewIconButton
//             className="ml-2"
//             onClick={handleShowPreviewRolePrivilagesModal}
//           />
//         ) : null}
//       </div>
//       {submitError?.graphQLErrors?.[0]?.extensions?.validation?.[
//         "input.role_template_id"
//       ] ||
//       submitError?.graphQLErrors?.[0]?.extensions?.validation?.[
//         "input.employeesIds"
//       ] ? (
//         <span className="error_message_style">
//           {submitError?.graphQLErrors?.[0]?.extensions?.validation?.[
//             "input.role_template_id"
//           ]?.toString()}
//           {submitError?.graphQLErrors?.[0]?.extensions?.validation?.[
//             "input.employeesIds"
//           ]?.toString()}
//         </span>
//       ) : null}

//       {isPrivilagesModalOpen ? <PreviewRolePrivilagesModal /> : null}
//     </MainModal>
//   );
// };

const ViewEmployeeActions = () => {
  const { t } = useTranslation();
  const [managersModal, setManagersModal] = useState({
    isOpen: false,
    isDirectManager: false,
  });
  const batchFilter = useSelector(
    (state) => state?.super?.batchEmployeesActions
  );
  const auth = useSelector((state) => state?.auth?.userProfile);
  const [allEmployeesData, setAllEmployeesData] = useState([]);
  const [changeDayOffSettings, setChangeDayOffSettings] = useState(false);
  const [workRemotelyModal, setWorkRemotelyModal] = useState({
    isOpen: false
  })
  const [posDepModal, setPosDepModal] = useState({
    isOpen: false,
    isDepartment: false
  })
  const [checkInsModal, setCheckInsModal] = useState(false)

  const columns = [
    {
      id: "action",
      cell: (row) =>
        selectedEmployees?.includes(row?.id) ? (
          <FontAwesomeIcon
            role="button"
            size="lg"
            color="#2764ac"
            onClick={() => handleRowSelection(row?.id)}
            icon={faCheckSquare}
          />
        ) : (
          <FontAwesomeIcon
            role="button"
            size="lg"
            color="#eee"
            onClick={() => handleRowSelection(row?.id)}
            icon={faSquare}
          />
        ),
      button: true,
      width: "40px",
    },
    {
      id: "name",
      name: t("name"),
      grow: 1.25,
      cell: (row) => (
        <CanViewEmployeeProfile
          allowBP
          directManger={row?.manager?.id}
          copiedManagers={row?.copied_managers?.map((cp) => cp?.id)}
          altChildren={<div className="col pl-0">{row?.name}</div>}
        >
          <div className="col pl-0">
            <Link
              className="employee-name"
              to={`/employees/employee-profile/${row?.id}`}
            >
              {row?.name}
            </Link>
          </div>
        </CanViewEmployeeProfile>
      ),
    },
    {
      id: "office",
      name: t("office"),
      cell: (row) => row.office?.name,
      grow: 1,
    },
    {
      id: "department",
      name: t("department"),
      cell: (row) => row.department?.name,
      grow: 1,
    },
    {
      id: "Position",
      name: t("position"),
      cell: (row) => row.position?.name,
      grow: 1,
    },
    {
      id: "Direct Manager",
      name: t("direct manager"),
      cell: (row) => row?.manager?.name,
      grow: 1,
    },
    {
      id: "Copied-Managers",
      name: t("copied manager"),
      grow: 2.25,
      cell: (row) => row.copied_managers?.map((cp) => cp?.name)?.join(", "),
      allowOverFlow: true,
    },
    {
      id: "Attendance-Type",
      name: t("Attendance Type"),
      grow: 1,
      cell: (row) => row.employee?.relevantAttendanceTypeConfiguration?.type,
      allowOverFlow: true,
    },
  ];

  const { loading, error, data, refetch } = useQuery(EMPLOYESS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    let filteredEmployees = _.filter(data?.users_by_role?.data, (employee) =>
      _.every(batchFilter, (value, key) => {
        if (
          value === null ||
          value === "" ||
          value == false ||
          value?.length === 0
        ) {
          return true; // Ignore filters with falsey values
        }

        // Perform filter by each filter key
        switch (key) {
          case "filterByEmployee":
            return value?.includes(employee.id);
          // return employee.id == (value);
          case "filterByOffice":
            return value?.includes(employee.office?.id);
          // return employee.office?.id == value;
          case "filterByDepartment":
            return value?.includes(employee.department?.id);
          // return employee.department?.id == value;
          case "filterByPosition":
            return value?.includes(employee.position?.id);
          // return employee.position?.id == value;
          case "filterByManager":
            let empMangers = [
              employee.manager?.id,
              ...employee?.copied_managers.map((cp) => cp?.id),
            ];
            return value?.some((id) => empMangers?.includes(id));
          // return employee.manager?.id == value || employee?.copied_managers.map((cp) => cp?.id)?.includes(value);
          case "showManagedEmployeesFilter":
            return (
              employee.manager?.id == auth?.id ||
              employee?.copied_managers?.map((cp) => cp?.id)?.includes(auth?.id)
            );
          case "directManaged":
            return employee.manager?.id == auth?.id;
          case "on_probation":
            return employee.employee?.on_probation;
          case "filterByWorkgroup":
            return value?.includes(
              employee.employee?.relevantAttendanceTypeConfiguration?.workGroup
                ?.id
            );
          case "filterByAttType":
            return (
              employee.employee?.relevantAttendanceTypeConfiguration?.type ==
              value
            );

          default:
            return true;
        }
      })
    );

    setAllEmployeesData(filteredEmployees);

    return () => { };
  }, [batchFilter, data?.users_by_role?.data]);

  const isOfficeBasedUsersSelected = () => {
    let officeBasedEmployee = data?.users_by_role?.data?.findIndex(
      (emp) =>
        selectedEmployees?.includes(emp?.id) &&
        emp.employee?.relevantAttendanceTypeConfiguration?.type ==
        "office based"
    );
    if (officeBasedEmployee > -1) {
      return true;
    } else {
      return false;
    }
  };
  //start Menu

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, i) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, type) => {
    if (type) {
      setBatchActions({
        type,
        isOpen: true,
      });
    }
    setAnchorEl(null);
  };
  //end Menu

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const handleRowSelection = (id, e) => {
    if (selectedEmployees?.includes(id)) {
      setSelectedEmployees((prevState) =>
        prevState?.filter((row) => row !== id)
      );
    } else {
      setSelectedEmployees((prevState) => [...prevState, id]);
    }
  };

  const resetSelection = () => {
    setSelectedEmployees([]);
  };

  const [batchActions, setBatchActions] = useState({
    isOpen: false,
    type: null,
  });

  const onDismiss = () => {
    setBatchActions({
      isOpen: false,
      type: null,
    });
  };

  const openManagersModal = (isDirectManager) => {
    setAnchorEl(null);
    setManagersModal({
      isOpen: true,
      isDirectManager,
    });
  };
  const CloseManagersModal = (isDirectManager) => {
    setManagersModal({
      isOpen: false,
      isDirectManager: false,
    });
  };

  const openWorkRemotelyModal = () => {
    setAnchorEl(null)
    setWorkRemotelyModal({
      isOpen: true
    })
  }

  const closeWorkRemotelyModal = () => {
    setWorkRemotelyModal({
      isOpen: false
    })
  }

  const openPDModal = (isDepartment) => {
    setAnchorEl(null);
    setPosDepModal({
      isOpen: true,
      isDepartment: isDepartment
    })
  }

  const closePDModal = () => {
    setPosDepModal({
      isOpen: false,
      isDepartment: false,
    })
  }

  const CloseDayOffModal = () => setChangeDayOffSettings(false);
  const openCheckInsModal = () => {
    setAnchorEl(null)
    setCheckInsModal(true)
  }
  const closeCheckInsModal = () => { setCheckInsModal(false) }

  const [verifyActivate, setVerifyActivate] = useState(false)
  const handleVerifyActivate = () => {
    setAnchorEl(null)
    setVerifyActivate(true)
  }
  const closeVerifyActivate = () => {
    setVerifyActivate(false)
  }

  return (
    <div className="hr-employee_content_wrapper_style">
      {loading ? <Loader fixed /> : null}

      {/* Filters */}
      <div
        style={{
          marginBlock: "25px",
          display: "grid",
          gridTemplateColumns:
            "repeat(auto-fill, minmax(400px, 2fr))" /* Repeat auto times with equal width (250 to ifr) */,
          rowGap: "5px",
          columnGap: "25px",
        }}
      >
        <BSelect
          name="filterByEmployee"
          optionLabel="name"
          optionValue="id"
          isMulti
          options={data?.users_by_role?.data}
          formName={dataTableRef}
          placeholder={t("select employee")}
          keepDefaultStyle
          containerStyle="form-group"
          isClearable
          skipLocalization
          icon="person"
        />
        <BSelect
          name="filterByOffice"
          optionLabel="name"
          optionValue="id"
          isMulti
          options={data?.company_offices?.data}
          formName={dataTableRef}
          placeholder={t("select office")}
          keepDefaultStyle
          containerStyle="form-group"
          isClearable
          skipLocalization
          icon="office"
        />
        <BSelect
          name="filterByDepartment"
          optionLabel="name"
          optionValue="id"
          isMulti
          options={data?.company_departments?.data}
          formName={dataTableRef}
          placeholder={t("select department")}
          keepDefaultStyle
          containerStyle="form-group"
          isClearable
          className=""
          skipLocalization
          icon="department"
        />
        <BSelect
          name="filterByPosition"
          optionLabel="name"
          optionValue="id"
          isMulti
          options={data?.company_positions?.data}
          formName={dataTableRef}
          placeholder={t("select position")}
          keepDefaultStyle
          containerStyle="form-group"
          isClearable
          className=""
          skipLocalization
          icon="person"
        />
        <BSelect
          name="filterByWorkgroup"
          optionLabel="name"
          optionValue="id"
          isMulti
          options={data?.work_groups_menu}
          formName={dataTableRef}
          placeholder={t("select workgroup")}
          keepDefaultStyle
          containerStyle="form-group"
          isClearable
          skipLocalization
          icon="employees"
        />
        <BSelect
          name="filterByManager"
          optionLabel="name"
          optionValue="id"
          isMulti
          options={data?.managers}
          formName={dataTableRef}
          placeholder={t("select manager")}
          keepDefaultStyle
          containerStyle="form-group"
          isClearable
          skipLocalization
          icon="person"
        />
        <BSelect
          name="filterByAttType"
          optionLabel="name"
          optionValue="id"
          options={[
            { name: "office based", id: "office based" },
            { name: "shift based", id: "shifts" },
          ]}
          formName={dataTableRef}
          placeholder={t("select attendance type")}
          keepDefaultStyle
          containerStyle="form-group"
          isClearable
          skipLocalization
          icon="person"
        />
        <CheckboxBooleanForm
          formName={dataTableRef}
          name="showManagedEmployeesFilter"
          options={[t("Only show employees I manage")]}
          type="checkbox"
          containerStyle="mt-1"
          setWithValue
        />
        <CheckboxBooleanForm
          formName={dataTableRef}
          name="directManaged"
          options={[t("Only show employees I manage directly")]}
          type="checkbox"
          containerStyle="mt-1"
          setWithValue
        />
        <CheckboxBooleanForm
          formName={dataTableRef}
          name="on_probation"
          options={[t("Only show employees on probation")]}
          type="checkbox"
          containerStyle="mt-1"
          setWithValue
        />
        <div style={{ gridColumnEnd: -1 }} className="text-right">
          <HasPrivileges allowBP reqireMain={[Privilages.EDIT_USER_PRIVILEGE]}>
            <AddButton
              label={t("Bulk Actions")}
              prefix={""}
              icon={<FontAwesomeIcon className="mx-2" icon={faPencilAlt} />}
              disabled={!selectedEmployees?.length}
              disabledLabel={t("select employees")}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
          </HasPrivileges>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={(e) => handleClose(e, null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <HasPrivileges
              allowBP
              reqireMain={[Privilages.EDIT_USER_PRIVILEGE]}
            >
              <MenuItem onClick={(e, val) => handleClose(e, "ACCESS_LEVEL")}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <FontAwesomeIcon className="mx-2" icon={faUserLock} />
                  <span className="m-1">{t("Assign Access Level")}</span>
                </div>
              </MenuItem>
            </HasPrivileges>
            <HasPrivileges
              allowBP
              reqireMain={[Privilages.ADD_EDIT_ATTENDANCE_TYPE_CONFIGURATION]}
            >
              {isOfficeBasedUsersSelected() ? (
                <>
                  <Tooltip title={<span>employees must be shift based only</span>} arrow>
                    <span>
                      <MenuItem disabled>
                        <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                          <FontAwesomeIcon className="mx-2" icon={faCalendar} />
                          <span className="m-1">
                            {t("Change Day off settings")}
                          </span>
                        </div>
                      </MenuItem>
                    </span>
                  </Tooltip>


                  <Tooltip title={<span>{t("employees must be shift based only")}</span>} arrow>
                    <span>
                      <MenuItem disabled>
                        <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                          <Room className="mx-2" />
                          <span className="m-1">
                            {t("Change Check-ins settings")}
                          </span>
                        </div>
                      </MenuItem>
                    </span>
                  </Tooltip>
                </>
              ) : (
                <>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setChangeDayOffSettings({
                        isOpen: true,
                      });
                    }}
                    disabled={isOfficeBasedUsersSelected()}
                  >
                    <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                      <FontAwesomeIcon className="mx-2" icon={faCalendar} />
                      <span className="m-1">{t("Change Day off settings")}</span>
                    </div>
                  </MenuItem>


                  <MenuItem
                    onClick={openCheckInsModal}
                    disabled={isOfficeBasedUsersSelected()}
                  >
                    <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                      <Room className="mx-2" />
                      <span className="m-1">{t("Change Check-ins settings")}</span>
                    </div>
                  </MenuItem>
                </>
              )}
            </HasPrivileges>

            <HasPrivileges
              allowBP
              reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEES]}
            >
              <MenuItem onClick={() => openPDModal(true)}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <FontAwesomeIcon className="mx-2" icon={faBuilding} />
                  <span className="m-1">
                    {t("change department")}
                  </span>
                </div>
              </MenuItem>

              <MenuItem onClick={() => openPDModal(false)}
              >
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <PersonOutlineIcon className="mx-2" />
                  <span className="m-1">
                    {t("change position")}
                  </span>
                </div>
              </MenuItem>


              <MenuItem onClick={openWorkRemotelyModal}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <LocationOffOutlined className="mx-2" />
                  <span className="m-1">
                    {t("Change The Work Remotely Settings")}
                  </span>
                </div>
              </MenuItem>
              <MenuItem onClick={() => openManagersModal(true)}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <FontAwesomeIcon className="mx-2" icon={faUser} />
                  <span className="m-1">{t("Change Direct Manager")}</span>
                </div>
              </MenuItem>

              <MenuItem onClick={() => openManagersModal(false)}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <FontAwesomeIcon className="mx-2" icon={faUsers} />
                  <span className="m-1">{t("Change Copied managers")}</span>
                </div>
              </MenuItem>

            </HasPrivileges>
            <HasPrivileges allowBP reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}>
              <MenuItem onClick={() => handleVerifyActivate()}>
                <div className="border px-2 py-1 rounded-pill text-black-50 w-100">
                  <HowToRegIcon className="mx-2" />
                  <span className="m-1">{t("Verify / Activate")}</span>
                </div>
              </MenuItem>
            </HasPrivileges>
          </Menu>
        </div>
      </div>

      <Alert
        variant="standard"
        action={
          <>
            <Button
              color="info"
              size="medium"
              onClick={() =>
                setSelectedEmployees([
                  ...new Set([
                    ...selectedEmployees,
                    ...allEmployeesData?.map((emp) => emp?.id),
                  ]),
                ])
              }
            >
              {t("select all")}
            </Button>
            {selectedEmployees?.length ? (
              <Button
                color="info"
                size="medium"
                onClick={() => setSelectedEmployees([])}
              >
                {t("reset")}
              </Button>
            ) : null}
          </>
        }
      >
        <AlertTitle>
          {t("employees selected", { count: selectedEmployees?.length })}
        </AlertTitle>
      </Alert>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        title="employees"
        responsive
        noHeader
        columns={columns}
        data={allEmployeesData}
        pagination
        dense
        paginationPerPage={50}
        paginationRowsPerPageOptions={[50, 100, 150, 250, 400]}
        paginationComponentOptions={{
          rowsPerPageText: t("employees per page"),
          rangeSeparatorText: t("of"),
          selectAllRowsItem: true,
          selectAllRowsItemText: t("All"),
        }}
        direction={document.body?.dir?.toLowerCase()}
      />

      {/* Modals */}
      {batchActions?.isOpen ? (
        <BatchActionModal
          resetSelection={resetSelection}
          selectedEmployees={selectedEmployees}
          batchActions={batchActions}
          dismiss={onDismiss}
        />
      ) : null}
      {managersModal.isOpen && (
        <ChangeManagersModal
          resetSelection={resetSelection}
          refetch={refetch}
          managersModal={managersModal}
          selectedEmployees={selectedEmployees}
          CloseManagersModal={CloseManagersModal}
        />
      )}

      {workRemotelyModal && <WorkRemotelyModal
        workRemotelyModal={workRemotelyModal}
        closeWorkRemotelyModal={closeWorkRemotelyModal}
        selectedEmployees={selectedEmployees}
        refetch={refetch}
        resetSelection={resetSelection}
      />}
      {posDepModal?.isOpen &&
        <PosDepModal
          posDepModal={posDepModal}
          closePDModal={closePDModal}
          resetSelection={resetSelection}
          selectedEmployees={selectedEmployees}
          refetch={refetch}
        />
      }

      {changeDayOffSettings && (
        <DayOffModal
          changeDayOffSettings={changeDayOffSettings}
          resetSelection={resetSelection}
          refetch={refetch}
          CloseDayOffModal={CloseDayOffModal}
          selectedEmployees={selectedEmployees}
        />
      )}

      {checkInsModal && (
        <ChangeCheckInsModal
          isOpen={checkInsModal}
          resetSelection={resetSelection}
          refetch={refetch}
          close={closeCheckInsModal}
          selectedEmployees={selectedEmployees}
        />
      )}

      {verifyActivate && (
        <VerifyActivate
          isOpen={verifyActivate}
          resetSelection={resetSelection}
          refetch={refetch}
          close={closeVerifyActivate}
          selectedEmployees={selectedEmployees}
        />
      )}
    </div>
  );
};

export default ViewEmployeeActions;
