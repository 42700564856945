import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chartjs-plugin-labels";
import { connect } from "react-redux";
const calculateMiddleColor = (ratio) => {
  let color1 = "23aaeb",
    color2 = "23aaeb66";
  const hex = (color) => {
    const colorString = color.toString(16);
    return colorString.length === 1 ? `0${colorString}` : colorString;
  };

  const r = Math.ceil(
    parseInt(color2.substring(0, 2), 16) * ratio +
      parseInt(color1.substring(0, 2), 16) * (1 - ratio)
  );
  const g = Math.ceil(
    parseInt(color2.substring(2, 4), 16) * ratio +
      parseInt(color1.substring(2, 4), 16) * (1 - ratio)
  );
  const b = Math.ceil(
    parseInt(color2.substring(4, 6), 16) * ratio +
      parseInt(color1.substring(4, 6), 16) * (1 - ratio)
  );

  return `rgb(${r},${g},${b})`;
};

let DoughnutChart;
const DepartmentChart = (props) => {
  const [mQuery, setMQuery] = useState(window.innerWidth < 768);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setMQuery(e.currentTarget.innerWidth < 768);
    });
    // this is the cleanup function to remove the listener
    return () =>
      window.removeEventListener("resize", (e) =>
        setMQuery(e.currentTarget.innerWidth < 768)
      );
  }, []);

  useEffect(() => {
    // intiliazing Line Chart and its options
    var theHelp = Chart.helpers;
    if (!props.loading && !props.firstLoad) {
      let ctx = document.getElementById("DoughnutChartID").getContext("2d");

      if (typeof DoughnutChart !== "undefined") DoughnutChart.destroy();

      DoughnutChart = new Chart(ctx, {
        // plugins: [ChartDataLabels],

        type: "doughnut",
        data: {
          labels: props.chartData?.names,
          datasets: [
            {
              data: props.chartData?.data,
              // backgroundColor: props.chartData?.data.map((_, i) =>
              //   calculateMiddleColor(0.5)
              // ),
              backgroundColor: [
                "#23aaeb",
                "#23aaebc2",
                "#23aaeba6",
                "#23aaeb87",
                "#23aaeb66",
                "#1398d7",
                "#00adff",
                "#007db8",
              ],
            },
          ],
        },
        options: {
          plugins: {
            labels: {
              render: (args) => `${args.percentage}%`,
              fontColor: "#fff",
            },
          },
          // responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: mQuery ? "top" : "right",
            labels: {
              generateLabels: function (chart) {
                var data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map(function (label, i) {
                    var meta = chart.getDatasetMeta(0);
                    var ds = data.datasets[0];
                    var arc = meta.data[i];
                    var custom = (arc && arc.custom) || {};
                    var getValueAtIndexOrDefault =
                      theHelp.getValueAtIndexOrDefault;
                    var arcOpts = chart.options.elements.arc;
                    var fill = custom.backgroundColor
                      ? custom.backgroundColor
                      : getValueAtIndexOrDefault(
                          ds.backgroundColor,
                          i,
                          arcOpts.backgroundColor
                        );
                    var stroke = custom.borderColor
                      ? custom.borderColor
                      : getValueAtIndexOrDefault(
                          ds.borderColor,
                          i,
                          arcOpts.borderColor
                        );
                    var bw = custom.borderWidth
                      ? custom.borderWidth
                      : getValueAtIndexOrDefault(
                          ds.borderWidth,
                          i,
                          arcOpts.borderWidth
                        );
                    return {
                      // And finally :
                      text: `${label} ${Math.round(
                        (ds.data[i] / ds.data.reduce((a, b) => +a + +b, 0)) *
                          100
                      )}%`,
                      fillStyle: fill,
                      strokeStyle: stroke,
                      lineWidth: bw,
                      hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                      index: i,
                    };
                  });
                }
                return [];
              },
            },
          },
        },
      });
    }
  }, [props.newLoaded, mQuery]);

  return (
    <div className="DoughnutChart_wrapper_style">
      {props.loading || props.firstLoad ? null : (
        <canvas height="100vh" id="DoughnutChartID"></canvas>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chartData: state.super.departmentsChart.data,
    loading: state.super.departmentsChart.isLoading,
    firstLoad: state.super.dashboardViewLoading,
    newLoaded: state.super.departmentsChart.refreshChart,
  };
};

export default connect(mapStateToProps)(DepartmentChart);
