import Types from "../Actions/types";

export const initState = {
  user: {},
  userProfile: {},
  authForm: {
    username: "",
  },
  isLoading: false,
  logingStatus: false,
  errorMessage: "",
  isLogedIn: false,
  token: null,
  loginError: null,
  isSignedIn: false,
  officesList: [{ id: "", name: "all" }],
  shouldExitCalendarPage: false,
};

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.LOGING_IN_ATTEMPT:
      return {
        ...state,
        isLoading: true,
        loginErr: null,
        authForm: {
          username: action?.payload?.user?.username,
        },
      };

    case Types.LOGED_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action?.payload?.token ?? null,

        // errorMessage:""
        // isLogedIn:true
      };

    case Types.LOGED_IN_FAILED:
      return { ...state, isLoading: false, errorMessage: action.payload };

    // fetching Profile
    case Types.FETCH_PROFILE_SUCCESS:
    case Types.UPSERT_USER_LOCALE_SUCCESS:
      return {
        ...state,
        userProfile: { ...state.userProfile, ...action.payload.user },
      };

    case Types.ON_INPUT_RESET:
      if ([action.payload.formName] in state) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]:
              initState[action.payload.formName][action.payload.name],
          },
        };
      } else {
        return state;
      }

    case Types.ON_INPUT_RESET_WITH_VALUE:
      if ([action.payload.formName] in state) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]: action.payload?.resetValue,
          },
        };
      } else {
        return state;
      }

    case Types.LOG_OUT:
      return { ...initState };

    case Types.SET_SHOULD_EXIT_SCHEDULE_PAGE: {
      return {
        ...state,
        shouldExitCalendarPage: action.payload,
      };
    }

    default:
      return state;
  }
};
