import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import AttendanceProfileListPage from "../AttendanceProfileListPage";

export const AttendanceProfile = () => {
  let { path, url } = useRouteMatch();

  return (
    <>
      <AttendanceProfileListPage />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceProfile);
