import React from "react";
import {
  CheckboxBooleanForm,
  RadioboxForm,
  BSelect,
  InputForm,
} from "form-builder";
import { workPlaceSettingOptions } from "../Constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CheckInSettings = ({
  reducer = "super",
  formProps,
  workPlacesOptions,
}) => {
  const { t } = useTranslation();

  const {
    check_in_settings_as_work_timing_sign_in_settings,
    employee_can_check_in_from,
    apply_same_setting_when_checking_out,
    employee_can_check_out_from,
  } = useSelector((state) => state?.[reducer]?.[formProps?.formName]);
  // } = us eSelector((state) => state.super.attendaceProfile);

  return (
    <div>
      <CheckboxBooleanForm
        {...formProps}
        options={[
          "Set Check-in and out settings according to the work timing sign-in/out settings",
        ]}
        name="check_in_settings_as_work_timing_sign_in_settings"
        type="checkbox"
      />

      {!check_in_settings_as_work_timing_sign_in_settings ? (
        <>
          <p className="check-in-configuration-header-style">
            {t("facial recognition settings")}
          </p>
          <CheckboxBooleanForm
            {...formProps}
            options={["Require facial recognition when checking in/out"]}
            name="reuiqre_facial_recognition"
            type="checkbox"
          />

          <p className="check-in-configuration-header-style">
            {t("Check-in/out Work Places")}
          </p>

          <RadioboxForm
            {...formProps}
            name="employee_can_check_in_from"
            validateBy="textRequired"
            label="Employee can check in From"
            keepDefaultStyle
            options={workPlaceSettingOptions}
            validationName={``}
            containerStyle="my-2"
            labelStyle="label-style font-weight-bold"
            optionItemStyle="mr-3"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
            optionsContainerStyle="d-flex flex-column mb-2"
            skipLocalization
          />

          {employee_can_check_in_from === "DEFAULT" ? (
            <BSelect
              {...formProps}
              isMulti
              name="check_in_specific_work_laces"
              validateBy="textRequired"
              label="Additional Workplaces"
              keepDefaultStyle
              options={workPlacesOptions}
              labelStyle="my-2"
              containerStyle="containerStyle flex-column align-items-start py-0"
              validationName={``}
              inputContainerStyle="flex-grow-1 mr-0 w-100"
              skipLocalization
              icon="people"
            />
          ) : null}

          {employee_can_check_in_from ? (
            <CheckboxBooleanForm
              {...formProps}
              options={["Apply the same settings when checking out"]}
              name="apply_same_setting_when_checking_out"
              type="checkbox"
            />
          ) : null}

          {!apply_same_setting_when_checking_out ? (
            <>
              <RadioboxForm
                {...formProps}
                name="employee_can_check_out_from"
                validateBy="textRequired"
                label="Employee can check out From"
                keepDefaultStyle
                options={workPlaceSettingOptions}
                validationName={``}
                containerStyle="my-2"
                labelStyle="label-style font-weight-bold"
                optionItemStyle="mr-3"
                optionInputStyle=" "
                optionLabelStyle="optionLabelStyle"
                optionsContainerStyle="d-flex flex-column mb-2"
                skipLocalization
              />

              {employee_can_check_out_from === "DEFAULT" ? (
                <BSelect
                  {...formProps}
                  isMulti
                  name="check_out_specific_work_laces"
                  validateBy="textRequired"
                  label="Additional Workplaces"
                  keepDefaultStyle
                  options={workPlacesOptions}
                  labelStyle="my-2"
                  containerStyle="containerStyle flex-column align-items-start py-0"
                  validationName={``}
                  inputContainerStyle="flex-grow-1 mr-0 w-100"
                  skipLocalization
                  icon="people"
                />
              ) : null}
            </>
          ) : null}
        </>
      ) : null}

      <InputForm
        {...formProps}
        validateBy="textRequired"
        name="metres"
        placeholder={t("metres")}
        label="Show work places surrounding the employee within"
        labelStyle="mr-2"
        containerStyle="mt-0 mb-3 d-flex align-items-center"
        icon="globe"
        hasSuffix
        suffixTitle="metres"
        rootStyle="mt-4"
      />
      {/*  */}
    </div>
  );
};

export default CheckInSettings;
