import React, { useEffect } from "react";
import { BSelect, RadioboxForm } from "form-builder";
import moment from "moment";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import swal from "sweetalert";
import { styled } from "@mui/material/styles";

import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  fetchRequestRulesListAction,
  onInputResetAction,
  showRequestRuleAction,
  upsertRequestRulesAction,
  deleteRequestRuleAction,
  fetchRequestRulesOptsAction,
  onInputChangeAction,
} from "../../Store/Actions";

import Loader from "../../Components/Loader";

import { useTranslation } from "react-i18next";
import RequestRule from "./RequestRule";
import { Switch } from "@mui/material";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import ShowMoreText from "react-show-more-text";
import HelperFns from "../../Helpers/HelperFns";
import * as REQUESTS from "../../Constants/Requests";
import {
  EditIconButton,
  TimesIconButton,
} from "../../Components/IconButtonWithTooltip";

// Start of Ant Switch style options
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(18px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#23aaeb",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#bce0fd",
    boxSizing: "border-box",
  },
}));
// End of Ant Switch style options

const dataTableRef = `requestRulesList`;

const RequestRulesList = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchRequestRulesListAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
    props.fetchRequestRulesOptsAction();

    if (
      !HelperFns.checkPrivileges({
        privileges: [
          Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS,
        ],
        allowBP: true,
      })
    ) {
      let e = {
        target: {
          name: "department_id",
          value: props.auth?.department?.id,
        },
      };
      props.onInputChangeAction(dataTableRef, e);
    }

    return () => {
      props.onInputResetAction(dataTableRef, "department_id");
      props.onInputResetAction(dataTableRef, "employee_id");
      props.onInputResetAction(dataTableRef, "status_id");
      props.onInputResetAction(dataTableRef, "request_type_id");
    };
  }, []);

  const handleFilter = () => {
    props.fetchRequestRulesListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };

  const handleDepFilter = () => {
    if (!!props?.data[dataTableRef]?.employee_id) {
      props.onInputResetAction(dataTableRef, "employee_id");
    } else {
      props.fetchRequestRulesListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...props.data[dataTableRef],
          employee_id: null,
          pagination: {
            ...props.data[dataTableRef].pagination,
            currentPage: 1,
          },
        },
      });
    }
  };

  const onFilterChange = useDidUpdateEffect(handleFilter, [
    props?.data[dataTableRef]?.request_type_id,
    props?.data[dataTableRef]?.employee_id,
    props?.data[dataTableRef]?.status_id,
  ]);
  const onDepartmentFilterChange = useDidUpdateEffect(handleDepFilter, [
    props?.data[dataTableRef]?.department_id,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchRequestRulesListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };
  const handleDeleteRequestRule = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteRequestRuleAction(row?.id);
      }
    });
  };

  const handleUpdateRuleActive = (row, active) => {
    let editedReuestRule = {
      id: row.id,
      status_id: row.status.id,
      request_types_ids: row?.request_types?.map((req) => req.id),
      department_id: row?.department?.id,
      all_department: +row?.all_department,
      active,
      start_date: row?.start_date,
      employees_ids: row?.employees?.map((emp) => emp?.user?.id),
    };

    props.upsertRequestRulesAction(editedReuestRule);
  };

  const handleNewRequestRule = () => {
    if (
      !HelperFns.checkPrivileges({
        privileges: [
          Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS,
        ],
        allowBP: true,
      })
    ) {
      let newReuestRule = {
        id: null,
        status_id: null,
        request_types_ids: [],
        department_id: props.auth?.department?.id,
        all_department: 1,
        active: true,
        start_date: "",
        employees_ids: [],
      };

      props.showRequestRuleAction(newReuestRule);
      return;
    }
    props.showRequestRuleAction();
  };
  const handleEditRequestRule = (row) => {
    let editedReuestRule = {
      id: row.id,
      status_id: row.status.id,
      request_types_ids: row?.request_types?.map((req) => req.id),
      department_id: row?.department?.id,
      all_department: +row?.all_department,
      active: row?.active,
      start_date: row?.start_date,
      employees_ids: row?.employees?.map((emp) => emp?.user?.id),
    };

    props.showRequestRuleAction(editedReuestRule);
  };

  const columns = [
    {
      name: t("action"),
      wrap: true,
      selector: "status",
      cell: (row) => (
        <h5 className={`action-text-${row.status.status} h6`}>
          {t(row?.status?.status?.toLowerCase())}
        </h5>
      ),
      grow: 1,
    },
    {
      name: t("requests"),
      selector: "request_types",
      wrap: true,
      grow: 1.25,
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={280}
          truncatedEndingComponent={null}
          anchorClass="mt-1 d-block"
        >
          {row?.request_types?.map(
            (requset, i) =>
              `${i !== 0 ? " - " : ""} ${t(requset?.name?.toLowerCase())}`
          )}
        </ShowMoreText>
      ),
    },
    {
      name: t("department"),
      selector: "department",
      wrap: true,
      grow: 1,
      cell: (row) => row?.department?.name,
    },
    {
      name: t("employees"),
      selector: "employees",
      wrap: true,
      grow: 2,
      cell: (row) =>
        row?.all_department ? (
          t("all employees")
        ) : (
          <ShowMoreText
            lines={2}
            more="Show more"
            less="Show less"
            expanded={false}
            width={280}
            truncatedEndingComponent={null}
            anchorClass="mt-1 d-block"
          >
            {row?.employees?.map(
              (emp, i) => `${i !== 0 ? " - " : ""} ${emp?.user?.name}`
            )}
          </ShowMoreText>
        ),
    },

    {
      name: t("start date"),
      selector: "start_date",
      wrap: true,
      grow: 1,
      cell: (row) =>
        row?.start_date ? moment(row?.start_date).format("DD/MM/yyyy") : "",
    },
    {
      name: t("status"),
      selector: "active",
      wrap: true,
      grow: 0.75,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.TURN_RULES_ON_OFF]}
          allowBP
          altChildren={
            <AntSwitch
              inputProps={{ "aria-label": "Switch demo" }}
              checked={row?.active}
              color="primary"
              disabled
            />
          }
          avalibleOnExpire={false}
          altExpireChildren={
            <AntSwitch
              inputProps={{ "aria-label": "Switch demo" }}
              checked={row?.active}
              color="primary"
              disabled
            />
          }
        >
          <AntSwitch
            inputProps={{ "aria-label": "Switch demo" }}
            checked={row?.active}
            onChange={(e, val) => handleUpdateRuleActive(row, val)}
            color="primary"
          />
        </HasPrivileges>
      ),
    },
    {
      name: "",
      wrap: true,
      grow: 1,
      ignoreRowClick: true,
      // center: true,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_REQUEST_RULES]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="cards_table_actions">
              <EditIconButton />
              <TimesIconButton />
            </div>
          }
        >
          <div className="cards_table_actions">
            <EditIconButton onClick={() => handleEditRequestRule(row)} />
            <TimesIconButton
              label="reject"
              onClick={() => handleDeleteRequestRule(row)}
            />
          </div>
        </HasPrivileges>
      ),
    },
  ];

  return (
    <>
      <div className="col-md-12 attendance-profile-list px-0">
        <div className="mb-2 col pl-0 d-flex flex-column flex-md-row align-items-md-center justify-content-between pr-0 m-0">
          <div className="flex-wrap d-flex align-items-center mw-100">
            <RadioboxForm
              formName={dataTableRef}
              containerStyle="mb-0"
              optionsContainerStyle=""
              // optionItemStyle="mx-3"
              name="status_id"
              optionInputStyle
              options={[
                {
                  label: "all",
                  value: null,
                },
                {
                  label: "accept",
                  value: "1",
                },
                {
                  label: "reject",
                  value: "5",
                },
                {
                  label: "deny",
                  value: "6",
                },
              ]}
            />
          </div>

          <div className="flex-fill">
            <BSelect
              formName={dataTableRef}
              name="request_type_id"
              optionLabel="label"
              optionValue="value"
              options={REQUESTS.RequestTypeOptions}
              keepDefaultStyle
              isClearable
              placeholder={t("type")}
              rootStyle="mr-0 mr-md-2"
              icon="type"
            />
          </div>

          <HasPrivileges
            reqireMain={[
              Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS,
            ]}
            allowBP
          >
            <div className="flex-fill">
              <BSelect
                formName={dataTableRef}
                name="department_id"
                optionLabel="name"
                optionValue="id"
                options={props?.departmentsOptions}
                keepDefaultStyle
                isClearable
                placeholder={t("department")}
                rootStyle="mr-0 mr-md-2"
                icon="department"
              />
            </div>
          </HasPrivileges>

          <div className="flex-fill">
            <BSelect
              name="employee_id"
              formName={dataTableRef}
              keepDefaultStyle
              isClearable
              placeholder={t("select_employee")}
              options={[
                ...props?.requestRulesUsersOpts?.map((opt) => ({
                  ...opt,
                  department_id: opt?.department?.id,
                })),
              ]}
              optDependType={
                props?.data[dataTableRef]?.department_id ? "equal" : "notEqual"
              }
              optDependValue={[props?.data[dataTableRef]?.department_id]}
              optDependKey="department_id"
              rootStyle="mr-0 mr-md-2"
              icon="person"
            />
          </div>

          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_REQUEST_RULES]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div className="align-items-center d-flex col justify-content-end p-0">
                <button
                  disabled
                  type="button"
                  className={`btn py-1 px-3 add_new_btn_style text-nowrap `}
                >
                  {t("new rule")}
                </button>
              </div>
            }
          >
            <div className="align-items-center d-flex col justify-content-end p-0">
              <button
                type="button"
                className={`btn py-1 px-3 add_new_btn_style text-nowrap `}
                onClick={handleNewRequestRule}
              >
                {t("new rule")}
              </button>
            </div>
          </HasPrivileges>
        </div>

        {props.data?.requestRuleModalActions?.isLoading ||
        props.loadingDeleteRequestRule ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}

        <DataTable
          className="cards_table"
          columns={columns}
          data={props.data[dataTableRef].data}
          noHeader
          persistTableHead
          paginationComponent={() => (
            <Pagination
              tableRef={dataTableRef}
              styleWraper=""
              onPaginate={handlePaginate}
              reducer="super"
            />
          )}
          pagination={true}
          paginationServer={true}
          progressPending={props.data[dataTableRef].isLoading}
          progressComponent={<Loader />}
        />
        {props?.data?.requestRuleModalActions?.isVissible && <RequestRule />}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state?.auth?.userProfile,
    data: state.super,
    loadingDeleteRequestRule: state.super.loadingDeleteRequestRule,
    requestRulesUsersOpts: state.super.requestRulesUsersOpts,
    departmentsOptions: state.super.departmentsOptions,
  };
};

export default connect(mapStateToProps, {
  fetchRequestRulesListAction,
  showRequestRuleAction,
  onInputResetAction,
  upsertRequestRulesAction,
  deleteRequestRuleAction,
  fetchRequestRulesOptsAction,
  onInputChangeAction,
})(RequestRulesList);
