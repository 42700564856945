import React from "react";
import { connect } from "react-redux";
import { CheckboxBooleanForm } from "form-builder";
import MainModal from "../../Components/MainModal";
import { useTranslation } from "react-i18next";

const formName = "employeeListColumns";

const ToggleModal = (props) => {
  const { t } = useTranslation();

  return (
    <MainModal
      isOpen
      toggle={props?.toggleModal}
      modalTitle={t("toggle columns")}
      btnLabel={t("close")}
      btnOnClick={props?.toggleModal}
      btnSubmitLoading={props?.isLoading}
    >
      <div className="row">
        <div className="col-12">
          {/* (Start) phone */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("Phone Number")]}
            name="phone"
            type="checkbox"
          />
          {/* (End) phone */}

          {/* (Start) Email */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("Email")]}
            name="email"
            type="checkbox"
          />
          {/* (End) Email */}

          {/* (Start) Office */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("office")]}
            name="office"
            type="checkbox"
          />
          {/* (End) Office */}

          {/* (Start) Position */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("position")]}
            name="position"
            type="checkbox"
          />
          {/* (End) Position */}

          {/* (Start) Department */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("department")]}
            name="department"
            type="checkbox"
          />
          {/* (End) Department */}

          {/* (Start) Attendance Profile */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("attendance profile")]}
            name="attendance_profile"
            type="checkbox"
          />
          {/* (End) Attendance Profile */}

          {/* (Start) Managers */}
          <CheckboxBooleanForm
            formName={formName}
            options={["Managers"]}
            name="manager"
            type="checkbox"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="manager"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["direct manager"]}
              name="direct_manager"
              type="checkbox"
              resetValue={0}
            />
            <CheckboxBooleanForm
              dependOn="manager"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["copied manager"]}
              name="copied_manager"
              type="checkbox"
              resetValue={0}
            />
          </div>
          {/* (End) Managers */}

          {/* (Start) Joining Date */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("joining date")]}
            name="joining_date"
            type="checkbox"
          />
          {/* (End) Joining Date */}

          {/* (Start) Leaves */}
          <CheckboxBooleanForm
            formName={formName}
            options={["leaves"]}
            name="leaves"
            type="checkbox"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["annual leaves"]}
              name="remaining_annual_leaves"
              type="checkbox"
              resetValue={0}
            />
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["emergency"]}
              name="remaining_emergency_leaves"
              type="checkbox"
              resetValue={0}
            />
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["sick"]}
              name="remaining_sick_leaves"
              type="checkbox"
              resetValue={0}
            />
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["permissions"]}
              name="remaining_permissions"
              type="checkbox"
              resetValue={0}
            />
          </div>
          {/* (End) Leaves */}
        </div>
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: false,
    employeeListColumns: state.super.employeeListColumns,
  };
};

export default connect(mapStateToProps)(ToggleModal);
