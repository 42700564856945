import gql from "graphql-tag";

/*
  Offices
*/

/*
  Departments
*/

/*
  Positions
*/

/*
  Work Timings
*/

/*
  Leave & Breaks
*/

/*
  Attendance Profiles
*/

/*
  Holidays
*/

/*
  Documents
*/

/*
  Expense Categories
*/

/*
  Access levels
*/

/*
  Announcements
*/

export const announcementFragment = gql`
  fragment announcement on Announcement {
    __typename
    id
    body
    title
  }
`;
