import React from "react";
import useCustomQuery from "../../../Helpers/Hooks/useCustomQuery";
import useCustomMutation from "../../../Helpers/Hooks/useCustomMutation";

import moment from "moment";
import Privilages from "../../../Constants/Privilages";
import { dashboardCRMQuery } from "../../../Graphql/query";
import { openSwalConfirm } from "../../../Helpers/HelperFns";
import { removeActivityMutation } from "../../../Graphql/mutation";
import { dateFormat, dateTimeListFormat } from "../../../Constants";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../../Components/IconButtonWithTooltip";
import { DashboardList } from "../../../Components/CRM";
import { AddButton } from "../../../Components/Buttons";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ActivityModal } from "../../../Components/CRMFormModals";

const modalInitState = { isOpen: false, data: null };
const formatData = (data = []) => {
  const obj = {};

  for (const d of data) {
    const type = d.type ? JSON.parse(d.type)?.name : null;
    if (type) {
      const entry = {
        id: d.id,
        name: d?.lead?.name,
        email: d?.contact?.email,
        contact: d?.contact?.name,
        phone: d?.contact?.phones?.[0]?.[1],
        assignedTo: d?.lead?.assignedTo?.name,
        date: moment(d?.date).format(dateTimeListFormat),
      };

      obj[type] = obj[type] ? [...obj[type], entry] : [entry];
    }
  }

  return obj;
};

const ActivitiesDashboard = () => {
  // Local State
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [filters, setFilters] = React.useState({ scope: "All" });
  const [modalState, setModalState] = React.useState(modalInitState);

  // Server State
  const [removeActivity] = useCustomMutation(removeActivityMutation);
  const { refetch: refetchList } = useCustomQuery(dashboardCRMQuery, {
    variables: {
      isActivities: true,
      activityScope: filters.scope,
      to: moment().format(dateFormat),
      from: moment().format(dateFormat),
    },
    onCompleted: ({ activities = {} }) => {
      setData(formatData(activities?.data));
      setIsLoading(false);
    },
  });

  // Constants
  const columns = [
    { selector: "date" },
    { selector: "contact", style: { fontWeight: "bold" } },
    { selector: "name" },
    { selector: "email" },
    { selector: "phone" },
    { selector: "assignedTo" },
    {
      cell: (data) => (
        <div className="d-flex gap-10">
          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_ACTIVITIES]}>
            <EditIconButton onClick={() => handleOpenModal({ id: data.id })} />
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.DELETE_ACTIVITIES]}>
            <RemoveIconButton onClick={() => handleRemove(data)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handleOpenModal = (data) => {
    setModalState({ isOpen: true, data });
  };

  const handleCloseModal = () => {
    setModalState(modalInitState);
  };

  const handleSetShowedScope = (_, scope) => {
    setIsLoading(true);
    setFilters((prev) => ({ ...prev, scope }));
  };

  const handleRemove = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeActivity({
          refetchList,
          isShowErrorModal: true,
          variables: { id: data.id },
        });
      }
    });
  };

  return (
    <div className="boxContainer py-2">
      {/* Header */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-20">
          <strong className="font-18 blue-color">Activities</strong>
          <AddButton isIconOnly onClick={() => handleOpenModal()} />
        </div>
        <ToggleButtonGroup
          exclusive
          size="small"
          color="primary"
          value={filters.scope}
          aria-label="Set showed scope"
          onChange={handleSetShowedScope}
        >
          <ToggleButton value="All" sx={{ textTransform: "capitalize" }}>
            All
          </ToggleButton>
          <ToggleButton value="Mine" sx={{ textTransform: "capitalize" }}>
            Mine
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      {/* List */}
      <DashboardList
        data={data}
        color="#23aaeb"
        columns={columns}
        isLoading={isLoading}
      />

      {/* Modal */}
      {modalState.isOpen ? (
        <ActivityModal onClose={handleCloseModal} data={modalState.data} />
      ) : null}
    </div>
  );
};

export default ActivitiesDashboard;
