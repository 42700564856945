// DayOffExceptionConfigInput
export const dayOffException = {
  day_off_exception_id: null,
  normal_work_timing_id: null,
  first_half_work_timing_id: null,
  second_half_work_timing_id: null,
  weight: 1,
  apply_compensation: 0,
  compensation_type: "bonus",
  compensation_quantity: undefined,
  treat_as_normal: false,
  allow_permission: false,
  allow_overtime: false,
  offices_ids: [],
  additional_work_places: [],
  additional_work_places_signout: [],
  workplace_setting: "DEFAULT",
  workplace_setting_signout: "DEFAULT",
  workplace_setting_identical: 1,
};

// workRemotelySettings
export const workRemotelySettings = {
  canWorkRemote: false,
  employee_can_request: true,
  maxRemoteDaysPerWeek: 1,
  flexibleRemote: true,
  canExchangeDays: false,
  remoteDays: [],
};

// UpdateOrCreateAttendanceTypeConfigurationInput
export const attendanceTypeConfig = {
  att_type_id: null,
  attendanceType: "office based",
  user_id: null,
  start: undefined,
  end: undefined,
  attendanceProfileId: undefined,
  permissionLeavesBreakSettingId: undefined,
  workGroupId: null,
  workTeamId: null,
  joiningFrom: undefined,
  allowMultipleCheckIns: false,
  checkInFormId: undefined,
  weekStartDay: 0,
  save_with: "default",
  skip_work_timing_changed: true,
  allow_work_on_weekend_or_holiday: false,
  ...workRemotelySettings,
  ...dayOffException,
};
