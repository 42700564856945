import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  fetchLoansListSuccess,
  onInputResetAction,
  showLoansModalAction,
  fetchLoansEmployeeOptionsAction,
  showErrorToast,
} from "../../Store/Actions";
import { useLazyQuery } from "@apollo/client";
import { loansQuery } from "../../Graphql/query";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import Constants from "../../Helpers/Constants";
import LoanCard from "../../Components/LoanCard";
import { RadioboxForm } from "form-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import LoanModal from "../../Components/LoanModal";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import swal from "sweetalert";
import { useParams } from "react-router-dom";

const EmployeeLoans = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { userId } = useParams();

  // states from reducer
  const loansListStates = useSelector((state) => state?.super?.loansList);
  const loansModalActions = useSelector(
    (state) => state.super.loansModalActions
  );

  // handle fetch query data
  const [handleFetchLoansData, { loading: isFetchingLoansLoading }] =
    useLazyQuery(loansQuery, {
      variables: {
        input: {
          status: loansListStates?.status,
          user_id: userId,
        },
        rows: loansListStates?.pagination?.perPage,
        page: loansListStates?.pagination?.currentPage,
      },
      onError(err) {
        dispatch(
          showErrorToast(
            err?.graphQLErrors[0]?.extensions?.reason ||
              err?.graphQLErrors[0]?.message
          )
        );
      },
      onCompleted: (data) => {
        // to fetch loans list data
        dispatch(
          fetchLoansListSuccess(
            "loansList",
            data?.getLoansPerUsersWithConditions
          )
        );

        // to fetch loans employee options
        dispatch(fetchLoansEmployeeOptionsAction(data?.users_by_role?.data));
      },
    });

  useEffect(() => {
    handleFetchLoansData();
    return () => {
      dispatch(onInputResetAction("loansList", "status"));
    };
  }, []);

  // handle filter for list
  const handleFilter = () => {
    handleFetchLoansData({
      variables: {
        page: 1,
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    loansListStates?.status,
  ]);

  // handle pagination for list
  const handlePaginate = (page = loansListStates?.pagination.currentPage) => {
    handleFetchLoansData({
      variables: {
        page: page,
      },
    });
  };

  // handling add loan modal
  const handleAddLoan = () => {
    let data = {
      id: null,
    };
    dispatch(
      showLoansModalAction({
        data,
        ref: "loansModalActions",
        formName: "loansModal",
      })
    );
  };

  return (
    <>
      <div className="content loans_wrapper_style position-relative">
        {isFetchingLoansLoading ? (
          <div className="loader_wrapper_style position-absolute">
            <Loader />
          </div>
        ) : null}

        <div className="row my-3 align-items-baseline">
          <div className="col-12 col-lg-5">
            <RadioboxForm
              formName={"loansList"}
              options={Constants.loanStatusFilter}
              name="status"
              type="radio"
              containerStyle="my-2 my-lg-0"
              labelStyle="label-style"
              optionsContainerStyle="optionsContainerStyle"
              optionItemStyle="optionItemStyle"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
            />
          </div>

          <div className="col-12 col-lg-7">
            <div className="row align-items-baseline">
              <div className="d-flex col-12 justify-content-end">
                <HasPrivileges
                  reqireMain={[Privilages.ADD_EDIT_DELETE_LOAN]}
                  allowBP
                  avalibleOnExpire={false}
                  altExpireChildren={
                    <div>
                      <button
                        className="btn add_new_btn_style"
                        type="button"
                        disabled
                      >
                        <FontAwesomeIcon className="mr-2" icon={faPlus} />
                        <span>{t("new loan")}</span>
                      </button>
                    </div>
                  }
                >
                  <div>
                    <button
                      className="btn add_new_btn_style"
                      type="button"
                      onClick={handleAddLoan}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faPlus} />
                      <span>{t("new loan")}</span>
                    </button>
                  </div>
                </HasPrivileges>
              </div>
            </div>
          </div>
        </div>

        <div className="loans_view">
          <div className="loans_view_header">
            <span className="loans_view_header_item">{t("name")}</span>
            <span className="loans_view_header_item">{t("Total Amount")}</span>
            <span className="loans_view_header_item">{t("Remaining")}</span>
            <span className="loans_view_header_item">{t("Installments")}</span>
            <span className="loans_view_header_item">{t("created by")}</span>
            <span className="loans_view_header_item">{t("Note")}</span>
            <span className="loans_view_header_item">{t("Granted On")}</span>
            <span className="loans_view_header_item">{t("status")}</span>
          </div>

          <div className="loans_view_list">
            {loansListStates?.data?.length > 0 ? (
              <>
                {loansListStates?.data.map((loan) => (
                  <LoanCard
                    key={loan?.id}
                    {...loan}
                    handleFetchLoansData={handleFetchLoansData}
                    isInEmployeeProfile={true}
                    userId={userId}
                  />
                ))}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center px-4 pt-4 pb-0">
                {t("no_records")}
              </div>
            )}
          </div>
        </div>

        <Pagination
          tableRef={"loansList"}
          styleWraper=""
          onPaginate={handlePaginate}
        />
      </div>

      {/* Start of Loan Modal */}
      {loansModalActions?.isVissible ? (
        <LoanModal
          handleFetchLoansData={handleFetchLoansData}
          isInEmployeeProfile={true}
          userId={userId}
        />
      ) : null}
      {/* end of Loan Modal */}
    </>
  );
};

export default EmployeeLoans;
