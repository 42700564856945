import React from "react";

import { Box } from "@mui/system";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";

const DashboardList = ({ data, columns, color, isLoading }) =>
  !isLoading ? (
    Object.keys(data)?.length ? (
      Object.keys(data).map((key) => (
        <Box
          key={key}
          sx={{
            my: 2,
            borderRadius: 1.25,
            border: `1px solid ${color}`,
            borderTop: `4px solid ${color}`,
            ".rdt_TableHead": { display: "none" },
          }}
        >
          <strong
            className="d-block px-3 py-1"
            style={{
              borderBottom: `1px solid ${color}`,
            }}
          >
            {key}
          </strong>
          <DataTable noHeader columns={columns} data={data?.[key] || []} />
        </Box>
      ))
    ) : (
      <p className="pt-4 text-center">There are no records to display</p>
    )
  ) : (
    <Box sx={{ height: 200, ".site-loader-inner": { height: 1 } }}>
      <Loader />
    </Box>
  );

export default DashboardList;
