import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { InputForm } from "form-builder";
import { connect } from "react-redux";
import {
  dismissApplicantPhoneModalAction,
  updateApplicantPhoneAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = "editApplicantPhoneModal";
const formNameValidation = "editApplicantPhoneModalValidation";
const formServerValidation = "editApplicantPhoneModalServerValidation";

const EditApplicantPhoneModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (props.modalValidation.length) {
      return;
    }

    props.updateApplicantPhoneAction({
      data: props.editApplicantPhoneModal,
      applicantId: props?.editApplicantPhoneModal?.applicant_id,
      ref: "editApplicantPhoneModalActions",
    });
  };

  const toggleModal = () => {
    props.dismissApplicantPhoneModalAction({
      ref: "editApplicantPhoneModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [props?.modalData?.isVissible]);

  return (
    <MainModal
      isOpen={props?.modalData?.isVissible}
      toggle={toggleModal}
      modalTitle={t("Edit Applicant Phone Number")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={props?.modalData?.isLoading}
    >
      <div>
        <div className="row">
          <div className="col-12">
            <InputForm
              label="phone number"
              labelStyle="mb-2"
              {...FormProps}
              reducer="recruitment"
              validateBy="textRequired"
              name="phone_number"
              type="number"
              validationName={`input.phone_number`}
              containerStyle=""
              inputContainerStyle="w-100"
            />
          </div>
        </div>

        {/* (Start) Error Message */}
        {props?.modalData?.modalMessage && isSubmitting && (
          <div className="warnig-msg-style text-break">
            {props?.modalData?.modalMessage}
          </div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  data: state.recruitment,
  modalData: state.recruitment.editApplicantPhoneModalActions,
  modalValidation: state.recruitment[formNameValidation],
  editApplicantPhoneModal: state.recruitment[formName],
  serverVaildation: state.recruitment[formServerValidation],
});

export default connect(mapStateToProps, {
  dismissApplicantPhoneModalAction,
  updateApplicantPhoneAction,
})(EditApplicantPhoneModal);
