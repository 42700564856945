import { KeyboardBackspace } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import IntakeForm from "../../Containers/IntakeForm";
import { initRecruitmentIntakeFormAction } from "../../Store/Actions";

const IntakeFormPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoadingIntakeForm = useSelector(
    (state) => state.recruitment.initRecruitmentIntakeLoading
  );

  useEffect(() => {
    dispatch(initRecruitmentIntakeFormAction());
  }, []);
  return (
    <>
      <div className="content pt-3">
        <Link to="/recruitment?tab=intake-forms">
          <div className="back-btn d-flex align-items-center position-sticky mb-3">
            <KeyboardBackspace className="back-icon" />
            <span>{t("back")}</span>
          </div>
        </Link>
        <div className="col-12">
          <h3 className="page-title-bold">{t("new intake form")}</h3>
        </div>
        <IntakeForm />
      </div>
      {isLoadingIntakeForm && (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      )}
    </>
  );
};

export default IntakeFormPage;
