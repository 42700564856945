import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import { storeCommentMutation } from "../../Graphql/mutation";

import Loader from "../Loader";
import MainModal from "../MainModal";
import { InputForm } from "form-builder";
import SendIcon from "@mui/icons-material/Send";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import IconButtonWithTooltip from "../IconButtonWithTooltip";

const CommentModal = ({ data, canCommentPrivileges, onClose, refetchList }) => {
  const { t } = useTranslation();

  // Local State
  const [comment, setComment] = useState("");

  // Server State
  const [saveComment, { loading }] = useCustomMutation(storeCommentMutation);

  /* ↓ Helpers ↓ */

  const handleSaveComment = () => {
    saveComment({
      refetchList,
      onCloseModal: onClose,
      variables: {
        comment,
        id: data.id,
        type: data.type.toUpperCase(),
      },
    });
  };

  return (
    <MainModal isOpen toggle={onClose} modalTitle={t("comments")}>
      {loading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      {data.comments?.length ? (
        data.comments.map(({ comment, user }, i) => (
          <div key={i}>
            <strong>{user?.name}</strong>
            <p>{comment}</p>
          </div>
        ))
      ) : (
        <p>no comments added yet</p>
      )}

     
        <HasPrivileges reqireMain={canCommentPrivileges}>
          <div className="d-flex align-items-center gap-20">
            <InputForm
              value={comment}
              rootStyle="flex-fill"
              inputContainerStyle="w-100"
              onChange={(e) => setComment(e.target.value)}
            />
            <IconButtonWithTooltip
              label="add comment"
              onClick={handleSaveComment}
              icon={<SendIcon sx={{ color: "#23aaeb" }} />}
            />
          </div>
        </HasPrivileges>
 
    </MainModal>
  );
};

export default CommentModal;
