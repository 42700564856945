import React from "react";
import { CheckboxBooleanForm } from "form-builder";

const UnpaidLeavesSection = ({ formProps }) => {
  return (
    <>
      <CheckboxBooleanForm
        {...formProps}
        options={["Allow Employees To Request Unpaid Leaves"]}
        name="allow_unpaid_leaves"
        validationName="input.unpaid_leave_setting.unpaid_leaves"
        type="checkbox"
        containerStyle="leaves_checkbox_container mb-2"
      />

      <CheckboxBooleanForm
        {...formProps}
        options={[
          "Include Holidays and Weekends When Calculating Unpaid Leave Durations",
        ]}
        name="include_weekends_and_holidays_in_unpaid"
        validationName="input.unpaid_leave_setting.include_weekends_and_holidays_in_unpaid"
        type="checkbox"
        dependOn="allow_unpaid_leaves"
        dependancyType="equal"
        dependancyValue={[1]}
        containerStyle="leaves_checkbox_container"
      />
    </>
  );
};

export default UnpaidLeavesSection;
