import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  logoutAction,
  // createSignInAction,
  // createSignOutAction,
  // hideForceSignOutAlert,
  upsertUserLocaleAction,
  showNotificationDrawer,
} from "../../Store/Actions";
import moment from "moment-timezone";
import Helper from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { SidebarPrivilages } from "../../Constants/Nav";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

import Loader from "../Loader";
import { Link } from "react-router-dom";
import SetupWizardComponent from "./SetupWizardComponent";
import { Avatar, Badge, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButtonWithTooltip from "../../Components/IconButtonWithTooltip";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import {
  faChevronDown,
  faGlobe,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

let secTimer;
const Languages = [
  {
    code: "en-US",
    name: "English",
    countryCode: "us",
  },
  {
    code: "ar-EG",
    name: "العربية",
    countryCode: "eg",
  },
  // {
  //   code: "ar-SA",
  //   name: "العربية",
  //   countryCode: "sa",
  // },
];

const AttendanceNavbar = ({ Toggler }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobileMedia = useMediaQuery("(max-width: 425px)");

  // Local State
  const [dt, setDt] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Reducer State
  const id = useSelector((state) => state.auth.userProfile.id);
  const lng = useSelector((state) => state.auth.userProfile.lng);
  const name = useSelector((state) => state.auth.userProfile.name);
  const office = useSelector((state) => state.auth.userProfile.office);
  // const dismissHome = useSelector((state) => state.user.homeSignInAlert);
  const isUpsertLocale = useSelector((state) => state.user.isUpsertLocale);
  // const forceSignOut = useSelector((state) => state.user.forceSignOutAlert);
  const profile_picture = useSelector(
    (state) => state.auth.userProfile?.profile_picture
  );
  // const isSignedIn = useSelector((state) => state.user.isSignedIn);
  // const signInLoading = useSelector((state) => state.user.signInLoading);
  // const signOutLoading = useSelector((state) => state.user.signOutLoading);
  // const attendance_profile = useSelector((state) => state.auth.userProfile.attendance_profile);

  /* ↓ State Effects ↓ */

  useEffect(() => {
    clearInterval(secTimer);
    setDt(null);
    if (office?.time_zone) {
      secTimer = setInterval(() => {
        setDt(moment.tz(office?.time_zone));
      }, 1000);
    }
    return () => {
      if (office?.time_zone) {
        clearInterval(secTimer);
        setDt(null);
      }
    };
  }, [office?.time_zone]);

  /* ↓ Helpers ↓ */

  const handleUpsertUserLocale = (locale) => {
    dispatch(upsertUserLocaleAction(id, locale));
  };

  const toggle = () => setDropdownOpen(!dropdownOpen);

  // const handleSignIn = () => {
  //   dispatch(getSignInInfoAction());
  // };

  // const handleSignOut = () => {
  //   dispatch(getSignOutInfoAction());
  // };

  const handleLogout = () => {
    dispatch(logoutAction());
    window.localStorage.clear();
  };

  // const handleHomeSignIn = () => {
  //   dispatch(createSignInAction(1));
  // };

  // const handleOfficeSignIn = () => {
  //   dispatch(createSignInAction(0));
  // };

  // const handleForceSignOut = () => {
  //   dispatch(createSignOutAction(1));
  // };

  // const handleDismissForceSignOut = () => {
  //   dispatch(hideForceSignOutAlert());
  // };

  const showNotification = () => {
    dispatch(showNotificationDrawer());
  };

  return (
    <>
      <nav className="header d-flex align-items-center justify-content-between">
        {/* Logo */}
        <HasPrivileges
          reqireMain={SidebarPrivilages}
          allowBP
          altChildren={
            <Link to="/" aria-label="Navigate to home page" className="px-3">
              {isMobileMedia ? (
                <img
                  src="favicon.ico"
                  width="30"
                  height="auto"
                  alt="Mawared Logo"
                />
              ) : (
                <img
                  src={require("../../Assets/img/logo.svg").default}
                  width="120"
                  height="auto"
                  alt="Mawared Logo"
                />
              )}
            </Link>
          }
        >
          <Link
            to="/"
            aria-label="Navigate to home page"
            className="translateX-96 px-3 d-none d-md-block"
          >
            <img
              src={require("../../Assets/img/logo.svg").default}
              width="120"
              height="auto"
              alt="Mawared Logo"
            />
          </Link>
        </HasPrivileges>

        <Toggler />

        {window.location.pathname !== "/setup-wizard" ? (
          <HasPrivileges allowBP>
            <SetupWizardComponent />
          </HasPrivileges>
        ) : null}

        <ul className="list-unstyled d-flex align-items-center h-100 mb-0">
          <li className="nav-item timing_style d-sm-block d-none">
            {dt && dt.format("dddd, DD MMM hh:mm:ss A")}
          </li>
          <li className="nav-item timing_style d-sm-none d-block">
            {dt && dt.format("hh:mm:ss a")}
          </li>

          {/* {attendance_profile?.activeWorkTiming?.sign_in_req ? (
            <li className="nav-item sign_in_btn_style">
              {!isSignedIn ? (
                <button
                  type="button"
                  onClick={handleSignIn}
                  className="btn btn-success btn-sm mr-1 ml-5 mt-0 fit-width"
                  disabled={signInLoading}
                >
                  {!signInLoading ? (
                    t("sign in")
                  ) : (
                    <Spinner
                      style={{ width: "1rem", height: "1rem", color: "#fff" }}
                    />
                  )}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleSignOut}
                  className="btn btn-danger btn-sm mr-1 ml-5 fit-width"
                  disabled={signOutLoading}
                >
                  {!signOutLoading ? (
                    t("sign out")
                  ) : (
                    <Spinner
                      style={{ width: "1rem", height: "1rem", color: "#fff" }}
                    />
                  )}
                </button>
              )}
            </li>
          ) : null} */}

          {/* change language */}
          <Dropdown
            className="mx-2 language"
            nav
            isOpen={dropdownOpen}
            toggle={toggle}
          >
            <DropdownToggle
              nav
              caret
              tabIndex={-1}
              className="d-flex align-items-center px-0"
            >
              <IconButtonWithTooltip
                label={t("change language")}
                onClick={() => undefined}
                icon={
                  <FontAwesomeIcon
                    icon={faGlobe}
                    className="gray-color"
                    style={{ fontSize: "15px" }}
                  />
                }
              />
            </DropdownToggle>

            <DropdownMenu className="language">
              {Languages.map(({ code, name, countryCode }) => (
                <DropdownItem
                  key={countryCode}
                  onClick={() => handleUpsertUserLocale(code)}
                  className="d-flex flex-row justify-content-between align-items-center"
                  disabled={code.includes(lng)}
                >
                  <span
                    style={{
                      opacity: code.includes(lng) ? 0.5 : 1,
                    }}
                    className={`flag-icon flag-icon-${countryCode} mx-1`}
                  />
                  <span className="mx-1">{name}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>

          {/* Notifications */}
          <HasPrivileges
            reqireMain={[
              Privilages.CUSTOMIZE_NOTIFICATIONS,
              Privilages.VIEW_NOTIFICATIONS_HISTORY,
            ]}
            allowBP
          >
            <li className="nav-item dropdown">
              <IconButtonWithTooltip
                label={t("show notifications")}
                onClick={() => showNotification()}
                icon={
                  <Badge variant="dot" color="error">
                    <FontAwesomeIcon
                      size="sm"
                      icon={faEnvelope}
                      className="gray-color"
                    />
                  </Badge>
                }
              />
            </li>
          </HasPrivileges>

          {/* Logout */}
          <li className="nav-item dropdown has-arrow">
            <button
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              data-toggle="dropdown"
              aria-label="Toggle user menu"
              className="dropdown-toggle nav-link d-flex gap-10 align-items-center cursor-pointer btn"
            >
              <span className="align-middle gray-color d-none d-sm-block">
                {name}
              </span>

              <span className="user-img">
                <Avatar
                  src={profile_picture?.path}
                  className="rounded_circle_user_img blackish-avatar"
                >
                  {Helper.getIntials(name)}
                </Avatar>
              </span>

              <FontAwesomeIcon icon={faChevronDown} className="gray-color" />
            </button>
            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={handleLogout}>
                {t("logout")}
              </button>
            </div>
          </li>
        </ul>
      </nav>

      {/* {dismissHome.open ? (
        <MainModal
          btnLabel="yes"
          btnOnClick={handleHomeSignIn}
          btnLabelCancel="no"
          btnOnCancelClick={handleOfficeSignIn}
          isOpen={true}
        >
          {dismissHome.message}
        </MainModal>
      ) : null}

      {forceSignOut.open ? (
        <MainModal
          btnLabel="yes"
          btnOnClick={handleForceSignOut}
          btnLabelCancel="cancel"
          btnOnCancelClick={handleDismissForceSignOut}
          isOpen={true}
        >
          {forceSignOut.message}
        </MainModal>
      ) : null} */}

      {isUpsertLocale ? <Loader fixed /> : null}
    </>
  );
};

export default AttendanceNavbar;
