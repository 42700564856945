import React from "react";
import useCustomQuery from "../../../Helpers/Hooks/useCustomQuery";
import useCustomMutation from "../../../Helpers/Hooks/useCustomMutation";

import {
  revertReminderMutation,
  removeReminderMutation,
  completeReminderMutation,
} from "../../../Graphql/mutation";
import moment from "moment";
import Privilages from "../../../Constants/Privilages";
import { dashboardCRMQuery } from "../../../Graphql/query";
import { openSwalConfirm } from "../../../Helpers/HelperFns";
import { dateFormat, dateTimeListFormat } from "../../../Constants";

import {
  ToggleButton,
  FormControlLabel,
  ToggleButtonGroup,
} from "@mui/material";
import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
  AcceptIconButton,
} from "../../../Components/IconButtonWithTooltip";
import UndoIcon from "@mui/icons-material/Undo";
import AntSwitch from "../../../Components/AntSwitch";
import { DashboardList } from "../../../Components/CRM";
import { AddButton } from "../../../Components/Buttons";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import { ReminderModal } from "../../../Components/CRMFormModals";

let dataRef;
const modalInitState = { isOpen: false, data: null };
const formatData = (data = [], isShowCompleted) => {
  const obj = {};

  for (const d of data) {
    if (isShowCompleted) {
      if (d.status?.toLowerCase() !== "completed") continue;
    } else {
      if (d.status?.toLowerCase() === "completed") continue;
    }

    const type = d.type ? JSON.parse(d.type)?.name : null;
    if (type) {
      const entry = {
        id: d.id,
        status: d?.status,
        name: d?.lead?.name,
        email: d?.contact?.email,
        contact: d?.contact?.name,
        phone: d?.contact?.phones?.[0]?.[1],
        assignedTo: d?.lead?.assignedTo?.name,
        date: moment(d?.due_date).format(dateTimeListFormat),
      };

      obj[type] = obj[type] ? [...obj[type], entry] : [entry];
    }
  }

  return obj;
};

const RemindersDashboard = () => {
  // Local State
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [modalState, setModalState] = React.useState(modalInitState);
  const [filters, setFilters] = React.useState({
    scope: "All",
    isShowCompleted: false,
  });

  // Server State
  const [removeReminder] = useCustomMutation(removeReminderMutation);
  const [revertReminder] = useCustomMutation(revertReminderMutation);
  const [completeReminder] = useCustomMutation(completeReminderMutation);
  const { refetch: refetchList } = useCustomQuery(dashboardCRMQuery, {
    variables: {
      isReminders: true,
      remindersScope: filters.scope,
      to: moment().format(dateFormat),
      from: moment().format(dateFormat),
    },
    onCompleted: ({ reminders = {} }) => {
      dataRef = reminders?.data;
      setData(formatData(reminders?.data, filters.isShowCompleted));
      setIsLoading(false);
    },
  });

  // Constants
  const columns = [
    { selector: "date" },
    { selector: "contact", style: { fontWeight: "bold" } },
    { selector: "name" },
    { selector: "email" },
    { selector: "phone" },
    { selector: "assignedTo" },
    {
      cell: (data) => (
        <div className="d-flex gap-10">
          <HasPrivileges reqireMain={[Privilages.SET_REMINDERS_AS_COMPLETED]}>
            {data.status.toLowerCase() !== "completed" ? (
              <AcceptIconButton
                label="complete"
                iconProps={{ color: "#23aaeb" }}
                onClick={() => handleCompleteReminder(data)}
              />
            ) : (
              <IconButtonWithTooltip
                label="revert"
                icon={<UndoIcon fontSize="small" />}
                onClick={() => handleRevertReminder(data)}
              />
            )}
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_REMINDERS]}>
            <EditIconButton onClick={() => handleOpenModal({ id: data.id })} />
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.DELETE_REMINDERS]}>
            <RemoveIconButton onClick={() => handleRemove(data)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handleOpenModal = (data) => {
    setModalState({ isOpen: true, data });
  };

  const handleCloseModal = () => {
    setModalState(modalInitState);
  };

  const handleSetShowedScope = (_, scope) => {
    setIsLoading(true);
    setFilters((prev) => ({ ...prev, scope }));
  };

  const handleToggleShowCompleted = () => {
    setData(formatData(dataRef, !filters.isShowCompleted));
    setFilters((prev) => ({ ...prev, isShowCompleted: !prev.isShowCompleted }));
  };

  const handleRevertReminder = (data) => {
    const options = { text: "" };
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        revertReminder({
          refetchList,
          isShowErrorModal: true,
          variables: { id: data.id },
        });
      }
    }, options);
  };

  const handleCompleteReminder = (data) => {
    const options = { text: "" };
    openSwalConfirm((wilComplete) => {
      if (wilComplete) {
        completeReminder({
          refetchList,
          isShowErrorModal: true,
          variables: { id: data.id },
        });
      }
    }, options);
  };

  const handleRemove = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeReminder({
          refetchList,
          isShowErrorModal: true,
          variables: { id: data.id },
        });
      }
    });
  };

  return (
    <div className="boxContainer py-2">
      {/* Header */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-20">
          <strong className="font-18 blue-color">Reminders</strong>
          <AddButton isIconOnly onClick={() => handleOpenModal()} />
        </div>
        <ToggleButtonGroup
          exclusive
          size="small"
          color="primary"
          value={filters.scope}
          aria-label="Set showed scope"
          onChange={handleSetShowedScope}
        >
          <ToggleButton value="All" sx={{ textTransform: "capitalize" }}>
            All
          </ToggleButton>
          <ToggleButton value="Mine" sx={{ textTransform: "capitalize" }}>
            Mine
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <FormControlLabel
        labelPlacement="start"
        control={<AntSwitch />}
        label="show completed reminders"
        onChange={handleToggleShowCompleted}
        sx={{
          marginTop: 2,
          marginInlineStart: 0,
          ".MuiFormControlLabel-label": { fontSize: 14, marginInlineEnd: 2 },
        }}
      />

      {/* List */}
      <DashboardList
        data={data}
        color="#ffb700"
        columns={columns}
        isLoading={isLoading}
      />

      {/* Modal */}
      {modalState.isOpen ? (
        <ReminderModal onClose={handleCloseModal} data={modalState.data} />
      ) : null}
    </div>
  );
};

export default RemindersDashboard;
