import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import {
  storeContactMutation,
  changeContactMutation,
} from "../../Graphql/mutation";
import { checkIsContact, openSwalConfirm } from "../../Helpers/HelperFns";
import { clearCRMValidations, setCRMValidations } from "../../Store/Actions";

import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import { InputForm } from "form-builder";
import IntlTelInput from "react-intl-tel-input";
import { RemoveIconButton } from "../IconButtonWithTooltip";

const phoneInitState = ["", "", "", ""];
const formServerValidation = "contactServerValidation";

const ContactModal = ({ state, onClose, refetchList, onUpsertContact }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formData, setFormData] = React.useState({
    id: state?.id || undefined,
    name: "",
    email: "",
    position: "",
    phones: [phoneInitState],
  });

  // Reducer State
  const contactServerValidation = useSelector(
    (state) => state.crm[formServerValidation]
  );

  // Server State
  const [storeContact, { loading: isStoreLoading }] =
    useCustomMutation(storeContactMutation);
  const [changeContact, { loading: isChangeLoading }] = useCustomMutation(
    changeContactMutation
  );

  /* ↓ State Effects ↓ */

  React.useState(() => {
    if (state?.isEdit) {
      setFormData(state.data);
    }
  }, [Boolean(state?.isEdit)]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCRMValidations(formServerValidation));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleAddPhone = () => {
    setFormData((prev) => ({
      ...prev,
      phones: [...prev?.phones, phoneInitState],
    }));
  };

  const handleRemovePhone = (idx) => {
    setFormData((prev) => ({
      ...prev,
      phones: prev.phones.filter((_, i) => i !== idx),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (input, phoneIndex) => {
    if (input?.isPhone && isNaN(input?.num)) return;
    const phone = [
      input?.dialCode ?? formData.phones[phoneIndex][0],
      input?.num ?? formData.phones[phoneIndex][1],
      input?.iso2 ?? formData.phones[phoneIndex][2],
      input?.desc ?? formData.phones[phoneIndex][3],
    ];
    setFormData((prev) => ({
      ...prev,
      phones: prev.phones.map((p, i) => (i === phoneIndex ? phone : p)),
    }));
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    const upsert = state.isEdit ? changeContact : storeContact;
    const { phones, ...data } = formData;

    const t = (confirm = 0) =>
      upsert({
        refetchList,
        onCloseModal: onClose,
        variables: {
          input: {
            ...data,
            confirm,
            lead_id: state.data?.lead_id,
            phones: phones.filter((phone) => phone[1]),
          },
        },
        onCompleted: () => onUpsertContact && onUpsertContact(),
        onError: (err) => {
          err[0]?.extensions?.validation &&
            dispatch(
              setCRMValidations({
                ref: formServerValidation,
                validations: err[0]?.extensions?.validation,
              })
            );
          if (err[0]?.extensions?.validation?.i[0]) {
            const text = err[0]?.extensions?.validation?.i[0]
              .toLowerCase()
              .includes("email")
              ? "This email already exists. Do you want to proceed?"
              : "This phone number already exists. Do you want to proceed?";
            openSwalConfirm((isConfirmed) => isConfirmed && t(1), { text });
          }
        },
      });

    t();
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      btnSubmitLoading={isStoreLoading || isChangeLoading}
      modalTitle={t(`${state.isEdit ? "edit" : "new"} contact`)}
    >
      <InputForm
        name="name"
        label="name"
        onChange={handleChange}
        inputContainerStyle="w-100"
        value={
          state?.isEdit
            ? checkIsContact(formData.name)
              ? ""
              : formData.name
            : formData.name
        }
      />
      <InputForm
        name="email"
        label="email"
        value={formData.email}
        onChange={handleChange}
        inputContainerStyle="w-100"
        formServerValidation={formServerValidation}
        formSubmitting={formSubmitting}
        validationName="i.email"
        reducer="crm"
      />
      <InputForm
        name="position"
        label="position"
        value={formData.position}
        onChange={handleChange}
        inputContainerStyle="w-100"
      />
      <div className="d-flex align-items-center gap-20 my-2">
        <strong className="d-block">{t("Phone Numbers")}</strong>
        <AddButton isIconOnly onClick={handleAddPhone} />
      </div>
      {formData?.phones?.map((phone, i) => (
        <div key={i}>
          <div className="d-flex align-items-end gap-10 mb-2">
            <div>
              <label
                className={`d-block${
                  contactServerValidation["i.phones"] ? "red-color" : ""
                }`}
              >
                {t("phone")}
              </label>
              <IntlTelInput
                separateDialCode
                autoComplete="tel"
                value={phone?.[1] || ""}
                defaultCountry={phone?.[2] || "eg"}
                inputClassName="input-style-default"
                onSelectFlag={(num, { dialCode, iso2 }) =>
                  handlePhoneChange({ dialCode, num, iso2 }, i)
                }
                onPhoneNumberChange={(_, num, { dialCode, iso2 }) =>
                  handlePhoneChange({ dialCode, num, iso2, isPhone: true }, i)
                }
              />
            </div>
            <InputForm
              icon="phone"
              rootStyle="w-50"
              name="description"
              label="description"
              containerStyle="pb-0"
              placeholder={t("whatsapp number")}
              labelStyle="w-100"
              value={phone?.[3] || ""}
              inputContainerStyle="w-100"
              onChange={(e) => handlePhoneChange({ desc: e.target.value }, i)}
            />
            {formData?.phones.length > 1 ? (
              <RemoveIconButton onClick={() => handleRemovePhone(i)} />
            ) : null}
          </div>
          {contactServerValidation["i.phones"] ? (
            <p className="mt-1 mb-1 text-12 red-color">
              {contactServerValidation["i.phones"]}
            </p>
          ) : null}
        </div>
      ))}
    </MainModal>
  );
};

export default ContactModal;
