import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import HelperFns from "../Helpers/HelperFns";

const MainModal = ({
  toggle,
  modalTitle = "",
  btnLabel = "save",
  btnOnClick,
  btnSubmitLoading = false,
  btnCancelLoading = false,
  disableSubmitButton = false,
  btnLabelCancel = "Cancel",
  btnOnCancelClick,
  children,
  modalFooterComponent,
  modalHeader,
  hasModalFooter = true,
  skipModalHeaderLocal = false,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Modal fade={false} centered={true} {...props}>
      {modalTitle && (
        <ModalHeader
          toggle={toggle}
          dir={HelperFns.isStringRTL(modalTitle) ? "rtl" : "ltr"}
        >
          {skipModalHeaderLocal ? modalTitle : t(modalTitle)}
        </ModalHeader>
      )}
      {modalHeader ? modalHeader : null}

      <ModalBody>{children}</ModalBody>

      {hasModalFooter ? (
        <ModalFooter>
          {modalFooterComponent ? (
            modalFooterComponent()
          ) : (
            <>
              {btnOnCancelClick && (
                <Button
                  disabled={btnCancelLoading}
                  color="danger"
                  onClick={btnOnCancelClick}
                >
                  {btnCancelLoading ? (
                    <Spinner
                      style={{ width: "1rem", height: "1rem", color: "#fff" }}
                    />
                  ) : (
                    t(btnLabelCancel)
                  )}
                </Button>
              )}

              {btnOnClick && (
                <Button
                  disabled={btnSubmitLoading || disableSubmitButton}
                  color="primary"
                  type="submit"
                  onClick={btnOnClick}
                >
                  {btnSubmitLoading ? (
                    <Spinner
                      style={{ width: "1rem", height: "1rem", color: "#fff" }}
                    />
                  ) : (
                    t(btnLabel)
                  )}
                </Button>
              )}
            </>
          )}
        </ModalFooter>
      ) : null}
    </Modal>
  );
};

export default MainModal;
