import {
  put,
  call,
  takeLatest,
  takeEvery,
  select,
  delay,
  throttle,
} from "redux-saga/effects";
import Types from "../Actions/types";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Helper from "../../Helpers/HelperFns";

import Store from "..";

import * as Action from "../Actions";
import * as client from "../../Services/Client";
import history from "../../Helpers/Navigations/history";
import HelperFns from "../../Helpers/HelperFns";
import moment from "moment";

export function* handleEditEmployeeSaga({ payload }) {
  try {
    const refEmployee = yield call(client.editEmployee, payload);
    // console.log("s", refEmployee);
    yield put(Action.openEditEmployeeAction());
    yield put(Action.editEmployeeSuccess(refEmployee.data));
    // yield put(Action.initEmployeeSuccess(refEmployee.data));
  } catch (error) {
    yield put(Action.editEmployeeFailed());
    console.log("errors", { error });
  }
}

export function* handleInitEmployeeSaga({ payload }) {
  try {
    const response = yield call(client.initEmployee, payload);
    yield put(Action.initEmployeeSuccess(response?.data));
  } catch (error) {
    yield put(Action.initEmployeeFailed());
    console.log("err", { error });
  }
}

export function* handleUpdateEmployeeSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
    let authReducer = yield select(getAuthReducer); // <-- get the inbound requests
    const refEmployee = yield call(client.updateEmployee, payload);
    if (!refEmployee.errors) {
      yield put(Action.updateEmployeeSuccess(refEmployee.data));
      if (window.location.pathname.includes("employee-profile")) {
        window.location.reload();
        return;
      }
      if (superReducer.editEmployeeForm) {
        yield put(Action.closeEditEmployeeAction());
        yield put(
          Action.fetchAllEmployeesAction({
            ref: "allEmployeesList",
            allEmployeesList: superReducer["allEmployeesList"],
          })
        );
      } else {
        history.goBack();
      }
      yield put(Action.showSuccessToast());
    } else {
      if (
        refEmployee.errors[0]?.extensions?.validation &&
        "input.user_input.force_save" in
        refEmployee.errors[0]?.extensions?.validation
      ) {
        swal({
          text: HelperFns.localizeFns("force_save"),
          icon: "warning",
          className: "swal-warning-style",
          buttons: true,
        }).then((forceSave) => {
          if (forceSave) {
            Store.dispatch(
              Action.upsertEmployeeAction({ ...payload, force_save: true })
            );
          }
        });
        yield put(
          Action.updateEmployeeFailed(
            Helper.parseValidatorErrors(
              refEmployee.errors[0]?.extensions?.validation
            )
          )
        );
      }
      if (
        refEmployee.errors[0]?.extensions?.validation &&
        "input.user_input.force_update_att_profile" in
        refEmployee.errors[0]?.extensions?.validation
      ) {
        HelperFns.checkPassword(
          "",
          Object.values(refEmployee?.errors[0]?.extensions?.validation)[0]
            .toString()
            .replace(/(?:\r\n|\r|\n)/g, "<br>"),
          "Confirm",
          "force_update_att_profile_swal_style",
          yield put(Action.upsertEmployeeAction({
            ...payload,
            force_update_att_profile: true,
          }))
        );
        yield put(
          Action.updateEmployeeFailed(
            Helper.parseValidatorErrors(
              refEmployee.errors[0]?.extensions?.validation
            )
          )
        );
      }
      if (
        refEmployee.errors[0]?.extensions?.validation &&
        "input.user_input.force_email" in
        refEmployee.errors[0]?.extensions?.validation
      ) {
        swal({
          text: HelperFns.localizeFns("force_email"),
          icon: "warning",
          className: "swal-warning-style",
          buttons: true,
        }).then((forceSave) => {
          if (forceSave) {
            Store.dispatch(
              Action.upsertEmployeeAction({ ...payload, force_email: true })
            );
          }
        });
        yield put(
          Action.updateEmployeeFailed(
            Helper.parseValidatorErrors(
              refEmployee.errors[0]?.extensions?.validation
            )
          )
        );
      } else {
        yield put(
          Action.updateEmployeeFailed(
            Helper.parseValidatorErrors(
              refEmployee.errors[0]?.extensions?.validation
            )
          )
        );
      }
    }
  } catch (error) {
    yield put(
      Action.updateEmployeeFailed(
        error ? error.message : "Something went wrong!"
      )
    );
  }
}

export function* handleFetchAttProfilesListSaga({ payload }) {
  try {
    const refAttLists = yield call(client.fetchAttProfiles, payload);
    yield put(
      Action.fetchAttProfilesListSuccess(
        payload.ref,
        refAttLists.data?.company_attendance_profiles
      )
    );
    yield put(
      Action.updateTotalFeatureCount(
        payload.ref,
        refAttLists.data?.total?.paginatorInfo?.total
      )
    );
  } catch (error) {
    yield put(Action.fetchAttProfilesListFailed());
  }
}

export function* handleCreateAttProfileSaga({ payload }) {
  try {
    const refAttProfile = yield call(client.createAttProfile, payload);
    yield put(Action.createAttProfileSuccess(refAttProfile.data?.attPro));
  } catch (error) {
    yield put(Action.createAttProfileFailed(error));
  }
}

export function* handleFetchAttProfileSaga({ payload }) {
  try {
    const response = yield call(client.fetchAttProfile, payload);

    yield put(
      Action.fetchAttendanceProfileSuccess(
        response?.data?.attPro,
        response?.data?.workPlaces?.data
      )
    );
    yield put(
      Action.updateAttendanceHolidaysOptions(response?.data?.holidays?.data)
    );
    yield put(Action.fetchWorktimeAction(response.data?.work_timings?.data));
    yield put(
      Action.UpdateLeavesBreaksOptions(
        response?.data?.PermissionLeavesBreakSettingsForMenu?.data
      )
    );
  } catch (error) {
    yield put(Action.fetchAttendanceProfileFailed());
  }
}

export function* handleFetchAllEmployeesListSaga({ payload }) {
  try {
    const refAllEmployees = yield call(client.fetchAllEmployees, payload);
    yield put(
      Action.fetchAllEmployeesListSuccess(
        payload.ref,
        refAllEmployees.data?.employees ??
        refAllEmployees.data?.managedEmployees
      )
    );
    yield put(
      Action.updateTotalFeatureCount(
        payload.ref,
        refAllEmployees?.data?.total?.company?.canAddNewEmployee
      )
    );
    yield put(
      Action.fetchAllDepartmentsByCompany({
        ref: "allDepartmentsByCompany",
        data: refAllEmployees.data?.company_departments.data,
      })
    );
    yield put(Action.fetchAllManagersAction(refAllEmployees?.data?.managers));
    yield put(
      Action.fetchAllOfficesOptionsAction(
        refAllEmployees?.data?.company_offices?.data
      )
    );
    yield put(
      Action.setAllPositionOptionsAction(
        refAllEmployees?.data?.company_positions?.data
      )
    );
    yield put(
      Action.fetchAllWorkTimingsMenu(
        refAllEmployees?.data?.work_timings_menu ?? []
      )
    );
  } catch (error) {
    yield put(Action.fetchAllEmployeesListFailed(payload.ref, error));
    console.log("err", error);
  }
}

export function* handleFetchOfficeHolidaysSaga({ payload }) {
  try {
    const response = yield call(client.fetchOfficeHolidays, payload);
    yield put(Action.fetchOfficeHolidaysSuccess(response.data?.holidays?.data));
    yield put(Action.fetchWorktimeAction(response.data?.work_timings?.data));
  } catch (error) {
    yield put(Action.fetchOfficeHolidaysFailed());
    console.log("err", error);
  }
}

export function* handleFetchAllOfficesSaga({ payload }) {
  try {
    const refAllOffices = yield call(client.fetchAllOffices, payload);
    yield put(
      Action.fetchAllOfficesSuccess(payload.ref, refAllOffices.data?.offices)
    );
    yield put(
      Action.updateTotalFeatureCount(
        payload.ref,
        refAllOffices?.data?.total?.paginatorInfo?.total
      )
    );
  } catch (error) {
    yield put(Action.fetchAllOfficesFailed(payload.ref, error));
    console.log("err", error);
  }
}

export function* handleFetchAllDepartmentsSaga({ payload }) {
  try {
    const refAllDepartments = yield call(client.fetchAllDepartments, payload);
    yield put(
      Action.fetchAllDepartmentsSuccess(
        payload.ref,
        refAllDepartments.data?.departments
      )
    );
  } catch (error) {
    yield put(Action.fetchAllDepartmentsFailed(payload.ref, error));
    console.log("err", error);
  }
}

export function* handleFetchAllPositionsSaga({ payload }) {
  try {
    const refAllPositions = yield call(client.fetchAllPositions, payload);
    yield put(
      Action.fetchAllPositionsSuccess(
        payload.ref,
        refAllPositions.data?.positions
      )
    );
  } catch (error) {
    yield put(Action.fetchAllPositionsFailed(payload.ref, error));
    console.log("err", error);
  }
}

export function* handleFetchAllHolidaysSaga({ payload }) {
  try {
    const refAllHolidays = yield call(client.fetchAllHolidays, payload);
    yield put(
      Action.fetchAllHolidaysSuccess(
        payload.ref,
        refAllHolidays.data?.company_holidays,
        refAllHolidays.data?.profile?.company?.auto_suggest_holidays
      )
    );
    yield put(
      Action.fetchHolidayOfficesAction(refAllHolidays?.data?.company_offices)
    );
    yield put(
      Action.fetchHolidayAttProfilesAction(
        refAllHolidays?.data?.company_attendance_profiles
      )
    );
  } catch (error) {
    yield put(Action.fetchAllHolidaysFailed(payload.ref, error));
    console.log("err", error);
  }
}

export function* handleFetchInboundRequestsSaga({ payload }) {
  try {
    const inboundRequests = yield call(client.fetchInboundRqeuests, payload);

    if (!inboundRequests?.errors) {
      if ("users_requests" in inboundRequests.data) {
        yield put(
          Action.fetchInboundRequestsSuccess(
            payload.ref,
            inboundRequests.data?.users_requests
          )
        );
      }
      if ("totalPendingRequests" in inboundRequests.data) {
        yield put(
          Action.fetchTotalPendingRequests(
            inboundRequests.data?.totalPendingRequests?.paginatorInfo?.total
          )
        );
      }
      if ("employees" in inboundRequests.data) {
        yield put(
          Action.updateRequestsEmployeesFilter(inboundRequests.data?.employees)
        );
      }
    } else {
      yield put(
        Action.fetchInboundRequestsFailed(payload.ref, inboundRequests?.errors)
      );
    }
  } catch (error) {
    yield put(Action.fetchInboundRequestsFailed(payload.ref, error));

    console.log("err", error.response);
  }
}

export const getSuperReducer = (state) => state.super;
export const getAuthReducer = (state) => state.auth;
export const getUserReducer = (state) => state.user;

export function* handleAcceptInboundRequestSaga({ payload }) {
  try {
    const response = yield call(client.acceptInboundRqeuest, payload);
    if (!response.errors) {
      if (
        response?.data?.accept_request?.__typename === "Payroll" ||
        response?.data?.accept_request?.__typename === "GeneralException"
      ) {
        yield put(Action.acceptInboundRequestFailed(payload.id));
        yield put(
          Action.showErrorToast(response?.data?.accept_request?.message)
        );
        return;
      }
      yield put(
        Action.acceptInboundRequestSuccess(
          payload?.id,
          payload?.page_flag?.toLowerCase() === "notifications"
        )
      ); //second true to update notifications request ui
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      if (payload?.page_flag?.toLowerCase() === "notifications") {
        yield put(Action.hideViewAttRequestModal());
        return;
      }
      // Case Edit Attendance Request
      if (payload.isEditRequestModal) {
        yield put(Action.hideViewAttRequestModal());
      }
      return;
    } else {
      yield put(Action.acceptInboundRequestFailed(payload?.id));
      yield put(Action.showErrorToast(response?.errors[0]?.extensions?.reason));
    }
    yield put(Action.acceptInboundRequestFailed(payload.id));
    yield put(Action.showErrorToast(response?.errors[0]?.extensions?.reason));
  } catch (error) {
    yield put(Action.acceptInboundRequestFailed(payload?.id));
    yield put(Action.showErrorToast(error?.message));
  }
}

export function* handleAcceptOvertimeRequestSaga({ payload }) {
  try {
    const response = yield call(client.acceptOvertimeRqeuest, payload);
    if (!("errors" in response)) {
      yield put(
        Action.acceptOvertimeRequestSuccess(response?.data?.accept_overtime?.id)
      );
      yield put(Action.showSuccessToast());
      return;
    } else {
      console.log("resopnse", response.errors[0]);
      yield put(Action.acceptOvertimeRequestFailed(response?.errors));
      if (response.errors[0].extensions?.parameters?.force_confirm) {
        swal({
          title: HelperFns.localizeFns("are you sure"),
          text: HelperFns.localizeFns(
            `override ${response.errors[0]?.extensions.reason}`
          ),
          icon: "warning",
          className: "swal-warning-style",
          dangerMode: true,
          buttons: true,
        }).then((confirm) => {
          if (confirm) {
            Store.dispatch(
              Action.acceptOvertimeRequestAction(
                payload.id,
                payload.interval,
                payload.created_at,
                payload?.employeeId,
                payload.page_flag,
                true
              )
            );
          }
        });
      }
    }
  } catch (error) {
    console.log({ error });
    yield put(Action.acceptOvertimeRequestFailed([]));
    yield put(Action.showErrorToast());
  }
}

export function* handleFetchEditAcceptedOvertimeRequestDataSaga({ payload }) {
  try {
    const response = yield call(
      client.fetchEditAcceptedOvertimeRequest,
      payload
    );
    if (!response?.errors) {
      yield put(
        Action.fetchEditAccpetedOvertimeRequestDataSuccess(
          response?.data?.edit_accepted_overtime_request
        )
      );
    } else {
      yield put(Action.fetchEditAccpetedOvertimeRequestDataFailed());

      yield put(
        Action.dismissOvertimeModalAction({
          ref: "overtimeModalActions",
          formName: "overtimeModal",
        })
      );
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchEditAccpetedOvertimeRequestDataFailed());

    yield put(
      Action.dismissOvertimeModalAction({
        ref: "overtimeModalActions",
        formName: "overtimeModal",
      })
    );
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchEditAcceptedOvertimeRequestData() {
  yield takeEvery(
    Types.FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_ATTEMPT,
    handleFetchEditAcceptedOvertimeRequestDataSaga
  );
}

export function* handleRejectInboundRequestSaga({ payload }) {
  try {
    const response = yield call(client.rejectInboundRqeuest, payload);
    if (!response.errors) {
      if (
        response?.data?.reject_request?.__typename === "GeneralException" ||
        response?.data?.reject_request?.__typename === "Payroll"
      ) {
        yield put(Action.rejectInboundRequestFailed(payload.id));
        yield put(
          Action.showErrorToast(response?.data?.reject_request?.message)
        );
        return;
      }
      yield put(
        Action.rejectInboundRequestSuccess(
          payload?.id,
          payload.page_flag?.toLowerCase() === "notifications"
        )
      ); //second true to update notifications request ui
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      if (payload.page_flag?.toLowerCase() === "notifications") {
        yield put(Action.hideViewAttRequestModal());
        return;
      }
      // Case Edit Attendance Request
      if (payload.isEditRequestModal) {
        yield put(Action.hideViewAttRequestModal());
      }
      return;
    } else {
      yield put(Action.rejectInboundRequestFailed(payload?.id));
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
    yield put(Action.showErrorToast(response?.errors?.[0]?.extensions?.reason));
    yield put(Action.rejectInboundRequestFailed(payload.id));
  } catch (error) {
    yield put(Action.rejectInboundRequestFailed(payload?.id));
    yield put(Action.showErrorToast(error?.message));
  }
}

export function* handleFetchAttendanceLogsSaga({ payload }) {
  try {
    const response = yield call(client.fetchAttendanceLogs, payload);

    if ("attendanceLogs" in response.data) {
      yield put(
        Action.fetchAttendanceLogsSuccess(
          payload.ref,
          response.data?.attendanceLogs
        )
      );
    }
    if ("employees" in response.data) {
      yield put(
        Action.updateEmployeesLogsFilter(response.data?.employees?.data)
      );
    }

    if ("company_departments" in response?.data) {
      yield put(
        Action.fetchAllDepartmentsByCompany({
          ref: "allDepartmentsByCompany",
          data: response?.data?.company_departments?.data,
        })
      );
    }

    if (response?.data?.company_offices) {
      yield put(
        Action.fetchAllOfficesOptionsAction(
          response?.data?.company_offices?.data
        )
      );
    }

    if (response?.data?.work_timings) {
      yield put(
        Action.fetchAllWorkTimingsOptionsAction(
          response?.data?.work_timings?.data
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchAttendanceLogsFailed(payload.ref, error));

    console.log("err", error.response);
  }
}

export function* handleFetchSuspensionLogsSaga({ payload }) {
  try {
    const response = yield call(client.fetchSuspensionLogs, payload);
    yield put(
      Action.fetchSuspensionLogsSuccess(
        payload.ref,
        response.data?.users_suspensions
      )
    );
  } catch (error) {
    yield put(Action.fetchSuspensionLogsFailed(payload.ref, error));

    console.log("err", error.response);
  }
}

export function* handleFetchBalanceLogsSaga({ payload }) {
  try {
    const response = yield call(client.fetchBalanceLogs, payload);
    if ("users_balances" in response.data) {
      yield put(
        Action.fetchBalanceLogsSuccess(
          payload.ref,
          response.data?.users_balances
        )
      );
    }
    if ("employees" in response.data) {
      yield put(
        Action.updateEmployeesLogsFilter(response.data?.employees?.data)
      );
    }
  } catch (error) {
    yield put(Action.fetchBalanceLogsFailed(payload.ref, error));

    console.log("err", error.response);
  }
}

export function* handleFetchOvertimeLogsSaga({ payload }) {
  try {
    const response = yield call(client.fetchOvertimeLogs, payload);
    yield put(
      Action.fetchOvertimeLogsSuccess(
        payload.ref,
        response.data?.users_overtime
      )
    );
  } catch (error) {
    yield put(Action.fetchOvertimeLogsFailed(payload.ref, error));

    console.log("err", error.response);
  }
}

export function* handleUpdateDepartmentSaga({ payload }) {
  try {
    const response = yield call(client.updateDepartment, payload.data);
    if (!response.errors) {
      yield put(Action.updateDepartmentSuccess(payload.ref));
      yield put(
        Action.dismissDepartmentModalAction({
          ref: payload.ref,
          formName: "departmentModal",
        })
      );
      yield put(Action.showSuccessToast("edit_success"));
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      yield put(
        Action.fetchAllDepartmentsAction({
          ref: "departmentsList",
          departmentsList: superReducer["departmentsList"],
        })
      );
    } else {
      yield put(
        Action.updateDepartmentFailed(
          payload.ref,
          response.errors[0]?.extensions?.reason
            ? response.errors[0]?.extensions?.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      Action.updateDepartmentFailed(
        payload.ref,
        error.message ? error.message : "Something went wrong!"
      )
    );

    console.log("err", error.response);
  }
}

export function* handleCreateDepartmentSaga({ payload }) {
  try {
    const response = yield call(client.createDepartment, payload.data);
    if (!response.errors) {
      yield put(Action.createDepartmentSuccess(payload.ref));
      yield put(
        Action.dismissDepartmentModalAction({
          ref: payload.ref,
          formName: "departmentModal",
        })
      );
      yield put(Action.showSuccessToast("edit_success"));
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      yield put(
        Action.fetchAllDepartmentsAction({
          ref: "departmentsList",
          departmentsList: superReducer["departmentsList"],
        })
      );
      yield put(Action.fetchSetupWizardEssentialDataAttempt());
    } else {
      yield put(
        Action.createDepartmentFailed(
          payload.ref,
          response.errors[0]?.extensions?.reason
            ? response.errors[0]?.extensions?.reason
            : response.errors[0]?.message ?? "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      Action.createDepartmentFailed(
        payload.ref,
        error.message ? error.message : "Something went wrong!"
      )
    );

    console.log("err", error.response);
  }
}

export function* handleDeleteDepartmentSaga({ payload }) {
  try {
    const response = yield call(client.deleteDepartment, payload);
    if (
      !response.errors &&
      response.data?.delete_department.status == "success"
    ) {
      yield put(Action.deleteDepartmentSuccess());
      yield put(
        Action.showSuccessToast(response?.data?.delete_department?.message)
      );
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      yield put(
        Action.fetchAllDepartmentsAction({
          ref: "departmentsList",
          departmentsList: superReducer["departmentsList"],
        })
      );
      yield put(Action.fetchSetupWizardEssentialDataAttempt());
    } else {
      yield put(Action.deleteDepartmentFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_department?.message
            ? response.data?.delete_department?.message
              ?.replace(/(\"\,|\[\")/g, "\n \n • ")
              ?.replace(/(\"|\])/g, "")
            : response.errors[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteDepartmentFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

export function* handleDeleteAttProfileSaga({ payload }) {
  try {
    const response = yield call(client.deleteAttProfile, payload);
    if (
      !response.errors &&
      response.data?.delete_attendance_profile.status == "success"
    ) {
      yield put(Action.deleteAttProfileSuccess());
      yield put(
        Action.showSuccessToast(
          response?.data?.delete_attendance_profile?.message
        )
      );
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      yield put(
        Action.fetchAttProfilesListAction({
          ref: "attendanceProfilesList",
          attendanceProfilesList: superReducer["attendanceProfilesList"],
        })
      );
      yield put(Action.fetchSetupWizardEssentialDataAttempt());
    } else {
      yield put(Action.deleteAttProfileFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_attendance_profile.message ||
          response.errors[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteAttProfileFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}
export function* handleCloneAttProfileSaga({ payload }) {
  try {
    const response = yield call(client.cloneAttProfile, payload);
    if (!response.errors) {
      yield put(Action.cloneAttProfileSuccess());
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      yield put(
        Action.fetchAttProfilesListAction({
          ref: "attendanceProfilesList",
          attendanceProfilesList: superReducer["attendanceProfilesList"],
        })
      );
    } else {
      yield put(Action.cloneAttProfileFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.cloneAttProfileFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

export function* handleUpdatePositionSaga({ payload }) {
  try {
    const response = yield call(client.updatePosition, payload.data);
    if (!response.errors) {
      yield put(Action.updatePositionSuccess(payload.ref));
      yield put(
        Action.dismissPositionModalAction({
          ref: payload.ref,
          formName: "positionModal",
        })
      );
      yield put(Action.showSuccessToast("edit_success"));
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      yield put(
        Action.fetchAllPositionsAction({
          ref: "positionsList",
          positionsList: superReducer["positionsList"],
        })
      );
    } else {
      yield put(
        Action.updatePositionFailed(
          payload.ref,
          response.errors[0]?.extensions?.reason
            ? response.errors[0]?.extensions?.reason
            : response.errors[0]?.message ?? "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      Action.updatePositionFailed(
        payload.ref,
        error.message ? error.message : "Something went wrong!"
      )
    );

    console.log("err", error.response);
  }
}

export function* handleCreatePositionSaga({ payload }) {
  try {
    const response = yield call(client.createPosition, payload.data);
    if (!response.errors) {
      yield put(Action.createPositionSuccess(payload.ref));
      yield put(
        Action.dismissPositionModalAction({
          ref: payload.ref,
          formName: "positionModal",
        })
      );
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      yield put(
        Action.fetchAllPositionsAction({
          ref: "positionsList",
          positionsList: superReducer["positionsList"],
        })
      );
      yield put(Action.fetchSetupWizardEssentialDataAttempt());
    } else {
      yield put(
        Action.createPositionFailed(
          payload.ref,
          response.errors[0]?.extensions?.reason
            ? response.errors[0]?.extensions?.reason
            : response.errors[0]?.message ?? "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      Action.createPositionFailed(
        payload.ref,
        error.message ? error.message : "Something went wrong!"
      )
    );

    console.log("err", error.response);
  }
}

export function* handleCreateHolidaysSaga({ payload }) {
  try {
    const response = yield call(client.upsertHoliday, payload.data);
    if (!response.errors) {
      yield put(Action.createHolidaySuccess(payload.ref));
      yield put(
        Action.dismissHolidaysModalAction({
          ref: payload.ref,
          formName: "holidaysModal",
        })
      );
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllHolidaysAction({
          ref: "holidaysList",
          holidaysList: superReducer["holidaysList"],
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.createHolidayFailed({
          ref: payload.ref,
          serverRef: "holidaysModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.createHolidayFailed({
          ref: payload.ref,
          serverRef: "holidaysModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            HelperFns.localizeFns("something went wrong"),
        })
      );
    }
  } catch (error) {
    yield put(
      Action.createHolidayFailed({
        ref: payload.ref,
        serverRef: "holidaysModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

export function* handleUpdateOfficeSaga({ payload }) {
  try {
    const response = yield call(client.updateOffice, payload);
    if (!response.errors) {
      yield put(Action.updateOfficeSuccess(response));
      yield put(Action.refreshSignInOutAction());
      yield put(Action.showSuccessToast());
      history.goBack();
      yield put(Action.fetchSetupWizardEssentialDataAttempt());
    } else {
      if (response?.errors[0]?.message == "swal") {
        swal({
          text: response.errors[0]?.extensions?.reason,
          icon: "warning",
          className: "swal-warning-style",
          buttons: {
            defeat: {
              text: HelperFns.localizeFns("No"),
              value: "reject",
            },
            catch: {
              text: HelperFns.localizeFns("Yes"),
              value: "accept",
            },
          },
        }).then((value) => {
          if (value) {
            Store.dispatch(
              Action.updateOfficeAction({ ...payload, swal_answer: value })
            );
          } else {
            Store.dispatch(Action.updateOfficeFailed({}));
          }
        });
      } else {
        yield put(
          Action.updateOfficeFailed(
            payload.ref,
            response.errors[0]?.extensions?.reason
              ? response.errors[0]?.extensions?.reason
              : "Something went wrong!"
          )
        );
      }
    }
  } catch (error) {
    yield put(
      Action.updateOfficeFailed(
        payload.ref,
        error.message ? error.message : "Something went wrong!"
      )
    );

    console.log("err", error.response);
  }
}

export function* handleUpsertAttendanceProfileSaga({ payload }) {
  let superReducer = yield select(getSuperReducer);
  const att = superReducer?.attendaceProfile;

  try {
    const response = yield call(client.upsertAttendanceProfile, payload);
    if (!response.errors) {
      const isSameConfig =
        JSON.stringify(att?.attendance_profile_work_timing_input) ===
        JSON.stringify(att.old_attendance_profile_work_timing_input);

      if (!payload?.isSetupWizard) {
        if (att?.id && !isSameConfig) {
          Swal.fire(
            HelperFns.localizeFns("success"),
            HelperFns.localizeFns(
              "As a result of updating the attendance type configuration, all requests related to upcoming dates will be deleted as well as all ongoing leaves will end at the end of the previous configuration And any leaves accepted leaves past this date will need to be resubmitted"
            ),
            "success"
          ).then(() => history.push("/settings?tab=att-profile"));
        } else {
          yield put(Action.showSuccessToast());
          history.push("/settings?tab=att-profile");
        }
      }

      yield delay(500);
      yield put(Action.upsertAttendanceProfileSuccess(response));

      if (payload?.isSetupWizard) {
        yield put(Action.upsertSetupWizardAttendanceProfileSuccess());
        yield put(
          Action.EditSetupWizardEssentialData("company_attendance_profiles")
        );
      }
    } else {
      if (
        response?.errors[0]?.extensions?.validation?.[
        "input.first_half_day_profile.save_with"
        ] ||
        response?.errors[0]?.extensions?.validation?.[
        "input.second_half_day_profile.save_with"
        ] ||
        response?.errors[0]?.extensions?.validation?.["input.save_with"]
      ) {
        Swal.fire({
          icon: "warning",
          text: HelperFns.localizeFns("recalc_swal"),
          input: "checkbox",
          inputPlaceholder: HelperFns.localizeFns("skip sign-in records"),
          inputValue: 1,
          returnInputValueOnDeny: true,
          showConfirmButton: true,
          confirmButtonText: HelperFns.localizeFns("Yes"),
          confirmButtonColor: "#7cd1f9",
          showDenyButton: true,
          denyButtonText: HelperFns.localizeFns("No"),
          showCancelButton: true,
          cancelButtonText: HelperFns.localizeFns("Cancel"),
          customClass: {
            popup: "swal-warning-style",
            // container: "swal-title",
            icon: "swal-icon",
            cancelButton: "order-1",
            denyButton: "order-2",
            confirmButton: "order-3",
            actions: "confirm-modal-actions",
          },
        }).then((values) => {
          const checkboxValue = values.value === 0 ? false : true;

          if (values.isDismissed) {
            return Store.dispatch(Action.upsertAttendanceProfileFailed({}));
          }

          if (values) {
            return Store.dispatch(
              Action.upsertAttendanceProfileAction({
                normal: {
                  ...payload.normal,
                  save_with: values.isConfirmed
                    ? "recalculate"
                    : "without_recalculate",
                  skip_work_timing_changed: checkboxValue,
                },
                firstHalf: payload.firstHalf
                  ? {
                    ...payload.firstHalf,
                    save_with: values.isConfirmed
                      ? "recalculate"
                      : "without_recalculate",
                    skip_work_timing_changed: checkboxValue,
                  }
                  : null,
                secondHalf: payload.secondHalf
                  ? {
                    ...payload.secondHalf,
                    save_with: values.isConfirmed
                      ? "recalculate"
                      : "without_recalculate",
                    skip_work_timing_changed: checkboxValue,
                  }
                  : null,
              })
            );
          }
        });

        yield put(Action.fetchSetupWizardEssentialDataAttempt());
        return;
      }

      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.upsertAttendanceProfileFailed(
            Helper.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            )
          )
        );
        yield put(Action.upsertSetupWizardAttendanceProfileFailed());

        return;
      } else {
        yield put(Action.upsertAttendanceProfileFailed({}));
        yield put(Action.upsertSetupWizardAttendanceProfileFailed());
        yield put(
          Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
        );
      }
    }
  } catch (error) {
    yield put(Action.upsertAttendanceProfileFailed({}));
    yield put(Action.showErrorToast(error?.message));
  }
}

export function* handleDeletePositionSaga({ payload }) {
  try {
    const response = yield call(client.deletePosition, payload);
    if (
      !response.errors &&
      response.data?.delete_position.status == "success"
    ) {
      yield put(Action.deletePositionSuccess());
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      yield put(
        Action.fetchAllPositionsAction({
          ref: "positionsList",
          positionsList: superReducer["positionsList"],
        })
      );
      yield put(Action.fetchSetupWizardEssentialDataAttempt());
    } else {
      yield put(Action.deletePositionFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_position?.message
            ? response.data?.delete_position?.message
              ?.replace(/(\"\,|\[\")/g, "\n \n • ")
              ?.replace(/(\"|\])/g, "")
            : response?.errors[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deletePositionFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

export function* handleDeleteHolidaysSaga({ payload }) {
  try {
    const response = yield call(client.deleteHoliday, payload);
    if (
      !response.errors &&
      response.data?.delete_holiday.status === "success"
    ) {
      yield put(Action.deleteHolidaySuccess());
      yield put(
        Action.showSuccessToast(response?.data?.delete_holiday?.message)
      );
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllHolidaysAction({
          ref: "holidaysList",
          holidaysList: superReducer["holidaysList"],
        })
      );
      return;
    }
    if (response.errors && response.data?.delete_holiday.status !== "success") {
      yield put(Action.deleteHolidayFailed());
      yield put(Action.showErrorToast(response?.data?.delete_holiday?.message));
      return;
    } else {
      yield put(Action.deleteHolidayFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteHolidayFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

export function* handleDeleteOfficeSaga({ payload }) {
  try {
    const response = yield call(client.deleteOffice, payload);
    if (
      !response?.errors &&
      response?.data?.delete_office?.status == "success"
    ) {
      yield put(Action.deleteOfficeSuccess());
      yield put(
        Action.showSuccessToast(response?.data?.delete_office?.message)
      );
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      yield put(
        Action.fetchAllOfficesAction({
          ref: "officesList",
          officesList: superReducer["officesList"],
        })
      );
      yield put(Action.fetchSetupWizardEssentialDataAttempt());
    } else {
      yield put(Action.deleteOfficeFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_office?.message ||
          response?.errors[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteOfficeFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onEditAttProfile() {
  yield takeEvery(
    Types.FETCH_ATTENDANCE_PROFILE_ATTEMPT,
    handleFetchAttProfileSaga
  );
}

function* onEditEmployee() {
  yield takeEvery(Types.EDIT_EMPLOYEE_ATTEMPT, handleEditEmployeeSaga);
}

function* onInitEmployee() {
  yield takeEvery(Types.INIT_EMPLOYEE_ATTEMPT, handleInitEmployeeSaga);
}

function* onUpdateEmployee() {
  yield takeEvery(Types.UPSERT_EMPLOYEE_ATTEMPT, handleUpdateEmployeeSaga);
}

function* onFetchAttProfilesList() {
  yield throttle(
    500,
    Types.FETCH_ATTENDANCE_PROFILES_LIST_ATTEMPT,
    handleFetchAttProfilesListSaga
  );
}

function* onCreatreAttProfile() {
  yield takeEvery(
    Types.CREATE_ATTENDANCE_PROFILE_ATTEMPT,
    handleCreateAttProfileSaga
  );
}
function* onFetchAllEmployeesList() {
  yield takeLatest(
    Types.FETCH_ALL_EMPLOYEES_ATTEMPT,
    handleFetchAllEmployeesListSaga
  );
}

function* onFetchAllOfices() {
  yield takeLatest(Types.FETCH_ALL_OFFICES_ATTEMPT, handleFetchAllOfficesSaga);
}

function* onFetchAllDepartments() {
  yield takeLatest(
    Types.FETCH_ALL_DEPARTMENTS_ATTEMPT,
    handleFetchAllDepartmentsSaga
  );
}

function* onFetchAllPositions() {
  yield takeLatest(
    Types.FETCH_ALL_POSITIONS_ATTEMPT,
    handleFetchAllPositionsSaga
  );
}

function* onFetchAllHolidays() {
  yield takeLatest(
    Types.FETCH_ALL_HOLIDAYS_ATTEMPT,
    handleFetchAllHolidaysSaga
  );
}

function* onFetchInboundRequests() {
  yield takeEvery(
    Types.FETCH_INBOUND_REQUESTS_ATTEMPT,
    handleFetchInboundRequestsSaga
  );
}
function* onAcceptInboundRequest() {
  yield takeEvery(
    Types.ACCEPT_INBOUND_REQUEST_ATTEMPT,
    handleAcceptInboundRequestSaga
  );
}
function* onRejectInboundRequest() {
  yield takeEvery(
    Types.REJECT_INBOUND_REQUEST_ATTEMPT,
    handleRejectInboundRequestSaga
  );
}

function* onFetchAttendanceLogs() {
  yield takeLatest(
    Types.FETCH_ATTENDANCE_LOGS_ATTEMPT,
    handleFetchAttendanceLogsSaga
  );
}
function* onFetchSuspensionLogs() {
  yield takeLatest(
    Types.FETCH_SUSPENSION_LOGS_ATTEMPT,
    handleFetchSuspensionLogsSaga
  );
}

function* onFetchBalanceLogs() {
  yield takeLatest(
    Types.FETCH_BALANCE_LOGS_ATTEMPT,
    handleFetchBalanceLogsSaga
  );
}

// (Start) Attendance Adjust Sign Out
function* handleAdjustSignOutSaga({ payload }) {
  try {
    const response = yield call(client.adjustSignOut, payload);

    if (response?.errors) {
      yield put(Action.handleAdjustSignOutFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ?? "Something went wrong"
        )
      );
      return;
    }

    if (response?.data) {
      yield put(Action.handleAdjustSignOutSuccess());
      yield put(Action.showSuccessToast());

      const superReducer = yield select(getSuperReducer);

      yield put(
        Action.fetchAttendanceLogsAction({
          ref: "attendanceLogsList",
          attendanceLogsList: superReducer["attendanceLogsList"],
        })
      );
      yield put(Action.adjustSignInOutIds({ operation: "clear" }));
    }
  } catch (error) {
    yield put(Action.handleAdjustSignOutFailed());

    Swal.fire({
      icon: "error",
      title: "Error",
      text: error.message ? error.message : "Something went wrong!",
      className: "swal-error-style",
      timer: 2000,
      buttons: false,
      showConfirmButton: false,
    });
  }
}

function* onHandleAdjustSignOut() {
  yield takeLatest(Types.ADJUST_SIGNOUT_ATTEMPT, handleAdjustSignOutSaga);
}

// (End) Attendance Adjust Sign Out

// (Start) Update Suspension
export function* handleUpdateSuspensionSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); //
    const response = yield call(client.updateSuspension, payload);
    if (
      !response.errors &&
      response.data?.update_suspension?.__typename == "Suspension"
    ) {
      yield put(Action.updateSuspensionSuccess());
      if (window.location.pathname.includes("employee-profile")) {
        window.location.reload();
        return;
      }
      yield put(
        Action.fetchAllEmployeesAction({
          ref: "allEmployeesList",
          allEmployeesList: superReducer["allEmployeesList"],
        })
      );
      yield put(Action.showSuccessToast());
      setTimeout(function () {
        window.location.reload(1);
      }, 2000);
    } else {
      if (
        response.data?.update_suspension?.__typename ==
        "EmployeeSuspendedException" ||
        response.data?.update_suspension?.__typename == "GeneralException"
      ) {
        yield put(
          Action.updateSuspensionFailed(
            response.data?.update_suspension?.message ?? "Something went wrong!"
          )
        );
      } else {
        yield put(
          Action.updateSuspensionFailed(
            response.errors[0]?.extensions?.reason
              ? response.errors[0]?.extensions?.reason
              : "Something went wrong!"
          )
        );
      }
    }
  } catch (error) {
    yield put(
      Action.updateSuspensionFailed(
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}

function* onUpdateSuspension() {
  yield takeEvery(Types.UPDATE_SUSPENSION_ATTEMPT, handleUpdateSuspensionSaga);
}
// (End) Update Suspension

// (Start) Delete Suspension
export function* handleDeleteSuspensionSaga({ payload }) {
  try {
    const response = yield call(client.deleteSuspension, payload);
    if (!response.errors) {
      yield put(Action.deleteSuspensionSuccess());
      yield put(
        Action.showToast({
          type: response.data?.delete_suspension.status,
          msg: response.data?.delete_suspension.message,
        })
      );
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      yield put(
        Action.fetchSuspensionLogsAction({
          ref: "suspensionLogsList",
          suspensionLogsList: superReducer.suspensionLogsList,
        })
      );
    } else {
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
      // yield put(Action.
      //   deleteSuspensionFailed(
      //     response.errors[0]?.extensions?.reason
      //       ? response.errors[0]?.extensions?.reason
      //       : "Something went wrong!"
      //   )
      // );
    }
  } catch (error) {
    yield put(Action.showErrorToast(error?.message));
    // yield put(Action.
    //   deleteSuspensionFailed(
    //     error.message ? error.message : "Something went wrong!"
    //   )
    // );
  }
}

function* onDeleteSuspension() {
  yield takeEvery(Types.DELETE_SUSPENSION_ATTEMPT, handleDeleteSuspensionSaga);
}
// (End) Delete Suspension

function* onFetchOvertimeLogs() {
  yield takeLatest(
    Types.FETCH_OVERTIME_LOGS_ATTEMPT,
    handleFetchOvertimeLogsSaga
  );
}

function* onUpdateDepartment() {
  yield takeLatest(Types.UPDATE_DEPARTMENT_ATTEMPT, handleUpdateDepartmentSaga);
}

function* onCreateDepartment() {
  yield takeLatest(Types.CREATE_DEPARTMENT_ATTEMPT, handleCreateDepartmentSaga);
}

function* onDeleteDepartment() {
  yield takeLatest(Types.DELETE_DEPARTMENT_ATTEMPT, handleDeleteDepartmentSaga);
}

function* onUpdatePosition() {
  yield takeLatest(Types.UPDATE_POSITION_ATTEMPT, handleUpdatePositionSaga);
}

function* onCreatePosition() {
  yield takeLatest(Types.CREATE_POSITION_ATTEMPT, handleCreatePositionSaga);
}

function* onDeletePosition() {
  yield takeLatest(Types.DELETE_POSITION_ATTEMPT, handleDeletePositionSaga);
}

function* onCreateHolidays() {
  yield takeLatest(Types.CREATE_HOLIDAYS_ATTEMPT, handleCreateHolidaysSaga);
}

function* onDeleteHolidays() {
  yield takeLatest(Types.DELETE_HOLIDAYS_ATTEMPT, handleDeleteHolidaysSaga);
}

function* onDeleteOffice() {
  yield takeLatest(Types.DELETE_OFFICE_ATTEMPT, handleDeleteOfficeSaga);
}

// (Start) Delete User
export function* handleDeleteUserSaga({ payload }) {
  try {
    const response = yield call(client.deleteUser, payload);
    if (!response.errors && response.data?.delete_user?.status == "success") {
      yield put(Action.deleteUserSuccess());
      yield put(
        Action.dismissConfirmDeleteManagerModalAction({
          ref: "confirmDeleteManagerModalActions",
          formName: "confirmDeleteManagerModal",
        })
      );
      yield put(Action.showSuccessToast(response?.data?.delete_user?.message));
      if (window.location.pathname.includes("employee-profile")) {
        history.push("/employees");
        return;
      }
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllEmployeesAction({
          ref: "allEmployeesList",
          allEmployeesList: superReducer["allEmployeesList"],
        })
      );
    } else {
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
      // yield put(Action.
      //   deleteUserFailed(
      //     response.errors[0]?.extensions?.reason
      //       ? response.errors[0]?.extensions?.reason
      //       : "Something went wrong!"
      //   )
      // );
    }
  } catch (error) {
    yield put(Action.showErrorToast(error?.message));
    // yield put(Action.
    //   deleteUserFailed(
    //     error.message ? error.message : "Something went wrong!"
    //   )
    // );
  }
}

function* onDeleteUser() {
  yield takeEvery(Types.DELETE_USER_ATTEMPT, handleDeleteUserSaga);
}
// (End) Delete User

export function* handleFetchInitDeleteManagerSaga({ payload }) {
  try {
    const response = yield call(client.initDeleteManager, payload);

    if (!response.errors) {
      yield put(Action.fetchInitDeleteManagerSuccess());

      if (response?.data?.user?.direct_manager_on?.length > 0) {
        Swal.fire({
          title: HelperFns.localizeFns("are you sure"),
          html:
            HelperFns.localizeFns("delete_direct_manager_swal_start_message") +
            `<br/><br/>` +
            `<div class="row">` +
            response?.data?.user?.direct_manager_on
              .map(
                (emp) =>
                  `${emp?.active
                    ? `<div class="col-6">` + "-" + emp?.name + `</div>`
                    : ""
                  }`
              )
              .join(` `)
              .toLowerCase() +
            `<br/>` +
            `</div>`,
          icon: "warning",
          className: "swal-warning-style",
          customClass: {
            // for custom style
            container: "delete_manager_swal_style",
          },
          showCancelButton: true,
          confirmButtonColor: "#ff6a6a",
          confirmButtonText: HelperFns.localizeFns("OK"),
          reverseButtons: true,
          cancelButtonText: HelperFns.localizeFns("Cancel"),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            let data = {
              id: response?.data?.user?.id,
              managedEmployees: response?.data?.user?.direct_manager_on,
              managerName: response?.data?.user?.name,
            };
            Store.dispatch(
              Action.showConfirmDeleteManagerModal({
                data,
                ref: "confirmDeleteManagerModalActions",
                formName: "confirmDeleteManagerModal",
              })
            );
          }
        });
      } else {
        Swal.fire({
          title: HelperFns.localizeFns("are you sure"),
          text: HelperFns.localizeFns("defaut_warning_messsage"),
          icon: "warning",
          className: "swal-warning-style",
          input: "password",
          inputPlaceholder: HelperFns.localizeFns("Type your password"),
          showCancelButton: true,
          confirmButtonColor: "#ff6a6a",
          confirmButtonText: HelperFns.localizeFns(
            "Confirm, delete the employee"
          ),
          reverseButtons: true,
          cancelButtonText: HelperFns.localizeFns("Cancel"),
          preConfirm: function (password) {
            if (password.length) {
              return password;
            } else {
              return false;
            }
          },
        }).then(({ isConfirmed, value }) => {
          if (isConfirmed) {
            Store.dispatch(
              Action.deleteUserAttempt(response?.data?.user?.id, value)
            );
          }
        });
      }
    } else {
      yield put(Action.fetchInitDeleteManagerFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchInitDeleteManagerFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchInitDeleteManager() {
  yield takeLatest(
    Types.FETCH_INIT_DELETE_MANAGER_ATTEMPT,
    handleFetchInitDeleteManagerSaga
  );
}

export function* handleFetchInitSuspendManagerSaga({ payload }) {
  try {
    const response = yield call(client.initSuspendManager, payload);

    if (!response.errors) {
      yield put(Action.fetchInitSuspendManagerSuccess());

      if (response?.data?.user?.direct_manager_on?.length > 0) {
        Swal.fire({
          title: HelperFns.localizeFns("are you sure"),
          html:
            HelperFns.localizeFns("delete_direct_manager_swal_start_message") +
            `<br/><br/>` +
            `<div class="row">` +
            response?.data?.user?.direct_manager_on
              .map(
                (emp) =>
                  `${emp?.active
                    ? `<div class="col-6">` + "-" + emp?.name + `</div>`
                    : ""
                  }`
              )
              .join(` `)
              .toLowerCase() +
            `<br/>` +
            `</div>`,
          icon: "warning",
          className: "swal-warning-style",
          customClass: {
            // for custom style
            container: "delete_manager_swal_style",
          },
          showCancelButton: true,
          confirmButtonColor: "#ff6a6a",
          confirmButtonText: HelperFns.localizeFns("OK"),
          reverseButtons: true,
          cancelButtonText: HelperFns.localizeFns("Cancel"),
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            // to show suspension modal
            let replace_by_field = true;
            Store.dispatch(Action.toggleSuspensionModal(replace_by_field));
            Store.dispatch(
              Action.addEmployeeIdToSuspension(response?.data?.user?.id)
            );

            // to add managed employees to suspension modal
            Store.dispatch(
              Action.addManagedEmployeesToSuspensionModal(
                response?.data?.user?.direct_manager_on
              )
            );

            // to fetch replace by manager options
            Store.dispatch(
              Action.fetchReplaceByManagersOptsAction("inSuspensionModal")
            );
          }
        });
      } else {
        // to show suspension modal and to hide replace by field
        let replace_by_field = false;
        yield put(Action.toggleSuspensionModal(replace_by_field));
        yield put(Action.addEmployeeIdToSuspension(response?.data?.user?.id));
      }
    } else {
      yield put(Action.fetchInitSuspendManagerFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchInitSuspendManagerFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchInitSuspendManager() {
  yield takeLatest(
    Types.FETCH_INIT_SUSPEND_MANAGER_ATTEMPT,
    handleFetchInitSuspendManagerSaga
  );
}

export function* handleFetchReplaceByManagersOptsSaga({ payload }) {
  try {
    const response = yield call(client.fetchReplaceByManagersOpts, payload);

    if (!response.errors) {
      yield put(
        Action.fetchReplaceByManagersOptsSuccess(response?.data?.company_users)
      );
    } else {
      yield put(Action.fetchReplaceByManagersOptsFailed());
      if (payload == "inSuspensionModal") {
        yield put(
          Action.dismissSuspensionModalActionAction({
            ref: "suspensionModalActions",
            formName: "suspensionModal",
          })
        );
      }
      if (payload == "inConfirmDeleteManagerModal") {
        yield put(
          Action.dismissConfirmDeleteManagerModalAction({
            ref: "confirmDeleteManagerModalActions",
            formName: "confirmDeleteManagerModal",
          })
        );
      }

      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchReplaceByManagersOptsFailed());
    if (payload == "inSuspensionModal") {
      yield put(
        Action.dismissSuspensionModalActionAction({
          ref: "suspensionModalActions",
          formName: "suspensionModal",
        })
      );
    }
    if (payload == "inConfirmDeleteManagerModal") {
      yield put(
        Action.dismissConfirmDeleteManagerModalAction({
          ref: "confirmDeleteManagerModalActions",
          formName: "confirmDeleteManagerModal",
        })
      );
    }
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchReplaceByManagersOpts() {
  yield takeLatest(
    Types.FETCH_REPLACE_BY_MANAGERS_OPTIONS_ATTEMPT,
    handleFetchReplaceByManagersOptsSaga
  );
}

export function* handleCheckPasswordUserSaga({ payload }) {
  try {
    const response = yield call(client.userCheckPassword, payload);
    if (
      !response.errors &&
      response.data?.check_password?.status == "success"
    ) {
      yield put(Action.checkPasswordSuccess());
      yield call(payload.cbFn()); // to call the mutation action imported with generic component
      // yield put(Action.showSuccessToast(response?.data?.check_password?.message));
    } else {
      yield put(Action.checkPasswordFailed());
      yield put(
        Action.showErrorToast(
          response.data?.check_password?.message ||
          response.errors[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.checkPasswordFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onCheckPasswordUser() {
  yield takeEvery(Types.CHECK_PASSWORD_ATTEMPT, handleCheckPasswordUserSaga);
}

// (Start) Activate User
export function* handleActivateUserSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); //
    // let authReducer = yield select(getAuthReducer); //
    const response = yield call(client.activateUser, payload);
    if (!response.errors) {
      yield put(Action.activateUserSuccess());
      if (window.location.pathname.includes("employee-profile")) {
        window.location.reload();
        return;
      }
      yield put(
        Action.fetchAllEmployeesAction({
          ref: "allEmployeesList",
          allEmployeesList: superReducer["allEmployeesList"],
        })
      );
      yield put(
        Action.showToast({
          type: response.data?.activate_user.status,
          msg: response.data?.activate_user.message,
        })
      );
    } else {
      if ("reason" in response?.errors[0]?.extensions) {
        yield put(
          Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
        );
        yield put(Action.activateUserFailed());
        yield put(Action.upsertSalaryConfigFailed({}));
        return;
      }
      yield put(Action.activateUserFailed());
      if (
        response?.errors[0]?.extensions?.validation?.[
        "input.user_salary_config_input"
        ]
      ) {
        yield put(
          Action.upsertSalaryConfigFailed(
            Helper.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation[
              "input.user_salary_config_input"
              ]
            )
          )
        );
        delete response?.errors[0]?.extensions?.validation[
          "input.user_salary_config_input"
        ];
        yield put(
          Action.onFormResetAction(
            "activationFormStepOneServerValidation",
            Helper.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            )
          )
        );
      } else {
        yield put(
          Action.onFormResetAction(
            "activationFormStepOneServerValidation",
            Helper.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            )
          )
        );
      }
    }
  } catch (error) {
    yield put(Action.showErrorToast(error?.message));
    yield put(Action.activateUserFailed());
  }
}

function* onActivateUser() {
  yield takeEvery(Types.ACTIVATE_USER_ATTEMPT, handleActivateUserSaga);
}
// (End) Activate User

function* onAcceptOvertimeRequest() {
  yield takeEvery(
    Types.ACCEPT_OVERTIME_REQUEST_ATTEMPT,
    handleAcceptOvertimeRequestSaga
  );
}
function* onUpdateOffice() {
  yield takeEvery(Types.UPDATE_OFFICE_ATTEMPT, handleUpdateOfficeSaga);
}

// (Start) EditHistory Modal
export function* handleEditHistoryModalSaga({ payload }) {
  try {
    const response = yield call(client.editHistoryModal, payload);
    if (!response.errors) {
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests

      yield put(Action.editHistoryModalSuccess());
      if (payload?.disableWindowReload) {
        yield put(
          Action.fetchAttendanceLogsAction({
            ref: "attendanceLogsList",
            attendanceLogsList: superReducer["attendanceLogsList"],
          })
        );
      } else {
        window.location.reload();
      }
      yield put(Action.showSuccessToast("edit_success"));
    } else {
      yield put(
        Action.editHistoryModalFailed(
          payload.ref,
          response.errors[0]?.extensions?.reason
            ? response.errors[0]?.extensions?.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      Action.editHistoryModalFailed(
        payload.ref,
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}

function* onEditHistoryModal() {
  yield takeEvery(Types.EDIT_ATT_LOG_ATTEMPT, handleEditHistoryModalSaga);
}
function* onDeleteAttProfile() {
  yield takeEvery(
    Types.DELETE_ATTENDANCE_PROFILE_ATTEMPT,
    handleDeleteAttProfileSaga
  );
}

function* onCloneAttProfile() {
  yield takeEvery(
    Types.CLONE_ATTENDANCE_PROFILE_ATTEMPT,
    handleCloneAttProfileSaga
  );
}

function* onUpsertAttendanceProfile() {
  yield takeEvery(
    Types.UPSERT_ATTENDANCE_PROFILE_ATTEMPT,
    handleUpsertAttendanceProfileSaga
  );
}

function* onFetchOfficeHolidays() {
  yield takeLatest(
    Types.FETCH_ALL_OFFICE_HOLIDAYS_ATTEMPT,
    handleFetchOfficeHolidaysSaga
  );
}
// (End) EditHistory Modal

// (Start) Balance Modal
export function* handleInitBalanceModalSaga({ payload }) {
  try {
    const response = yield call(client.adjustBalanceModal, payload.data);
    if (!response.errors) {
      let superReducer = yield select(getSuperReducer);

      if (
        response.data?.adjust_balance?.__typename === "GeneralException" ||
        response.data?.adjust_balance?.__typename ===
        "EmployeeNotActiveException"
      ) {
        yield put(
          Action.balanceModalFailed({
            ref: payload.ref,
            serverRef: "balanceModalServerValidation",
            errors: {},
            modalMessage:
              response.data?.adjust_balance?.message ??
              HelperFns.localizeFns("something went wrong"),
          })
        );

        return;
      }
      yield put(
        Action.balanceModalSuccess({
          ref: payload.ref,
          formName: payload.formName,
        })
      );
      yield put(Action.showSuccessToast());

      if (!payload.userId) {
        yield put(
          Action.fetchBalanceLogsAction({
            ref: "balanceLogsList",
            balanceLogsList: superReducer["balanceLogsList"],
          })
        );
      }
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.balanceModalFailed({
          ref: payload.ref,
          serverRef: "balanceModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.balanceModalFailed({
          ref: payload.ref,
          serverRef: "balanceModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            HelperFns.localizeFns("something went wrong"),
        })
      );
    }
  } catch (error) {
    yield put(
      Action.balanceModalFailed({
        ref: payload.ref,
        serverRef: "balanceModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onInitBalanceModal() {
  yield takeEvery(Types.BALANCE_MODAL_ATTEMPT, handleInitBalanceModalSaga);
}

// (End) Balance Modal

export function* handleFetchDashboardSaga({ payload }) {
  try {
    const response = yield call(client.fetchDashboard, payload);

    if ("usersStatus" in response.data) {
      yield put(
        Action.fetchDashboardWidgetsSuccess(response.data?.usersStatus)
      );
    }

    if ("users_requests" in response.data) {
      yield put(
        Action.fetchInboundRequestsSuccess(
          "inboundRequestsList",
          response.data?.users_requests
        )
      );
    }
    if ("employees" in response.data) {
      yield put(Action.updateRequestsEmployeesFilter(response.data?.employees));
    }

    if ("departments_users_chart" in response.data) {
      yield put(
        Action.fetchDepartmentsChartSuccess(
          response.data?.departments_users_chart
        )
      );
    }

    if (
      "company_profile_missing_documents" in response.data ||
      "company_profile_expired_user_documents" in response.data
    ) {
      yield put(
        Action.fetchRequiredDocumentsSuccess({
          missingDocuments: response?.data?.company_profile_missing_documents,
          expiredDocuments:
            response?.data?.company_profile_expired_user_documents,
        })
      );
    } else {
      yield put(Action.fetchRequiredDocumentsFailed());
    }
    if (response?.data?.length == 0) {
      yield put(Action.switchDashboardLoadingOff());
    }
  } catch (error) {
    yield put(Action.fetchInboundRequestsFailed(payload.ref));
    yield put(Action.fetchRequiredDocumentsFailed());
    console.log("err", { error });
  }
}

function* onFetchDashboard() {
  yield takeEvery(Types.FETCH_DASHBOARD_ATTEMPT, handleFetchDashboardSaga);
}

export function* handleFetchAttendanceChartSaga({ payload }) {
  try {
    const response = yield call(client.fetchAttendanceChart, payload);
    yield put(
      Action.fetchAttendanceChartSuccess(response.data?.attendance_chart)
    );
  } catch (error) {
    yield put(Action.fetchAttendanceChartFailed(payload.ref, error));

    console.log("err", error.response);
  }
}

function* onFetchAttendanceChart() {
  yield takeLatest(
    Types.FETCH_ATTENDANCE_CHART_ATTEMPT,
    handleFetchAttendanceChartSaga
  );
}

export function* handleFetchAttendanceChartEmployeesNameSaga({ payload }) {
  try {
    const response = yield call(
      client.fetchAttendanceChartEmployeesName,
      payload
    );
    if (!response?.errors) {
      yield put(
        Action.fetchEmployeeNameOnClickSuccess(
          response.data?.att_history_employees
        )
      );
    } else {
      yield put(Action.fetchEmployeeNameOnClickFailed());

      yield put(
        Action.dismissAttChartEmployeeNameModalAction({
          ref: "attendanceChartEmployeeNamesModalActions",
          formName: "attendanceChartEmployeeNamesModal",
        })
      );

      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchEmployeeNameOnClickFailed());

    yield put(
      Action.dismissAttChartEmployeeNameModalAction({
        ref: "attendanceChartEmployeeNamesModalActions",
        formName: "attendanceChartEmployeeNamesModal",
      })
    );
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchAttendanceChartEmployeesName() {
  yield takeLatest(
    Types.FETCH_EMPLOYEE_NAME_ON_CLICK_ATTEMPT,
    handleFetchAttendanceChartEmployeesNameSaga
  );
}

export function* handleFetchDepartmentsChartSaga({ payload }) {
  try {
    const response = yield call(client.fetchDepartmentsChart, payload);
    yield put(
      Action.fetchDepartmentsChartSuccess(
        response.data?.departments_users_chart
      )
    );
  } catch (error) {
    yield put(Action.fetchDepartmentsChartFailed(payload.ref, error));

    console.log("err", error.response);
  }
}

function* onFetchDepartmentsChart() {
  yield takeLatest(
    Types.FETCH_DEPARTMENTS_CHART_ATTEMPT,
    handleFetchDepartmentsChartSaga
  );
}

export function* handleFetchOvertimeChartSaga({ payload }) {
  try {
    const response = yield call(client.fetchOvertimeChart, payload);
    yield put(Action.fetchOvertimeChartSuccess(response.data?.overtime_chart));
  } catch (error) {
    yield put(Action.fetchOvertimeChartFailed(payload.ref, error));

    console.log("err", error.response);
  }
}

function* onFetchOvertimeChart() {
  yield takeLatest(
    Types.FETCH_OVERTIME_CHART_ATTEMPT,
    handleFetchOvertimeChartSaga
  );
}

export function* handleFetchDashboardUsersSaga({ payload }) {
  try {
    const response = yield call(client.fetchDashboardUsers, payload);
    yield put(
      Action.fetchDashboardUsersSuccess(
        payload.status,
        response.data?.users.data
      )
    );
  } catch (error) {
    yield put(Action.fetchDashboardUsersFailed(payload.ref));

    console.log("err", error.response);
  }
}

function* onFetchDashboardUsers() {
  yield takeLatest(
    Types.FETCH_DASHBOARD_USERS_ATTEMPT,
    handleFetchDashboardUsersSaga
  );
}

export function* handleFetchOfficeSaga({ payload }) {
  try {
    const response = yield call(client.fetchOffice, payload);

    if (!response?.errors) {
      yield put(Action.editOfficeSuccess(response.data));
    } else {
      yield put(Action.editOfficeFailed());
      yield put(
        Action.showErrorToast(
          response?.data?.office || response?.errors?.[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.editOfficeFailed(error));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchOffice() {
  yield takeEvery(Types.FETCH_OFFICE_ATTEMPT, handleFetchOfficeSaga);
}

export function* handleInitOfficeSaga({ payload }) {
  try {
    const response = yield call(client.initOffice, payload);
    if (!response?.errors) {
      yield put(
        Action.initOfficeActionSuccess(response?.data?.init_office?.id)
      );
    } else {
      yield put(Action.initOfficeActionFailed());
      yield put(
        Action.showErrorToast(
          response?.data?.office || response?.errors?.[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.initOfficeActionFailed(error));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onInitOffice() {
  yield takeEvery(Types.INIT_OFFICE_ATTEMPT, handleInitOfficeSaga);
}

export function* handleEditEmployeePrivilegesSaga({ payload }) {
  try {
    const response = yield call(client.editEmployeePrivileges, payload);
    if (!response?.errors) {
      yield put(
        Action.showManagePrivilagesModalAction({
          ref: "managePrivilagesModalActions",
          formName: "managePrivilagesModal",
          data: {
            system_privileges: response.data?.system_privileges,
            ...response.data?.userPrivileges,
          },
        })
      );
      yield put(
        Action.fetchPlanRolesOptsAction(
          [
            response?.data?.profile?.company?.roles ?? [],
            response?.data?.profile?.company?.currentSubscription?.plan
              ?.roles ?? [],
          ].flat(Infinity)
        )
      );
    } else {
      yield put(Action.editEmployeePrivilegesFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.editEmployeePrivilegesFailed(error));
    yield put(Action.showErrorToast(error?.message));
  }
}
function* onEditEmployeePrivileges() {
  yield takeEvery(
    Types.EDIT_EMPLOYEE_PRIVILEGES_ATTEMPT,
    handleEditEmployeePrivilegesSaga
  );
}

export function* handleUpsertEmployeePrivilegesSaga({ payload }) {
  try {
    const response = yield call(client.upsertEmployeePrivileges, payload);
    if (!response?.errors) {
      yield put(
        Action.dismissManagePrivilagesModalAction({
          ref: "managePrivilagesModalActions",
          formName: "managePrivilagesModal",
        })
      );
    } else {
      yield put(
        Action.upsertEmployeePrivilegesFailed(
          response?.errors[0]?.extensions?.validation
        )
      );
      if (!response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.showErrorToast(
            response?.errors[0]?.extensions?.reason ||
            (response?.errors[0]?.extensions?.validation
              ? Object.values(
                response?.errors[0]?.extensions?.validation
              )[0]?.toString()
              : response?.errors[0]?.message)
          )
        );
      }
    }
  } catch (error) {
    yield put(Action.upsertEmployeePrivilegesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpsertEmployeePrivileges() {
  yield takeEvery(
    Types.UPSERT_EMPLOYEE_PRIVILEGES_ATTEMPT,
    handleUpsertEmployeePrivilegesSaga
  );
}

export function* handleFetchUsersClaimsSaga({ payload }) {
  try {
    const response = yield call(client.fetchUsersClaims, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchUsersClaimsSuccess(payload.ref, response.data?.usersClaims)
      );
      yield put(
        Action.fetchExtraUsersFilterAction(response?.data?.extraUsersFilter)
      );
      if ("totalPendingClaims" in response.data) {
        yield put(
          Action.fetchTotalPendingClaims(
            response.data?.totalPendingClaims?.paginatorInfo?.total
          )
        );
      }
    } else {
      yield put(Action.fetchUsersClaimsFailed(payload.ref));
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchUsersClaimsFailed(payload.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchUsersClaims() {
  yield takeLatest(
    Types.FETCH_USERS_CLAIMS_ATTEMPT,
    handleFetchUsersClaimsSaga
  );
}

export function* handleFetchUsersBonusSaga({ payload }) {
  try {
    const response = yield call(client.fetchUsersBonus, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchUsersBonusSuccess(payload.ref, response.data?.usersBonus)
      );
      yield put(
        Action.fetchExtraUsersFilterAction(response?.data?.extraUsersFilter)
      );
      yield put(
        Action.fetchDepartmentsSuccess(
          response?.data?.company_departments?.data
        )
      );
      yield put(
        Action.fetchAllOfficesOptionsAction(
          response?.data?.company_offices?.data
        )
      );
    } else {
      yield put(Action.fetchUsersBonusFailed(payload.ref));
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchUsersBonusFailed(payload.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchUsersBonus() {
  yield takeLatest(Types.FETCH_USERS_BONUS_ATTEMPT, handleFetchUsersBonusSaga);
}

export function* handleFetchUsersDeductionsSaga({ payload }) {
  try {
    const response = yield call(client.fetchUsersDeductions, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchUsersDeductionsSuccess(
          payload.ref,
          response.data?.usersDeductions
        )
      );
      yield put(
        Action.fetchExtraUsersFilterAction(response?.data?.extraUsersFilter)
      );
      yield put(
        Action.fetchDepartmentsSuccess(
          response?.data?.company_departments?.data
        )
      );
      yield put(
        Action.fetchAllOfficesOptionsAction(
          response?.data?.company_offices?.data
        )
      );
    } else {
      yield put(Action.fetchUsersDeductionsFailed(payload.ref));
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchUsersDeductionsFailed(payload.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchUsersDeductions() {
  yield takeLatest(
    Types.FETCH_USERS_DEDUCTIONS_ATTEMPT,
    handleFetchUsersDeductionsSaga
  );
}

export function* handleUpsertBonusSaga({ payload }) {
  try {
    const response = yield call(client.upsertBonus, payload);

    if (!response?.errors) {
      let superReducer = yield select(getSuperReducer);
      if (response?.data?.upsert_bonus?.status == "success") {
        yield put(
          Action.upsertBonusSuccess({
            ref: payload.ref,
            formName: payload.formName,
          })
        );
        yield put(
          Action.fetchUsersBonusAction({
            ref: "BonusList",
            BonusList: superReducer["BonusList"],
          })
        );
        yield put(Action.showSuccessToast());
      } else {
        yield put(
          Action.upsertBonusFailed({
            ref: payload.ref,
            serverRef: "BonusFormServerValidation",
            errors: {},
          })
        );
        yield put(Action.showErrorToast(response?.data?.upsert_bonus?.message));
      }
    } else {
      if (response?.errors?.extensions?.validation) {
        yield put(
          Action.upsertBonusFailed({
            ref: payload.ref,
            serverRef: "BonusFormServerValidation",
            errors: Helper.parseValidatorErrors(
              response?.errors?.[0]?.extensions?.validation
            ),
          })
        );
      } else {
        yield put(
          Action.upsertBonusFailed({
            ref: payload.ref,
            serverRef: "BonusFormServerValidation",
            errors: {},
          })
        );
        yield put(
          Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
        );
      }
    }
  } catch (error) {
    yield put(
      Action.upsertBonusFailed({
        ref: payload.ref,
        serverRef: "BonusFormServerValidation",
        errors: {},
      })
    );
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpsertBonus() {
  yield takeLatest(Types.UPSERT_BONUS_ATTEMPT, handleUpsertBonusSaga);
}

export function* handleDeleteBonusSaga({ payload }) {
  try {
    const response = yield call(client.deleteBonus, payload);
    if (!response.errors && response.data?.delete_bonus?.status === "success") {
      yield put(Action.deleteBonusSuccess());
      yield put(Action.showSuccessToast(response.data?.delete_bonus?.message));
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchUsersBonusAction({
          ref: "BonusList",
          BonusList: superReducer["BonusList"],
        })
      );
    } else {
      yield put(Action.deleteBonusFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_bonus?.message ||
          response.errors[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteBonusFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteBonus() {
  yield takeLatest(Types.DELETE_BONUS_ATTEMPT, handleDeleteBonusSaga);
}

export function* handleUpsertDeductionSaga({ payload }) {
  try {
    const response = yield call(client.upsertDeduction, payload);

    if (!response?.errors) {
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.upsertDeductionSuccess({
          ref: payload.ref,
          formName: payload.formName,
        })
      );
      if (response?.data?.upsert_deduction?.status == "success") {
        yield put(
          Action.fetchUsersDeductionsAction({
            ref: "DeductionsList",
            DeductionsList: superReducer["DeductionsList"],
          })
        );
        yield put(Action.showSuccessToast());
      } else {
        yield put(
          Action.showErrorToast(response?.data?.upsert_deduction?.message)
        );
        yield put(
          Action.upsertDeductionFailed({
            ref: payload.ref,
            serverRef: "DeductionFormServerValidation",
            errors: {},
          })
        );
      }
    } else {
      if (response?.errors?.extensions?.validation) {
        yield put(
          Action.upsertDeductionFailed({
            ref: payload.ref,
            serverRef: "DeductionFormServerValidation",
            errors: Helper.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            ),
          })
        );
      } else {
        yield put(
          Action.showErrorToast(response?.errors[0]?.extensions?.reason)
        );
        yield put(
          Action.upsertDeductionFailed({
            ref: payload.ref,
            serverRef: "DeductionFormServerValidation",
            errors: {},
          })
        );
      }
    }
  } catch (error) {
    yield put(
      Action.upsertDeductionFailed({
        ref: payload.ref,
        serverRef: "DeductionFormServerValidation",
        errors: {},
      })
    );
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpsertDeduction() {
  yield takeLatest(Types.UPSERT_DEDUCTION_ATTEMPT, handleUpsertDeductionSaga);
}

export function* handleDeleteDeductionSaga({ payload }) {
  try {
    const response = yield call(client.deleteDeduction, payload);
    if (
      !response.errors &&
      response.data?.delete_deduction?.status === "success"
    ) {
      yield put(Action.deleteDeductionSuccess());
      yield put(
        Action.showSuccessToast(response?.data?.delete_deduction?.message)
      );
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchUsersDeductionsAction({
          ref: "DeductionsList",
          DeductionsList: superReducer["DeductionsList"],
        })
      );
    } else {
      yield put(Action.deleteDeductionFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_deduction?.message ||
          response.errors[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteDeductionFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteDeduction() {
  yield takeLatest(Types.DELETE_DEDUCTION_ATTEMPT, handleDeleteDeductionSaga);
}

export function* handleFetchPayrollReportSaga({ payload }) {
  try {
    // Fix Duplicated Requests
    if (
      payload?.officeFilter?.IsOfficeFilter && // Run At Office Filter Case Only
      !payload?.officeFilter?.isFirstRender && // Don't Run At First Render
      payload?.officeFilter?.currectCurrency != payload[payload.ref].currency_id // Cancel Office Did Update Request If Curreny Is Changed To Trigger Currency Did Update Request Only
    ) {
      return yield put(Action.stopReportListLoader(payload.ref));
    }

    const response = yield call(client.fetchPayrollReport, payload);
    if (!response?.errors) {
      if ("payrollReport" in response.data) {
        yield put(
          Action.fetchPayrollReportListSuccess(
            payload.ref,
            response.data?.payrollReport
          )
        );
      }

      if ("payrollReportEmployeesFilter" in response.data) {
        yield put(
          Action.fetchPayrollReportEmployeesFilterAction(
            response?.data?.payrollReportEmployeesFilter
          )
        );
      }

      if ("payrollReportCurrenciesFilter" in response.data) {
        yield put(
          Action.fetchPayrollReportCurrincesFilterAction(
            response?.data?.payrollReportCurrenciesFilter
          )
        );
      }

      if ("payrollReportRegulationFilter" in response.data) {
        yield put(
          Action.fetchPayrollReportRegulationFilterAction(
            response?.data?.payrollReportRegulationFilter
          )
        );
      }

      if ("Extra_fields_in_payroll" in response.data) {
        yield put(
          Action.fetchPayrollExtraFieldsSuccess(
            response?.data?.Extra_fields_in_payroll
          )
        );
      }

      if ("closed_months" in response.data) {
        yield put(
          Action.fetchPayrollClosedMonthsSuccess({
            ref: "closedPayrollMonths",
            data: response?.data?.closed_months?.closed_months,
          })
        );
      }

      if ("company_departments" in response.data) {
        yield put(
          Action.fetchAllDepartmentsByCompany({
            ref: "allDepartmentsByCompany",
            data: response?.data?.company_departments.data,
          })
        );
      }
    } else {
      yield put(Action.fetchPayrollReportListFailed(payload.ref));
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchPayrollReportListFailed(payload.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchPayrollReport() {
  yield takeLatest(
    Types.FETCH_PAYROLL_REPORT_LIST_ATTEMPT,
    handleFetchPayrollReportSaga
  );
}

export function* handleFetchMonthlyReportSaga({ payload }) {
  try {
    const response = yield call(client.fetchMonthlyReport, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchMonthlyReportListSuccess(
          payload,
          response.data?.monthlyReport
        )
      );
      yield put(
        Action.fetchExtraUsersFilterAction(response?.data?.extraUsersFilter)
      );
      yield put(
        Action.fetchAllDepartmentsByCompany({
          ref: "allDepartmentsByCompany",
          data: response?.data?.company_departments?.data,
        })
      );
      yield put(
        Action.setAllPositionOptionsAction(
          response?.data?.company_positions?.data
        )
      );
    } else {
      yield put(Action.fetchMonthlyReportListFailed(payload.ref));
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    console.log("err", { error });
    yield put(Action.fetchMonthlyReportListFailed(payload.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchMonthlyReport() {
  yield takeLatest(
    Types.FETCH_MONTHLY_REPORT_LIST_ATTEMPT,
    handleFetchMonthlyReportSaga
  );
}

export function* handleFetchYearlyReportSaga({ payload }) {
  try {
    const response = yield call(client.fetchYearlyReport, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchYearlyReportListSuccess(
          payload,
          response.data?.yearlyReport
        )
      );
      yield put(
        Action.fetchExtraUsersFilterAction(response?.data?.extraUsersFilter)
      );
      yield put(
        Action.fetchAllDepartmentsByCompany({
          ref: "allDepartmentsByCompany",
          data: response?.data?.company_departments?.data,
        })
      );
    } else {
      yield put(Action.fetchYearlyReportListFailed(payload.ref));
      yield put(
        Action.showErrorToast(
          response?.errors[0]?.extensions?.reason ||
          (response?.errors?.[0]?.extensions?.validation
            ? Object.values(
              response?.errors?.[0]?.extensions?.validation
            )[0]?.toString()
            : response?.errors?.[0]?.message)
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchYearlyReportListFailed(payload.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchYearlyReport() {
  yield takeLatest(
    Types.FETCH_YEARLY_REPORT_LIST_ATTEMPT,
    handleFetchYearlyReportSaga
  );
}

export function* exportYearlyReportSaga({ payload }) {
  try {
    const response = yield call(client.exportYearlyReport, payload);
    if (!response?.errors) {
      if (response?.data?.export_yearly_report?.status === "success") {
        yield put(
          Action.hideExportYearlyReportModal({
            ref: "exportYearlyReportModal",
          })
        );
        yield put(Action.exportYearlyReportSuccess(payload?.ref));
        HelperFns.downloadFile(response.data?.export_yearly_report?.file);
        yield put(
          Action.showSuccessToast(response?.data?.export_yearly_report?.message)
        );
      } else {
        yield put(Action.exportYearlyReportFailed(payload?.ref));
      }
    } else {
      yield put(Action.exportYearlyReportFailed(payload?.ref));
      yield put(
        Action.showErrorToast(
          response?.errors[0]?.extensions?.reason ||
          (response?.errors?.[0]?.extensions?.validation
            ? Object.values(
              response?.errors?.[0]?.extensions?.validation
            )[0]?.toString()
            : response?.errors?.[0]?.message)
        )
      );
    }
  } catch (error) {
    yield put(Action.exportYearlyReportFailed(payload?.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onExportYearlyReport() {
  yield takeEvery(Types.EXPORT_YEARLY_REPORT_ATTEMPT, exportYearlyReportSaga);
}

export function* handleFetchUsersSalaryConfigSaga({ payload }) {
  try {
    const refEmployeeSalaryConfig = yield call(
      client.fetchUsersSalaryConfig,
      payload
    );
    yield put(
      Action.fetchEmployeeSalaryConfigSuccess(refEmployeeSalaryConfig.data.user)
    );
  } catch (error) {
    yield put(Action.fetchEmployeeSalaryConfigFailed());
    console.log("err", error);
  }
}

function* onFetchUsersSalaryConfig() {
  yield takeEvery(
    Types.FETCH_EMPLOYEE_SALARY_CONFIG_ATTEMPT,
    handleFetchUsersSalaryConfigSaga
  );
}

export function* handleUpsertEmployeeClaimsRequestSaga({ payload }) {
  try {
    const response = yield call(client.upsertEmployeeClaimRequest, payload);

    if (!response?.errors) {
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.upsertEmployeeClaimsRequestSuccess({
          ref: payload.ref,
          formName: payload.formName,
        })
      );
      if ("id" in response?.data?.upsert_claim) {
        yield put(
          Action.fetchUsersClaimsAction({
            ref: "claimsList",
            claimsList: superReducer["claimsList"],
          })
        );
      }
      yield put(Action.showSuccessToast());
    }

    if (response?.errors) {
      yield put(
        Action.upsertEmployeeClaimsRequestFailed({
          ref: payload.ref,
          serverRef: "employeeClaimRequestFormServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
      // yield put(Action.showErrorToast(response?.errors?.[0]?.extensions?.reason));
    }
  } catch (error) {
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpsertEmployeeClaimsRequest() {
  yield takeLatest(
    Types.UPSERT_EMPLOYEE_CLAIM_REQUEST_ATTEMPT,
    handleUpsertEmployeeClaimsRequestSaga
  );
}

export function* handleClaimActionsRequestSaga({ payload }) {
  try {
    const response = yield call(client.mutateClaimRqeuestAction, payload);
    if (!response.errors) {
      yield put(Action.claimRequestActionSuccess(payload.ref, response.data));
      yield put(Action.showSuccessToast());
      if (window.location.pathname.includes("employee-profile")) {
        window.location.reload();
      } else {
        let superReducer = yield select(getSuperReducer);
        yield put(
          Action.fetchUsersClaimsAction({
            ref: "claimsList",
            claimsList: superReducer["claimsList"],
          })
        );
        yield put(
          Action.dismissAcceptClaimRequestModalAction({
            ref: "acceptClaimRequestModalActions",
            formName: "acceptClaimRequestModal",
          })
        );
      }
    }
    if (response?.errors) {
      yield put(
        Action.claimRequestActionFailed({
          ref: payload.ref,
          serverRef: "acceptClaimRequestModalFormServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
      // yield put(Action.showErrorToast(response?.errors?.[0]?.extensions?.reason || response?.errors?.[0]?.message));
    }
  } catch (error) {
    yield put(
      Action.claimRequestActionFailed({
        ref: payload.ref,
        serverRef: "acceptClaimRequestModalFormServerValidation",
        errors: {},
      })
    );
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onRejectClaimRequest() {
  yield takeEvery(
    Types.REJECT_CLAIM_REQUEST_ATTEMPT,
    handleClaimActionsRequestSaga
  );
}

function* onAcceptClaimRequest() {
  yield takeEvery(
    Types.ACCEPT_CLAIM_REQUEST_ATTEMPT,
    handleClaimActionsRequestSaga
  );
}
export function* fetchDeductionCalculatedAmountSaga({ payload }) {
  const deductibles = [
    "",
    "userSocialHistory",
    "userMedicalHistory",
    "userTaxesHistory",
  ];
  try {
    const response = yield call(client.fetchDeductCalculation, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchAmountModalSuccess({
          ref: payload?.ref,
          formName: payload?.formName,
          data: {
            amount:
              response?.data?.payroll?.[deductibles[+payload?.deductible_id]]
                ?.original_amount,
          },
        })
      );
    } else {
      yield put(
        Action.editPayrollDeductionsFailed({
          ref: payload?.ref,
          message: response.errors[0]?.extensions?.reason
            ? response.errors[0]?.extensions?.reason
            : response.errors[0]?.message ?? "Something went wrong!",
        })
      );
    }
  } catch (error) {
    yield put(
      Action.editPayrollDeductionsFailed({
        ref: payload?.ref,
        message: error.message ?? "Something went wrong!",
      })
    );
    // yield put(Action.showErrorToast(error?.message));
  }
}

export function* handleFetchUsersPayslipSaga({ payload }) {
  try {
    const response = yield call(client.fetchUserPayslip, payload);
    yield put(
      Action.fetchUserPaySlipActionSuccess(response?.data?.usersPaySlip)
    );
  } catch (error) {
    yield put(Action.fetchUserPaySlipActionFailed());
    console.log("err", error);
  }
}

function* onFetchDeductionCalculatedAmountSaga() {
  yield takeEvery(
    Types.FETCH_DEDUCTION_AMOUNT_ATTEMPT,
    fetchDeductionCalculatedAmountSaga
  );
}

export function* handleExportPaySlipSaga({ payload }) {
  try {
    const response = yield call(client.submitExportPayslip, payload);

    if (!response?.errors) {
      if (response?.data?.export_payslip_report?.status === "success") {
        yield put(Action.exportPayslipSuccess());
        yield put(
          Action.showToast({
            type: response.data?.export_payslip_report.status,
            msg: response.data?.export_payslip_report.message,
          })
        );
        HelperFns.downloadFile(response.data?.export_payslip_report?.file);
      }
    } else {
      yield put(Action.exportPayslipFailed());
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.exportPayslipFailed());
    yield put(Action.showErrorToast());
  }
}

function* onExportPayslipSaga() {
  yield takeEvery(Types.EXPORT_PAYSLIP_ACTION, handleExportPaySlipSaga);
}

export function* handleEditPayrollDeductionsSaga({ payload }) {
  try {
    const response = yield call(client.editPayrollDeductions, payload);
    if (!response?.errors) {
      const responseStatus = response?.data?.edit_payroll_view_record?.status;
      yield put(
        Action.editPayrollDeductionsSuccess({
          ref: payload?.ref,
        })
      );
      yield put(
        Action.showToast({
          type: response.data?.edit_payroll_view_record.status,
          msg: response?.data?.edit_payroll_view_record?.message,
        })
      );

      if (responseStatus === "success") {
        // Dismiss The Modal
        yield put(
          Action.dismissEditAmountModalAction({
            ref: payload?.ref,
            formName: payload?.formName,
          })
        );
        // Refresh Payroll List
        yield put(
          Action.fetchPayrollReportListAction(payload?.fetchPayrollReportData)
        );
      }
    } else {
      yield put(
        Action.editPayrollDeductionsFailed({
          ref: payload?.ref,
          message: response.errors[0]?.extensions?.reason
            ? response.errors[0]?.extensions?.reason
            : response.errors[0]?.message ?? "Something went wrong!",
        })
      );
    }
  } catch (error) {
    yield put(
      Action.editPayrollDeductionsFailed({
        ref: payload?.ref,
        message: error.message ?? "Something went wrong!",
      })
    );
    // yield put(Action.showErrorToast(error?.message));
  }
}

function* oneditPayrollDeductions() {
  yield takeEvery(
    Types.EDIT_PAYROLL_DEDUCTIONS_ATTEMPT,
    handleEditPayrollDeductionsSaga
  );
}

export function* updatePayrollPaymentSaga({ payload }) {
  try {
    const response = yield call(client.updatePayrollPayment, payload);
    const message = response?.data?.update_payroll?.message;

    // (Start) Handle Html Message
    const messageToDisplay = JSON.parse(message)
      .map((errorMsg) => `<li>${errorMsg}</li>`)
      .join("");
    let nodeMessage = document.createElement("div");
    nodeMessage.className = "list-messages-style";
    nodeMessage.innerHTML = messageToDisplay;
    // (End) Handle Html Message

    const isSuccess = message?.length <= 2;

    if (response?.data?.update_payroll?.status?.toLowerCase() === "confirm") {
      swal({
        title: HelperFns.localizeFns(
          `Unable to ${payload?.status_id === 3 ? "Submit" : "Revert"} Payment`
        ),
        content: nodeMessage,
        icon: "warning",
        className: "swal-warning-style",
        dangerMode: true,
        buttons: [
          HelperFns.localizeFns("Cancel"),
          HelperFns.localizeFns("Continue"),
        ],
      }).then((confirm) => {
        if (confirm) {
          Store.dispatch(
            Action.submitPayrollPaymentAction({ ...payload, confirm: 1 })
          );
        } else {
          if (payload?.status_id === 3) {
            // Submit Case
            Store.dispatch(Action.submitPaymentSuccess({ ref: payload?.ref }));
          } else {
            // Reverse Case
            Store.dispatch(
              Action.reversePaymentSuccess({
                ref: payload?.ref,
                id: payload?.ids[0],
              })
            );
          }
        }
      });
      // ReFetch Payroll Data
      yield put(
        Action.fetchPayrollReportListAction(payload?.fetchPayrollReportData)
      );
      return;
    }

    if (payload?.status_id === 3) {
      // Submit Case
      yield put(Action.submitPaymentSuccess({ ref: payload?.ref }));
    } else {
      // Reverse Case
      yield put(
        Action.reversePaymentSuccess({
          ref: payload?.ref,
          id: payload?.ids[0],
        })
      );
    }
    yield put(
      Action.showToast({
        type: isSuccess ? "success" : "error",
        msg: isSuccess
          ? "success"
          : `Unable to ${payload?.status_id === 3 ? "Submit" : "Revert"
          } Payment`,
      })
    );

    // ReFetch Payroll Data
    yield put(
      Action.fetchPayrollReportListAction(payload?.fetchPayrollReportData)
    );
  } catch (error) {
    if (payload?.status_id === 3) {
      // Submit Case
      yield put(Action.SubmitPaymentFailed({ ref: payload?.ref }));
    } else {
      // Reverse Case
      yield put(
        Action.reversePaymentFailed({ ref: payload?.ref, id: payload?.ids[0] })
      );
    }
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onSubmitPayrollPaymentSaga() {
  yield takeEvery(
    Types.SUBMIT_PAYROLL_PAYMENT_ATTEMPT,
    updatePayrollPaymentSaga
  );
}

function* onReversePayrollPaymentSaga() {
  yield takeEvery(
    Types.REVERSE_PAYROLL_PAYMENT_ATTEMPT,
    updatePayrollPaymentSaga
  );
}

function* onFetchUsersPayslip() {
  yield takeEvery(
    Types.FETCH_USER_PAYSLIP_ATTEMPT,
    handleFetchUsersPayslipSaga
  );
}

// FetchExportPayrollOpts
export function* handleExportPayrollOptsSaga() {
  try {
    const response = yield call(client.fetchExportPayrollOpts);
    if (!response?.errors) {
      yield put(Action.fetchExportPayrollOptionsSuccess(response?.data));
    } else {
      yield put(Action.fetchExportPayrollOptionsFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.fetchExportPayrollOptionsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchExportPayrollOpts() {
  yield takeEvery(
    Types.FETCH_EXPORT_PAYROLL_OPTIONS_ATTEMPT,
    handleExportPayrollOptsSaga
  );
}

// SUBMIT ExportPayrollOpts
export function* handleSubmitExportPayrollSaga({ payload }) {
  try {
    const response = yield call(client.submitExportPayroll, payload);
    if (!response?.errors) {
      yield put(
        Action.showToast({
          type: response.data?.exportPayroll.status,
          msg: response.data?.exportPayroll.message,
        })
      );

      if (response?.data?.exportPayroll?.status === "success") {
        yield put(Action.submitExportPayrollSuccess(response?.data));
        HelperFns.downloadFile(response.data.exportPayroll.file);
      } else {
        yield put(Action.submitExportPayrollFailed());
      }
    } else {
      yield put(Action.submitExportPayrollFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.submitExportPayrollFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onSubmitExportPayroll() {
  yield takeEvery(
    Types.SUBMIT_EXPORT_PAYROLL_ATTEMPT,
    handleSubmitExportPayrollSaga
  );
}

// handle Fetch work timings list

export function* handleFetchWorkTimingsListSaga({ payload }) {
  try {
    const response = yield call(client.fetchWorkTimings, payload);
    yield put(
      Action.fetchWorkTimingsListSuccess(
        payload.ref,
        response.data?.work_timings
      )
    );
    yield put(
      Action.updateTotalFeatureCount(
        payload.ref,
        response.data?.total?.paginatorInfo?.total
      )
    );
  } catch (error) {
    yield put(Action.fetchWorkTimingsListFailed(payload.ref));
    console.log("err", { error });
  }
}

function* onFetchWorkTimingsList() {
  yield takeLatest(
    Types.FETCH_WORK_TIMINGS_LIST_ATTEMPT,
    handleFetchWorkTimingsListSaga
  );
}

export function* handleEditFetchWorkTimeSaga({ payload }) {
  try {
    const response = yield call(client.editFetchWorkTime, payload);
    yield put(
      Action.editFetchWorkTimeSuccess(
        HelperFns.serializeFetchedWorkTime(response.data?.work_time)
      )
    );
  } catch (error) {
    yield put(Action.editFetchWorkTimeFailed());
    console.log("err", { error });
  }
}

function* onEditFetchWorkTime() {
  yield takeLatest(
    Types.EDIT_FETCH_WORK_TIMINGS_ATTEMPT,
    handleEditFetchWorkTimeSaga
  );
}

export function* handleViewFetchWorkTimeSaga({ payload }) {
  try {
    const response = yield call(client.editFetchWorkTime, payload);
    yield put(
      Action.viewWorkTimeSuccess(
        HelperFns.serializeFetchedWorkTime(response.data?.work_time)
      )
    );
  } catch (error) {
    yield put(Action.viewWorkTimeFailed());
    console.log("err", { error });
  }
}

function* onViewFetchWorkTime() {
  yield takeLatest(Types.VIEW_WORK_TIME_ATTEMPT, handleViewFetchWorkTimeSaga);
}

// (Start) Export Monthly Report
export function* exportMonthlyReportsaga({ payload }) {
  try {
    const response = yield call(client.exportMonthlyReport, payload);
    if (!response?.errors) {
      yield put(
        Action.showToast({
          type: response.data?.export_monthly_report.status,
          msg: response.data?.export_monthly_report.message,
        })
      );

      if (response?.data?.export_monthly_report?.status == "success") {
        yield put(
          Action.hideExportMonthlyReportModal({
            ref: "exportMonthlyReportModal",
          })
        );
        yield put(Action.exportMonthlyReportSuccess(payload?.ref));
        HelperFns.downloadFile(response.data?.export_monthly_report?.file);
      } else {
        yield put(Action.exportMonthlyReportFailed(payload?.ref));
      }
    } else {
      yield put(Action.exportMonthlyReportFailed(payload?.ref));
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.exportMonthlyReportFailed(payload?.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

export function* handleUpsertWorkTimingsSaga({ payload }) {
  try {
    let superReducer = yield select(getSuperReducer); // <-- get the inbound requests

    const response = yield call(client.upsertWorkTimings, payload);
    if (!response.errors) {
      yield put(
        Action.upsertWorkTimingsSuccess(
          response?.data?.upsert_work_timing,
          payload.parentFormName,
          payload.workTimeIndex
        )
      );
      if (!payload?.parentFormName) {
        yield put(
          Action.fetchWorkTimingsListAction({
            ref: "workTimingsList",
            workTimingsList: superReducer.workTimingsList,
          })
        );
        yield put(Action.fetchSetupWizardEssentialDataAttempt());
      }
    } else {
      if ("reason" in response?.errors[0]?.extensions) {
        yield put(
          Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
        );
        yield put(Action.upsertWorkTimingsFailed({}));
        return;
      }
      yield put(
        Action.upsertWorkTimingsFailed(
          Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          )
        )
      );
    }
  } catch (error) {
    yield put(Action.upsertWorkTimingsFailed({}));
    console.log("err", error);
  }
}
// fetch Activation Attendance history
export function* handleFetchActivationAttendanceSaga({ payload }) {
  try {
    const response = yield call(client.fetchActivationAttendance, payload);
    if (
      !response?.errors &&
      response.data?.init_activate?.status !== "failed"
    ) {
      // yield put(Action.showToast({
      //   type: response.data?.exportPayroll.status,
      //   msg: response.data?.exportPayroll.message,
      // }));

      yield put(
        Action.fetchActivationAttendanceSuccess(response?.data?.init_activate)
      );
    } else {
      if (response.data?.init_activate?.status == "failed") {
        yield put(Action.fetchActivationAttendanceFailed());
        yield put(
          Action.showErrorToast(
            response?.data?.init_activate?.message ||
            response?.errors?.[0]?.message
          )
        );
      } else {
        yield put(Action.fetchActivationAttendanceFailed());
        yield put(
          Action.showErrorToast(
            response?.errors?.[0]?.extensions?.reason ||
            response?.errors?.[0]?.message
          )
        );
      }
    }
  } catch (error) {
    yield put(Action.fetchActivationAttendanceFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpsertWorkTimings() {
  yield takeEvery(
    Types.UPSERT_WORK_TIMINGS_ATTEMPT,
    handleUpsertWorkTimingsSaga
  );
}

function* onExportMonthlyReportsaga() {
  yield takeEvery(Types.EXPORT_MONTHLY_REPORT_ATTEMPT, exportMonthlyReportsaga);
}

// (End) Export Monthly Report

function* onFetchActivationAttendance() {
  yield takeEvery(
    Types.FETCH_ACTIVATION_ATTENDANCE_ATTEMPT,
    handleFetchActivationAttendanceSaga
  );
}

// clone - delete Worktime

export function* handleCloneWorkTimeSaga({ payload }) {
  try {
    const response = yield call(client.cloneWorkTime, payload.id);
    if (!response.errors) {
      if (payload.ref === "modal")
        yield put(
          Action.viewWorkTimeAction(response.data.clone_work_timing.id)
        );
      yield put(Action.cloneWorkTimeSuccess());
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
      yield put(
        Action.fetchWorkTimingsListAction({
          ref: "workTimingsList",
          workTimingsList: superReducer.workTimingsList,
        })
      );
    } else {
      yield put(Action.cloneWorkTimeFailed());
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.showErrorToast(
            Object.values(
              response?.errors[0]?.extensions?.validation
            )?.toString()
          )
        );
        return;
      }
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.cloneWorkTimeFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onCloneWorkTime() {
  yield takeEvery(Types.CLONE_WORK_TIME_ATTEMPT, handleCloneWorkTimeSaga);
}

export function* handleDeleteWorkTimeSaga({ payload }) {
  try {
    const response = yield call(client.deleteWorkTime, payload);
    if (!response.errors) {
      if (response?.data?.delete_work_timing?.status !== "error") {
        yield put(Action.deleteWorkTimeSuccess());
        yield put(Action.showSuccessToast());
        let superReducer = yield select(getSuperReducer); // <-- get the inbound requests
        yield put(
          Action.fetchWorkTimingsListAction({
            ref: "workTimingsList",
            workTimingsList: superReducer.workTimingsList,
          })
        );
        yield put(Action.fetchSetupWizardEssentialDataAttempt());
      } else {
        yield put(Action.deleteWorkTimeFailed());
        yield put(
          Action.showErrorToast(response.data?.delete_work_timing?.message)
        );
      }
    } else {
      yield put(Action.deleteWorkTimeFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.deleteWorkTimeFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteWorkTime() {
  yield takeEvery(Types.DELETE_WORK_TIME_ATTEMPT, handleDeleteWorkTimeSaga);
}

export function* handleFetchEmailNotfifcationsSettingseSaga({ payload }) {
  try {
    const response = yield call(
      client.fetchEmailNotificationsSettings,
      payload
    );
    yield put(
      Action.fetchEmailNotificationsSuccess(
        response.data?.my_notifications_settings
      )
    );
    if ("errors" in response) {
      yield put(Action.fetchEmailNotificationsFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
      return;
    }
  } catch (error) {
    yield put(Action.fetchEmailNotificationsFailed());

    console.log("err", error);
  }
}

function* onFetchEmailNotificationsSettings() {
  yield takeEvery(
    Types.FETCH_EMAIL_NOTFIFCATIONS_ATTEMPT,
    handleFetchEmailNotfifcationsSettingseSaga
  );
}

export function* handleUpdateEmailNotifcationsSettingsSaga({ payload }) {
  try {
    const response = yield call(
      client.updateEmailNotifcationsSettings,
      payload
    );
    if (
      !response.errors &&
      response.data?.upsert_notification_settings?.status == "success"
    ) {
      yield put(Action.updateEmailNotificationsSuccess(payload));
      yield put(Action.fetchEmailNotificationsAction());
    } else {
      yield put(Action.updateEmailNotificationsFailed(payload));
      yield put(
        Action.showErrorToast(
          response.data?.upsert_notification_settings?.message ||
          response.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.updateEmailNotificationsFailed(payload));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpdateEmailNotificationsSettings() {
  yield takeEvery(
    Types.UPDATE_EMAIL_NOTIFICATIONS_ATTEMPT,
    handleUpdateEmailNotifcationsSettingsSaga
  );
}

export function* handleFetchAllCompaniesSaga({ payload }) {
  try {
    const response = yield call(client.fetchAllCompanies, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchAllCompaniesSuccess(
          payload.ref,
          response.data?.companies ?? response.data?.saas_companies
        )
      );
      yield put(
        Action.fetchCompanyAccountManagersAction(
          response?.data?.active_saas_users
        )
      );
      if (response?.data?.plans?.data) {
        yield put(
          Action.fetchSubscriptionPlansAction(response?.data?.plans?.data)
        );
      }
      yield put(Action.fetchCompanyCurrencyAction(response?.data?.currencies));
    } else {
      yield put(Action.fetchAllCompaniesFailed(payload.ref));
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchAllCompaniesFailed(payload.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchAllCompanies() {
  yield takeLatest(
    Types.FETCH_ALL_COMPANIES_ATTEMPT,
    handleFetchAllCompaniesSaga
  );
}

export function* handleDeleteCompaniesSaga({ payload }) {
  try {
    const response = yield call(client.deleteCompanies, payload);
    if (!response.errors) {
      yield put(Action.deleteCompaniesSuccess());
      yield put(Action.showSuccessToast());
      if (window.location.pathname.includes("admin-panel/company-profile/")) {
        setTimeout(function () {
          window.location.href = "/admin-panel?tab=companies";
        }, 1000);
        return;
      }
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllCompaniesAction({
          ref: "companiesList",
          companiesList: superReducer["companiesList"],
        })
      );
    } else {
      yield put(Action.deleteCompaniesFailed());
      yield put(
        Action.showErrorToast(
          response?.errors[0]?.extensions?.reason ||
          (response?.errors?.[0]?.extensions?.validation
            ? Object.values(
              response?.errors?.[0]?.extensions?.validation
            )[0]?.toString()
            : response?.errors?.[0]?.message)
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteCompaniesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteCompanies() {
  yield takeLatest(Types.DELETE_COMPANIES_ATTEMPT, handleDeleteCompaniesSaga);
}

export function* handleActivateCompaniesSaga({ payload }) {
  try {
    const response = yield call(client.activateCompanies, payload);
    if (!response.errors) {
      yield put(Action.activateCompaniesSuccess());
      yield put(Action.showSuccessToast());
      if (window.location.pathname.includes("admin-panel/company-profile/")) {
        setTimeout(function () {
          window.location.reload();
        }, 1000);
        return;
      }
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllCompaniesAction({
          ref: "companiesList",
          companiesList: superReducer["companiesList"],
        })
      );
    } else {
      yield put(Action.activateCompaniesFailed());
    }
  } catch (error) {
    yield put(Action.activateCompaniesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onActiviateCompanies() {
  yield takeEvery(
    Types.ACTIVATE_COMPANIES_ATTEMPT,
    handleActivateCompaniesSaga
  );
}

export function* handleSuspendCompaniesSaga({ payload }) {
  try {
    const response = yield call(client.suspendCompanies, payload);
    if (!response.errors) {
      yield put(Action.suspendCompaniesSuccess());
      yield put(Action.showSuccessToast());
      if (window.location.pathname.includes("admin-panel/company-profile/")) {
        setTimeout(function () {
          window.location.reload();
        }, 1000);
        return;
      }
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllCompaniesAction({
          ref: "companiesList",
          companiesList: superReducer["companiesList"],
        })
      );
    } else {
      yield put(Action.suspendCompaniesFailed());
      yield put(
        Action.showErrorToast(
          response?.errors[0]?.extensions?.reason ||
          (response?.errors?.[0]?.extensions?.validation
            ? Object.values(
              response?.errors?.[0]?.extensions?.validation
            )[0]?.toString()
            : response?.errors?.[0]?.message)
        )
      );
    }
  } catch (error) {
    yield put(Action.suspendCompaniesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onSuspendCompanies() {
  yield takeEvery(Types.SUSPEND_COMPANIES_ATTEMPT, handleSuspendCompaniesSaga);
}

export function* handleUpsertCompanySaga({ payload }) {
  try {
    const response = yield call(client.upsertCompany, payload.data);
    if (!response.errors) {
      yield put(Action.upsertCompaniesSuccess(payload.ref));
      yield put(
        Action.dismissCompanyModalAction({
          ref: payload.ref,
          formName: "companiesModal",
        })
      );
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllCompaniesAction({
          ref: "companiesList",
          companiesList: superReducer["companiesList"],
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.upsertCompaniesFailed({
          ref: payload.ref,
          serverRef: "companiesModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.upsertCompaniesFailed({
          ref: payload.ref,
          serverRef: "companiesModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            response?.errors[0]?.message,
        })
      );
    }
  } catch (error) {
    yield put(
      Action.upsertCompaniesFailed({
        ref: payload.ref,
        serverRef: "companiesModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpsertCompany() {
  yield takeLatest(Types.UPSERT_COMPANY_ATTEMPT, handleUpsertCompanySaga);
}

export function* handleUpdateCompanySaga({ payload }) {
  try {
    const response = yield call(client.updateCompany, payload.data);
    if (!response.errors) {
      yield put(Action.updateCompaniesSuccess(payload.ref));
      yield put(
        Action.dismissCompanyModalAction({
          ref: payload.ref,
          formName: "companiesModal",
        })
      );
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllCompaniesAction({
          ref: "companiesList",
          companiesList: superReducer["companiesList"],
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.updateCompaniesFailed({
          ref: payload.ref,
          serverRef: "companiesModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.updateCompaniesFailed({
          ref: payload.ref,
          serverRef: "companiesModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            response?.errors[0]?.message,
        })
      );
    }
  } catch (error) {
    yield put(
      Action.updateCompaniesFailed({
        ref: payload.ref,
        serverRef: "companiesModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpdateCompany() {
  yield takeLatest(Types.UPDATE_COMPANY_ATTEMPT, handleUpdateCompanySaga);
}

export function* handleFetchCompanyProfileSaga({ payload }) {
  try {
    const refCompanyProfile = yield call(client.fetchCompanyProfile, payload);
    yield put(
      Action.fetchCompanyProfileSuccess(refCompanyProfile.data?.company)
    );
    yield put(
      Action.fetchCompanyAccountManagersAction(
        refCompanyProfile?.data?.active_saas_users
      )
    );
    yield put(
      Action.fetchSubscriptionPlansAction(refCompanyProfile?.data?.plans)
    );
    yield put(
      Action.fetchCompanyCurrencyAction(refCompanyProfile?.data?.currencies)
    );
    if ("subscriptions" in refCompanyProfile.data) {
      yield put(
        Action.fetchCompanyProfileSubscriptionsSuccess(
          "companyProfileSubscriptionList",
          refCompanyProfile?.data?.subscriptions
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchCompanyProfileFailed());
    yield put(
      Action.fetchCompanyProfileSubscriptionsFailed(payload.ref, error)
    );

    console.log("err", error);
  }
}

function* onFetchCompanyProfile() {
  yield takeEvery(
    Types.FETCH_COMPANY_PROFILE_ATTEMPT,
    handleFetchCompanyProfileSaga
  );
}

export function* handleFetchCompanyProfileSubscriptionsSaga({ payload }) {
  try {
    const response = yield call(
      client.fetchCompanyProfileSubscription,
      payload
    );
    yield put(
      Action.fetchCompanyProfileSubscriptionsSuccess(
        payload.ref,
        response.data?.subscriptions
      )
    );
  } catch (error) {
    yield put(
      Action.fetchCompanyProfileSubscriptionsFailed(payload.ref, error)
    );

    console.log("err", error.response);
  }
}

function* onFetchCompanyProfileSubscriptions() {
  yield takeLatest(
    Types.FETCH_COMPANY_PROFILE_SUPSCRIPTIONS_ATTEMPT,
    handleFetchCompanyProfileSubscriptionsSaga
  );
}

export function* handleUpdateCompanyNameSaga({ payload }) {
  try {
    const response = yield call(client.updateCompanyName, payload.data);
    if (!response.errors) {
      yield put(Action.updateCompanyNameActionSuccess(payload.ref));
      yield put(
        Action.dismissEditCompanyNameModalAction({
          ref: payload.ref,
          formName: "editCompanyNameModal",
        })
      );
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchCompanyProfileAction({
          id: payload?.data?.id,
          companyProfileSubscriptionList:
            superReducer["companyProfileSubscriptionList"],
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.updateCompanyNameActionFailed({
          ref: payload.ref,
          serverRef: "editCompanyNameModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.updateCompanyNameActionFailed({
          ref: payload.ref,
          serverRef: "editCompanyNameModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            response?.errors[0]?.message,
        })
      );
    }
  } catch (error) {
    yield put(
      Action.updateCompanyNameActionFailed({
        ref: payload.ref,
        serverRef: "editCompanyNameModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpdateCompanyName() {
  yield takeLatest(
    Types.UPDATE_COMPANY_NAME_MODAL_ATTEMPT,
    handleUpdateCompanyNameSaga
  );
}

export function* handleUpdateAccountManagersSaga({ payload }) {
  try {
    const response = yield call(
      client.updateCompanyAccountManagers,
      payload.data
    );
    if (!response.errors) {
      yield put(Action.updateCompanyAccountManagersSuccess(payload.ref));
      yield put(
        Action.dismissEditAccountManagersModalAction({
          ref: payload.ref,
          formName: "editAccountManagersModal",
        })
      );
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchCompanyProfileAction({
          id: payload?.data?.id,
          companyProfileSubscriptionList:
            superReducer["companyProfileSubscriptionList"],
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.updateCompanyAccountManagersFailed({
          ref: payload.ref,
          serverRef: "editAccountManagersModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.updateCompanyAccountManagersFailed({
          ref: payload.ref,
          serverRef: "editAccountManagersModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            response?.errors[0]?.message,
        })
      );
    }
  } catch (error) {
    yield put(
      Action.updateCompanyAccountManagersFailed({
        ref: payload.ref,
        serverRef: "editAccountManagersModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpdateCompanyAccountManagers() {
  yield takeLatest(
    Types.UPDATE_ACCOUNT_MANAGERS_MODAL_ATTEMPT,
    handleUpdateAccountManagersSaga
  );
}

export function* handleUpdateCompanyBussPartnersSaga({ payload }) {
  try {
    const response = yield call(client.updateCompanyBussPartners, payload.data);
    if (!response.errors) {
      yield put(Action.updateCompanyBussPartnersSuccess(payload.ref));
      yield put(
        Action.dismissEditBussPartnersModalAction({
          ref: payload.ref,
          formName: "editCompanyBussPartnersModal",
        })
      );
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchCompanyProfileAction({
          id: payload?.data?.id,
          companyProfileSubscriptionList:
            superReducer["companyProfileSubscriptionList"],
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.updateCompanyBussPartnersFailed({
          ref: payload.ref,
          serverRef: "editCompanyBussPartnersModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.updateCompanyBussPartnersFailed({
          ref: payload.ref,
          serverRef: "editCompanyBussPartnersModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            response?.errors[0]?.message,
        })
      );
    }
  } catch (error) {
    yield put(
      Action.updateCompanyBussPartnersFailed({
        ref: payload.ref,
        serverRef: "editCompanyBussPartnersModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpdateCompanyBussinessPartners() {
  yield takeLatest(
    Types.UPDATE_BUSSINESS_PARTNERS_MODAL_ATTEMPT,
    handleUpdateCompanyBussPartnersSaga
  );
}

export function* handleEditCompanyBussPartnerSaga({ payload }) {
  try {
    const response = yield call(client.editCompanyBussPartner, payload.data);
    if (!response.errors) {
      yield put(Action.editBussPartnerSuccess(payload.ref));
      yield put(
        Action.dismissUpdateBussPartnerModalAction({
          ref: payload.ref,
          formName: "updateBussPartnerModal",
        })
      );
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchCompanyProfileAction({
          id: response?.data?.update_partner?.user?.company?.id,
          companyProfileSubscriptionList:
            superReducer["companyProfileSubscriptionList"],
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.editBussPartnerFailed({
          ref: payload.ref,
          serverRef: "updateBussPartnerModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.editBussPartnerFailed({
          ref: payload.ref,
          serverRef: "updateBussPartnerModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            response?.errors[0]?.message,
        })
      );
    }
  } catch (error) {
    yield put(
      Action.editBussPartnerFailed({
        ref: payload.ref,
        serverRef: "updateBussPartnerModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onEditCompanyBussinessPartner() {
  yield takeLatest(
    Types.EDIT_BUSSINESS_PARTNER_MODAL_ATTEMPT,
    handleEditCompanyBussPartnerSaga
  );
}

export function* handleDeleteCompanyBussPartnersSaga({ payload }) {
  try {
    const response = yield call(client.deleteCompanyBussPartners, payload);
    if (!response.errors) {
      yield put(
        Action.deleteCompanyBussPartnersSuccess(response?.data?.delete_partner)
      );
      yield put(Action.showSuccessToast());
      if (window.location.pathname.includes("admin-panel/company-profile/")) {
        setTimeout(function () {
          window.location.reload();
        }, 1000);
        return;
      }
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllCompaniesAction({
          ref: "companiesList",
          companiesList: superReducer["companiesList"],
        })
      );
    } else {
      yield put(Action.deleteCompanyBussPartnersFailed());
      yield put(
        Action.showErrorToast(
          response?.errors[0]?.extensions?.reason ||
          (response?.errors?.[0]?.extensions?.validation
            ? Object.values(
              response?.errors?.[0]?.extensions?.validation
            )[0]?.toString()
            : response?.errors?.[0]?.message)
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteCompanyBussPartnersFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteCompanyBussinessPartners() {
  yield takeLatest(
    Types.DELETE_COMPANY_BUSSINESS_PARTNERS_ATTEMPT,
    handleDeleteCompanyBussPartnersSaga
  );
}

export function* handleUpsertCompanySubscriptionSaga({ payload }) {
  try {
    const response = yield call(client.upsertCompanySubscription, payload.data);
    if (!response.errors) {
      yield put(Action.upsertCompanySubscriptionSuccess(payload.ref));
      yield put(
        Action.dismissAddSubscriptionModalAction({
          ref: payload.ref,
          formName: "addSubscriptionModal",
        })
      );
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchCompanyProfileAction({
          id: payload?.data?.company_id,
          companyProfileSubscriptionList:
            superReducer["companyProfileSubscriptionList"],
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.upsertCompanySubscriptionFailed({
          ref: payload.ref,
          serverRef: "addSubscriptionModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.upsertCompanySubscriptionFailed({
          ref: payload.ref,
          serverRef: "addSubscriptionModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            response?.errors[0]?.message,
        })
      );
    }
  } catch (error) {
    yield put(
      Action.upsertCompanySubscriptionFailed({
        ref: payload.ref,
        serverRef: "addSubscriptionModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpsertCompanySubscription() {
  yield takeLatest(
    Types.UPSERT_COMPANY_SUBSCRIPTION_ATTEMPT,
    handleUpsertCompanySubscriptionSaga
  );
}

export function* handleDeleteCompanySubscriptionSaga({ payload, companyId }) {
  try {
    const response = yield call(client.deleteCompanySubscription, payload);
    if (
      !response.errors &&
      response.data?.delete_subscription?.status == "success"
    ) {
      yield put(Action.deleteCompanyPlanSubscriptionSuccess());
      yield put(
        Action.showSuccessToast(response?.data?.delete_subscription?.message)
      );
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchCompanyProfileAction({
          id: companyId,
          companyProfileSubscriptionList:
            superReducer["companyProfileSubscriptionList"],
        })
      );
    } else {
      yield put(Action.deleteCompanyPlanSubscriptionFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_subscription?.message ||
          response.errors[0]?.extensions?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteCompanyPlanSubscriptionFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteCompanySubscription() {
  yield takeLatest(
    Types.DELETE_COMPANY_SUBSCRIPTION_ATTEMPT,
    handleDeleteCompanySubscriptionSaga
  );
}

export function* handleFetchAdminUsersSaga({ payload }) {
  try {
    const response = yield call(client.fetchAllAdminUsers, payload);
    yield put(
      Action.fetchAdminUsersSuccess(
        payload.ref,
        response.data?.active_saas_users
      )
    );
  } catch (error) {
    yield put(Action.fetchAdminUsersFailed(payload.ref, error));
    console.log("err", error);
  }
}

function* onFetchAdminUsers() {
  yield takeLatest(Types.FETCH_ADMIN_USERS_ATTEMPT, handleFetchAdminUsersSaga);
}

export function* handleCreateAdminUsersSaga({ payload }) {
  try {
    const response = yield call(client.createAdminUser, payload.data);
    if (!response.errors) {
      yield put(Action.createAdminUserSuccess(payload.ref));
      yield put(
        Action.dismissAdminUserModalAction({
          ref: payload.ref,
          formName: "adminUsersModal",
        })
      );
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAdminUsersAction({
          ref: "adminUsersList",
          adminUsersList: superReducer["adminUsersList"],
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.createAdminUserFailed({
          ref: payload.ref,
          serverRef: "adminUsersModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.createAdminUserFailed({
          ref: payload.ref,
          serverRef: "adminUsersModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            HelperFns.localizeFns("something went wrong"),
        })
      );
    }
  } catch (error) {
    yield put(
      Action.createAdminUserFailed({
        ref: payload.ref,
        serverRef: "adminUsersModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onCreateAdminUser() {
  yield takeLatest(Types.CREATE_ADMIN_USER_ATTEMPT, handleCreateAdminUsersSaga);
}

export function* handleUpdateAdminUsersProfileSaga({ payload }) {
  try {
    const response = yield call(client.createAdminUser, payload?.data);
    if (!response?.errors) {
      yield put(
        Action.updateAdminUserProfileSuccess(
          response?.data?.updateOrCreateSaasUser
        )
      );
      yield put(Action.showSuccessToast());
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAdminUsersAction({
          ref: "adminUsersList",
          adminUsersList: superReducer["adminUsersList"],
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.updateAdminUserProfileFailed({
          ref: payload?.ref,
          serverRef: "adminUsersProfileModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.updateAdminUserProfileFailed({
          ref: payload?.ref,
          serverRef: "adminUsersProfileModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            response?.errors[0]?.message,
        })
      );
    }
  } catch (error) {
    yield put(
      Action.updateAdminUserProfileFailed({
        ref: payload?.ref,
        serverRef: "adminUsersProfileModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpdateAdminUserProfile() {
  yield takeLatest(
    Types.UPDATE_ADMIN_USER_PROFILE_ATTEMPT,
    handleUpdateAdminUsersProfileSaga
  );
}

export function* handleDeleteAdminUserSaga({ payload }) {
  try {
    const response = yield call(client.deleteAdminUser, payload);
    if (
      !response.errors &&
      response.data?.delete_saas_user?.status == "success"
    ) {
      yield put(Action.deleteAdminUserSuccess());
      yield put(
        Action.showSuccessToast(response?.data?.delete_saas_user?.message)
      );
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAdminUsersAction({
          ref: "adminUsersList",
          adminUsersList: superReducer["adminUsersList"],
        })
      );
    } else {
      yield put(Action.deleteAdminUserFailed());
      yield put(
        Action.showErrorToast(
          response?.errors[0]?.extensions?.reason ||
          (response?.errors?.[0]?.extensions?.validation
            ? Object.values(
              response?.errors?.[0]?.extensions?.validation
            )[0]?.toString()
            : response?.errors?.[0]?.message)
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteAdminUserFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteAdminUsers() {
  yield takeLatest(Types.DELETE_ADMIN_USER_ATTEMPT, handleDeleteAdminUserSaga);
}

export function* handleFetchAdminUsersCompaniesSaga({ payload }) {
  try {
    const response = yield call(client.fetchAdminUsersCompanies, payload);
    yield put(
      Action.fetchAdminUserCompaniesSuccess(
        response.data?.companies ?? response.data?.saas_companies
      )
    );
  } catch (error) {
    yield put(Action.fetchAdminUserCompaniesFailed());
    console.log("err", error);
  }
}

function* onFetchAdminUsersCompanies() {
  yield takeEvery(
    Types.FETCH_ADMIN_USER_COMPANIES_ATTEMPT,
    handleFetchAdminUsersCompaniesSaga
  );
}

export function* handleFetchAdminUsersEmployeesSaga({ payload }) {
  try {
    const response = yield call(client.fetchAdminUsersEmployees, payload);
    yield put(Action.fetchAdminUserEmployeesSuccess(response?.data?.company));
  } catch (error) {
    yield put(Action.fetchAdminUserEmployeesFailed());
    console.log("err", error);
  }
}

function* onFetchAdminUsersEmployees() {
  yield takeEvery(
    Types.FETCH_ADMIN_USER_EMPLOYEES_ATTEMPT,
    handleFetchAdminUsersEmployeesSaga
  );
}

export function* handleEditAdminUserPrivilegesSaga({ payload }) {
  try {
    const response = yield call(client.editAdminUserPrivileges, payload);
    console.log(response);
    if (!response?.errors) {
      yield put(
        Action.showManagePrivilagesModalAction({
          ref: "managePrivilagesModalActions",
          formName: "managePrivilagesModal",
          data: {
            system_privileges: response.data?.admin_privileges,
            ...response.data?.userPrivileges,
          },
        })
      );
    } else {
      yield put(Action.editAdminUserPrivilegesFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.editAdminUserPrivilegesFailed(error));
    yield put(Action.showErrorToast(error?.message));
  }
}
function* onEditAdminUserPrivileges() {
  yield takeEvery(
    Types.EDIT_ADMIN_USER_PRIVILEGES_ATTEMPT,
    handleEditAdminUserPrivilegesSaga
  );
}

export function* handleUpsertAdminUserPrivilegesSaga({ payload }) {
  try {
    const response = yield call(client.upsertAdminUserPrivileges, payload);
    if (!response?.errors) {
      yield put(
        Action.dismissManagePrivilagesModalAction({
          ref: "managePrivilagesModalActions",
          formName: "managePrivilagesModal",
        })
      );
    } else {
      yield put(Action.upsertAdminUserPrivilegesFailed());
      yield put(
        Action.showErrorToast(
          response?.errors[0]?.extensions?.reason ||
          (response?.errors?.[0]?.extensions?.validation
            ? Object.values(
              response?.errors?.[0]?.extensions?.validation
            )[0]?.toString()
            : response?.errors?.[0]?.message)
        )
      );
    }
  } catch (error) {
    yield put(Action.upsertAdminUserPrivilegesFailed(error));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpsertAdminUserPrivileges() {
  yield takeEvery(
    Types.UPSERT_ADMIN_USER_PRIVILEGES_ATTEMPT,
    handleUpsertAdminUserPrivilegesSaga
  );
}

// init suspension

export function* handleInitSuspenseSaga({ payload }) {
  try {
    const response = yield call(client.initSuspenseUser, payload);
    if (!response?.errors) {
      if (
        response?.data?.init_suspension?.__typename ==
        "EmployeeSuspendedException" ||
        response?.data?.init_suspension?.__typename == "GeneralException"
      ) {
        yield put(
          Action.initSuspensionFailed(response?.data?.init_suspension?.message)
        );
        return;
      }
      yield put(Action.initSuspensionSuccess(response?.data?.init_suspension));
    } else {
      yield put(Action.initSuspensionFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.initSuspensionFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onInitSuspension() {
  yield takeLatest(Types.INIT_SUSPENSION_USER_ATTEMPT, handleInitSuspenseSaga);
}

export function* handleResendCredentialsUserSaga({ payload }) {
  try {
    const response = yield call(client.resendCredentails, payload);
    if (
      !response.errors &&
      response.data?.resend_verification?.status == "success"
    ) {
      yield put(Action.resendCredentailsSuccess());
      yield put(
        Action.showSuccessToast(response?.data?.resend_verification?.message)
      );
      // if (window.location.pathname.includes("employee-profile")) {
      //   window.location.reload();
      //   return;
      // }
      // let superReducer = yield select(getSuperReducer);
      // yield put(
      //   Action.fetchAllEmployeesAction({
      //     ref: "allEmployeesList",
      //     allEmployeesList: superReducer["allEmployeesList"],
      //   })
      // );
    } else {
      yield put(Action.resendCredentailsFailed());
      yield put(
        Action.showErrorToast(
          response.data?.resend_verification?.message ||
          response.errors[0]?.extensions?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.resendCredentailsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onResendCredentails() {
  yield takeEvery(
    Types.RESEND_CREDENTAILS_ATTEMPT,
    handleResendCredentialsUserSaga
  );
}

// employee profile documents
export function* handleFetchEmployeeDocumentsSaga({ payload }) {
  try {
    const response = yield call(client.fetchEmployeeDocuments, payload);
    if (!response.errors) {
      console.log(response.data);
      yield put(
        Action.fetchEmployeeDocumnetsSuccess(
          response.data?.employeeDocuments?.profileDocuments
        )
      );
    } else {
      yield put(Action.fetchEmployeeDocumnetsFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    console.log({ error });
    yield put(Action.fetchEmployeeDocumnetsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchEmployeeDocuments() {
  yield takeEvery(
    Types.FETCH_EMPLOYEE_DOCUMENTS_ATTEMPT,
    handleFetchEmployeeDocumentsSaga
  );
}

export function* handleAddEmployeeDocumentSaga({ payload }) {
  try {
    const response = yield call(client.addEmployeeDocument, payload);
    if (!response.errors) {
      console.log(response.data);
      yield put(Action.addUserDocumentSuccess());
      if (payload?.documnet?.page_flag === "company_profile") {
        yield put(
          Action.fetchRequiredDocumentsAttempt(
            {
              company_id: response.data.upsert_user_document?.user?.company?.id,
              page_flag: payload?.documnet?.page_flag,
            },
            payload?.hasEmployeesPrivileges
          )
        );
      } else {
        yield put(
          Action.fetchEmployeeDocumnetsAction(
            response.data.upsert_user_document?.user?.id
          )
        );
        yield put(
          Action.fetchHistoryDocModalAttempt({
            ref: "historyDocumentsModalActions",
            employee_id: response.data.upsert_user_document?.user?.id,
            document_id: response.data.upsert_user_document?.document?.id,
          })
        );
      }
    } else {
      if (payload?.inputType?.page_flag === "company_profile") {
        yield put(
          Action.fetchRequiredDocumentsFailed(
            response.errors[0]?.extensions?.reason ||
            response?.errors[0]?.extensions?.validation[
              "input.document_id"
            ]?.toString() ||
            HelperFns.localizeFns("Something went wrong")
          )
        );
      } else {
        yield put(
          Action.addUserDocumentFailed(
            response.errors[0]?.extensions?.reason ||
            response?.errors[0]?.extensions?.validation[
              "input.document_id"
            ]?.toString() ||
            HelperFns.localizeFns("Something went wrong")
          )
        );
      }
      // yield put(Action.showErrorToast(response?.errors?.[0]?.extensions?.reason));
    }
  } catch (error) {
    console.log({ error });
    yield put(
      Action.addUserDocumentFailed(error.message ?? "Something went wrong!")
    );
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onAddEmployeeDocument() {
  yield takeEvery(
    Types.ADD_USER_DOCUMENT_ATTEMPT,
    handleAddEmployeeDocumentSaga
  );
}

export function* handlefetchUpsertDocModalSaga({ payload }) {
  try {
    const response = yield call(client.fetchUpsertDocModal, payload);
    if (!response.errors) {
      console.log(response.data);
      yield put(
        Action.fetchUpsertDocModalSuccess({
          countries: response.data?.countries,
        })
      );
    } else {
      yield put(Action.fetchUpsertDocModalFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    console.log({ error });
    yield put(Action.fetchUpsertDocModalFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onfetchUpsertDocModal() {
  yield takeEvery(
    Types.FETCH_UPSERT_DOC_MODAL_ATTEMPT,
    handlefetchUpsertDocModalSaga
  );
}

export function* handleDeleteUserDocumentAttachmentSaga({ payload }) {
  try {
    const response = yield call(client.deleteUserDocumentAttachment, payload);
    if (!response.errors) {
      console.log(response.data);
      yield put(Action.deleteUserDocumentAttachmentSuccess(payload.file_id));
    } else {
      yield put(Action.deleteUserDocumentAttachmentFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.extensions?.validation
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteUserDocumentAttachmentFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteUserDocumentAttachment() {
  yield takeEvery(
    Types.DELETE_USER_DOCUMENT_ATTACHMENT_ATTEMPT,
    handleDeleteUserDocumentAttachmentSaga
  );
}

export function* exportEmployeeListSaga({ payload }) {
  try {
    const response = yield call(client.exportEmployeeList, payload);
    if (!response?.errors) {
      yield put(
        Action.showSuccessToast(response?.data?.export_employees_list?.message)
      );
      if (response?.data?.export_employees_list?.status === "success") {
        yield put(
          Action.dismissExportEmployeeListModal({
            ref: "exportEmployeeListModal",
          })
        );
        yield put(Action.exportEmployeeListSuccess(payload?.ref));
        HelperFns.downloadFile(response.data?.export_employees_list?.file);
      } else {
        yield put(Action.exportEmployeeListFailed(payload?.ref));
      }
    } else {
      yield put(Action.exportEmployeeListFailed(payload?.ref));
      yield put(
        Action.showErrorToast(
          response?.errors[0]?.extensions?.reason ||
          (response?.errors?.[0]?.extensions?.validation
            ? Object.values(
              response?.errors?.[0]?.extensions?.validation
            )[0]?.toString()
            : response?.errors?.[0]?.message)
        )
      );
    }
  } catch (error) {
    yield put(Action.exportEmployeeListFailed(payload?.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onExportEmployeeList() {
  yield takeEvery(Types.EXPORT_EMPLOYEE_LIST_ATTEMPT, exportEmployeeListSaga);
}

// fetch Request Rules List
export function* handleFetchRequestRulesListSaga({ payload }) {
  try {
    const response = yield call(client.fetchRequestRules, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchRequestRulesListSuccess(payload.ref, response.data?.rules)
      );
    } else {
      yield put(Action.fetchRequestRulesListFailed(payload.ref));
      console.log("err", response.errors);
    }
  } catch (error) {
    yield put(Action.fetchRequestRulesListFailed(payload.ref));
    console.log("err", { error });
  }
}

function* onFetchRequestRulesList() {
  yield takeLatest(
    Types.FETCH_REQUEST_RULES_LIST_ATTEMPT,
    handleFetchRequestRulesListSaga
  );
}

export function* handleFetchRequestRulesOptsSaga({ payload }) {
  try {
    const response = yield call(client.fetchRequestRulesOpts, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchRequestRulesUsersOptsSuccess(response.data?.usersOpts?.data)
      );
      yield put(
        Action.fetchDepartmentsSuccess(response.data?.departmentOpts?.data)
      );
    } else {
      yield put(Action.fetchRequestRulesUsersOptsFailed());
      yield put(Action.fetchDepartmentsFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.fetchRequestRulesUsersOptsFailed());
    yield put(Action.fetchDepartmentsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}
function* onFetchRequestRulesOpts() {
  yield takeLatest(
    Types.FETCH_REQUEST_RULES_OPTS_ATTEMPT,
    handleFetchRequestRulesOptsSaga
  );
}

// upsert Request Rule
export function* handleUpsertRequestRulesSaga({ payload }) {
  try {
    const response = yield call(client.upsertRequestRules, payload);

    if (!response?.errors) {
      yield put(Action.showSuccessToast());
      yield put(Action.upsertRequestRulesSuccess());
      let requestRules = yield select(getSuperReducer); // <-- get the super for request rules list
      yield put(
        Action.fetchRequestRulesListAction({
          ref: "requestRulesList",
          requestRulesList: requestRules?.requestRulesList,
        })
      );
    } else {
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.upsertRequestRulesFailed(
            Helper.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            )
          )
        );
        return;
      }
      yield put(Action.upsertRequestRulesFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.upsertRequestRulesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpsertRequestRules() {
  yield takeLatest(
    Types.UPSERT_REQUEST_RULES_ATTEMPT,
    handleUpsertRequestRulesSaga
  );
}

export function* handleDeleteRequestRulesSaga({ payload }) {
  try {
    const response = yield call(client.deleteRequestRules, payload);

    if (!response?.errors) {
      if (response?.data?.delete_rule?.status == "success") {
        yield put(Action.showSuccessToast());
        yield put(Action.deleteRequestRuleSuccess());
        let requestRules = yield select(getSuperReducer); // <-- get the super for request rules list
        yield put(
          Action.fetchRequestRulesListAction({
            ref: "requestRulesList",
            requestRulesList: requestRules?.requestRulesList,
          })
        );
        return;
      }
      if (response?.data?.delete_rule?.status !== "success") {
        yield put(
          Action.showSuccessToast(response?.data?.delete_rule?.message)
        );
        yield put(Action.deleteRequestRulesFailed());
        return;
      }
    } else {
      yield put(Action.deleteRequestRulesFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.deleteRequestRulesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteRequestRules() {
  yield takeLatest(
    Types.DELETE_REQUEST_RULES_ATTEMPT,
    handleDeleteRequestRulesSaga
  );
}
function* handleFetchOpenrollMonthsSaga({ payload }) {
  try {
    const response = yield call(client.fetchOpenPayrollMonthsQuery, payload);
    if (!response.errors) {
      yield put(
        Action.fetchOpenPayrollMonthsSuccess(
          response?.data?.open_payroll_months
        )
      );
      yield put(Action.AddCompanyCurrencies(response?.data?.currencies));
      if (response?.data?.profile?.offices) {
        yield put(
          Action.editOpenPayrollMonthsOfficesAccordingToScope(
            response?.data?.profile?.offices
          )
        );
      } else if (response?.data?.profile?.managedOffices) {
        yield put(
          Action.editOpenPayrollMonthsOfficesAccordingToScope(
            response?.data?.profile?.managedOffices
          )
        );
      } else if (
        !response?.data?.profile?.offices &&
        !response?.data?.profile?.managedOffices
      ) {
        yield put(
          Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
        );
      }
    } else {
      yield put(Action.fetchOpenPayrollMonthsFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchOpenPayrollMonths() {
  yield takeLatest(
    Types.FETCH_OPEN_PAYROLL_MONTHS_ATTEMPT,
    handleFetchOpenrollMonthsSaga
  );
}
function* handleDownloadEmployeeTempleteSaga({ payload }) {
  try {
    const response = yield call(client.ExportEmployeeImportTemplate, payload);
    if (response?.data?.export_excel_file_template?.status === "success") {
      yield put(Action.downloadEmployeeTemplateSuccess());
      window.open(response?.data?.export_excel_file_template?.file, "_blank");
    } else {
      yield put(Action.downloadEmployeeTemplateFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.downloadEmployeeTemplateFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDownloadEmplyeeTemplete() {
  yield takeEvery(
    Types.DOWNLOAD_EMPLOYEE_TEMPLATE_ATTEMPT,
    handleDownloadEmployeeTempleteSaga
  );
}

function* handleUploadEmployeesBulkSaga({ payload }) {
  let superReducer = yield select(getSuperReducer);
  try {
    const response = yield call(client.UploadEmployeeBulkCsvQuery, payload);
    if (response.data?.import_excel_file_template?.status === "success") {
      yield put(Action.uploadBulkEmployeesCsvSuccess());
      yield put(Action.showSuccessToast());
      yield call(payload.resetFile);
    } else if (response.data?.import_excel_file_template?.status === "error") {
      yield put(
        Action.uploadBulkEmployeesCsvFailed(
          response?.data?.import_excel_file_template?.messages
        )
      );
      yield call(payload.resetFile);
    } else {
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );

      yield put(Action.uploadBulkEmployeesCsvRequestFailed());
      yield call(payload.resetFile);
    }
  } catch (error) {
    yield put(Action.showErrorToast(error?.message));
    yield put(Action.uploadBulkEmployeesCsvRequestFailed());
    yield call(payload.resetFile);

    console.log(error);
  }
}

function* onUploadEmployeeBulk() {
  yield takeEvery(
    Types.UPLOAD_BULK_EMPLOYEES_CSV_ATTEMPT,
    handleUploadEmployeesBulkSaga
  );
}

function* handleFetchTotalExpensesSaga({ payload }) {
  try {
    const response = yield call(client.fetchTotalExpenses, payload);
    if (!response.errors) {
      yield put(
        Action.fetchTotalExpensesSuccess(
          response?.data?.expenses_chart_for_categories
        )
      );
      yield put(
        Action.fetchDashboardCurrenciesSuccess(response?.data?.currencies)
      );
    } else {
      yield put(Action.fetchTotalExpensesFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.fetchTotalExpensesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchTotalExpenses() {
  yield takeLatest(
    Types.FETCH_TOTAL_EXPENSES_ATTEMPT,
    handleFetchTotalExpensesSaga
  );
}

function* handleFetchSubExpensesSaga({ payload }) {
  try {
    const response = yield call(client.fetchSubExpenses, payload);
    if (!response.errors) {
      if (response?.data?.expenses_chart_for_sub_categories?.length == 0) {
        yield put(Action.fetchSubCategoryExpensesFailed());
        yield put(
          Action.showToast({
            type: "warning",
            msg: "this category has no sub categories",
          })
        );
      } else {
        yield put(
          Action.fetchSubCategoryExpensesSuccess(
            response?.data?.expenses_chart_for_sub_categories
          )
        );
      }
    } else {
      yield put(Action.fetchSubCategoryExpensesFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.fetchSubCategoryExpensesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchSubExpenses() {
  yield takeLatest(
    Types.FETCH_SUB_CATEGORY_EXPENSES_ATTEMPT,
    handleFetchSubExpensesSaga
  );
}

function* handleFetchEmployeesTurnoverChartSaga({ payload }) {
  try {
    const response = yield call(client.fetchEmployeesTurnoverQuery, payload);
    if (!response.errors) {
      yield put(Action.fetchEmployeesTurnoverSuccess(response?.data));
    } else {
      yield put(Action.fetchEmployeesTurnoverFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.fetchEmployeesTurnoverFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchEmployeesTurnoverChart() {
  yield takeLatest(
    Types.FETCH_EMPLOYEES_TURNOVER_CHART_ATTEMPT,
    handleFetchEmployeesTurnoverChartSaga
  );
}

export function* handleFetchRolePresetPrivilegesSaga({ payload }) {
  try {
    const response = yield call(client.fetchRolePresetPrivileges, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchRolePresetPrivilegesSuccess(
          response.data?.role?.main,
          response.data?.role?.all,
          response.data?.role?.managed
        )
      );
    } else {
      yield put(Action.fetchRolePresetPrivilegesFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.fetchRolePresetPrivilegesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}
function* onFetchRolePresetPrivileges() {
  yield takeLatest(
    Types.FETCH_ROLE_PRESET_PRIVILEGS_ATTEMPT,
    handleFetchRolePresetPrivilegesSaga
  );
}

export function* handleFetchPreviewRolePrivilegesModalDataSaga({ payload }) {
  try {
    const response = yield call(client.fetchPreviewRolePrivilegesData, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchPreviewRolePrivilagesSuccess({
          data: {
            system_privileges: JSON.parse(response.data?.system_privileges),
            ...response.data?.role,
          },
        })
      );
    } else {
      yield put(Action.fetchPreviewRolePrivilagesFailed());

      yield put(
        Action.dismissPreviewRolePrivilagesModalAction({
          ref: "previewRolePrivilagesModalActions",
          formName: "previewRolePrivilagesModal",
        })
      );
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchPreviewRolePrivilagesFailed());

    yield put(
      Action.dismissPreviewRolePrivilagesModalAction({
        ref: "previewRolePrivilagesModalActions",
        formName: "previewRolePrivilagesModal",
      })
    );
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchPreviewRolePrivilegesModalData() {
  yield takeLatest(
    Types.FETCH_PREVIEW_ROLE_PRIVILEGES_ATTEMPT,
    handleFetchPreviewRolePrivilegesModalDataSaga
  );
}

export function* handleFetchRequestCommentsModalDataSaga({ payload }) {
  try {
    const response = yield call(client.fetchRequestCommentsModalData, payload);
    if (!response?.errors) {
      yield put(Action.fetchRequestCommentsSuccess(response.data?.get_request));
    } else {
      yield put(Action.fetchRequestCommentsFailed());

      yield put(
        Action.dismissRequestCommentsModalAction({
          ref: "requestCommentModalActions",
          formName: "requestCommentModal",
        })
      );

      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchRequestCommentsFailed());

    yield put(
      Action.dismissRequestCommentsModalAction({
        ref: "requestCommentModalActions",
        formName: "requestCommentModal",
      })
    );
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchRequestCommentsModalData() {
  yield takeLatest(
    Types.FETCH_REQUEST_COMMENTS_ATTEMPT,
    handleFetchRequestCommentsModalDataSaga
  );
}

export function* handleUpsertRequestCommentSaga({ payload }) {
  try {
    const response = yield call(client.upsertRequestComments, payload);
    if (!response?.errors) {
      yield put(Action.upsertRequestCommentSuccess());
      yield put(
        Action.fetchRequestCommentsAction(
          response?.data?.upsert_comment?.request_id
        )
      );
    } else {
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.upsertRequestCommentFailed({
            ref: payload.ref,
            serverRef: "requestCommentModalServerValidation",
            errors: Helper.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            ),
          })
        );
      } else {
        yield put(
          Action.upsertRequestCommentFailed({
            ref: payload.ref,
            serverRef: "requestCommentModalServerValidation",
            errors: {},
            modalMessage:
              response?.errors[0]?.extensions?.reason ??
              response?.errors[0]?.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(
      Action.upsertRequestCommentFailed({
        ref: payload.ref,
        serverRef: "requestCommentModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpsertRequestComments() {
  yield takeLatest(
    Types.UPSERT_REQUEST_COMMENT_ATTEMPT,
    handleUpsertRequestCommentSaga
  );
}

export function* handleAutoSuggestHolidaysSaga({ payload }) {
  try {
    const response = yield call(client.autoSuggestHolidays, payload);
    if (!response.errors) {
      yield put(Action.autoSuggestHolidaysSuccess());

      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllHolidaysAction({
          ref: "holidaysList",
          holidaysList: superReducer["holidaysList"],
        })
      );
      yield put(Action.showSuccessToast());
    } else {
      yield put(Action.autoSuggestHolidaysFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.autoSuggestHolidaysFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onAutoSuggestHolidays() {
  yield takeLatest(
    Types.AUTO_SUGGEST_HOLIDAYS_ATTEMPT,
    handleAutoSuggestHolidaysSaga
  );
}

export function* handleAcceptSuggestedHolidaysSaga({ payload }) {
  try {
    const response = yield call(client.acceptSuggsetedHoliday, payload);
    if (!response.errors) {
      yield put(Action.acceptSuggestedHolidaySuccess());

      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllHolidaysAction({
          ref: "holidaysList",
          holidaysList: superReducer["holidaysList"],
        })
      );
      yield put(Action.showSuccessToast());
    } else {
      yield put(Action.acceptSuggestedHolidayFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.acceptSuggestedHolidayFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onAcceptSuggestedHolidays() {
  yield takeLatest(
    Types.ACCEPT_SUGGESTED_HOLIDAY_ATTEMPT,
    handleAcceptSuggestedHolidaysSaga
  );
}

export function* handleDeleteSuggestedHolidaysSaga({ payload }) {
  try {
    const response = yield call(client.deleteSuggesetedHolidays, payload);
    if (
      !response.errors &&
      response.data?.delete_suggested_holiday?.status === "success"
    ) {
      yield put(Action.deleteSuggestedHolidaySuccess());
      yield put(
        Action.showSuccessToast(
          response?.data?.delete_suggested_holiday?.message
        )
      );
      let superReducer = yield select(getSuperReducer);
      yield put(
        Action.fetchAllHolidaysAction({
          ref: "holidaysList",
          holidaysList: superReducer["holidaysList"],
        })
      );
    } else {
      yield put(Action.deleteSuggestedHolidayFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_suggested_holiday?.message ||
          response.errors[0]?.extensions?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteSuggestedHolidayFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteSuggestedHolidays() {
  yield takeLatest(
    Types.DELETE_SUGGESTED_HOLIDAYS_ATTEMPT,
    handleDeleteSuggestedHolidaysSaga
  );
}

export function* handleDeleteSalaryConfigSaga({ payload, userId }) {
  try {
    const response = yield call(client.deleteSalaryConfig, payload);
    if (
      !response.errors &&
      response.data?.delete_user_salary_config?.status === "success"
    ) {
      yield put(Action.deleteSalaryConfigSuccess());
      yield put(
        Action.showSuccessToast(
          response?.data?.delete_user_salary_config?.message
        )
      );
      yield put(
        Action.fetchEmployeeSalaryConfigAction({
          id: userId,
        })
      );
    } else {
      yield put(Action.deleteSalaryConfigFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_user_salary_config?.message ||
          response.errors[0]?.extensions?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteSalaryConfigFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteSalaryConfig() {
  yield takeLatest(
    Types.DELETE_SALARY_CONFIG_ATTEMPT,
    handleDeleteSalaryConfigSaga
  );
}

export function* handleFetchHalfWorkTimingTemplateSaga({ payload }) {
  try {
    const response = yield call(
      client.fetchSuggestedHalfWorkTimingTemplate,
      payload
    );
    if (!response.errors) {
      yield put(
        Action.fetchSuggestedHalfWorkTimingDataSuccess(
          HelperFns.serializeFetchedWorkTime(response.data?.break_work_timing)
        )
      );
      yield put(Action.showSuccessToast());
    } else {
      yield put(Action.fetchSuggestedHalfWorkTimingDataFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchSuggestedHalfWorkTimingDataFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchHalfWorkTimingTemplate() {
  yield takeLatest(
    Types.FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_ATTEMPT,
    handleFetchHalfWorkTimingTemplateSaga
  );
}

export function* handleFetchEditAttendanceWorkTimingOptionsSaga({
  payload,
  oldWorkTimingId,
}) {
  try {
    const response = yield call(
      client.fetchEditAttendnaceLogsWorkTimings,
      payload
    );
    if (!response?.errors) {
      yield put(
        Action.fetchEditAttendanceWorkTimingsOptionsSuccess(
          response?.data?.work_timings,
          oldWorkTimingId
        )
      );
    } else {
      yield put(Action.fetchEditAttendanceWorkTimingsOptionsFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchEditAttendanceWorkTimingsOptionsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchEditAttendanceWorkTimingOptions() {
  yield takeLatest(
    Types.FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS,
    handleFetchEditAttendanceWorkTimingOptionsSaga
  );
}

export function* handleFetchEditAttendanceLocationsOptionsSaga({ payload }) {
  try {
    const response = yield call(
      client.fetchEditAttendnaceLogsLocations,
      payload
    );
    if (!response?.errors) {
      yield put(
        Action.fetchEditAttendanceLocationsOptionsSuccess(
          response?.data?.locations_options
        )
      );
    } else {
      yield put(Action.fetchEditAttendanceLocationsOptionsFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchEditAttendanceLocationsOptionsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchEditAttendanceLocationsOptions() {
  yield takeLatest(
    Types.FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS,
    handleFetchEditAttendanceLocationsOptionsSaga
  );
}

export function* handleUpdateAttendnaceLogsLocationsIdsSaga({ payload }) {
  try {
    const response = yield call(client.updateLocationsIds, payload);
    if (!response?.errors) {
      yield put(
        Action.updateLocationsIdsSuccess(
          response?.data?.change_available_locations_ids
        )
      );
    } else {
      yield put(Action.updateLocationsIdsFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.updateLocationsIdsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpdateAttendnaceLogsLocationsIds() {
  yield takeLatest(
    Types.UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS,
    handleUpdateAttendnaceLogsLocationsIdsSaga
  );
}

export function* handleFetchingAccessLevelsSaga({ payload }) {
  try {
    const response = yield call(client.fetchAccessLevels, payload);
    yield put(
      Action.fetchAccessLevelsSuccess([
        ...response?.data?.profile?.company?.roles,
        ...response?.data?.profile?.company?.currentSubscription?.plan?.roles,
      ])
    );
  } catch (error) {
    yield put(Action.fetchAccessLevelsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchAccessLevelsWatcher() {
  yield takeLatest(
    Types.FETCH_COMPANY_ACCESS_LEVELS_ATTEMPT,
    handleFetchingAccessLevelsSaga
  );
}

export function* handleUpsertingRoleToCompanySaga({ payload }) {
  try {
    const response = yield call(client.upsertRoleToCompany, payload);
    console.log(response);
    if (!response.errors) {
      yield put(Action.upsertRoleToCompanySuccess());
      yield put(Action.fetchAccessLevelsAttempt());
    } else {
      console.log(!response.errors?.extensions);
      yield put(
        Action.upsertRoleToCompanyFailed({
          serverRef: "accessLevelsServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
      return;
    }
  } catch (error) {
    yield put(
      Action.upsertRoleToCompanyFailed(
        error ? error.message : "Something went wrong"
      )
    );
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpsertRoleToCompanyWatcher() {
  yield takeLatest(
    Types.UPSERT_ROLE_TO_COMPANY_ATTEMPT,
    handleUpsertingRoleToCompanySaga
  );
}

export function* handleEditRolePrivilegesSaga({ payload }) {
  try {
    const response = yield call(client.editRolePrivileges, payload);
    yield put(Action.editRolePrivilegesSuccess(response?.data));
    console.log(response);
  } catch (error) {
    yield put(Action.editRolePrivilegesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onEditRolePrivilegesWatcher() {
  yield takeLatest(
    Types.EDIT_ROLE_PRIVILEGES_ATTEMPT,
    handleEditRolePrivilegesSaga
  );
}

export function* handleDeleteCompanyRoleSaga({ payload }) {
  try {
    const response = yield call(client.deleteCompanyRole, payload);
    if (!response?.errors) {
      yield put(Action.deleteCompanyRoleSuccess());
      yield put(Action.fetchAccessLevelsAttempt());
      yield put(Action.showSuccessToast(response?.data?.deleteRole?.message));
    } else {
      yield put(Action.deleteCompanyRoleFailed());
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.deleteCompanyRoleFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteCompnayRoleWatcher() {
  yield takeLatest(
    Types.DELETE_COMPANY_ROLE_ATTEMPT,
    handleDeleteCompanyRoleSaga
  );
}

export function* handleFetchPlanPrivilegesSaga({ payload }) {
  try {
    const response = yield call(client.fetchPlanPrivileges, payload);
    if (!response.errors) {
      yield put(
        Action.fetchPlanPrivilegesSuccess(response.data.plan_privileges)
      );
    } else {
      yield put(Action.fetchPlanPrivilegesFailed());
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.fetchPlanPrivilegesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchPlanPrivilegesSaga() {
  yield takeLatest(
    Types.FETCH_PLAN_PRIVILEGES_ATTEMPT,
    handleFetchPlanPrivilegesSaga
  );
}

export function* handleFetchingAssignmentForm({ payload }) {
  try {
    const response = yield call(client.fetchAssignmentForm, payload);
    if (!response.errors) {
      yield put(
        Action.fetchAssignmentFormSuccessAction(response?.data, payload)
      );
    } else {
      yield put(Action.fetchAssignmentFormFailedAction(response?.data));
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* onHandleFetchingAssignmentFormInputs() {
  yield takeLatest(
    Types.FETCH_ASSIGNMENT_FORM_ATTEMPT,
    handleFetchingAssignmentForm
  );
}

export function* handleDeletingAssignmet({ payload }) {
  try {
    const response = yield call(client.deleteAssignment, payload);
    if (!response.errors) {
      if (response?.data?.deleteAssignment?.status == "error") {
        yield put(
          Action.showErrorToast(response?.data?.deleteAssignment?.message)
        );
        yield put(Action.deleteAssignmentFailed());
        return;
      }
      if (response?.data?.deleteAssignment?.status == "force_delete") {
        swal({
          text: response?.data?.deleteAssignment?.message,
          icon: "warning",
          className: "swal-warning-style",
          buttons: true,
        }).then((force_delete) => {
          if (force_delete) {
            Store.dispatch(Action.deleteAssignmentAttempt(payload.id, true));
          }
        });

        yield put(
          Action.showErrorToast(response?.data?.deleteAssignment?.message)
        );
        yield put(Action.deleteAssignmentFailed());
        return;
      }
      let { from, employees, locations, work_timings } = yield select(
        (state) => state.assignments.assignmentFilters
      );
      yield put(Action.deleteAssignmentSuccess());
      yield put(
        Action.fetchAssignmentsAttempt({
          from: moment(from, "DD-MM-YYYY").format("YYYY-MM-DD"),
          to: moment(from, "DD-MM-YYYY").add(6, "days").format("YYYY-MM-DD"),
          employeeIds: employees.map((employee) => +employee),
          locationIds: locations.map((location) => +location),
          workTimingsIds: work_timings.map((workTiming) => +workTiming),
        })
      );
    } else {
      yield put(Action.deleteAssignmentFailed());
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
    }
    console.log(response);
  } catch (error) {
    yield put(Action.deleteAssignmentFailed());
    console.log(error);
  }
}

function* onHandleDeleteingAssignmentWatcher() {
  yield takeLatest(Types.DELETE_ASSIGNMENT_ATTEMPT, handleDeletingAssignmet);
}

export function* handleFetchingAssignments({ payload }) {
  try {
    const response = yield call(client.fetchAssignments, payload);
    if (!response?.errors) {
      yield put(Action.fetchAssignmentsSuccess(response?.data));
    } else {
      yield put(Action.fetchAssignmentsFailed());
    }
    console.log(response);
  } catch (error) {
    yield put(Action.fetchAssignmentsFailed());
  }
}

function* onFetchAssignmentsWatcher() {
  yield takeLatest(Types.FETCH_ASSIGNMENTS_ATTEMPT, handleFetchingAssignments);
}

export function* handleGetNotificationHistorySaga({ payload }) {
  try {
    const response = yield call(client.fetchNotificationHistory, payload);
    if (response.data) {
      yield put(Action.getNotificationHistorySuccess(response?.data));
    }
    if (response.errors) {
      yield put(
        Action.showErrorToast(response?.errors?.[0]?.extensions?.reason)
      );
      yield put(Action.getNotificationHistoryFailed());
    }
  } catch (error) {
    yield put(Action.getNotificationHistoryFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onGetNotificationHistorySaga() {
  yield takeLatest(
    Types.GET_NOTIFICATION_HISTORY_ATTEMPT,
    handleGetNotificationHistorySaga
  );
}

const sagaWatchers = {
  onEditAttProfile,
  onEditEmployee,
  onInitEmployee,
  onUpdateEmployee,
  onFetchAttProfilesList,
  onCreatreAttProfile,
  onFetchAllEmployeesList,
  onFetchAllOfices,
  onFetchAllDepartments,
  onFetchAllPositions,
  onFetchInboundRequests,
  onAcceptInboundRequest,
  onRejectInboundRequest,
  onFetchAttendanceLogs,
  onFetchSuspensionLogs,
  onFetchBalanceLogs,
  onHandleAdjustSignOut,
  onUpdateSuspension,
  onFetchOvertimeLogs,
  onUpdateDepartment,
  onCreateDepartment,
  onDeleteDepartment,
  onUpdatePosition,
  onCreatePosition,
  onDeletePosition,
  onDeleteSuspension,
  onDeleteUser,
  onActivateUser,
  onAcceptOvertimeRequest,
  onUpdateOffice,
  onDeleteOffice,
  onEditHistoryModal,
  onInitBalanceModal,
  onUpsertAttendanceProfile,
  onFetchOfficeHolidays,
  onDeleteAttProfile,
  onCloneAttProfile,
  onFetchDashboard,
  onFetchAttendanceChart,
  onFetchAttendanceChartEmployeesName,
  onFetchOffice,
  onFetchDepartmentsChart,
  onFetchOvertimeChart,
  onFetchDashboardUsers,
  onFetchUsersClaims,
  onFetchUsersBonus,
  onFetchUsersDeductions,
  onUpsertBonus,
  onDeleteBonus,
  onUpsertDeduction,
  onDeleteDeduction,
  onEditEmployeePrivileges,
  onUpsertEmployeePrivileges,
  onFetchPayrollReport,
  onFetchUsersSalaryConfig,
  onUpsertEmployeeClaimsRequest,
  onRejectClaimRequest,
  onAcceptClaimRequest,
  oneditPayrollDeductions,
  onSubmitPayrollPaymentSaga,
  onReversePayrollPaymentSaga,
  onFetchDeductionCalculatedAmountSaga,
  onFetchUsersPayslip,
  onExportPayslipSaga,
  onFetchAllHolidays,
  onCreateHolidays,
  onDeleteHolidays,
  onInitOffice,
  onFetchMonthlyReport,
  onFetchYearlyReport,
  onFetchExportPayrollOpts,
  onSubmitExportPayroll,
  onFetchAllCompanies,
  onDeleteCompanies,
  onActiviateCompanies,
  onSuspendCompanies,
  onUpsertCompany,
  onFetchCompanyProfile,
  onFetchCompanyProfileSubscriptions,
  onUpdateCompanyName,
  onUpdateCompanyAccountManagers,
  onUpdateCompanyBussinessPartners,
  onDeleteCompanyBussinessPartners,
  onUpsertCompanySubscription,
  onDeleteCompanySubscription,
  onUpdateCompany,
  onFetchAdminUsers,
  onCreateAdminUser,
  onDeleteAdminUsers,
  onFetchAdminUsersCompanies,
  onFetchAdminUsersEmployees,
  onEditAdminUserPrivileges,
  onUpsertAdminUserPrivileges,
  onUpdateAdminUserProfile,
  onExportMonthlyReportsaga,
  onExportYearlyReport,
  onFetchActivationAttendance,
  onFetchWorkTimingsList,
  onUpsertWorkTimings,
  onFetchEmailNotificationsSettings,
  onUpdateEmailNotificationsSettings,
  onEditFetchWorkTime,
  onViewFetchWorkTime,
  onCloneWorkTime,
  onDeleteWorkTime,
  onInitSuspension,
  onResendCredentails,
  onCheckPasswordUser,
  onFetchEmployeeDocuments,
  onAddEmployeeDocument,
  onfetchUpsertDocModal,
  onDeleteUserDocumentAttachment,
  onEditCompanyBussinessPartner,
  onExportEmployeeList,
  onFetchRequestRulesList,
  onFetchRequestRulesOpts,
  onUpsertRequestRules,
  onDeleteRequestRules,
  onFetchOpenPayrollMonths,
  onDownloadEmplyeeTemplete,
  onUploadEmployeeBulk,
  onFetchInitDeleteManager,
  onFetchInitSuspendManager,
  onFetchReplaceByManagersOpts,
  onFetchEditAcceptedOvertimeRequestData,

  onFetchRolePresetPrivileges,
  onFetchPreviewRolePrivilegesModalData,
  onFetchTotalExpenses,
  onFetchSubExpenses,
  onFetchEmployeesTurnoverChart,
  onFetchRequestCommentsModalData,
  onUpsertRequestComments,
  onFetchHalfWorkTimingTemplate,
  onAutoSuggestHolidays,
  onAcceptSuggestedHolidays,
  onDeleteSuggestedHolidays,
  onDeleteSalaryConfig,
  onFetchEditAttendanceWorkTimingOptions,
  onFetchEditAttendanceLocationsOptions,
  onUpdateAttendnaceLogsLocationsIds,
  onFetchAccessLevelsWatcher,
  onUpsertRoleToCompanyWatcher,
  onEditRolePrivilegesWatcher,
  onDeleteCompnayRoleWatcher,
  onFetchPlanPrivilegesSaga,
  onHandleFetchingAssignmentFormInputs,
  onFetchAssignmentsWatcher,
  onHandleDeleteingAssignmentWatcher,
  onGetNotificationHistorySaga,
};

export default sagaWatchers;
