import { put, call, takeLatest, select } from "redux-saga/effects";
import Types from "../Actions/types";
import swal from "sweetalert";

import * as Action from "../Actions";
import * as Client from "../../Services/Client";

import HelperFns from "../../Helpers/HelperFns";

import Store from "..";

export const getCheckInsReducer = (state) => state.checkIns;
export const getSuperReducer = (state) => state.super;

export function* handleUpdateCheckInSaga({ payload }) {
  try {
    const response = yield call(Client.updateCheckIn, payload);
    if (!response?.errors) {
      if (response.data?.update_check_in_out?.__typename === "CheckIn") {
        yield put(Action.updateCheckInsSuccess("editCheckInsModalActions"));
        yield put(
          Action.dismissEditCheckInsModalAction({
            ref: "editCheckInsModalActions",
            formName: "editCheckInsModal",
          })
        );
        yield put(Action.showSuccessToast());
        let superReducer = yield select(getSuperReducer);
        yield put(
          Action.fetchAttendanceLogsAction({
            ref: "attendanceLogsList",
            attendanceLogsList: superReducer["attendanceLogsList"],
          })
        );
        return;
      }
      // to handle invalid location alert
      if (
        response?.data?.update_check_in_out?.__typename ===
        "InvalidLocationException"
      ) {
        swal({
          text: HelperFns.localizeFns(
            "The check-in coordinates do not match the location selected, Are you sure you want to continue?"
          ),
          icon: "warning",
          className: "swal-warning-style",
          buttons: {
            defeat: {
              text: HelperFns.localizeFns("No"),
              value: false,
            },
            catch: {
              text: HelperFns.localizeFns("Yes"),
              value: true,
            },
          },
        }).then((value) => {
          if (value) {
            Store.dispatch(
              Action.updateCheckInsAction({
                data: {
                  ...payload,
                  validate_against_check_in_location: false,
                  validate_against_check_out_location: false,
                },
              })
            );
          } else {
            Store.dispatch(
              Action.updateCheckInsFailed({
                ref: "editCheckInsModalActions",
                serverRef: "editCheckInsModalServerValidation",
                errors: {},
                modalMessage: HelperFns.localizeFns(
                  "Outside Specified Location"
                ),
              })
            );
          }
        });
        return;
      }
      yield put(
        Action.updateCheckInsFailed({
          ref: "editCheckInsModalActions",
          serverRef: "editCheckInsModalServerValidation",
          errors: {},
          modalMessage:
            response?.data?.update_check_in_out?.message ??
            HelperFns.localizeFns("something went wrong"),
        })
      );
      return;
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        Action.updateCheckInsFailed({
          ref: "editCheckInsModalActions",
          serverRef: "editCheckInsModalServerValidation",
          errors: HelperFns.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        Action.updateCheckInsFailed({
          ref: "editCheckInsModalActions",
          serverRef: "editCheckInsModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            HelperFns.localizeFns("something went wrong"),
        })
      );
    }
  } catch (error) {
    yield put(
      Action.updateCheckInsFailed({
        ref: "editCheckInsModalActions",
        serverRef: "editCheckInsModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpdateCheckIns() {
  yield takeLatest(Types.UPDATE_CHECK_INS_ATTEMPT, handleUpdateCheckInSaga);
}

export function* handleFetchcheckInOutLocationsOptionsSaga({ payload }) {
  try {
    const response = yield call(Client.fetchCheckInOutLocations, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchCheckInOutsLocationsOptionsSuccess(
          response?.data?.profile?.company
        )
      );
    } else {
      yield put(Action.fetchCheckInOutsLocationsOptionsFailed());
      yield put(
        Action.showErrorToast(
          response.errors[0]?.extensions?.reason || response.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchCheckInOutsLocationsOptionsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchcheckInOutLocationsOptions() {
  yield takeLatest(
    Types.FETCH_CHECK_OUT_LOCATIONS_OPTIONS,
    handleFetchcheckInOutLocationsOptionsSaga
  );
}

export default {
  onUpdateCheckIns,
  onFetchcheckInOutLocationsOptions,
};
