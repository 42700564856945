import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { noSignInOutTimeStatuses } from "../Constants/Requests";

import { LocationOn } from "@mui/icons-material";
import { AccessTime } from "@mui/icons-material";

const RenderMultipleSignIns = ({
  signIn,
  index,
  signInTime,
  showLocations = true,
  showActiveSignInColor = true,
}) => {
  const { t } = useTranslation();

  const checkIfSignInIsActive = (signIn) => {
    return signIn === signInTime && showActiveSignInColor;
  };

  if (signIn?.status === "absent" && !signIn?.sign_in_time) {
    return (
      <p className="emp-multiple-sign-ins-absent-status">{t(signIn?.status)}</p>
    );
  } else {
    return (
      <div className="mutliple-sign-in-container">
        <>
          <p
            className={
              signIn?.status?.toLowerCase() === "absent"
                ? "emp-multiple-sign-ins-absent-status"
                : checkIfSignInIsActive(signIn?.sign_in_time)
                ? "sign-in-title sign-in-title-active"
                : "sign-in-title"
            }
          >
            {t("emp-multi-sign-in-index", {
              index: index + 1,
              suffix:
                index === 0
                  ? "st"
                  : index === 1
                  ? "nd"
                  : index === 2
                  ? "rd"
                  : "th",
              order:
                index === 0
                  ? t("1st")
                  : index === 1
                  ? t("2nd")
                  : index === 2
                  ? t("3rd")
                  : null,
            })}
            {signIn?.status ? ` - ${t(signIn?.status?.toLowerCase())}` : null}
          </p>
          {!noSignInOutTimeStatuses.includes(signIn?.status?.toLowerCase()) ? (
            <>
              <div className="d-flex align-items-center justify-content-between m-0">
                <p
                  className={
                    checkIfSignInIsActive(signIn?.sign_in_time)
                      ? "multiple-sign-signs-text multiple-sign-signs-text-active"
                      : "multiple-sign-signs-text"
                  }
                >
                  {signIn.sign_in_time
                    ? moment(signIn.sign_in_time).format("hh:mm:ss a")
                    : "--:--:--"}
                </p>
                <p
                  className={
                    checkIfSignInIsActive(signIn?.sign_in_time)
                      ? "multiple-sign-signs-text multiple-sign-signs-text-active"
                      : "multiple-sign-signs-text"
                  }
                >
                  {signIn.sign_out_time
                    ? moment(signIn.sign_out_time).format("hh:mm:ss a")
                    : "--:--:--"}
                </p>
              </div>
              {showLocations ? (
                <div className="d-flex align-items-center justify-content-between m-0">
                  <p className="multiple-sign-signs-text">
                    <LocationOn className="sign-ins-tooltip-icons" />
                    {signIn.sign_in_location_name
                      ? signIn.sign_in_location_name
                      : "........"}
                  </p>
                  <p className="multiple-sign-signs-text">
                    <LocationOn className="sign-ins-tooltip-icons" />
                    {signIn.sign_out_location_name
                      ? signIn.sign_out_location_name
                      : "........"}
                  </p>
                </div>
              ) : null}

              <p>
                <AccessTime className="employee-card-work-timing-icon" />
                {signIn?.work_timing_name}
              </p>
            </>
          ) : null}
        </>
      </div>
    );
  }
};

export default RenderMultipleSignIns;
