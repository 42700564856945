import { SHOW_TOAST, HIDE_TOAST } from "../Actions/Feedback/types";

const initState = {
  toast: {
    msg: "",
    type: "",
    duration: 0,
    isOpen: false,
  },
};

const feedbackReducer = (state = initState, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        toast: {
          isOpen: true,
          ...action.payload,
        },
      };
    case HIDE_TOAST:
      return {
        ...state,
        toast: initState.toast,
      };
    default:
      return state;
  }
};

export default feedbackReducer;
