import React from "react";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { showToast } from "../../Helpers/HelperFns";
import { wizardWorkGroupQuery } from "../../Graphql/query";

import Loader from "../../Components/Loader";
import { WorkGroupModal } from "../../Components/WorkGroupModal";

const SetupWizardWorkGroup = () => {
  const { t } = useTranslation();

  // Local State
  const [isLoading, setIsLoading] = React.useState(true);
  const [workGroupId, setWorkGroupId] = React.useState("");

  // Reducer State

  // Server State
  const [getWorkGroup] = useLazyQuery(wizardWorkGroupQuery);

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    getWorkGroup({
      onCompleted: ({ workGroups }) => {
        workGroups?.data?.length && setWorkGroupId(workGroups?.data[0]?.id);
        setIsLoading(false);
      },
      onError: (err) => {
        setIsLoading(false);
        showToast("error", err?.message);
      },
    });
  }, []);

  return (
    <section className="setup-wizard-step">
      <h2 className="setup-wizard-title mb-4">{t("create your work group")}</h2>

      {isLoading ? (
        <Loader fixed />
      ) : (
        <WorkGroupModal
          isSetupWizard
          workGroupId={workGroupId}
          setWorkGroupId={setWorkGroupId}
        />
      )}
    </section>
  );
};

export default SetupWizardWorkGroup;
