import React, { memo } from "react";
import AssignmentCard from "./AssignmentCard";
import { useSelector } from "react-redux";
import moment from "moment";
import HelperFns from "../../Helpers/HelperFns";

const handleAssignmentsView = (assignments) => {
  let assignmentsBeforeSet = [];
  assignments?.forEach((assignment) => {
    assignment.assignments.forEach((childAssignment) => {
      assignmentsBeforeSet.push(childAssignment);
    });
  });
  let uniqueStrinfigiedAssignments = new Set(
    assignmentsBeforeSet.map((childAssignment) =>
      JSON.stringify(childAssignment)
    )
  );
  let uniqueAssignments = [...uniqueStrinfigiedAssignments]?.map(
    (childAssignment) => JSON.parse(childAssignment)
  );

  uniqueAssignments.sort((a, b) => {
    return (
      moment(b.to).diff(moment(b.from), "days") -
      moment(a.to).diff(moment(a.from), "days")
    );
  });

  return uniqueAssignments;
};

const RenderAssignments = ({ assignments = [], handleDelete, handleEdit }) => {
  let rows = [[1, 2, 3, 4, 5, 6, 7]];

  const { from: assignmentFrom } = useSelector(
    (state) => state.assignments.assignmentFilters
  );

  return handleAssignmentsView(assignments).map((assignment, idx) => {
    let firstDayOfWeek = HelperFns.getDayOfTheWeek(
      assignmentFrom,
      "first",
      "YYYY-MM-DD"
    );
    let { from, to } = assignment;
    let dateDiff;
    let fromColumn;
    let toColumn;
    let requiredColumns = [];

    // grid area
    let rowStart;
    let rowEnd;
    let columnStart;
    let columnEnd;

    // checking if assignment's start date is before the start of current week
    if (new Date(from).getTime() < new Date(firstDayOfWeek).getTime()) {
      dateDiff = moment(to).diff(moment(firstDayOfWeek), "days");
      fromColumn = 1;
    } else {
      dateDiff = moment(to).diff(moment(from), "days");
      fromColumn = moment(from, "YYYY-MM-DD").isoWeekday();
    }

    toColumn = fromColumn + dateDiff > 7 ? 7 : fromColumn + dateDiff;

    for (let i = fromColumn; i <= toColumn; i++) {
      requiredColumns.push(i);
    }

    let noAvailableRows = true;

    for (let i = 0; i < rows.length; i++) {
      if (
        requiredColumns?.every((requiredCol) => rows[i].includes(requiredCol))
      ) {
        rowStart = i + 1;
        rowEnd = rowStart + 1;
        requiredColumns.forEach((requiredCol) => {
          rows[i].splice(rows[i].indexOf(requiredCol), 1);
        });
        noAvailableRows = false;
        break;
      } else {
        continue;
      }
    }

    if (noAvailableRows) {
      let newRow = [1, 2, 3, 4, 5, 6, 7];
      requiredColumns.forEach((requiredCol) => {
        newRow.splice(newRow.indexOf(requiredCol), 1);
      });
      rows.push(newRow);
      rowStart = rows.length;
      rowEnd = rowStart + 1;
    }

    columnStart = fromColumn;
    columnEnd = toColumn + 1;

    return (
      <AssignmentCard
        key={idx}
        numberOfColumns={requiredColumns?.length}
        assignment={assignment}
        handleDelete={() => handleDelete(assignment?.id)}
        handleEdit={() => handleEdit(assignment?.id, assignment?.change_shift)}
        gridStyle={{
          gridArea: `${rowStart} / ${columnStart} / ${rowEnd} / ${columnEnd}`,
        }}
      />
    );
  });
};

const arePropsEqual = (oldProps, newProps) => {
  return (
    JSON.stringify(oldProps?.assignments) ===
    JSON.stringify(newProps?.assignments)
  );
};

export default memo(RenderAssignments, arePropsEqual);
