import React from "react";
import MainModal from "../MainModal";
import { connect } from "react-redux";
import { Config } from "../../Config";
import moment from "moment";
import { dismissViewCheckInsOutsLocationModalAction } from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";

const formName = "viewCheckInOutLocationModal";

const ViewCheckInsOutsLocationMap = (props) => {
  const { t } = useTranslation();

  const toggleModal = () => {
    props.dismissViewCheckInsOutsLocationModalAction({
      ref: "viewCheckInOutLocationModalActions",
      formName,
    });
  };

  return (
    <MainModal
      isOpen={props?.modalData?.isVissible}
      toggle={toggleModal}
      modalTitle={
        props?.viewCheckInOutLocationModal?.signinOutLocationsModal
          ? props?.viewCheckInOutLocationModal?.type === "sign_in"
            ? t("sign in location")
            : t("sign out location")
          : props?.viewCheckInOutLocationModal?.checkType === "check_in"
          ? t("check-in location")
          : t("check-out location")
      }
      btnSubmitLoading={props?.modalData?.isLoading}
      className="edit_check_in_form_wrapper_style"
    >
      <div>
        <div className="row mb-2">
          <div className="col-12">
            <span className="mr-2 sec-color">
              {props?.viewCheckInOutLocationModal?.name}
            </span>
            <span style={{ color: "#5d6979" }}>
              {moment(props?.viewCheckInOutLocationModal?.date).format(
                "dddd DD-MM-YYYY"
              )}
            </span>
          </div>
        </div>

        <div className="row my-2">
          <div className="d-flex col-12 align-items-baseline">
            <label className="my-2">
              {props?.viewCheckInOutLocationModal?.signinOutLocationsModal
                ? props?.viewCheckInOutLocationModal?.type === "sign_in"
                  ? t("sign in location")
                  : t("sign out location")
                : props?.viewCheckInOutLocationModal?.checkType === "check_in"
                ? t("check-in location")
                : t("check-out location")}
            </label>

            <div
              className="check_in_description_style mx-3"
              style={{ color: "#5d6979" }}
            >
              {!props?.viewCheckInOutLocationModal?.location_name
                ? t("unspecified location")
                : props?.viewCheckInOutLocationModal?.location_name}
            </div>
          </div>
        </div>

        <div className="row">
          <div className={"col-12 mt-2"}>
            <div style={{ height: "250px" }}>
              <Avatar
                src={props?.viewCheckInOutLocationModal?.face}
                sx={{ width: 100, height: 100, right: "/* @noflip */ 0", top: 0 }}
                className="avatar blackish-avatar position-absolute mt-2 mx-4"
              />

              <iframe
                width="100%"
                height="auto"
                style={{ border: 0, width: "100%", height: "100%" }}
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=${Config.googleMapKey}&q=${props?.viewCheckInOutLocationModal?.lat},${props?.viewCheckInOutLocationModal?.lng}`}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  data: state.checkIns,
  modalData: state.checkIns.viewCheckInOutLocationModalActions,
  viewCheckInOutLocationModal: state.checkIns[formName],
});

export default connect(mapStateToProps, {
  dismissViewCheckInsOutsLocationModalAction,
})(ViewCheckInsOutsLocationMap);
