import React from "react";
import { Link } from "react-router-dom";

const ManagerDashboardAlertContinueSetupWizard = () => {
  return (
    <div className="manager-dashboard-alert-company-wizard-container d-flex justify-content-center align-items-center">
      <div className="dashboard-alert d-flex flex-column justify-content-center align-items-center">
        <span>
          To Be Able To View The Dashboard You Will Need To Finish Setting Up
          Your Company
        </span>
        <Link to="/setup-wizard">
          <div className="alert-setup-wizard-btn d-flex align-items-center justify-content-center">
            Setup Wizard
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ManagerDashboardAlertContinueSetupWizard;
