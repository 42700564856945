import { call, all } from "redux-saga/effects";

import AuthSaga from "./AuthSaga";
import UserSaga from "./UserSaga";
import SuperSaga from "./SuperSaga";
import CompanyWizardSaga from "./CompanyWizardSaga";
import DocumentsSaga from "./DocumentsSaga";
import SalaryConfigSaga from "./SalaryConfigSaga";
import FinancesSaga from "./FinancesSaga";
import AdminSaga from "./AdminSaga";
import RecruitmentSaga from "./RecruitmentSaga";
import CheckInsSaga from "./CheckInsSaga";

export default function* rootSaga() {
  yield all([
    call(AuthSaga.onLogingInAttempt),

    call(UserSaga.onCancelUserRequest),
    call(UserSaga.onFetchingAttendaceRecords),
    call(UserSaga.onCreatetSignInSaga),
    call(UserSaga.onGetSignOutInfoSaga),
    call(UserSaga.onGetSignInInfoSaga),
    call(UserSaga.onDismissHomeSignInSaga),
    call(UserSaga.onCreateSignOutSaga),
    call(UserSaga.onRefreshSignInOutSaga),
    call(UserSaga.onUpsertUserLocaleSaga),

    call(UserSaga.onUpsertCustomRequest),

    call(SuperSaga.onEditAttProfile),
    call(SuperSaga.onEditEmployee),
    call(SuperSaga.onInitEmployee),
    call(SuperSaga.onUpdateEmployee),

    call(SuperSaga.onFetchAttProfilesList),
    call(SuperSaga.onCreatreAttProfile),

    call(SuperSaga.onFetchAllEmployeesList),

    call(SuperSaga.onFetchAllOfices),
    call(SuperSaga.onFetchAllDepartments),
    call(SuperSaga.onFetchAllPositions),
    call(SuperSaga.onFetchInboundRequests),
    call(SuperSaga.onAcceptInboundRequest),
    call(SuperSaga.onRejectInboundRequest),
    call(UserSaga.OnHandleNormalRequestSaga),
    call(UserSaga.OnHandleUnpaidRequestSaga),
    call(UserSaga.OnHandleEmergencyRequestSaga),
    call(UserSaga.OnHandlePermissionRequestSaga),
    call(UserSaga.OnHandleHalfdayRequestSaga),
    call(UserSaga.OnHandleHomeExchangeRequestSaga),
    call(UserSaga.OnHandleHomeFlexRequestSaga),
    call(UserSaga.onHandleFetchHistoryListSaga),
    call(UserSaga.onUpsertSickRequest),
    call(SuperSaga.onFetchAttendanceLogs),
    call(SuperSaga.onFetchSuspensionLogs),
    call(SuperSaga.onFetchBalanceLogs),
    call(SuperSaga.onHandleAdjustSignOut),
    call(SuperSaga.onUpdateSuspension),
    call(SuperSaga.onFetchOvertimeLogs),
    call(SuperSaga.onUpdateDepartment),
    call(SuperSaga.onUpdatePosition),
    call(SuperSaga.onDeleteSuspension),
    call(SuperSaga.onDeleteUser),
    call(SuperSaga.onActivateUser),
    call(SuperSaga.onAcceptOvertimeRequest),
    call(SuperSaga.onEditHistoryModal),
    call(SuperSaga.onDeleteDepartment),
    call(SuperSaga.onDeletePosition),
    call(SuperSaga.onCreateDepartment),
    call(SuperSaga.onCreatePosition),
    call(SuperSaga.onInitBalanceModal),
    call(SuperSaga.onUpdateOffice),
    call(SuperSaga.onDeleteOffice),
    call(SuperSaga.onUpsertAttendanceProfile),
    call(SuperSaga.onFetchOfficeHolidays),
    call(SuperSaga.onDeleteAttProfile),
    call(SuperSaga.onCloneAttProfile),
    call(UserSaga.onForgotPasswordSaga),
    call(UserSaga.onResetPasswordSaga),
    call(SuperSaga.onFetchDashboard),
    call(SuperSaga.onFetchOffice),
    call(SuperSaga.onFetchAttendanceChart),
    call(SuperSaga.onFetchAttendanceChartEmployeesName),
    call(SuperSaga.onFetchDepartmentsChart),
    call(SuperSaga.onFetchOvertimeChart),
    call(SuperSaga.onFetchDashboardUsers),
    call(SuperSaga.onFetchUsersClaims),
    call(SuperSaga.onFetchUsersBonus),
    call(SuperSaga.onFetchUsersDeductions),
    call(SuperSaga.onUpsertBonus),
    call(SuperSaga.onDeleteBonus),
    call(SuperSaga.onUpsertDeduction),
    call(SuperSaga.onDeleteDeduction),
    call(SuperSaga.onEditEmployeePrivileges),
    call(SuperSaga.onUpsertEmployeePrivileges),
    call(SuperSaga.onFetchPayrollReport),
    call(SuperSaga.onFetchMonthlyReport),
    call(SuperSaga.onFetchYearlyReport),
    call(SuperSaga.onFetchUsersSalaryConfig),
    call(UserSaga.onUpsertClaimsRequest),
    call(SuperSaga.onUpsertEmployeeClaimsRequest),
    call(SuperSaga.onRejectClaimRequest),
    call(SuperSaga.onAcceptClaimRequest),
    call(UserSaga.onCancelClaimRequest),
    call(SuperSaga.oneditPayrollDeductions),
    call(SuperSaga.onSubmitPayrollPaymentSaga),
    call(SuperSaga.onReversePayrollPaymentSaga),
    call(SuperSaga.onFetchDeductionCalculatedAmountSaga),
    call(UserSaga.onFetchMyPayroll),
    call(SuperSaga.onFetchUsersPayslip),
    call(SuperSaga.onExportPayslipSaga),
    call(SalaryConfigSaga.onCreateNewAllowance),
    call(SalaryConfigSaga.onCreateNewDeductible),
    call(SalaryConfigSaga.onCreateNewEmployeeSalary),
    call(SalaryConfigSaga.onInitEmployeeSalaryInfo),
    call(SalaryConfigSaga.onUpsertSalaryConfig),
    call(SalaryConfigSaga.onInitActivateEmployeeSalaryInfo),
    call(SuperSaga.onFetchAllHolidays),
    call(SuperSaga.onCreateHolidays),
    call(SuperSaga.onDeleteHolidays),
    call(SuperSaga.onInitOffice),

    call(SuperSaga.onFetchExportPayrollOpts),
    call(SuperSaga.onSubmitExportPayroll),

    call(SuperSaga.onFetchAllCompanies),
    call(SuperSaga.onDeleteCompanies),
    call(SuperSaga.onActiviateCompanies),
    call(SuperSaga.onSuspendCompanies),
    call(SuperSaga.onUpsertCompany),
    call(SuperSaga.onFetchCompanyProfile),
    call(SuperSaga.onFetchCompanyProfileSubscriptions),
    call(SuperSaga.onUpdateCompanyName),
    call(SuperSaga.onUpdateCompanyAccountManagers),
    call(SuperSaga.onUpdateCompanyBussinessPartners),
    call(SuperSaga.onDeleteCompanyBussinessPartners),
    call(SuperSaga.onUpsertCompanySubscription),
    call(SuperSaga.onDeleteCompanySubscription),
    call(SuperSaga.onUpdateCompany),
    call(SuperSaga.onFetchAdminUsers),
    call(SuperSaga.onCreateAdminUser),
    call(SuperSaga.onDeleteAdminUsers),
    call(SuperSaga.onFetchAdminUsersCompanies),
    call(SuperSaga.onFetchAdminUsersEmployees),
    call(SuperSaga.onEditAdminUserPrivileges),
    call(SuperSaga.onUpsertAdminUserPrivileges),
    call(SuperSaga.onUpdateAdminUserProfile),
    call(UserSaga.onEditAttRequestModal),
    call(UserSaga.onViewAttRequestModal),
    call(SuperSaga.onFetchWorkTimingsList),
    call(SuperSaga.onUpsertWorkTimings),
    call(SuperSaga.onExportMonthlyReportsaga),
    call(SuperSaga.onExportYearlyReport),

    call(AuthSaga.onVerifyUserPhoneSaga),
    call(AuthSaga.onVerifyUserEmailSaga),
    call(AuthSaga.onSendUserPhoneVerificationSaga),
    call(AuthSaga.onSendUserEmailVerificationSaga),
    call(SuperSaga.onFetchActivationAttendance),
    call(SuperSaga.onEditFetchWorkTime),
    call(SuperSaga.onViewFetchWorkTime),

    call(SalaryConfigSaga.onFecthPresetSalary),
    call(DocumentsSaga.onUpsertDocument),
    call(DocumentsSaga.onfetchCompanyDocsList),
    call(DocumentsSaga.onStoreDocumentable),
    call(DocumentsSaga.onStoreDocumentableRow),
    call(DocumentsSaga.onFetchRequiredDocumentsSaga),
    call(SuperSaga.onFetchEmailNotificationsSettings),
    call(SuperSaga.onUpdateEmailNotificationsSettings),

    call(SuperSaga.onCloneWorkTime),
    call(SuperSaga.onDeleteWorkTime),
    call(FinancesSaga.onFetchPettyCashList),
    call(FinancesSaga.onUpsertPettyCashBalance),
    call(FinancesSaga.onFetchEmployeeCurrentBalance),
    call(FinancesSaga.onFetchCompanySettingsList),
    call(FinancesSaga.onUpsertCompanySetings),
    call(FinancesSaga.onDeleteCompanySettings),
    call(FinancesSaga.onFetchCompanyExpensesList),
    call(FinancesSaga.onUpsertCompanyExpense),
    call(FinancesSaga.onFetchCompanyExpenseSubCatg),
    call(FinancesSaga.onFetchCompanyExpenseTotalBalance),
    call(FinancesSaga.onExportCompanyExpenses),
    call(FinancesSaga.onFetchExpenseFormSubCatgories),
    call(FinancesSaga.onFetchExpenseFormCurrency),
    call(FinancesSaga.onFetchExpenseFormEmployees),
    call(FinancesSaga.onDeleteCompanyExpense),
    call(UserSaga.onFetchUserPettyCashList),
    call(UserSaga.onFetchUserProfileCurrentBalance),
    call(FinancesSaga.onFetchUserProfilePettyCashCurrencies),
    call(FinancesSaga.onFetchEmployeeCurrenciesInModifyBalance),
    call(FinancesSaga.onDeleteCompanyExpenseAttachmentFile),
    call(FinancesSaga.onDeletePettyCashAttachmentFile),
    call(SuperSaga.onInitSuspension),
    call(SuperSaga.onResendCredentails),

    call(SuperSaga.onCheckPasswordUser),
    call(SuperSaga.onFetchInitDeleteManager),
    call(SuperSaga.onFetchInitSuspendManager),
    call(SuperSaga.onFetchReplaceByManagersOpts),
    call(SuperSaga.onFetchEmployeeDocuments),
    call(SuperSaga.onAddEmployeeDocument),
    call(SuperSaga.onfetchUpsertDocModal),
    call(DocumentsSaga.onfetchHistoryDocModal),
    call(SuperSaga.onDeleteUserDocumentAttachment),
    call(SuperSaga.onEditCompanyBussinessPartner),
    call(SuperSaga.onExportEmployeeList),
    call(SuperSaga.onFetchRequestRulesList),
    call(SuperSaga.onFetchRequestRulesOpts),
    call(SuperSaga.onUpsertRequestRules),
    call(SuperSaga.onDeleteRequestRules),

    call(AdminSaga.onFetchSystemPlansListSaga),
    call(AdminSaga.onFetchSystemPrivilegesSaga),
    call(AdminSaga.onUpsertSystemPlanSaga),
    call(AdminSaga.onUpsertRoleSaga),
    call(AdminSaga.onFetchPlanRolesSaga),
    call(AdminSaga.onEditSystemPlanSaga),
    call(AdminSaga.onFetchPlanPageSaga),
    call(AdminSaga.onUpdatePlanStatusSaga),
    call(AdminSaga.onDeletePlanSaga),
    call(AdminSaga.onDeleteRoleSaga),
    call(AdminSaga.onFetchEditRoleSaga),
    call(AdminSaga.onFetchAdminHolidays),
    call(AdminSaga.onUpsertAdminHoliday),
    call(AdminSaga.onDeleteAdminHolidays),
    call(SuperSaga.onFetchEditAcceptedOvertimeRequestData),

    call(SuperSaga.onFetchOpenPayrollMonths),
    call(SuperSaga.onDownloadEmplyeeTemplete),
    call(SuperSaga.onUploadEmployeeBulk),
    call(SuperSaga.onFetchEditAcceptedOvertimeRequestData),

    call(CompanyWizardSaga.onAddDepartments),
    call(CompanyWizardSaga.onAddPositions),
    call(CompanyWizardSaga.onUpdateWizardOffice),
    call(CompanyWizardSaga.onFetchSetupWizardEssentialData),
    call(CompanyWizardSaga.onUpsertSetupWizardWorkTimings),
    call(CompanyWizardSaga.onFetchSetupWizardDataInAuth),
    call(CompanyWizardSaga.onEditSetupWizard),

    call(SuperSaga.onFetchRolePresetPrivileges),
    call(SuperSaga.onFetchPreviewRolePrivilegesModalData),
    call(SuperSaga.onFetchTotalExpenses),
    call(SuperSaga.onFetchSubExpenses),
    call(SuperSaga.onFetchEmployeesTurnoverChart),
    call(SuperSaga.onFetchRequestCommentsModalData),
    call(SuperSaga.onUpsertRequestComments),

    call(RecruitmentSaga.onFetchRecruitmentProcesses),
    call(RecruitmentSaga.onDeleteRecruitmentProcess),
    call(RecruitmentSaga.onUpdateRecruitmentProcessStatus),
    call(RecruitmentSaga.onFetchIntakeForms),
    call(RecruitmentSaga.onDeleteIntakeForm),
    call(RecruitmentSaga.onUpdateIntakeFormStatus),
    call(RecruitmentSaga.onFetchJobPosts),
    call(RecruitmentSaga.onDeleteJobPosts),
    call(RecruitmentSaga.onUpdateJobPostsStatus),
    call(RecruitmentSaga.onFetchApplications),
    call(RecruitmentSaga.onUpsertRecruitmentProcess),

    call(RecruitmentSaga.onInitRecruitmentProcessForm),
    call(RecruitmentSaga.onInitRecruitmentIntakeForm),
    call(RecruitmentSaga.onUpsertRecruitmentIntake),
    call(RecruitmentSaga.onUpdateApplicationStageStatus),
    call(RecruitmentSaga.onFetchApplicants),
    call(RecruitmentSaga.onDeleteApplicants),
    call(RecruitmentSaga.onUpsertApplication),
    call(RecruitmentSaga.onUpsertJobPost),
    call(RecruitmentSaga.onFetchJobPostFormProcessesAndIntakeFormsOptions),
    call(RecruitmentSaga.onFetchApplicantProfile),
    call(RecruitmentSaga.onUpdateApplicantEmail),
    call(RecruitmentSaga.onCreateApplicantPhoneNumber),
    call(RecruitmentSaga.onUpdateApplicantPhoneNumber),
    call(RecruitmentSaga.onDeleteApplicantPhoneNumber),
    call(RecruitmentSaga.onFetchJobPostIntakeForm),
    call(RecruitmentSaga.onFillStageForm),
    call(RecruitmentSaga.onCloneRecruitmentProcess),
    call(RecruitmentSaga.onCloneIntakeForm),
    call(SuperSaga.onFetchHalfWorkTimingTemplate),
    call(SuperSaga.onAutoSuggestHolidays),
    call(SuperSaga.onAcceptSuggestedHolidays),
    call(SuperSaga.onDeleteSuggestedHolidays),
    call(SuperSaga.onDeleteSalaryConfig),
    call(SuperSaga.onFetchEditAttendanceWorkTimingOptions),
    call(SuperSaga.onFetchEditAttendanceLocationsOptions),
    call(SuperSaga.onUpdateAttendnaceLogsLocationsIds),
    call(SuperSaga.onHandleFetchingAssignmentFormInputs),
    call(SuperSaga.onFetchAssignmentsWatcher),
    call(SuperSaga.onHandleDeleteingAssignmentWatcher),

    call(AdminSaga.onGenertaDemoSaga),

    call(CheckInsSaga.onUpdateCheckIns),
    call(CheckInsSaga.onFetchcheckInOutLocationsOptions),
    call(SuperSaga.onFetchAccessLevelsWatcher),
    call(SuperSaga.onUpsertRoleToCompanyWatcher),
    call(SuperSaga.onEditRolePrivilegesWatcher),
    call(SuperSaga.onDeleteCompnayRoleWatcher),
    call(SuperSaga.onFetchPlanPrivilegesSaga),
    call(SuperSaga.onGetNotificationHistorySaga),
    call(UserSaga.onCheckAllNotificationsSaga),
  ]);
}
