import React from "react";
import { useTranslation } from "react-i18next";

import { Section, Unit } from "./SectionBase";

const OvertimeSection = (props) => {
  const { t } = useTranslation();

  return (
    <Section
      title="overtime"
      units={[
        <Unit
          colStyles="col-12"
          label="Calculate Overtime After calculated sign out time by"
          val={props?.min_overtime_cuttoff + " " + t("Minutes")}
        />,
        // <Unit
        //   colStyles="col-12 col-lg"
        //   label="max. overtime hours per month"
        //   val={props?.max_overtime_per_month + " " + t("Hours")}
        // />,
        <Unit
          colStyles="col-12 col-lg"
          label="max. overtime hours per day"
          val={props?.max_overtime_per_day + " " + t("Hours")}
        />,
      ]}
    />
  );
};

export default OvertimeSection;
