import React, { useEffect } from "react";
import MainModal from "../../Components/MainModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { Alert, AlertTitle } from '@mui/material';
import Select from "react-select";
import { RadioboxForm } from "form-builder";
import { useMutation, useQuery } from "@apollo/client";
import { dayOffSettingModalQuery } from "../../Graphql/query";
import { changeEmployeesDayOffSettingsMutation } from "../../Graphql/mutation";

const DayOffModal = (props) => {
  const { t } = useTranslation();
  
  const initState = {
    normal_work_timing_id:"",
    weight:1.0,
    apply_compensation:0,
    compensation_type:"",
    compensation_quantity:0.0,
    treat_as_normal:false,
    allow_permission:false,
    allow_overtime:false,
    offices_ids:[],
    additional_work_places: [],
    additional_work_places_signout: [],
    workplace_setting:"DEFAULT",
    workplace_setting_signout:"DEFAULT",
    workplace_setting_identical:1,
    add_multiple:false,
    employees_ids:props.selectedEmployees
  }

  const [allowed, setAllowed] = useState(true)
  const [AllowEmployeesToRequestHalf, setAllowEmployeesToRequestHalf] = useState(false)
  const [input, setInput] = useState(initState);

  // fetching form data
  const {data, loading, error} = useQuery(
    dayOffSettingModalQuery,{
        fetchPolicy:"network-only"
    }
  )

  // submitting mutation
  const [changeEmployeesDayOffSettings, { loading: submitLoading, error:submitError }] =
  useMutation(changeEmployeesDayOffSettingsMutation, {
    onError: (err) => {
      console.log({err});
      if(err?.graphQLErrors[0]?.extensions?.validation){
        let validation = err?.graphQLErrors[0]?.extensions?.validation
        Object.keys(validation).forEach(key => {
          setValidator(prev=>(
          {...prev,
          [key]:{
            isValid:false, 
            msg:validation[key].toString()
          }}))
        })
      }
      else showToast("error", err?.graphQLErrors[0]?.extensions?.reason || err.message)
    },
    onCompleted: (res) => {
      if(res?.addDayOffExceptionConfigForEmployees?.status==="success"){
        props.resetSelection()
        props.refetch()
        props.CloseDayOffModal()
      }
      showToast(
        res?.addDayOffExceptionConfigForEmployees?.status,
        res?.addDayOffExceptionConfigForEmployees.message
      );
         
    },
  });


  const [validator, setValidator] = useState({
    "input.weight":{isValid:true, msg:""},
    "input.normal_work_timing_id":{isValid:true, msg:""},
    "input.additional_work_places":{isValid:true, msg:""},
    "input.additional_work_places_signout":{isValid:true, msg:""},
    "input.first_half_work_timing_id":{isValid:true, msg:""},
    "input.second_half_work_timing_id":{isValid:true, msg:""},
    "input.compensation_type":{isValid:true, msg:""},
    "input.compensation_quantity": {isValid:true, msg:""}
  }) ;

  const validate = () => {
    if(!allowed)return true;
    let serverValidation = true
    let clientValidation = true
    Object.values(validate).forEach(v => serverValidation = serverValidation&&v.isValid)
    if(serverValidation){
      setValidator({
          "input.weight":{isValid:(Boolean(input.weight)), msg:"This Field Is Required."},
          "input.normal_work_timing_id":{isValid:Boolean(input.normal_work_timing_id), msg:"This Field Is Required."},
          "input.additional_work_places":{isValid:input.additional_work_places?.length!==0&&Boolean(input.additional_work_places), msg:"This Field Is Required."},
          "input.additional_work_places_signout":{isValid:input.additional_work_places_signout?.length!==0&&Boolean(input.additional_work_places_signout), msg:"This Field Is Required."},
          "input.first_half_work_timing_id":{isValid:Boolean(input.first_half_work_timing_id), msg:"This Field Is Required."},
          "input.second_half_work_timing_id":{isValid:Boolean(input.second_half_work_timing_id), msg:"This Field Is Required."},
          "input.compensation_type":{isValid:Boolean(input.compensation_type), msg:"This Field Is Required."},
          "input.compensation_quantity": {isValid:Boolean(input.compensation_quantity), msg:"This field is required."}
        })
      clientValidation = (Boolean(input.normal_work_timing_id) &&  
                  (Boolean(input.weight)) && 
                  (input.apply_compensation ? Boolean(input.compensation_quantity) : true) && 
                  (input.apply_compensation ? Boolean(input.compensation_type) : true) && 
                  (input?.workplace_setting==="DEFAULT"? input.additional_work_places?.length!==0&&Boolean(input.additional_work_places) : true ) && 
                  (input.workplace_setting_identical? true : (input?.workplace_setting_signout==="DEFAULT"? Boolean(input.additional_work_places_signout) : true)) && 
                  (
                    AllowEmployeesToRequestHalf?(Boolean(input.first_half_work_timing_id) && 
                    Boolean(input.second_half_work_timing_id)) : true
                  ))
    }
    return clientValidation&&serverValidation
  }


  // resetting form data start
  useEffect(()=>{
    setInput(initState)
  },[props.changeDayOffSettings])

  //  if not allowed to request half day
  useEffect(()=>{
    let reset = {...input};
    if (reset.first_half_work_timing_id) delete reset.first_half_work_timing_id
    if(reset.second_half_work_timing_id) delete reset.second_half_work_timing_id

    setInput(reset)
  },[AllowEmployeesToRequestHalf])
  

  
  //  if sign out settings are not specific workplace set it to null
  useEffect(()=>{
    let reset = {...input};
    if (input.workplace_setting_signout!=="DEFAULT") {
       reset.additional_work_places_signout = null
    }
    setInput(reset)
  },[input.workplace_setting_signout])
  

  // if signout settings identical to signin set signout settings = signout settings
  useEffect(()=>{
    let reset = {...input};
    if (input.workplace_setting_identical) {
      reset.additional_work_places_signout = reset.additional_work_places
      reset.workplace_setting_signout = reset.workplace_setting
    }
    if (input.workplace_setting!=="DEFAULT") {
       reset.additional_work_places = null
    }
    setInput(reset)
  },[input.additional_work_places, input.workplace_setting, input.workplace_setting_identical])

  const handleSave = () => {
    if(!validate()) return
    HelperFns.checkPassword(
      "defaut_warning_messsage",
      "",
      "Confirm",
      "",
      () => submit()
    );
  };
  
  const submit = () => {
    changeEmployeesDayOffSettings({ 
        variables: {
          input:input
        }})
  };

  const handleCancle = () => {
    props.CloseDayOffModal()
  };


  const handleInputChange = (e) => {
    setInput(prev=>({...prev, [e.target.name]:(e.target.checked)}))
  };

  
  const handleIntCheckBoxChange = (e) => {
    setInput(prev=>({...prev, [e.target.name]:(e.target.checked? 1 : 0)}))
  };

  const handleRadioChange = (e,val) => {
    setInput((prevState) => {
      return { ...prevState, [e.target.name]: val };
    });
  };


  const selectVal = (val, options, multi) => { 
        if(multi) {
            return (options.filter((opt) => val?.includes(opt?.id)) || [])
        }
        return (options.find((opt) => opt?.id === val) || null)
    }

  const handleSelect = (val,e)=>{
    if (e.name==="additional_work_places"||e.name==="additional_work_places_signout") {
        setInput((prev) => ({
          ...prev,
          [e.name]: val?.map((el) => el.id),
        }));
        return
    }
    setInput(prev=>({...prev,[e.name]:val?.id? val.id : null} ))
}


  return (
    <MainModal
      isOpen={props.changeDayOffSettings}
      size="lg"
      modalTitle={t("Bulk Actions")}
      btnSubmitLoading={submitLoading}
      btnOnClick={handleSave}
      toggle={handleCancle}
      className="p-5 "
    >
      <form>
      <div className="p-2">
        <Alert severity="info">
                <AlertTitle>
                  {t("Change Day off settings")}
                </AlertTitle>
                <strong>{t("Employees will be effected", { count: props.selectedEmployees.length })}</strong>
          </Alert>
      </div>

        <div className={"p-2 pb-3 "+(validator? "" : "invalid-container-style")}>
                <RadioboxForm
                            labelStyle="label-style font-weight-bold"
                            label="allow employee to sign-in on their days off"
                            name={"workplace_setting"}
                            value={allowed}
                            validateContainerStyle={""}
                            optionInputStyle="text-black-100"
                            containerStyle="mb-3"
                            optionLabelStyle="optionLabelStyle"
                            optionsContainerStyle="d-flex gap-10"
                            options={[
                                {
                                label: t("Allow"),
                                value:true,
                                },
                                {
                                label: t("Don't Allow"),
                                value: false,
                                }]}
                        />

                {allowed && 
                    <div>
                        <div className="row mt-2">
                            <div className={"col-lg-6 "+(validator["input.normal_work_timing_id"].isValid? "" : "invalid-container-style")}>
                                <label className={"select-def-label mb-1 validity-label-style"}>
                                {t("work timing") }
                                </label>
                                <Select
                                    className={"select-def-input-containe b-select-style flex-grow-1"}
                                    classNamePrefix={"b-select-style"}
                                    value={selectVal(input.normal_work_timing_id,(data?.normal_work_timings.data || []))}
                                    onChange={handleSelect}
                                    isClearable={true}
                                    isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                                    isSearchable
                                    placeholder={t("select option")}
                                    options={data?.normal_work_timings.data || []}
                                    getOptionLabel={(opt) => opt?.name}
                                    getOptionValue={(opt) => opt?.id}
                                    isLoading={loading}
                                    name={"normal_work_timing_id"}
                                />
                                <div className="validity-msg-style text-left">
                                  { t(validator["input.normal_work_timing_id"].msg) }
                                </div>
                            </div>
                            <div className={"col-lg-6 "+(validator["input.weight"]?.isValid? "" : "invalid-container-style")}>
                                <label className={"select-def-label mb-1 validity-label-style"}>
                                {t("weight") }
                                </label>
                                    <div className={"d-flex align-items-end justify-content-start mb-md-0"}>
                                        <input
                                            dir="auto"
                                            name="weight"
                                            value={input.weight}
                                            onChange={(e)=>{
                                                setInput(prev=>({
                                                ...prev,
                                                weight:parseFloat(e.target.value)
                                            }))}}
                                            className={"border-left-0 border-right-0 border-top-0  border-bottom w-100 "+(validator["input.weight"]?.isValid? "" : " validity-input-style")+ " text-dark"}
                                            type={"number"}
                                            style={{ resize: "none" }}
                                        />
                                    </div>
                                <div className="validity-msg-style text-left">
                                  { t(validator["input.weight"]?.msg) }
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-3 pt-3">
                            <div>
                                <label className={"checkbox"}>
                                <input
                                    type="checkbox"
                                    name={"treat_as_normal"}
                                    value={input.treat_as_normal}
                                    onChange={(e) => handleInputChange(e)}
                                    checked={input.treat_as_normal}
                                    {...props}
                                    />
                                <span></span>

                                <div className={`booleanInputLabelStyle ${input.treat_as_normal?"lightActiveColor":""}`}>
                                    {t("Treat this day as a normal work day")}
                                </div>
                            </label>
                            </div>

                            <div>
                                <label className={"checkbox"}>
                                    <input
                                    type="checkbox"
                                    name={"AllowEmployeesToRequestHalf"}
                                    value={AllowEmployeesToRequestHalf}
                                    onChange={(e) => setAllowEmployeesToRequestHalf(e.target.checked)}
                                    checked={AllowEmployeesToRequestHalf}
                                    {...props}
                                    />
                                    <span></span>

                                    <div className={`booleanInputLabelStyle ${AllowEmployeesToRequestHalf?"lightActiveColor":""}`}>
                                        {t("allow employees to request half-days")}
                                    </div>
                                </label>
                            </div>
                        </div>
                        { AllowEmployeesToRequestHalf &&
                            <div className="row mt-2">
                                <div className={"col-lg-6 "+ (validator["input.first_half_work_timing_id"].isValid? "" : "invalid-container-style")}>
                                    <label className={"select-def-label mb-1 validity-label-style"}>
                                    {t("first half work timing") }
                                    </label>
                                    <Select
                                        className={"select-def-input-containe b-select-style flex-grow-1"}
                                        classNamePrefix={"b-select-style"}
                                        value={selectVal(input.first_half_work_timing_id,(data?.half_work_timings.data || []))}
                                        onChange={handleSelect}
                                        name={"first_half_work_timing_id"}
                                        isClearable={true}
                                        isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                                        isSearchable
                                        placeholder={t("select work timing")}
                                        options={data?.half_work_timings.data || []}
                                        getOptionLabel={(opt) => opt?.name}
                                        getOptionValue={(opt) => opt?.id}
                                        isLoading={loading}
                                    />
                                    <div className="validity-msg-style text-left">
                                      { t(validator["input.first_half_work_timing_id"].msg) }
                                    </div>
                                </div>
                                <div className={"col-lg-6 "+ (validator["input.second_half_work_timing_id"]?.isValid? "" : "invalid-container-style")}>
                                    <label className={"select-def-label mb-1 validity-label-style"}>
                                    {t("second half work timing") }
                                    </label>
                                    <Select
                                        className={"select-def-input-containe b-select-style flex-grow-1"}
                                        classNamePrefix={"b-select-style"}
                                        value={selectVal(input.second_half_work_timing_id,(data?.half_work_timings.data || []))}
                                        onChange={handleSelect}
                                        name={"second_half_work_timing_id"}
                                        isClearable={true}
                                        isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                                        isSearchable
                                        placeholder={t("select work timing")}
                                        options={data?.half_work_timings.data || []}
                                        getOptionLabel={(opt) => opt?.name}
                                        getOptionValue={(opt) => opt?.id}
                                        isLoading={loading}
                                    />
                                    <div className="validity-msg-style text-left">
                                      { t(validator["input.second_half_work_timing_id"]?.msg) }
                                    </div>
                                </div>
                            </div>
                        }

                        <strong className="d-block mt-4 mb-2 blue-color">
                        {t("work places")}
                        </strong>

                        <RadioboxForm
                            labelStyle="label-style font-weight-bold"
                            label="Employee can sign in From"
                            name={"workplace_setting"}
                            value={input.workplace_setting}
                            interceptChange={handleRadioChange}
                            validateContainerStyle={""}
                            optionInputStyle="text-black-100"
                            containerStyle="mb-0"
                            optionLabelStyle="optionLabelStyle"
                            optionsContainerStyle="d-flex flex-column  gap-10"
                            options={[
                                {
                                label: t("Anywhere"),
                                value:"ANY_PLACE",
                                },
                                {
                                label: t("Any work place in the company"),
                                value: "ALL_COMPANY_WORKPLACES",
                                },
                                {
                                label: t("Specific work places"),
                                value: "DEFAULT"
                                },
                            ]}
                        />

                        {  input?.workplace_setting==="DEFAULT" &&
                            <div className={"py-3 "+ (validator["input.additional_work_places"].isValid? "" : "invalid-container-style")}>
                                <label className={"select-def-label validity-label-style"}>
                                {t("Additional Workplaces") }
                                </label>
                                <Select
                                    className={"select-def-input-containe b-select-style flex-grow-1"}
                                    classNamePrefix={"b-select-style"}
                                    value={selectVal(input.additional_work_places,(data?.locations_menu.data || []), true)}
                                    onChange={handleSelect}
                                    name={"additional_work_places"}
                                    isClearable={true}
                                    isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                                    isSearchable
                                    placeholder={t("select option")}
                                    options={data?.locations_menu.data || []}
                                    getOptionLabel={(opt) => opt?.name}
                                    getOptionValue={(opt) => opt?.id}
                                    isLoading={loading}
                                    isMulti={true}
                                />
                                <div className="validity-msg-style text-left">
                                  { t(validator["input.additional_work_places"].msg) }
                                </div>
                            </div>
                        }  

                        <div className="py-3">
                            <label className={"checkbox"}>
                                <input
                                type="checkbox"
                                name={"workplace_setting_identical"}
                                value={input.workplace_setting_identical}
                                onChange={(e) => handleIntCheckBoxChange(e)}
                                checked={input.workplace_setting_identical}
                                {...props}
                                />
                                <span></span>

                                <div className={`booleanInputLabelStyle ${input.workplace_setting_identical?"lightActiveColor":""}`}>
                                    {t("Apply the same settings when signing out")}
                                </div>
                            </label>
                        </div>

                        {  !input?.workplace_setting_identical && 
                            <>
                            <RadioboxForm
                            label="Employee can sign out From"
                            labelStyle="label-style font-weight-bold"
                            value={input.workplace_setting_signout}
                            name={"workplace_setting_signout"}
                            interceptChange={handleRadioChange}
                            validateContainerStyle={""}
                            optionInputStyle="text-black-100"
                            containerStyle="mb-0"
                            optionLabelStyle="optionLabelStyle"
                            optionsContainerStyle="d-flex flex-column  gap-10"
                            options={[
                              {
                              label: t("Anywhere"),
                              value:"ANY_PLACE",
                              },
                              {
                              label: t("Any work place in the company"),
                              value: "ALL_COMPANY_WORKPLACES",
                              },
                              {
                              label: t("Specific work places"),
                              value: "DEFAULT"
                              },
                            ]}/>

                            {  input?.workplace_setting_signout==="DEFAULT" &&
                                <div className={"py-3 "+ (validator["input.additional_work_places_signout"].isValid? "" : "invalid-container-style")}>
                                    <label className={"select-def-label validity-label-style"}>
                                    {t("Additional Workplaces") }
                                    </label>
                                    <Select
                                        className={"select-def-input-containe b-select-style flex-grow-1"}
                                        classNamePrefix={"b-select-style"}
                                        value={selectVal(input.additional_work_places_signout,(data?.locations_menu.data || []), true)}
                                        onChange={handleSelect}
                                        name={"additional_work_places_signout"}
                                        isClearable={true}
                                        isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                                        isSearchable
                                        isMulti={true}
                                        placeholder={t("select option")}
                                        options={data?.locations_menu.data}
                                        getOptionLabel={(opt) => opt?.name}
                                        getOptionValue={(opt) => opt?.id}
                                        isLoading={loading}
                                    />
                                    <div className="validity-msg-style text-left">
                                      { t(validator["input.additional_work_places_signout"].msg) }
                                    </div>
                                </div>
                            }</>
                        }

                        {!input.treat_as_normal && <>
                            {/* _______  compensation start __________ */}
                            <>
                                <strong className="d-block mt-4 mb-2 blue-color">
                                    {t("compensation")}
                                </strong>
                                <label className={"checkbox"}>
                                    <input
                                    type="checkbox"
                                    name={"apply_compensation"}
                                    value={input.apply_compensation}
                                    onChange={(e) => handleIntCheckBoxChange(e)}
                                    checked={input.apply_compensation}
                                    {...props}
                                    />
                                    <span></span>

                                    <div className={`booleanInputLabelStyle ${input.apply_compensation?"lightActiveColor":""}`}>
                                        {t("compensate employee for the extra time worked")}
                                    </div>
                                </label>
                                <div className={`info-line-container${!input.apply_compensation ? " d-none" : ""}`}>
                                    <div aria-hidden="true" className="info-line">
                                    <div className="testing-top-circle" />
                                    <div className="testing-bottom-circle" />
                                    </div>
                                      <div className={`${validator["input.compensation_type"]?.isValid ? "" : " invalid-container-style"}`}>
                                          <RadioboxForm
                                            name={"compensation_type"}
                                            value={input.compensation_type}
                                            interceptChange={handleRadioChange}
                                            options={[
                                                { label: "award bonus days", value: "bonus" },
                                                { label: "award additional days", value: "leaves" },
                                            ]}
                                            containerStyle="my-1"
                                            labelStyle="label-style"
                                            optionItemStyle="mr-3"
                                            optionInputStyle=" "
                                            optionLabelStyle="optionLabelStyle"
                                            optionsContainerStyle="d-flex gap-20"
                                            dependancyValue={[1]}
                                          />
                                          <div className="validity-msg-style text-left">
                                            { t(validator["input.compensation_type"].msg) }
                                          </div>
                                        </div>
                                        <div className={(validator["input.compensation_quantity"].isValid? "":"invalid-container-style")}>
                                          <div className={"d-flex align-items-end justify-content-start mb-md-0 mr-2 "}>
                                              <label htmlFor="" className="min-w-150">
                                                {t(`${
                                                        input.compensation_type === "bonus" ? "bonus" : "leave"
                                                    } days / work day`)}
                                              </label>
                                              <input
                                                  dir="auto"
                                                  name="compensation_quantity"
                                                  value={input.compensation_quantity}
                                                  onChange={(e)=>{
                                                      setInput(prev=>({
                                                      ...prev,
                                                      compensation_quantity:parseInt(e.target.value)
                                                  }))}}
                                                  className={"ml-3 inputs-days-inputs-style border-left-0 border-right-0 border-top-0  border-bottom validity-input-style"+ " text-dark"}
                                                  type={"number"}
                                                  style={{ resize: "none" }}
                                              />
                                            </div>
                                              <div className={"validity-msg-style text-left"}>
                                                { t(validator["input.compensation_quantity"].msg) }
                                              </div>
                                        </div>
                                </div>
                            </>
                            {/* _______  compensation end __________ */}

                            <strong className="d-block mt-4 mb-2 blue-color">
                                {t("Requests")}
                            </strong>
                            <label className={"checkbox mr-3"}>
                                <input
                                type="checkbox"
                                name={"allow_permission"}
                                value={input.allow_permission}
                                onChange={(e) => handleInputChange(e)}
                                checked={input.allow_permission}
                                {...props}
                                />
                                <span></span>

                                <div className={`booleanInputLabelStyle ${input.allow_permission?"lightActiveColor":""}`}>
                                    {t("Permissions")}
                                </div>
                            </label>
                            <label className={"checkbox"}>
                                <input
                                type="checkbox"
                                name={"allow_overtime"}
                                value={input.allow_overtime}
                                onChange={(e) => handleInputChange(e)}
                                checked={input.allow_overtime}
                                {...props}
                                />
                                <span></span>

                                <div className={`booleanInputLabelStyle ${input.allow_overtime?"lightActiveColor":""}`}>
                                    {t("Overtime")}
                                </div>
                            </label>
                        </>}
                        
                    </div>
                }
        </div>
        </form>
    </MainModal>
  );
};

export default DayOffModal;
