import moment from "moment";
import { dateFormat } from "../../Constants";

export const checkIsContact = (contact) => {
  const c = contact.replace("-", "").replaceAll(" ", "");
  return !isNaN(c) || c.includes("@");
};

export const buildOption = (val = "") => ({ name: val, id: val });

export const formatOptions = (options = []) =>
  options.map((opt) => ({ name: opt, id: opt }));

export const formatSendingInputs = (inputs = {}) => {
  const formattedInput = {};

  Object.entries(inputs).forEach(([key, value]) => {
    // Date Value
    if (moment.isMoment(value)) {
      formattedInput[key] = moment(value).format(dateFormat);
    }

    // Select Value
    if (typeof value === "object") {
      if (inputs[key]?.id) {
        const val = isNaN(+value.id) ? value.id : +value.id;
        formattedInput[key] = val || undefined;
      }

      if (Array.isArray(value)) {
        if (value[0]?.id) {
          formattedInput[key] = value.map(({ id }) => (isNaN(id) ? id : +id));
        } else {
          const arr = value.filter((v) => Boolean(v));
          formattedInput[key] = arr?.length ? arr : undefined;
        }
      }
      return;
    }

    // Text Value
    const val = isNaN(+value) ? value : +value;
    formattedInput[key] = val || undefined;
  });

  return formattedInput;
};
