import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  initSalaryConfig,
  deleteSalaryConfigAction,
} from "../../Store/Actions";
import moment from "moment";
import Privilages from "../../Constants/Privilages";
import { openSwalConfirm } from "../../Helpers/HelperFns";

import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
  PreviewIconButton,
} from "../../Components/IconButtonWithTooltip";
import DataTable from "react-data-table-component";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { Visibility } from "@mui/icons-material";

const SalaryConfiguration = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Constants
  const isInternal = props?.salaryConfig.salary_management_type === "internal";
  const columns = [
    {
      grow: 0.7,
      cell: ({ start_at, end_at }) => (
        <>
          {start_at != null ? (
            <>{moment(start_at).format("Do MMM YYYY")}</>
          ) : (
            <>{t("now")}</>
          )}
          {" - "}
          {end_at != null ? (
            <>{moment(end_at).format("Do MMM YYYY")}</>
          ) : (
            <>{t("now")}</>
          )}
        </>
      ),
    },
    {
      omit: isInternal,
      cell: ({ salary_management_type }) => (
        <div className="font-weight-bold text-uppercase px-4">
          {salary_management_type === "external"
            ? t("Manage Employee Payroll Externally")
            : ""}

          {salary_management_type === "suspend" ? t("suspended") : ""}
        </div>
      ),
    },
    {
      omit: !isInternal,
      cell: ({ payment_interval }) => (
        <Cell label={t("interval")} txt={payment_interval} />
      ),
    },
    {
      omit: !isInternal,
      cell: ({ userSalaryConfigDetail = {} }) => (
        <>
          {userSalaryConfigDetail?.calculate_salary_by === "daily" ? (
            <Cell
              label={t("rate per day")}
              txt={getCost(userSalaryConfigDetail?.rate_per_day)}
            />
          ) : null}

          {userSalaryConfigDetail?.calculate_salary_by === "hourly" ? (
            <Cell
              label={t("varies")}
              txt={getCost(
                userSalaryConfigDetail?.hourlyRate.reduce(
                  (acc, val) => acc + val?.rate,
                  0
                )
              )}
            />
          ) : null}

          {userSalaryConfigDetail?.calculate_salary_by === "full_time" ||
          userSalaryConfigDetail.calculate_salary_by === "part_time" ? (
            <Cell
              label={t("base_salary")}
              txt={getCost(userSalaryConfigDetail?.base_salary)}
            />
          ) : null}
        </>
      ),
    },
    {
      omit: !isInternal,
      cell: ({ userSalaryConfigAllowances = [] }) => (
        <Cell
          label={t("allowances")}
          txt={getCost(
            userSalaryConfigAllowances.reduce((a, v) => a + +v.amount, 0)
          )}
        />
      ),
    },
    {
      omit: !isInternal,
      cell: ({ userSalaryConfigDeductibles = [] }) => (
        <Cell
          label={t("deductibles")}
          txt={getCost(
            userSalaryConfigDeductibles.reduce((a, v) => a + +v.amount, 0)
          )}
        />
      ),
    },
    {
      grow: 0.7,
      style: { display: "flex", justifyContent: "flex-end" },
      cell: ({ canEdit, canDelete, end_at }) => (
        <div className="d-flex align-items-center gap-10">
          <HasPrivileges
            allowBP
            reqireMain={[Privilages.ADD_EMPLOYEE_SALARY_CONFIGURATION]}
          >
            {canEdit ? (
              <EditIconButton onClick={handleEditSalaryConfig} />
            ) : null}

            {canDelete && end_at === null ? (
              <RemoveIconButton onClick={handleDeleteSalaryConfig} />
            ) : null}
          </HasPrivileges>

          {isInternal ? (
            <IconButtonWithTooltip
              label="Preview"
              icon={<Visibility />}
              onClick={() =>
                props?.handleShowSalaryConfigModal(props.salaryConfig)
              }
            />
          ) : null}
        </div>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const getCost = (cost) => {
    return (
      cost + " " + t(props?.salaryConfig.userSalaryConfigDetail?.currency?.name)
    );
  };

  const userCountryId = useSelector(
    (state) => state?.super?.selectedEmployeeProfile?.office?.country?.id
  );

  // handle edit salary config
  const handleEditSalaryConfig = () => {
    dispatch(
      initSalaryConfig(
        props?.office_id,
        false,
        props?.salaryConfig,
        userCountryId
      )
    );
  };

  // handle delete salary config
  const handleDeleteSalaryConfig = () => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        dispatch(
          deleteSalaryConfigAction(props?.salaryConfig?.id, props?.userId)
        );
      }
    });
  };

  return (
    <div className="card-container my-2 px-1">
      <DataTable
        noHeader
        noTableHead
        columns={columns}
        data={[props?.salaryConfig]}
      />
    </div>
  );
};

const Cell = ({ label, txt }) => (
  <b className="d-flex gap-10">
    <span className="gray-color">{label}</span>
    {txt}
  </b>
);

export default SalaryConfiguration;
