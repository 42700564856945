import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import { connect } from "react-redux";
import Helper from "../../Helpers/HelperFns";
import Skeleton from "@mui/material/Skeleton";
import { BSelect } from "form-builder";
import { useTranslation } from "react-i18next";
import Constants from "../../Constants";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { overtimeChartQuery } from "../../Graphql/query";
import { BarchartPlaceholder } from "../../LoadingSection/Dashboard";

let Bar;
const OvertimeChart = (props) => {
  const [mQuery, setMQuery] = useState(window.innerWidth);
  const { t } = useTranslation();

  const [offices, setOffices] = useState([])
  const [filter, setFilter] = useState({
    month: moment().format("MM"),
    year: moment().format("YYYY"),
    office_id: null
  });


  const {
    data,
    loading,
  } = useQuery(
    overtimeChartQuery, {
    variables: {
      month: filter.month,
      year: +filter.year,
      office_id: filter?.office_id ? filter.office_id : "",
      firstRender: offices.length === 0
    },
    fetchPolicy: "network-only",
  });


  useEffect(() => {
    if (data?.company_offices) {
      setOffices(data.company_offices.data)
    }
  }, [data])

  useEffect(() => {
    window.addEventListener("resize", handleWindowChange);
    // this is the cleanup function to remove the listener
    return () => window.removeEventListener("resize", handleWindowChange);
  }, []);

  const handleWindowChange = (query) => {
    setMQuery(query.currentTarget.innerWidth);
  };

  useEffect(() => {
    // start of rounded bar charts draw function
    // draws a rectangle with a rounded top
    Chart.helpers.drawRoundedTopRectangle = function (
      ctx,
      x,
      y,
      width,
      height,
      radius
    ) {
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      // top right corner
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      // bottom right	corner
      ctx.lineTo(x + width, y + height);
      // bottom left corner
      ctx.lineTo(x, y + height);
      // top left
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
    };

    Chart.elements.RoundedTopRectangle = Chart.elements.Rectangle.extend({
      draw: function () {
        var ctx = this._chart.ctx;
        var vm = this._view;
        var left, right, top, bottom, signX, signY, borderSkipped;
        var borderWidth = vm.borderWidth;

        if (!vm.horizontal) {
          // bar
          left = vm.x - vm.width / 2;
          right = vm.x + vm.width / 2;
          top = vm.y;
          bottom = vm.base;
          signX = 1;
          signY = bottom > top ? 1 : -1;
          borderSkipped = vm.borderSkipped || "bottom";
        } else {
          // horizontal bar
          left = vm.base;
          right = vm.x;
          top = vm.y - vm.height / 2;
          bottom = vm.y + vm.height / 2;
          signX = right > left ? 1 : -1;
          signY = 1;
          borderSkipped = vm.borderSkipped || "left";
        }

        // Canvas doesn't allow us to stroke inside the width so we can
        // adjust the sizes to fit if we're setting a stroke on the line
        if (borderWidth) {
          // borderWidth shold be less than bar width and bar height.
          var barSize = Math.min(
            Math.abs(left - right),
            Math.abs(top - bottom)
          );
          borderWidth = borderWidth > barSize ? barSize : borderWidth;
          var halfStroke = borderWidth / 2;
          // Adjust borderWidth when bar top position is near vm.base(zero).
          var borderLeft =
            left + (borderSkipped !== "left" ? halfStroke * signX : 0);
          var borderRight =
            right + (borderSkipped !== "right" ? -halfStroke * signX : 0);
          var borderTop =
            top + (borderSkipped !== "top" ? halfStroke * signY : 0);
          var borderBottom =
            bottom + (borderSkipped !== "bottom" ? -halfStroke * signY : 0);
          // not become a vertical line?
          if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
          }
          // not become a horizontal line?
          if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
          }
        }

        // calculate the bar width and roundess
        var barWidth = Math.abs(left - right);
        var roundness = this._chart.config.options.barRoundness || 0.5;
        var radius = barWidth * roundness * 0.5;

        // keep track of the original top of the bar
        var prevTop = top;

        // move the top down so there is room to draw the rounded top
        top = prevTop + radius;
        var barRadius = top - prevTop;

        ctx.beginPath();
        ctx.fillStyle = vm.backgroundColor;
        ctx.strokeStyle = vm.borderColor;
        ctx.lineWidth = borderWidth;

        // draw the rounded top rectangle
        Chart.helpers.drawRoundedTopRectangle(
          ctx,
          left,
          top - barRadius + 1,
          barWidth,
          bottom - prevTop,
          barRadius
        );

        ctx.fill();
        if (borderWidth) {
          ctx.stroke();
        }

        // restore the original top value so tooltips and scales still work
        top = prevTop;
      },
    });

    Chart.defaults.roundedBar = Chart.helpers.clone(Chart.defaults.bar);

    Chart.controllers.roundedBar = Chart.controllers.bar.extend({
      dataElementType: Chart.elements.RoundedTopRectangle,
    });
    // End of rounded bar charts draw function

    // intiliazing Bar Chart and its options
    if (!loading) {
      var ctx = document.getElementById("BarChart").getContext("2d");
      if (typeof Bar !== "undefined") Bar.destroy();

      Bar = new Chart(ctx, {
        type: "roundedBar",
        data: {
          labels: data?.overtime_chart?.departments,
          datasets: [
            {
              data: data?.overtime_chart?.hours?.map((hour) =>
                Helper.timeToHours(hour)
              ),
              backgroundColor: [
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
              ],
              borderColor: [
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
                "#23aaeb",
              ],
              borderWidth: 0,
            },
          ],
        },
        options: {
          barRoundness: 1,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                barThickness: 25,
                ticks: {
                  fontColor: "#5c6974",
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "#313030",
                },
                gridLines: {
                  display: true,
                },
              },
            ],
          },
        },
      });
    }
  }, [data?.overtime_chart]);


  const onOfficeChange = (v) => {
    if (v) {
      setFilter(prevState => ({ ...prevState, office_id: v?.value }))
    } else {
      setFilter(prevState => ({ ...prevState, office_id: null }))
    }
  }

  const onMonthChange = (v) => {
    if (v) {
      setFilter(prevState => ({ ...prevState, month: v?.key }))
    } else {
      setFilter(prevState => ({ ...prevState, month: null }))
    }

  }

  const onYearChange = (v) => {
    if (v) {
      setFilter(prevState => ({ ...prevState, year: v?.value }))
    } else {
      setFilter(prevState => ({ ...prevState, year: null }))
    }
  }


  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between chart-title">
        <h4 className="ml-1 mb-0 pt-1">{t("overtime_chart")}</h4>
        <div className="d-flex mr-0">
          <BSelect
            optionLabel="label"
            optionValue="value"
            options={offices}
            onChange={(v) => onOfficeChange(v)}
            bValue={filter.office_id}
            isClearable
            containerStyle="month-picker mr-2"
            isLoading={!offices?.length && loading}
            placeholder={t("all offices")}
            icon="office"
          />

          <BSelect
            optionLabel="value"
            optionValue="key"
            options={Constants.MonthsData}
            onChange={(v) => onMonthChange(v)}
            bValue={filter.month}
            keepDefaultStyle
            containerStyle="month-picker mr-2"
            getOptionLabel={(option) => t(option.value)}
            icon="calendar"
          />

          <BSelect
            optionLabel="value"
            optionValue="value"
            onChange={(v) => onYearChange(v)}
            bValue={filter.year}
            options={Constants.YearData}
            keepDefaultStyle
            containerStyle="year-picker"
            icon="calendar"
          />
        </div>
      </div>
      <div
        style={{
          overflow: "auto",
          overflowX:
            data?.overtime_chart?.hours?.length * 60 > mQuery / 2 ? "scroll" : "auto",
        }}
        className="barChart_wrapper_style"
      >
        {loading ?
          <BarchartPlaceholder />
          : (
            <div
              style={{
                width:
                  data?.overtime_chart?.hours?.length * 60 > mQuery / 2
                    ? data?.overtime_chart?.hours?.length * 60
                    : "auto",
              }}
            >
              <canvas id="BarChart"></canvas>
            </div>
          )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // chartData: state.super.overtimeChart.data,
    // loading: state.super.overtimeChart.isLoading,
    firstLoad: state.super.dashboardViewLoading,
    newLoaded: state.super.overtimeChart.refreshChart,
  };
};

export default connect(mapStateToProps)(OvertimeChart);
