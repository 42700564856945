import React from "react";
import { makeStyles } from "tss-react/mui";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { useHistory, useLocation } from "react-router";
import Constants from "../../Constants";
import {
  faAngleLeft,
  faClipboard,
  faNotesMedical,
  faUsers,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const verticalNavbarStyles = makeStyles()(() => Constants.verticalNavbarStyles);
const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const RecruitmentTabs = ({ isRouteSidebar }) => {
  const { classes } = isRouteSidebar
    ? verticalNavbarStyles()
    : horizontalNavbarStyles();
  const { t } = useTranslation();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const history = useHistory();

  const handleChangeTab = (event, newValue) => {
    history.push(`/recruitment?tab=${newValue}`);
  };

  let query = useQuery();
  const activeValue = query.get("tab");

  const possibleValues = [
    "recruitment-process",
    "intake-forms",
    "job-posts",
    "applicants",
  ];

  return (
    <>
      <Tabs
        variant="scrollable"
        value={possibleValues.includes(activeValue) ? activeValue : false}
        onChange={handleChangeTab}
        orientation={isRouteSidebar ? "vertical" : "horizontal"}
        scrollButtons="auto"
        classes={{
          root: classes.root,
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
          scrollButtons: classes.scrollButtons,
        }}
      >
        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_RECRUITMENT_PROCESSES],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value="recruitment-process"
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faUserTie}
                  className="mx-2 routeBar-icon"
                />
                {t("Recruitment Processes")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_INTAKE_FORMS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value="intake-forms"
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faNotesMedical}
                  className="mx-2 routeBar-icon"
                />
                {t("Intake Forms")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_JOB_POSTS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value="job-posts"
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faClipboard}
                  className="mx-2 routeBar-icon"
                />
                {t("Job Posts")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_JOB_POST_APPLICANTS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value="applicants"
            label={
              <div className="mx-2 routeBar-text">
                <FontAwesomeIcon
                  icon={faUsers}
                  className="mx-2 routeBar-icon"
                />
                {t("Applicants")}
              </div>
            }
          />
        )}
      </Tabs>
    </>
  );
};

export default RecruitmentTabs;
