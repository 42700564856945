import { Box, Drawer, Tab, Tabs } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EmailNotifications from "../../Routes/EmailNotifications";
import {
  checkAllNotificationToReadAttempt,
  hideNotificationDrawer,
} from "../../Store/Actions";
import NotificationHistory from "./NotificationHistory";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";

function NotificationDrawer() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const isOpen = useSelector(
    (state) => state.super.notificationDrawer.isVisible
  );
  const dispatch = useDispatch();

  const handleChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  const checkForNotificationsPrivilege = () => {
    return HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_NOTIFICATIONS_HISTORY],
      allowBP: true,
    });
  };

  useEffect(() => {
    if (!checkForNotificationsPrivilege()) {
      setActiveTab(2);
    }
  }, []);

  useEffect(() => {
    if (!isOpen && checkForNotificationsPrivilege()) {
      setActiveTab(1);
    }
  }, [isOpen]);

  return (
    <div>
      <Drawer
        anchor={i18next.dir() === "ltr" ? "right" : "left"}
        open={isOpen}
        onClose={() => dispatch(hideNotificationDrawer())}
        sx={{ minWidth: "20% !important", marginTop: "5%" }}
        className="notification-drawer-main-container"
      >
        <Box className="notification-drawer-main-container-box">
          {/* <TabContext value={activeTab} > */}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              onChange={handleChange}
              value={activeTab}
              // aria-label="lab API tabs example"
              indicatorColor="none"
              variant="fullWidth"
              className=""
            >
              {HelperFns.checkPrivileges({
                privileges: [Privilages.VIEW_NOTIFICATIONS_HISTORY],
                allowBP: true,
              }) ? (
                <Tab
                  label={t("notification_history")}
                  value={1}
                  className={
                    activeTab === 1
                      ? "notification-drawer-active-tab"
                      : "notification-drawer-inactive-tab"
                  }
                />
              ) : null}

              {HelperFns.checkPrivileges({
                privileges: [Privilages.CUSTOMIZE_NOTIFICATIONS],
                allowBP: true,
              }) ? (
                <Tab
                  label={t("settings")}
                  value={2}
                  className={
                    activeTab === 2
                      ? "notification-drawer-active-tab"
                      : "notification-drawer-inactive-tab"
                  }
                />
              ) : null}
            </Tabs>
          </Box>
          {/* <TabPanel value="1">Item One</TabPanel> */}
          {/* <TabPanel value="2">Item Two</TabPanel> */}
          {/* </TabContext> */}
          {activeTab === 1 ? <NotificationHistory /> : <EmailNotifications />}
        </Box>
      </Drawer>
    </div>
  );
}

export default NotificationDrawer;
