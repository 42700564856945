import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import { onInputResetAction } from "../../Store/Actions";
import { workPlaceSettingOptions } from "../../Constants";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  CheckboxBooleanForm,
} from "form-builder";

/**
 * start of WorkOnDaysOff
 */

export const WorkOnDaysOff = ({
  formProps,
  halfWorkTimingOptions,
  normalWorkTimingOptions,
  serverValidationPrefix = "",
  ...props
}) => {
  const { t } = useTranslation();

  const isTreatedAsNormal = useSelector(
    (state) =>
      state?.[formProps?.reducer]?.[formProps?.formName]?.treat_as_normal
  );
  return (
    <>
      {props?.hideName ? null : (
        <strong className="d-block mt-4 blue-color">
          {t("work on days off")}
        </strong>
      )}
      <CheckboxBooleanForm
        {...formProps}
        setWithValue
        checkedValue={true}
        unCheckedValue={false}
        name="allow_work_on_weekend_or_holiday"
        options={["allow employee to sign-in on their days off"]}
      />
      <div className="row mt-2">
        <BSelect
          {...formProps}
          validation="textRequired"
          label="work timing"
          name="normal_work_timing_id"
          placeholder={t("select work timing")}
          validateBy="textRequired"
          validationName={`input.${serverValidationPrefix}day_off_exception_config.normal_work_timing_id`}
          keepDefaultStyle
          options={normalWorkTimingOptions}
          optionLabel="name"
          optionValue="id"
          inputContainerStyle="w-100"
          dependOn="allow_work_on_weekend_or_holiday"
          dependancyType="equal"
          dependancyValue={[true]}
          rootStyle="col-lg-6"
          customNewStyles
          skipLocalization
        />
        {isTreatedAsNormal ? (
          <InputForm
            {...formProps}
            name="weight"
            validateBy="textRequired"
            validationName={`input.${serverValidationPrefix}day_off_exception_config.weight`}
            label="weight"
            labelStyle="min-w-150 mb-3"
            containerStyle=" "
            rootStyle="col-lg-6"
            type="number"
            dependOn="allow_work_on_weekend_or_holiday"
            dependancyType="equal"
            dependancyValue={[true]}
          />
        ) : null}
      </div>
      <CheckboxBooleanForm
        {...formProps}
        options={["Treat this day as a normal work day"]}
        name="treat_as_normal"
        validationName={`input.${serverValidationPrefix}day_off_exception_config.treat_as_normal`}
        labelStyle="label-style"
        setWithValue
        checkedValue={true}
        unCheckedValue={false}
        dependOn="allow_work_on_weekend_or_holiday"
        dependancyValue={[true]}
        dependancyType="equal"
      />
      {isTreatedAsNormal ? (
        <>
          <CheckboxBooleanForm
            {...formProps}
            options={["allow employees to request half-days"]}
            name="allow_half_days"
            labelStyle="label-style"
            dependOn="allow_work_on_weekend_or_holiday"
            dependancyValue={[true]}
            dependancyType="equal"
          />
          <div className="row mt-2">
            <BSelect
              {...formProps}
              validation="textRequired"
              label="first half work timing"
              name="first_half_work_timing_id"
              placeholder={t("select work timing")}
              validateBy="textRequired"
              validationName={`input.${serverValidationPrefix}day_off_exception_config.first_half_work_timing_id`}
              keepDefaultStyle
              options={halfWorkTimingOptions}
              optionLabel="name"
              optionValue="id"
              inputContainerStyle="w-100"
              dependOn="allow_half_days"
              dependancyType="equal"
              dependancyValue={[1]}
              rootStyle="col-lg-6"
              customNewStyles
              customComponents={{ Option }}
            />
            <BSelect
              {...formProps}
              label="second half work timing"
              name="second_half_work_timing_id"
              placeholder={t("select work timing")}
              validateBy="textRequired"
              validationName={`input.${serverValidationPrefix}day_off_exception_config.second_half_work_timing_id`}
              keepDefaultStyle
              options={halfWorkTimingOptions}
              optionLabel="name"
              optionValue="id"
              inputContainerStyle="w-100"
              dependOn="allow_half_days"
              dependancyType="equal"
              dependancyValue={[1]}
              rootStyle="col-lg-6"
              customNewStyles
              customComponents={{ Option }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

/**
 * end of WorkOnDaysOff
 *
 * start of WorkPlaces
 */

export const WorkPlaces = ({
  formProps,
  locationsOptions,
  serverValidationPrefix = "",
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workplace_setting_identical = useSelector(
    (state) =>
      state?.[formProps?.reducer || "super"]?.[formProps?.formName]
        ?.workplace_setting_identical
  );

  useDidUpdateEffect(() => {
    if (!workplace_setting_identical)
      dispatch(
        onInputResetAction(
          formProps?.formName,
          "additional_work_places_signout"
        )
      );
  }, [workplace_setting_identical]);

  return (
    <>
      {formProps?.reducer !== "assignments" ? (
        <strong className="d-block mt-4 mb-2 blue-color">
          {t("work places")}
        </strong>
      ) : null}
      <RadioboxForm
        {...formProps}
        name="workplace_setting"
        validateBy="textRequired"
        label="Employee can sign in From"
        keepDefaultStyle
        options={workPlaceSettingOptions}
        validationName={`input.${serverValidationPrefix}day_off_exception_config.workplace_setting`}
        containerStyle="my-2"
        labelStyle="label-style font-weight-bold"
        optionItemStyle="mr-3"
        optionInputStyle=" "
        optionLabelStyle="optionLabelStyle"
        optionsContainerStyle="d-flex flex-column mb-2"
        skipLocalization
      />
      <BSelect
        {...formProps}
        isMulti
        name="additional_work_places"
        validateBy="textRequired"
        label="Additional Workplaces"
        keepDefaultStyle
        options={locationsOptions}
        labelStyle="my-2"
        containerStyle="containerStyle flex-column align-items-start py-0"
        validationName={`input.${serverValidationPrefix}day_off_exception_config.additional_work_places`}
        inputContainerStyle="flex-grow-1 mr-0 w-100"
        skipLocalization
        icon="people"
        dependOn="workplace_setting"
        dependancyValue={["DEFAULT"]}
        dependancyType="equal"
      />
      <CheckboxBooleanForm
        {...formProps}
        containerStyle="my-3"
        name="workplace_setting_identical"
        options={["Apply the same settings when signing out"]}
      />

      <RadioboxForm
        {...formProps}
        name="workplace_setting_signout"
        validateBy="textRequired"
        label="Employee can sign out From"
        keepDefaultStyle
        options={workPlaceSettingOptions}
        validationName={`input.${serverValidationPrefix}day_off_exception_config.workplace_setting`}
        containerStyle="my-2"
        labelStyle="label-style font-weight-bold"
        optionItemStyle="mr-3"
        optionInputStyle=" "
        optionLabelStyle="optionLabelStyle"
        optionsContainerStyle="d-flex flex-column mb-2"
        skipLocalization
        dependOn="workplace_setting_identical"
        dependancyValue={[0]}
        dependancyType="equal"
      />
      {!workplace_setting_identical ? (
        <BSelect
          {...formProps}
          isMulti
          name="additional_work_places_signout"
          validateBy="textRequired"
          label="Additional Workplaces"
          keepDefaultStyle
          options={locationsOptions}
          labelStyle="my-2"
          validationName={`input.${serverValidationPrefix}day_off_exception_config.additional_work_places_signout`}
          containerStyle="containerStyle flex-column align-items-start py-0"
          inputContainerStyle="flex-grow-1 mr-0 w-100"
          skipLocalization
          icon="people"
          dependOn="workplace_setting_signout"
          dependancyValue={["DEFAULT"]}
          dependancyType="equal"
        />
      ) : null}
    </>
  );
};

/**
 * end of WorkPlaces
 *
 * start of Compensation
 */

export const Compensation = ({
  formProps,
  compensation_type,
  apply_compensation,
  serverValidationPrefix = "",
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useDidUpdateEffect(() => {
    !apply_compensation &&
      dispatch(onInputResetAction(formProps.formName, "compensation_quantity"));
  }, [apply_compensation]);

  return (
    <>
      <strong className="d-block mt-4 mb-2 blue-color">
        {t("compensation")}
      </strong>
      <CheckboxBooleanForm
        {...formProps}
        name="apply_compensation"
        options={["compensate employee for the extra time worked"]}
        containerStyle="my-1"
      />
      <div
        className={`info-line-container${!apply_compensation ? " d-none" : ""}`}
      >
        <div aria-hidden="true" className="info-line">
          <div className="testing-top-circle" />
          <div className="testing-bottom-circle" />
        </div>
        <RadioboxForm
          {...formProps}
          name="compensation_type"
          options={[
            { label: "award bonus days", value: "bonus" },
            { label: "award additional days", value: "leaves" },
          ]}
          containerStyle="my-1"
          labelStyle="label-style"
          optionItemStyle="mr-3"
          optionInputStyle=" "
          optionLabelStyle="optionLabelStyle"
          optionsContainerStyle="d-flex gap-20"
          validationName={`input.${serverValidationPrefix}day_off_exception_config.compensation_type`}
          dependOn="apply_compensation"
          dependancyType="equal"
          dependancyValue={[1]}
        />
        <InputForm
          {...formProps}
          name="compensation_quantity"
          validationName={`input.${serverValidationPrefix}day_off_exception_config.compensation_quantity`}
          label={`${
            compensation_type === "bonus" ? "bonus" : "leave"
          } days / work day`}
          labelStyle="min-w-150"
          containerStyle="d-flex align-items-end justify-content-start mb-md-0 mr-2"
          inputContainerStyle="ml-3 inputs-days-inputs-style"
          type="number"
        />
      </div>
    </>
  );
};

/**
 * end of Compensation
 *
 * start of Requests
 */

export const Requests = ({ formProps }) => {
  const { t } = useTranslation();

  return (
    <>
      <strong className="d-block mt-4 mb-2 blue-color">{t("requests")}</strong>
      <div className="d-flex gap-20">
        <CheckboxBooleanForm
          {...formProps}
          options={["permissions"]}
          name="allow_permission"
          labelStyle="label-style"
          setWithValue
          checkedValue={true}
          unCheckedValue={false}
        />
        <CheckboxBooleanForm
          {...formProps}
          options={["overtime"]}
          name="allow_overtime"
          labelStyle="label-style"
          setWithValue
          checkedValue={true}
          unCheckedValue={false}
        />
      </div>
    </>
  );
};
