import React from "react";
import { useTranslation } from "react-i18next";

export const Section = ({ subSection, title, units, violations, ...props }) => {
  const { t } = useTranslation();
  const Tag = subSection ? "article" : "section";

  return (
    <Tag {...props}>
      {subSection ? (
        <h4 className="sub-title h5">{t(title)}</h4>
      ) : (
        <h3 className="section-title h4">{t(title)}</h3>
      )}
      {units ? <div className="row">{units.map((unit) => unit)}</div> : null}
      {violations ? <ViolationsGroup violations={violations} /> : null}
      {props?.children}
    </Tag>
  );
};
export const ViolationsGroup = ({ violations = [] }) => {
  const { t } = useTranslation();

  return (
    <>
      <strong className="violation-title">{t("Violations")}</strong>
      <div className="row">
        {violations?.map((violation, i) => (
          <Unit
            key={violation}
            label={`Violation`}
            labelNum={"#" + Number(i + 1)}
            val={violation.value + " " + t(violation.quantity_unit)}
          />
        ))}
      </div>
    </>
  );
};

export const Unit = ({
  label,
  labelNum = "",
  val,
  boolVal,
  isBoolVal = false,
  isHidden,
  colStyles,
  ...props
}) => {
  const { t } = useTranslation();

  return !isHidden ? (
    <div
      style={{ minHeight: 25 }}
      className={`d-flex gap-10 ${colStyles || "col-12 col-md-6 col-lg-4"}`}
      {...props}
    >
      <span>
        {t(label)} {labelNum}
      </span>

      {isBoolVal ? (
        boolVal ? (
          <span className="green-color">{t("Yes")}</span>
        ) : (
          <span className="red-color">{t("No")}</span>
        )
      ) : (
        <b className="dark-color">{val}</b>
      )}
    </div>
  ) : null;
};
