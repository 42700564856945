import { v4 as uuid, validate as uuidValidate } from "uuid";

export default class FormField {
  id;
  parent_field_id;
  ancestors;
  name;
  description;
  apply_validation;
  apply_description;
  grid;
  type;
  selection_type;
  dependent_option_names;
  validation_type_ids;
  options;
  mainOptions;
  order;
  suggested_field_type;

  constructor(parent_field_id, order, ancestors = []) {
    this.id = uuid();
    this.parent_field_id = parent_field_id;
    this.order = order;
    this.ancestors = ancestors;

    this.name = "";
    this.description = "";
    this.apply_validation = false;
    this.apply_description = false;
    this.grid = "";
    this.type = "";
    this.selection_type = "";
    this.dependent_option_names = [];
    this.validation_type_ids = [];
    this.options = [];
    this.mainOptions = [];
    this.suggested_field_type = null;

    this.serialize = function getSubmittedData() {
      return {
        id: uuidValidate(this.id) ? null : this.id,
        name: this.name,
        description: this.description,
        grid: this.grid,
        type: this.type,
        selection_type: this.selection_type,
        dependent_option_names: this.dependent_option_names.length
          ? this.dependent_option_names?.map((opt) => opt?.label)
          : [],
        validation_type_ids: !!this.apply_validation
          ? this.validation_type_ids?.map((validation) => validation.value)
          : [],
        options: this.options?.map((option) => ({
          id: uuidValidate(option.value) ? null : option.value,
          name: option.label,
        })),
      };
    };
  }
}
