import React from "react";
import { connect } from "react-redux";
import MainModal from "../../Components/MainModal";
import EditSystemPlanPage from "../../Routes/SystemPlanPage/EditSystemPlanPage";
import { toogleViewPlanPrivileges } from "../../Store/Actions";

const ViewPlanPrivileges = (props) => {
  return (
    <MainModal
      isOpen={props.viewPlanPrivilegesModal?.isVissible}
      toggle={() => props.toogleViewPlanPrivileges()}
      modalTitle={`${props.systemPlan.name} `}
      className="manage_privilages_modal_wrapper_style"
    >
      <EditSystemPlanPage showPrivilegesOnly />
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    systemPlan: state.admin?.systemPlan,
    viewPlanPrivilegesModal: state.admin?.viewPlanPrivilegesModal,
    system_privileges: state?.admin?.system_privileges,
    systemPlanLoading: state.admin?.loadingEditPlan,
  };
};

export default connect(mapStateToProps, { toogleViewPlanPrivileges })(
  ViewPlanPrivileges
);
