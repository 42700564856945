import React from "react";
import EmployeeLoans from "../EmployeeLoans";

const LoansTab = (props) => {
  return (
    <>
      <EmployeeLoans />
    </>
  );
};

export default LoansTab;
