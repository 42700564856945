import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InputForm } from "form-builder";

// Ui
import MainModal from "../MainModal";

// Helpers
import { upsertWorkTeamMutation } from "../../Graphql/mutation";
import { onFormResetAction } from "../../Store/Actions";
import { editWorkTeamAction } from "../../Store/Actions/shiftsActions/actions";
import { handleActionMutation } from "../../Helpers/HelperFns";

//   form props
const formName = "editWorkTeamMembersForm";
const formNameValidation = "editWorkTeamMembersFormValidation";
const formServerValidation = "editWorkTeamMembersFormServerValidation";
const reducer = "shifts";

const EditWorkTeamModal = ({
  isModalOpen,
  setIsModalOpen,
  fetchWorkTeam,
  workGroupId,
  workTeam,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const { workTeamId } = useParams();

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
    reducer,
  };

  //   Redux hooks
  const dispatch = useDispatch();
  const editWorkTeamMembersForm = useSelector(
    (state) => state?.shifts?.[formName]
  );
  const formClientValidation = useSelector(
    (state) => state?.shifts?.[formNameValidation]
  );

  // Apollo hooks
  const [upsertWorkTeam, { loading: upsertWorkTeamLoading }] = useMutation(
    upsertWorkTeamMutation
  );

  useEffect(() => {
    dispatch(editWorkTeamAction(workTeam?.name));

    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
      dispatch(onFormResetAction(formServerValidation));
    };
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (formClientValidation?.length === 0) {
      setIsSubmitting(false);
      const { error } = await handleActionMutation(upsertWorkTeam, {
        variables: {
          input: {
            id: workTeamId,
            name: editWorkTeamMembersForm?.name ?? "",
            workGroupId,
          },
        },
        isReturnUnion: true,
        refetchQuery: fetchWorkTeam,
      });

      if (!error) {
        setIsModalOpen(false);
      }
    }
  };

  return (
    <MainModal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(false)}
      modalTitle={t("edit work team")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={upsertWorkTeamLoading}
    >
      <InputForm
        {...formProps}
        validateBy="textRequired"
        label={t("team name")}
        name="name"
        validationName="input.name"
        type="text"
        placeholder={t("Team name")}
        containerStyle="name_field_container"
        inputContainerStyle="name_field_input"
        labelStyle="name_field_label"
        icon="people"
      />
    </MainModal>
  );
};

export default EditWorkTeamModal;
