import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { InputForm } from "form-builder";
import { connect } from "react-redux";
import {
  dismissEditAmountModalAction,
  editPayrollDeductionsAction,
  fetchAmountModalAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = "editAmountModal";
const ref = "editAmountModalActions";
const formNameValidation = "editAmountModalValidation";

const EditAmountModal = (props) => {
  const { t } = useTranslation();

  const handleSubmitBtn = (isRevert) => {
    if (!isRevert) {
      setIsSubmitting(true);
      if (props.modalValidation.length) {
        return;
      }
    }

    props?.editPayrollDeductionsAction({
      monthlyId: props?.editAmountModal?.monthlyId,
      deductible_id: props?.editAmountModal?.deductible_id,
      amount: +props?.editAmountModal?.new_amount,
      revert: isRevert,
      fetchPayrollReportData: props?.fetchPayrollReportData,
      ref,
      formName,
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;
  const dismissModal = () => {
    props.dismissEditAmountModalAction({
      ref,
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
    isModalVissible &&
      props?.fetchAmountModalAction({
        id: props?.editAmountModal?.monthlyId,
        deductible_id: props?.editAmountModal?.deductible_id,
        ref,
        formName,
      });
  }, [isModalVissible]);

  useEffect(() => {
    return dismissModal;
  }, []);

  return (
    <MainModal
      toggle={dismissModal}
      isOpen={isModalVissible}
      btnLabelCancel={t("Revert")}
      modalTitle={t(`Edit ${props?.editAmountModal?.title}`)}
      btnOnClick={() => handleSubmitBtn(false)}
      btnOnCancelClick={() => handleSubmitBtn(true)}
      btnSubmitLoading={isLoading}
      btnCancelLoading={isLoading}
      className="edit_amount_modal_wrapper_style"
    >
      <div>
        <div className="row">
          <div className="d-flex align-items-baseline justify-content-between col-12">
            <div className="d-flex align-items-baseline">
              <label className="mr-4 pr-3">{t("calculated amount")}</label>
              <p className="amount_wrapper_style">
                <span>{props?.editAmountModal?.currency}</span>
                {props?.editAmountModal?.amount !== ""
                  ? props?.editAmountModal?.amount?.toFixed(2)
                  : "---"}
              </p>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="d-flex align-items-baseline col-12">
            <label className="mr-5 pr-4">{t("new amount")}</label>
            <p className="d-flex align-items-baseline amount_wrapper_style mb-0">
              <span>{props?.editAmountModal?.currency}</span>
              <InputForm
                name="new_amount"
                formName={formName}
                formNameValidation={formNameValidation}
                formSubmitting={isSubmitting}
                labelStyle="mb-2"
                containerStyle=""
                inputContainerStyle="w-100"
                validateBy="textRequired"
              />
            </p>
          </div>
        </div>

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.editAmountModalActions,
  modalValidation: state.super[formNameValidation],
  editAmountModal: state.super[formName],
});

export default connect(mapStateToProps, {
  dismissEditAmountModalAction,
  editPayrollDeductionsAction,
  fetchAmountModalAction,
})(EditAmountModal);
