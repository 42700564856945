import React, { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PayrollWidget from "../../Components/PayrollWidget";
import Constants from "../../Helpers/Constants";
import { BSelect, RadioboxForm, CheckboxBooleanForm } from "form-builder";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faExchangeAlt,
  faFileInvoiceDollar,
  faFileExport,
  faInfo,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import ExportModal from "../../Components/ExportModal";
import PayslipModal from "../../Components/PayslipModal";
import EditAmountModal from "../../Components/EditAmountModal";
import {
  showEditAmountModalAction,
  fetchPayrollReportListAction,
  onInputResetAction,
  submitPayrollPaymentAction,
  reversePayrollPaymentAction,
  fetchUserPaySlipAction,
  fetchExportPayrollOptionsAction,
  onInputChangeAction,
} from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import Privilages from "../../Constants/Privilages";

import { Spinner } from "reactstrap";
import ExportPayrollModal from "./ExportPayrollModal";
import { Link, useLocation } from "react-router-dom";
import AntSwitch from "../../Components/AntSwitch";
import moment from "moment";
import {
  allowancesDetailsQuery,
  bonusDetailsQuery,
  otherAddedDetailsQuery,
  otherDeductablesDetailsQuery,
  overtimeDetailsQuery,
  penaltyDetailsQuery,
} from "../../Graphql/query";
import ViewPayrollDetails from "./ViewPayrollDetails";
import { useLazyQuery } from "@apollo/client";
import swal from "sweetalert";
import { InfoIconButton } from "../../Components/IconButtonWithTooltip";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const YearlyPayroll = (props) => {
  const dataTableRef = "payrollList";
  const { t } = useTranslation();

  let query = useQuery();

  const payrollMonth = query.get("month");
  const payrollYear = query.get("year");

  const selectedCurrency = props?.data?.payrollCurrenciesFilter?.find(
    (currency) => currency.id == props.data[dataTableRef].currency_id
  );

  const [isFirstRender, setIsFirstRender] = useState(true);

  const headQuarterOffice = props?.user?.offices?.find(
    (office) => office.head_quarter == 1
  );

  useEffect(() => {
    if (payrollMonth && payrollYear) {
      props.fetchPayrollReportListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...props.data[dataTableRef],
          office_id: "",
          month: moment(payrollMonth, "M").format("MM"),
          year: +payrollYear,
        },
      });
      window.history.pushState({}, null, "/payroll");
      return;
    }

    props.fetchPayrollReportListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        month: moment().format("MM"),
        year: +new Date().getFullYear(),
        currency_id: headQuarterOffice?.currency?.id ?? "",
      },
    });

    setTimeout(() => {
      setIsFirstRender(false);
    }, 0);

    return () => {
      props.onInputResetAction(dataTableRef, "status");
      props.onInputResetAction(dataTableRef, "month");
      props.onInputResetAction(dataTableRef, "year");
      props.onInputResetAction(dataTableRef, "user_id");
      props.onInputResetAction(dataTableRef, "office_id");
      props.onInputResetAction(dataTableRef, "currency_id");
      props.onInputResetAction(dataTableRef, "department_id");
      props.onInputResetAction(dataTableRef, "monthly_amount");
      props.onInputResetAction(dataTableRef, "regulation_id");
    };
  }, []);

  const handleFilter = () => {
    !isFirstRender &&
      props.fetchPayrollReportListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...props.data[dataTableRef],
          pagination: {
            ...props.data[dataTableRef].pagination,
            currentPage: 1,
          },
        },
      });
  };

  const handleFilterOffice = () => {
    if (isFirstRender) {
      return;
    }

    // if (props?.selectedOffice?.id || props?.selectedOffice === null) {
    props.fetchPayrollReportListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        user_id: "",
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
      officeFilter: {
        IsOfficeFilter: true,
        currectCurrency: props.data[dataTableRef].currency_id,
        isFirstRender,
      },
    });
    // }
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].status,
    props.data[dataTableRef].month,
    props.data[dataTableRef].year,
    props.data[dataTableRef].user_id,
    props.data[dataTableRef].currency_id,
    props.data[dataTableRef].department_id,
    props.data[dataTableRef].monthly_amount,
    props.data[dataTableRef].show_external,
    props.data[dataTableRef].regulation_id,
  ]);

  const useDidOfficeFilterUpdate = useDidUpdateEffect(handleFilterOffice, [
    props.data[dataTableRef].office_id,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    console.log("@pagination effect");
    props.fetchPayrollReportListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  const [ShowExportModal, setShowExportModal] = useState(false);
  const toggleShowExportModal = () => setShowExportModal(!ShowExportModal);

  // for show payslip modal and use modal component one time
  const [ShowPayslipModal, setShowPayslipModal] = useState(false);
  const [payslipDetails, setPayslipDetails] = useState(null);

  const toggleShowPayslipModal = (payslipDetails) => {
    setShowPayslipModal(true);
    setPayslipDetails(payslipDetails);

    props.fetchUserPaySlipAction({
      user_id: payslipDetails?.user?.id,
      month_year_payroll: payslipDetails?.month_year_payroll,
    });
  };

  const toggleHidePayslipModal = () => {
    setShowPayslipModal(false);
    setPayslipDetails(null);
  };

  // for edit amount modal
  const handleEditAmount = (data) => {
    props.showEditAmountModalAction({
      ref: "editAmountModalActions",
      formName: "editAmountModal",
      data,
    });
  };

  const externalTableStyle = {
    backgroundColor: "#fff",
    // borderRightColor: "#fff !important",
    borderLeftColor: "#fff !important",
  };

  const extraTableStyle = {
    backgroundColor: "rgba(220, 220, 220, 0.84)",
  };

  const groupColumns = [
    {
      name: "",
      grow: 3.07,
    },
    {
      name: <div className="extra_main_title_style mx-auto">{t("extra")}</div>,
      grow: 4,
      center: true,
    },
    {
      name: (
        <div className="deductions_main_title_style">{t("deductions")}</div>
      ),
      grow: 5.48,
      center: true,
    },
    {
      name: "",
      grow: 1.24,
    },
  ];
  // start handle view cell details

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState({});
  const dismissDetailsModal = () => {
    console.log("dismiss");
    setIsDetailsModalOpen(false);
    setDetailsModalData({});
  };
  const handleViewDetails = (args) => {
    setIsDetailsModalOpen(true);
    setDetailsModalData({
      title: args.title,
      month_year: args?.month_year,
      name: args?.row?.name,
    });
    if (args.title === "overtime") {
      fetchOvertimeDetails({
        variables: {
          user_id: args?.user_id,
          month_year: args?.month_year,
          payroll_id: args?.row?.payroll_id,
        },
      });
    }
    if (args.title === "bonus") {
      fetchBonusDetails({
        variables: {
          user_id: args?.user_id,
          month_year: args?.month_year,
        },
      });
    }
    if (args?.title === "penalty") {
      fetchPenaltyDetails({
        variables: {
          user_id: args?.user_id,
          month_year: args?.month_year,
          month_year_record_id: args?.row?.payroll_id,
        },
      });
    }
    if (args?.title === "other deductables") {
      fetchOtherDeductablesDetails({
        variables: {
          payroll_id: args?.row?.payroll_id,
        },
      });
    }
    if (args?.title === "other added") {
      fetchOtherAddedDetails({
        variables: {
          payroll_id: args?.row?.payroll_id,
        },
      });
    }
    if (args?.title === "allowances") {
      fetchAllowancesDetails({
        variables: {
          month_year_record_id: args?.row?.payroll_id,
        },
      });
    }
  };

  const [fetchOvertimeDetails, { loading: loadingOvertimeDetails, variables }] =
    useLazyQuery(overtimeDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
          payroll_id: variables?.payroll_id,
        }));
      },
    });
  const [fetchBonusDetails, { loading: loadingBonusDetails }] = useLazyQuery(
    bonusDetailsQuery,
    {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        console.log("res", res);
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    }
  );
  const [fetchPenaltyDetails, { loading: loadingPenaltyDetails }] =
    useLazyQuery(penaltyDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    });

  const [fetchOtherDeductablesDetails, { loading: loadingOtherDeductDetails }] =
    useLazyQuery(otherDeductablesDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    });

  const [fetchOtherAddedDetails, { loading: loadingOtherAddedDetails }] =
    useLazyQuery(otherAddedDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    });

  const [fetchAllowancesDetails, { loading: loadingAllowancesDetails }] =
    useLazyQuery(allowancesDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData?.userAllowancesHistory,
        }));
      },
    });
  // end handle View cell details
  const TableCell = ({
    configType,
    symbol,
    value,
    allowNegativeValue = false,
  }) =>
    configType != "external" ? (
      <div className="d-flex align-items-center">
        <div className="pr-1">{symbol}</div>
        <p className="mb-0">
          {!!value
            ? roundToTwo(
                allowNegativeValue && value.toFixed(2) < 0
                  ? value
                  : Math.abs(value)
              )
            : "0.00"}
        </p>
      </div>
    ) : (
      <div></div>
    );

  const columns = [
    {
      name: t("employees"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 3,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   !props?.extraPayrollRow?.user?.name,
      style: {
        backgroundColor: "#fff",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
        {
          when: (row) => row?.config_type != "internal",
          style: {
            borderRight: "1px solid #d2d2d2;",
          },
        },
      ],
      cell: (row) => (
        <div className="d-flex w-100 align-items-center justify-content-between">
          {row?.isExtra ? (
            <div className="mr-2">
              {row?.user?.name} {t("employees")}
            </div>
          ) : (
            <CanViewEmployeeProfile
              allowBP
              directManger={row?.user?.manager?.id}
              copiedManagers={row?.user?.copied_managers?.map((cp) => cp?.id)}
              altChildren={<div className="mr-2">{row?.user?.name}</div>}
            >
              <div className="mr-2">
                <Link
                  className="employee-name"
                  to={`/employees/employee-profile/${row?.user?.id}`}
                >
                  {row?.user?.name}
                </Link>
              </div>
            </CanViewEmployeeProfile>
          )}

          {row?.payment_status == 3 && !row?.isExtra && (
            <HasPrivileges
              reqireMain={[Privilages.REVERSE_PAYMENTS]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={
                <div className="data-table-action mr-2">
                  <span className="unpay_btn_style btn p-0 px-1">
                    <FontAwesomeIcon icon={faExchangeAlt} />
                  </span>
                </div>
              }
            >
              <div className="data-table-action mr-2">
                {props?.reverseLoading?.includes(row?.payroll_id) ? (
                  <Spinner
                    style={{
                      width: "1rem",
                      height: "1rem",
                      color: "#8997a4",
                    }}
                  />
                ) : (
                  <span
                    onClick={() => handleReversePayment(row?.payroll_id)}
                    className="unpay_btn_style btn p-0 px-1"
                  >
                    <FontAwesomeIcon icon={faExchangeAlt} />
                  </span>
                )}
              </div>
            </HasPrivileges>
          )}
        </div>
      ),
    },
    {
      name: t("base"),
      wrap: true,
      selector: "base_salary",
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(35, 170, 235, 0.14)",
      },
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.base_salary == 0,
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <TableCell
          configType={row?.config_type}
          symbol={row?.currency?.symbol}
          value={
            props.data[dataTableRef].monthly_amount
              ? row?.monthly_base_salary
              : row?.base_salary
          }
        />
      ),
    },
    {
      name: t("allowances"),
      wrap: true,
      selector: "allowances_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.allowances_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={row?.config_type}
            symbol={row?.currency?.symbol}
            value={
              props.data[dataTableRef].monthly_amount
                ? row?.monthly_allowances
                : row?.allowances_amount
            }
          />
          {(row?.config_type == "internal" &&
            Math.abs(row?.monthly_allowances) > 0) ||
          Math.abs(row?.allowances_amount) > 0 ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    title: "allowances",
                    row,
                    user_id: row?.id,
                    month_year: `${props.payrollList.month}-${props.payrollList.year}`,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("bonuses"),
      wrap: true,
      selector: "bonuses_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.bonuses_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={row?.config_type}
            symbol={row?.currency?.symbol}
            value={row?.bonuses_amount}
          />
          {row?.config_type == "internal" &&
          Math.abs(row?.bonuses_amount) > 0 ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    title: "bonus",
                    row,
                    user_id: row?.id,
                    month_year: `${props.payrollList.month}-${props.payrollList.year}`,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("overtime"),
      wrap: true,
      selector: "overtime_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.overtime_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={row?.config_type}
            symbol={row?.currency?.symbol}
            value={row?.overtime_amount}
          />
          {row?.config_type == "internal" &&
          Math.abs(row?.overtime_amount) > 0 ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    title: "overtime",
                    row,
                    user_id: row?.id,
                    month_year: `${props.payrollList.month}-${props.payrollList.year}`,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("others"),
      wrap: true,
      selector: "claims_amount",
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18)",
      },
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.claims_amount == 0,
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: {
            ...externalTableStyle,
            // padding: "5px 2px 5px 36px !important",
          },
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) =>
        row?.config_type == "external" ? (
          <div className="w-100 text-right font-weight-bold managed-style">
            {t("managed")}
          </div>
        ) : (
          <div className="d-flex align-items-center w-100 justify-content-between">
            <TableCell
              configType={row?.config_type}
              symbol={row?.currency?.symbol}
              value={row?.claims_amount}
            />
            {row?.config_type == "internal" &&
            Math.abs(row?.claims_amount) > 0 ? (
              <div className="data-table-action">
                <InfoIconButton
                  onClick={() =>
                    handleViewDetails({
                      title: "other added",
                      row,
                      user_id: row?.id,
                      month_year: `${props.payrollList.month}-${props.payrollList.year}`,
                    })
                  }
                />
              </div>
            ) : null}
          </div>
        ),
    },
    {
      name: t("penalties"),
      wrap: true,
      selector: "penalties_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.penalties_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: {
            ...externalTableStyle,
            // padding: "5px 36px 5px 2px !important",
          },
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) =>
        row?.config_type == "external" ? (
          <div className="w-100 text-left font-weight-bold external-style">
            {t("externally")}
          </div>
        ) : (
          <div className="d-flex align-items-center w-100 justify-content-between">
            <TableCell
              configType={row?.config_type}
              symbol={row?.currency?.symbol}
              value={row?.penalties_amount}
            />

            {row?.config_type == "internal" &&
            Math.abs(row?.penalties_amount) > 0 ? (
              <div className="data-table-action">
                <InfoIconButton
                  onClick={() =>
                    handleViewDetails({
                      title: "penalty",
                      row,
                      user_id: row?.id,
                      month_year: `${props.payrollList.month}-${props.payrollList.year}`,
                    })
                  }
                />
              </div>
            ) : null}
          </div>
        ),
    },
    {
      name: t("social"),
      wrap: true,
      selector: "social_amount",
      sortable: false,
      grow: 1.3,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.social_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
        {
          when: (row) => row?.social_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={row?.config_type}
            symbol={row?.currency?.symbol}
            value={
              props.data[dataTableRef].monthly_amount
                ? row?.monthly_social
                : row?.social_amount
            }
          />
          {row?.payment_status == 4 &&
            !row?.isExtra &&
            row?.config_type !== "external" && (
              <HasPrivileges
                reqireMain={[Privilages.EDIT_SOCIAL_MEDICAL_TAXES_AMOUNT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action">
                  <span
                    className="btn p-0"
                    onClick={() =>
                      handleEditAmount({
                        title: "Social Insurance",
                        currency: row?.currency?.symbol,
                        deductible_id: 1,
                        monthlyId: row?.payroll_id,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
    {
      name: t("medical"),
      wrap: true,
      selector: "medical_amount",
      sortable: false,
      grow: 1.3,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.medical_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
        {
          when: (row) => row?.medical_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={row?.config_type}
            symbol={row?.currency?.symbol}
            value={
              props.data[dataTableRef].monthly_amount
                ? row?.monthly_medical
                : row?.medical_amount
            }
          />
          {row?.payment_status == 4 &&
            !row?.isExtra &&
            row?.config_type !== "external" && (
              <HasPrivileges
                reqireMain={[Privilages.EDIT_SOCIAL_MEDICAL_TAXES_AMOUNT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action">
                  <span
                    className="btn p-0"
                    onClick={() =>
                      handleEditAmount({
                        title: "Medical Insurance",
                        currency: row?.currency?.symbol,
                        deductible_id: 2,
                        monthlyId: row?.payroll_id,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
    {
      name: t("taxes"),
      wrap: true,
      selector: "taxes_amount",
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.taxes_amount == 0,
      sortable: false,
      grow: 1.3,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
        {
          when: (row) => row?.taxes_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={row?.config_type}
            symbol={row?.currency?.symbol}
            value={
              props.data[dataTableRef].monthly_amount
                ? row?.monthly_taxes
                : row?.taxes_amount
            }
          />
          {row?.payment_status == 4 &&
            !row?.isExtra &&
            row?.config_type !== "external" && (
              <HasPrivileges
                reqireMain={[Privilages.EDIT_SOCIAL_MEDICAL_TAXES_AMOUNT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action">
                  <span
                    className="btn p-0"
                    onClick={() =>
                      handleEditAmount({
                        title: "Taxes",
                        currency: row?.currency?.symbol,
                        deductible_id: 3,
                        monthlyId: row?.payroll_id,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
    {
      name: t("others"),
      wrap: true,
      selector: "all_other_deductibles_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.all_other_deductibles_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={row?.config_type}
            symbol={row?.currency?.symbol}
            value={
              props.data[dataTableRef].monthly_amount
                ? row?.monthly_deductibles
                : row?.all_other_deductibles_amount
            }
          />
          {row?.config_type == "internal" &&
          (Math.abs(row?.monthly_deductibles) > 0 ||
            Math.abs(row?.all_other_deductibles_amount) > 0) ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    title: "other deductables",
                    row,
                    user_id: row?.id,
                    month_year: `${props.payrollList.month}-${props.payrollList.year}`,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("payable"),
      wrap: true,
      selector: "payable_amount",
      sortable: false,
      grow: 1.4,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.payable_amount == 0,
      style: {
        backgroundColor: "#f8f9fa",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100">
          <TableCell
            configType={row?.config_type}
            symbol={row?.currency?.symbol}
            value={
              props.data[dataTableRef].monthly_amount
                ? row?.monthly_payable_amount
                : row?.payable_amount
            }
            allowNegativeValue={true}
          />
          {!row?.isExtra &&
            row?.payment_status == "3" &&
            row?.config_type !== "external" &&
            !props.data[dataTableRef]?.monthly_amount && (
              <HasPrivileges
                reqireMain={[Privilages.VIEW_MONTHLY_PAYSLIPS]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action ml-1">
                  <span
                    className="payslip_icon_style btn p-0"
                    onClick={() => toggleShowPayslipModal(row)}
                  >
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
  ];

  // (Start) Update Payroll Payemnet
  const [usersMonthlyIds, setUserMonthlyIds] = useState([]);

  const handleSelectedRows = (selectedRowState) => {
    setUserMonthlyIds(
      selectedRowState?.selectedRows?.map((user) => user.payroll_id)
    );
  };

  const handleSubmitPayment = () => {
    // Reset State
    setUserMonthlyIds([]);

    props?.submitPayrollPaymentAction({
      ids: usersMonthlyIds,
      status_id: 3,
      ref: "submitPayrollPayment",
      fetchPayrollReportData: {
        ref: dataTableRef,
        [dataTableRef]: props.data[dataTableRef],
      },
    });
  };

  const handleReversePayment = (monthlyId) => {
    props.reversePayrollPaymentAction({
      ids: [monthlyId],
      status_id: 4,
      ref: "reversePaymentLoading",
      fetchPayrollReportData: {
        ref: dataTableRef,
        [dataTableRef]: props.data[dataTableRef],
      },
    });
  };
  // (End) Update Payroll Payemnet

  function roundToTwo(num) {
    // return +(Math.round(num + "e+2") + "e-2");
    return num.toFixed(2);
  }

  const handleShowExportPayroll = () => {
    props.fetchExportPayrollOptionsAction();
  };

  const handlePayrollFilter = (event, value) => {
    let e = {
      target: {
        name: event.target.name,
        value,
      },
    };
    props.onInputChangeAction(dataTableRef, e);
  };

  return (
    <>
      <HasPrivileges
        reqireMain={[Privilages.VIEW_PAYROLL_LIST]}
        allowBP
        altChildren={
          <div className="row align-items-center justify-content-end">
            <div className="w-25 mb-3 pr-3">
              <BSelect
                name="year"
                optionLabel="value"
                optionValue="value"
                options={Constants.YearData}
                formName={dataTableRef}
                keepDefaultStyle
                containerStyle="year-picker"
                icon="calendar"
              />
            </div>
          </div>
        }
      >
        <>
          <div className="row align-items-center">
            <div className="col-xl-3 col-12 row mx-0 mb-xl-0 mb-2 px-0">
              <div className="col-4">
                <BSelect
                  name="year"
                  optionLabel="value"
                  optionValue="value"
                  options={Constants.YearData}
                  formName={dataTableRef}
                  keepDefaultStyle
                  containerStyle="year-picker"
                  icon="calendar"
                />
              </div>
              <div className="col-8">
                <BSelect
                  name="month"
                  optionValue="key"
                  options={Constants.MonthData}
                  formName={dataTableRef}
                  keepDefaultStyle
                  containerStyle="month-picker"
                  getOptionLabel={(option) =>
                    props?.closedMonths?.includes(`${option?.key}`)
                      ? `${t(option?.value)} - ${t("closed")}`
                      : t(option?.value)
                  }
                  icon="calendar"
                />
              </div>
            </div>

            <div className="col-xl-4 col-12 row mx-0 px-0">
              <div className="col-4">
                <BSelect
                  name="currency_id"
                  formName={dataTableRef}
                  options={props?.data?.payrollCurrenciesFilter}
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t("currencies")}
                  isClearable
                  icon="currency"
                />
              </div>

              <div className="col">
                <BSelect
                  formName={dataTableRef}
                  name="office_id"
                  optionLabel="name"
                  optionValue="id"
                  options={[
                    { name: t("all offices"), id: "" },
                    ...props?.user?.offices,
                  ]}
                  keepDefaultStyle
                  // isClearable
                  placeholder={t("Offices")}
                  icon="office"
                />
              </div>

              {props.data[dataTableRef].office_id === "" ? (
                <div className="col-4">
                  <BSelect
                    name="regulation_id"
                    optionLabel="name"
                    optionValue="id"
                    options={props?.data?.regualtionTypesOptions}
                    formName={dataTableRef}
                    placeholder={t("select regulation")}
                    keepDefaultStyle
                    isClearable
                    icon="money"
                  />
                </div>
              ) : null}
            </div>

            <div className="col-xl-3 col-12">
              <BSelect
                name="department_id"
                optionLabel="name"
                optionValue="id"
                options={props?.allDepartmentsByCompany}
                formName={dataTableRef}
                placeholder={t("select department")}
                keepDefaultStyle
                isClearable
                icon="department"
              />
            </div>

            <div className="d-flex align-items-center col">
              <label className="mx-2">{t("monthly payroll view")}</label>
              <AntSwitch
                name="monthly_amount"
                // value={props.data[dataTableRef].monthly_amount}
                onChange={handlePayrollFilter}
                checked={props.data[dataTableRef].monthly_amount}
              />
            </div>
          </div>
          {props.data[dataTableRef].currency_id && (
            <div className="row">
              <div className="col-xl-4 col-12">
                <PayrollWidget
                  WidgetIcon="total_net.svg"
                  title={t("total net pay")}
                  currency={selectedCurrency?.name}
                  amount={props?.payrollWidgets.total_net_paid}
                  WidgetStatusColor="total_net_pay"
                />
              </div>
              <div className="col-xl-4 col-12">
                <PayrollWidget
                  WidgetIcon="total_paid.svg"
                  title={t("total paid")}
                  currency={selectedCurrency?.name}
                  amount={props?.payrollWidgets.total_paid}
                  WidgetStatusColor="total_paid"
                />
              </div>
              <div className="col-xl-4 col-12">
                <PayrollWidget
                  WidgetIcon="pending.svg"
                  title={t("pending payment")}
                  currency={selectedCurrency?.name}
                  amount={props?.payrollWidgets.pending_payment}
                  WidgetStatusColor="pending_payment"
                />
              </div>
            </div>
          )}

          <div className="row my-3">
            <div className="col-12 col-xl-4">
              <div className="row align-items-center">
                <div className="col-12 col-xl-7 mb-xl-0 mb-2">
                  <RadioboxForm
                    formName={dataTableRef}
                    options={Constants.PayrollStatusFilter}
                    name="status"
                    type="radio"
                    containerStyle="my-2 my-lg-0"
                    labelStyle="label-style"
                    optionsContainerStyle="optionsContainerStyle flex-nowrap"
                    optionItemStyle="optionItemStyle payroll_option_items"
                    optionInputStyle=" "
                    optionLabelStyle="optionLabelStyle"
                  />
                </div>

                <div className="col-12 col-xl-5">
                  <BSelect
                    reducer="super"
                    name="user_id"
                    formName={dataTableRef}
                    options={props?.data?.payrollEmployeesFilter}
                    keepDefaultStyle
                    optionLabel="name"
                    optionValue="id"
                    isClearable
                    placeholder={t("employee")}
                    icon="person"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-xl-4 d-flex align-items-center">
              <label className="mx-2">
                {t("show externally managed employees")}
              </label>
              <AntSwitch
                name="show_external"
                onChange={handlePayrollFilter}
                checked={props.data[dataTableRef].show_external}
              />
            </div>

            <div className="col">
              <div className="d-flex justify-content-end p-t-5">
                <HasPrivileges
                  reqireMain={[Privilages.EXPORT_PAYROLL_TABLE]}
                  allowBP
                  avalibleOnExpire={false}
                  altExpireChildren={
                    <div>
                      <button
                        className="btn add_new_btn_style export_btn_style"
                        type="button"
                      >
                        <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                        {t("export payroll")}
                      </button>
                    </div>
                  }
                >
                  <div>
                    <button
                      className="btn add_new_btn_style export_btn_style"
                      type="button"
                      onClick={handleShowExportPayroll}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                      {t("export payroll")}
                    </button>
                  </div>
                </HasPrivileges>

                <HasPrivileges
                  reqireMain={[Privilages.SUBMIT_PAYMENTS]}
                  allowBP
                  avalibleOnExpire={false}
                  altExpireChildren={
                    <div>
                      <button
                        className="btn add_new_btn_style ml-3"
                        type="button"
                      >
                        {t("Submit Payment")}
                      </button>
                    </div>
                  }
                >
                  <div>
                    <button
                      className="btn add_new_btn_style ml-3"
                      type="button"
                      onClick={handleSubmitPayment}
                      disabled={
                        !usersMonthlyIds?.length || props?.isSubmitLoading
                      }
                    >
                      {props?.isSubmitLoading ? (
                        <Spinner
                          style={{
                            width: "1rem",
                            height: "1rem",
                            color: "#fff",
                          }}
                        />
                      ) : (
                        t("Submit Payment")
                      )}
                    </button>
                  </div>
                </HasPrivileges>
              </div>
            </div>
          </div>
        </>
      </HasPrivileges>

      <DataTable
        className="requests-profile-data-list payroll_main_datatable_wrapper_style"
        columns={groupColumns}
        data={[]}
        noHeader
        noTableHead={
          !props.data[dataTableRef].data?.length ||
          props.data[dataTableRef].isLoading
        }
        persistTableHead
        customStyles={{
          noData: "d-flex flex-column",
        }}
        noDataComponent={
          <DataTable
            noDataComponent={<div className="p-4"> {t("no_records")} </div>}
            className="requests-profile-data-list payroll_datatable_wrapper_style"
            columns={columns}
            noTableHead={
              !props.data[dataTableRef].data?.length ||
              props.data[dataTableRef].isLoading
            }
            data={
              props?.data[dataTableRef]?.currency_id &&
              props.data[dataTableRef].data?.length &&
              props?.extraPayrollRow?.user?.name
                ? [
                    {
                      ...props?.extraPayrollRow,
                      currency: {
                        symbol: selectedCurrency?.symbol,
                      },
                    },
                    ...props.data[dataTableRef].data,
                  ]
                : props.data[dataTableRef].data
            }
            selectableRows
            onSelectedRowsChange={handleSelectedRows}
            selectableRowDisabled={(row) =>
              row?.payment_status == "3" || row?.isExtra
            }
            clearSelectedRows={props.data[dataTableRef].isLoading}
            selectableRowsComponentProps={(row) => row}
            selectableRowsComponent={forwardRef((e, d) => {
              // console.log("sss", e, d);
              if (!e.disabled) {
                return (
                  <div className="mr-2">
                    <CheckboxBooleanForm
                      formName={dataTableRef}
                      options={[""]}
                      name={e.name}
                      type="checkbox"
                      labelStyle="checkbox"
                      containerStyle="containerStyle"
                      optionsContainerStyle="d-flex flex-column"
                      optionItemStyle="optionItemStyle mr-0"
                      optionInputStyle="optionInputStyle containerStyle checkbox fix-checkbox-payroll-datable-responsive"
                      optionLabelStyle="optionLabelStyle ml-4 my-1 w-100"
                      optionLabelActiveStyle="optionLabelActiveStyle"
                      // onChange={e.onChange}
                      {...e}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="fix-checkbox-payroll-datable-responsive"></div>
                );
              }
            })}
            noHeader
            persistTableHead
            sortServer
            defaultSortAsc={props.data[dataTableRef].sorting.dir == "asc"}
            defaultSortField={props.data[dataTableRef].sorting.key}
            paginationComponent={() => (
              <Pagination
                tableRef={dataTableRef}
                styleWraper=""
                onPaginate={handlePaginate}
                reducer="super"
              />
            )}
            pagination={true}
            paginationServer={true}
            progressPending={props.data[dataTableRef].isLoading}
            progressComponent={<Loader />}
          />
        }
      />

      {/* Start of Export Modal */}
      <ExportModal
        modal_isOpen={ShowExportModal}
        modal_toggle={toggleShowExportModal}
        dataTableRef={dataTableRef}
      />
      {/* End of Export Modal  */}

      {/* Start of Payslip Modal */}
      <PayslipModal
        {...payslipDetails}
        modal_isOpen={ShowPayslipModal}
        modal_toggle={toggleHidePayslipModal}
      />
      {/* End of Payslip Modal  */}

      {/* Start of Edit amount Modal */}
      <EditAmountModal
        fetchPayrollReportData={{
          ref: dataTableRef,
          [dataTableRef]: props?.data[dataTableRef],
        }}
      />
      {/* End of Edit amount  */}
      {/* Strt of View payroll cell details  */}
      <ViewPayrollDetails
        isModalVissible={isDetailsModalOpen}
        dismissModal={dismissDetailsModal}
        data={detailsModalData}
        isLoading={
          loadingOvertimeDetails ||
          loadingBonusDetails ||
          loadingPenaltyDetails ||
          loadingOtherDeductDetails ||
          loadingOtherAddedDetails ||
          loadingAllowancesDetails
        }
      />
      {/* End of Edit amount  */}
      <ExportPayrollModal />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    user: state.auth.userProfile,
    payrollWidgets: state.super.payrollWidgets,
    extraPayrollRow: state.super.extraPayrollRow,
    isSubmitLoading: state.super.submitPayrollPayment?.isLoading,
    reverseLoading: state.super.reversePaymentLoading,
    selectedOfficeId: state.super.payrollList.office_id,
    closedMonths: state.super.closedPayrollMonths,
    allDepartmentsByCompany: state.super.allDepartmentsByCompany,
    payrollList: state?.super?.payrollList,
  };
};

export default connect(mapStateToProps, {
  showEditAmountModalAction,
  fetchPayrollReportListAction,
  onInputResetAction,
  submitPayrollPaymentAction,
  reversePayrollPaymentAction,
  fetchUserPaySlipAction,
  fetchExportPayrollOptionsAction,
  onInputChangeAction,
})(YearlyPayroll);
