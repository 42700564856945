import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchSetupWizardEssentialDataAttempt,
  setSetupWizardRemainingSteps,
  setSetupWizardActiveTab,
} from "../../Store/Actions";
import {
  getWizardDataStepsCounts,
  getInitialActiveStep,
} from "../../Helpers/HelperFns/SetupWizard";
import Constants from "../../Constants";

import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import SetupWizardTabs from "../../Containers/SetupWizardTabs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SetupWizardAttType from "../../Containers/SetupWizardTabs/SetupWizardAttType";
import SetupWizardDepartments from "../../Containers/SetupWizardTabs/SetupWizardDepartments";
import SetupWizardOffice from "../../Containers/SetupWizardTabs/SetupWizardOffice";
import SetupWizardPositions from "../../Containers/SetupWizardTabs/SetupWizardPositions";
import SetupWizardAttendanceProfile from "../../Containers/SetupWizardTabs/SetupWizardAttendanceProfile";
import SetupWizardLeavesBreaks from "../../Containers/SetupWizardTabs/SetupWizardLeavesBreaks";
import SetupWizardWorkTimings from "../../Containers/SetupWizardTabs/SetupWizardWorkTimings";
import SetupWizardWorkGroup from "../../Containers/SetupWizardTabs/SetupWizardWorkGroup";

const officeBased = Constants.attendanceTypes.OFFICE_BASED;

const SetupWizardPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer State
  const {
    wizardAttType: attType,
    companyWizardActiveTab,
    setupWizardEssentialInfo,
    setupWizardinitialLoading,
  } = useSelector((state) => state.wizard);

  // Constants
  const tabs = React.useMemo(
    () => [
      <SetupWizardOffice />,
      <SetupWizardDepartments />,
      <SetupWizardPositions />,
      <SetupWizardAttType />,
      <SetupWizardWorkTimings />,
      <SetupWizardLeavesBreaks />,
      ...(attType
        ? attType === officeBased
          ? [<SetupWizardAttendanceProfile />]
          : [<SetupWizardWorkGroup />]
        : []),
    ],
    [attType]
  );

  /* ↓ State Effects ↓ */

  useEffect(() => {
    const handleRedirecting = () => history.push("/employees");
    dispatch(fetchSetupWizardEssentialDataAttempt(handleRedirecting));
  }, []);

  useEffect(() => {
    if (!setupWizardinitialLoading) {
      // Handle initial active tab
      const activeTab = getInitialActiveStep(setupWizardEssentialInfo);
      dispatch(setSetupWizardActiveTab(activeTab));

      // Handle remaining steps
      const counts = getWizardDataStepsCounts(setupWizardEssentialInfo);
      dispatch(
        setSetupWizardRemainingSteps(
          counts.filter((count) => !Boolean(count)).length
        )
      );
    }
  }, [setupWizardinitialLoading]);

  return (
    <div className="setup-wizard-layout-wrapper">
      {/* Header */}
      <header className="text-center">
        <Link
          to="/employees"
          className="d-flex mb-3 back-btn d-flex align-items-center position-sticky fit-width"
        >
          <KeyboardBackspaceIcon className="back-icon" />
          <span>{t("exit")}</span>
        </Link>
        <h1 className="dark-color">{t("Welcome to Mawared HR System")}</h1>
        <p className="text-16">
          {t(
            "To be able to get the best out of Mawared, we need to set up the company first"
          )}
        </p>
      </header>

      {/* Tabs */}
      <SetupWizardTabs steps={tabs?.length} />

      {/* Panels */}
      {setupWizardinitialLoading ? (
        <Loader fixed />
      ) : (
        tabs[companyWizardActiveTab - 1]
      )}
    </div>
  );
};

export default SetupWizardPage;
