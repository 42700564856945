import React, { useEffect } from "react";
import { BSelect } from "form-builder";
import { connect } from "react-redux";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  showUpsertDocModal,
  onInputChangeAction,
  storeDocumentableAttempt,
  showDocumentsEdit,
  hideDocumentsEdit,
} from "../../Store/Actions";

import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { Spinner } from "reactstrap";
import HelperFns from "../../Helpers/HelperFns";
import { useTranslation } from "react-i18next";
import IconButtonWithTooltip, {
  EditIconButton,
  TimesIconButton,
} from "../../Components/IconButtonWithTooltip";

const formName = "companyDocsForm";

const CompanyDocs = (props) => {
  const { t } = useTranslation();

  const handleEdit = (type) => {
    const isRequiredInput = type === "required_documents";
    props?.showDocumentsEdit({
      ref: isRequiredInput
        ? "companyRequiredDocumentsActions"
        : "companyOptionalDocumentsActions",
    });
  };

  const handleCancel = (type) => {
    // Reset Selected Ids
    props?.onInputChangeAction(
      formName,
      { name: type },
      "select",
      props?.[type]?.map((doc) => doc.id)
    );
    // Toggle Edit Inputs
    const isRequiredInput = type === "required_documents";
    props?.hideDocumentsEdit({
      ref: isRequiredInput
        ? "companyRequiredDocumentsActions"
        : "companyOptionalDocumentsActions",
    });
  };

  // Reset And Close Inputs If It's Still Vissible
  useEffect(() => {
    props?.requiredActions?.isVissible && handleCancel("required_documents");
    props?.optionalActions?.isVissible && handleCancel("optional_documents");
  }, []);

  const handleAccept = (type) => {
    const isRequiredInput = type === "required_documents";
    props?.storeDocumentableAttempt({
      input: {
        documentable_id: props?.company_id,
        documentable_type: "Company",
        [isRequiredInput ? "required_documents_ids" : "optional_documents_ids"]:
          props?.companyDocsForm?.[type],
      },
      resetInputData: [
        formName,
        { name: type },
        "select",
        props?.[type]?.map((doc) => doc.id),
      ],
      formName: isRequiredInput
        ? "companyRequiredDocuments"
        : "companyOptionalDocuments",
      ref: isRequiredInput
        ? "companyRequiredDocumentsActions"
        : "companyOptionalDocumentsActions",
    });
    // setEditDocs({
    //   ...editDocs,
    //   [type]: false,
    // });
  };

  const handleShowUpsertDocModal = () => {
    props?.showUpsertDocModal();
  };

  return (
    <div className="card_container company_docs">
      <div className="d-flex align-items-center docs_title">
        <h4 className="sub-title-style mb-0">{t("company level documents")}</h4>
      </div>
      <h6 className="docs_label">{t("required documents")}</h6>
      {props?.requiredActions?.isVissible ? (
        <div className="d-flex align-items-center gap-20">
          <BSelect
            reducer="documents"
            name="required_documents"
            formName={formName}
            options={props?.documentsListOptions?.filter(
              (doc) => !props?.[formName]?.optional_documents?.includes(doc.id)
            )}
            keepDefaultStyle
            optionValue="id"
            isMulti
            placeholder={t("select documents")}
            rootStyle="flex-1"
            getOptionLabel={(option) => HelperFns.getCountryShortName(option)}
            icon="document"
          />
          <div>
            {props?.requiredActions?.isLoading ? (
              <Spinner
                className="mx-3"
                style={{
                  width: "1rem",
                  height: "1rem",
                  color: "#23aaeb",
                }}
              />
            ) : (
              <>
                <IconButtonWithTooltip
                  label="add new document"
                  onClick={handleShowUpsertDocModal}
                  icon={
                    <FontAwesomeIcon size="sm" icon={faPlus} color="#2764ac" />
                  }
                />
                <IconButtonWithTooltip
                  label="save"
                  className="mx-2"
                  onClick={() => handleAccept("required_documents")}
                  icon={
                    <FontAwesomeIcon size="sm" icon={faCheck} color="#27b40c" />
                  }
                />
                <TimesIconButton
                  label="Cancel"
                  onClick={() => handleCancel("required_documents")}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="docs_choices">
            {!props?.required_documents?.length
              ? "-------"
              : props?.required_documents
                  .map((doc) => HelperFns.getCountryShortName(doc))
                  ?.join(" - ")}
          </h6>
          <HasPrivileges
            reqireMain={[Privilages.MANAGE_COMPANY_DOCUMENTS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<EditIconButton />}
          >
            <EditIconButton onClick={() => handleEdit("required_documents")} />
          </HasPrivileges>
        </div>
      )}
      <hr />
      <h6 className="docs_label">{t("optional documents")}</h6>
      {props?.optionalActions?.isVissible ? (
        <div className="d-flex align-items-center gap-20">
          <BSelect
            reducer="documents"
            name="optional_documents"
            formName={formName}
            options={props?.documentsListOptions?.filter(
              (doc) => !props?.[formName]?.required_documents?.includes(doc.id)
            )}
            keepDefaultStyle
            optionValue="id"
            isMulti
            placeholder={t("select documents")}
            rootStyle="flex-1"
            getOptionLabel={(option) => HelperFns.getCountryShortName(option)}
            icon="document"
          />

          <div className="">
            {props?.optionalActions?.isLoading ? (
              <Spinner
                className="mx-3"
                style={{
                  width: "1rem",
                  height: "1rem",
                  color: "#23aaeb",
                }}
              />
            ) : (
              <>
                <IconButtonWithTooltip
                  label="add new document"
                  onClick={handleShowUpsertDocModal}
                  icon={
                    <FontAwesomeIcon size="sm" icon={faPlus} color="#2764ac" />
                  }
                />
                <IconButtonWithTooltip
                  label="save"
                  buttonProps={{ className: "mx-2" }}
                  onClick={() => handleAccept("optional_documents")}
                  icon={
                    <FontAwesomeIcon size="sm" icon={faCheck} color="#27b40c" />
                  }
                />
                <TimesIconButton
                  label="Cancel"
                  onClick={() => handleCancel("optional_documents")}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="docs_choices">
            {!props?.optional_documents?.length
              ? "-------"
              : props?.optional_documents.map((doc) => doc?.name)?.join(" - ")}
          </h6>
          <HasPrivileges
            reqireMain={[Privilages.MANAGE_COMPANY_DOCUMENTS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<EditIconButton />}
          >
            <EditIconButton onClick={() => handleEdit("optional_documents")} />
          </HasPrivileges>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    required_documents: state.documents?.companyRequiredDocuments,
    optional_documents: state.documents?.companyOptionalDocuments,
    documentsListOptions: state.documents?.documentsListOptions,
    company_id: state.auth?.userProfile?.company?.id,
    requiredActions: state.documents?.companyRequiredDocumentsActions,
    optionalActions: state.documents?.companyOptionalDocumentsActions,
    companyDocsForm: state.documents?.companyDocsForm,
  };
};

export default connect(mapStateToProps, {
  showUpsertDocModal,
  onInputChangeAction,
  storeDocumentableAttempt,
  showDocumentsEdit,
  hideDocumentsEdit,
})(CompanyDocs);
