import React from "react";
import { CheckboxForm, BSelect } from "form-builder";
import moment from "moment";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import Constants from "../../Helpers/Constants";
import * as REQUESTS from "../../Constants/Requests";
import { faEye, faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import {
  cancelRequestAction,
  acceptInboundRequestAction,
  rejectInboundRequestAction,
  showOvertimeModalAction,
  showViewAttRequestModal,
  fetchEditAccpetedOvertimeRequestDataAction,
  showRequestCommentsModalAction,
  showEditLeavesModalAction,
} from "../../Store/Actions";
import OvertimeModal from "../InboundRequests/OvertimeModal";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";
import AttRequestInfoModal from "../InboundRequests/AttRequestInfoModal";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import HelperFns from "../../Helpers/HelperFns";
import RequestCommentsModal from "../../Components/RequestCommentsModal";
import EditLeavesModal from "../../Components/EditLeavesModal";
import IconButtonWithTooltip, {
  AcceptIconButton,
  DownloadFileIconButton,
  EditIconButton,
  TimesIconButton,
} from "../../Components/IconButtonWithTooltip";
import FloatingMenu from "../../Components/FloatingMenu";
import ShowMoreText from "react-show-more-text";

const MyRequests = ({
  employeeRequestsData,
  pagination,
  refetchQueries,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();

  // handle Request comments modal
  const handleRequestComments = (row) => {
    let data = {
      id: row?.id,
    };
    props.showRequestCommentsModalAction({
      ref: "requestCommentModalActions",
      formName: "requestCommentModal",
      data,
    });
  };

  const handleCancelRequest = (row) => {
    if (!props.hasActiveSubscription) {
      return;
    }

    if (row?.requestType?.id == REQUESTS.PERMISSIONS) {
      swal({
        title: t("default_warning_cancel_message"),
        icon: "warning",
        className: "swal-warning-style",
        dangerMode: true,
        buttons: [t("Cancel"), t("OK")],
      }).then((willCancel) => {
        if (willCancel) {
          props.cancelRequestAction(
            row?.id,
            null,
            null,
            props.employeeId,
            refetchQueries
          );
        }
      });
      return;
    }

    if (
      moment(row?.time_from).format("yyyy-MM-DD") <=
        moment(new Date()).format("yyyy-MM-DD") &&
      moment(new Date()).format("yyyy-MM-DD") <=
        moment(row?.time_to).format("yyyy-MM-DD") &&
      row?.time_from != null &&
      row?.time_to != null
    ) {
      // Start of handling edit leaves modal
      let data = {
        id: row?.id,
        time_from:
          row?.time_from != null
            ? moment(row?.time_from).format("yyyy-MM-DD")
            : null,
        time_to:
          row?.time_to != null
            ? moment(row?.time_to).format("yyyy-MM-DD")
            : null,
        employeeId: props.employeeId,
      };
      props.showEditLeavesModalAction({
        data,
        ref: "editLeavesModalActions",
        formName: "editLeavesModal",
      });
      // End of handling edit leaves modal
    } else {
      swal({
        title: t("default_warning_cancel_message"),
        icon: "warning",
        className: "swal-warning-style",
        dangerMode: true,
        buttons: [t("Cancel"), t("OK")],
      }).then((willCancel) => {
        if (willCancel) {
          props.cancelRequestAction(
            row?.id,
            null,
            null,
            props.employeeId,
            refetchQueries
          );
        }
      });
    }
  };

  const handleAcceptRequest = (row) => {
    swal({
      title: t("default_warning_accept_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        if (row?.requestType?.id !== REQUESTS.OVERTIME) {
          props.acceptInboundRequestAction(
            row?.id,
            props.employeeId,
            null,
            false,
            refetchQueries
          );
          return;
        }
      }
    });
  };

  const handleRejectRequest = (row) => {
    swal({
      title: t("default_warning_reject_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.rejectInboundRequestAction(
          row.id,
          props.employeeId,
          null,
          false,
          refetchQueries
        );
      }
    });
  };

  // for overtime modal
  const handleAcceptOvertimeRequest = (data) => {
    let payable_at =
      data?.status?.toLowerCase() === "pending"
        ? data?.req_day
        : data?.payable_at;

    let acceptOvertimeData = {
      id: data?.id,
      name: data?.employee?.user?.name,
      interval_in_time: data?.interval_in_time,
      created_at: payable_at,
      req_day: data?.req_day,
    };
    props.showOvertimeModalAction({
      ref: "overtimeModalActions",
      formName: "overtimeModal",
      acceptOvertimeData,
    });
  };

  // edit overtime modal for approved request case
  const handleEditAcceptedOvertimeRequest = (data) => {
    let acceptOvertimeData = {
      id: data?.id,
      name: data?.employee?.user?.name,
      req_day: data?.req_day,
      page_flag: props?.pageFlag,
      modalType: "Edit",
    };
    props.showOvertimeModalAction({
      ref: "overtimeModalActions",
      formName: "overtimeModal",
      acceptOvertimeData,
    });
    props.fetchEditAccpetedOvertimeRequestDataAction(data?.id);
  };

  // (Start) Attendance Request Modal Info
  const handleShowAttRequestInfo = (row) => {
    if (!props.hasActiveSubscription) {
      return;
    }
    props?.showViewAttRequestModal(row);
  };
  // (End) Attendance Request Modal Info

  const handleRenderPendeningActions = (row) => {
    if (props.employeeId) {
      // request has no layers or it has layers and auth if one of pending acceptors
      if (
        row?.pending_acceptors?.length === 0 ||
        (row?.pending_acceptors?.legnth > 0 &&
          row?.pending_acceptors?.includes(+props?.authUserId))
      ) {
        return (
          <HasPrivileges
            reqireMain={[Privilages.MANAGE_EMPLOYEE_REQUESTS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div className="cards_table_actions">
                {row?.type === "Edit Attendance" ? (
                  <span className="btn p-0">
                    <IconButtonWithTooltip
                      icon={<FontAwesomeIcon icon={faEye} color="#313030" />}
                    />
                  </span>
                ) : (
                  <>
                    <AcceptIconButton />
                    <TimesIconButton />
                  </>
                )}
              </div>
            }
          >
            <div className="cards_table_actions">
              {props?.requstsLoader.includes(row.id) ? (
                <Spinner />
              ) : (
                <>
                  {row?.requestType?.id === REQUESTS.EDIT_ATTENDANCE ? (
                    <IconButtonWithTooltip
                      label="show request"
                      onClick={() => handleShowAttRequestInfo(row)}
                      icon={<FontAwesomeIcon icon={faEye} color="#313030" />}
                    />
                  ) : (
                    <>
                      {row.status?.toLowerCase() === "pending" ? (
                        <>
                          <AcceptIconButton
                            onClick={
                              row?.requestType?.id == REQUESTS.OVERTIME
                                ? () => handleAcceptOvertimeRequest(row)
                                : () => handleAcceptRequest(row)
                            }
                          />
                          <TimesIconButton
                            label="reject"
                            onClick={() => handleRejectRequest(row)}
                          />
                        </>
                      ) : row.status?.toLowerCase() === "accepted" &&
                        row?.requestType?.id != REQUESTS.OVERTIME &&
                        HelperFns.canCancelRequest(row) ? (
                        <TimesIconButton
                          label="Cancel"
                          onClick={() => handleCancelRequest(row)}
                        />
                      ) : null}
                    </>
                  )}
                </>
              )}
            </div>
          </HasPrivileges>
        );
      }
    }
    if (!props.employeeId) {
      let currentStatus = row.status.toLowerCase();
      if (
        currentStatus === "rejected" ||
        row?.requestType?.id === REQUESTS.OVERTIME
      ) {
        return;
      }
      return (
        <div className="">
          {props?.requstsLoader?.includes(row.id) ? (
            <Spinner style={{ margin: "0 22px" }} />
          ) : (
            <div
              expired={!props.hasActiveSubscription}
              className="cards_table_actions"
            >
              <TimesIconButton
                label="Cancel"
                onClick={() => handleCancelRequest(row)}
              />

              {row?.requestType?.id === REQUESTS.EDIT_ATTENDANCE && (
                <IconButtonWithTooltip
                  label="show request"
                  onClick={() => handleShowAttRequestInfo(row)}
                  icon={<FontAwesomeIcon icon={faEye} color="#313030" />}
                />
              )}
            </div>
          )}
        </div>
      );
    }
  };

  const handleRenderOvertimeActions = (row) => {
    if (props.employeeId && row.status?.toLowerCase() === "accepted") {
      return (
        <HasPrivileges
          reqireMain={[Privilages.MANAGE_EMPLOYEE_REQUESTS]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="cards_table_actions">
              <EditIconButton />
            </div>
          }
        >
          <div className="cards_table_actions">
            <EditIconButton
              onClick={() => handleEditAcceptedOvertimeRequest(row)}
            />
          </div>
        </HasPrivileges>
      );
    }
  };

  const columns = [
    {
      name: t("type"),
      wrap: true,
      selector: "request_type_id",
      sortable: true,
      grow: 1.25,
      cell: (row) =>
        `${t(`${row.requestType?.name?.toLowerCase()}`)} ${
          row?.applicable?.__typename === "Exception"
            ? `(${row?.applicable?.assignment?.name})`
            : ""
        }`,
    },
    {
      name: t("requested for"),
      selector: "time_from",
      wrap: true,
      cell: (row) => {
        switch (row?.requestType?.id) {
          case REQUESTS.NORMAL_LEAVES:
          case REQUESTS.EMERGENCY_LEAVES:
          case REQUESTS.UNPAID_LEAVES:
          case REQUESTS.SICK_LEAVES:
            return (
              <div className="p-0 m-0">
                <p className="p-0 m-0">
                  {t("from")} {moment(row.time_from).format("DD/MM/yyyy")}
                </p>

                <p className="p-0 m-0">
                  {t("to")} {moment(row.time_to).format("DD/MM/yyyy")}
                </p>
              </div>
            );

          case REQUESTS.BEREAVEMENT:
          case REQUESTS.MATERNITY_PATERNITY_LEAVE:
          case REQUESTS.HAJJ_LEAVE:
          case REQUESTS.STUDY_LEAVE:
          case REQUESTS.SABBATICAL_LEAVE:
          case REQUESTS.MARRIAGE_LEAVE:
            return (
              <div className="p-0 m-0">
                <div>
                  <p className="p-0 m-0">
                    {t("from")} {moment(row?.time_from).format("DD/MM/yyyy")}
                  </p>

                  <p className="p-0 m-0">
                    {t("to")} {moment(row?.time_to).format("DD/MM/yyyy")}
                  </p>
                </div>
                <div className="badge-info-border my-1 text-center">
                  <p className="m-0">{t(row?.payment_flag)}</p>
                </div>
              </div>
            );

          case REQUESTS.OVERTIME:
            return (
              <div className="p-0 m-0 w-100">
                <div>
                  <p className="p-0 m-0">
                    {t("day")} {moment(row?.req_day).format("DD/MM/yyyy")}
                  </p>
                </div>
                <div className="flex-row flex-wrap d-flex mt-2">
                  <p className="d-flex p-0 mr-2 mb-0">interval</p>
                  {row?.status?.toLowerCase() === "accepted"
                    ? moment(row?.payable_interval_in_time, "HH:mm:ss").format(
                        "HH:mm"
                      )
                    : moment(row?.interval_in_time, "HH:mm:ss").format("HH:mm")}
                </div>
              </div>
            );
          case REQUESTS.HOME:
            return `${moment(row.req_day).format("DD/MM/yyyy")}`;

          case REQUESTS.NORMAL_HALF_DAY:
          case REQUESTS.UNPAID_HALF_DAY:
          case REQUESTS.EMERGENCY_HALF_DAY:
            return (
              <div className="p-0 m-0">
                <p className="p-0 m-0">
                  {moment(row.req_day).format("DD/MM/yyyy")}
                </p>

                <p className="p-0 m-0">
                  {t(`${row?.add_info?.toLocaleLowerCase()}_half`)}{" "}
                </p>
              </div>
            );

          case REQUESTS.EDIT_ATTENDANCE:
          case REQUESTS.IGNORE_ASSIGNMENT:
            return (
              <p className="p-0 m-0">
                {moment(row?.req_day).format("DD/MM/yyyy")}
              </p>
            );

          default:
            return (
              <div className="p-0 m-0">
                <p className="p-0 m-0">
                  {moment(row.time_from).format("DD/MM/yyyy")}
                </p>

                <div className="d-flex align-items-center">
                  <p className="p-0 m-0">
                    {t("from")} {moment(row?.time_from).format("hh:mm a")}{" "}
                    {t("to")} {` `}
                    {moment(row?.time_to).format("hh:mm a")}
                  </p>
                </div>
              </div>
            );
        }
      },
      sortable: true,
      grow: 1.75,
    },
    {
      name: t("reviewed by"),
      wrap: true,
      sortable: false,
      grow: 1.3,
      cell: (row) =>
        row?.status?.toLowerCase() !== "pending" && row?.action_by == null ? (
          t("By System")
        ) : row?.action_by?.user_type?.toLowerCase() !== "owner" ? (
          <CanViewEmployeeProfile
            allowBP
            directManger={row?.action_by?.manager?.id}
            copiedManagers={row?.action_by?.copied_managers?.map(
              (cp) => cp?.id
            )}
            altChildren={<div className="col pl-0">{row?.action_by?.name}</div>}
          >
            <div className="col pl-0">
              <a
                className="employee-name"
                href={`/employees/employee-profile/${row?.action_by?.id}`}
              >
                {row?.action_by?.name}
              </a>
            </div>
          </CanViewEmployeeProfile>
        ) : (
          <div className="col pl-0">{row?.action_by?.name}</div>
        ),
    },
    {
      name: t("status"),
      wrap: true,
      selector: "status",
      sortable: true,
      grow: 0.75,
      cell: (row) => {
        switch (row.status) {
          case "Pending":
            return <span className="badge pending_status">{t("pending")}</span>;
          case "Rejected":
            return (
              <span className="badge rejected_status">{t("rejected")}</span>
            );
          case "Accepted":
            return (
              <div>
                <span className="badge approved_status my-2">
                  {t("accepted")}
                </span>
                {row?.requestType?.id === REQUESTS.OVERTIME ? (
                  <div>
                    {moment(
                      row?.subModel?.overtime_payable_at,
                      "MM-YYYY"
                    ).format("MMMM YYYY")}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );

          default:
            break;
        }
      },
    },
    {
      name: t("comments"),
      wrap: false,
      grow: 1,
      selector: "comments",
      ignoreRowClick: true,
      omit: HelperFns.checkPrivileges({
        allowBP: true,
        privileges: props?.employeeId
          ? [Privilages.VIEW_COMMENTS_ON_REQUESTS]
          : [Privilages.VIEW_COMMENTS_ON_MY_REQUESTS],
      })
        ? false
        : true,
      cell: (row) => (
        <HasPrivileges
          reqireMain={
            props?.employeeId
              ? [Privilages.VIEW_COMMENTS_ON_REQUESTS]
              : [Privilages.VIEW_COMMENTS_ON_MY_REQUESTS]
          }
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="d-flex align-items-baseline request_comments_in_list_wrapper_style">
              <div className="cards_table_actions">
                <IconButtonWithTooltip
                  icon={
                    <FontAwesomeIcon
                      icon={faComments}
                      className={
                        row?.number_of_comments !== 0
                          ? "comments_on_request_style"
                          : ""
                      }
                    />
                  }
                />
              </div>

              {row?.number_of_comments !== 0 ? (
                <div>{row?.number_of_comments}</div>
              ) : (
                ""
              )}
            </div>
          }
        >
          <div className="d-flex align-items-baseline request_comments_in_list_wrapper_style">
            <div className="cards_table_actions">
              <IconButtonWithTooltip
                label="show comments"
                onClick={() => handleRequestComments(row)}
                icon={
                  <FontAwesomeIcon
                    icon={faComments}
                    className={
                      row?.number_of_comments !== 0
                        ? "comments_on_request_style"
                        : ""
                    }
                  />
                }
              />
            </div>

            {row?.number_of_comments !== 0 ? (
              <div>{row?.number_of_comments}</div>
            ) : (
              ""
            )}
          </div>
        </HasPrivileges>
      ),
    },
    {
      name: t("notes"),
      wrap: true,
      grow: 0.5,
      center: true,
      selector: "layer_notes",
      ignoreRowClick: true,
      width: "250px",
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={250}
        >
          <div className="py-2">
            {(row?.layer_approvals ?? [])?.map((layer) => (
              <p className="mb-1">
                {layer?.acceptor
                  ? layer?.isApproved
                    ? t("layer-level-approval", {
                        level: layer?.level,
                        suffix: HelperFns.getOrderSuffix(layer?.level),
                        acceptor: layer?.acceptor?.name,
                      })
                    : t("layer-level-rejection", {
                        level: layer?.level,
                        suffix: HelperFns.getOrderSuffix(layer?.level),
                        acceptor: layer?.acceptor?.name,
                      })
                  : t("layer-level-pending", {
                      level: layer?.level,
                      suffix: HelperFns.getOrderSuffix(layer?.level),
                    })}
              </p>
            ))}
          </div>
        </ShowMoreText>
      ),
    },
    {
      name: "",
      wrap: true,
      selector: "action",
      grow: 0.3,
      style: "padding:0 !important;",
      cell: (row) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            {row?.requestType?.id === REQUESTS.OVERTIME &&
            row?.subModel?.can_edit_overtime_request
              ? handleRenderOvertimeActions(row)
              : row?.taken === 0
              ? handleRenderPendeningActions(row)
              : null}

            {row?.documents?.map((document) => (
              <DownloadFileIconButton
                key={document?.file?.id}
                href={document?.file?.path}
              />
            ))}
          </div>
        );
      },
      ignoreRowClick: true,
    },
  ];

  return (
    <div className="col-md-12 col-lg-6 my-2 my-requests requests_wrapper_style">
      {props?.editAcceptedOvertimeRequestLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex flex-wrap gap-20 justify-content-between align-items-md-start mb-2 requests-style">
        <div className="d-flex align-items-center gap-10">
          <div className="side-title mb-0">{t("requests")}</div>
          <FloatingMenu
            list={
              <CheckboxForm
                formName={props.dataTableRef}
                options={Constants.requestsStatusFilter?.map(
                  ({ label, value }) => ({
                    label: t(`${label.toLocaleLowerCase()}`),
                    value,
                  })
                )}
                reducer={props.reducer}
                name="statusFilter"
                type="checkbox"
                containerStyle="px-3"
                optionsContainerStyle="py-2 d-flex flex-column"
                optionItemStyle="optionItemStyle w-60px py-1"
                optionInputStyle=" "
                optionLabelStyle="optionLabelStyle"
                optionLabelActiveStyle="optionLabelActiveStyle"
              />
            }
          />
        </div>

        <div className="d-flex flex-wrap align-items-center gap-10">
          <BSelect
            name="monthFilter"
            rootStyle=""
            optionLabel="value"
            optionValue="key"
            options={Constants.AllMonthData}
            formName={props.dataTableRef}
            reducer={props.reducer}
            keepDefaultStyle
            containerStyle="month-picker"
            getOptionLabel={(option) => t(option.value)}
            placeholder={t("month")}
            icon="calendar"
          />
          <BSelect
            name="yearFilter"
            optionLabel="value"
            optionValue="value"
            options={Constants.YearData}
            formName={props.dataTableRef}
            reducer={props.reducer}
            keepDefaultStyle
            containerStyle="year-picker"
            placeholder={t("year")}
            icon="calendar"
          />
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table requests-data-table-custom"
        columns={columns}
        data={employeeRequestsData}
        noHeader
        persistTableHead
        sortServer
        onSort={props.onSorting}
        defaultSortAsc={props.data.sorting.dir == "asc"}
        defaultSortField={props.data.sorting.key}
        pagination={false}
        paginationServer={false}
        progressPending={isLoading}
        progressComponent={<Loader />}
      />
      <Pagination
        styleWraper=""
        onPaginate={props.onPaginate}
        customPaginator={pagination}
      />
      {/* Start Overtime modal */}
      <OvertimeModal refetchQueries={refetchQueries} />
      {/* End Overtime modal */}

      {/* Start View Att Request modal */}
      <AttRequestInfoModal
        employeeId={props.employeeId}
        pageFlag="my_requests_general"
      />
      {/* End View Att Request modal */}

      {/* Start of Request Comments modal */}
      <RequestCommentsModal
        employeeId={props?.employeeId}
        refetchQueries={refetchQueries}
      />
      {/* End of Request Comments modal */}

      {/* Start of Edit Leaves modal */}
      <EditLeavesModal />
      {/* End of Edit Leaves modal */}
    </div>
  );
};

const mapStateToProps = (state, { reducer, dataTableRef }) => {
  return {
    data: state[reducer][dataTableRef],
    authUserId: state.auth.userProfile.id,
    requstsLoader: state[reducer].requstsLoader,
    editAcceptedOvertimeRequestLoading:
      state.super.editAcceptedOvertimeRequestLoading,
    hasActiveSubscription:
      state.auth?.userProfile?.company?.hasActiveSubscription,
  };
};

export default connect(mapStateToProps, {
  cancelRequestAction,
  acceptInboundRequestAction,
  rejectInboundRequestAction,
  showOvertimeModalAction,
  showViewAttRequestModal,
  fetchEditAccpetedOvertimeRequestDataAction,
  showRequestCommentsModalAction,
  showEditLeavesModalAction,
})(MyRequests);
