import React from "react";
import { BSelect } from "form-builder";
import { connect, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  multiIndexInputChange,
  childInputChange,
  childInputChangeDocuments,
  hideDocumentsEdit,
  showUpsertDocModal,
  handleRowDocumentAction,
  resetDocumentsRow,
  storeDocumentableAttempt,
  storeDocumentableRowAttempt,
  showErrorToast,
} from "../../Store/Actions";

import swal from "sweetalert";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";
import IconButtonWithTooltip, {
  EditIconButton,
  TimesIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";

const DocsRow = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    formName = "rowDocsForm",
    fetchedCompanyRef,
    childName,
    documentable_type,
    // dataTableRef,
  } = props;

  // const handleMultiIndexInputChange = (val, childName, index) => {
  //   props.multiIndexInputChange(formName, childName, index, val ? val : []);
  // };

  const handleShowUpsertDocModal = () => {
    props?.showUpsertDocModal();
  };

  const handleSelectInputChange = (val, event, index, type) => {
    let valBasedOnType;
    switch (type) {
      case "single":
        valBasedOnType = val?.id | "";
        break;
      case "boolean":
        valBasedOnType = val;
        break;
      default:
        valBasedOnType = val?.map((val) => val.id) || [];
        break;
    }
    // const valBasedOnType =
    //   type === "single" ? val?.id | "" : val?.map((val) => val.id) || [];
    props.childInputChangeDocuments(
      formName,
      childName,
      event.name,
      index,
      valBasedOnType
    );
  };

  const handleCancel = (index) => {
    const selectedDocumentable = props?.data?.[formName][childName][index];
    const selectedDocumentableIndex = props?.data?.[
      fetchedCompanyRef
    ]?.findIndex(
      (fetchedRow) => fetchedRow.id === selectedDocumentable?.documentable
    );
    const selectedDocumentableFetched =
      props?.data?.[fetchedCompanyRef]?.[selectedDocumentableIndex];

    const selectedDocumentableFetchedRequiredIds =
      selectedDocumentableFetched?.requiredDocuments?.map((doc) => doc.id);
    const selectedDocumentableFetchedOptionalIds =
      selectedDocumentableFetched?.optionalDocuments?.map((doc) => doc.id);

    // If Row Has Been Already Saved (Row Match Excatly Fetched One)
    if (
      selectedDocumentableFetchedRequiredIds?.join ===
        selectedDocumentable?.required_documents?.join &&
      selectedDocumentableFetchedOptionalIds?.join ===
        selectedDocumentable?.optional_documents?.join
    ) {
      props.resetDocumentsRow(formName, childName, index, {
        documentable: selectedDocumentableFetched?.id,
        required_documents: selectedDocumentableFetchedRequiredIds,
        optional_documents: selectedDocumentableFetchedOptionalIds,
        showEdit: false,
        isLoading: false,
      });
    } else {
      props?.handleRowDocumentAction({
        formName: formName,
        childName: childName,
        index: index,
        action: "delete",
      });
    }
  };

  const handleAccept = (index) => {
    const selectedDocumentable = props?.data?.[formName][childName][index];

    if (
      !selectedDocumentable.documentable ||
      (!selectedDocumentable.required_documents?.length &&
        !selectedDocumentable.optional_documents?.length)
    ) {
      dispatch(showErrorToast("Please fill all the Required Fields"));
    }

    props?.storeDocumentableRowAttempt({
      formName,
      childName,
      fetchedCompanyRef,
      name: "isLoading",
      index,
      value: true,
      input: {
        documentable_id: selectedDocumentable.documentable,
        documentable_type,
        required_documents_ids: selectedDocumentable.required_documents,
        optional_documents_ids: selectedDocumentable.optional_documents,
      },
      resetData: {
        documentable: selectedDocumentable.id,
        required_documents: selectedDocumentable.requiredDocuments?.map(
          (doc) => doc.id
        ),
        optional_documents: selectedDocumentable.optionalDocuments?.map(
          (doc) => doc.id
        ),
        showEdit: false,
        isLoading: false,
      },
    });
  };

  const handleDeleteRow = (index) => {
    const selectedDocumentable = props?.data?.[formName][childName][index];

    props?.storeDocumentableRowAttempt({
      actionType: "delete",
      formName,
      childName,
      fetchedCompanyRef,
      name: "isLoading",
      index,
      value: true,
      input: {
        documentable_id: selectedDocumentable.documentable,
        documentable_type,
        required_documents_ids: [],
        optional_documents_ids: [],
      },
      resetData: {
        documentable: selectedDocumentable.id,
        required_documents: selectedDocumentable.requiredDocuments?.map(
          (doc) => doc.id
        ),
        optional_documents: selectedDocumentable.optionalDocuments?.map(
          (doc) => doc.id
        ),
        showEdit: false,
        isLoading: false,
      },
    });
  };

  const handleRowDocument = (action = "add", index) => {
    props?.handleRowDocumentAction({
      formName,
      childName,
      action,
      index,
    });
  };

  const renderFirstColIcon = (document) => {
    switch (document) {
      case "department":
        return "department";
      case "office":
        return "office";
      case "employee":
        return "person";
      default:
        return "person";
    }
  };

  const columns = [
    {
      name: t(documentable_type.toLowerCase()),
      wrap: true,
      selector: documentable_type,
      sortable: false,
      grow: 1,
      cell: (row, index) => {
        const selectedDocumentable = props?.data?.[fetchedCompanyRef]?.find(
          (opt) =>
            props.data?.[formName][childName][index]?.documentable == opt.id
        );

        return props.data?.[formName][childName][index]?.showEdit ? (
          <BSelect
            menuPortalTarget={document.querySelector("body")}
            reducer="documents"
            formName={formName}
            name="documentable"
            value={selectedDocumentable}
            options={props?.data?.[fetchedCompanyRef]?.filter(
              (documentable) =>
                !props?.data?.[formName][childName]
                  ?.map((row) => `${row.documentable}`)
                  .includes(documentable.id)
            )}
            keepDefaultStyle
            optionLabel="name"
            optionValue="id"
            placeholder={t(`select ${documentable_type.toLowerCase()}`)}
            rootStyle="col px-0 text-nowrap"
            onChange={(e, val) =>
              handleSelectInputChange(e, val, index, "single")
            }
            icon={renderFirstColIcon(documentable_type.toLowerCase())}
          />
        ) : (
          <span className="docs_choices">
            {selectedDocumentable?.name || "-----"}
          </span>
        );
      },
    },
    {
      name: t("required documents"),
      wrap: true,
      selector: "required_docs",
      sortable: false,
      grow: 1,
      cell: (row, index) => {
        const selectedRequiredDocs = props.documentsListOptions?.filter((opt) =>
          props.data?.[formName][childName][
            index
          ]?.required_documents?.includes(opt.id)
        );
        return props.data?.[formName][childName][index]?.showEdit ? (
          <BSelect
            menuPortalTarget={document.querySelector("body")}
            reducer="documents"
            formName={formName}
            name="required_documents"
            value={props.documentsListOptions?.filter((opt) =>
              props.data?.[formName][childName][
                index
              ]?.required_documents?.includes(opt.id)
            )}
            options={props?.documentsListOptions?.filter(
              (doc) =>
                !props?.data?.[formName][childName][
                  index
                ]?.optional_documents?.includes(doc.id)
            )}
            keepDefaultStyle
            getOptionLabel={(option) => HelperFns.getCountryShortName(option)}
            optionValue="id"
            isMulti
            placeholder={t("select document")}
            rootStyle="col px-0 text-nowrap"
            onChange={(e, val) => handleSelectInputChange(e, val, index)}
            icon="document"
          />
        ) : (
          <h6>
            {selectedRequiredDocs?.length
              ? selectedRequiredDocs
                  ?.map((item) => HelperFns.getCountryShortName(item))
                  .join(" - ")
              : "-----"}
          </h6>
        );
      },
    },
    {
      name: t("optional documents"),
      wrap: true,
      selector: "optional_docs",
      sortable: false,
      grow: 1,
      cell: (row, index) => {
        const selectedOptionalDocs = props.documentsListOptions?.filter((opt) =>
          props.data?.[formName][childName][
            index
          ]?.optional_documents?.includes(opt.id)
        );
        return props.data?.[formName][childName][index]?.showEdit ? (
          <BSelect
            menuPortalTarget={document.querySelector("body")}
            reducer="documents"
            formName={formName}
            name="optional_documents"
            value={props.documentsListOptions?.filter((opt) =>
              props.data?.[formName][childName][
                index
              ]?.optional_documents?.includes(opt.id)
            )}
            options={props?.documentsListOptions?.filter(
              (doc) =>
                !props?.data?.[formName][childName][
                  index
                ]?.required_documents?.includes(doc.id)
            )}
            keepDefaultStyle
            getOptionLabel={(option) => HelperFns.getCountryShortName(option)}
            optionValue="id"
            isMulti
            placeholder={t("select document")}
            rootStyle="col px-0 text-nowrap"
            onChange={(e, val) => handleSelectInputChange(e, val, index)}
            icon="document"
          />
        ) : (
          <h6>
            {selectedOptionalDocs?.length
              ? selectedOptionalDocs
                  ?.map((item) => HelperFns.getCountryShortName(item))
                  .join(" - ")
              : "-----"}
          </h6>
        );
      },
    },
    {
      name: " ",
      wrap: true,
      selector: "action",
      right: true,
      width: "150px",
      cell: (row, index) => (
        <HasPrivileges
          reqireMain={[Privilages.MANAGE_COMPANY_DOCUMENTS]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="cards_table_actions">
              <EditIconButton />
              <RemoveIconButton />
            </div>
          }
        >
          <div className="cards_table_actions">
            {props?.isLoading ||
            props.data?.[formName][childName][index]?.isLoading ? (
              <Spinner
                className="mx-3"
                style={{
                  width: "1rem",
                  height: "1rem",
                  color: "#23aaeb",
                }}
              />
            ) : props.data?.[formName][childName][index]?.showEdit ? (
              <div className="cards_table_actions">
                <IconButtonWithTooltip
                  label="add new document"
                  onClick={handleShowUpsertDocModal}
                  icon={
                    <FontAwesomeIcon size="sm" icon={faPlus} color="#2764ac" />
                  }
                />
                <IconButtonWithTooltip
                  label="save"
                  onClick={() => handleAccept(index)}
                  icon={
                    <FontAwesomeIcon size="sm" icon={faCheck} color="#27b40c" />
                  }
                />
                <TimesIconButton
                  label="Cancel"
                  onClick={() => handleCancel(index)}
                />
              </div>
            ) : (
              <div className="cards_table_actions">
                <EditIconButton
                  onClick={() =>
                    handleSelectInputChange(
                      true,
                      {
                        name: "showEdit",
                      },
                      index,
                      "boolean"
                    )
                  }
                />
                <RemoveIconButton onClick={() => confirmDeleteRow(index)} />
              </div>
            )}
          </div>
        </HasPrivileges>
      ),
      ignoreRowClick: true,
    },
  ];

  const confirmDeleteRow = (index) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteRow(index);
      }
    });
  };

  // const handlePaginate = (
  //   page = props.data[dataTableRef].pagination.currentPage
  // ) => {
  //   props.fetchAllDocumentablesAction({
  //     ref: dataTableRef,
  //     [dataTableRef]: {
  //       ...props.data[dataTableRef],
  //       pagination: {
  //         ...props.data[dataTableRef].pagination,
  //         currentPage: page,
  //       },
  //     },
  //   });
  // };

  // const handleSorting = (field, dir) => {
  //   props.fetchAllDocumentablesAction({
  //     [dataTableRef]: {
  //       ...props.data[dataTableRef],
  //       pagination: {
  //         ...props.data[dataTableRef].pagination,
  //         currentPage: 1,
  //       },
  //       sorting: {
  //         dir,
  //         key: field.selector,
  //       },
  //     },
  //     ref: dataTableRef,
  //   });
  //   props.setDataTableSortingAction(dataTableRef, field.selector, dir);
  // };

  return (
    <div className="card_container">
      <div className="d-flex align-items-center docs_title">
        <h4 className="sub-title-style mb-0">
          {t(`${documentable_type} level documents`)}
        </h4>
        <HasPrivileges
          reqireMain={[Privilages.MANAGE_COMPANY_DOCUMENTS]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <button
              className="btn mx-3 d-flex align-items-center justify-content-center add_docs"
              type="button"
              disabled
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          }
        >
          <button
            className="btn mx-3 d-flex align-items-center justify-content-center add_docs"
            type="button"
            aria-label={t("add")}
            onClick={() => handleRowDocument("add")}
            disabled={
              props?.data?.[fetchedCompanyRef]?.length ===
              props?.data?.[formName]?.[childName]?.length
            }
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </HasPrivileges>
      </div>

      <DataTable
        className="cards_table"
        columns={columns}
        data={props?.data?.[formName][childName]}
        noDataComponent={
          <div className="p-4">{t("There Are No Records To Display")}</div>
        }
        noHeader
        persistTableHead
        // sortServer
        // onSort={handleSorting}
        // defaultSortAsc={props.data?.[dataTableRef]?.sorting.dir == "asc"}
        // defaultSortField={props.data?.[dataTableRef]?.sorting.key}
        // paginationComponent={() => (
        //   <Pagination
        //     tableRef={dataTableRef}
        //     reducer="documents"
        //     styleWraper=""
        //     onPaginate={handlePaginate}
        //   />
        // )}
        // pagination={true}
        // paginationServer={true}
        // progressPending={props.data?.[dataTableRef]?.isLoading}
        // progressComponent={<Loader />}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.documents,
    documentsListOptions: state.documents?.documentsListOptions,
    isLoading: false,
  };
};

export default connect(mapStateToProps, {
  multiIndexInputChange,
  childInputChange,
  childInputChangeDocuments,
  hideDocumentsEdit,
  showUpsertDocModal,
  handleRowDocumentAction,
  resetDocumentsRow,
  storeDocumentableAttempt,
  storeDocumentableRowAttempt,
})(DocsRow);
