import React, { useEffect, useState } from "react";

import { InputForm } from "form-builder";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import FormBuilderField from "../../Components/FormBuilderField";
import FormField from "../../models/FormField";
import { processFormFieldSchema } from "../../Helpers/Validations/processFieldValidation";

import {
  addFormFieldAction,
  onInputChangeAction,
  resetRecruitmentIntakeForm,
  setRecruitmentFormSubmitting,
  upsertRecruitmentIntakeAction,
  onIntakeFormFieldsChangeAction,
} from "../../Store/Actions";
import { v4 as uuid, validate as uuidValidate } from "uuid";
import { Spinner } from "reactstrap";
import { runValidation } from "../../Helpers/Validations/formbuilderInputValidation";
import _ from "lodash";
import PreviewProcessStage from "../../Components/RecruitmentProcessStage/PreviewProcessStage";

const FormProps = {
  formName: "recruitmentIntakeForm",
  formServerValidation: "recruitmentIntakeFormServerValidation",
  // formSubmitting: props.recruitmentProcessFormSubmitting,
};

const Checkbox = ({ label, ...props }) => {
  const { t } = useTranslation();
  return (
    <label className={"checkbox mx-4"}>
      <input type="checkbox" {...props} />
      <span></span>

      <div
        className={`
                  booleanInputLabelStyle
                  ${props?.checked ? "lightActiveColor" : ""}
                  `}
      >
        {t(label)}
      </div>
    </label>
  );
};

const IntakeForm = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetRecruitmentIntakeForm());
    };
  }, []);

  const [isPreview, setIsPreview] = useState(false);

  const isLoading = useSelector(
    (state) => state.recruitment.upsertRecruitmentIntakeLoading
  );

  useEffect(() => {
    if (isLoading) {
      console.log("preview");
      setIsPreview(false);
    }
  }, [isLoading]);

  const authUser = useSelector((state) => state.auth.userProfile);
  const intakeForm = useSelector(
    (state) => state.recruitment.recruitmentIntakeForm
  );

  const intakeFormFields = useSelector(
    (state) => state.recruitment.recruitmentIntakeFormFields
  );

  const formSubmitting = useSelector(
    (state) => state.recruitment?.recruitmentProcessFormSubmitting
  );
  let newOrder = useSelector(
    (state) =>
      state.recruitment?.["recruitmentIntakeFormFields"][0].form.fields.length +
      1
  );
  const { fieldId, stageId } =
    useSelector((state) => state.recruitment?.openField) || {};
  let activeField = useSelector(
    (state) =>
      state.recruitment?.["recruitmentIntakeFormFields"][
        stageId
      ]?.form?.fields?.filter((el) => el.id === fieldId)[0]
  );

  const addNewField = () => {
    let field = new FormField(null, newOrder);
    if (activeField) {
      processFormFieldSchema
        .validate(activeField, { abortEarly: false })
        .then((valid) => {
          console.log(valid);
          dispatch(
            addFormFieldAction(
              "recruitmentIntakeFormFields",
              0,
              field,
              "intakeForm"
            )
          );
          dispatch({
            type: "OPEN_ID",
            payload: { fieldId: field.id, stageId: 0 },
          });
        })

        .catch((err) => {
          console.log(err);
          dispatch({
            type: "SUBMIT_FIELD",
            payload: activeField.id,
          });
        });
    } else {
      dispatch(
        addFormFieldAction(
          "recruitmentIntakeFormFields",
          0,
          field,
          "intakeForm"
        )
      );
      dispatch({
        type: "OPEN_ID",
        payload: { fieldId: field.id, stageId: 0 },
      });
    }
  };

  const handleCheckboxChange = (e) => {
    console.log(e.target.checked);
    const { name, value, checked } = e.target;
    let event = {
      target: {
        name: e.target.name,
        value: e.target.checked,
      },
    };
    if (checked) {
      let newFieldOrder = intakeForm?.fields?.length
        ? intakeForm?.fields?.length + 1
        : 1;
      let newField = new FormField(null, newFieldOrder);
      let updatedFields = [
        ...(intakeForm?.fields ?? []),
        {
          ...newField,
          name,
          type: "text",
          suggested_field_type:
            name.toLowerCase() === "phone_number" ? "phone" : name,
        },
      ];
      dispatch(onIntakeFormFieldsChangeAction(updatedFields));
    } else {
      let updatedFields = intakeForm?.fields?.filter((el) => el.name !== name);
      dispatch(onIntakeFormFieldsChangeAction(updatedFields));
    }
    dispatch(onInputChangeAction("recruitmentIntakeForm", event));
  };

  const handleSubmit = () => {
    // console.log("values", props.recruitmentProcessStages);

    dispatch(setRecruitmentFormSubmitting());
    let res = {
      ...intakeForm,
      // company_id: authUser?.company?.id,
      attachement:
        intakeForm?.fields?.findIndex(
          (field) => field?.suggested_field_type == "attachment"
        ) > -1,
      email:
        intakeForm?.fields?.findIndex(
          (field) => field?.suggested_field_type == "email"
        ) > -1,
      phone_number:
        intakeForm?.fields?.findIndex(
          (field) => field?.suggested_field_type == "phone"
        ) > -1,
      fields: serializeForm(intakeForm?.fields),
    };

    console.log(res);
    dispatch(upsertRecruitmentIntakeAction(res));
  };

  const serializeForm = (items, id = null) =>
    items
      ?.filter((item) => item.parent_field_id == id)
      ?.map(
        ({
          id,
          parent_field_id,
          mainOptions,
          apply_validation,
          apply_description,
          dependent_option_names,
          validation_type_ids,
          ancestors,
          ...item
        }) => ({
          ...item,
          id: uuidValidate(id) ? null : id,
          dependent_option_names: dependent_option_names.length
            ? dependent_option_names.map((opt) => opt?.label)
            : [],
          children_fields: serializeForm(items, id),
          options: item?.options?.map((option) => ({
            id:
              uuidValidate(option.value) || option.value == option.label
                ? null
                : option.value,
            name: option.label,
          })),
          validation_type_ids:
            !!apply_validation || validation_type_ids?.length
              ? validation_type_ids?.map((validation) => validation.value)
              : [],
        })
      );

  // Start handle Form Fields Action

  const intakeOpenFields = useSelector(
    (state) => state.recruitment.openIntakeFields
  );
  const intakeSubmittedFields = useSelector(
    (state) => state.recruitment.submittedIntakeFields
  );

  const handleAddChildField = (parent_field_id = null, ancestors = []) => {
    if (intakeOpenFields?.length) {
      let openFieldsStatus = intakeOpenFields?.map((opFieldId) => {
        let openField = intakeForm?.fields?.find(
          (field) => field.id === opFieldId
        );
        if (!!openField?.id) {
          dispatch({
            type: "SUBMITED_INTAKE_FIELD",
            payload: openField?.id,
          });
          return runValidation(openField);
        } else {
          return true;
        }
      });

      if (openFieldsStatus?.includes(false)) {
        return;
      } else {
        let newFieldOrder = intakeForm?.fields?.length
          ? intakeForm?.fields?.length + 1
          : 1;
        let newAncestors = [...(ancestors ?? []), parent_field_id];
        let newField = new FormField(
          parent_field_id,
          newFieldOrder,
          newAncestors
        );
        let updatedFields = [...(intakeForm?.fields ?? []), newField];

        dispatch(onIntakeFormFieldsChangeAction(updatedFields));
        dispatch({
          type: "OPEN_INTAKE_FIELD",
          payload: newField.id,
        });
        setIsPreview(false);
      }
    } else {
      let newFieldOrder = intakeForm?.fields?.length
        ? intakeForm?.fields?.length + 1
        : 1;
      let newAncestors = [...(ancestors ?? []), parent_field_id];
      let newField = new FormField(
        parent_field_id,
        newFieldOrder,
        newAncestors
      );
      let updatedFields = [...(intakeForm?.fields ?? []), newField];

      dispatch(onIntakeFormFieldsChangeAction(updatedFields));
      dispatch({
        type: "OPEN_INTAKE_FIELD",
        payload: newField.id,
      });
      setIsPreview(false);
    }
  };

  const handleFieldDelete = (fieldId) => {
    // return;
    let updatedFields = _.filter(
      intakeForm?.fields,
      (field) => !(field.id === fieldId || field?.ancestors?.includes(fieldId))
    );
    dispatch(onIntakeFormFieldsChangeAction(updatedFields));
    return;
  };

  const handleUpdateFormBuilder = (updatedfield, fieldId) => {
    let updatedFields = intakeForm?.fields?.map((field) =>
      field?.id === fieldId ? { ...updatedfield } : field
    );
    dispatch(onIntakeFormFieldsChangeAction(updatedFields));
  };

  const handleOpenField = (fieldId, skipValidation = false) => {
    const openField = _.find(intakeForm?.fields, (field) =>
      intakeOpenFields.includes(field?.id)
    );
    //no open field found in this stage
    if (skipValidation) {
      dispatch({
        type: "OPEN_INTAKE_FIELD",
        payload: fieldId,
      });
    }

    if (!!!openField?.id) {
      dispatch({
        type: "OPEN_INTAKE_FIELD",
        payload: fieldId,
      });
    }
    //open field found in this stage && is valid
    if (!!openField && runValidation(openField)) {
      dispatch({
        type: "OPEN_INTAKE_FIELD",
        payload: fieldId,
      });
    } else {
      dispatch({
        type: "SUBMITED_INTAKE_FIELD",
        payload: openField?.id,
      });
    }
  };

  const handleCloseField = (fieldId) => {
    console.log("fieldId", fieldId);
    dispatch({
      type: "SUBMITED_INTAKE_FIELD",
      payload: fieldId,
    });
    dispatch({
      type: "CLOSE_INTAKE_FIELD",
      payload: fieldId,
    });
  };

  const handleUpdatePreview = (updatedFields) => {
    dispatch(onIntakeFormFieldsChangeAction(updatedFields));
  };
  // End handle Form Fields Action
  const formServerValidation = useSelector(
    (state) => state.recruitment?.recruitmentIntakeFormServerValidation
  );

  return (
    <div className="intake-form">
      <div className="my-3">
        <div className="col-12">
          <InputForm
            {...FormProps}
            formSubmitting={formSubmitting}
            label={t("form name")}
            reducer="recruitment"
            validateBy="textRequired"
            name="name"
            type="text"
            labelStyle="mr-5"
            placeholder={t("name")}
            containerStyle="align-items-center d-flex flex-row mb-3 mt-0"
            inputContainerStyle="flex-grow-1"
            validationName={`input.name`}
          />
        </div>
      </div>

      <div className="my-3">
        <div className="col-12">
          <label className="mr-4">{t("form fields")}</label>

          <Checkbox
            label="applicant name"
            disabled
            checked={intakeForm?.fields?.find(
              (field) => field?.suggested_field_type === "name"
            )}
          />
          <Checkbox
            onChange={handleCheckboxChange}
            name="email"
            checked={intakeForm?.fields?.find(
              (field) => field?.suggested_field_type === "email"
            )}
            label="email"
          />
          <Checkbox
            onChange={handleCheckboxChange}
            name="phone_number"
            checked={intakeForm?.fields?.find(
              (field) => field?.suggested_field_type === "phone"
            )}
            label="phone number"
          />
          {formServerValidation?.["input.email"] ? (
            <span className="errorerror-color error_message_style">
              {formServerValidation?.["input.email"]?.toString()}
            </span>
          ) : null}
        </div>
      </div>

      <div className="col-12 d-flex flex-row align-items-baseline my-3">
        <h5 className="mr-2 d-flex field-title">{t("add new field")}</h5>
        <button
          className="d-flex add-button"
          role="button"
          onClick={() => handleAddChildField()}
        >
          <FontAwesomeIcon icon={faPlus} color="#fff" />
        </button>
        <div className="ml-auto">
          {intakeForm?.fields?.length ? (
            <span
              role="button"
              className="preview-btn mx-1"
              onClick={(e) => {
                e.stopPropagation();
                setIsPreview(!isPreview);
              }}
            >
              {!isPreview ? t("preview form") : t("edit form")}
            </span>
          ) : null}
        </div>
      </div>

      {isPreview ? (
        <PreviewProcessStage
          onUpdatePreview={handleUpdatePreview}
          data={intakeForm?.fields}
        />
      ) : (
        intakeForm?.fields
          ?.filter(
            (field) =>
              !(
                field?.suggested_field_type == "email" ||
                field?.suggested_field_type == "phone" ||
                field?.suggested_field_type == "name"
              )
          )
          ?.filter(({ parent_field_id }) => !!!parent_field_id)
          ?.map((firstParent, index) => (
            <>
              <FormBuilderField
                field={firstParent}
                serverValidation={FormProps.formServerValidation}
                key={firstParent.id}
                id={firstParent.id}
                data={intakeForm?.fields}
                validationName={`input.fields.${index}`}
                handleAddChildField={handleAddChildField}
                onDeleteField={handleFieldDelete}
                onUpdateFormBuilder={handleUpdateFormBuilder}
                openFields={intakeOpenFields}
                onOpenField={handleOpenField}
                onCloseField={handleCloseField}
                submittedFields={intakeSubmittedFields}
              />
            </>
          ))
      )}

      <div className="d-flex justify-content-end align-items-center">
        <button
          className="btn btn-primary submit_btn_style"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <Spinner style={{ width: "1rem", height: "1rem", color: "#fff" }} />
          ) : (
            t("save")
          )}
        </button>
      </div>
    </div>
  );
};

export default IntakeForm;
