import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomQuery from "../../Helpers/Hooks/useCustomQuery";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import {
  formatOptions,
  openSwalConfirm,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import moment from "moment";
import Privilages from "../../Constants/Privilages";
import { dateTimeListFormat } from "../../Constants";
import { trialsListQuery } from "../../Graphql/query";
import { removeTrialMutation } from "../../Graphql/mutation";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import Box from "@mui/material/Box";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import { TrialModal } from "../../Components/CRMFormModals";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { InputForm, BSelect, DateTimePickerForm } from "form-builder";

const modalInitState = { isOpen: false, data: null };
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const TrialsList = ({ isLeadProfile, leadProfileData }) => {
  const { t } = useTranslation();

  // Local State
  const [trials, setTrials] = useState([]);
  const [options, setOptions] = useState({ users: [] });
  const [modalState, setModalState] = useState(modalInitState);
  const [pagination, setPagination] = useState(paginationInitState);
  const [filters, setFilters] = useState({
    to: "",
    from: "",
    company: "",
    user_id: "",
    status: null,
    lead_ids: isLeadProfile ? [leadProfileData?.id] : null,
  });

  // Server State
  const [removeTrial] = useCustomMutation(removeTrialMutation);
  const { refetch: refetchList, loading: isListLoading } = useCustomQuery(
    trialsListQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        first: pagination.perPage,
        page: pagination.currentPage,
        ...formatSendingInputs(filters),
        company: filters.company,
        isOptionsSkip: Object.values(options).every((l) => l.length),
      },
      onCompleted: ({ trials, users }) => {
        // Data
        setTrials(trials?.data);
        setPagination(trials?.paginatorInfo);

        // Options
        !Object.values(options).every((l) => l.length) &&
          setOptions({ users: users?.data });
      },
    }
  );

  // Constants
  const columns = [
    {
      name: t("company"),
      cell: ({ subscription = {} }) => subscription?.company?.name,
    },
    {
      name: t("business partner"),
      cell: ({ subscription = {} }) =>
        subscription?.company?.businessPartners
          ?.map((b) => b?.user?.name)
          .join(", "),
    },
    {
      name: t("plan"),
      cell: ({ subscription = {} }) => subscription?.plan?.name,
    },
    {
      name: t("from"),
      cell: ({ subscription = {} }) =>
        moment(subscription?.start_date).format(dateTimeListFormat),
    },
    {
      name: t("to"),
      cell: ({ subscription = {} }) =>
        moment(subscription?.end_date).format(dateTimeListFormat),
    },
    { name: t("created by"), cell: ({ user = {} }) => user?.name },
    {
      name: t("account manager"),
      cell: ({ subscription = {} }) =>
        subscription?.company?.saasUsers
          ?.map(({ name = "" }) => name)
          .join(", "),
    },
    { name: t("status"), selector: "status" },
    {
      name: t("No. Of Employees"),
      selector: "subscription.company.numberOfEmployees",
    },
    {
      cell: (data) => (
        <>
          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_TRIALS]}>
            <EditIconButton
              onClick={() =>
                setModalState({ isOpen: true, data: { id: data.id } })
              }
            />
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.DELETE_TRIALS]}>
            <RemoveIconButton onClick={() => handleRemoveTrial(data)} />
          </HasPrivileges>
        </>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleTextFilters = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleRemoveTrial = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeTrial({
          refetchList,
          isShowErrorModal: true,
          variables: { id: data.id },
        });
      }
    });
  };

  return (
    <>
      {/* Filters */}
      <div className="my-4">
        <div className="d-flex align-items-end gap-20 mb-3">
          <InputForm
            name="company"
            rootStyle="flex-fill"
            value={filters.company}
            onChange={handleTextFilters}
            inputContainerStyle="w-100"
            placeholder={t("search")}
          />

          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_TRIALS]}>
            <AddButton
              label="trial"
              onClick={() =>
                setModalState((prev) => ({ ...prev, isOpen: true }))
              }
            />
          </HasPrivileges>
        </div>

        <Box className="d-sm-flex align-items-end gap-20">
          <BSelect
            isClearable
            name="status"
            label="status"
            icon="employee"
            keepDefaultStyle
            containerStyle=" "
            rootStyle="flex-fill"
            value={filters.status}
            onChange={handleSelectFilters}
            options={formatOptions([
              "Upcoming",
              "Ongoing",
              "Expired",
              "Onboarded",
            ])}
          />
          <DateTimePickerForm
            hasIcon
            isClearable
            label="from"
            value={filters.from}
            rootStyle="flex-fill"
            containerStyle="py-0"
            labelStyle="w-100 mb-2"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("from", val)}
          />
          <DateTimePickerForm
            hasIcon
            label="to"
            isClearable
            value={filters.to}
            rootStyle="flex-fill"
            containerStyle="py-0"
            labelStyle="w-100 mb-2"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("to", val)}
          />
          <BSelect
            isClearable
            icon="employee"
            name="user_id"
            label="created by"
            keepDefaultStyle
            rootStyle="flex-fill"
            options={options.users}
            value={filters.user_id}
            onChange={handleSelectFilters}
            isLoading={!options.users.length}
          />
        </Box>
      </div>

      {/* List */}
      <DataTable
        noHeader
        data={trials}
        columns={columns}
        className="cards_table"
        progressPending={isListLoading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modalState.isOpen ? (
        <TrialModal
          data={modalState.data}
          refetchList={refetchList}
          isLeadProfile={isLeadProfile}
          leadProfileData={leadProfileData}
          onClose={() => setModalState(modalInitState)}
        />
      ) : null}
    </>
  );
};

export default TrialsList;
