import gql from "graphql-tag";

/*
  Offices
*/

/*
  Departments
*/

/*
  Positions
*/

/*
  Work Timings
*/

/*
  Leave & Breaks
*/

/*
  Attendance Profiles
*/

/*
  Holidays
*/

/*
  Documents
*/

/*
  Expense Categories
*/

/*
  Access levels
*/

/*
  Announcements
*/

export const storeAnnouncementMutation = gql`
  mutation storeAnnouncement(
    $type: String
    $body: String
    $publish: Int
    $title: String
    $send_to: JSON
  ) {
    store_announcement(
      body: $body
      type: $type
      title: $title
      publish: $publish
      send_to: $send_to
    ) {
      __typename
    }
  }
`;
export const editAnnouncementMutation = gql`
  mutation editAnnouncement(
    $id: ID
    $type: String
    $body: String
    $publish: Int
    $title: String
    $send_to: JSON
  ) {
    change_announcement(
      id: $id
      body: $body
      type: $type
      title: $title
      publish: $publish
      send_to: $send_to
    ) {
      __typename
    }
  }
`;
export const publishAnnouncementMutation = gql`
  mutation publishAnnouncement($id: ID!) {
    publish_announcement(id: $id)
  }
`;
export const removeAnnouncementMutation = gql`
  mutation publishAnnouncement($id: ID!) {
    remove_announcement(id: $id)
  }
`;
