import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Constants from "../../Helpers/Constants";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  fetchJobPostsAction,
  onInputResetAction,
  deleteJobPostsAction,
  updateJobPostsStatusAction,
  showJobPostModalAction,
} from "../../Store/Actions";
import { InputForm, RadioboxForm } from "form-builder";
import AntSwitch from "../../Components/AntSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@mui/material/Tooltip";
import swal from "sweetalert";
import JobPostModal from "./JobPostModal";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import { IconButton } from "@mui/material";

const dataTableRef = "jobPostsList";

const JobPostsList = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchJobPostsAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
    return () => {
      props.onInputResetAction(dataTableRef, "status");
      props.onInputResetAction(dataTableRef, "name");
    };
  }, []);

  const handleFilter = () => {
    props.fetchJobPostsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].status,
    props.data[dataTableRef].name,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchJobPostsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  // to delete job post
  const handleDeleteJobPosts = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteJobPostsAction(row?.id);
      }
    });
  };

  // handle update job post status active/inactive
  const handleUpdateJobPostsStatus = (row) => {
    props.updateJobPostsStatusAction(row?.id);
  };

  // to handle copy to clipboard
  const [copiedText, setCopiedText] = useState();
  const [toggleToolTip, setToggleToolTip] = useState(false);

  const handleCopyToClipBoard = (row) => {
    setCopiedText(row?.link);

    setToggleToolTip(true);

    setTimeout(() => {
      setToggleToolTip(false);
    }, 700);
  };

  // handling add job post modal
  const handleAddJobPost = () => {
    let data = {
      id: null,
    };
    props.showJobPostModalAction({
      data,
      ref: "jobPostModalActions",
      formName: "jobPostModal",
    });
  };

  // handleing edit job post modal
  const handleEditJobPost = (row) => {
    let data = {
      id: row?.id,
      title: row?.title,
      description: row?.description,
      allow_multiple_apply: row?.allow_multiple_apply,
      set_limit_for_recurring_application: row?.limit_of_recurring_applications
        ? 1
        : 0,
      limit_of_recurring_applications: row?.limit_of_recurring_applications,
      custom_intakeForm_and_process: row?.custom_intakeForm_and_process,
      intake_form_id: row?.custom_intakeForm_and_process
        ? row?.intake_form_id
        : null,
      process_id: row?.custom_intakeForm_and_process ? row?.process_id : null,
      link: row?.link,
      process: row?.process,
    };
    props.showJobPostModalAction({
      data,
      ref: "jobPostModalActions",
      formName: "jobPostModal",
    });
  };

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "title",
      sortable: false,
      grow: 2.2,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.VIEW_JOB_POST_APPLICANTS]}
          allowBP
          altChildren={<>{row?.title}</>}
          avalibleOnExpire={false}
          altExpireChildren={<>{row?.title}</>}
        >
          <Link
            to={`/recruitment/job-posts/${row?.id}`}
            className="cursor-pointer"
          >
            {row?.title}
          </Link>
        </HasPrivileges>
      ),
    },
    {
      name: t("Job Description"),
      wrap: true,
      selector: "description",
      sortable: false,
      grow: 1.6,
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={280}
        >
          <div dangerouslySetInnerHTML={{ __html: row?.description }}></div>
        </ShowMoreText>
      ),
    },
    {
      name: t("Recruitment Processes"),
      wrap: true,
      selector: "process",
      sortable: false,
      grow: 1.2,
      cell: (row) => <>{row?.process?.name}</>,
    },
    {
      name: t("Intake Forms"),
      wrap: true,
      selector: "intakeForm",
      sortable: false,
      grow: 1.2,
      cell: (row) => <>{row?.intakeForm?.name}</>,
    },
    {
      name: t("Active Applicants"),
      wrap: true,
      selector: "no_active_applicants",
      sortable: false,
      grow: 1.2,
    },
    {
      name: t("active"),
      wrap: true,
      selector: "is_active",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.ACTIVATE_DEACTIVATE_JOB_POSTS]}
          allowBP
          altChildren={
            <AntSwitch
              inputProps={{ "aria-label": "Switch demo" }}
              checked={row?.is_active}
              disabled
              color="primary"
            />
          }
          avalibleOnExpire={false}
          altExpireChildren={
            <AntSwitch
              inputProps={{ "aria-label": "Switch demo" }}
              checked={row?.is_active}
              disabled
              color="primary"
            />
          }
        >
          <AntSwitch
            inputProps={{ "aria-label": "Switch demo" }}
            checked={row?.is_active}
            onChange={(e, val) => handleUpdateJobPostsStatus(row, val)}
            color="primary"
          />
        </HasPrivileges>
      ),
    },
    {
      name: "",
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_JOB_POSTS]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="cards_table_actions">
              <FontAwesomeIcon icon={faLink} size="xs" color="#23aaeb" />
              <EditIconButton />
              <RemoveIconButton />
            </div>
          }
        >
          <div className="cards_table_actions">
            <CopyToClipboard
              text={row?.link}
              onCopy={() => handleCopyToClipBoard(row)}
            >
              <Tooltip
                arrow
                placement={toggleToolTip && copiedText ? "top" : "bottom"}
                title={t(
                  toggleToolTip && copiedText
                    ? "copied to clipboard"
                    : "copy to clipboard"
                )}
                aria-label={t(
                  toggleToolTip && copiedText
                    ? "copied to clipboard"
                    : "copy to clipboard"
                )}
              >
                <IconButton size="small">
                  <FontAwesomeIcon icon={faLink} size="xs" color="#23aaeb" />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>

            <EditIconButton onClick={() => handleEditJobPost(row)} />
            <RemoveIconButton onClick={() => handleDeleteJobPosts(row)} />
          </div>
        </HasPrivileges>
      ),
    },
  ];

  return (
    <div className="extra_bonus_wrapper_style">
      {props?.isDeleteJobPostsLoading || props?.isUpdateJobPostStatusLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="row my-3 align-items-baseline">
        <div className="col-12 col-lg-5">
          <RadioboxForm
            reducer="recruitment"
            formName={dataTableRef}
            options={Constants.recruitmentProcessStatusFilter?.map(
              ({ label, value }) => ({
                label: t(label),
                value,
              })
            )}
            name="status"
            type="radio"
            containerStyle="my-2 my-lg-0"
            labelStyle="label-style"
            optionsContainerStyle="optionsContainerStyle"
            optionItemStyle="optionItemStyle"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
          />
        </div>

        <div className="col-12 col-lg-7">
          <div className="row align-items-baseline">
            <div className="d-flex align-items-baseline col-12 my-2 my-lg-0">
              <div className="col">
                <InputForm
                  reducer="recruitment"
                  name="name"
                  formName={dataTableRef}
                  placeholder={t("search")}
                  inputContainerStyle=" "
                  containerStyle=" "
                />
              </div>

              <HasPrivileges
                reqireMain={[Privilages.ADD_EDIT_DELETE_JOB_POSTS]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <button
                    className="btn add_new_btn_style"
                    type="button"
                    disabled
                  >
                    {t("add new")}
                  </button>
                }
              >
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  onClick={handleAddJobPost}
                >
                  {t("add new")}
                </button>
              </HasPrivileges>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={props.data[dataTableRef].data}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="recruitment"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data[dataTableRef].isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of Job post Modal */}
      <JobPostModal />
      {/* End of Job post Modal */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.recruitment,
    isDeleteJobPostsLoading: state?.recruitment?.deleteJobPostsLoading,
    isUpdateJobPostStatusLoading:
      state?.recruitment?.updateJobPostStatusLoading,
  };
};

export default connect(mapStateToProps, {
  fetchJobPostsAction,
  onInputResetAction,
  deleteJobPostsAction,
  updateJobPostsStatusAction,
  showJobPostModalAction,
})(JobPostsList);
