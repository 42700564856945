const validateAddingBusinessPartner = (
  modalType,
  numberOfPartners,
  numberOfEmployees,
  employeeLimits
) => {
  let planLimits = employeeLimits;
  if (modalType?.toLowerCase() === "edit") {
    if (planLimits > numberOfPartners + numberOfEmployees) {
      return true;
    } else {
      return false;
    }
  }
  if (modalType?.toLowerCase() === "add") {
    if (planLimits > numberOfPartners) {
      return true;
    } else {
      return false;
    }
  }
};

export default validateAddingBusinessPartner;
