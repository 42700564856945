export const Config = {
  // //Production Env
  // API_URL: "http://mawared.pro",
  Client_Secret: "V9igDc6lyqjvfqSfcvBgbtghAnU5RfrLyD8cr2Gr",
  Client_Id: 2,

  // Testing Env
  // API_URL: "https://mawared-testing.arabiclocalizer.info",
  // Client_Secret:"HEKC4vcjsUCHKm2eZlCL0tPvL6QovpYQF8llinq7",
  // Client_Id:4,
  //Local Env
  // API_URL: "http://mawared.local",
  // Client_Secret: "pFk1D9sfYawCJJuZEsvvEzDO2WhU7GfPnDZUmG18",
  // Client_Id: 4,
  googleMapKey: process.env.REACT_APP_GOOGLE_MAP_API_URL,
  // API_URL: "https://testing-back.mawaredhr.com",
  API_URL: process.env.REACT_APP_API_URL,
  DEMO_URL: process.env.REACT_APP_DEMO_URL,
  ASSIGNMENTS_COMPANIES_IDS: ["1", "12"],
};
