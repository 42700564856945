import React from "react";
import MainModal from "../../Components/MainModal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toggleSignInBreaksModal } from "../../Store/Actions";
import { Alarm, ArrowDownward, ArrowUpward } from "@mui/icons-material";
import moment from "moment";

const getDurationInHours = (start, end) => {
  const duration = moment.duration(moment(end).diff(moment(start)));
  const durationHours =
    duration.hours() < 10 ? `0${duration.hours()}` : duration.hours();

  return durationHours;
};

const getDurationInMinutes = (start, end) => {
  const duration = moment.duration(moment(end).diff(moment(start)));

  const durationMinutes =
    duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();
  return durationMinutes;
};

const SignInBreaksModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(
      toggleSignInBreaksModal({
        isOpen: false,
        breakInOuts: [],
        name: null,
        date: null,
      })
    );
  };

  const modalData = useSelector((state) => state.super?.signInBreaksModal);

  return (
    <MainModal
      className="sign-in-breaks-modal"
      modalTitle={`${modalData?.name} - ${modalData?.date}`}
      toggle={handleCloseModal}
      isOpen={modalData?.isOpen}
      hasModalFooter={false}
    >
      {modalData?.breakInOuts?.map((breakInOut, index) => (
        <div
          key={breakInOut?.id}
          className={`d-flex align-items-center justify-content-between py-3 ${
            modalData?.breakInOuts?.length > 1 &&
            index < modalData?.breakInOuts?.length - 1
              ? "border-bottom"
              : ""
          }`}
        >
          <div className="mr-3 d-flex align-items-center">
            <ArrowDownward className="break-in-icon" />
            <p className="mb-0">
              {moment(breakInOut?.break_in_time).format("hh:mm A")}
            </p>
          </div>

          <div className="mr-3 d-flex align-items-center">
            <ArrowUpward className="break-out-icon" />
            <p className="mb-0">
              {moment(breakInOut?.break_out_time).format("hh:mm A")}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <Alarm className="break-duration" />
            <p className="mb-0">
              {getDurationInHours(
                breakInOut?.break_in_time,
                breakInOut?.break_out_time
              )}
              :
              {getDurationInMinutes(
                breakInOut?.break_in_time,
                breakInOut?.break_out_time
              )}
            </p>
          </div>
        </div>
      ))}
    </MainModal>
  );
};

export default SignInBreaksModal;
