import React, { useState } from "react";
import { connect } from "react-redux";

import {
  VisibilityOff,
  Visibility,
  VpnKey,
  EmailOutlined,
} from "@mui/icons-material";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";

import { resetPasswordAttempt } from "../../Store/Actions";
import { Spinner } from "reactstrap";
import LoginLayout from "../../Components/LoginLayout";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPasswordPage = (props) => {
  const { t } = useTranslation();
  // Data from sent email
  const { token } = useParams();
  let query = useQuery();

  const email = query.get("email") ?? query.get("phone");

  // to handle show/hide password btn
  const [showPasswordState, setShowPasswordState] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  // password inputs value
  const [passwordValue, setPasswordValue] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  // password confirm validation message
  const [validationMsg, setValidationMsg] = useState("");

  const handleToggleShowPassword = (type) => {
    setShowPasswordState((state) => ({
      ...state,
      [type]: !state[type],
    }));
  };

  const handlePasswordInput = (e, type) => {
    setValidationMsg("");
    setPasswordValue((state) => ({
      ...state,
      [type]: e.target.value,
    }));
  };

  const handleSubmitBtn = (e) => {
    e.preventDefault();

    // Handle Empty password
    if (!passwordValue.newPassword || !passwordValue.confirmPassword) {
      return setValidationMsg("Please Enter your password");
    }
    // Handle Not matching password
    if (passwordValue.newPassword !== passwordValue.confirmPassword) {
      return setValidationMsg("Please make sure your passwords match");
    }

    props.resetPasswordAttempt({
      email: email,
      token: token.trim(),
      password: passwordValue.newPassword,
      password_confirmation: passwordValue.confirmPassword,
    });
  };

  return (
    <LoginLayout>
      <form method="POST" onSubmit={handleSubmitBtn}>
        <h4>{t("reset password")}</h4>
        <div className="d-flex flex-column login_input_wrapper_style">
          <label>
            {t("email")} / {t("phone")}
          </label>
          <OutlinedInput
            type="text"
            value={email}
            disabled
            classes={{
              root: "custom_outlined_input_style",
            }}
          />
        </div>

        <div className="my-2 d-flex flex-column login_input_wrapper_style">
          <label>{t("new password")}</label>
          <OutlinedInput
            type={showPasswordState.newPassword ? "text" : "password"}
            value={passwordValue.newPassword}
            onChange={(e) => handlePasswordInput(e, "newPassword")}
            placeholder={t("new password")}
            classes={{
              root: "custom_outlined_input_style",
            }}
            startAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <VpnKey />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleToggleShowPassword("newPassword")}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordState.newPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>

        <div className="my-2 d-flex flex-column login_input_wrapper_style">
          <label>{t("confirm new password")}</label>
          <OutlinedInput
            type={showPasswordState.confirmPassword ? "text" : "password"}
            value={passwordValue.confirmPassword}
            onChange={(e) => handlePasswordInput(e, "confirmPassword")}
            placeholder={t("confirm new password")}
            classes={{
              root: "custom_outlined_input_style",
            }}
            startAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <VpnKey />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleToggleShowPassword("confirmPassword")}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordState.confirmPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>

        {validationMsg && (
          <div className="warnig-msg-style mt-2">{validationMsg}</div>
        )}

        <div className="d-flex mt-4 pt-2">
          <button
            className="btn btn-block login_btn_style mt-0"
            onClick={handleSubmitBtn}
            type="submit"
            disabled={props.resetPasswordLoading}
          >
            {props.resetPasswordLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                size="sm"
                color="#fff"
              />
            ) : (
              t("submit")
            )}
          </button>
        </div>
      </form>
    </LoginLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    resetPasswordLoading: state.user.resetPasswordLoading,
  };
};

export default connect(mapStateToProps, {
  resetPasswordAttempt,
})(ResetPasswordPage);
