import { CRM_PRIVILEGES } from "./CRM";
import { assignmentPrivileges } from "./Attendance";

export default {
  COMPANY_SETTINGS: "1",
  VIEW_OFFICES: "2",
  ADD_EDIT_DELETE_OFFICES: "3",
  VIEW_DEPARTMENTS: "4",
  ADD_EDIT_DELETE_DEPARTMENTS: "5",
  VIEW_POSITIONS: "6",
  ADD_EDIT_DELETE_POSITIONS: "7",
  VIEW_COMPANY_DOCUMENTS: "8",
  MANAGE_COMPANY_DOCUMENTS: "9",
  VIEW_EMPLOYEES: "10",
  ADD_EDIT_DELETE_EMPLOYEES: "11",
  ACTIVATE_SUSPEND_EMPLOYEES: "12",
  VIEW_EMPLOYEE_PROFILES: "13",
  // VIEW_EMPLOYEE_BALANCE_HISTORY: VIEW_EMPLOYEE_BALANCE_ADJUSTMENT,
  // VIEW_EMPLOYEE_PROFILE_REQUESTS: VIEW_EMPLOYEE_REQUESTS,
  // MANAGE_EMPLOYEE_PROFILE_REQUESTS:MANAGE_EMPLOYEE_REQUESTS ,
  // VIEW_DASHBOARD: "",
  VIEW_EMPLOYEE_DAILY_STATUSES: "18",
  VIEW_EMPLOYEE_ATTENDANCE_HISTORY_CHART: "19",
  VIEW_EMPLOYEE_OVERTIME_CHART: "20",
  VIEW_EMPLOYEES_PER_DEPARTMENT_CHART: "21",
  VIEW_ATTENDANCE_PROFILES: "22",
  ADD_CLONE_PROFILES: "23",
  EDIT_ATTENDANCE_PROFILES: "24",
  DELETE_ATTENDANCE_PROFILES: "25",
  VIEW_ATTENDANCE_HISTORY_LOGS: "26",
  EDIT_EMPLOYEE_ATTENDANCE: "27",
  VIEW_EMPLOYEE_REQUESTS: "28",
  MANAGE_EMPLOYEE_REQUESTS: "29",
  VIEW_EMPLOYEE_BALANCE_ADJUSTMENT: "30",
  ADD_DEDUCT_LEAVE_BALANCE: "32",
  VIEW_EMPLOYEE_PAYABLE_OVERTIME: "33",
  PAYROLL_MANAGEMENT: "34",
  VIEW_PAYROLL_LIST: "35",
  SUBMIT_PAYMENTS: "36",
  REVERSE_PAYMENTS: "37",
  EDIT_SOCIAL_MEDICAL_TAXES_AMOUNT: "38",
  EXPORT_PAYROLL_TABLE: "39",
  VIEW_MONTHLY_PAYSLIPS: "40",
  EXPORT_PAYSLIPS: "41",
  VIEW_EMPLOYEE_CLAIMS: "42",
  MANAGE_CLAIM_REQUESTS: "43",
  VIEW_EMPLOYEE_BONUSES_DEDUCTIONS: "44",
  ADD_EDIT_DELETE_BONUSES_DEDUCTIONS: "45",
  VIEW_PROFILE: "46",
  VIEW_LEAVE_BALANCE_HISTORY: "47",
  VIEW_REQUESTS: "48",
  APPLY_FOR_REQUESTS: "49",
  APPLY_FOR_LEAVE_REQUESTS: "50",
  APPLY_FOR_CLAIM_REQUESTS: "51",
  VIEW_ATTENDANCE_HISTORY: "52",
  TAKE_BREAKS: "53",
  VIEW_COMPANIES: "54",
  ADD_EDIT_COMPANY: "55",
  DELETE_COMPANY: "56",
  SUSPEND_ACTIVATE_COMPANY: "57",
  ADD_NEW_SUBSCIPTION: "58",
  EDIT_DELETE_SUBSCIPTION: "59",
  VIEW_ADMIN_USERS: "60",
  ADD_ADMIN_USERS: "61",
  REMOVE_ADMIN_USERS: "62",
  EDIT_ADMIN_USERS_PRIVILEGES: "63",
  VIEW_BUSINESS_PARTNERS: "64",
  ADD_EDIT_BUSINESS_PARTNERS: "65",
  DELETE_BUSINESS_PARTNERS: "66",
  SUSPEND_ACTIVATE_BUSINESS_PARTNERS: "67",
  BUSINESS_PARTNER_PRIVILEGES: "69",
  VIEW_EMPLOYEE_SALARY_CONFIGURATION: "70",
  ADD_EMPLOYEE_SALARY_CONFIGURATION: "71",
  VIEW_EMPLOYEE_DOCUMENTS: "72",
  MANAGE_EMPLOYEE_DOCUMENTS: "73",
  VIEW_PAYROLL: "74",
  VIEW_PAYSLIP: "75",
  EXPORT_PAYSLIP: "76",
  ATTENDANCE_SETTINGS: "77",
  // VIEW_INBOUND_REQUESTS: VIEW_EMPLOYEE_REQUESTS,
  // BASIC_PRIVILEGES: "",
  EDIT_USER_PRIVILEGE: "80",
  VIEW_EMPLOYEE_PENALTIES: "81",
  VIEW_PENALTIES: "82",
  // VIEW_EMPLOYEE_ATTENDANCE_LOGS: VIEW_ATTENDANCE_HISTORY_LOGS,
  SIGN_IN_AND_SIGN_OUT_FOR_ALL_OFFICE_USERS: "84",
  RESET_OFFICE_PASSWORD: "85",
  VIEW_ATTENDANCE_MONTHLY_REPORT: "86",
  EXPORT_ATTENDANCE_MONTHLY_REPORT: "87",
  VIEW_EMPLOYEE_DIRECTORY: "88",
  VIEW_EMPLOYEE_DIRECTORY_IN_MY_DEPARTMENT: "89",
  VIEW_EMPLOYEE_DIRECTORY_IN_OTHER_DEPARTMENTS: "90",
  VIEW_EMPLOYEE_EMAILS: "91",
  VIEW_EMPLOYEE_PHONE_NUMBERS: "92",
  WATCH_OTHER_EMPLOYEES: "93",
  VIEW_HOLIDAYS: "94",
  ADD_EDIT_HOLIDAYS: "95",
  DELETE_HOLIDAYS: "96",
  VIEW_COMPANY_PROFILE: "97",
  VIEW_SUBSCRIPTIONS: "98",
  VIEW_ASSIGNED_ACCOUNT_MANAGERS: "99",
  ASSIGN_ACCOUNT_MANAGERS: "100",
  VIEW_ADMIN_USER_PROFILE: "101",
  ASSIGN_REMOVE_ADMIN_USER_TO_COMPANIES: "102",
  SUPER_PRIVILEGE: "103",
  CUSTOMIZE_NOTIFICATIONS: "104",
  MANAGE_ADMIN_USER_PRIVILEGES: "105",
  VIEW_WORK_TIMINGS: "106",
  ADD_EDIT_CLONE_WORK_TIMINGS: "107",
  DELETE_WORK_TIMINGS: "108",
  PREVIEW_WORK_TIMINGS: "109",
  VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE: "110",
  MODIFY_EMPLOYEE_PETTY_CASH_BALANCE: "111",
  ADD_NEW_EXPENSE_TO_EMPLOYEES: "112",
  VIEW_PETTY_CASH_BALANCE_ADJUSTMENT_LIST: "113",
  VIEW_PETTY_CASH_BALANCE_LIST: "114",
  ADD_EDIT_COMPANY_EXPENSES: "115",
  // VIEW_COMPANY_SETTINGS:VIEW_EXPENSE_CATEGORIES,
  VIEW_EXPENSE_CATEGORIES: "117",
  ADD_EDIT_EXPENSE_CATEGORIES: "118",
  DELETE_EXPENSE_CATEGORIES: "119",
  // FINANCIAL_MANAGEMENT: ,
  VIEW_COMPANY_EXPENSES: "121",
  DELETE_COMPANY_EXPENSES: "122",
  ADD_EXPENSES_ON_BEHALF_OF_OTHER_EMPLOYEES: "123",
  ADD_EXPENSE: "124",
  MODIFY_PETTY_CASH_BALANCE: "125",
  VIEW_REQUEST_RULES: "126",
  VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_MY_DEPARTMENT: "127",
  VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS: "128",
  ADD_EDIT_DELETE_REQUEST_RULES: "129",
  TURN_RULES_ON_OFF: "130",
  SUBMIT_REQUEST_ON_BEHALF_OF_EMPLOYEES: "131",
  VIEW_THE_EXPENSES_OF_MY_OFFICE: "132",
  VIEW_THE_EXPENSES_OF_ALL_OFFICES: "133",
  VIEW_MISSING_EXPIRED_DOCUMENT: "134",

  ADD_EDIT_NEW_DOCUMENTS: "135",
  EMPLOYEE_DOCUMENTS: "136",
  // VIEW_MISSING_EXPIRED_DOCUMENT: ,
  // VIEW_EMPLOYEES_ON_BREAK: "",
  VIEW_EXPENSES_CHART: "139",
  VIEW_THE_EXPENSES_CHART_OF_MY_OFFICE: "140",
  VIEW_THE_EXPENSES_CHART_OF_ALL_OFFICES: "141",
  VIEW_OPEN_PAYROLL_MONTHS: "142",
  VIEW_TURNOVER_CHART: "143",
  ADD_EDIT_DELETE_PLANS: "144",
  ACTIVATE_DEACTIVATE_PLANS: "145",
  VIEW_PLAN_PROFILE: "146",
  VIEW_PLAN_PRIVILEGES: "147",
  VIEW_PLAN_ROLE_TEMPLATES: "148",
  ADD_EDIT_DELETE_ROLE_TEMPLATES: "149",
  VIEW_PLANS: "150",
  EXPORT_EXPENSES: "151",
  VERIFY_PHONE_EMAIL: "152",
  VIEW_COMMENTS_ON_MY_REQUESTS: "153",
  COMMENT_ON_MY_REQUESTS: "154",
  // VIEW_EMPLOYEE_PROFILE_COMMENTS_ON_REQUESTS: VIEW_COMMENTS_ON_REQUESTS,
  // EMPLOYEE_PROFILE_COMMENT_ON_REQUESTS: COMMENT_ON_REQUESTS,
  VIEW_COMMENTS_ON_REQUESTS: "157",
  COMMENT_ON_REQUESTS: "158",
  RECRUITMENT_MANAGEMENT: "159",
  VIEW_RECRUITMENT_PROCESSES: "160",
  ADD_EDIT_DELETE_RECRUITMENT_PROCESSES: "161",
  ACTIVATE_DEACTIVATE_RECRUITMENT_PROCESSES: "162",
  VIEW_INTAKE_FORMS: "163",
  ADD_EDIT_DELETE_INTAKE_FORMS: "164",
  ACTIVATE_DEACTIVATE_INTAKE_FORMS: "165",
  VIEW_JOB_POSTS: "166",
  ADD_EDIT_DELETE_JOB_POSTS: "167",
  ACTIVATE_DEACTIVATE_JOB_POSTS: "168",
  CHANGE_APPLIACATION_STATUS: "169",
  VIEW_JOB_POST_APPLICANTS: "170",
  VIEW_APPLICANT_PROFILES: "171",
  EDIT_STATUSES_OF_JOB_APPLICATIONS: "172",
  APPLY_REVERT_PENALTIES: "159",
  LEAVE_AND_REQUEST_MANAGEMENT: "160",
  PETTY_CASH_AND_EXPENSE_MANAGEMENT: "161",
  EMPLOYEE_BASIC_PRIVILEGES: "162",
  ATTENDANCE_BASIC_PRIVILEGES: "163",
  REQUESTS_BASIC_PRIVILEGES: "164",
  PAYROLL_BASIC_PRIVILEGES: "165",
  PETTY_CASH_BASIC_PRIVILEGES: "166",
  EMPLOYEE_MANAGEMENT: "167",
  ATTENDANCE_HISTORY: "168",
  MANAGE_EMPLOYEE_ATTENDANCE: "169",
  VIEW_ATTENDANCE_REPORTS: "170",
  RECRUITMENT_MANAGEMENT: "171",
  VIEW_RECRUITMENT_PROCESSES: "172",
  ADD_EDIT_DELETE_RECRUITMENT_PROCESSES: "173",
  ACTIVATE_DEACTIVATE_RECRUITMENT_PROCESSES: "174",
  VIEW_INTAKE_FORMS: "175",
  ADD_EDIT_DELETE_INTAKE_FORMS: "176",
  ACTIVATE_DEACTIVATE_INTAKE_FORMS: "177",
  VIEW_JOB_POSTS: "178",
  ADD_EDIT_DELETE_JOB_POSTS: "179",
  ACTIVATE_DEACTIVATE_JOB_POSTS: "180",
  CHANGE_APPLIACATION_STATUS: "181",
  VIEW_JOB_POST_APPLICANTS: "182",
  VIEW_APPLICANT_PROFILES: "183",
  EDIT_STATUSES_OF_JOB_APPLICATIONS: "184",
  VIEW_CLAIMS_IN_EMPLOYEE_PROFILE: "185",
  IMPORT_COMPANY_EMPLOYEES: "186",
  VIEW_ADMIN_HOLIDAYS: "187",
  ADD_EDIT_DELETE_ADMIN_HOLIDAYS: "188",
  ADD_NEW_ACCESS_LEVELS: "189",
  GENERATE_DEMO: "190",
  VIEW_ACCESS_LEVELS: "191",
  ADD_EDIT_DELETE_NEW_ACCESS_LEVELS: "192",
  LOAN_MANAGEMENT: "193",
  VIEW_LOANS_LIST: "194",
  RECEIVE_CONTACT_EMAILS_FROM_WEBSITE: "195",
  VIEW_LEAVE_AND_BREAK_PROFILES: "196",
  ADD_EDIT_CLONE_LEAVE_BREAK_PROFILES: "197",
  DELETE_LEAVE_BREAK_PROFILES: "198",
  VIEW_LOCATIONS: "199",
  ADD_EDIT_DELETE_LOCATIONS: "200",
  VIEW_NOTIFICATIONS_HISTORY: "201",
  APPLY_FOR_EDIT_ATTENDANCE_REQUESTS: "202",
  ADD_EDIT_DELETE_LOAN: "203",
  SETTLE_INSTALLMENT_PAYMENTS: "204",
  VIEW_MY_LOANS: "205",
  SIGN_IN_OUT_ON_BEHALF_OF_EMPLOYEES: "209",
  SEND_VERIFICATION_LINK: "245",
  ...CRM_PRIVILEGES,
  VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS: "246",
  ADD_EDIT_ATTENDANCE_TYPE_CONFIGURATION: "247",
  VIEW_EMPLOYEE_WORK_GROUPS: "248",
  ADD_EDIT_DELETE_WORK_GROUPS: "249",
  VIEW_WORK_TEAMS: "250",
  ADD_EDIT_TEAMS: "251",
  VIEW_TEAM_MEMBERS: "252",
  ADD_REMOVE_EMPLOYEES_FROM_TEAMS: "253",
  VIEW_EMPLOYEE_SCHEDULES: "254",
  PLAN_NEW_SCHEDULES_FOR_MANAGED_WORKGROUPS: "255",
  VIEW_DRAFTED_SCHEDULES: "256",
  EDIT_DELETE_DRAFTED_SCHEDULES: "257",
  VIEW_PROFILE_CALENDAR: "258",
  ...assignmentPrivileges,

  // request approval layers start
  VIEW_APPROVAL_LAYERS: "259",
  VIEW_APPROVAL_LAYERS_APPLIED_ON_MY_OFFICE: "260",
  ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_OFFICE: "261",
  VIEW_APPROVAL_LAYERS_APPLIED_ON_ALL_OFFICE: "262",
  ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_OFFICE: "263",
  VIEW_APPROVAL_LAYERS_APPLIED_ON_MY_DEPARTMENT: "264",
  ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_DEPARTMENT: "265",
  VIEW_APPROVAL_LAYERS_APPLIED_ON_ALL_DEPARTMENT: "266",
  ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_DEPARTMENT: "267",
  VIEW_APPROVAL_LAYERS_APPLIED_ON_EMPLOYEES: "268",
  ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_EMPLOYEES: "269",
  // request approval layers end

  VIEW_ADMIN_ANNOUNCEMENT: "291",
  ADD_EDIT_DELETE_ADMIN_ANNOUNCEMENT: "292",
  VIEW_COMPANY_ANNOUNCEMENT: "293",
  ADD_EDIT_DELETE_COMPANY_ANNOUNCEMENT: "294",
  IGNORE_ASSIGNMENT: "298",
};
