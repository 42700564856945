import { put, call, takeLatest, select, takeEvery } from "redux-saga/effects";
import Types from "../Actions/types";

import * as Action from "../Actions";
import * as Client from "../../Services/Client";

import HelperFns from "../../Helpers/HelperFns";
import history from "../../Helpers/Navigations/history";

export const getRecruitmentReducer = (state) => state.recruitment;

export function* handleFetchRecruitmentProcessesListSaga({ payload }) {
  try {
    const response = yield call(Client.fetchRecruitmentProcessesList, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchRecruitmentProcessesSuccess(
          payload?.ref,
          response?.data?.processes
        )
      );
    } else {
      yield put(Action.fetchRecruitmentProcessesFailed(payload?.ref));
      yield put(
        Action.showErrorToast(
          response.errors[0]?.extensions?.reason || response.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchRecruitmentProcessesFailed(payload?.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchRecruitmentProcesses() {
  yield takeLatest(
    Types.FETCH_RECRUITMENT_PROCESSES_LIST_ATTEMPT,
    handleFetchRecruitmentProcessesListSaga
  );
}

export function* handleDeleteRecruitmentProcessSaga({ payload }) {
  try {
    const response = yield call(Client.deleteRecruitmentProcess, payload);
    if (
      !response.errors &&
      response.data?.delete_process?.status == "success"
    ) {
      yield put(Action.deleteRecruitmentProcessesSuccess());
      yield put(
        Action.showSuccessToast(response.data?.delete_process?.message)
      );
      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchRecruitmentProcessesAction({
          ref: "recruitmentProcessesList",
          recruitmentProcessesList:
            recruitmentReducer["recruitmentProcessesList"],
        })
      );
    } else {
      yield put(Action.deleteRecruitmentProcessesFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_process?.message ||
            response?.errors?.[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteRecruitmentProcessesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteRecruitmentProcess() {
  yield takeLatest(
    Types.DELETE_RECRUITMENT_PROCESSES_ATTEMPT,
    handleDeleteRecruitmentProcessSaga
  );
}

export function* handleUpdateRecruitmentProcessStatusSaga({ payload }) {
  try {
    const response = yield call(Client.updateRecruitmentProcessStatus, payload);
    if (!response.errors) {
      yield put(Action.updateRecruitmentProcessesStatusSuccess());

      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchRecruitmentProcessesAction({
          ref: "recruitmentProcessesList",
          recruitmentProcessesList:
            recruitmentReducer["recruitmentProcessesList"],
        })
      );
      yield put(Action.showSuccessToast());
    } else {
      yield put(Action.updateRecruitmentProcessesStatusFailed());
      yield put(
        Action.showErrorToast(
          response?.errors?.[0]?.extensions?.reason ||
            response.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.updateRecruitmentProcessesStatusFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpdateRecruitmentProcessStatus() {
  yield takeLatest(
    Types.UPDTAE_RECRUITMENT_PROCESSES_STATUS_ATTEMPT,
    handleUpdateRecruitmentProcessStatusSaga
  );
}

export function* handleFetchIntakeFormsListSaga({ payload }) {
  try {
    const response = yield call(Client.fetchIntakeFormsList, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchIntakeFormsSuccess(
          payload?.ref,
          response?.data?.intake_forms
        )
      );
    } else {
      yield put(Action.fetchIntakeFormsFailed(payload?.ref));
      yield put(
        Action.showErrorToast(
          response?.errors[0]?.extensions?.reason ||
            response?.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchIntakeFormsFailed(payload?.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchIntakeForms() {
  yield takeLatest(
    Types.FETCH_INTAKE_FORMS_LIST_ATTEMPT,
    handleFetchIntakeFormsListSaga
  );
}

export function* handleDeleteIntakeFormSaga({ payload }) {
  try {
    const response = yield call(Client.deleteIntakeForm, payload);
    if (
      !response.errors &&
      response.data?.delete_intake_form?.status == "success"
    ) {
      yield put(Action.deleteIntakeFormsSuccess());
      yield put(
        Action.showSuccessToast(
          response?.data?.delete_intake_form?.message
        )
      );
      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchIntakeFormsAction({
          ref: "intakeFormsList",
          intakeFormsList: recruitmentReducer["intakeFormsList"],
        })
      );
    } else {
      yield put(Action.deleteIntakeFormsFailed());
      yield put(
        Action.showErrorToast(
          response?.data?.delete_intake_form?.message ||
            response?.errors?.[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteIntakeFormsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteIntakeForm() {
  yield takeLatest(
    Types.DELETE_INTAKE_FORMS_ATTEMPT,
    handleDeleteIntakeFormSaga
  );
}

export function* handleCloneIntakeFormSaga({ payload }) {
  try {
    const response = yield call(Client.cloneIntakeForm, payload);
    if (
      !response.errors &&
      response.data?.clone_intake_form?.status == "success"
    ) {
      yield put(Action.cloneIntakeFormsSuccess());
      yield put(
        Action.showSuccessToast(
          response.data?.clone_intake_form?.message
        )
      );
      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchIntakeFormsAction({
          ref: "intakeFormsList",
          intakeFormsList: recruitmentReducer["intakeFormsList"],
        })
      );
    } else {
      yield put(Action.cloneIntakeFormsFailed());
      yield put(
        Action.showErrorToast(
          response.data?.clone_intake_form?.message ||
            response.errors?.[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.cloneIntakeFormsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onCloneIntakeForm() {
  yield takeLatest(Types.CLONE_INTAKE_FORMS_ATTEMPT, handleCloneIntakeFormSaga);
}

export function* handleUpdateIntakeFormStatusSaga({ payload }) {
  try {
    const response = yield call(Client.updateIntakeFormStatus, payload);
    if (!response.errors) {
      yield put(Action.updateIntakeFormsStatusSuccess());

      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchIntakeFormsAction({
          ref: "intakeFormsList",
          intakeFormsList: recruitmentReducer["intakeFormsList"],
        })
      );
      yield put(Action.showSuccessToast());
    } else {
      yield put(Action.updateIntakeFormsStatusFailed());
      yield put(
        Action.showErrorToast(
          response.errors?.[0]?.extensions?.reason ||
            response.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.updateIntakeFormsStatusFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpdateIntakeFormStatus() {
  yield takeLatest(
    Types.UPDTAE_INTAKE_FORMS_STATUS_ATTEMPT,
    handleUpdateIntakeFormStatusSaga
  );
}

export function* handleFetchJobPostsListSaga({ payload }) {
  try {
    const response = yield call(Client.fetchJobPostsList, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchJobPostsSuccess(payload?.ref, response?.data?.job_posts)
      );
    } else {
      yield put(Action.fetchJobPostsFailed(payload?.ref));
      yield put(
        Action.showErrorToast(
          response.errors?.[0]?.extensions?.reason ||
            response.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchJobPostsFailed(payload?.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchJobPosts() {
  yield takeLatest(
    Types.FETCH_JOB_POSTS_LIST_ATTEMPT,
    handleFetchJobPostsListSaga
  );
}

export function* handleDeleteJobPostsSaga({ payload }) {
  try {
    const response = yield call(Client.deleteJobPost, payload);
    if (
      !response.errors &&
      response.data?.delete_job_post?.status == "success"
    ) {
      yield put(Action.deleteJobPostsSuccess());
      yield put(
        Action.showSuccessToast(response.data?.delete_job_post?.message)
      );
      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchJobPostsAction({
          ref: "jobPostsList",
          jobPostsList: recruitmentReducer["jobPostsList"],
        })
      );
    } else {
      yield put(Action.deleteJobPostsFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_job_post?.message ||
            response?.errors?.[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteJobPostsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteJobPosts() {
  yield takeLatest(Types.DELETE_JOB_POSTS_ATTEMPT, handleDeleteJobPostsSaga);
}

export function* handleUpdateJobPostsStatusSaga({ payload }) {
  try {
    const response = yield call(Client.updateJobPostStatus, payload);
    if (!response.errors) {
      yield put(Action.updateJobPostsStatusSuccess());

      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchJobPostsAction({
          ref: "jobPostsList",
          jobPostsList: recruitmentReducer["jobPostsList"],
        })
      );
      yield put(Action.showSuccessToast());
    } else {
      yield put(Action.updateJobPostsStatusFailed());
      yield put(
        Action.showErrorToast(
          response.errors?.[0]?.extensions?.reason ||
            response.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.updateJobPostsStatusFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpdateJobPostsStatus() {
  yield takeLatest(
    Types.UPDTAE_JOB_POSTS_STATUS_ATTEMPT,
    handleUpdateJobPostsStatusSaga
  );
}

export function* handleFetchApplicationListSaga({ payload }) {
  try {
    const response = yield call(Client.fetchApplicationsList, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchApplicationsListSuccess(
          payload?.ref,
          response?.data?.applicants_list,
          response?.data?.job_post
        )
      );
      yield put(
        Action.fetchProcessStagesOptionsAction(
          response?.data?.get_job_post_stages
        )
      );
    } else {
      yield put(Action.fetchApplicationsListFailed(payload?.ref));
      yield put(
        Action.showErrorToast(
          response.errors?.[0]?.extensions?.reason ||
            response.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchApplicationsListFailed(payload?.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchApplications() {
  yield takeLatest(
    Types.FETCH_APPLICATION_LIST_ATTEMPT,
    handleFetchApplicationListSaga
  );
}

// Start Fetch Init Recruitment Saga  ------------------------------------------------

export function* handleInitRecruitmentProcessFormSaga({ payload }) {
  try {
    const response = yield call(Client.initRecruitmentProcessForm, payload);
    if (!response?.errors) {
      yield put(
        Action.initRecruitmentProcessSuccess(response?.data?.validation_types)
      );
      if (!!payload && !!response?.data?.recruitmentProcessesForm?.id) {
        yield put(
          Action.fetchRecruitmentProcessSuccess(
            response?.data?.recruitmentProcessesForm
          )
        );
      }
    } else {
      yield put(Action.initRecruitmentProcessFailed());
      yield put(
        Action.showErrorToast(
          response.errors?.[0]?.extensions?.reason ||
            response.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.initRecruitmentProcessFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onInitRecruitmentProcessForm() {
  yield takeLatest(
    Types.INIT_RECRUITMENT_PROCESS_FORM_ATTEMPT,
    handleInitRecruitmentProcessFormSaga
  );
}
export function* handleInitRecruitmentIntakeFormSaga({ payload }) {
  try {
    const response = yield call(Client.initRecruitmentIntakeForm, payload);
    if (!response?.errors) {
      yield put(
        Action.initRecruitmentIntakeFormSuccess(
          response?.data?.validation_types
        )
      );
      if (!!payload && !!response?.data?.recruitmentIntakeForm?.id) {
        yield put(
          Action.fetchRecruitmentIntakeFormSuccess(
            response?.data?.recruitmentIntakeForm
          )
        );
      }
    } else {
      yield put(Action.initRecruitmentIntakeFormFailed());
      yield put(
        Action.showErrorToast(
          response.errors?.[0]?.extensions?.reason ||
            response.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.initRecruitmentIntakeFormFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onInitRecruitmentIntakeForm() {
  yield takeLatest(
    Types.INIT_RECRUITMENT_INTAKE_FORM_ATTEMPT,
    handleInitRecruitmentIntakeFormSaga
  );
}

// End Fetch Init Recruitment Saga  ------------------------------------------------

export function* handleUpsertRecruitmentProcessSaga({ payload }) {
  try {
    const response = yield call(Client.upsertRecruitmentProcess, payload);
    if (!response?.errors) {
      yield put(Action.upsertRecruitmentProcessSuccess());
      yield put(Action.showSuccessToast());
      history.push("/recruitment?tab=recruitment-process");
      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchRecruitmentProcessesAction({
          ref: "recruitmentProcessesList",
          recruitmentProcessesList:
            recruitmentReducer["recruitmentProcessesList"],
        })
      );
    } else {
      if (response?.errors?.[0]?.extensions?.reason) {
        yield put(Action.upsertRecruitmentProcessFailed({}));
        yield put(
          Action.showErrorToast(response.errors?.[0]?.extensions?.reason)
        );
      }
      yield put(
        Action.upsertRecruitmentProcessFailed(
          response?.errors[0]?.extensions?.validation ?? {}
        )
      );
      console.log(response.errors[0]);
    }
  } catch (error) {
    yield put(Action.upsertRecruitmentProcessFailed({}));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpsertRecruitmentProcess() {
  yield takeLatest(
    Types.UPSERT_RECRUITMENT_PROCESS_ATTEMPT,
    handleUpsertRecruitmentProcessSaga
  );
}

export function* handleUpsertRecruitmentIntakeSaga({ payload }) {
  try {
    const response = yield call(Client.upsertRecruitmentIntake, payload);
    if (!response?.errors) {
      yield put(Action.upsertRecruitmentIntakeSuccess());
      yield put(Action.showSuccessToast());
      history.push("/recruitment?tab=intake-forms");
      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchIntakeFormsAction({
          ref: "intakeFormsList",
          intakeFormsList: recruitmentReducer["intakeFormsList"],
        })
      );
    } else {
      yield put(
        Action.upsertRecruitmentIntakeFailed(
          response?.errors[0]?.extensions?.validation ?? {}
        )
      );
      console.log(response.errors[0]);
    }
  } catch (error) {
    yield put(Action.upsertRecruitmentIntakeFailed({}));
  }
}

function* onUpsertRecruitmentIntake() {
  yield takeLatest(
    Types.UPSERT_RECRUITMENT_INTAKE_ATTEMPT,
    handleUpsertRecruitmentIntakeSaga
  );
}

export function* handleUpdateApplicationStageStatusSaga({ payload }) {
  try {
    const response = yield call(Client.updateApplicationStageStatus, payload);
    if (
      !response.errors &&
      response.data?.change_application_stage?.status == "success"
    ) {
      yield put(
        Action.updateApplicationsStatusSuccess(payload.applicant_job_post_id)
      );
      // yield put(Action.showSuccessToast(response.data?.change_application_stage?.message));
      if (window.location.pathname.includes("recruitment/applicant-profile/")) {
        yield put(
          Action.fetchApplicantProfileAction({
            id: payload?.applicant_job_post_id,
          })
        );
      } else {
        let recruitmentReducer = yield select(getRecruitmentReducer);
        yield put(
          Action.fetchApplicationsListAction({
            job_post_id: payload?.jobPostId,
            ref: "applicationsList",
            applicationsList: recruitmentReducer["applicationsList"],
          })
        );
      }
    } else {
      yield put(
        Action.updateApplicationsStatusFailed(payload.applicant_job_post_id)
      );
      yield put(
        Action.showErrorToast(
          response.data?.change_application_stage?.message ||
            response?.errors?.[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(
      Action.updateApplicationsStatusFailed(payload.applicant_job_post_id)
    );
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpdateApplicationStageStatus() {
  yield takeEvery(
    Types.UPDATE_APPLICATION_STAGE_STATUS_ATTEMPT,
    handleUpdateApplicationStageStatusSaga
  );
}

export function* handleFetchApplicantsListSaga({ payload }) {
  try {
    const response = yield call(Client.fetchApplicantsList, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchApplicantsSuccess(
          payload?.ref,
          response?.data?.applicants_list
        )
      );
      yield put(Action.fetchJobPostsOptionsAction(response?.data?.job_posts));
      yield put(
        Action.fetchProcessStagesOptionsAction(
          response?.data?.get_job_post_stages
        )
      );
    } else {
      yield put(Action.fetchApplicantsFailed(payload?.ref));
      yield put(
        Action.showErrorToast(
          response.errors?.[0]?.extensions?.reason ||
            response.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchApplicantsFailed(payload?.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchApplicants() {
  yield takeLatest(
    Types.FETCH_APPLICANTS_LIST_ATTEMPT,
    handleFetchApplicantsListSaga
  );
}

export function* handleDeleteApplicantsSaga({ payload }) {
  try {
    const response = yield call(Client.deleteApplicant, payload);
    if (
      !response.errors &&
      response.data?.delete_applicant?.status == "success"
    ) {
      yield put(Action.deleteApplicantsSuccess());
      yield put(
        Action.showSuccessToast(response.data?.delete_applicant?.message)
      );
      if (window.location.pathname.includes("recruitment/applicant-profile/")) {
        setTimeout(function () {
          window.history.back();
        }, 1000);
        return;
      }
      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchApplicantsAction({
          ref: "applicantsList",
          applicantsList: recruitmentReducer["applicantsList"],
        })
      );
    } else {
      yield put(Action.deleteApplicantsFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_applicant?.message ||
            response?.errors?.[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteApplicantsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteApplicants() {
  yield takeLatest(Types.DELETE_APPLICANTS_ATTEMPT, handleDeleteApplicantsSaga);
}

export function* handleUpsertApplicationSaga({ payload, ref, external }) {
  try {
    const response = yield call(Client.upsertApplication, {
      payload,
      external,
    });
    if (!response?.errors) {
      yield put(Action.upsertApplicationSuccess(ref));
      yield put(
        Action.dismissApplicationModalAction({
          ref: ref,
          formName: "applicationModal",
        })
      );
      yield put(Action.showSuccessToast());
      if (external) {
        // direct to another route with new component
        history.push("/JobApplicationSuccess");
        // window.history.go(-1);
        return;
      }
      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchApplicationsListAction({
          job_post_id: payload?.job_post_id,
          ref: "applicationsList",
          applicationsList: recruitmentReducer["applicationsList"],
        })
      );
    } else {
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.upsertApplicationFailed({
            ref: ref,
            serverRef: "applicationModalServerValidation",
            errors: HelperFns.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            ),
          })
        );
      } else {
        yield put(
          Action.upsertApplicationFailed({
            ref: ref,
            serverRef: "applicationModalServerValidation",
            errors: {},
            modalMessage:
              response?.errors[0]?.extensions?.reason ??
              response?.errors[0]?.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(
      Action.upsertApplicationFailed({
        ref: ref,
        serverRef: "applicationModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpsertApplication() {
  yield takeLatest(
    Types.UPSERT_APPLICATION_ATTEMPT,
    handleUpsertApplicationSaga
  );
}

export function* handleUpsertJobPostSaga({ payload }) {
  try {
    const response = yield call(Client.upsertJobPost, payload.data);
    if (!response?.errors) {
      yield put(Action.upsertJobPostSuccess(payload.ref));
      yield put(
        Action.dismissJobPostModalAction({
          ref: payload.ref,
          formName: "jobPostModal",
        })
      );
      yield put(Action.showSuccessToast());

      // to copy link to clip board in response

      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchJobPostsAction({
          ref: "jobPostsList",
          jobPostsList: recruitmentReducer["jobPostsList"],
        })
      );

      // navigator.clipboard.writeText(response?.data?.upsert_job_post?.link);
    } else {
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.upsertJobPostFailed({
            ref: payload.ref,
            serverRef: "jobPostModalServerValidation",
            errors: HelperFns.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            ),
          })
        );
      } else {
        yield put(
          Action.upsertJobPostFailed({
            ref: payload.ref,
            serverRef: "jobPostModalServerValidation",
            errors: {},
            modalMessage:
              response?.errors[0]?.extensions?.reason ??
              response?.errors[0]?.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(
      Action.upsertJobPostFailed({
        ref: payload.ref,
        serverRef: "jobPostModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpsertJobPost() {
  yield takeLatest(Types.UPSERT_JOB_POST_ATTEMPT, handleUpsertJobPostSaga);
}

export function* handleFetchJobPostFormProcessesAndIntakeFormsOptionsSaga({
  payload,
}) {
  try {
    const response = yield call(
      Client.fetchJobPostFormIntakeFormsAndProcesses,
      payload
    );
    if (!response?.errors) {
      yield put(
        Action.fetchJobPostsProcessesAndIntakeFormsOptionsSuccess(
          response?.data?.processes_for_menu,
          response?.data?.intake_forms_for_menu
        )
      );
    } else {
      yield put(Action.fetchJobPostsProcessesAndIntakeFormsOptionsFailed());
      yield put(
        Action.showErrorToast(
          response.errors?.[0]?.extensions?.reason ||
            response.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchJobPostsProcessesAndIntakeFormsOptionsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchJobPostFormProcessesAndIntakeFormsOptions() {
  yield takeLatest(
    Types.FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS,
    handleFetchJobPostFormProcessesAndIntakeFormsOptionsSaga
  );
}

export function* handleFetchApplicantProfileSaga({ payload }) {
  try {
    const response = yield call(Client.fetchApplicantProfile, payload);
    if (!response?.errors) {
      yield put(Action.fetchApplicantProfileSuccess(response?.data?.applicant));
    } else {
      yield put(Action.fetchApplicantProfileFailed());
      yield put(
        Action.showErrorToast(
          response.errors?.[0]?.extensions?.reason ||
            response.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetchApplicantProfileFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchApplicantProfile() {
  yield takeLatest(
    Types.FETCH_APPLICANT_PROFILE_ATTEMPT,
    handleFetchApplicantProfileSaga
  );
}

export function* handleUpdateApplicantEmailSaga({ payload }) {
  try {
    const response = yield call(Client.updateApplicantEmail, payload.data);
    if (!response.errors && response.data?.edit_email?.status == "success") {
      yield put(Action.updateApplicantEmailSuccess(payload.ref));
      yield put(
        Action.dismissApplicantEmailModalAction({
          ref: payload.ref,
          formName: "editApplicantEmailModal",
        })
      );
      yield put(
        Action.showSuccessToast(response?.data?.edit_email?.message)
      );
      yield put(
        Action.fetchApplicantProfileAction({
          id: payload?.applicantId,
        })
      );
    } else {
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.updateApplicantEmailFailed({
            ref: payload.ref,
            serverRef: "editApplicantEmailModalServerValidation",
            errors: HelperFns.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            ),
          })
        );
      } else {
        yield put(
          Action.updateApplicantEmailFailed({
            ref: payload.ref,
            serverRef: "editApplicantEmailModalServerValidation",
            errors: {},
            modalMessage: response?.data?.edit_email?.message
              ? response.data?.edit_email?.message
              : response?.errors[0]?.extensions?.reason ??
                response?.errors[0]?.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(
      Action.updateApplicantEmailFailed({
        ref: payload.ref,
        serverRef: "editApplicantEmailModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpdateApplicantEmail() {
  yield takeLatest(
    Types.UPDATE_APPLICANT_EMAIL_ATTEMPT,
    handleUpdateApplicantEmailSaga
  );
}

export function* handleCreateApplicantPhoneNumberSaga({ payload }) {
  try {
    const response = yield call(Client.updateApplicantPhone, payload.data);
    if (
      !response.errors &&
      response.data?.edit_phone_number?.status == "success"
    ) {
      yield put(Action.createApplicantPhoneSuccess(payload.ref));
      yield put(
        Action.dismissAddApplicantPhoneModalAction({
          ref: payload.ref,
          formName: "addApplicantPhoneModal",
        })
      );
      yield put(
        Action.showSuccessToast(
          response?.data?.edit_phone_number?.message
        )
      );
      yield put(
        Action.fetchApplicantProfileAction({
          id: payload?.applicantId,
        })
      );
    } else {
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.createApplicantPhoneFailed({
            ref: payload.ref,
            serverRef: "addApplicantPhoneModalServerValidation",
            errors: HelperFns.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            ),
          })
        );
      } else {
        yield put(
          Action.createApplicantPhoneFailed({
            ref: payload.ref,
            serverRef: "addApplicantPhoneModalServerValidation",
            errors: {},
            modalMessage: response?.data?.edit_phone_number?.message
              ? response.data?.edit_phone_number?.message
              : response?.errors[0]?.extensions?.reason ??
                response?.errors[0]?.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(
      Action.createApplicantPhoneFailed({
        ref: payload.ref,
        serverRef: "addApplicantPhoneModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onCreateApplicantPhoneNumber() {
  yield takeLatest(
    Types.CREATE_APPLICANT_PHONE_ATTEMPT,
    handleCreateApplicantPhoneNumberSaga
  );
}

export function* handleUpdateApplicantPhoneNumberSaga({ payload }) {
  try {
    const response = yield call(Client.updateApplicantPhone, payload.data);
    if (
      !response.errors &&
      response.data?.edit_phone_number?.status == "success"
    ) {
      yield put(Action.updateApplicantPhoneSuccess(payload.ref));
      yield put(
        Action.dismissApplicantPhoneModalAction({
          ref: payload.ref,
          formName: "editApplicantPhoneModal",
        })
      );
      yield put(
        Action.showSuccessToast(
          response?.data?.edit_phone_number?.message
        )
      );
      yield put(
        Action.fetchApplicantProfileAction({
          id: payload?.applicantId,
        })
      );
    } else {
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.updateApplicantPhoneFailed({
            ref: payload.ref,
            serverRef: "editApplicantPhoneModalServerValidation",
            errors: HelperFns.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            ),
          })
        );
      } else {
        yield put(
          Action.updateApplicantPhoneFailed({
            ref: payload.ref,
            serverRef: "editApplicantPhoneModalServerValidation",
            errors: {},
            modalMessage: response?.data?.edit_phone_number?.message
              ? response.data?.edit_phone_number?.message
              : response?.errors[0]?.extensions?.reason ??
                response?.errors[0]?.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(
      Action.updateApplicantPhoneFailed({
        ref: payload.ref,
        serverRef: "editApplicantPhoneModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpdateApplicantPhoneNumber() {
  yield takeLatest(
    Types.UPDATE_APPLICANT_PHONE_ATTEMPT,
    handleUpdateApplicantPhoneNumberSaga
  );
}

export function* handleDeleteApplicantPhoneNumberSaga({ payload }) {
  try {
    const response = yield call(Client.deleteApplicantPhoneNumber, payload);
    if (
      !response.errors &&
      response.data?.delete_phone_number?.status == "success"
    ) {
      yield put(Action.deleteApplicantPhoneNumberSuccess());
      yield put(
        Action.showSuccessToast(
          response?.data?.delete_phone_number?.message
        )
      );
      yield put(
        Action.fetchApplicantProfileAction({
          id: payload?.applicant_id,
        })
      );
    } else {
      yield put(Action.deleteApplicantPhoneNumberFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_phone_number?.message ||
            response?.errors?.[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteApplicantPhoneNumberFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteApplicantPhoneNumber() {
  yield takeLatest(
    Types.DELETE_APPLICANT_PHONE_NUMBER_ATTEMPT,
    handleDeleteApplicantPhoneNumberSaga
  );
}
// update

// handle fetch IntakeForm for jobpost
export function* handleFetchJobPostIntakeFormSaga({ payload }) {
  try {
    const response = yield call(Client.fetchJobPostIntakeForm, payload);
    if (!response.errors) {
      yield put(Action.fetchIntakeFormSuccess(response.data?.job_post));
    } else {
      yield put(Action.fetchIntakeFormFailed());
      yield put(
        Action.showErrorToast(response.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(Action.fetchIntakeFormFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchJobPostIntakeForm() {
  yield takeLatest(
    Types.FETCH_INTAKE_FORM_ATTEMPT,
    handleFetchJobPostIntakeFormSaga
  );
}

export function* handleFillStageFormSaga({ payload, applicant_id }) {
  try {
    const response = yield call(Client.fillStageForm, payload);
    console.log("res", response.data?.fill_stage_data);
    if (
      !response.errors &&
      response.data?.fill_stage_data?.status === "sucess"
    ) {
      yield put(Action.fillStageDataSuccess(response.data?.fill_stage_data));
      yield put(Action.fetchApplicantProfileAction({ id: applicant_id }));
      yield put(
        Action.showSuccessToast(response?.data?.fill_stage_data?.message)
      );
    } else {
      yield put(
        Action.fillStageDataFailed(
          response?.errors[0]?.extensions?.validation ?? {}
        )
      );
    }
  } catch (error) {
    yield put(Action.fillStageDataFailed({}));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFillStageForm() {
  yield takeLatest(Types.FILL_STAGE_DATA_ATTEMPT, handleFillStageFormSaga);
}

export function* handleCloneRecruitmentProcessSaga({ payload }) {
  try {
    const response = yield call(Client.cloneRecruitmentProcess, payload);
    if (
      !response.errors &&
      response.data?.clone_process?.status === "success"
    ) {
      yield put(Action.cloneRecruitmentProcessesSuccess());
      yield put(
        Action.showSuccessToast(response?.data?.clone_process?.message)
      );
      let recruitmentReducer = yield select(getRecruitmentReducer);
      yield put(
        Action.fetchRecruitmentProcessesAction({
          ref: "recruitmentProcessesList",
          recruitmentProcessesList:
            recruitmentReducer["recruitmentProcessesList"],
        })
      );
    } else {
      yield put(Action.cloneRecruitmentProcessesFailed());
      yield put(
        Action.showErrorToast(
          response.data?.clone_process?.message ||
            response?.errors?.[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.cloneRecruitmentProcessesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onCloneRecruitmentProcess() {
  yield takeLatest(
    Types.CLONE_RECRUITMENT_PROCESSES_ATTEMPT,
    handleCloneRecruitmentProcessSaga
  );
}

export default {
  onFetchRecruitmentProcesses,
  onDeleteRecruitmentProcess,
  onUpdateRecruitmentProcessStatus,
  onFetchIntakeForms,
  onDeleteIntakeForm,
  onUpdateIntakeFormStatus,
  onFetchJobPosts,
  onDeleteJobPosts,
  onUpdateJobPostsStatus,
  onFetchApplications,
  onUpsertRecruitmentProcess,
  onInitRecruitmentProcessForm,
  onInitRecruitmentIntakeForm,
  onUpsertRecruitmentIntake,
  onUpdateApplicationStageStatus,
  onFetchApplicants,
  onDeleteApplicants,
  onUpsertApplication,
  onUpsertJobPost,
  onFetchJobPostFormProcessesAndIntakeFormsOptions,
  onFetchApplicantProfile,
  onUpdateApplicantEmail,
  onCreateApplicantPhoneNumber,
  onUpdateApplicantPhoneNumber,
  onDeleteApplicantPhoneNumber,
  onFetchJobPostIntakeForm,
  onFillStageForm,
  onCloneRecruitmentProcess,
  onCloneIntakeForm,
};
