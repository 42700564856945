export const dateFormat = "YYYY-MM-D";
export const dateTimeFormat = "YYYY-MM-D hh:mm:ss";
export const dateTimeListFormat = "DD-MM-YYYY hh:mm";

export const leadSourcesOptions = ["Facebook", "Linkedin", "Referral", "Others"];

export const leadStatusOptions = [
  "Interested",
  "Unreachable",
  "Not Interested",
  "Follow-Up",
  "Reviewing Proposal",
  "Trial",
  "Client",
  "Recycled",
  "Change Status",
];

export const subStatusOptions = {
  Interested: ["Highly Interested", "Semi-Interested", "Low interest"],
  Unreachable: [
    "First Call",
    "Second Call",
    "Third Call",
    "Fourth Call",
    "Fifth Call",
    "Sixth Call",
    "Reviewing details",
  ],
  "Not Interested": [
    "Misunderstood",
    "Issues with pricing",
    "Client is using another system",
    "System is not fulfilling client requirements",
    "Others",
  ],
  "Follow-Up": ["Requested By Client", "Busy at the moment"],
  "Reviewing Proposal": [
    "In Progress",
    "Accepted Proposal",
    "Rejected Proposal",
  ],
};

export const activityTypeOptions = [
  "Call",
  "Message",
  "Email",
  "Meeting",
  "Demo",
  "Proposal",
];

export const CRM_PRIVILEGES = {
  ACCESS_CRM: "213",

  // Lead
  VIEW_LEADS: "214",
  VIEW_OTHER_LEADS: "215",
  VIEW_UNASSIGNED_LEADS: "216",
  ASSIGN_LEADS: "217",
  ASSIGN_LEADS_TO_OTHER_AGENTS: "218",
  ADD_EDIT_LEADS: "219",
  DELETE_LEADS: "220",
  CAN_BE_ASSIGNED_LEADS: "221",
  VIEW_LEAD_PROFILE: "243",
  VIEW_LEAD_CONTACT: "244",

  // Activity
  VIEW_ACTIVITIES: "222",
  VIEW_ACTIVITIES_OF_ASSIGNED_LEAD: "223",
  VIEW_ACTIVITIES_OF_OTHER_AGENTS: "224",
  ADD_EDIT_ACTIVITIES: "225",
  DELETE_ACTIVITIES: "226",
  VIEW_COMMENTS_ON_ACTIVITIES: "227",
  COMMENT_ON_ACTIVITIES: "228",

  // Reminder
  VIEW_REMINDERS: "229",
  VIEW_REMINDERS_OF_ASSIGNED_LEADS: "230",
  ADD_EDIT_REMINDERS: "231",
  DELETE_REMINDERS: "232",
  SET_REMINDERS_AS_COMPLETED: "233",
  VIEW_COMMENTS_ON_REMINDERS: "234",
  COMMENT_ON_REMINDERS: "235",

  // Trial
  VIEW_TRIALS: "236",
  VIEW_TRIALS_OF_ASSIGNED_LEADS: "237",
  ADD_EDIT_TRIALS: "238",
  DELETE_TRIALS: "239",

  // Others
  VIEW_CRM_CALENDAR: "240",
  VIEW_CRM_DASHBOARD: "241",
  VIEW_CRM_REPORTS: "242",
};

export const crmSidebarPrivileges = [
  // CRM_PRIVILEGES.VIEW_CRM_DASHBOARD,
  CRM_PRIVILEGES.VIEW_CRM_CALENDAR,
  CRM_PRIVILEGES.VIEW_LEADS,
  CRM_PRIVILEGES.VIEW_ACTIVITIES,
  CRM_PRIVILEGES.VIEW_REMINDERS,
  CRM_PRIVILEGES.VIEW_TRIALS,
];

export const crmTabs = [
  {
    value: "dashboard",
    privileges: [CRM_PRIVILEGES.VIEW_CRM_DASHBOARD],
  },
  { value: "calendar", privileges: [CRM_PRIVILEGES.VIEW_CRM_CALENDAR] },
  {
    value: "leads",
    privileges: [CRM_PRIVILEGES.VIEW_LEADS],
  },
  {
    value: "activities",
    privileges: [CRM_PRIVILEGES.VIEW_ACTIVITIES],
  },
  {
    value: "reminders",
    privileges: [CRM_PRIVILEGES.VIEW_REMINDERS],
  },
  {
    value: "trials",
    privileges: [CRM_PRIVILEGES.VIEW_TRIALS],
  },
];
