import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Tab } from "@mui/material";
import YearlyPayroll from "./YearlyPayroll";
import PayrollView from "./PayrollView.jsx";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const tabs = [
  {
    label: "monthly",
    Panel: PayrollView,
    props: { isMonthly: true },
  },
  {
    label: "weekly",
    Panel: PayrollView,
    props: { isMonthly: false },
  },
];

const Payroll = () => {
  const { t } = useTranslation();

  // Local State
  const [value, setValue] = React.useState("0");

  /* ↓ Helpers ↓ */

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="content payroll_wrapper_style no-padding-first-child">
      <h2 className="page-title">{t("payroll report")}</h2>

      <TabContext value={value}>
        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {tabs.map(({ label }, i) => (
              <Tab key={label} label={t(label)} value={String(i)} />
            ))}
          </TabList>
        </Box>

        {/* Panels */}
        {tabs.map(({ Panel, props }, i) => (
          <TabPanel key={i} value={String(i)}>
            <Panel {...props} />
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};

export default Payroll;
