import React, { useEffect } from "react";
import MainModal from "../../Components/MainModal";
import PreviewRolePrivilegsTreeView from "./PreviewRolePrivilegsTreeView";
import { connect } from "react-redux";
import {
  dismissPreviewRolePrivilagesModalAction,
  fetchPreviewRolePrivilagesAction,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import { useTranslation } from "react-i18next";

const formName = "previewRolePrivilagesModal";

const PreviewRolePrivilagesModal = (props) => {
  const { t } = useTranslation();

  const toggleModal = () => {
    props.dismissPreviewRolePrivilagesModalAction({
      ref: "previewRolePrivilagesModalActions",
      formName,
    });
  };

  useEffect(() => {
    if (props?.isModalVissible) {
      props?.fetchPreviewRolePrivilagesAction(
        props?.previewRolePrivilagesModal?.planId,
        props?.previewRolePrivilagesModal?.roleId
      );
    }
  }, [props?.isModalVissible]);

  if (props?.isRolePrivilagesModalLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  return (
    <MainModal
      isOpen={props?.isModalVissible}
      toggle={toggleModal}
      modalTitle={t("preview role privileges")}
      className="manage_privilages_modal_wrapper_style no_footer_modal_style custom_disable_style"
    >
      <div>
        <PreviewRolePrivilegsTreeView
          parents={props?.previewRolePrivilagesModal?.system_privileges}
        />
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  isModalVissible: state.super.previewRolePrivilagesModalActions.isVissible,
  previewRolePrivilagesModal: state.super[formName],
  isRolePrivilagesModalLoading: state.super.isRolePrivilagesModalLoading,
});

export default connect(mapStateToProps, {
  dismissPreviewRolePrivilagesModalAction,
  fetchPreviewRolePrivilagesAction,
})(PreviewRolePrivilagesModal);
