import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";
import { withDependency } from "../../Helpers/HOC/withDependency";
import { ErrorOutline } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { onInputChangeAction, onInputResetAction } from "../../Store/Actions";

const InputTooltip = ({ title = "", tooltipStyle }) => {
  return (
    <span className={`${tooltipStyle ? tooltipStyle : "pl-2"}`}>
      <Tooltip placement="top" title={title}>
        <ErrorOutline fontSize="inherit" color="inherit" />
      </Tooltip>
    </span>
  );
};

const styless = new Proxy(
  {},
  {
    get: (target, propKey) => () => {},
  }
);

const defStyle = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    padding: 20,
  }),
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: 400,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const BCRSelect = ({
  isDisabled,
  isClearable,
  isRtl,
  isSearchable,
  isMulti,
  name,
  dependOn,
  label,
  labelStyle,
  keepDefaultLabel,
  formName,
  validateBy,
  formSubmitting,
  formNameValidation,
  containerStyle,
  keepDefaultContainer,
  inputContainerStyle,
  keepDefaultInputContainer,
  hasTooltip,
  tooltipTitle,
  tooltipStyle,
  options,
  SSR,
  endUrl,
  queryKey,
  minQuery = 3,
  optionLabel = "value",
  optionValue = "value",
  keepDefaultStyle,
  classNamePrefix,
  defaultStyle,
  errorStyle,
  keepDefaultError,
  optDependType,
  optDependKey,
  optDependValue,
  data,
  validateContainerStyle,
  validateMessage,
  rootStyle = "",
  formServerValidation,
  ...props
}) => {
  const inputval = data?.[formName]?.[name];
  const validate = useFormValidation(
    inputval,
    name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled
  );
  const isServerValid = !Boolean(data[formServerValidation])
    ? false
    : data[formServerValidation][name]
    ? true
    : false;
  const dependOptions = () => {
    switch (optDependType) {
      case "equal":
        return options?.filter((opt) =>
          optDependValue.includes(opt[optDependKey])
        );

      case "notEqual":
        return options?.filter(
          (opt) => !optDependValue.includes(opt[optDependKey])
        );

      case "greater":
        return options?.filter((opt) => opt[optDependKey] > optDependValue);
      case "less":
        return options?.filter((opt) => opt[optDependKey] < optDependValue);

      default:
        return options;
    }
  };
  const dispatch = useDispatch();
  const [allOptions, setAllOptions] = useState(dependOptions());

  useEffect(() => {
    setAllOptions(dependOptions());
    return () => {};
  }, [options]);

  const handleChange = (value, event) => {
    if (isMulti) {
      console.log(value, event);
      dispatch(
        onInputChangeAction(
          formName,
          event,
          "select",
          value ? value.map((val) => val[optionValue]) : []
        )
      );
    } else {
      console.log(value);
      dispatch(
        onInputChangeAction(
          formName,
          event,
          "select",
          value ? value[optionValue] : ""
        )
      );
    }
  };
  const createOption = (label) => ({
    [optionLabel]: label,
    [optionValue]: label.toLowerCase(),
  });

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    setAllOptions([...allOptions, newOption]);
    if (isMulti) {
      let val = inputval?.length
        ? [
            ...inputval?.map((select) => ({
              [optionValue]: select,
            })),
            newOption,
          ]
        : [newOption];
      let e = {
        name,
      };
      console.log(val);
      handleChange(val, e);
    } else {
      let e = {
        name,
      };
      handleChange(newOption, e);
    }
  };

  const vaildContainer = () => {
    if (!validate.validity || isServerValid) {
      return validateContainerStyle ?? "invalid-container-style";
    }
    return " ";
  };

  return (
    <div className={`${rootStyle} ${vaildContainer()}`}>
      <div
        className={`${keepDefaultContainer ? "select-def-container" : ""} ${
          containerStyle ? containerStyle : ""
        }`}
      >
        {label && (
          <label
            className={`${keepDefaultLabel ? "select-def-label" : ""} ${
              labelStyle ? labelStyle : ""
            }`}
          >
            {label}
            {hasTooltip && (
              <InputTooltip title={tooltipTitle} tooltipStyle={tooltipStyle} />
            )}
          </label>
        )}

        <CreatableSelect
          className={`${
            keepDefaultInputContainer ? "select-def-input-container" : ""
          } ${inputContainerStyle ? inputContainerStyle : ""}`}
          classNamePrefix={classNamePrefix}
          getOptionLabel={(option) => option[optionLabel]}
          getOptionValue={(option) => option[optionValue]}
          isDisabled={isDisabled}
          isClearable={isClearable}
          isRtl={isRtl}
          options={allOptions}
          value={
            isMulti
              ? allOptions?.filter((opt) =>
                  data?.[formName]?.[name]?.includes(opt[optionValue])
                )
              : allOptions?.filter(
                  (opt) => data?.[formName]?.[name] == opt[optionValue]
                )
          }
          isMulti={isMulti}
          styles={keepDefaultStyle ? "" : styless}
          name={name}
          onChange={handleChange}
          onCreateOption={handleCreate}
          {...props}
        />
      </div>
      <div className="validity-msg-style text-left">
        {validateMessage ? validateMessage : validate.validityMessage}
        {validate.validityMessage && <br />}
        {Boolean(data[formServerValidation])
          ? data[formServerValidation][name] ?? ""
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
  };
};

export default connect(mapStateToProps)(withDependency(BCRSelect));
