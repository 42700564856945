import React from "react";
import { useTranslation } from "react-i18next";

const ExpiredDashboardComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="manager-dashboard-alert-company-wizard-container d-flex justify-content-center align-items-center">
      <div className="dashboard-alert d-flex flex-column justify-content-center align-items-center">
        <span className="text-capitalize mb-0">
          {t(
            "to be able to view the Dashboard please contact the system admin to renew your subscription"
          )}
        </span>
      </div>
    </div>
  );
};

export default ExpiredDashboardComponent;
