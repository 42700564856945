import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import { formatOptions } from "../../Helpers/HelperFns";
import { changeLeadsStatusMutation } from "../../Graphql/mutation";
import { leadStatusOptions, subStatusOptions } from "../../Constants";

import MainModal from "../MainModal";
import { BSelect } from "form-builder";

const formNameValidation = "leadStatusClientValidation";

const LeadsStatusModal = ({
  data,
  onClose,
  onResetSelectedRows,
  refetchList,
}) => {
  const { t } = useTranslation();

  // Local State
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formData, setFormData] = React.useState({
    status: null,
    subStatus: null,
    leads: data?.leads || [],
  });

  // Reducer State
  const leadStatusClientValidation = useSelector(
    (state) => state.crm?.[formNameValidation]
  );

  // Server Status
  const [changeLeadsStatus, { loading }] = useCustomMutation(
    changeLeadsStatusMutation
  );

  /* ↓ Helpers ↓ */

  const handleSelectChange = (val, { name: key }) => {
    setFormData((prev) => ({ ...prev, [key]: val }));
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (leadStatusClientValidation.length) return;

    const { leads, status, subStatus } = formData;
    changeLeadsStatus({
      isShowSuccessModal: true,
      onCloseModal: onClose,
      variables: {
        leads: leads.map((l) => Number(l.id)),
        status: [status?.name, subStatus?.name],
      },
      onCompleted: () => {
        refetchList();
        onResetSelectedRows();
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      modalTitle="Change Status"
      btnSubmitLoading={loading}
    >
      <BSelect
        name="leads"
        label="leads"
        icon="employee"
        isMulti
        isDisabled
        keepDefaultStyle
        value={formData.leads}
        options={data?.leadsOptions || []}
        validateBy="textRequired"
        formSubmitting={formSubmitting}
        formNameValidation={formNameValidation}
      />
      <div className="d-flex gap-10">
        <BSelect
          name="status"
          icon="employee"
          label="lead status"
          keepDefaultStyle
          rootStyle="flex-fill"
          value={formData.status}
          onChange={handleSelectChange}
          options={formatOptions(leadStatusOptions)}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
        />
        {Object.keys(subStatusOptions).includes(formData.status?.name) ? (
          <BSelect
            name="subStatus"
            icon="employee"
            label="lead sub status"
            keepDefaultStyle
            rootStyle="flex-fill"
            onChange={handleSelectChange}
            value={formData.subStatus}
            options={formatOptions(subStatusOptions[formData.status.name])}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        ) : null}
      </div>
    </MainModal>
  );
};

export default LeadsStatusModal;
