import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import RecruitmentProcessesList from "../../Containers/RecruitmentProcessesList";
import JobPostsList from "../../Containers/JobPostsList";
import IntakeFormsList from "../../Containers/IntakeFormsList";
import ApplicantsList from "../../Containers/ApplicantsList";
import RecruitmentTabs from "./RecruitmentTabs";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ViewRecruitment = (props) => {
  const history = useHistory();
  let query = useQuery();
  const activeValue = query.get("tab");

  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_RECRUITMENT_PROCESSES],
        allowBP: true,
      })
    ) {
      return history.push(`/recruitment?tab=recruitment-process`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_JOB_POSTS],
        allowBP: true,
      })
    ) {
      return history.push(`/recruitment?tab=job-posts`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_INTAKE_FORMS],
        allowBP: true,
      })
    ) {
      return history.push(`/recruitment?tab=intake-forms`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_JOB_POST_APPLICANTS],
        allowBP: true,
      })
    ) {
      return history.push(`/recruitment?tab=applicants`);
    }

    return null;
  };

  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "recruitment-process":
        return (
          <HasPrivileges
            allowBP
            reqireMain={[Privilages.VIEW_RECRUITMENT_PROCESSES]}
          >
            <RecruitmentProcessesList />
          </HasPrivileges>
        );
      case "job-posts":
        return (
          <HasPrivileges allowBP reqireMain={[Privilages.VIEW_JOB_POSTS]}>
            <JobPostsList />
          </HasPrivileges>
        );
      case "intake-forms":
        return (
          <HasPrivileges allowBP reqireMain={[Privilages.VIEW_INTAKE_FORMS]}>
            <IntakeFormsList />
          </HasPrivileges>
        );
      case "applicants":
        return (
          <HasPrivileges
            allowBP
            reqireMain={[Privilages.VIEW_JOB_POST_APPLICANTS]}
          >
            <ApplicantsList />
          </HasPrivileges>
        );

      default:
        break;
    }
  };
  return (
    <div className="content pt-2">
      <RecruitmentTabs />
      <div>{renderSelectedTab()}</div>
    </div>
  );
};

export default ViewRecruitment;
