import gql from "graphql-tag";

import { leaveBreaksFragment, workTimeFragment } from "../fragments";

export const fetchSetupWizardlData = gql`
  query fetchSetupWizardEssentialInfo {
    company_offices(first: 1, page: 1) {
      paginatorInfo {
        count
      }
    }
    company_departments(first: 1, page: 1) {
      paginatorInfo {
        count
      }
    }
    company_positions(first: 1, page: 1) {
      paginatorInfo {
        count
      }
    }
    work_timings(
      first: 1
      page: 1
      input: { work_timing_profile_type: "normal" }
    ) {
      paginatorInfo {
        count
      }
    }
    company_leaves_breaks: PermissionLeavesBreakSettingsForMenu(
      first: 1
      page: 1
    ) {
      paginatorInfo {
        count
      }
    }
    company_attendance_profiles(first: 1, page: 1) {
      paginatorInfo {
        count
      }
    }
    workGroups(first: 1, page: 1) {
      paginatorInfo {
        count
      }
    }
  }
`;

export const wizardOfficeQuery = gql`
  query wizardOffice {
    offices(first: 1, page: 1) {
      data {
        id
      }
    }
  }
`;

export const wizardDepartmentsQuery = gql`
  query wizardDepartments {
    departments(first: 9999, page: 1) {
      data {
        id
        name
      }
    }
  }
`;

export const wizardPositionsQuery = gql`
  query wizardPositions {
    positions(first: 9999, page: 1) {
      data {
        id
        name
      }
    }
  }
`;

export const wizardWorkTimingQuery = gql`
  query wizardWorkTiming {
    workTimings: work_timings(
      page: 1
      first: 1
      input: { work_timing_profile_type: "normal" }
    ) {
      data {
        ...workTime
      }
    }
  }
  ${workTimeFragment}
`;

export const wizardLeaveBreaksQuery = gql`
  query wizardLeaveBreaks {
    leavesBreaks: PermissionLeavesBreakSettings(first: 1, page: 1) {
      data {
        ...leaveBreaks
      }
    }
  }
  ${leaveBreaksFragment}
`;

export const wizardAttProfileQuery = gql`
  query wizardAttProfile {
    attendanceProfiles: attendance_profiles(first: 1, page: 1) {
      data {
        id
      }
    }
  }
`;

export const wizardWorkGroupQuery = gql`
  query wizardWorkGroup {
    workGroups(first: 1, page: 1) {
      data {
        id
      }
    }
  }
`;
