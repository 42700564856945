import React from 'react';
import ManagerDashboard from '../../Containers/ManagerDashboard';

const ViewManagerDashboard = (props) => {
    return (

        <ManagerDashboard />

    )
}

export default ViewManagerDashboard;
