import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Companies from "../../Containers/Companies";
import ViewAdminUsers from "../../Routes/ViewAdminUsers";
import AdminPanelTabs from "./AdminPanelTabs";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import SystemPlansList from "../../Containers/SystemPlansList";
import AdminHolidaysList from "../../Containers/AdminHolidaysList";
import {
  Calendar,
  Dashboard,
  LeadsList,
  TrialsList,
  RemindersList,
  ActivitiesList,
} from "../../Containers/CRMLists";
import TaxesPage from "../../Containers/Taxes";
import Announcements from "../../Containers/Announcements";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ViewAdminPanel = (props) => {
  const history = useHistory();
  let query = useQuery();
  const activeValue = query.get("tab");

  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_COMPANIES, Privilages.SUPER_PRIVILEGE],
        allowBP: false,
      })
    ) {
      return history.push(`/admin-panel?tab=companies`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_ADMIN_USERS, Privilages.SUPER_PRIVILEGE],
        allowBP: false,
      })
    ) {
      return history.push(`/admin-panel?tab=admin-users`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_PLANS, Privilages.SUPER_PRIVILEGE],
        allowBP: false,
      })
    ) {
      return history.push(`/admin-panel?tab=plans`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.SUPER_PRIVILEGE,
          Privilages.VIEW_ADMIN_HOLIDAYS,
        ],
        allowBP: false,
      })
    ) {
      return history.push(`/admin-panel?tab=holidays`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.SUPER_PRIVILEGE,
          Privilages.VIEW_ADMIN_ANNOUNCEMENT,
        ],
        allowBP: false,
      })
    ) {
      return history.push(`/admin-panel?tab=announcements`);
    }

    // Dashboard
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_CRM_DASHBOARD],
        allowBP: false,
      })
    ) {
      return history.push(`/admin-panel?tab=Dashboard`);
    }
    // Calender
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.SUPER_PRIVILEGE, Privilages.VIEW_CRM_CALENDAR],
        allowBP: false,
      })
    ) {
      return history.push(`/admin-panel?tab=calender`);
    }
    // Lead
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.SUPER_PRIVILEGE, Privilages.VIEW_LEADS],
        allowBP: false,
      })
    ) {
      return history.push(`/admin-panel?tab=leads`);
    }

    // Activity
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.SUPER_PRIVILEGE, Privilages.VIEW_ACTIVITIES],
        allowBP: false,
      })
    ) {
      return history.push(`/admin-panel?tab=activities`);
    }

    // Reminder
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.SUPER_PRIVILEGE, Privilages.VIEW_REMINDERS],
        allowBP: false,
      })
    ) {
      return history.push(`/admin-panel?tab=reminders`);
    }

    // Trial
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.SUPER_PRIVILEGE, Privilages.VIEW_TRIALS],
        allowBP: false,
      })
    ) {
      return history.push(`/admin-panel?tab=trials`);
    }

    return null;
  };

  const handleChange = (event, newValue) => {
    history.push(`/admin-panel?tab=${newValue}`);
  };

  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "companies":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_COMPANIES, Privilages.SUPER_PRIVILEGE]}
          >
            <Companies />
          </HasPrivileges>
        );
      case "admin-users":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.VIEW_ADMIN_USERS,
              Privilages.SUPER_PRIVILEGE,
            ]}
          >
            <ViewAdminUsers />
          </HasPrivileges>
        );
      case "plans":
        return (
          <HasPrivileges
            reqireMain={[Privilages.SUPER_PRIVILEGE, Privilages.VIEW_PLANS]}
          >
            <SystemPlansList />
          </HasPrivileges>
        );
      case "holidays":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.VIEW_ADMIN_HOLIDAYS,
            ]}
          >
            <AdminHolidaysList />
          </HasPrivileges>
        );

      case "taxes":
        return <TaxesPage />;

      case "announcements":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.VIEW_ADMIN_ANNOUNCEMENT,
            ]}
          >
            <Announcements isAdminPanel />
          </HasPrivileges>
        );

      // CRM
      case "dashboard":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_CRM_DASHBOARD]}>
            <Dashboard />
          </HasPrivileges>
        );
      case "calendar":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.VIEW_CRM_CALENDAR,
            ]}
          >
            <Calendar />
          </HasPrivileges>
        );
      case "leads":
        return (
          <HasPrivileges
            reqireMain={[Privilages.SUPER_PRIVILEGE, Privilages.VIEW_LEADS]}
          >
            <LeadsList />
          </HasPrivileges>
        );
      case "activities":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.VIEW_ACTIVITIES,
            ]}
          >
            <ActivitiesList />
          </HasPrivileges>
        );
      case "reminders":
        return (
          <HasPrivileges
            reqireMain={[Privilages.SUPER_PRIVILEGE, Privilages.VIEW_REMINDERS]}
          >
            <RemindersList />
          </HasPrivileges>
        );
      case "trials":
        return (
          <HasPrivileges
            reqireMain={[Privilages.SUPER_PRIVILEGE, Privilages.VIEW_TRIALS]}
          >
            <TrialsList />
          </HasPrivileges>
        );

      default:
        break;
    }
  };
  return (
    <div className="content pt-3">
      <AdminPanelTabs
        activeValue={activeValue}
        handleChangeTab={handleChange}
      />
      <div>{renderSelectedTab()}</div>
    </div>
  );
};

export default ViewAdminPanel;
