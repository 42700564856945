import React, { useState } from "react";
import { connect } from "react-redux";
import {
  CheckboxBooleanForm,
  DateTimePickerForm,
  InputForm,
} from "form-builder";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { onInputResetAction } from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import { AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const AttSignInSection = (props) => {
  // const formName = `attendaceProfile`;
  // const formNameValidation = `attendaceProfileValidation`;
  // const { pathname } = useLocation();

  const { t } = useTranslation();

  const { formProps } = props;

  const [extraSettingExpand, setExtraSettingExpand] = useState(false)

  // let maxSignOut = moment(
  //     props.data[formProps.formName].sign_in_end_time,
  //     "HH:mm"
  //   )
  //     .add(props.data[formProps.formName].work_hours, "hours")
  //     .format("DD/MM/yyyy HH:mm"),
  //   minSignIn = moment(
  //     props.data[formProps.formName].sign_in_start_time,
  //     "HH:mm"
  //   ).format("DD/MM/yyyy HH:mm");

  // const renderEndOfDay = () => {
  //   if (
  //     // moment(maxSignOut, "DD/MM/yyyy").isAfter(moment(minSignIn, "DD/MM/yyyy"))
  //     true
  //   ) {
  //     return (
  //       <DateTimePickerForm
  //         timeOnly
  //         {...formProps}
  //         dependOn="sign_in_req"
  //         dependancyType="equal"
  //         dependancyValue={[1, "1"]}
  //         // dependTime="sign_in_end_time"
  //         // validateBy="end_time"
  //         label="Employee Cannot Sign Out After"
  //         name="end_of_day"
  //         validationName="input.end_of_day"
  //       />
  //     );
  //   } else {
  //     if (props.data[formProps.formName].end_of_day !== "00:00") {
  //       props.onInputResetAction(formProps?.formName, "end_of_day");
  //       return <></>;
  //     }
  //     return <></>;
  //   }
  // };

  return (
    <>
      <div className="">
        {/* <CheckboxBooleanForm
          {...formProps}
          options={["Employees Need to Sign In At The Start Of Each Work Day"]}
          name="sign_in_req"
          type="checkbox"
        /> */}
      </div>
      <div
        className={
          +props.data[formProps.formName].sign_in_req ? "mt-3" : "d-none"
        }
      >
        <h4 className="my-2 sub-title-style">{t("sign in")}</h4>

        <div className={""}>
          <DateTimePickerForm
            timeOnly
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            validateBy="textRequired"
            // dependTime="sign_in_end_time"
            label="Minimum Sign In Time"
            name="sign_in_start_time"
            validationName="input.sign_in_start_time"
          />

          <DateTimePickerForm
            timeOnly
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            validateBy="textRequired"
            // dependTime="sign_in_start_time"
            label="Maximum Sign In Time"
            name="sign_in_end_time"
            validationName="input.sign_in_end_time"
          />

          <InputForm
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            validateBy="textRequired"
            label="number of working hours"
            name="work_hours"
            validationName="input.work_hours"
            type="text"
            stepInput
            labelStyle=" "
            inputContainerStyle="m-start-auto"
          />

          {/* <div className="text-left">
              <span
                color="primary"
                role="button"
                onClick={toggle}
                style={{
                  color: "#23aaeb",
                  borderBottom: "1px solid #23aaeb",
                  padding: 2,
                  borderRadius: "5%",
                  fontWeight: "800",
                  fontSize: "12px",
                  display: +props.data[formProps.formName].sign_in_req
                    ? "inline-block"
                    : "none",
                }}
              >
                {t("Extra Settings")}
              </span>
            </div> */}

          <div>
            <DateTimePickerForm
              timeOnly
              {...formProps}
              dependOn="sign_in_req"
              dependancyType="equal"
              dependancyValue={[1]}
              validateBy="textRequired"
              // dependTime="sign_in_start_time"
              label="Employee can sign in as early as"
              name="time_allowed_before_sign_in"
              validationName="input.time_allowed_before_sign_in"
            />
          </div>

          {props.data[formProps.formName].sign_in_start_time && props.data[formProps.formName].time_allowed_before_sign_in && moment(
            props.data[formProps.formName].sign_in_start_time,
            "HH:mm"
          ).format("A") == "AM" && moment(
            props.data[formProps.formName].time_allowed_before_sign_in,
            "HH:mm"
          ).format("A") == "PM" ?

            <CheckboxBooleanForm
              {...formProps}
              validationName="input.consider_permissibility_hours"
              options={["consider permissibility hours"]}
              name="consider_permissibility_hours"
              type="checkbox"
              dependOn="sign_in_req"
              dependancyType="equal"
              dependancyValue={[1]}
              containerStyle="mt-2 mb-1"
              setWithValue

            />
            : null}
          <DateTimePickerForm
            timeOnly
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1, "1"]}
            // dependTime="sign_in_end_time"
            // validateBy="end_time"
            label="Employee Cannot Sign Out After"
            name="end_of_day"
            validationName="input.end_of_day"
          />

          <CheckboxBooleanForm
            {...formProps}
            validationName="input.calc_sign_out_time_from_actual_sign_in"
            options={["calculate sign out time from actual sign in"]}
            name="calc_sign_out_time_from_actual_sign_in"
            type="checkbox"
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            containerStyle="mt-2 mb-1"
          />
        </div>
      </div>

      <Accordion
        expanded={extraSettingExpand}
        onChange={() => setExtraSettingExpand(!extraSettingExpand)}
        classes={{
          root: "accordion-none"
        }}
      >
        <AccordionSummary
          classes={{
            root: "p-0 m-0"
          }}
        >
          <span className="font-13">
            {t("Extra Settings")}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <DateTimePickerForm
            timeOnly
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            label="cannot sign in after"
            name="can_not_sign_in_after"
            validationName="input.can_not_sign_in_after"
            isClearable
          />
          <DateTimePickerForm
            timeOnly
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            label="cannot sign out before"
            name="can_not_sign_out_before"
            validationName="input.can_not_sign_out_before"
            isClearable
          />
        </AccordionDetails>
      </Accordion>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
  };
};

export default connect(mapStateToProps, { onInputResetAction })(
  AttSignInSection
);
