import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import SalaryConfiguration from "./SalaryConfiguration";
import ViewSalaryConfigModal from "./ViewSalaryConfigModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Loader from "../../Components/Loader";
import {
  fetchEmployeeSalaryConfigAction,
  initSalaryConfig,
} from "../../Store/Actions";
import { useParams } from "react-router-dom";
import UpsertSalaryConfigModal from "./UpsertSalaryConfigModal";
import { useTranslation } from "react-i18next";

const SalaryConfigTab = (props) => {
  const { t } = useTranslation();
  const { userId } = useParams();

  const userCountryId = useSelector(
    (state) => state?.super?.selectedEmployeeProfile?.office?.country?.id
  );

  const handleAddNewSalaryConfig = () => {
    props.initSalaryConfig(props?.data?.office?.id, true, null, userCountryId);
  };

  useEffect(() => {
    props.fetchEmployeeSalaryConfigAction({
      id: userId,
    });
    return () => {};
  }, []);

  // for show salary config modal and use modal component one time
  const [ShowSalaryConfigModal, setShowSalaryConfigModal] = useState(false);

  const [salaryDetails, setSalaryDetails] = useState(null);

  const toggleShowSalaryConfigModal = (salaryDetails) => {
    setShowSalaryConfigModal(true);
    setSalaryDetails(salaryDetails);
  };

  const toggleHideSalaryConfigModal = () => {
    setShowSalaryConfigModal(false);
    setSalaryDetails(null);
  };

  if (props.isLoading) {
    return <Loader />;
  }

  return (
    <div className="employee-form">
      {props?.isDeleteSalaryConfigLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
        <div></div>

        <HasPrivileges
          reqireMain={[Privilages.ADD_EMPLOYEE_SALARY_CONFIGURATION]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <button
              className="btn add_new_btn_style mt-3 mt-md-0 mx-4 mx-lg-0"
              type="button"
              disabled
            >
              {t("Add New Configuration")}
            </button>
          }
        >
          <button
            className="btn add_new_btn_style mt-3 mt-md-0 mx-4 mx-lg-0"
            type="button"
            onClick={handleAddNewSalaryConfig}
            disabled={props?.data?.active == "0"}
            title={props?.data?.active == "0" ? "employee is suspended" : null}
          >
            {t("Add New Configuration")}
          </button>
        </HasPrivileges>
      </div>

      <>
        {!props?.data?.userSalaryConfigs?.length ? (
          <div className="mt-5 w-100 text-center">No Data Available ...</div>
        ) : (
          <div className="mt-4 mx-4 mx-lg-0">
            {props?.data?.userSalaryConfigs?.map((salary) => (
              <SalaryConfiguration
                key={salary?.id}
                salaryConfig={salary}
                office_id={props.data?.office?.id}
                handleShowSalaryConfigModal={toggleShowSalaryConfigModal}
                userId={userId}
              />
            ))}
          </div>
        )}
      </>

      <ViewSalaryConfigModal
        {...salaryDetails}
        modal_isOpen={ShowSalaryConfigModal}
        modal_toggle={toggleHideSalaryConfigModal}
      />
      <UpsertSalaryConfigModal />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.super?.employeeSalaryConfigList,
  isLoading: state.super?.employeeSalaryConfigLoading,
  isDeleteSalaryConfigLoading: state?.super?.deleteSalaryConfigLoading,
});

export default connect(mapStateToProps, {
  fetchEmployeeSalaryConfigAction,
  initSalaryConfig,
})(SalaryConfigTab);
