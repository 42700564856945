import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import useCustomQuery from "../../Helpers/Hooks/useCustomQuery";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import {
  subStatusOptions,
  leadStatusOptions,
  dateTimeListFormat,
  leadSourcesOptions,
} from "../../Constants/CRM";
import {
  removeLeadMutation,
  assignLeadsMutation,
} from "../../Graphql/mutation";
import HelperFns, {
  formatOptions,
  openSwalConfirm,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import moment from "moment";
import Privilages from "../../Constants/Privilages";
import { leadsListQuery } from "../../Graphql/query";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
} from "form-builder";
import {
  LeadModal,
  ActivityModal,
  ReminderModal,
  LeadsStatusModal,
  AssignLeadsModal,
} from "../../Components/CRMFormModals";
import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import Box from "@mui/material/Box";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import ReactShowMoreText from "react-show-more-text";
import { AddButton } from "../../Components/Buttons";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { ContactTooltip, LeadNameCell } from "../../Components/CRM";
import { PersonAdd, Event, NotificationAdd } from "@mui/icons-material";

const modalInitState = { isOpen: false, data: null };
const modals = [
  { name: "lead", Modal: LeadModal },
  { name: "activity", Modal: ActivityModal },
  { name: "reminder", Modal: ReminderModal },
  { name: "assignLead", Modal: AssignLeadsModal },
  { name: "leadsStatus", Modal: LeadsStatusModal },
];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const LeadsList = () => {
  const { t } = useTranslation();
  const isTabletMatch = useMediaQuery("(max-width: 767px)");

  // Local State
  const [leads, setLeads] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isClearSelectedRows, setIsClearSelectedRows] = useState(false);
  const [options, setOptions] = useState({ users: [], countries: [] });
  const [modalsState, setModalsState] = useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );
  const [pagination, setPagination] = useState(paginationInitState);
  const [filters, setFilters] = useState({
    to: "",
    from: "",
    name: "",
    source: "",
    status: "",
    type: "All",
    subStatus: "",
    country: null,
    assigned_to: null,
    orderBy: undefined,
    last_activity_at: "",
  });

  // Server State
  const [assign] = useCustomMutation(assignLeadsMutation);
  const [removeLead] = useCustomMutation(removeLeadMutation);
  const { refetch: refetchList, loading: isListLoading } = useCustomQuery(
    leadsListQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...formatSendingInputs(filters),
        name: filters.name,
        orderBy: filters?.orderBy,
        first: pagination.perPage,
        page: pagination.currentPage,
        isOptionsSkip: Object.values(options).every((l) => l.length),
        status: filters.status?.id
          ? [filters.status?.id, filters.subStatus?.id].filter((f) =>
              Boolean(f)
            )
          : undefined,
      },
      onCompleted: ({ leads, users, countries }) => {
        // Data
        setLeads(leads?.data || []);
        setPagination(leads?.paginatorInfo || paginationInitState);

        // Options
        !Object.values(options).every((l) => l.length) &&
          setOptions({
            users: users?.data || [],
            countries: formatOptions(countries),
          });
      },
    }
  );

  // Reducer State
  const user_id = useSelector((state) => state.user.userProfile.id);

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    setSelectedRows([]);
    setIsClearSelectedRows(true);
    setTimeout(() => {
      setIsClearSelectedRows(false);
    }, 1);
  }, [pagination.currentPage, JSON.stringify(filters)]);

  React.useEffect(() => {
    setFilters((prev) => ({ ...prev, subStatus: "" }));
  }, [filters.status?.id]);

  /* ↓ Helpers ↓ */

  const handleSelectRows = ({ selectedRows = [] }) => {
    setSelectedRows(selectedRows.map((r) => ({ id: r?.id, name: r?.name })));
  };

  const handleUnselectRows = (unselectedIDs) => {
    setSelectedRows((prev) => prev.filter((id) => !unselectedIDs.includes(id)));
    setIsClearSelectedRows(true);
    setTimeout(() => {
      setIsClearSelectedRows(false);
    }, 1);
  };

  const handleResetSelectedRows = () => {
    setSelectedRows([]);
    setIsClearSelectedRows(true);
  };

  const handleSort = (row, order) => {
    setFilters((prev) => ({
      ...prev,
      orderBy: [{ column: row?.sortField, order: order.toUpperCase() }],
    }));
  };

  const handleOpenModal = (name, data = null) => {
    const modalState = { name, data, isOpen: true };

    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? modalState : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handleFilters = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleRemoveLead = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeLead({
          refetchList,
          isShowErrorModal: true,
          variables: { id: data.id },
        });
      }
    });
  };

  // Constants
  const columns = [
    {
      cell: (data) => (
        <div>
          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_LEADS]}>
            <EditIconButton
              onClick={() => handleOpenModal("lead", { id: data.id })}
            />
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_ACTIVITIES]}>
            <IconButtonWithTooltip
              label="add activity"
              icon={<Event fontSize="small" className="text-primary" />}
              onClick={() =>
                handleOpenModal("activity", {
                  id: data?.id,
                  name: data?.name,
                  contacts: data?.contacts,
                })
              }
            />
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_REMINDERS]}>
            <IconButtonWithTooltip
              label="add reminder"
              icon={<NotificationAdd fontSize="small" className="blue-color" />}
              onClick={() =>
                handleOpenModal("reminder", {
                  id: data.id,
                  name: data.name,
                  contacts: data?.contacts,
                })
              }
            />
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.DELETE_LEADS]}>
            <RemoveIconButton onClick={() => handleRemoveLead(data)} />
          </HasPrivileges>
        </div>
      ),
    },
    { name: t("country"), selector: "country" },
    {
      name: t("contacts"),
      cell: ({ contacts = [] }) => <div>{contacts.map(ContactTooltip)}</div>,
    },
    {
      name: t("name"),
      sortable: true,
      sortField: "name",
      cell: (lead) => <LeadNameCell lead={lead} />,
    },
    {
      name: t("added on"),
      sortable: true,
      sortField: "created_at",
      cell: ({ date }) => moment(date).format(dateTimeListFormat),
    },
    { name: t("employees"), selector: "eps_no" },
    { name: t("industry"), cell: ({ industry }) => industry },
    { name: t("source"), selector: "source" },
    {
      name: t("assigned to"),
      cell: (data) =>
        data?.assignedTo?.name || (
          <HasPrivileges
            reqireMain={[
              Privilages.ASSIGN_LEADS,
              Privilages.ASSIGN_LEADS_TO_OTHER_AGENTS,
            ]}
          >
            <div className="px-4">
              <IconButtonWithTooltip
                label="assign"
                icon={<PersonAdd fontSize="small" className="blue-color" />}
                onClick={() =>
                  !HelperFns.checkPrivileges({
                    privileges: [Privilages.ASSIGN_LEADS_TO_OTHER_AGENTS],
                  })
                    ? openSwalConfirm(
                        (isConfirmed) =>
                          isConfirmed &&
                          assign({
                            refetchList,
                            variables: { lead_ids: [data.id], user_id },
                          }),
                        {
                          text: "You are going to assign this lead to yourself.",
                        }
                      )
                    : handleOpenModal("assignLead", {
                        id: data.id,
                        name: data.name,
                      })
                }
              />
            </div>
          </HasPrivileges>
        ),
    },
    {
      name: t("notes"),
      cell: ({ notes }) => (
        <ReactShowMoreText lines={1}>{notes}</ReactShowMoreText>
      ),
    },
    {
      name: t("last activity date"),
      sortable: true,
      sortField: "latestActivityAt",
      cell: ({ latestActivity = {} }) =>
        latestActivity
          ? moment(latestActivity?.date).format(dateTimeListFormat)
          : null,
    },
    {
      name: t("status"),
      cell: ({ latestActivity = {} }) =>
        latestActivity ? (
          <div>
            <strong className="d-block">
              {JSON.parse(latestActivity?.status)[0]}
            </strong>
            {JSON.parse(latestActivity?.status)[1]}
          </div>
        ) : (
          <strong>Fresh Lead</strong>
        ),
    },
  ];

  return (
    <>
      {/* Filters */}
      <div className="my-4">
        <InputForm
          name="name"
          icon="search"
          value={filters.name}
          inputStyle="border-0"
          onChange={handleFilters}
          placeholder={t("Search")}
          inputContainerStyle="w-100"
          rootStyle="boxContainer shadow-sm flex-fill px-2 m-0"
        />
        <Box
          className="align-items-end gap-20 mt-3"
          sx={{
            display: "grid",
            gridTemplateColumns: `repeat(${isTabletMatch ? "2" : "3"}, 1fr)`,
          }}
        >
          <BSelect
            isClearable
            icon="city"
            name="country"
            label="country"
            placeholder={t("select country")}
            value={filters.country}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            isLoading={!options.countries.length}
            options={options?.countries}
            containerStyle=" "
          />
          <BSelect
            isClearable
            name="status"
            label="status"
            icon="document"
            placeholder={t("select status")}
            value={filters.status}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            options={formatOptions(["Fresh Lead", ...leadStatusOptions])}
            containerStyle=" "
          />
          <BSelect
            isClearable
            name="subStatus"
            label="sub status"
            icon="document"
            placeholder={t("select sub status")}
            value={filters.subStatus}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            options={formatOptions(subStatusOptions[filters.status?.id])}
            containerStyle=" "
          />
          <BSelect
            isClearable
            icon="employee"
            name="assigned_to"
            label="assigned to"
            placeholder={t("assigned to")}
            value={filters.assigned_to}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            isLoading={!options.users.length}
            options={options?.users}
            containerStyle=" "
          />
          <DateTimePickerForm
            hasIcon
            isClearable
            label="from"
            labelStyle="w-100"
            value={filters.from}
            rootStyle="flex-fill"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("from", val)}
          />
          <DateTimePickerForm
            hasIcon
            label="to"
            isClearable
            labelStyle="w-100"
            value={filters.to}
            rootStyle="flex-fill"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("to", val)}
          />
          <BSelect
            isClearable
            icon="employee"
            name="source"
            label="source"
            placeholder={t("source")}
            value={filters.source}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            isLoading={!options.users.length}
            options={formatOptions(leadSourcesOptions)}
            containerStyle=" "
          />
          <div
            style={{ gridColumn: "-3/-1" }}
            className={`d-flex gap-20 align-items-center justify-content-${
              isTabletMatch ? "between" : "end"
            }`}
          >
            <HasPrivileges
              reqireMain={[
                Privilages.VIEW_OTHER_LEADS,
                Privilages.VIEW_UNASSIGNED_LEADS,
              ]}
            >
              <RadioboxForm
                name="type"
                value={filters.type}
                options={[
                  {
                    label: "Assigned",
                    value: "Assigned",
                    optProps: { onChange: handleFilters },
                  },
                  {
                    label: "Unassigned",
                    value: "Unassigned",
                    optProps: { onChange: handleFilters },
                  },
                  {
                    label: "all",
                    value: "All",
                    optProps: { onChange: handleFilters },
                  },
                ]}
                optionInputStyle=" "
                containerStyle="mb-0"
                optionItemStyle="d-inline-block mx-2"
                optionsContainerStyle="d-flex"
              />
            </HasPrivileges>
            <div className="d-flex gap-10 justify-content-end">
              {selectedRows.length ? (
                <HasPrivileges reqireMain={[Privilages.ASSIGN_LEADS_TO_OTHER_AGENTS]}>
                  <AddButton
                    prefix=""
                    label="Change Status"
                    icon={<PriorityHighIcon fontSize="small" />}
                    onClick={() =>
                      handleOpenModal("leadsStatus", {
                        leads: selectedRows,
                        leadsOptions: leads,
                      })
                    }
                  />
                </HasPrivileges>
              ) : null}
              <HasPrivileges
                reqireMain={[Privilages.ASSIGN_LEADS_TO_OTHER_AGENTS]}
              >
                <AddButton
                  prefix="assign"
                  label="leads"
                  icon={<PersonAdd fontSize="small" />}
                  onClick={() => handleOpenModal("assignLead")}
                />
              </HasPrivileges>
              <HasPrivileges reqireMain={[Privilages.ADD_EDIT_LEADS]}>
                <AddButton
                  label="lead"
                  onClick={() => handleOpenModal("lead")}
                />
              </HasPrivileges>
            </div>
          </div>
        </Box>
      </div>

      {/* List */}
      <DataTable
        noHeader
        sortServer
        data={leads}
        selectableRows
        columns={columns}
        onSort={handleSort}
        className="cards_table"
        progressComponent={<Loader />}
        progressPending={isListLoading}
        clearSelectedRows={isClearSelectedRows}
        onSelectedRowsChange={handleSelectRows}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            isLeadList
            refetchList={refetchList}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
            {...(name === "assignLead" && { handleUnselectRows, selectedRows })}
            {...(name === "leadsStatus" && {
              onResetSelectedRows: handleResetSelectedRows,
            })}
          />
        ) : null
      )}
    </>
  );
};

export default LeadsList;
