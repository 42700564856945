import React, { useCallback, useEffect, useState } from "react";
import { BSelect } from "form-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone-uploader";
import { useTranslation } from "react-i18next";
import {
  downloadEmployeeTemplateAttempt,
  setIsEmployeeImportModalOpen,
  showSuccessToast,
  showErrorToast,
  uploadBulkEmployeesCsvAttempt,
  uploadBulkEmployeesCsvFailed,
  uploadBulkEmployeesCsvSuccess,
} from "../../Store/Actions";
import { connect, useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import MainModal from "../MainModal";
import { useParams } from "react-router-dom";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import HelperFns from "../../Helpers/HelperFns";
import Switch from "@mui/material/Switch";

const dowmloadShiftBaseTempQuery = gql`
  query ExportFile($companyId: ID) {
    export_excel_file_template_shift_based(input: { company_id: $companyId }) {
      status
      file
    }
  }
`;

const uploadShiftBaseTempQuery = gql`
  query shiftBasedExcelImport($file: Upload, $companyId: ID) {
    import_excel_shift_file_template(company_id: $companyId, file: $file) {
      status
      messages {
        row
        name
        error_data {
          column
          value
          message
        }
      }
    }
  }
`;

const AddBulkEmployeesModalContent = (props) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(undefined);
  const dispatch = useDispatch();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [isShiftBased, setIsShiftBased] = useState(false);

  const { companyId } = useParams();

  const [downloadShiftTemplate, { loading, data, error }] = useLazyQuery(
    dowmloadShiftBaseTempQuery,
    {
      onCompleted: (data) => {
        if (
          data?.export_excel_file_template_shift_based?.status === "success"
        ) {
          HelperFns.downloadFile(
            data?.export_excel_file_template_shift_based?.file
          );
        }
      },
    }
  );

  const [
    uploadShiftTemplate,
    { loading: uploadLoading, data: uploadData, error: uploadError },
  ] = useLazyQuery(uploadShiftBaseTempQuery, {
    onCompleted: (data) => {
      console.log(data);
      if (data?.import_excel_shift_file_template?.status === "success") {
        dispatch(uploadBulkEmployeesCsvSuccess());
        dispatch(showSuccessToast());
        resetFile();
      } else {
        dispatch(
          uploadBulkEmployeesCsvFailed(
            data?.import_excel_shift_file_template?.messages
          )
        );
      }
    },
    onError: (error) => {
      dispatch(
        showErrorToast(
          error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
        )
      );
    },
  });
  useEffect(() => {
    setFile(undefined);
    setIsErrorVisible(false);
  }, [props.isBulkImportEmployeesOpen]);
  const handleImageChange = ({ file, meta }, status) => {
    if (status === "done") {
      setFile(file);
      setIsErrorVisible(false);
    }
  };
  const regulations = [
    { id: 5, name: "Mawared Custom" },
    { id: 2, name: "Test regulation" },
  ];
  const handleDownloadTemplete = () => {
    if (isShiftBased) {
      downloadShiftTemplate({
        variables: {
          companyId,
        },
      });
    } else {
      props.downloadEmployeeTemplateAttempt(companyId);
    }
  };

  const resetFile = () => {
    setFile(undefined);
  };

  const handleUploadEmployeesCsv = () => {
    if (file) {
      if (isShiftBased) {
        uploadShiftTemplate({
          variables: {
            file,
            companyId,
          },
        });
      } else {
        props.uploadBulkEmployeesCsvAttempt({
          file,
          company_id: companyId,
          resetFile,
        });
      }
    } else {
      setIsErrorVisible(true);
    }
  };

  const dismissModal = () => {
    props.setIsEmployeeImportModalOpen(false);
  };

  return (
    <MainModal
      toggle={dismissModal}
      btnOnClick={handleUploadEmployeesCsv}
      btnOnCancelClick={dismissModal}
      btnLabel="import"
      btnLabelCancel="Cancel"
      isOpen={props.isBulkImportEmployeesOpen}
      modalTitle="import employees to the system"
      className="import_employees-modal-style"
      btnSubmitLoading={props.uploadEmployeesCsvLoading || uploadLoading}
    >
      <div className="d-flex flex-column justify-content-start align-items-start px-1 w-100 documents_dropzone_style">
        {/* <BSelect
        name="regulation_type"
        formName="companyRegulations"
        options={regulations}
        optionLabel="name"
        optionValue="id"
        validateBy="textRequired"
        containerStyle="w-185 mb-2"
        label="Choose Salary Regulation"
        labelStyle="font-13 mb-2 import-employee-select-style"
      /> */}
        <h6>{t("template")}</h6>
        <span className="font-13 discreption">
          {t(
            "for a successful import, please make sure you are using this template when uploading your data"
          )}
        </span>

        <fieldset className="mb-2">
          <label className="mx-2">{t("shift based")}</label>
          <Switch
            checked={isShiftBased}
            onChange={(e) => setIsShiftBased(e.target.checked)}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </fieldset>

        {isShiftBased ? (
          <buton
            onClick={handleDownloadTemplete}
            className="employee-upload-btn-style d-flex justify-content-center align-items-center mb-4"
          >
            {loading ? (
              <Spinner
                style={{
                  width: "1rem",
                  height: "1rem",
                  color: "#fff",
                }}
              />
            ) : (
              <>
                <FontAwesomeIcon icon={faFileDownload} />
                <span>{t("download template")}</span>
              </>
            )}
          </buton>
        ) : (
          <buton
            onClick={handleDownloadTemplete}
            className="employee-upload-btn-style d-flex justify-content-center align-items-center mb-4"
          >
            {props.bulkImportEmployeesLoading ? (
              <Spinner
                style={{
                  width: "1rem",
                  height: "1rem",
                  color: "#fff",
                }}
              />
            ) : (
              <>
                <FontAwesomeIcon icon={faFileDownload} />
                <span>{t("download template")}</span>
              </>
            )}
          </buton>
        )}

        <h6>{t("upload file")}</h6>
        <Dropzone
          onChangeStatus={handleImageChange}
          canRemove
          accept=".csv,.xlsx,.xls"
          name="attachments"
          maxFiles="1"
          inputContent={t("drag files here or browse")}
          addClassNames={{
            dropzone: "upload-file-container ",
            inputLabel: "drop-zone-label",
          }}
        />
        {isErrorVisible ? (
          <span className="text-danger font-11">
            {t("please upload a valid excel file")}
          </span>
        ) : null}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    bulkImportEmployeesLoading: state?.super?.bulkImportEmployeesLoading,
    uploadEmployeesCsvLoading: state?.super?.uploadEmployeesCsvLoading,
    isBulkImportEmployeesOpen: state?.super?.isBulkImportEmployeesOpen,
  };
};

export default connect(mapStateToProps, {
  downloadEmployeeTemplateAttempt,
  setIsEmployeeImportModalOpen,
  uploadBulkEmployeesCsvAttempt,
})(AddBulkEmployeesModalContent);
