import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { Section, Unit } from "./SectionBase";

const SignInOutSettingsSection = (props) => {
  const { t } = useTranslation();

  return (
    <Section title="Sign In/Out Settings">
      <div className="d-flex gap-10 flex-column flex-lg-row justify-content-between font-13">
        <Section subSection title="Sign In" className="flex-1">
          <Unit
            colStyles="justify-content-between"
            label="Send A Sign In Reminder"
            val={
              props?.sign_in_reminder ? (
                moment(props?.sign_in_reminder_time, "HH:mm").format("hh:mm A")
              ) : (
                <span className="red-color">{t("No")}</span>
              )
            }
          />
          <Unit
            isBoolVal
            boolVal={props?.face_rekognition}
            colStyles="justify-content-between"
            label="Apply Sign In with Facial Recognition"
          />
          {/* <Unit
            isBoolVal
            boolVal={props?.apply_facial_challenge_to_sign_in}
            colStyles="justify-content-between"
            label="Apply Liveness Detection with Facial Recognition"
          /> */}
          <Unit
            isBoolVal
            boolVal={props?.home_face_rekognition}
            colStyles="justify-content-between"
            label="Apply Facial Recognition On Employees working from home"
          />
          <Unit
            isBoolVal
            boolVal={props?.remote_sign_in}
            colStyles="justify-content-between"
            label="Allow Employee To Sign In From Any Location"
          />
          <Unit
            isBoolVal
            boolVal={props?.sign_in_from_office_ips}
            colStyles="justify-content-between"
            label="Allow Sign In From Office IP Address"
          />
          <Unit
            isBoolVal
            boolVal={props?.sign_in_from_office_geolocation}
            colStyles="justify-content-between"
            label="Allow Sign In From Office Location"
          />
          <Unit
            isBoolVal
            boolVal={props?.allow_locations_custom_override}
            colStyles="justify-content-between"
            label="Allow Custom Locations In Employee Profile"
          />
          <Unit
            isBoolVal
            boolVal={props?.allow_sign_in_outside_specified_locations}
            colStyles="justify-content-between"
            label="Allow Sign In Outside Specified Locations"
          />
        </Section>

        <Section subSection title="Sign Out" className="flex-1">
          <Unit
            colStyles="justify-content-between"
            label="Send A Sign Out Reminder"
            val={
              props?.sign_out_reminder ? (
                moment(props?.sign_out_reminder_time, "HH:mm").format("hh:mm A")
              ) : (
                <span className="red-color">{t("No")}</span>
              )
            }
          />
          <Unit
            isBoolVal
            boolVal={props?.same_policies}
            colStyles="justify-content-between"
            style={{ height: 50, lineHeight: 1.7 }}
            label="Apply Sign Out Same as Sign In"
          />
          <Unit
            isBoolVal
            boolVal={props?.remote_sign_out}
            colStyles="justify-content-between"
            label="Allow Employee To Sign Out From Any Location"
          />
          <Unit
            isBoolVal
            boolVal={props?.sign_out_from_office_ips}
            colStyles="justify-content-between"
            label="Allow Sign Out From Office IP Address"
          />
          <Unit
            isBoolVal
            boolVal={props?.sign_out_from_office_geolocation}
            colStyles="justify-content-between"
            label="Allow Sign Out From Office Location"
          />
          <Unit
            isBoolVal
            boolVal={props?.sign_out_locations_custom_override}
            colStyles="justify-content-between"
            label="Allow Sign Out Custom Locations In Employee Profile"
          />
          <Unit
            isBoolVal
            boolVal={props?.allow_sign_out_outside_specified_locations}
            colStyles="justify-content-between"
            label="Allow Sign out Outside Specified Locations"
          />
        </Section>
      </div>
    </Section>
  );
};

export default SignInOutSettingsSection;
