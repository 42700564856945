import React from "react";
import { Edit } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { showMainsModalWithData } from "../../Store/Actions";
import { useDispatch } from "react-redux";
import HelperFns from "../../Helpers/HelperFns";
import { useTranslation } from "react-i18next";

const Documents = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    containerClass = "col-12 col-lg-6",
    editAction,
    document,
    user_document,
    userId,
    handleshowEditDocumentModal,
    handleshowAddDocumentsModal,
    document_files,
    page_flag,
  } = props;

  const handleEditButton = () => {
    if (editAction === "upsert") {
      // Show Upsert Modal
      handleshowEditDocumentModal(
        document?.id,
        document?.fields,
        JSON.parse(user_document?.data),
        user_document?.files,
        user_document?.id,
        userId,
        document?.name,
        document?.country
      );
    } else {
      // Show History Modal
      dispatch(
        showMainsModalWithData({
          ref: "historyDocumentsModalActions",
          formName: "historyDocumentsModal",
          data: {
            employee_id: userId,
            document_id: document?.id,
            document_fields: document?.fields,
            document_name: document?.name,
            country: document?.country,
            document_files: user_document?.files,
          },
        })
      );
    }
  };

  const handleAddButton = () => {
    handleshowAddDocumentsModal(
      document?.id,
      document?.fields,
      userId,
      document?.name,
      document?.country
    );
  };

  return (
    <div className={containerClass}>
      <div className="row align-items-center card-container my-1 px-4">
        <div className="col-9 d-flex flex-wrap align-items-center">
          {/* (Start) Document Name */}
          <h4 className="col documents-name py-2 mb-0 text-nowrap">
            {HelperFns.getCountryShortName({
              name: document?.name,
              country_id: document?.country?.id,
              country: document?.country,
            })}
          </h4>
          {/* (End) Document Name */}

          {/* (Start) Document Date */}
          <div className="col px-0 d-flex align-items-center flex-column flex-md-row text-nowrap">
            <div className={`${user_document?.isExpired ? "error-color" : ""}`}>
              {user_document && JSON.parse(user_document?.data)[1] ? (
                <>
                  <span
                    className={`${
                      user_document?.isExpired ? "error-color" : ""
                    } px-2 mb-0`}
                  >
                    {t("Exp.Date")}
                  </span>
                  <span className="documents-date">
                    {JSON.parse(user_document?.data)[1]}
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* (End) Document Date */}
        </div>

        <div className="col-3 px-0 d-flex justify-content-end">
          {/* (Start) Document Files */}
          {document_files?.length ? (
            <div className="d-flex btns-style mx-1">
              {document_files?.slice(0, 3)?.map((attachement) => (
                <a
                  role={"button"}
                  href={attachement?.path}
                  target="_blank"
                  className="attachment_label_style mx-1"
                >
                  <FontAwesomeIcon
                    className="edit-icon-style md-icon"
                    icon={faFileDownload}
                  />
                </a>
              ))}
            </div>
          ) : null}
          {/* (End) Document Files */}

          {/* (Start) Document Action */}
          <div className="btns-style">
            <HasPrivileges
              reqireMain={
                page_flag === "company_profile"
                  ? [Privilages.ADD_EDIT_NEW_DOCUMENTS]
                  : [Privilages.MANAGE_EMPLOYEE_DOCUMENTS]
              }
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={
                user_document ? (
                  <Edit className="edit-icon-style lg-icon" />
                ) : (
                  <FontAwesomeIcon className="add-icon-style" icon={faPlus} />
                )
              }
            >
              {user_document ? (
                <Edit
                  className="edit-icon-style lg-icon"
                  onClick={handleEditButton}
                />
              ) : (
                <FontAwesomeIcon
                  onClick={handleAddButton}
                  className="add-icon-style"
                  icon={faPlus}
                />
              )}
            </HasPrivileges>
          </div>
          {/* (End) Document Action */}
        </div>
      </div>
    </div>
  );
};

export default Documents;
