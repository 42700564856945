import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import ExportModal from "../../Components/ExportModal";
import PayslipModal from "../../Components/PayslipModal";
import {
  fetchMyPayrollReportAction,
  fetchUserPaySlipAction,
} from "../../Store/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import moment from "moment";
import { useTranslation } from "react-i18next";

const MyPayrollTab = (props) => {
  const dataTableRef = "myPayroll";
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchMyPayrollReportAction({
      ref: dataTableRef,
    });
  }, []);

  const [ShowExportModal, setShowExportModal] = useState(false);
  const toggleShowExportModal = () => setShowExportModal(!ShowExportModal);

  // for show payslip modal and use modal component one time
  const [ShowPayslipModal, setShowPayslipModal] = useState(false);
  const [payslipDetails, setPayslipDetails] = useState(null);

  const toggleShowPayslipModal = (payslipDetails) => {
    setShowPayslipModal(true);
    setPayslipDetails(payslipDetails);

    props.fetchUserPaySlipAction({
      user_id: payslipDetails?.user?.id,
      month_year_payroll: payslipDetails?.month_year_payroll,
    });
  };

  const toggleHidePayslipModal = () => {
    setShowPayslipModal(false);
    setPayslipDetails(null);
  };

  function roundToTwo(num) {
    // return +(Math.round(num + "e+2") + "e-2");
    return num.toFixed(2);
  }

  const TableCell = ({ configType, symbol, value }) =>
    configType != "external" ? (
      <div className="d-flex align-items-center">
        <div className="pr-1">{symbol}</div>
        <p className="mb-0">{!!value ? roundToTwo(Math.abs(value)) : "0.00"}</p>
      </div>
    ) : (
      <div></div>
    );

  const externalTableStyle = {
    backgroundColor: "#fff",
    // borderRightColor: "#fff !important",
    borderLeftColor: "#fff !important",
  };

  const groupColumns = [
    {
      name: "",
      grow: 3.07,
    },
    {
      name: <div className="extra_main_title_style mx-auto">{t("Extra")}</div>,
      grow: 4,
      center: true,
    },
    {
      name: (
        <div className="deductions_main_title_style">{t("Deductions")}</div>
      ),
      grow: 5.48,
      center: true,
    },
    {
      name: "",
      grow: 1.24,
    },
  ];

  const columns = [
    {
      name: "Month",
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 3,
      style: {
        backgroundColor: "#fff",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: {
            borderRight: "1px solid #d2d2d2;",
          },
        },
      ],
      cell: (row) => (
        <div className="mr-2">
          {moment(row?.month_year_payroll?.replace("-", "-01-")).format(
            "MMMM, YYYY"
          )}
        </div>
      ),
    },
    {
      name: "base",
      wrap: true,
      selector: "base_salary",
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(35, 170, 235, 0.14)",
      },
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.base_salary == 0,
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
      ],
      cell: (row) => (
        <TableCell
          configType={row?.config_type}
          symbol={row?.currency?.symbol}
          value={row?.base_salary}
        />
      ),
    },
    {
      name: "allowances",
      wrap: true,
      selector: "allowances_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.allowances_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
      ],
      cell: (row) => (
        <TableCell
          configType={row?.config_type}
          symbol={row?.currency?.symbol}
          value={row?.allowances_amount}
        />
      ),
    },
    {
      name: "bonuses",
      wrap: true,
      selector: "bonuses_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.bonuses_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
      ],
      cell: (row) => (
        <TableCell
          configType={row?.config_type}
          symbol={row?.currency?.symbol}
          value={row?.bonuses_amount}
        />
      ),
    },
    {
      name: "overtime",
      wrap: true,
      selector: "overtime_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.overtime_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
      ],
      cell: (row) => (
        <TableCell
          configType={row?.config_type}
          symbol={row?.currency?.symbol}
          value={row?.overtime_amount}
        />
      ),
    },
    {
      name: "claims",
      wrap: true,
      selector: "claims_amount",
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18)",
      },
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.claims_amount == 0,
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: {
            ...externalTableStyle,
            // padding: "5px 2px 5px 36px !important",
          },
        },
      ],
      cell: (row) =>
        row?.config_type == "external" ? (
          <div className="w-100 text-right font-weight-bold managed-style">
            Managed
          </div>
        ) : (
          <TableCell
            configType={row?.config_type}
            symbol={row?.currency?.symbol}
            value={row?.claims_amount}
          />
        ),
    },
    {
      name: "penalties",
      wrap: true,
      selector: "penalties_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.penalties_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: {
            ...externalTableStyle,
            // padding: "5px 36px 5px 2px !important",
          },
        },
      ],
      cell: (row) =>
        row?.config_type == "external" ? (
          <div className="w-100 text-left font-weight-bold external-style">
            Externally
          </div>
        ) : (
          <TableCell
            configType={row?.config_type}
            symbol={row?.currency?.symbol}
            value={row?.penalties_amount}
          />
        ),
    },
    {
      name: "social",
      wrap: true,
      selector: "social_amount",
      sortable: false,
      grow: 1.3,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.social_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.social_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <TableCell
          configType={row?.config_type}
          symbol={row?.currency?.symbol}
          value={row?.social_amount}
        />
      ),
    },
    {
      name: "medical",
      wrap: true,
      selector: "medical_amount",
      sortable: false,
      grow: 1.3,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.medical_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.medical_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <TableCell
          configType={row?.config_type}
          symbol={row?.currency?.symbol}
          value={row?.medical_amount}
        />
      ),
    },
    {
      name: "taxes",
      wrap: true,
      selector: "taxes_amount",
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.taxes_amount == 0,
      sortable: false,
      grow: 1.3,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
        {
          when: (row) => row?.taxes_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <TableCell
          configType={row?.config_type}
          symbol={row?.currency?.symbol}
          value={row?.taxes_amount}
        />
      ),
    },
    {
      name: "others",
      wrap: true,
      selector: "all_other_deductibles_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.all_other_deductibles_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08)",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
      ],
      cell: (row) => (
        <TableCell
          configType={row?.config_type}
          symbol={row?.currency?.symbol}
          value={row?.all_other_deductibles_amount}
        />
      ),
    },
    {
      name: "payable",
      wrap: true,
      selector: "payable_amount",
      sortable: false,
      grow: 1.4,
      // omit:
      //   props?.data[dataTableRef]?.currency_id &&
      //   props?.extraPayrollRow?.payable_amount == 0,
      style: {
        backgroundColor: "#f8f9fa",
      },
      conditionalCellStyles: [
        {
          when: (row) => row?.config_type != "internal",
          style: externalTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex w-100 align-items-center justify-content-between">
          <TableCell
            configType={row?.config_type}
            symbol={row?.currency?.symbol}
            value={row?.payable_amount}
          />

          {row?.config_type != "external" && (
            <HasPrivileges reqireMain={[Privilages.VIEW_PAYSLIP]} allowBP>
              <div className="data-table-action">
                <span
                  className="payslip_icon_style btn p-0"
                  onClick={() => toggleShowPayslipModal(row)}
                >
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                </span>
              </div>
            </HasPrivileges>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="content payroll_wrapper_style">
      <DataTable
        className="requests-profile-data-list payroll_main_datatable_wrapper_style"
        columns={groupColumns}
        data={[]}
        noHeader
        noTableHead={
          !props.data[dataTableRef].data?.length ||
          props.data[dataTableRef].isLoading
        }
        persistTableHead
        customStyles={{
          noData: "d-flex flex-column",
        }}
        noDataComponent={
          <DataTable
            noDataComponent={<div className="p-4"> {t("no_records")} </div>}
            className="requests-profile-data-list payroll_datatable_wrapper_style"
            columns={columns}
            noTableHead={
              !props.data[dataTableRef].data?.length ||
              props.data[dataTableRef].isLoading
            }
            data={props.data[dataTableRef].data}
            // selectableRowDisabled={true}
            noHeader
            persistTableHead
            sortServer
            defaultSortAsc={props.data[dataTableRef].sorting.dir == "asc"}
            defaultSortField={props.data[dataTableRef].sorting.key}
            progressPending={props.data[dataTableRef].isLoading}
            progressComponent={<Loader />}
          />
        }
      />

      {/* Start of Export Modal */}
      <ExportModal
        modal_isOpen={ShowExportModal}
        modal_toggle={toggleShowExportModal}
        dataTableRef={dataTableRef}
      />
      {/* End of Export Modal  */}

      {/* Start of Payslip Modal */}
      <PayslipModal
        {...payslipDetails}
        modal_isOpen={ShowPayslipModal}
        modal_toggle={toggleHidePayslipModal}
      />
      {/* End of Payslip Modal  */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.user,
});

export default connect(mapStateToProps, {
  fetchMyPayrollReportAction,
  fetchUserPaySlipAction,
})(MyPayrollTab);
