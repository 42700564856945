import React from "react";
import { connect } from "react-redux";
import { CheckboxBooleanForm } from "form-builder";
import MainModal from "../../Components/MainModal";
import {
  exportEmployeeListAction,
  dismissExportEmployeeListModal,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = "employeeListExportData";

const ExportModal = (props) => {
  const { t } = useTranslation();

  const toggleModal = () => {
    props?.dismissExportEmployeeListModal({
      ref: "exportEmployeeListModal",
    });
  };

  const handleSubmitBtn = () => {
    props?.exportEmployeeListAction({
      ref: "exportEmployeeListModal",
      exportColumns: props?.employeeListColumnsExport,
      filterOptions: {
        column: "name",
        order: "ASC",
        name: props?.allEmployeesListData?.filter,
        status: props?.allEmployeesListData?.suspendFilter,
        working_status: props?.allEmployeesListData?.statusFilter,
        from_home: props?.allEmployeesListData?.from_home,
        on_break: props?.allEmployeesListData?.on_break,
        department_id: +props?.allEmployeesListData?.filterByDepartment,
        manager_id: props?.allEmployeesListData?.filterByManager,
        is_managed_filter: props?.allEmployeesListData?.showManagedEmployeesFilter,
      },
    });
  };

  return (
    <MainModal
      isOpen={props?.isModalVissible}
      toggle={toggleModal}
      modalTitle={t("Export Employee List")}
      btnLabel={t("export")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={props?.isLoading}
    >
      <div className="row">
        <div className="col-12">
          {/* (Start) phone */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("Phone Number")]}
            name="phone"
            type="checkbox"
          />
          {/* (End) phone */}
          {/* (Start) emp code */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("employee code")]}
            name="emp_code"
            type="checkbox"
          />
          {/* (End) emp code */}

          {/* (Start) Email */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("Email")]}
            name="email"
            type="checkbox"
          />
          {/* (End) Email */}

          {/* (Start) Office */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("office")]}
            name="office"
            type="checkbox"
          />
          {/* (End) Office */}

          {/* (Start) Position */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("position")]}
            name="position"
            type="checkbox"
          />
          {/* (End) Position */}

          {/* (Start) Department */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("department")]}
            name="department"
            type="checkbox"
          />
          {/* (End) Department */}

          {/* (Start) Attendance Profile */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("attendance profile")]}
            name="attendance_profile"
            type="checkbox"
          />
          {/* (End) Attendance Profile */}

          {/* (Start) Managers */}
          <CheckboxBooleanForm
            formName={formName}
            options={["Managers"]}
            name="managers"
            type="checkbox"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="managers"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["direct manager"]}
              name="direct_manager"
              type="checkbox"
              resetValue={0}
            />
            <CheckboxBooleanForm
              dependOn="managers"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["copied manager"]}
              name="copied_managers"
              type="checkbox"
              resetValue={0}
            />
          </div>
          {/* (End) Managers */}

          {/* (Start) Joining Date */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("joining date")]}
            name="joining_date"
            type="checkbox"
          />
          {/* (End) Joining Date */}

          {/* (Start) Leaves */}
          <CheckboxBooleanForm
            formName={formName}
            options={["leaves"]}
            name="leaves"
            type="checkbox"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["normal"]}
              name="normal_leaves"
              type="checkbox"
              resetValue={0}
            />
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["emergency"]}
              name="emergency_leaves"
              type="checkbox"
              resetValue={0}
            />
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["sick"]}
              name="sick_leaves"
              type="checkbox"
              resetValue={0}
            />
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["permissions"]}
              name="permissions"
              type="checkbox"
              resetValue={0}
            />
          </div>
          {/* (End) Leaves */}

          {/* (Start) On Probation */}
          <CheckboxBooleanForm
            formName={formName}
            options={[t("Only show employees on probation")]}
            name="on_probation"
            type="checkbox"
          />
          {/* (End) On Probation */}
        </div>
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.super.exportEmployeeListModal?.isLoading,
    employeeListColumnsExport: state.super.employeeListExportData,
    allEmployeesListData: state.super.allEmployeesList,
    isModalVissible: state.super.exportEmployeeListModal.isVissible,
  };
};

export default connect(mapStateToProps, {
  exportEmployeeListAction,
  dismissExportEmployeeListModal,
})(ExportModal);
