import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { connect } from "react-redux";
import {
  dismissManagePrivilagesModalAction,
  upsertAdminUserPrivilegesAction,
} from "../../Store/Actions";
import TreeView from "../../Builder/TreeView";
import Loader from "../../Components/Loader";
import { useTranslation } from "react-i18next";

const formName = "managePrivilagesModal";
const formNameValidation = "managePrivilagesModalValidation";

const EditAdminPrivilagesModal = (props) => {
  const { t } = useTranslation();

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    props.upsertAdminUserPrivilegesAction({
      user_id: props.managePrivilagesModal.id,
      main: props.managePrivilagesModal.main,
      all: props.managePrivilagesModal.all,
      managed: props.managePrivilagesModal.managed,
    });
    console.log(props.managePrivilagesModal);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;
  const toggleModal = () => {
    props.dismissManagePrivilagesModalAction({
      ref: "managePrivilagesModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  if (props?.isLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }
  return (
    <MainModal
      isOpen={isModalVissible}
      btnLabelCancel="Cancel"
      btnOnCancelClick={toggleModal}
      modalTitle={`${t("Management Privileges")} - ${
        props.managePrivilagesModal.name
      }`}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="manage_privilages_modal_wrapper_style"
    >
      <div>
        {props.managePrivilagesModal.id && (
          <TreeView
            parents={JSON.parse(
              props?.managePrivilagesModal?.system_privileges
            )}
            adminPriviages
          />
        )}

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.managePrivilagesModalActions,
  modalValidation: state.super[formNameValidation],
  managePrivilagesModal: state.super[formName],
  isLoading: state.super.editPrivileges,
});

export default connect(mapStateToProps, {
  dismissManagePrivilagesModalAction,
  upsertAdminUserPrivilegesAction,
})(EditAdminPrivilagesModal);
