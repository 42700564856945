import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

function RTL(props) {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}

const MuiProvider = ({ children }) => {
  const lng = useSelector((state) => state.auth.userProfile.lng);
  const isRTL = lng?.includes("ar");

  const DirectionProvider = isRTL ? RTL : React.Fragment;
  const theme = useMemo(
    () =>
      createTheme({
        direction: isRTL ? "rtl" : "ltr",
        components: {
          MuiButton: {
            defaultProps: { disableElevation: true, disableRipple: true },
          },
        },
      }),
    [isRTL]
  );

  return (
    <DirectionProvider>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </DirectionProvider>
  );
};

export default MuiProvider;
