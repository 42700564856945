import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";

import { wizardPositionsQuery } from "../../Graphql/query";
import { createPositionsAttempt } from "../../Store/Actions";
import { deletePositionMutation } from "../../Graphql/mutation";
import { openSwalConfirm, showToast } from "../../Helpers/HelperFns";

import { Spinner } from "reactstrap";
import Loader from "../../Components/Loader";
import { PersonOutline } from "@mui/icons-material";
import { AddButton } from "../../Components/Buttons";
import { TimesIconButton } from "../../Components/IconButtonWithTooltip";

const SetupWizardPositions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [localPos, setLocalPos] = useState([{ id: null, name: "" }]);

  // Reducer State
  const setupWizardPositions = useSelector(
    (state) => state.wizard.setupWizardEssentialInfo["company_positions"]
  );
  const setupWizardLoading = useSelector(
    (state) => state.wizard.setupWizardLoading
  );

  // Server State
  const [getPositions] = useLazyQuery(wizardPositionsQuery);
  const [deletePosition] = useMutation(deletePositionMutation);

  /* ↓ State Effects ↓ */

  useEffect(() => {
    getPositions({
      onCompleted: ({ positions }) => {
        positions?.data?.length && setLocalPos(positions?.data);
        setIsLoading(false);
      },
      onError: (err) => {
        setIsLoading(false);
        showToast("error", err?.message);
      },
    });
  }, []);

  /* ↓ Helpers ↓ */

  const checkClientsideValidity = () => {
    const newErrors = [];
    localPos?.forEach((dep, index) => {
      if (!dep.name || dep.name === "") {
        newErrors.push(index);
      }
    });
    return newErrors;
  };

  const handleSubmit = (e) => {
    const newErrors = checkClientsideValidity();
    if (newErrors?.length > 0) {
      setErrors(newErrors);
      e && e.preventDefault();
      return;
    } else {
      e && e.preventDefault();
      dispatch(createPositionsAttempt(localPos));
    }
  };

  const handleAddInput = () => {
    const modifiedArray = [...localPos];
    modifiedArray.push({ id: null, name: "" });
    setLocalPos(modifiedArray);
  };

  const handleInputChange = (index, e) => {
    let modfiedArray = [...localPos];
    modfiedArray[index].name = e.target.value;
    setLocalPos(modfiedArray);
    const filteredErrors = errors.filter((error) => error !== index);
    setErrors(filteredErrors);
  };

  const handleDeleteInput = (index, id) => {
    const modifiedArray = [...localPos];
    setLocalPos(localPos.filter((_, i) => i !== index));

    if (setupWizardPositions.paginatorInfo?.count && id) {
      openSwalConfirm((willDelete) => {
        willDelete
          ? deletePosition({
              variables: { id },
              onError: (err) => {
                setLocalPos(modifiedArray);
                showToast("error", err?.message);
              },
            })
          : setLocalPos(modifiedArray);
      });
    }
  };

  return (
    <section className="setup-wizard-step">
      <h2 className="setup-wizard-title mb-4">{t("create your positions")}</h2>

      <div className="d-flex gap-10">
        <h3 className="sub_title_style">
          {t("positions")}
          <hr />
        </h3>
        <AddButton
          isIconOnly
          onClick={handleAddInput}
          sx={{ bgcolor: "#27b40c", "&:hover": { bgcolor: "#27b40c" } }}
        />
      </div>

      <div className="card-box container-fluid mb-4 ">
        {isLoading ? (
          <Loader inner />
        ) : (
          <>
            {localPos?.map((pos, index) => {
              var hasError = errors.includes(index);
              return (
                <div
                  key={index}
                  className={`d-flex gap-10 align-items-${
                    hasError ? "center" : "end"
                  }`}
                >
                  <div className="col px-0">
                    <div className={hasError ? "invalid-container-style" : ""}>
                      <div className={index === 0 ? "mt-0" : "mt-2"}>
                        <label className={"mb-1 validity-label-style"}>
                          {t("position name")}
                        </label>
                        <div className={"my-0 input-container-style-default"}>
                          <div className={`input_icon`}>
                            <PersonOutline />
                          </div>
                          <input
                            className="input-style-default validity-input-style pl-3"
                            name="name"
                            placeholder={t("name")}
                            value={pos.name}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        </div>
                        <div className="validity-msg-style">
                          {hasError ? t("validation.filled") : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  {index !== 0 && (
                    <TimesIconButton
                      onClick={() => handleDeleteInput(index, pos.id)}
                    />
                  )}
                </div>
              );
            })}
            <div className="text-right">
              <button
                className="btn btn-primary submit_btn_style ml-auto mt-3"
                onClick={handleSubmit}
              >
                {setupWizardLoading ? (
                  <Spinner
                    style={{ width: "1rem", height: "1rem", color: "#fff" }}
                  />
                ) : (
                  t("save")
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default SetupWizardPositions;
