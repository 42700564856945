import Types from "./types";
import Helper from "../../Helpers/HelperFns";
export * from "./companyWizardActions/CompanyWizardActions";
export * from "./requestRuleActions";
export * from "./adminActions/actions";
export * from "./recruitmentActions/actions";
export * from "./checkInsActions/actions";
export * from "./CRMActions/actions";
export * from "./Feedback/actions";

//handle  pusher  events  actions
export const changeEmployeeData = (data) => {
  return {
    type: Types.CHANGE_EMPLOYEE_DATA,
    payload: {
      data,
    },
  };
};

export const updateEmployeeBreakStatus = (data) => {
  return {
    type: Types.PUSHER_EMPLOYEE_BREAK_IN_OUT,
    payload: {
      data,
    },
  };
};
// Start Handle Login Actions
export const loginAction = (user) => {
  return {
    type: Types.LOGING_IN_ATTEMPT,
    payload: {
      user,
    },
  };
};

export const loginSuccess = (token, user) => {
  return {
    type: Types.LOGED_IN_SUCCESS,
    payload: { token, user },
  };
};

export const loginFailed = (err) => {
  return {
    type: Types.LOGED_IN_FAILED,
    payload: err,
  };
};

// Start Handle Fetch Profile Actions

export const fetchProfileSuccess = (user) => {
  return {
    type: Types.FETCH_PROFILE_SUCCESS,
    payload: { user },
  };
};

export const setOfficeCurrency = (user) => {
  return {
    type: Types.SET_OFFICE_CURRENCY,
    payload: { user },
  };
};

// Start Handle Fetch Dashboard Actions

export const fetchDashboardViewAction = ({ ...rest }) => {
  return {
    type: Types.FETCH_DASHBOARD_ATTEMPT,
    payload: {
      ...rest,
    },
  };
};

export const fetchAttendanceChartAction = (data) => {
  return {
    type: Types.FETCH_ATTENDANCE_CHART_ATTEMPT,
    payload: {
      ...data,
      ref: "attendanceChart",
    },
  };
};

export const fetchAttendanceChartSuccess = (data) => {
  return {
    type: Types.FETCH_ATTENDANCE_CHART_SUCCESS,
    payload: { data, ref: "attendanceChart" },
  };
};

export const fetchAttendanceChartFailed = () => {
  return {
    type: Types.FETCH_ATTENDANCE_CHART_FAILED,
  };
};

// Start of fetch Employees Name on Click actions
export const fetchEmployeeNameOnClickAction = (date, status, office_id = null ) => {
  return {
    type: Types.FETCH_EMPLOYEE_NAME_ON_CLICK_ATTEMPT,
    payload: { date, status, office_id },
  };
};

export const fetchEmployeeNameOnClickSuccess = (data) => {
  return {
    type: Types.FETCH_EMPLOYEE_NAME_ON_CLICK_SUCCESS,
    payload: data,
  };
};

export const fetchEmployeeNameOnClickFailed = () => {
  return {
    type: Types.FETCH_EMPLOYEE_NAME_ON_CLICK_FAILED,
  };
};
// End of fetch Employees Name on Click actions

// Start of show/hide Employees Name modal actions
export const showAttChartEmployeeNameModalAction = (data) => {
  return {
    type: Types.SHOW_ATT_CHART_EMPLOYEES_NAME_MODAL,
    payload: data,
  };
};

export const dismissAttChartEmployeeNameModalAction = (data) => {
  return {
    type: Types.HIDE_ATT_CHART_EMPLOYEES_NAME_MODAL,
    payload: data,
  };
};
// End of show/hide Employees Name modal actions

export const fetchDepartmentsChartAction = (data) => {
  return {
    type: Types.FETCH_DEPARTMENTS_CHART_ATTEMPT,
    payload: {
      ...data,
      ref: "departmentsChart",
    },
  };
};

export const fetchDepartmentsChartSuccess = (data) => {
  return {
    type: Types.FETCH_DEPARTMENTS_CHART_SUCCESS,
    payload: { data, ref: "departmentsChart" },
  };
};

export const fetchDepartmentsChartFailed = () => {
  return {
    type: Types.FETCH_DEPARTMENTS_CHART_FAILED,
  };
};

export const fetchOvertimeChartAction = (data) => {
  return {
    type: Types.FETCH_OVERTIME_CHART_ATTEMPT,
    payload: {
      ...data,
      ref: "overtimeChart",
    },
  };
};

export const fetchOvertimeChartSuccess = (data) => {
  return {
    type: Types.FETCH_OVERTIME_CHART_SUCCESS,
    payload: { data, ref: "overtimeChart" },
  };
};

export const fetchOvertimeChartFailed = () => {
  return {
    type: Types.FETCH_OVERTIME_CHART_FAILED,
    payload: { ref: "overtimeChart" },
  };
};

export const fetchDashboardWidgetsSuccess = (data) => {
  return {
    type: Types.FETCH_DASHBOARD_WIDGETS_SUCCESS,
    payload: { ref: "dashboardWidgets", data },
  };
};

export const cancelRequestAction = (
  id,
  pageFlag = "",
  end_date = "",
  employeeId = null,
  refetchQueries
) => {
  return {
    type: Types.CANCEL_REQUEST_ATTEMPT,
    payload: { id, pageFlag, end_date, employeeId, refetchQueries },
  };
};

export const cancelRequestSuccess = (id) => {
  return {
    type: Types.CANCEL_REQUEST_SUCCESS,
    payload: { id },
  };
};

export const cancelRequestFailed = (id) => {
  return {
    type: Types.CANCEL_REQUEST_FAILED,
    payload: { id },
  };
};

// Start of show/hide edit leaves modal actions
export const showEditLeavesModalAction = (data) => {
  return {
    type: Types.SHOW_EDIT_LEAVES_MODAL,
    payload: data,
  };
};

export const dismissEditLeavesModalAction = (data) => {
  return {
    type: Types.HIDE_EDIT_LEAVES_MODAL,
    payload: data,
  };
};
// End of show/hide edit leaves modal actions

// handle Fetch User History
export const fetchAttendanceRecordsAction = (filter) => {
  return {
    type: Types.FETCH_ATTENDANCE_RECORDS_ATTEMPT,
    payload: filter,
  };
};
export const fetchAttendanceRecordsSuccess = (data) => {
  return {
    type: Types.FETCH_ATTENDANCE_RECORDS_SUCCESS,
    payload: data,
  };
};

export const fetchAttendanceRecordsFailed = () => {
  return {
    type: Types.FETCH_ATTENDANCE_RECORDS_FAILED,
  };
};

export const assignAttendanceHalfProfile = (half, id) => {
  return {
    type: Types.ASSIGN_HLAF_ATTENDANCE_PROFILE,
    payload: {
      half,
      id,
    },
  };
};

// HAMNDLE INPUTS CHANGE
export const onInputChangeAction = (formName, event, type = "", value = "") => {
  switch (type) {
    case "radio":
      return {
        type: Types.ON_RADIO_INPUT_CHANGE,
        payload: {
          formName,
          event,
          type,
          value,
        },
      };
    case "checkbox":
      return {
        type: Types.ON_CHECKBOX_INPUT_CHANGE,
        payload: {
          formName,
          event,
          type,
          value,
        },
      };

    case "booleanCheckbox":
      return {
        type: Types.ON_CHECKBOX_BOOLEAN_INPUT_CHANGE,
        payload: {
          formName,
          event,
          type,
          value,
        },
      };

    case "select":
      return {
        type: Types.ON_SELECT_INPUT_CHANGE,
        payload: {
          formName,
          event,
          type,
          value,
        },
      };
    case "append":
      return {
        type: Types.ON_APPEND_INPUT_CHANGE,
        payload: {
          formName,
          event,
          value,
        },
      };

    default:
      return {
        type: Types.ON_INPUT_CHANGE,
        payload: {
          formName,
          event,
        },
      };
  }
};

// HAMNDLE INPUTS Increment
export const onInputIncrementAction = (formName, name) => {
  return {
    type: Types.ON_INPUT_INCREMENT,
    payload: {
      formName,
      name,
    },
  };
};

// HAMNDLE INPUTS Dencrement
export const onInputDecrementAction = (formName, name) => {
  return {
    type: Types.ON_INPUT_DECREMENT,
    payload: {
      formName,
      name,
    },
  };
};

// HAMNDLE INPUTS RESET
export const onInputResetAction = (formName, name) => {
  return {
    type: Types.ON_INPUT_RESET,
    payload: {
      formName,
      name,
    },
  };
};

export const onFormResetAction = (formName, value) => {
  return {
    type: Types.ON_FORM_RESET,
    payload: {
      formName,
      value,
    },
  };
};

export const onInputResetWithValueAction = (formName, name, resetValue) => {
  return {
    type: Types.ON_INPUT_RESET_WITH_VALUE,
    payload: {
      formName,
      name,
      resetValue,
    },
  };
};

// Set value @ Reducer
export const updateValueAction = (formName, name, value) => {
  return {
    type: Types.UPDATE_VALUE,
    payload: {
      formName,
      name,
      value,
    },
  };
};

//Handle input form validation
export const setInputValidateAction = (formNameValidation, name) => {
  return {
    type: Types.SET_INPUT_VALID,
    payload: {
      formNameValidation,
      name,
    },
  };
};

export const setInputInValidateAction = (formNameValidation, name) => {
  return {
    type: Types.SET_INPUT_INVALID,
    payload: {
      formNameValidation,
      name,
    },
  };
};

//handle dataTable #sorting #paginate #filter
export const setDataTableSortingAction = (ref, key, dir) => {
  return {
    type: Types.SET_DATATABLE_SORTING,
    payload: {
      ref,
      key,
      dir,
    },
  };
};

export const setDataTableFilterAction = (ref, filter = "") => {
  return {
    type: Types.SET_DATATABLE_FILTER,
    payload: {
      ref,
      filter,
    },
  };
};
export const setDataTablePaginateAction = (ref, pagination) => {
  return {
    type: Types.SET_DATATABLE_PAGINATE,
    payload: {
      ref,
      pagination,
    },
  };
};
// handle attendance Profile
export const fetchAttProfilesListAction = ({ ...data }) => {
  return {
    type: Types.FETCH_ATTENDANCE_PROFILES_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};
export const fetchAttProfilesListSuccess = (ref, list, sorting) => {
  return {
    type: Types.FETCH_ATTENDANCE_PROFILES_LIST_SUCCESS,
    payload: {
      ref,
      list,
      sorting,
    },
  };
};
export const fetchAttProfilesListFailed = (ref) => {
  return {
    type: Types.FETCH_ATTENDANCE_PROFILES_LIST_FAILED,
    payload: {
      ref,
    },
  };
};

export const resetAttProfileFormAction = () => {
  return {
    type: Types.RESET_ATTENDANCE_PROFILE_FORM,
  };
};

export const createAttProfileAction = () => {
  return {
    type: Types.CREATE_ATTENDANCE_PROFILE_ATTEMPT,
  };
};

export const createAttProfileSuccess = ({ ...normal }) => {
  return {
    type: Types.CREATE_ATTENDANCE_PROFILE_SUCCESS,
    payload: {
      normal: Helper.serializedFetchedAttProfile(normal),
    },
  };
};
export const createAttProfileFailed = (error) => {
  return {
    type: Types.CREATE_ATTENDANCE_PROFILE_FAILED,
    payload: { error },
  };
};

export const fetchAttendanceProfileAction = (id) => {
  return {
    type: Types.FETCH_ATTENDANCE_PROFILE_ATTEMPT,
    payload: { id },
  };
};

export const fetchAttendanceProfileSuccess = (data, workPlaces) => {
  return {
    type: Types.FETCH_ATTENDANCE_PROFILE_SUCCESS,
    payload: {
      normal: data ? Helper.serializedFetchedAttProfile(data) : null,
      workPlaces,
    },
  };
};

export const updateAttendanceHolidaysOptions = (holidays) => {
  return {
    type: Types.UPDATE_ATTENDANCE_PROFILE_HOLIDAYS,
    payload: holidays,
  };
};

export const fetchAttendanceProfileFailed = (error) => {
  return {
    type: Types.FETCH_ATTENDANCE_PROFILE_FAILED,
    payload: { error },
  };
};

// handle fetch All Employees
export const fetchAllEmployeesAction = ({ ...data }) => {
  return {
    type: Types.FETCH_ALL_EMPLOYEES_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchAllEmployeesListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_ALL_EMPLOYEES_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};
export const fetchAllEmployeesListFailed = (ref, error) => {
  return {
    type: Types.FETCH_ALL_EMPLOYEES_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

// handle fetch Employee Profile

export const fetchEmployeeProfileSuccess = (user) => {
  console.log(user);
  return {
    type: Types.FETCH_EMPLOYEE_PROFILE_SUCCESS,
    payload: { user },
  };
};

export const resetEmployeeProfileViewAction = () => {
  return {
    type: Types.RESET_EMPLOYEE_PRFOILE_VIEW,
  };
};

// Handle Fetch Employee Attendance logs
export const fetchEmployeeAttendanceLogsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_EMPLOYEE_ATTENDANCE_LOGS_SUCCESS,
    payload: {
      ref,
      list: Helper.serializedFetchedLogs(list),
    },
  };
};

export const fetchEmployeeAttendanceLogsFailed = (ref, error) => {
  return {
    type: Types.FETCH_EMPLOYEE_ATTENDANCE_LOGS_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

// HANDLE INIT & EDIT Employee

export const initEmployeeAction = (payload) => {
  return {
    type: Types.INIT_EMPLOYEE_ATTEMPT,
  };
};

export const editEmployeeAction = (user_id) => {
  return {
    type: Types.EDIT_EMPLOYEE_ATTEMPT,
    payload: { user_id },
  };
};

export const editEmployeeSuccess = (data) => {
  return {
    type: Types.EDIT_EMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const editEmployeeFailed = () => {
  return {
    type: Types.EDIT_EMPLOYEE_FAILED,
  };
};

export const initEmployeeSuccess = (data) => {
  return {
    type: Types.INIT_EMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const initEmployeeFailed = () => {
  return {
    type: Types.INIT_EMPLOYEE_FAILED,
  };
};

// For Locations At Create New Employee
export const setSelectedAttProfileAction = (selectedAttProfile) => {
  return {
    type: Types.SET_SELECTED_EMPLOYEE_ATT_PROFILE,
    payload: { selectedAttProfile },
  };
};

export const upsertEmployeeAction = (empInfo) => {
  return {
    type: Types.UPSERT_EMPLOYEE_ATTEMPT,
    payload: empInfo,
  };
};

export const updateEmployeeSuccess = (data) => {
  return {
    type: Types.UPDATE_EMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const updateEmployeeFailed = (errorMsg) => {
  return {
    type: Types.UPDATE_EMPLOYEE_FAILED,
    payload: errorMsg,
  };
};

export const resetEmployeeFormAction = () => {
  return {
    type: Types.RESET_EMPLOYEE_FORM,
  };
};

export const openEditEmployeeAction = () => {
  return {
    type: Types.SET_EDIT_EMPLOYEE_OPEN,
  };
};

export const closeEditEmployeeAction = () => {
  return {
    type: Types.SET_EDIT_EMPLOYEE_CLOSED,
  };
};

// handle LogOut
export const logoutAction = () => {
  return {
    type: Types.LOG_OUT,
  };
};

export const onDataTablePaginateAction = (ref, page) => {
  return {
    type: Types.PAGINATE_DATA_TABLE,
    payload: {
      ref,
      page,
    },
  };
};

export const fetchAllOfficesAction = ({ ...data }) => {
  return {
    type: Types.FETCH_ALL_OFFICES_ATTEMPT,
    payload: {
      ...data,
    },
  };
};
export const fetchAllOfficesSuccess = (ref, list) => {
  return {
    type: Types.FETCH_ALL_OFFICES_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};
export const fetchAllOfficesFailed = (ref, error) => {
  return {
    type: Types.FETCH_ALL_OFFICES_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

export const fetchAllDepartmentsAction = ({ ...data }) => {
  return {
    type: Types.FETCH_ALL_DEPARTMENTS_ATTEMPT,
    payload: { ...data },
  };
};
export const fetchAllDepartmentsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_ALL_DEPARTMENTS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};
export const fetchAllDepartmentsByCompany = ({ ref, data }) => {
  return {
    type: Types.FETCH_ALL_DEPARTMENTS_BY_COMPANY,
    payload: {
      ref,
      data,
    },
  };
};
export const fetchAllDepartmentsFailed = (ref, error) => {
  return {
    type: Types.FETCH_ALL_DEPARTMENTS_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

export const fetchAllPositionsAction = ({ ...data }) => {
  return {
    type: Types.FETCH_ALL_POSITIONS_ATTEMPT,
    payload: {
      ...data,
    },
  };
};
export const fetchAllPositionsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_ALL_POSITIONS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};
export const fetchAllPositionsFailed = (ref, error) => {
  return {
    type: Types.FETCH_ALL_POSITIONS_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

export const fetchAllHolidaysAction = ({ ...data }) => {
  return {
    type: Types.FETCH_ALL_HOLIDAYS_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchAllHolidaysSuccess = (ref, list, auto_suggest_holidays) => {
  return {
    type: Types.FETCH_ALL_HOLIDAYS_SUCCESS,
    payload: {
      ref,
      list,
      auto_suggest_holidays,
    },
  };
};

export const fetchAllHolidaysFailed = (ref, error) => {
  return {
    type: Types.FETCH_ALL_HOLIDAYS_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

export const setCommissionDateRangeAction = (dateRange) => {
  return {
    type: Types.SET_COMMISSION_DATE_RANGE,
    payload: dateRange,
  };
};

// update lang & lat location
export const updatePositionLocationAction = (formName, lng, lat) => {
  return {
    type: Types.UPDATE_LNG_LAT_LOCATION,
    payload: {
      formName,
      lng,
      lat,
    },
  };
};
// handle hr actions

//fetch inbound Requests
export const fetchInboundRequestsAction = ({ ...data }) => {
  return {
    type: Types.FETCH_INBOUND_REQUESTS_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchInboundRequestsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_INBOUND_REQUESTS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const updateRequestsEmployeesFilter = (list) => {
  return {
    type: Types.UPDTAE_REQUESTS_EMPLOYEES_FILTER,
    payload: {
      list,
    },
  };
};

export const fetchInboundRequestsFailed = (ref, error) => {
  return {
    type: Types.FETCH_INBOUND_REQUESTS_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

export const acceptInboundRequestAction = (
  id,
  employeeId,
  page_flag,
  isEditRequestModal = false,
  refetchQueries = []
) => {
  return {
    type: Types.ACCEPT_INBOUND_REQUEST_ATTEMPT,
    payload: { id, employeeId, page_flag, isEditRequestModal, refetchQueries },
  };
};
export const acceptInboundRequestSuccess = (
  id,
  updateNotificationsRequestUi = false
) => {
  return {
    type: Types.ACCEPT_INBOUND_REQUEST_SUCCESS,
    payload: { id, updateNotificationsRequestUi },
  };
};
export const acceptInboundRequestFailed = (id) => {
  return {
    type: Types.ACCEPT_INBOUND_REQUEST_FAILED,
    payload: { id },
  };
};

export const acceptOvertimeRequestAction = (
  id,
  interval,
  created_at,
  employeeId,
  page_flag,
  force_confirm = false,
  refetchQueries
) => {
  return {
    type: Types.ACCEPT_OVERTIME_REQUEST_ATTEMPT,
    payload: {
      id,
      interval,
      created_at,
      employeeId,
      page_flag,
      force_confirm,
      refetchQueries,
    },
  };
};
export const acceptOvertimeRequestSuccess = (id) => {
  return {
    type: Types.ACCEPT_OVERTIME_REQUEST_SUCCESS,
    payload: { id },
  };
};
export const acceptOvertimeRequestFailed = (error) => {
  return {
    type: Types.ACCEPT_OVERTIME_REQUEST_FAILED,
    payload: { error },
  };
};

export const rejectInboundRequestAction = (
  id,
  employeeId,
  page_flag,
  isEditRequestModal = false,
  refetchQueries
) => {
  return {
    type: Types.REJECT_INBOUND_REQUEST_ATTEMPT,
    payload: { id, employeeId, page_flag, isEditRequestModal, refetchQueries },
  };
};
export const rejectInboundRequestSuccess = (
  id,
  updateNotificationsRequestUi = false
) => {
  return {
    type: Types.REJECT_INBOUND_REQUEST_SUCCESS,
    payload: { id, updateNotificationsRequestUi },
  };
};
export const rejectInboundRequestFailed = (id) => {
  return {
    type: Types.REJECT_INBOUND_REQUEST_FAILED,
    payload: { id },
  };
};

// ******* (Start) Profile Action Modal *****

// toggle Attendance Action Modal
export const toggleAttendanceActionModal = () => {
  return { type: Types.TOGGLE_ATTENDANCE_ACTION_MODAL };
};

// Success Action
export const attendanceActionModalSuccess = () => {
  return {
    type: Types.ATTENDANCE_ACTION_MODAL_SUCCESS,
  };
};

// Failed Action
export const attendanceActionModalFailed = (message) => {
  return {
    type: Types.ATTENDANCE_ACTION_MODAL_FAILED,
    payload: { message },
  };
};

// (Start) Normal Leave Request
export const normalRequestActionAttempt = (
  from,
  to,
  deducted_from_emergency = false,
  employee_id = null,
  requestable,
  attachments,
  refetchQueries
) => {
  return {
    type: Types.SEND_NORMAL_REQUEST_ATTEMPT,
    payload: {
      from,
      to,
      deducted_from_emergency,
      employee_id,
      requestable,
      attachments,
      refetchQueries,
    },
  };
};

// (End) Normal Leave Request

// (Start) Unpaid Leave Request
export const unpaidRequestActionAttempt = (
  from,
  to,
  employee_id = null,
  requestable,
  attachments,
  refetchQueries
) => {
  return {
    type: Types.SEND_UNPAID_REQUEST_ATTEMPT,
    payload: {
      from,
      to,
      employee_id,
      requestable,
      attachments,
      refetchQueries,
    },
  };
};

// (End) Unpaid Leave Request

// (Start) Emergency Leave Request
export const emergencyRequestActionAttempt = (from, to, employee_id = null) => {
  return {
    type: Types.SEND_EMERGENCY_REQUEST_ATTEMPT,
    payload: {
      from,
      to,
      employee_id,
    },
  };
};

// (End) Emergency Leave Request

// (Start) permission Request
export const permissionRequestActionAttempt = (
  from,
  to,
  employee_id = null,
  requestDay,
  requestable,
  attachments,
  refetchQueries
) => {
  return {
    type: Types.SEND_PERMISSION_REQUEST_ATTEMPT,
    payload: {
      from,
      to,
      employee_id,
      requestDay,
      requestable,
      attachments,
      refetchQueries,
    },
  };
};

// (End) permission Request

// (Start) Halfday Request
export const halfdayRequestActionAttempt = (
  date,
  half,
  deducted_from_emergency = false,
  employee_id = null,
  requestable,
  attachments,
  refetchQueries
) => {
  return {
    type: Types.SEND_HALFDAY_REQUEST_ATTEMPT,
    payload: {
      deducted_from_emergency,
      date,
      half,
      employee_id,
      requestable,
      attachments,
      refetchQueries,
    },
  };
};

// (End) Halfday Request

// (Start) Home Exchange Request
export const homeExchangeRequestActionAttempt = (
  homeDay,
  instead,
  employee_id = null,
  requestable,
  attachments,
  refetchQueries
) => {
  return {
    type: Types.SEND_HOME_EXCHANGE_REQUEST_ATTEMPT,
    payload: {
      homeDay,
      instead,
      employee_id,
      requestableId: requestable?.id,
      requestableType: requestable?.typename,
      attachments,
      refetchQueries,
    },
  };
};

// (End) Home Exchange Request

// (Start) Home Exchange Request
export const homeFlexRequestActionAttempt = (
  homeDay,
  employee_id = null,
  requestable,
  attachments,
  refetchQueries
) => {
  return {
    type: Types.SEND_HOME_FLEX_REQUEST_ATTEMPT,
    payload: {
      homeDay,
      employee_id,
      requestableId: requestable?.id,
      requestableType: requestable?.typename,
      attachments,
      refetchQueries,
    },
  };
};

// (End) Home Exchange Request

// ******* (End) Profile Action Modal *****

// handle fetch user history attendance
export const fetchHistoryListAttempt = ({ ref, ...rest }) => {
  return {
    type: Types.FETCH_HISTORY_LIST_ATTEMPT,
    payload: { ref, ...rest },
  };
};

export const fetchHistoryListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_HISTORY_LIST_SUCCESS,
    payload: {
      ref,
      list: Helper.serializedFetchedLogs(list),
    },
  };
};

export const fetchHistoryListFailed = (ref, error) => {
  return {
    type: Types.FETCH_HISTORY_LIST_FAILED,
    payload: {
      ref,
      error,
    },
  };
};
// Handle sign in / out

export const getSignInInfoAction = () => {
  return {
    type: Types.SIGN_IN_INFO_ATTEMPT,
  };
};

export const getSignOutInfoAction = () => {
  return {
    type: Types.SIGN_OUT_INFO_ATTEMPT,
  };
};

export const createSignInAction = (from_home) => {
  return {
    type: Types.SIGN_IN_ATTEMPT,
    payload: {
      from_home,
    },
  };
};

export const createSignInSuccess = (res) => {
  return {
    type: Types.SIGN_IN_SUCCESS,
    payload: res,
  };
};

export const createSignInFailed = (error) => {
  return {
    type: Types.SIGN_IN_FAILED,
    payload: { error },
  };
};

export const dismissHomeSignInAction = () => {
  return {
    type: Types.DISMISS_HOME_SIGN_IN_ATTEMPT,
  };
};

export const showDismissHomeRequestAlert = (response) => {
  return {
    type: Types.SHOW_DISMISS_HOME_ALERT,
    payload: response,
  };
};

export const hideDismissHomeRequestAlert = () => {
  return {
    type: Types.HIDE_DISMISS_HOME_ALERT,
  };
};

export const createSignOutAction = (force_confirm) => {
  return {
    type: Types.SIGN_OUT_ATTEMPT,
    payload: {
      force_confirm,
    },
  };
};

export const createSignOutSuccess = (res) => {
  return {
    type: Types.SIGN_OUT_SUCCESS,
    payload: res,
  };
};

export const createSignOutFailed = () => {
  return {
    type: Types.SIGN_OUT_FAILED,
  };
};

export const showForceSignOutAlert = (response) => {
  return {
    type: Types.SHOW_FORCE_SIGN_OUT_ALERT,
    payload: response,
  };
};

export const hideForceSignOutAlert = () => {
  return {
    type: Types.HIDE_FORCE_SIGN_OUT_ALERT,
  };
};

export const refreshSignInOutAction = () => {
  return {
    type: Types.REFRESH_SIGN_IN_OUT_ATTEMPT,
  };
};

export const refreshSignInOutSuccess = (res) => {
  return {
    type: Types.REFRESH_SIGN_IN_OUT_SUCCESS,
    payload: res,
  };
};

// Start of fetching employee claims and requests for total notfications data
export const fetchTotalEmployeeRequests = (data) => {
  return {
    type: Types.FETCH_TOTAL_EMPLOYEE_REQUESTS,
    payload: data,
  };
};
// End of fetching employee claims and requests for total notfications data

// Start of fetching employee requests for total notfications data
export const fetchTotalPendingRequests = (data) => {
  return {
    type: Types.FETCH_TOTAL_PENDING_REQUESTS,
    payload: data,
  };
};
// End of fetching employee requests for total notfications data

// Start of fetching employee claims for total notfications data
export const fetchTotalPendingClaims = (data) => {
  return {
    type: Types.FETCH_TOTAL_PENDING_CLAIMS,
    payload: data,
  };
};
// End of fetching employee claims for total notfications data

// Handle logs
// handle fetch user history attendance
export const fetchAttendanceLogsAction = ({ ...data }) => {
  return {
    type: Types.FETCH_ATTENDANCE_LOGS_ATTEMPT,
    payload: { ...data },
  };
};

export const fetchAttendanceLogsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_ATTENDANCE_LOGS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchAttendanceLogsFailed = (ref, error) => {
  return {
    type: Types.FETCH_ATTENDANCE_LOGS_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

// Start of adjust sign out actions
export const handleAdjustSignOut = (employee_id, filter) => {
  return {
    type: Types.ADJUST_SIGNOUT_ATTEMPT,
    payload: { filter, employee_id },
  };
};

export const handleAdjustSignOutFailed = () => {
  return {
    type: Types.ADJUST_SIGNOUT_FAILED,
  };
};

export const handleAdjustSignOutSuccess = () => {
  return {
    type: Types.ADJUST_SIGNOUT_SUCCESS,
  };
};

// Start of fetch edit attendnace work timing options actions
export const fetchEditAttendanceWorkTimingsOptionsAction = (
  work_timing_profile_type,
  oldWorkTimingId
) => {
  return {
    type: Types.FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS,
    payload: work_timing_profile_type,
    oldWorkTimingId,
  };
};

export const fetchEditAttendanceWorkTimingsOptionsSuccess = (
  data,
  oldWorkTimingId
) => {
  return {
    type: Types.FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS_SUCCESS,
    payload: data,
    oldWorkTimingId,
  };
};

export const fetchEditAttendanceWorkTimingsOptionsFailed = () => {
  return {
    type: Types.FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS_FAILED,
  };
};
// End of fetch edit attendnace work timing options actions

// Start of fetch edit attendnace locations options actions
export const fetchEditAttendanceLocationsOptionsAction = (user_id) => {
  return {
    type: Types.FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS,
    payload: user_id,
  };
};

export const fetchEditAttendanceLocationsOptionsSuccess = (data) => {
  return {
    type: Types.FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchEditAttendanceLocationsOptionsFailed = () => {
  return {
    type: Types.FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS_FAILED,
  };
};
// End of fetch edit attendnace locations options actions

// Start of update edit attendnace locations actions
export const updateLocationsIdsAction = (
  sign_in_id,
  new_available_locations_ids
) => {
  return {
    type: Types.UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS,
    payload: {
      sign_in_id,
      new_available_locations_ids,
    },
  };
};

export const updateLocationsIdsSuccess = (data) => {
  return {
    type: Types.UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS_SUCCESS,
    payload: data,
  };
};

export const updateLocationsIdsFailed = () => {
  return {
    type: Types.UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS_FAILED,
  };
};
// End of update edit attendnace locations actions

export const updateEmployeesLogsFilter = (data) => {
  return {
    type: Types.UPDTAE_EMPLOYEES_FILTER_LOGS,
    payload: data,
  };
};

export const fetchSuspensionLogsAction = ({ ...data }) => {
  return {
    type: Types.FETCH_SUSPENSION_LOGS_ATTEMPT,
    payload: { ...data },
  };
};

export const fetchSuspensionLogsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_SUSPENSION_LOGS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchSuspensionLogsFailed = (ref, error) => {
  return {
    type: Types.FETCH_SUSPENSION_LOGS_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

export const fetchBalanceLogsAction = ({ ...data }) => {
  return {
    type: Types.FETCH_BALANCE_LOGS_ATTEMPT,
    payload: { ...data },
  };
};

export const fetchBalanceLogsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_BALANCE_LOGS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchBalanceLogsFailed = (ref, error) => {
  return {
    type: Types.FETCH_BALANCE_LOGS_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

// (Start) Suspension Modal
// toggle Modal
export const toggleSuspensionModal = (
  replace_direct_manager_in_suspension_modal
) => ({
  type: Types.TOGGLE_SUSPENSION_MODAL,
  payload: replace_direct_manager_in_suspension_modal,
});

export const dismissSuspensionModalActionAction = (data) => {
  return {
    type: Types.HIDE_SUSPENSION_MODAL,
    payload: data,
  };
};

export const updateSuspensionAttempt = (employeeData) => ({
  type: Types.UPDATE_SUSPENSION_ATTEMPT,
  payload: employeeData,
});
export const updateSuspensionSuccess = () => ({
  type: Types.UPDATE_SUSPENSION_SUCCESS,
});
export const updateSuspensionFailed = (message) => ({
  type: Types.UPDATE_SUSPENSION_FAILED,
  payload: message,
});
export const addEmployeeIdToSuspension = (id) => ({
  type: Types.ADD_EMPLOYEE_ID_TO_SUSPENSION,
  payload: id,
});

// handle adding managed employees to suspension modal
export const addManagedEmployeesToSuspensionModal = (managedEmployees) => ({
  type: Types.MANAGED_EMPLOYEES_IN_SUSPENSION_MODAL,
  payload: managedEmployees,
});
// (End) Suspension Modal

// (Start) Avtivate User
export const activateUserAttempt = ({
  user_id,
  activation_date,
  user_salary_config_input,
  employee_attendance,
  newBalanceData,
  attendanceTypeConfig,
  scheduleBaseDataForActivation,
}) => ({
  type: Types.ACTIVATE_USER_ATTEMPT,
  payload: {
    user_id,
    activation_date,
    user_salary_config_input,
    employee_attendance,
    newBalanceData,
    attendanceTypeConfig,
    scheduleBaseDataForActivation,
  },
});
export const activateUserSuccess = () => ({
  type: Types.ACTIVATE_USER_SUCCESS,
});
export const activateUserFailed = () => ({
  type: Types.ACTIVATE_USER_FAILED,
});

// toggle Modal
export const toggleActivationModal = (
  hasSickLeave,
  allowOvertime,
  hasEmergencyLeave,
  countryId
) => ({
  type: Types.TOGGLE_ACTIVATION_MODAL,
  payload: { hasSickLeave, allowOvertime, hasEmergencyLeave, countryId },
});

export const addEmployeeIdToActivation = (user_id, office, countryId) => ({
  type: Types.ADD_EMPLOYEE_ID_TO_ACTIVATION,
  payload: { user_id, office, countryId },
});
// (End) Avtivate User

// (Start) Delete Suspension
export const deleteSuspensionAttempt = (id, password) => ({
  type: Types.DELETE_SUSPENSION_ATTEMPT,
  payload: { id, password },
});
export const deleteSuspensionSuccess = () => ({
  type: Types.DELETE_SUSPENSION_SUCCESS,
});
export const deleteSuspensionFailed = (message) => ({
  type: Types.DELETE_SUSPENSION_FAILED,
  payload: message,
});
// (End) Delete Suspension

// Start of handle the adding the activation/onboarding balance to net remaning balance action
export const addActivationOnBoardingBalanceAction = (
  newNetRemaningLeaveBalance
) => ({
  type: Types.CALCULATE_NEW_NET_REMANING_LEAVE_BALANCE,
  payload: { newNetRemaningLeaveBalance },
});
// Start of handle the adding the activation/onboarding balance to net remaning balance action

export const fetchOvertimeLogsAction = ({ ...data }) => {
  return {
    type: Types.FETCH_OVERTIME_LOGS_ATTEMPT,
    payload: { ...data },
  };
};

export const fetchOvertimeLogsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_OVERTIME_LOGS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchOvertimeLogsFailed = (ref, error) => {
  return {
    type: Types.FETCH_OVERTIME_LOGS_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

// department modal

export const showDepartmentModalAction = (data) => {
  return {
    type: Types.SHOW_DEPARTMENT_MODAL,
    payload: data,
  };
};
export const dismissDepartmentModalAction = (data) => {
  return {
    type: Types.HIDE_DEPARTMENT_MODAL,
    payload: data,
  };
};

export const updateDepartmentAction = (data) => ({
  type: Types.UPDATE_DEPARTMENT_ATTEMPT,
  payload: data,
});

export const updateDepartmentSuccess = (ref) => ({
  type: Types.UPDATE_DEPARTMENT_SUCCESS,
  payload: { ref },
});

export const updateDepartmentFailed = (ref, message) => ({
  type: Types.UPDATE_DEPARTMENT_FAILED,
  payload: { ref, message },
});

export const createDepartmentAction = (data) => ({
  type: Types.CREATE_DEPARTMENT_ATTEMPT,
  payload: data,
});

export const createDepartmentSuccess = (ref) => ({
  type: Types.CREATE_DEPARTMENT_SUCCESS,
  payload: { ref },
});

export const createDepartmentFailed = (ref, message) => ({
  type: Types.CREATE_DEPARTMENT_FAILED,
  payload: { ref, message },
});

export const deleteDepartmentAction = (data) => ({
  type: Types.DELETE_DEPARTMENT_ATTEMPT,
  payload: data,
});

export const deleteDepartmentSuccess = () => ({
  type: Types.DELETE_DEPARTMENT_SUCCESS,
});

export const deleteDepartmentFailed = () => ({
  type: Types.DELETE_DEPARTMENT_FAILED,
});

// Position modal

export const showPositionModalAction = (data) => {
  return {
    type: Types.SHOW_POSITION_MODAL,
    payload: data,
  };
};

export const dismissPositionModalAction = (data) => {
  return {
    type: Types.HIDE_POSITION_MODAL,
    payload: data,
  };
};

export const showHolidaysModalAction = (data) => {
  return {
    type: Types.SHOW_HOLIDAYS_MODAL,
    payload: data,
  };
};

export const dismissHolidaysModalAction = (data) => {
  return {
    type: Types.HIDE_HOLIDAYS_MODAL,
    payload: data,
  };
};

export const updatePositionAction = (data) => ({
  type: Types.UPDATE_POSITION_ATTEMPT,
  payload: data,
});

export const updatePositionSuccess = (ref) => ({
  type: Types.UPDATE_POSITION_SUCCESS,
  payload: { ref },
});

export const updatePositionFailed = (ref, message) => ({
  type: Types.UPDATE_POSITION_FAILED,
  payload: { ref, message },
});

export const createPositionAction = (data) => ({
  type: Types.CREATE_POSITION_ATTEMPT,
  payload: data,
});

export const createPositionSuccess = (ref) => ({
  type: Types.CREATE_POSITION_SUCCESS,
  payload: { ref },
});

export const createPositionFailed = (ref, message) => ({
  type: Types.CREATE_POSITION_FAILED,
  payload: { ref, message },
});

export const deletePositionAction = (data) => ({
  type: Types.DELETE_POSITION_ATTEMPT,
  payload: data,
});

export const deletePositionSuccess = () => ({
  type: Types.DELETE_POSITION_SUCCESS,
});

export const deletePositionFailed = () => ({
  type: Types.DELETE_POSITION_FAILED,
});

export const createHolidayAction = (data) => ({
  type: Types.CREATE_HOLIDAYS_ATTEMPT,
  payload: data,
});

export const createHolidaySuccess = (ref) => ({
  type: Types.CREATE_HOLIDAYS_SUCCESS,
  payload: { ref },
});

export const createHolidayFailed = (error) => ({
  type: Types.CREATE_HOLIDAYS_FAILED,
  payload: {
    ...error,
  },
});

export const deleteHolidayAction = (data) => ({
  type: Types.DELETE_HOLIDAYS_ATTEMPT,
  payload: data,
});

export const deleteHolidaySuccess = () => ({
  type: Types.DELETE_HOLIDAYS_SUCCESS,
});

export const deleteHolidayFailed = () => ({
  type: Types.DELETE_HOLIDAYS_FAILED,
});

// (Start) Delete User
export const deleteUserAttempt = (
  id,
  password,
  replace_by = null,
  new_manager_for_replaced_by = null
) => ({
  type: Types.DELETE_USER_ATTEMPT,
  payload: { id, password, replace_by, new_manager_for_replaced_by },
});

export const deleteUserSuccess = () => ({
  type: Types.DELETE_USER_SUCCESS,
});

export const deleteUserFailed = (message) => ({
  type: Types.DELETE_USER_FAILED,
  payload: message,
});

export const showConfirmDeleteManagerModal = (data) => {
  return {
    type: Types.SHOW_CONFIRM_DELETE_MANAGER_MODAL,
    payload: data,
  };
};

export const dismissConfirmDeleteManagerModalAction = (data) => {
  return {
    type: Types.HIDE_CONFIRM_DELETE_MANAGER_MODAL,
    payload: data,
  };
};
// (End) Delete User

// Start of fetch init delete manager action
export const fetchInitDeleteManagerAction = (data) => {
  return {
    type: Types.FETCH_INIT_DELETE_MANAGER_ATTEMPT,
    payload: data,
  };
};

export const fetchInitDeleteManagerSuccess = () => {
  return {
    type: Types.FETCH_INIT_DELETE_MANAGER_SUCCESS,
  };
};

export const fetchInitDeleteManagerFailed = () => {
  return {
    type: Types.FETCH_INIT_DELETE_MANAGER_FAILED,
  };
};
// End of fetch init delete manager action

// Start of fetch init suspend manager action
export const fetchInitSuspendManagerAction = (data) => {
  return {
    type: Types.FETCH_INIT_SUSPEND_MANAGER_ATTEMPT,
    payload: data,
  };
};

export const fetchInitSuspendManagerSuccess = () => {
  return {
    type: Types.FETCH_INIT_SUSPEND_MANAGER_SUCCESS,
  };
};

export const fetchInitSuspendManagerFailed = () => {
  return {
    type: Types.FETCH_INIT_SUSPEND_MANAGER_FAILED,
  };
};
// End of fetch init suspend manager action

// Start of fetch replace by managers options action
export const fetchReplaceByManagersOptsAction = (data) => {
  return {
    type: Types.FETCH_REPLACE_BY_MANAGERS_OPTIONS_ATTEMPT,
    payload: data,
  };
};

export const fetchReplaceByManagersOptsSuccess = (data) => {
  return {
    type: Types.FETCH_REPLACE_BY_MANAGERS_OPTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchReplaceByManagersOptsFailed = () => {
  return {
    type: Types.FETCH_REPLACE_BY_MANAGERS_OPTIONS_FAILED,
  };
};
// End of fetch replace by managers options action

// Start of check password actions
export const checkPasswordAttempt = (password, cbFn) => ({
  type: Types.CHECK_PASSWORD_ATTEMPT,
  payload: { password, cbFn },
});

export const checkPasswordSuccess = () => ({
  type: Types.CHECK_PASSWORD_SUCCESS,
});

export const checkPasswordFailed = () => ({
  type: Types.CHECK_PASSWORD_FAILED,
});
// End of check password actions

// UPDATE Navbar SELECTED OFFICE ACTION
// (Start) EditHistory Modal
export const showEditHistoryModal = (employeeRecord) => {
  return {
    type: Types.SHOW_EDIT_HISTORY_MODAL,
    payload: { ...employeeRecord },
  };
};
export const hideEditHistoryModal = () => {
  return { type: Types.HIDE_EDIT_HISTORY_MODAL };
};

export const editHistoryModalAttempt = (employeeData) => ({
  type: Types.EDIT_ATT_LOG_ATTEMPT,
  payload: employeeData,
});
export const editHistoryModalSuccess = (ref) => ({
  type: Types.EDIT_ATT_LOG_SUCCESS,
  payload: { ref },
});
export const editHistoryModalFailed = (ref, message) => ({
  type: Types.EDIT_ATT_LOG_FAILED,
  payload: { ref, message },
});
// (End) EditHistory Modal

// (Start) Edit AttRequest Modal
export const showEditAttRequestModal = (data) => {
  return {
    type: Types.SHOW_EDIT_ATT_REQUEST_MODAL,
    payload: {
      data,
      ref: "editAttRequestModal",
      formName: "editAttRequestModalData",
    },
  };
};
export const hideEditAttRequestModal = () => {
  return {
    type: Types.HIDE_EDIT_ATT_REQUEST_MODAL,
    payload: {
      ref: "editAttRequestModal",
      formName: "editAttRequestModalData",
    },
  };
};

export const editAttRequestModalAttempt = (data) => ({
  type: Types.EDIT_ATT_REQUEST_MODAL_ATTEMPT,
  payload: { ref: "editAttRequestModal", data },
});
export const editAttRequestModalSuccess = () => ({
  type: Types.EDIT_ATT_REQUEST_MODAL_SUCCESS,
  payload: { ref: "editAttRequestModal" },
});
export const editAttRequestModalFailed = (message) => ({
  type: Types.EDIT_ATT_REQUEST_MODAL_FAILED,
  payload: { ref: "editAttRequestModal", message },
});
// (End) Edit AttRequest Modal

// (Start) View AttRequest Modal
export const showViewAttRequestModal = (data, notification = false) => {
  return {
    type: Types.SHOW_VIEW_ATT_REQUEST_MODAL,
    payload: {
      data,
      ref: "viewAttRequestModal",
      formName: "viewAttRequestModalData",
      notification,
    },
  };
};
export const hideViewAttRequestModal = () => {
  return {
    type: Types.HIDE_VIEW_ATT_REQUEST_MODAL,
    payload: {
      ref: "viewAttRequestModal",
      formName: "viewAttRequestModalData",
    },
  };
};

export const viewAttRequestModalAttempt = ({ emp_id, date, type }) => ({
  type: Types.VIEW_ATT_REQUEST_MODAL_ATTEMPT,
  payload: { ref: "viewAttRequestModal", emp_id, date, type },
});
export const viewAttRequestModalSuccess = (data) => ({
  type: Types.VIEW_ATT_REQUEST_MODAL_SUCCESS,
  payload: {
    data,
    ref: "viewAttRequestModal",
    formName: "viewAttRequestModalData",
  },
});
export const viewAttRequestModalFailed = (message) => ({
  type: Types.VIEW_ATT_REQUEST_MODAL_FAILED,
  payload: { ref: "viewAttRequestModal", message },
});
// (End) View AttRequest Modal

// (Start) Balance Modal
export const showBalanceModal = (payload) => {
  return {
    type: Types.SHOW_BALANCE_MODAL,
    payload,
  };
};

export const hideBalanceModal = (ref, formName) => {
  return {
    type: Types.HIDE_BALANCE_MODAL,
    payload: { ref, formName },
  };
};

export const balanceModalAttempt = (data) => ({
  type: Types.BALANCE_MODAL_ATTEMPT,
  payload: {
    ...data,
  },
});

export const balanceModalSuccess = (data) => ({
  type: Types.BALANCE_MODAL_SUCCESS,
  payload: {
    ...data,
  },
});

export const balanceModalFailed = (error) => {
  return {
    type: Types.BALANCE_MODAL_FAILED,
    payload: {
      ...error,
    },
  };
};
// (End) Balance Modal

// HANDLE OFFICE FORM

//LOCATION OFFICE

export const showOfficeLocationModalAction = (data) => {
  return {
    type: Types.SHOW_OFFICE_LOCATION_MODAL,
    payload: data,
  };
};

export const showEmployeeLocationModalAction = (data) => {
  return {
    type: Types.SHOW_EMPLOYEE_LOCATION_MODAL,
    payload: data,
  };
};

export const dismissOfficeLocationModalAction = (data) => {
  return {
    type: Types.HIDE_OFFICE_LOCATION_MODAL,
    payload: data,
  };
};

export const setLocationsServerValidation = (data) => {
  return {
    type: Types.SET_LOCATIONS_SERVER_VALIDATION,
    payload: {
      data,
    },
  };
};

// assign  location to office locations
export const addOfficeLocationAction = (formName, data) => {
  return {
    type: Types.UPDATE_OFFICE_LOCATION,
    payload: { formName, data },
  };
};

export const addEmployeeLocationAction = (formName, data) => {
  return {
    type: Types.UPDATE_EMPLOYEE_LOCATION,
    payload: { formName, data },
  };
};

export const resetOfficeLocationAction = () => {
  return {
    type: Types.RESET_OFFICE_LOCATION,
  };
};

//HOLIDAY OFFICE

export const showOfficeHolidayModalAction = (data) => {
  return {
    type: Types.SHOW_OFFICE_HOLIDAY_MODAL,
    payload: data,
  };
};

export const dismissOfficeHolidayModalAction = (data) => {
  return {
    type: Types.HIDE_OFFICE_HOLIDAY_MODAL,
    payload: data,
  };
};

// assign  holiday to office holidays
export const addOfficeHolidayAction = (data) => {
  return {
    type: Types.UPDATE_OFFICE_HOLIDAY,
    payload: data,
  };
};

// assign  holiday to attendance profile holidays
export const addAttendanceProfileHolidayAction = (data) => {
  return {
    type: Types.UPDATE_ATTENDANCE_PROFILE_HOLIDAY,
    payload: data,
  };
};

export const resetOfficeHolidayAction = () => {
  return {
    type: Types.RESET_OFFICE_HOLIDAY,
  };
};

// handle multiselect with value is object option

export const updateObjectMultiSelectAction = (data) => {
  return {
    type: Types.UPDATE_OBJECT_MULTI_SELECT,
    payload: { ...data },
  };
};

// HANDLE OFFICE

export const fetchOfficeAction = (id) => ({
  type: Types.FETCH_OFFICE_ATTEMPT,
  payload: {
    id,
    includeOffice: id ? true : false,
  },
});
export const editOfficeSuccess = (data) => ({
  type: Types.FETCH_OFFICE_SUCCESS,
  payload: data,
});
export const editOfficeFailed = (error) => ({
  type: Types.FETCH_OFFICE_FAILED,
  payload: error,
});

export const resetOfficeFormAction = () => ({
  type: Types.RESET_OFFICE_FORM,
});

// handle office upsert
export const updateOfficeAction = (data) => ({
  type: Types.UPDATE_OFFICE_ATTEMPT,
  payload: data,
});

export const updateOfficeSuccess = (ref) => ({
  type: Types.UPDATE_OFFICE_SUCCESS,
  payload: { ref },
});

export const updateOfficeFailed = (ref, message) => ({
  type: Types.UPDATE_OFFICE_FAILED,
  payload: { ref, message },
});

export const resetOfficePassword = () => ({
  type: Types.RESET_OFFICE_PASSWORD,
});

// Start of set country currencies action
export const setCountryCurrenciesAction = (data) => ({
  type: Types.SET_SELECTED_CONTURY_CURRENIESES,
  payload: data,
});
// End of set country currencies action

//delete office

export const deleteOfficeAction = (data) => ({
  type: Types.DELETE_OFFICE_ATTEMPT,
  payload: data,
});

export const deleteOfficeSuccess = () => ({
  type: Types.DELETE_OFFICE_SUCCESS,
});

export const deleteOfficeFailed = () => ({
  type: Types.DELETE_OFFICE_FAILED,
});

export const setBalanceWeekRange = (weekRange) => ({
  type: Types.SET_BALANCE_WEEK_RANG,
  payload: weekRange,
});

export const upsertAttendanceProfileAction = (data) => {
  return {
    type: Types.UPSERT_ATTENDANCE_PROFILE_ATTEMPT,
    payload: {
      normal: data.normal,
      firstHalf: data.firstHalf,
      secondHalf: data.secondHalf,
      isSetupWizard: data?.isSetupWizard,
    },
  };
};

export const upsertAttendanceProfileSuccess = () => ({
  type: Types.UPSERT_ATTENDANCE_PROFILE_SUCCESS,
});

export const upsertAttendanceProfileFailed = (serverError = {}) => {
  return {
    type: Types.UPSERT_ATTENDANCE_PROFILE_FAILED,
    payload: serverError,
  };
};

export const deleteAttProfileAction = (data) => ({
  type: Types.DELETE_ATTENDANCE_PROFILE_ATTEMPT,
  payload: data,
});

export const deleteAttProfileSuccess = () => ({
  type: Types.DELETE_ATTENDANCE_PROFILE_SUCCESS,
});

export const deleteAttProfileFailed = () => ({
  type: Types.DELETE_ATTENDANCE_PROFILE_FAILED,
});

export const cloneAttProfileAction = (data) => ({
  type: Types.CLONE_ATTENDANCE_PROFILE_ATTEMPT,
  payload: data,
});

export const cloneAttProfileSuccess = () => ({
  type: Types.CLONE_ATTENDANCE_PROFILE_SUCCESS,
});

export const cloneAttProfileFailed = () => ({
  type: Types.CLONE_ATTENDANCE_PROFILE_FAILED,
});

export const fetchOfficeHolidaysAction = () => {
  return {
    type: Types.FETCH_ALL_OFFICE_HOLIDAYS_ATTEMPT,
  };
};

export const fetchOfficeHolidaysSuccess = (data) => {
  return {
    type: Types.FETCH_ALL_OFFICE_HOLIDAYS_SUCCESS,
    payload: data,
  };
};

export const fetchOfficeHolidaysFailed = (error) => {
  return {
    type: Types.FETCH_ALL_OFFICE_HOLIDAYS_FAILED,
    payload: error,
  };
};

export const forgotPasswordAttempt = (
  email = null,
  phone = null,
  dialCode = null
) => ({
  type: Types.FORGOT_PASSWORD_ATTEMPT,
  payload: { email, phone, dialCode },
});

export const forgotPasswordFinish = () => ({
  type: Types.FORGOT_PASSWORD_FINISH,
});

export const resetPasswordAttempt = (data) => ({
  type: Types.RESET_PASSWORD_ATTEMPT,
  payload: data,
});

export const resetPasswordSuccess = () => ({
  type: Types.RESET_PASSWORD_SUCCESS,
});
export const resetPasswordFailed = () => ({
  type: Types.RESET_PASSWORD_FAILED,
});

export const fetchDashboardDepartmentFilter = (departments) => {
  return {
    type: Types.FETCH_DASHBOARD_DEPARTMNETS,
    payload: departments,
  };
};

export const updateOvertimeInputAction = (data) => {
  return {
    type: Types.UPDATE_OVERTIME_INPUT,
    payload: data,
  };
};

export const dismissDashboardUsersAction = (status) => {
  return {
    type: Types.DISMISS_DASHBOARD_USERS_MODAL,
    payload: {
      ref: status,
    },
  };
};
export const fetchDashboardUsersAction = (status) => {
  return {
    type: Types.FETCH_DASHBOARD_USERS_ATTEMPT,
    payload: {
      status,
    },
  };
};

export const fetchDashboardUsersSuccess = (ref, data) => {
  return {
    type: Types.FETCH_DASHBOARD_USERS_SUCCESS,
    payload: {
      data,
      ref,
    },
  };
};

export const fetchDashboardUsersFailed = (ref, data = []) => {
  return {
    type: Types.FETCH_DASHBOARD_USERS_FAILED,
    payload: {
      data,
      ref,
    },
  };
};

// Start of Bonus form actions
export const showAddNewBonusModalAction = (data) => {
  return {
    type: Types.SHOW_BONUS_MODAL,
    payload: data,
  };
};

export const dismissAddNewBonusModalAction = (data) => {
  return {
    type: Types.HIDE_BONUS_MODAL,
    payload: data,
  };
};
// End of Bonus form actions

// Start of Deductions form actions
export const showAddNewDeductionsModalAction = (data) => {
  return {
    type: Types.SHOW_DEDUCTIONS_MODAL,
    payload: data,
  };
};

export const dismissAddNewDeductionsModalAction = (data) => {
  return {
    type: Types.HIDE_DEDUCTIONS_MODAL,
    payload: data,
  };
};
// End of Deductions form actions

// Start of Claim Request form actions
export const showClaimRequestModalAction = (data) => {
  return {
    type: Types.SHOW_CLAIM_REQUEST_MODAL,
    payload: data,
  };
};

export const dismissClaimRequestModalAction = (data) => {
  return {
    type: Types.HIDE_CLAIM_REQUEST_MODAL,
    payload: data,
  };
};
// End of Claim Request form actions

// Start of Accept Claim Request form actions
export const showAcceptClaimRequestModalAction = (data) => {
  return {
    type: Types.SHOW_ACCEPT_CLAIM_REQUEST_MODAL,
    payload: data,
  };
};

export const dismissAcceptClaimRequestModalAction = (data) => {
  return {
    type: Types.HIDE_ACCEPT_CLAIM_REQUEST_MODAL,
    payload: data,
  };
};
// End of Accept Claim Request form actions

// Start of extras employee filter action
export const fetchExtraUsersFilterAction = (users) => {
  return {
    type: Types.FETCH_EXTRA_USERS_FILTER,
    payload: users,
  };
};
// End of extras employee filter action

// Start of fetch users claims
export const fetchUsersClaimsAction = (data) => {
  return {
    type: Types.FETCH_USERS_CLAIMS_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchUsersClaimsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_USERS_CLAIMS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchUsersClaimsFailed = (ref, list) => {
  return {
    type: Types.FETCH_USERS_CLAIMS_FAILED,
    payload: {
      ref,
      list,
    },
  };
};

// Start of fetch users bonus
export const fetchUsersBonusAction = (data) => {
  return {
    type: Types.FETCH_USERS_BONUS_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchUsersBonusSuccess = (ref, list) => {
  return {
    type: Types.FETCH_USERS_BONUS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchUsersBonusFailed = (ref, list) => {
  return {
    type: Types.FETCH_USERS_BONUS_FAILED,
    payload: {
      ref,
      list,
    },
  };
};
// End of fetch users bonus

// Start of delete bonus record action
export const deleteBonusAction = (data) => ({
  type: Types.DELETE_BONUS_ATTEMPT,
  payload: data,
});

export const deleteBonusSuccess = () => ({
  type: Types.DELETE_BONUS_SUCCESS,
});

export const deleteBonusFailed = () => ({
  type: Types.DELETE_BONUS_FAILED,
});
// End of delete bonus record action

// Start of fetch users deductions
export const fetchUsersDeductionsAction = (data) => {
  return {
    type: Types.FETCH_USERS_DEDUCTIONS_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchUsersDeductionsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_USERS_DEDUCTIONS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchUsersDeductionsFailed = (ref, list) => {
  return {
    type: Types.FETCH_USERS_DEDUCTIONS_FAILED,
    payload: {
      ref,
      list,
    },
  };
};
// End of fetch users deductions

// Start of delete Deduction record action
export const deleteDeductionAction = (data) => ({
  type: Types.DELETE_DEDUCTION_ATTEMPT,
  payload: data,
});

export const deleteDeductionSuccess = () => ({
  type: Types.DELETE_DEDUCTION_SUCCESS,
});

export const deleteDeductionFailed = () => ({
  type: Types.DELETE_DEDUCTION_FAILED,
});
// End of delete Deduction record action

// Start of upsert Bonus modal action
export const showEditBonusModalAction = (bonusRecord) => {
  return {
    type: Types.EDIT_BONUS_ATTEMPT,
    payload: {
      bonusRecord,
    },
  };
};

export const upsertBonusAction = (data) => {
  return {
    type: Types.UPSERT_BONUS_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const upsertBonusSuccess = (res) => {
  return {
    type: Types.UPSERT_BONUS_SUCCESS,
    payload: {
      ...res,
    },
  };
};

export const upsertBonusFailed = (error) => {
  return {
    type: Types.UPSERT_BONUS_FAILED,
    payload: {
      ...error,
    },
  };
};
// End of upsert Bonus modal action

// Start of upsert Deduction modal action
export const showEditDeductionsModalAction = (deductionRecord) => {
  return {
    type: Types.EDIT_DEDUCTIONS_ATTEMPT,
    payload: {
      deductionRecord,
    },
  };
};

export const upsertDeductionAction = (data) => {
  return {
    type: Types.UPSERT_DEDUCTION_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const upsertDeductionSuccess = (res) => {
  return {
    type: Types.UPSERT_DEDUCTION_SUCCESS,
    payload: {
      ...res,
    },
  };
};

export const upsertDeductionFailed = (error) => {
  return {
    type: Types.UPSERT_DEDUCTION_FAILED,
    payload: {
      ...error,
    },
  };
};
// End of upsert Deduction modal action

// Start of edit amount form modal actions
export const showEditAmountModalAction = (data) => {
  return {
    type: Types.SHOW_EDIT_AMOUNT_MODAL,
    payload: data,
  };
};

export const dismissEditAmountModalAction = (data) => {
  return {
    type: Types.HIDE_EDIT_AMOUNT_MODAL,
    payload: data,
  };
};

export const fetchAmountModalAction = (data) => {
  return {
    type: Types.FETCH_DEDUCTION_AMOUNT_ATTEMPT,
    payload: data,
  };
};
export const fetchAmountModalSuccess = (data) => {
  return {
    type: Types.FETCH_DEDUCTION_AMOUNT_SUCCESS,
    payload: data,
  };
};
// End of edit amount form modal actions

// Start of manage privilages modal actions
export const editEmployeePrivilegesAction = (user_id, planId) => {
  return {
    type: Types.EDIT_EMPLOYEE_PRIVILEGES_ATTEMPT,
    payload: {
      user_id,
      planId,
    },
  };
};
export const editEmployeePrivilegesFailed = () => {
  return {
    type: Types.EDIT_EMPLOYEE_PRIVILEGES_FAILED,
  };
};

export const upsertEmployeePrivilegesAction = (data) => {
  return {
    type: Types.UPSERT_EMPLOYEE_PRIVILEGES_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const upsertEmployeePrivilegesFailed = (validation = {}) => {
  return {
    type: Types.UPSERT_EMPLOYEE_PRIVILEGES_FAILED,
    payload: { validation },
  };
};

export const showManagePrivilagesModalAction = (data) => {
  return {
    type: Types.SHOW_MANAGE_PRIVILAGES_MODAL,
    payload: data,
  };
};

export const dismissManagePrivilagesModalAction = (data) => {
  return {
    type: Types.HIDE_MANAGE_PRIVILAGES_MODAL,
    payload: data,
  };
};

export const updateRadioScopeAction = (formName, name, value) => {
  return {
    type: Types.UPDATE_PRIVILEGES_RADIO,
    payload: { formName, name, value },
  };
};
// End of manage privilages modal actions

// Start of payroll Report list actions
export const fetchPayrollReportListAction = (data) => {
  return {
    type: Types.FETCH_PAYROLL_REPORT_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchPayrollReportListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_PAYROLL_REPORT_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchPayrollReportListFailed = (ref, list) => {
  return {
    type: Types.FETCH_PAYROLL_REPORT_LIST_FAILED,
    payload: {
      ref,
      list,
    },
  };
};

export const stopReportListLoader = (ref) => {
  return {
    type: Types.STOP_REPORT_LIST_LOADER,
    payload: { ref },
  };
};

export const fetchMyPayrollReportAction = ({ ref }) => {
  return {
    type: Types.FETCH_MY_PAYROLL_ATTEMPT,
    payload: { ref },
  };
};

export const fetchMyPayrollSuccess = (ref, list) => {
  return {
    type: Types.FETCH_MY_PAYROLL_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};
// End of payroll Report list actions

// Start of Monthly Report list actions
export const fetchMonthlyReportListAction = (data) => {
  return {
    type: Types.FETCH_MONTHLY_REPORT_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchMonthlyReportListSuccess = (data, list) => {
  return {
    type: Types.FETCH_MONTHLY_REPORT_LIST_SUCCESS,
    payload: {
      data,
      list,
    },
  };
};

export const fetchMonthlyReportListFailed = (ref, list) => {
  return {
    type: Types.FETCH_MONTHLY_REPORT_LIST_FAILED,
    payload: {
      ref,
      list,
    },
  };
};
// End of Monthly Report list actions

// Start of Yearly Report list actions
export const fetchYearlyReportListAction = (data) => {
  return {
    type: Types.FETCH_YEARLY_REPORT_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchYearlyReportListSuccess = (data, list) => {
  return {
    type: Types.FETCH_YEARLY_REPORT_LIST_SUCCESS,
    payload: {
      data,
      list,
    },
  };
};

export const fetchYearlyReportListFailed = (ref, list) => {
  return {
    type: Types.FETCH_YEARLY_REPORT_LIST_FAILED,
    payload: {
      ref,
      list,
    },
  };
};
// End of Yearly Report list actions

// Start of payroll report list employee filter action
export const fetchPayrollReportEmployeesFilterAction = (users) => {
  return {
    type: Types.FETCH_PAYROLL_REPORT_EMPLOYEES_FILTER,
    payload: users,
  };
};
// End of payroll report list employee filter action

// Start of payroll report list currinces filter action
export const fetchPayrollReportCurrincesFilterAction = (currinces) => {
  return {
    type: Types.FETCH_PAYROLL_REPORT_CURRENCIES_FILTER,
    payload: currinces,
  };
};
// End of payroll report list currinces filter action

// Start of payroll report list regulation filter action
export const fetchPayrollReportRegulationFilterAction = (regulations) => {
  return {
    type: Types.FETCH_PAYROLL_REGULATION_OPTIONS_FILTER,
    payload: regulations,
  };
};
// End of payroll report list regulation filter action

// Start of Employee Salary config actions
export const fetchEmployeeSalaryConfigAction = (data) => {
  return {
    type: Types.FETCH_EMPLOYEE_SALARY_CONFIG_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchEmployeeSalaryConfigSuccess = (user) => {
  return {
    type: Types.FETCH_EMPLOYEE_SALARY_CONFIG_SUCCESS,
    payload: { user },
  };
};

export const fetchEmployeeSalaryConfigFailed = () => {
  return {
    type: Types.FETCH_EMPLOYEE_SALARY_CONFIG_FAILED,
  };
};
// End of Employee Salary config actions

// Start of upsert Calims request modal action
export const showEditClaimsRequestModalAction = (claimRequestRecord) => {
  return {
    type: Types.EDIT_EMPLOYEE_CLAIM_REQUEST_ATTEMPT,
    payload: {
      claimRequestRecord,
    },
  };
};

export const upsertClaimsRequestAction = (data) => {
  return {
    type: Types.UPSERT_CLAIM_REQUEST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const upsertEmployeeClaimsRequestAction = (data) => {
  return {
    type: Types.UPSERT_EMPLOYEE_CLAIM_REQUEST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const upsertClaimsRequestSuccess = (res) => {
  return {
    type: Types.UPSERT_CLAIM_REQUEST_SUCCESS,
    payload: {
      ...res,
    },
  };
};

export const upsertEmployeeClaimsRequestSuccess = (res) => {
  return {
    type: Types.UPSERT_EMPLOYEE_CLAIM_REQUEST_SUCCESS,
    payload: {
      ...res,
    },
  };
};

export const upsertClaimsRequestFailed = (error) => {
  return {
    type: Types.UPSERT_CLAIM_REQUEST_FAILED,
    payload: {
      ...error,
    },
  };
};

export const upsertEmployeeClaimsRequestFailed = (error) => {
  return {
    type: Types.UPSERT_EMPLOYEE_CLAIM_REQUEST_FAILED,
    payload: {
      ...error,
    },
  };
};

export const acceptClaimRequestAction = (ref, id, payroll_at) => {
  return {
    type: Types.ACCEPT_CLAIM_REQUEST_ATTEMPT,
    payload: { ref, id, payroll_at, action_type: "accept" },
  };
};

export const rejectClaimRequestAction = (id, payroll_at) => {
  return {
    type: Types.REJECT_CLAIM_REQUEST_ATTEMPT,
    payload: { id, payroll_at, action_type: "reject" },
  };
};

export const claimRequestActionSuccess = (data) => {
  return {
    type: Types.CLAIM_ACTION_REQUEST_SUCCESS,
    payload: data,
  };
};

export const claimRequestActionFailed = ({ ref, serverRef, errors }) => {
  return {
    type: Types.CLAIM_ACTION_REQUEST_FAILED,
    payload: { ref, serverRef, errors },
  };
};

export const cancelClaimRequestAction = (id) => {
  return {
    type: Types.CANCEL_CLAIM_REQUEST_ATTEMPT,
    payload: { id },
  };
};

export const cancelClaimRequestSuccess = (id) => {
  return {
    type: Types.CANCEL_CLAIM_REQUEST_SUCCESS,
    payload: { id },
  };
};

export const cancelClaimRequestFailed = (id) => {
  return {
    type: Types.CANCEL_CLAIM_REQUEST_FAILED,
    payload: { id },
  };
};
// End of upsert Calims request modal action

// Start Of Sick Leaves request modal action

export const upsertSickRequestAction = (data) => {
  return {
    type: Types.UPSERT_SICK_REQUEST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const upsertSickRequestSuccess = (res) => {
  return {
    type: Types.UPSERT_SICK_REQUEST_SUCCESS,
    payload: {
      ...res,
    },
  };
};
// End Of Sick Leaves request modal action

// Start Of Sick Leaves request modal action

export const upsertCustomRequestAction = (data) => {
  return {
    type: Types.UPSERT_CUSTOM_REQUEST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const upsertCustomRequestSuccess = (res) => {
  return {
    type: Types.UPSERT_CUSTOM_REQUEST_SUCCESS,
    payload: {
      ...res,
    },
  };
};

export const upsertCustomRequestFailed = (error) => {
  return {
    type: Types.UPSERT_CUSTOM_REQUEST_FAILED,
    payload: error,
  };
};
// End Of Sick Leaves request modal action

// (Start) Fetch Payroll Extra Fields
export const fetchPayrollExtraFieldsSuccess = (data) => {
  return {
    type: Types.FETCH_PAYROLL_EXTRA_FIELDS_SUCCESS,
    payload: data,
  };
};
// (End) Fetch Payroll Extra Fields

// (Start) Fetch Payroll Closed Months
export const fetchPayrollClosedMonthsSuccess = ({ ref, data }) => {
  return {
    type: Types.FETCH_PAYROLL_CLOSED_MONTHS_SUCCESS,
    payload: { ref, data },
  };
};
// (End) Fetch Payroll Closed Months

// (Start) Edit Payroll Deductions actions
export const editPayrollDeductionsAction = (data) => {
  return {
    type: Types.EDIT_PAYROLL_DEDUCTIONS_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const editPayrollDeductionsSuccess = (data) => {
  return {
    type: Types.EDIT_PAYROLL_DEDUCTIONS_SUCCESS,
    payload: data,
  };
};

export const editPayrollDeductionsFailed = (data) => {
  return {
    type: Types.EDIT_PAYROLL_DEDUCTIONS_FAILED,
    payload: data,
  };
};
// (End) Edit Payroll Deductions actions

// Start Salary Config Form

export const updateSalaryOptions = (
  allowancesOptions,
  deductiblesOptions,
  monthOptions,
  paymentFactors
) => {
  return {
    type: Types.UPDTAE_SALARY_CONFIG_OPTIONS,
    payload: {
      allowancesOptions,
      deductiblesOptions,
      monthOptions,
      paymentFactors,
    },
  };
};

export const createNewAllowanceAction = (value, index) => {
  return {
    type: Types.CREATE_ALLOWANCE_ATTEMPT,
    payload: {
      value,
      index,
      loadingKey: "allowanceLoadingOpts",
    },
  };
};

export const createNewAllowanceSuccess = (option, index) => {
  return {
    type: Types.CREATE_ALLOWANCE_SUCCESS,
    payload: {
      option,
      index,
    },
  };
};

export const createNewAllowanceFailed = (index) => {
  return {
    type: Types.CREATE_ALLOWANCE_FAILED,
    payload: {
      index,
      loadingKey: "allowanceLoadingOpts",
    },
  };
};

export const createNewDeductibleAction = (value, index) => {
  return {
    type: Types.CREATE_DEDUCTIBLE_ATTEMPT,
    payload: {
      value,
      index,
      loadingKey: "deductibleLoadingOpts",
    },
  };
};

export const createNewDeductibleSuccess = (option, index) => {
  return {
    type: Types.CREATE_DEDUCTIBLE_SUCCESS,
    payload: {
      option,
      index,
    },
  };
};

export const createNewDeductibleFailed = (index) => {
  return {
    type: Types.CREATE_DEDUCTIBLE_FAILED,
    payload: {
      index,
      loadingKey: "deductibleLoadingOpts",
    },
  };
};

export const addAllowanceInputAction = () => {
  return {
    type: Types.ADD_ALLOWANCE_INPUT,
  };
};

export const removeAllowanceInputAction = (index) => {
  return {
    type: Types.REMOVE_ALLOWANCE_INPUT,
    payload: {
      index,
    },
  };
};

export const addDeductibleInputAction = () => {
  return {
    type: Types.ADD_DEDUCTIBLE_INPUT,
  };
};

export const removeDeductibleInputAction = (index) => {
  return {
    type: Types.REMOVE_DEDUCTIBLE_INPUT,
    payload: {
      index,
    },
  };
};

export const childInputChange = (formName, childName, name, index, value) => {
  return {
    type: Types.ON_CHILD_INPUT_CHANGE,
    payload: {
      formName,
      childName,
      name,
      index,
      value,
    },
  };
};

export const childCheckboxChange = (formName, childName, event, index) => {
  return {
    type: Types.ON_CHILD_CHECKBOX_CHANGE,
    payload: {
      formName,
      childName,
      event,
      index,
    },
  };
};

export const childInputChangeDocuments = (
  formName,
  childName,
  name,
  index,
  value
) => {
  return {
    type: Types.ON_CHILD_INPUT_CHANGE_DOCUMENTS,
    payload: {
      formName,
      childName,
      name,
      index,
      value,
    },
  };
};

export const resetDocumentsRow = (formName, childName, index, value) => {
  return {
    type: Types.ON_RESET_DOCUMENTS_ROW,
    payload: {
      formName,
      childName,
      index,
      value,
    },
  };
};

export const fetchRequiredDocumentsAttempt = (
  inputType,
  hasEmployeesPrivileges
) => {
  return {
    type: Types.FETCH_REQUIRED_DOCUMENTS_ATTEMPT,
    payload: {
      inputType,
      hasEmployeesPrivileges,
    },
  };
};

export const fetchRequiredDocumentsSuccess = (data) => {
  return {
    type: Types.FETCH_REQUIRED_DOCUMENTS_SUCCESS,
    payload: data,
  };
};

export const fetchRequiredDocumentsFailed = () => {
  return {
    type: Types.FETCH_REQUIRED_DOCUMENTS_FAILED,
  };
};

export const updateCompanyRowsDocs = ({
  fetchedCompanyRef,
  documentable,
  requiredDocuments,
  optionalDocuments,
}) => {
  return {
    type: Types.UPDATE_COMPANY_ROW_DOCS,
    payload: {
      fetchedCompanyRef,
      documentable,
      requiredDocuments,
      optionalDocuments,
    },
  };
};

export const multiIndexInputChange = (formName, childName, index, value) => {
  return {
    type: Types.ON_MULTI_INPUT_INDEX_CHANGE,
    payload: {
      formName,
      childName,
      index,
      value,
    },
  };
};

export const resetSalaryConfigFormAction = (salary_management_type = 0) => {
  return {
    type: Types.RESET_SALARY_CONFIG_FORM,
    payload: { salary_management_type },
  };
};

export const createNewEmployeeSalaryAction = (
  user_salary_config_input,
  user_input
) => {
  return {
    type: Types.CREATE_NEW_EMPLOYEE_SALARY_ATTEMPT,
    payload: {
      user_salary_config_input,
      user_input,
    },
  };
};

export const createNewEmployeeSalarySuccess = (response) => {
  return {
    type: Types.CREATE_NEW_EMPLOYEE_SALARY_SUCCESS,
    payload: {
      response,
    },
  };
};

export const createNewEmployeeSalaryFailed = (serverError = null) => {
  return {
    type: Types.CREATE_NEW_EMPLOYEE_SALARY_FAILED,
    payload: serverError,
  };
};

export const initSalaryConfig = (office_id, isNew, salaryConfig, countryId) => {
  return {
    type: Types.INIT_UPSERT_SALARY_CONFIG_FORM,
    payload: { office_id, isNew, salaryConfig, countryId },
  };
};

export const initSalaryConfigFailed = () => {
  return {
    type: Types.INIT_UPSERT_SALARY_CONFIG_FORM_FAILED,
  };
};

export const showNewUpsertSalaryConfigSuccess = (data) => {
  return {
    type: Types.SHOW_NEW_UPSERT_SALARY_CONFIG_FORM_SUCCESS,
    payload: { ...data },
  };
};

export const initActivateNewSalaryConfigSuccess = (data) => {
  return {
    type: Types.INIT_ACTIVATE_SALARY_CONFIG_SUCCESS,
    payload: { ...data },
  };
};

export const initActivateNewSalaryConfigFailed = () => {
  return {
    type: Types.INIT_ACTIVATE_SALARY_CONFIG_FAILED,
  };
};

export const showEditUpsertSalaryConfigSuccess = (data) => {
  return {
    type: Types.SHOW_EDIT_UPSERT_SALARY_CONFIG_FORM_SUCCESS,
    payload: { ...data },
  };
};

export const upsertSalaryConfigAction = (user_salary_config_input) => {
  return {
    type: Types.UPSERT_SALARY_CONFIG_ATTEMPT,
    payload: { user_salary_config_input },
  };
};

export const upsertSalaryConfigSuccess = () => {
  return {
    type: Types.UPSERT_SALARY_CONFIG_SUCCESS,
  };
};

export const upsertSalaryConfigFailed = (serverError = null) => {
  return {
    type: Types.UPSERT_SALARY_CONFIG_FAILED,
    payload: serverError,
  };
};

export const dismissUpsertSalaryConfig = () => {
  return {
    type: Types.DISMISS_UPSERT_SALARY_CONFIG_FORM,
  };
};

// End Salary Config Form

// (Start) Reverse Payment actions
export const reversePayrollPaymentAction = (data) => {
  return {
    type: Types.REVERSE_PAYROLL_PAYMENT_ATTEMPT,
    payload: data,
  };
};

export const reversePaymentSuccess = (data) => {
  return {
    type: Types.REVERSE_PAYROLL_PAYMENT_SUCCESS,
    payload: data,
  };
};

export const reversePaymentFailed = (data) => {
  return {
    type: Types.REVERSE_PAYROLL_PAYMENT_FAILED,
    payload: data,
  };
};
// (End) Reverse Payment actions

// (Start) Update Payroll Payment
export const submitPayrollPaymentAction = (data) => {
  return {
    type: Types.SUBMIT_PAYROLL_PAYMENT_ATTEMPT,
    payload: data,
  };
};

export const submitPaymentSuccess = (data) => {
  return {
    type: Types.SUBMIT_PAYROLL_PAYMENT_SUCCESS,
    payload: data,
  };
};

export const SubmitPaymentFailed = (data) => {
  return {
    type: Types.SUBMIT_PAYROLL_PAYMENT_FAILED,
    payload: data,
  };
};

// (End) Update Payroll Payment

// Start of user payslip actions
export const fetchUserPaySlipAction = (data) => {
  return {
    type: Types.FETCH_USER_PAYSLIP_ATTEMPT,
    payload: data,
  };
};

export const fetchUserPaySlipActionSuccess = (data) => {
  return {
    type: Types.FETCH_USER_PAYSLIP_SUCCESS,
    payload: data,
  };
};

export const fetchUserPaySlipActionFailed = () => {
  return {
    type: Types.FETCH_USER_PAYSLIP_FAILED,
  };
};

// End of user payslip actions

// Start of Add Documents Modal actions
export const showAddDocumentsModalAction = (data) => {
  return {
    type: Types.SHOW_DOCUMENT_MODAL,
    payload: data,
  };
};

export const dismissAddDocumentsModalAction = (data) => {
  return {
    type: Types.HIDE_DOCUMENT_MODAL,
    payload: data,
  };
};
// End of Add Documents Modal actions

// Start of Edit Documents Modal actions
export const showEditDocumentModalAction = (data) => {
  return {
    type: Types.SHOW_EDIT_DOCUMENT_MODAL,
    payload: data,
  };
};

export const dismissEditDocumentsModalAction = (data) => {
  return {
    type: Types.HIDE_EDIT_DOCUMENT_MODAL,
    payload: data,
  };
};
// End of Edit Documents Modal actions

// Start of fetch holiday offices action
export const fetchHolidayOfficesAction = (holidayOfficess) => {
  return {
    type: Types.FETCH_HOLIDAY_OFFICES,
    payload: holidayOfficess,
  };
};
// End of fetch holiday offices action

// Start of Init Office
export const initOfficeAction = () => {
  return {
    type: Types.INIT_OFFICE_ATTEMPT,
  };
};

export const initOfficeActionSuccess = (id) => {
  return {
    type: Types.INIT_OFFICE_SUCCESS,
    payload: { id },
  };
};

export const initOfficeActionFailed = () => {
  return {
    type: Types.INIT_OFFICE_FAILED,
  };
};
// End of Init Office

// Start of Accept overtime Request modal actions
export const showOvertimeModalAction = (data) => {
  return {
    type: Types.SHOW_OVERTIME_MODAL,
    payload: data,
  };
};
export const dismissOvertimeModalAction = (data) => {
  return {
    type: Types.HIDE_OVERTIME_MODAL,
    payload: data,
  };
};
// End of Accept overtime Request modal actions

// Start of Edit accepted overtime request actions
export const fetchEditAccpetedOvertimeRequestDataAction = (request_id) => {
  return {
    type: Types.FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_ATTEMPT,
    payload: request_id,
  };
};

export const fetchEditAccpetedOvertimeRequestDataSuccess = (data) => {
  return {
    type: Types.FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_SUCCESS,
    payload: data,
  };
};

export const fetchEditAccpetedOvertimeRequestDataFailed = () => {
  return {
    type: Types.FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_FAILED,
  };
};
// End of Edit accepted overtime request actions

// Start of fetch holiday Attendnace profiles action
export const fetchHolidayAttProfilesAction = (holidayAttProfiles) => {
  return {
    type: Types.FETCH_HOLIDAY_ATT_PROFILE,
    payload: holidayAttProfiles,
  };
};
// End of fetch holiday Attendnace profiles action

// Start Fetch Export Payroll Options
export const fetchExportPayrollOptionsAction = () => {
  return {
    type: Types.FETCH_EXPORT_PAYROLL_OPTIONS_ATTEMPT,
  };
};

export const fetchExportPayrollOptionsSuccess = (data) => {
  return {
    type: Types.FETCH_EXPORT_PAYROLL_OPTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchExportPayrollOptionsFailed = () => {
  return {
    type: Types.FETCH_EXPORT_PAYROLL_OPTIONS_FAILED,
  };
};

export const dismissExportPayrollOptions = () => {
  return {
    type: Types.DISMISS_EXPORT_PAYROLL_MODAL_ACTION,
  };
};
// End Fetch Export Payroll Options

// Start Submit Export Payroll
export const submitExportPayrollAction = (formData) => {
  return {
    type: Types.SUBMIT_EXPORT_PAYROLL_ATTEMPT,
    payload: formData,
  };
};

export const submitExportPayrollSuccess = (data) => {
  return {
    type: Types.SUBMIT_EXPORT_PAYROLL_SUCCESS,
  };
};

export const submitExportPayrollFailed = () => {
  return {
    type: Types.SUBMIT_EXPORT_PAYROLL_FAILED,
  };
};
// End Submit Export Payroll

// Start Submit Export Payslip
export const exportPayslipAction = (user_id, month_year) => {
  return {
    type: Types.EXPORT_PAYSLIP_ACTION,
    payload: {
      user_id,
      month_year,
    },
  };
};

export const exportPayslipSuccess = () => {
  return {
    type: Types.EXPORT_PAYSLIP_SUCCESS,
  };
};

export const exportPayslipFailed = () => {
  return {
    type: Types.EXPORT_PAYSLIP_FAILED,
  };
};

// End Submit Export Payslip

// Start Upsert User Language
export const upsertUserLocaleAction = (user_id, locale) => {
  return {
    type: Types.UPSERT_USER_LOCALE_ATTEMPT,
    payload: {
      user_id,
      locale,
    },
  };
};

export const upsertUserLocaleSuccess = (user) => {
  return {
    type: Types.UPSERT_USER_LOCALE_SUCCESS,
    payload: { user },
  };
};

export const upsertUserLocaleFailed = () => {
  return {
    type: Types.UPSERT_USER_LOCALE_FAILED,
  };
};
// End Upsert User Language

export const toggleMonthlyReportCheckbox = ({ ref, value }) => {
  return {
    type: Types.TOGGLE_MONTHLY_REPORT_CHECKBOX,
    payload: { ref, value },
  };
};

export const toggleYearlyReportCheckbox = ({ ref, value }) => {
  return {
    type: Types.TOGGLE_YEARLY_REPORT_CHECKBOX,
    payload: { ref, value },
  };
};

// Start user phone verification
export const verifyUSerPhoneAction = (phone_token) => {
  return {
    type: Types.VERIFY_USER_PHONE_ATTEMPT,
    payload: {
      phone_token,
    },
  };
};

export const verifyUSerPhoneSuccess = () => {
  return {
    type: Types.VERIFY_USER_PHONE_SUCCESS,
  };
};

export const verifyUSerPhoneFailed = () => {
  return {
    type: Types.VERIFY_USER_PHONE_FAILED,
  };
};

export const sendPhoneVerificationAction = (
  phone = null,
  user_id = null,
  force_send = false,
  send_via
) => {
  return {
    type: Types.SEND_PHONE_VERIFICATION_ATTEMPT,
    payload: {
      phone,
      user_id,
      force_send,
      send_via,
    },
  };
};

export const sendPhoneVerificationSuccess = () => {
  return {
    type: Types.SEND_PHONE_VERIFICATION_SUCCESS,
  };
};

export const sendPhoneVerificationFailed = () => {
  return {
    type: Types.SEND_PHONE_VERIFICATION_FAILED,
  };
};
// End user phone verification

// start of user Email verification
export const verifyUserEmailAction = (email_token) => {
  return {
    type: Types.VERIFY_USER_EMAIL_ATTEMPT,
    payload: {
      email_token,
    },
  };
};

export const verifyUserEmailSuccess = () => {
  return {
    type: Types.VERIFY_USER_EMAIL_SUCCESS,
  };
};

export const verifyUserEmailFailed = () => {
  return {
    type: Types.VERIFY_USER_EMAIL_FAILED,
  };
};

export const sendEmailVerificationAction = (
  email = null,
  user_id = null,
  force_send = false
) => {
  return {
    type: Types.SEND_EMAIL_VERIFICATION_ATTEMPT,
    payload: {
      email,
      user_id,
      force_send,
    },
  };
};

export const sendEmailVerificationSuccess = () => {
  return {
    type: Types.SEND_EMAIL_VERIFICATION_SUCCESS,
  };
};

export const sendEmailVerificationFailed = () => {
  return {
    type: Types.SEND_EMAIL_VERIFICATION_FAILED,
  };
};
// End of user Email verification

// handle add && remove violations

export const addViolationAction = (formName, childName, penalty_type) => {
  return {
    type: Types.ADD_VIOLATION_INPUT,
    payload: { formName, childName, penalty_type },
  };
};

export const removeViolationAction = (formName, childName, index) => {
  return {
    type: Types.REMOVE_VIOLATION_INPUT,
    payload: {
      formName,
      childName,
      index,
    },
  };
};

// hanlde fetch work timings list

export const fetchWorkTimingsListAction = ({ ...data }) => {
  return {
    type: Types.FETCH_WORK_TIMINGS_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};
export const fetchWorkTimingsListSuccess = (ref, list, sorting) => {
  return {
    type: Types.FETCH_WORK_TIMINGS_LIST_SUCCESS,
    payload: {
      ref,
      list,
      sorting,
    },
  };
};
export const fetchWorkTimingsListFailed = (ref) => {
  return {
    type: Types.FETCH_WORK_TIMINGS_LIST_FAILED,
    payload: {
      ref,
    },
  };
};

// upsert work timing action

export const showWorkTimingModalAction = (
  createdFrom,
  formName,
  workTimeIndex,
  which_half,
  normal_work_timing_id
) => {
  return {
    type: Types.SHOW_WORK_TIMINGS_MODAL,
    payload: {
      createdFrom,
      formName,
      workTimeIndex,
      which_half,
      normal_work_timing_id,
    },
  };
};

export const dismissWorkTimingModalAction = () => {
  return {
    type: Types.DISMISS_WORK_TIMINGS_MODAL,
  };
};

export const upsertWorkTimingsAction = (
  workTimingForm,
  parentFormName = null,
  workTimeIndex
) => {
  return {
    type: Types.UPSERT_WORK_TIMINGS_ATTEMPT,
    payload: {
      workTimingForm,
      parentFormName,
      workTimeIndex,
    },
  };
};
export const upsertWorkTimingsSuccess = (
  res,
  parentFormName = null,
  workTimeIndex = 0
) => {
  return {
    type: Types.UPSERT_WORK_TIMINGS_SUCCESS,
    payload: {
      res,
      parentFormName,
      workTimeIndex,
    },
  };
};
export const upsertWorkTimingsFailed = (errors) => {
  return {
    type: Types.UPSERT_WORK_TIMINGS_FAILED,
    payload: {
      errors,
    },
  };
};

export const editFetchWorkTimeAction = (id) => {
  return {
    type: Types.EDIT_FETCH_WORK_TIMINGS_ATTEMPT,
    payload: {
      id,
    },
  };
};
export const editFetchWorkTimeSuccess = (workTime) => {
  return {
    type: Types.EDIT_FETCH_WORK_TIMINGS_SUCCESS,
    payload: {
      workTime,
    },
  };
};
export const editFetchWorkTimeFailed = () => {
  return {
    type: Types.EDIT_FETCH_WORK_TIMINGS_FAILED,
  };
};

export const addNewWorkTimeConfigAction = (formName) => {
  return {
    type: Types.ADD_NEW_WORK_TIME_CONFIG,
    payload: { formName },
  };
};

// Start of fetch half work timing template actions
export const fetchSuggestedHalfWorkTimingData = (id, which_half) => {
  return {
    type: Types.FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_ATTEMPT,
    payload: {
      id,
      which_half,
    },
  };
};

export const fetchSuggestedHalfWorkTimingDataSuccess = (data) => {
  return {
    type: Types.FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_SUCCESS,
    payload: data,
  };
};

export const fetchSuggestedHalfWorkTimingDataFailed = () => {
  return {
    type: Types.FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_FAILED,
  };
};
// End of fetch half work timing template actions

// (Start) Export Monthly Report
export const exportMonthlyReportAction = ({
  ref,
  exportColumns,
  filterOptions,
}) => {
  return {
    type: Types.EXPORT_MONTHLY_REPORT_ATTEMPT,
    payload: {
      ref,
      exportColumns,
      filterOptions,
    },
  };
};

export const exportMonthlyReportSuccess = (ref) => {
  return {
    type: Types.EXPORT_MONTHLY_REPORT_SUCCESS,
    payload: { ref },
  };
};

export const exportMonthlyReportFailed = (ref) => {
  return {
    type: Types.EXPORT_MONTHLY_REPORT_FAILED,
    payload: { ref },
  };
};

export const showExportMonthlyReportModal = ({ ref }) => {
  return {
    type: Types.SHOW_EXPORT_MONTHLY_REPORT_MODAL,
    payload: { ref },
  };
};

export const hideExportMonthlyReportModal = ({ ref }) => {
  return {
    type: Types.HIDE_EXPORT_MONTHLY_REPORT_MODAL,
    payload: { ref },
  };
};
// (End) Export Monthly Report

// Start Export Yearly Report
export const exportYearlyReportAction = ({
  ref,
  exportColumns,
  filterOptions,
}) => {
  return {
    type: Types.EXPORT_YEARLY_REPORT_ATTEMPT,
    payload: {
      ref,
      exportColumns,
      filterOptions,
    },
  };
};

export const exportYearlyReportSuccess = (ref) => {
  return {
    type: Types.EXPORT_YEARLY_REPORT_SUCCESS,
    payload: { ref },
  };
};

export const exportYearlyReportFailed = (ref) => {
  return {
    type: Types.EXPORT_YEARLY_REPORT_FAILED,
    payload: { ref },
  };
};

export const showExportYearlyReportModal = ({ ref }) => {
  return {
    type: Types.SHOW_EXPORT_YEARLY_REPORT_MODAL,
    payload: { ref },
  };
};

export const hideExportYearlyReportModal = ({ ref }) => {
  return {
    type: Types.HIDE_EXPORT_YEARLY_REPORT_MODAL,
    payload: { ref },
  };
};
// End Export Yearly Report

// activation moda
export const resetActivationAttendance = (auto = 0) => {
  return {
    type: Types.RESET_ACTICATION_ATTENDANCE_FORM,
    payload: { auto },
  };
};

export const fetchActivationAttendanceAction = (
  user_id,
  activation_date,
  attendanceProfileId
) => {
  return {
    type: Types.FETCH_ACTIVATION_ATTENDANCE_ATTEMPT,
    payload: { user_id, activation_date, attendanceProfileId },
  };
};

export const fetchActivationAttendanceSuccess = (data) => {
  return {
    type: Types.FETCH_ACTIVATION_ATTENDANCE_SUCCESS,
    payload: data,
  };
};

export const fetchActivationAttendanceFailed = (data) => {
  return {
    type: Types.FETCH_ACTIVATION_ATTENDANCE_FAILED,
    payload: data,
  };
};

// salary config presets
export const fetchSalaryPresetAction = (id) => {
  return {
    type: Types.FETCH_SALARY_PRESET_ATTEMPT,
    payload: id,
  };
};

export const fetchSalaryPresetSuccess = (presetData) => {
  return {
    type: Types.FETCH_SALARY_PRESET_SUCCESS,
    payload: presetData,
  };
};

export const fetchSalaryPresetFailed = () => {
  return {
    type: Types.FETCH_SALARY_PRESET_FAILED,
  };
};

// (Start) Upsert Document Modal
const upsertDocModalRef = "upsertDocModalActions";
const upsertDocModalFormName = "upsertDocModal";
export const showUpsertDocModal = (data = {}) => {
  return {
    type: Types.SHOW_COMPANY_DOCUMENT_MODAL,
    payload: { ref: upsertDocModalRef, formName: upsertDocModalFormName, data },
  };
};
export const hideUpsertDocModal = () => {
  return {
    type: Types.HIDE_COMPANY_DOCUMENT_MODAL,
    payload: { ref: upsertDocModalRef, formName: upsertDocModalFormName },
  };
};

export const upsertDocumentAttempt = ({ input, company_id }) => ({
  type: Types.UPSERT_DOCUMENT_ATTEMPT,
  payload: { ref: upsertDocModalRef, input, company_id },
});
export const upsertDocumentSuccess = (data) => ({
  type: Types.UPSERT_DOCUMENT_SUCCESS,
  payload: { ref: upsertDocModalRef, formName: "documentsListOptions", data },
});
export const upsertDocumentFailed = (message) => ({
  type: Types.UPSERT_DOCUMENT_FAILED,
  payload: { ref: upsertDocModalRef, message },
});
// (End) Upsert Document Modal

// (Start) History Document Modal
export const fetchHistoryDocModalAttempt = ({
  ref,
  document_id,
  employee_id,
}) => ({
  type: Types.FETCH_HISTORY_DOC_MODAL_ATTEMPT,
  payload: { ref, document_id, employee_id },
});

export const fetchHistoryDocModalSuccess = ({ ref, formName, data = [] }) => {
  return {
    type: Types.FETCH_HISTORY_DOC_MODAL_SUCCESS,
    payload: { ref, formName: "upsertDocsHistory", data },
  };
};

export const fetchHistoryDocModalFailed = ({ ref, message }) => {
  return {
    type: Types.FETCH_HISTORY_DOC_MODAL_FAILED,
    payload: { ref, message },
  };
};
// (End) History Document Modal

// (End) Main Modal Actions
export const showMainsModalWithData = ({ ref, formName, data }) => {
  return {
    type: Types.SHOW_MAIN_MODAL_WITH_DATA,
    payload: { ref, formName, data },
  };
};

export const hideMainsModalWithData = ({ ref, formName }) => {
  return {
    type: Types.HIDE_MAIN_MODAL_WITH_DATA,
    payload: { ref, formName },
  };
};
// (End) Main Modal Actions

// (Start) Fetch Company List Documents
const companyDocsActionsRef = "documentsListActions";
export const fetchDocumentsListAttempt = ({
  company_id,
  hasEmployeesPrivileges,
}) => ({
  type: Types.FETCH_DOCUMENTS_LIST_ATTEMPT,
  payload: { ref: companyDocsActionsRef, company_id, hasEmployeesPrivileges },
});
export const fetchDocumentsListSuccess = (data) => ({
  type: Types.FETCH_DOCUMENTS_LIST_SUCCESS,
  payload: { data },
});
export const fetchDocumentsListFailed = (message) => ({
  type: Types.FETCH_DOCUMENTS_LIST_FAILED,
  payload: { ref: companyDocsActionsRef, message },
});
// (End) Fetch Company List Documents

// (Start) Store Documentable
export const storeDocumentableAttempt = ({
  input,
  resetInputData,
  formName,
  ref,
}) => ({
  type: Types.STORE_DOCUMENTABLE_ATTEMPT,
  payload: { ref, input, resetInputData, formName },
});
export const storeDocumentableSuccess = ({ data, formName, ref }) => ({
  type: Types.STORE_DOCUMENTABLE_SUCCESS,
  payload: { ref, data, formName },
});
export const storeDocumentableFailed = ({ ref }) => ({
  type: Types.STORE_DOCUMENTABLE_FAILED,
  payload: { ref },
});

export const storeDocumentableRowAttempt = ({
  formName,
  childName,
  fetchedCompanyRef,
  name,
  index,
  value,
  input,
  resetData,
  actionType = "",
}) => {
  return {
    type: Types.STORE_DOCUMENTABLE_ROW_ATTEMPT,
    payload: {
      formName,
      childName,
      fetchedCompanyRef,
      name,
      index,
      value,
      input,
      resetData,
      actionType,
    },
  };
};
export const childInputChangeDocumentsMultiple = ({
  formName,
  childName,
  name,
  index,
  value,
}) => ({
  type: Types.ON_CHILD_INPUT_CHANGE_DOCUMENTS_MULTIPLE,
  payload: { formName, childName, name, index, value },
});

export const showDocumentsEdit = ({ ref }) => ({
  type: Types.SHOW_DOCUMENTS_EDIT,
  payload: { ref },
});
export const hideDocumentsEdit = ({ ref }) => ({
  type: Types.HIDE_DOCUMENTS_EDIT,
  payload: { ref },
});

export const handleRowDocumentAction = ({
  action,
  formName,
  childName,
  index,
}) => ({
  type: Types.HANDLE_ROW_DOCUMENT,
  payload: { action, formName, childName, index },
});
// (End) Store Documentable
export const fetchWorktimeAction = (data) => {
  return {
    type: Types.FETCH_ALL_WORK_TIME_OPTIONS,
    payload: data,
  };
};

// Handle Leaves Breaks Profiles
export const UpdateLeavesBreaksOptions = (data) => {
  return {
    type: Types.UPDATE_LEAVES_BREAKS_OPTIONS,
    payload: data,
  };
};

export const showLeavesBreaksModal = (data) => {
  return {
    type: Types.SHOW_LEAVES_BREAKS_MODAL,
    payload: {
      data,
    },
  };
};

export const setLeavesBreaksServerValidation = (data) => {
  return {
    type: Types.SET_LEAVES_BREAKS_SERVER_VALIDATION,
    payload: {
      data,
    },
  };
};

export const resetLeavesBreaksForm = () => {
  return {
    type: Types.RESET_LEAVES_BREAKS_FORM,
  };
};

// HANDLE VIEW WORKTIME

export const viewWorkTimeAction = (id) => {
  return {
    type: Types.VIEW_WORK_TIME_ATTEMPT,
    payload: { id },
  };
};

export const viewWorkTimeSuccess = (workTime) => {
  return {
    type: Types.VIEW_WORK_TIME_SUCCESS,
    payload: { workTime },
  };
};
export const viewWorkTimeFailed = () => {
  return {
    type: Types.VIEW_WORK_TIME_FAILED,
  };
};

export const dismissViewWorkTime = () => {
  return {
    type: Types.DISMISS_VIEW_WORK_TIME,
  };
};

// CLONE - EDIT WORKTIME

export const deleteWorkTimeAction = (data) => ({
  type: Types.DELETE_WORK_TIME_ATTEMPT,
  payload: data,
});

export const deleteWorkTimeSuccess = () => ({
  type: Types.DELETE_WORK_TIME_SUCCESS,
});

export const deleteWorkTimeFailed = () => ({
  type: Types.DELETE_WORK_TIME_FAILED,
});

export const cloneWorkTimeAction = (data) => ({
  type: Types.CLONE_WORK_TIME_ATTEMPT,
  payload: data,
});

export const cloneWorkTimeSuccess = () => ({
  type: Types.CLONE_WORK_TIME_SUCCESS,
});

export const cloneWorkTimeFailed = () => ({
  type: Types.CLONE_WORK_TIME_FAILED,
});

export const resetOldSignInConfig = (formName, id) => {
  return {
    type: Types.RESET_OLD_SIGN_IN_CONFIG_ACTION,
    payload: {
      formName,
      id,
    },
  };
};

export const removeSignInConfig = (formName, index) => {
  return {
    type: Types.REMOVE_SIGN_IN_CONFIG_ACTION,
    payload: {
      formName,
      index,
    },
  };
};
// Start of fetch Email Notifications settings actions
export const fetchEmailNotificationsAction = (refetch = false) => {
  return {
    type: Types.FETCH_EMAIL_NOTFIFCATIONS_ATTEMPT,
    payload: { refetch },
  };
};

export const fetchEmailNotificationsSuccess = (data) => {
  return {
    type: Types.FETCH_EMAIL_NOTFIFCATIONS_SUCCESS,
    payload: data,
  };
};

export const fetchEmailNotificationsFailed = () => {
  return {
    type: Types.FETCH_EMAIL_NOTFIFCATIONS_FAILED,
  };
};

export const updateEmailNotificationsAction = (id, type, status) => ({
  type: Types.UPDATE_EMAIL_NOTIFICATIONS_ATTEMPT,
  payload: {
    id,
    type,
    status,
  },
});

export const updateEmailNotificationsSuccess = (data) => ({
  type: Types.UPDATE_EMAIL_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const updateEmailNotificationsFailed = (data) => ({
  type: Types.UPDATE_EMAIL_NOTIFICATIONS_FAILED,
  payload: data,
});
// End of fetch Email Notifications settings actions

// Start of fetch companies actions
export const fetchAllCompaniesAction = (data) => {
  return {
    type: Types.FETCH_ALL_COMPANIES_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchAllCompaniesSuccess = (ref, list) => {
  return {
    type: Types.FETCH_ALL_COMPANIES_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchAllCompaniesFailed = (ref, list) => {
  return {
    type: Types.FETCH_ALL_COMPANIES_FAILED,
    payload: {
      ref,
      list,
    },
  };
};
// End of fetch companies actions

// Start of delete companies actions
export const deleteCompaniesAction = (data) => ({
  type: Types.DELETE_COMPANIES_ATTEMPT,
  payload: data,
});

export const deleteCompaniesSuccess = () => ({
  type: Types.DELETE_COMPANIES_SUCCESS,
});

export const deleteCompaniesFailed = () => ({
  type: Types.DELETE_COMPANIES_FAILED,
});
// End of delete companies actions

// Start of activate companies actions
export const activateCompaniesAction = (company_id) => {
  return {
    type: Types.ACTIVATE_COMPANIES_ATTEMPT,
    payload: { company_id, company_status: "activate" },
  };
};

export const activateCompaniesSuccess = () => {
  return {
    type: Types.ACTIVATE_COMPANIES_SUCCESS,
  };
};

export const activateCompaniesFailed = () => ({
  type: Types.ACTIVATE_COMPANIES_FAILED,
});
// End of activate companies actions

// Start of suspend companies actions
export const suspendCompaniesAction = (company_id) => {
  return {
    type: Types.SUSPEND_COMPANIES_ATTEMPT,
    payload: { company_id, company_status: "suspend" },
  };
};

export const suspendCompaniesSuccess = () => {
  return {
    type: Types.SUSPEND_COMPANIES_SUCCESS,
  };
};

export const suspendCompaniesFailed = () => ({
  type: Types.SUSPEND_COMPANIES_FAILED,
});
// End of suspend companies actions

// Start of upsert comapny modal action
export const showCompanyModalAction = (data) => {
  return {
    type: Types.SHOW_COMPANY_MODAL,
    payload: data,
  };
};

export const dismissCompanyModalAction = (data) => {
  return {
    type: Types.HIDE_COMPANY_MODAL,
    payload: data,
  };
};

export const upsertCompaniesAction = (data) => ({
  type: Types.UPSERT_COMPANY_ATTEMPT,
  payload: data,
});

export const upsertCompaniesSuccess = (ref) => ({
  type: Types.UPSERT_COMPANY_SUCCESS,
  payload: { ref },
});

export const upsertCompaniesFailed = (error) => ({
  type: Types.UPSERT_COMPANY_FAILED,
  payload: {
    ...error,
  },
});

export const updateCompaniesAction = (data) => ({
  type: Types.UPDATE_COMPANY_ATTEMPT,
  payload: data,
});

export const updateCompaniesSuccess = (ref) => ({
  type: Types.UPDATE_COMPANY_SUCCESS,
  payload: { ref },
});

export const updateCompaniesFailed = (error) => ({
  type: Types.UPDATE_COMPANY_FAILED,
  payload: {
    ...error,
  },
});

export const addBusinessPartnersInputAction = (company_id) => {
  return {
    type: Types.ADD_BUSSINESS_PARTNER_INPUT,
    payload: {
      company_id,
    },
  };
};

export const removeBusinessPartnersInputAction = (index) => {
  return {
    type: Types.REMOVE_BUSSINESS_PARTNER_INPUT,
    payload: {
      index,
    },
  };
};
// End of upsert comapny modal action

// Start of fetch company account managers action
export const fetchCompanyAccountManagersAction = (accountManagers) => {
  return {
    type: Types.FETCH_COMPANY_ACCOUNT_MANAGERS,
    payload: accountManagers,
  };
};
// End of fetch company account managers action

// Start of fetch company subscription plans action
export const fetchSubscriptionPlansAction = (subPlans) => {
  return {
    type: Types.FETCH_SUBSCRIPTION_PLANS,
    payload: subPlans,
  };
};
// End of fetch company subscription plans action

// Start of fetch company currency action
export const fetchCompanyCurrencyAction = (compCurrency) => {
  return {
    type: Types.FETCH_COMPANY_CURRENCY,
    payload: compCurrency,
  };
};
// End of fetch company currency action

// Start of fetch Company Profile actions
export const fetchCompanyProfileAction = ({ ref, ...rest }) => {
  return {
    type: Types.FETCH_COMPANY_PROFILE_ATTEMPT,
    payload: {
      ref,
      ...rest,
    },
  };
};

export const fetchCompanyProfileSuccess = (company) => {
  return {
    type: Types.FETCH_COMPANY_PROFILE_SUCCESS,
    payload: { company },
  };
};

export const fetchCompanyProfileFailed = () => {
  return {
    type: Types.FETCH_COMPANY_PROFILE_FAILED,
  };
};

export const resetCompanyProfileViewAction = () => {
  return {
    type: Types.RESET_COMPANY_PRFOILE_VIEW,
  };
};

export const fetchCompanyProfileSubscriptionsAction = ({ ...options }) => {
  return {
    type: Types.FETCH_COMPANY_PROFILE_SUPSCRIPTIONS_ATTEMPT,
    payload: {
      ...options,
    },
  };
};

export const fetchCompanyProfileSubscriptionsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_COMPANY_PROFILE_SUPSCRIPTIONS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchCompanyProfileSubscriptionsFailed = (ref, error) => {
  return {
    type: Types.FETCH_COMPANY_PROFILE_SUPSCRIPTIONS_FAILED,
    payload: {
      ref,
      error,
    },
  };
};
// End of fetch Company Profile actions

// Start of update comapny name modal action
export const showEditCompanyNameModalAction = (data) => {
  return {
    type: Types.SHOW_EDIT_COMPANY_NAME_MODAL,
    payload: data,
  };
};

export const dismissEditCompanyNameModalAction = (data) => {
  return {
    type: Types.HIDE_EDIT_COMPANY_NAME_MODAL,
    payload: data,
  };
};

export const updateCompanyNameAction = (data) => ({
  type: Types.UPDATE_COMPANY_NAME_MODAL_ATTEMPT,
  payload: data,
});

export const updateCompanyNameActionSuccess = (ref) => ({
  type: Types.UPDATE_COMPANY_NAME_MODAL_SUCCESS,
  payload: { ref },
});

export const updateCompanyNameActionFailed = (error) => ({
  type: Types.UPDATE_COMPANY_NAME_MODAL_FAILED,
  payload: {
    ...error,
  },
});
// End of update comapny name modal action

// Start of update comapny account managers modal action
export const showEditAccountManagersModalAction = (data) => {
  return {
    type: Types.SHOW_EDIT_ACCOUNT_MANAGERS_MODAL,
    payload: data,
  };
};

export const dismissEditAccountManagersModalAction = (data) => {
  return {
    type: Types.HIDE_EDIT_ACCOUNT_MANAGERS_MODAL,
    payload: data,
  };
};

export const updateCompanyAccountManagersAction = (data) => ({
  type: Types.UPDATE_ACCOUNT_MANAGERS_MODAL_ATTEMPT,
  payload: data,
});

export const updateCompanyAccountManagersSuccess = (ref) => ({
  type: Types.UPDATE_ACCOUNT_MANAGERS_MODAL_SUCCESS,
  payload: { ref },
});

export const updateCompanyAccountManagersFailed = (error) => ({
  type: Types.UPDATE_ACCOUNT_MANAGERS_MODAL_FAILED,
  payload: {
    ...error,
  },
});
// End of update comapny account managers modal action

// Start of update comapny bussiness partners modal action
export const showEditBussPartnersModalAction = (data) => {
  return {
    type: Types.SHOW_EDIT_BUSSINESS_PARTNERS_MODAL,
    payload: data,
  };
};

export const dismissEditBussPartnersModalAction = (data) => {
  return {
    type: Types.HIDE_EDIT_BUSSINESS_PARTNERS_MODAL,
    payload: data,
  };
};

export const addBusinessPartnersInputInProfileAction = (company_id) => {
  return {
    type: Types.ADD_BUSSINESS_PARTNER_INPUT_IN_PROFILE,
    payload: {
      company_id,
    },
  };
};

export const removeBusinessPartnersInputInProfileAction = (index) => {
  return {
    type: Types.REMOVE_BUSSINESS_PARTNER_INPUT_IN_PROFILE,
    payload: {
      index,
    },
  };
};

export const updateCompanyBussPartnersAction = (data) => ({
  type: Types.UPDATE_BUSSINESS_PARTNERS_MODAL_ATTEMPT,
  payload: data,
});

export const updateCompanyBussPartnersSuccess = (ref) => ({
  type: Types.UPDATE_BUSSINESS_PARTNERS_MODAL_SUCCESS,
  payload: { ref },
});

export const updateCompanyBussPartnersFailed = (error) => ({
  type: Types.UPDATE_BUSSINESS_PARTNERS_MODAL_FAILED,
  payload: {
    ...error,
  },
});

export const deleteCompanyBussPartnersAction = (data) => ({
  type: Types.DELETE_COMPANY_BUSSINESS_PARTNERS_ATTEMPT,
  payload: data,
});

export const deleteCompanyBussPartnersSuccess = (response) => ({
  type: Types.DELETE_COMPANY_BUSSINESS_PARTNERS_SUCCESS,
  payload: response,
});

export const deleteCompanyBussPartnersFailed = () => ({
  type: Types.DELETE_COMPANY_BUSSINESS_PARTNERS_FAILED,
});
// End of update comapny bussiness partners modal action

// Start of edit comapny bussiness partner modal action
export const showUpdateBussPartnerModalAction = (data) => {
  return {
    type: Types.SHOW_UPDATE_BUSSINESS_PARTNER_MODAL,
    payload: data,
  };
};

export const dismissUpdateBussPartnerModalAction = (data) => {
  return {
    type: Types.HIDE_UPDATE_BUSSINESS_PARTNER_MODAL,
    payload: data,
  };
};

export const editBussPartnerAction = (data) => ({
  type: Types.EDIT_BUSSINESS_PARTNER_MODAL_ATTEMPT,
  payload: data,
});

export const editBussPartnerSuccess = (ref) => ({
  type: Types.EDIT_BUSSINESS_PARTNER_MODAL_SUCCESS,
  payload: { ref },
});

export const editBussPartnerFailed = (error) => ({
  type: Types.EDIT_BUSSINESS_PARTNER_MODAL_FAILED,
  payload: {
    ...error,
  },
});
// End of edit comapny bussiness partner modal action

// Start of upsert/delete comapny subscription modal action
export const showAddCompanySubscriptionModalAction = (data) => {
  return {
    type: Types.SHOW_COMPANY_SUBSCRIPTION_MODAL,
    payload: data,
  };
};

export const dismissAddSubscriptionModalAction = (data) => {
  return {
    type: Types.HIDE_COMPANY_SUBSCRIPTION_MODAL,
    payload: data,
  };
};

export const upsertCompanySubscriptionAction = (data) => ({
  type: Types.UPSERT_COMPANY_SUBSCRIPTION_ATTEMPT,
  payload: data,
});

export const upsertCompanySubscriptionSuccess = (ref) => ({
  type: Types.UPSERT_COMPANY_SUBSCRIPTION_SUCCESS,
  payload: { ref },
});

export const upsertCompanySubscriptionFailed = (error) => ({
  type: Types.UPSERT_COMPANY_SUBSCRIPTION_FAILED,
  payload: {
    ...error,
  },
});

export const deleteCompanyPlanSubscriptionAction = (
  supscription_id,
  companyId
) => ({
  type: Types.DELETE_COMPANY_SUBSCRIPTION_ATTEMPT,
  payload: supscription_id,
  companyId,
});

export const deleteCompanyPlanSubscriptionSuccess = () => ({
  type: Types.DELETE_COMPANY_SUBSCRIPTION_SUCCESS,
});

export const deleteCompanyPlanSubscriptionFailed = () => ({
  type: Types.DELETE_COMPANY_SUBSCRIPTION_FAILED,
});
// End of upsert/delete comapny subscription modal action

// Start of update employee limit value
export const updateEmployeeLimitValueAction = (
  employeeLimitValue,
  formName
) => {
  return {
    type: Types.UPDATE_EMPLOYEE_LIMIT_VALUE,
    payload: employeeLimitValue,
    ref: formName,
  };
};
// End of update employee limit value

// Start of Admin users actions
export const fetchAdminUsersAction = ({ ...data }) => {
  return {
    type: Types.FETCH_ADMIN_USERS_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchAdminUsersSuccess = (ref, list) => {
  return {
    type: Types.FETCH_ADMIN_USERS_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchAdminUsersFailed = (ref, error) => {
  return {
    type: Types.FETCH_ADMIN_USERS_FAILED,
    payload: {
      ref,
      error,
    },
  };
};

export const showAdminUserModalAction = (data) => {
  return {
    type: Types.SHOW_ADMIN_USER_MODAL,
    payload: data,
  };
};

export const dismissAdminUserModalAction = (data) => {
  return {
    type: Types.HIDE_ADMIN_USER_MODAL,
    payload: data,
  };
};

export const createAdminUserAction = (data) => ({
  type: Types.CREATE_ADMIN_USER_ATTEMPT,
  payload: data,
});

export const createAdminUserSuccess = (ref) => ({
  type: Types.CREATE_ADMIN_USER_SUCCESS,
  payload: { ref },
});

export const createAdminUserFailed = (error) => ({
  type: Types.CREATE_ADMIN_USER_FAILED,
  payload: {
    ...error,
  },
});

export const deleteAdminUserAction = (data) => ({
  type: Types.DELETE_ADMIN_USER_ATTEMPT,
  payload: data,
});

export const deleteAdminUserSuccess = () => ({
  type: Types.DELETE_ADMIN_USER_SUCCESS,
});

export const deleteAdminUserFailed = () => ({
  type: Types.DELETE_ADMIN_USER_FAILED,
});
// End of Admin users actions

// Start of fetch admin user companies actions
export const fetchAdminUserCompaniesAction = (data) => {
  return {
    type: Types.FETCH_ADMIN_USER_COMPANIES_ATTEMPT,
    payload: data,
  };
};

export const fetchAdminUserCompaniesSuccess = (data) => {
  return {
    type: Types.FETCH_ADMIN_USER_COMPANIES_SUCCESS,
    payload: data,
  };
};

export const fetchAdminUserCompaniesFailed = () => {
  return {
    type: Types.FETCH_ADMIN_USER_COMPANIES_FAILED,
  };
};
// End of fetch admin user companies actions

// Start of fetch admin user employees actions
export const fetchAdminUserEmployeesAction = (data) => {
  return {
    type: Types.FETCH_ADMIN_USER_EMPLOYEES_ATTEMPT,
    payload: data,
  };
};

export const fetchAdminUserEmployeesSuccess = (data) => {
  return {
    type: Types.FETCH_ADMIN_USER_EMPLOYEES_SUCCESS,
    payload: data,
  };
};

export const fetchAdminUserEmployeesFailed = () => {
  return {
    type: Types.FETCH_ADMIN_USER_EMPLOYEES_FAILED,
  };
};
// End of fetch admin user employees actions

// Start of Edit admin user privilages modal actions
export const editAdminUserPrivilegesAction = (user_id) => {
  return {
    type: Types.EDIT_ADMIN_USER_PRIVILEGES_ATTEMPT,
    payload: {
      user_id,
    },
  };
};

export const editAdminUserPrivilegesFailed = () => {
  return {
    type: Types.EDIT_ADMIN_USER_PRIVILEGES_FAILED,
  };
};

export const upsertAdminUserPrivilegesAction = (data) => {
  return {
    type: Types.UPSERT_ADMIN_USER_PRIVILEGES_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const upsertAdminUserPrivilegesFailed = () => {
  return {
    type: Types.UPSERT_ADMIN_USER_PRIVILEGES_FAILED,
  };
};
// End of Edit admin user privilages modal actions

// Start of admin user profile actions
export const showAdminUserProfileModalAction = (data) => {
  return {
    type: Types.SHOW_ADMIN_USER_PROFILE_MODAL,
    payload: data,
  };
};

export const dismissAdminUserProfileModalAction = (data) => {
  return {
    type: Types.HIDE_ADMIN_USER_PROFILE_MODAL,
    payload: data,
  };
};

export const updateAdminUserProfileAction = (data) => ({
  type: Types.UPDATE_ADMIN_USER_PROFILE_ATTEMPT,
  payload: data,
});

export const updateAdminUserProfileSuccess = (data) => ({
  type: Types.UPDATE_ADMIN_USER_PROFILE_SUCCESS,
  payload: data,
});

export const updateAdminUserProfileFailed = (error) => ({
  type: Types.UPDATE_ADMIN_USER_PROFILE_FAILED,
  payload: {
    ...error,
  },
});
// End of admin user profile actions

// Start of fetch petty cash list actions
export const fetchPettyCashListAction = (data) => {
  return {
    type: Types.FETCH_PETTY_CASH_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchPettyCashListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_PETTY_CASH_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchPettyCashListFailed = (ref, list) => {
  return {
    type: Types.FETCH_PETTY_CASH_LIST_FAILED,
    payload: {
      ref,
      list,
    },
  };
};
// End of fetch petty cash list actions

// Start of fetch employees for petty cash action
export const fetchPettyCashEmployeesAction = (pettyCashEmployees) => {
  return {
    type: Types.FETCH_PETTY_CASH_EMPLOYEES,
    payload: pettyCashEmployees,
  };
};
// End of fetch employees for petty cash action

// Start of fetch employees for petty cash filter action
export const fetchAvaliablePettyCashEmployeesFilterOptionsAction = (
  avaliablePettyCashEmployeesFilterOptions
) => {
  return {
    type: Types.FETCH_AVALIABLE_PETTY_CASH_EMPLOYEES_FILTER_OPTIONS,
    payload: avaliablePettyCashEmployeesFilterOptions,
  };
};
// End of fetch employees for petty cash filter action

// Start of modify/edit petty cash balance modal action
export const showModifyPettyCashBalanceModalAction = (data) => {
  return {
    type: Types.SHOW_PETTY_CASH_BALANCE_MODAL,
    payload: data,
  };
};

export const dismissModifyPettyCashBalanceModalAction = (data) => {
  return {
    type: Types.HIDE_PETTY_CASH_BALANCE_MODAL,
    payload: data,
  };
};

export const upsertPettyCashBalanceAction = (data) => ({
  type: Types.UPSERT_PETTY_CASH_BALANCE_ATTEMPT,
  payload: data,
});

export const upsertPettyCashBalanceSuccess = (ref) => ({
  type: Types.UPSERT_PETTY_CASH_BALANCE_SUCCESS,
  payload: { ref },
});

export const upsertPettyCashBalanceFailed = (error) => ({
  type: Types.UPSERT_PETTY_CASH_BALANCE_FAILED,
  payload: {
    ...error,
  },
});
// End of modify/edit petty cash balance modal action

// Start of fetch employees petty cash currencies action
export const fetchEmployeeOfficeCurrencyInPettyCashAction = (data) => {
  return {
    type: Types.FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_ATTEMPT,
    payload: data,
  };
};

export const fetchEmployeeOfficeCurrencyInPettyCashSuccess = (data) => {
  return {
    type: Types.FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_SUCCESS,
    payload: data,
  };
};

export const fetchEmployeeOfficeCurrencyInPettyCashFailed = () => {
  return {
    type: Types.FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_FAILED,
  };
};
// End of fetch employees petty cash currencies action

// Start of fetch current balance of employees actions
export const fetchEmployeeCurrentBalanceAction = (data) => {
  return {
    type: Types.FETCH_EMPLOYEE_CURRENT_BALANCE_ATTEMPT,
    payload: data,
  };
};

export const fetchEmployeeCurrentBalanceSuccess = (data) => {
  return {
    type: Types.FETCH_EMPLOYEE_CURRENT_BALANCE_SUCCESS,
    payload: data,
  };
};

export const fetchEmployeeCurrentBalanceFailed = () => {
  return {
    type: Types.FETCH_EMPLOYEE_CURRENT_BALANCE_FAILED,
  };
};
// End of fetch current balance of employees actions

// Start of fetch company settings list actions
export const fetchCompanySettingsListAction = (data) => {
  return {
    type: Types.FETCH_COMPANY_SETTINGS_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchCompanySettingsListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_COMPANY_SETTINGS_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchCompanySettingsListFailed = (ref, list) => {
  return {
    type: Types.FETCH_COMPANY_SETTINGS_LIST_FAILED,
    payload: {
      ref,
      list,
    },
  };
};
// End of fetch company settings list actions

// Start of company settings modal actions
export const showCompanySettingsModalAction = (data) => {
  return {
    type: Types.SHOW_COMPANY_SETTINGS_MODAL,
    payload: data,
  };
};

export const dismissCompanySettingsModalAction = (data) => {
  return {
    type: Types.HIDE_COMPANY_SETTINGS_MODAL,
    payload: data,
  };
};

export const upsertCompanySettingAction = (data) => ({
  type: Types.UPSERT_COMPANY_SETTINGS_ATTEMPT,
  payload: data,
});

export const upsertCompanySettingSuccess = (ref) => ({
  type: Types.UPSERT_COMPANY_SETTINGS_SUCCESS,
  payload: { ref },
});

export const upsertCompanySettingFailed = (error) => ({
  type: Types.UPSERT_COMPANY_SETTINGS_FAILED,
  payload: {
    ...error,
  },
});

export const deleteCompanySettingAction = (data) => ({
  type: Types.DELETE_COMPANY_SETTINGS_ATTEMPT,
  payload: data,
});

export const deleteCompanySettingSuccess = () => ({
  type: Types.DELETE_COMPANY_SETTINGS_SUCCESS,
});

export const deleteCompanySettingFailed = () => ({
  type: Types.DELETE_COMPANY_SETTINGS_FAILED,
});
// End of company settings modal actions

// Start of fetch company expenses list actions
export const fetchCompanyExpensesListAction = (data) => {
  return {
    type: Types.FETCH_COMPANY_EXPENSE_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchCompanyExpensesSuccess = (ref, list) => {
  return {
    type: Types.FETCH_COMPANY_EXPENSE_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchCompanyExpensesFailed = (ref, list) => {
  return {
    type: Types.FETCH_COMPANY_EXPENSE_FAILED,
    payload: {
      ref,
      list,
    },
  };
};
// End of fetch company expenses list actions

// Start of upsert company expenses modal action
export const showCompanyExpensesModalAction = (data) => {
  return {
    type: Types.SHOW_COMPANY_EXPENSE_MODAL,
    payload: data,
  };
};

export const dismissCompanyExpenseModalAction = (data) => {
  return {
    type: Types.HIDE_COMPANY_EXPENSE_MODAL,
    payload: data,
  };
};

export const upsertCompanyExpensesAction = (data) => ({
  type: Types.UPSERT_COMPANY_EXPENSE_ATTEMPT,
  payload: data,
});

export const upsertCompanyExpensesSuccess = (ref) => ({
  type: Types.UPSERT_COMPANY_EXPENSE_SUCCESS,
  payload: { ref },
});

export const upsertCompanyExpensesFailed = (error) => ({
  type: Types.UPSERT_COMPANY_EXPENSE_FAILED,
  payload: {
    ...error,
  },
});
// End of upsert company expenses modal action

// Start of fetch categories for company expenses action
export const fetchCompanyExpensesCategoriesAction = (
  companyExpensesCategories
) => {
  return {
    type: Types.FETCH_COMPANY_EXPENSES_CATEGORIES,
    payload: companyExpensesCategories,
  };
};
// End of fetch categories for company expenses action

// Start of fetch sub-categories for company expenses action
export const fetchCompanyExpensesSubCategoriesAction = (data) => {
  return {
    type: Types.FETCH_COMPANY_EXPENSES_SUBCATEGORIES_ATTEMPT,
    payload: data,
  };
};

export const fetchCompanyExpensesSubCategoriesSuccess = (data) => {
  return {
    type: Types.FETCH_COMPANY_EXPENSES_SUBCATEGORIES_SUCCESS,
    payload: data,
  };
};

export const fetchCompanyExpensesSubCategoriesFailed = () => {
  return {
    type: Types.FETCH_COMPANY_EXPENSES_SUBCATEGORIES_FAILED,
  };
};
// End of fetch sub-categories for company expenses action

// Start of fetch company expenses total balance action
export const fetchTotalExpenseBalanceAction = (data) => {
  return {
    type: Types.FETCH_EXPENSE_TOTAL_BALANCE_ATTEMPT,
    payload: data,
  };
};

export const fetchTotalExpenseBalanceSuccess = (data) => {
  return {
    type: Types.FETCH_EXPENSE_TOTAL_BALANCE_SUCCESS,
    payload: data,
  };
};

export const fetchTotalExpenseBalanceFailed = () => {
  return {
    type: Types.FETCH_EXPENSE_TOTAL_BALANCE_FAILED,
  };
};
// End of fetch company expenses total balance action

// Start of export company expenses actions
export const exportCompanyExpensesAction = (data) => ({
  type: Types.EXPORT_COMPANY_EXPENSES_ATTEMPT,
  payload: data,
});

export const exportCompanyExpensesSuccess = () => ({
  type: Types.EXPORT_COMPANY_EXPENSES_SUCCESS,
});

export const exportCompanyExpensesFailed = () => ({
  type: Types.EXPORT_COMPANY_EXPENSES_FAILED,
});
// End of export company expenses actions

// Start of fetch sub catgories options in expense form actions
export const fetchSubCatgoriesInExpenseFormAction = (data) => {
  return {
    type: Types.FETCH_EXPENSE_FORM_SUBCATGERORIES_ATTEMPT,
    payload: data,
  };
};

export const fetchSubCatgoriesInExpenseFormSuccess = (data) => {
  return {
    type: Types.FETCH_EXPENSE_FORM_SUBCATGERORIES_SUCCESS,
    payload: data,
  };
};

export const fetchSubCatgoriesInExpenseFormFailed = () => {
  return {
    type: Types.FETCH_EXPENSE_FORM_SUBCATGERORIES_FAILED,
  };
};
// End of fetch sub catgories options in expense form actions

// Start of fetch currencies options in expense form actions
export const fetchCurrenciesInExpenseFormAction = (data) => {
  return {
    type: Types.FETCH_EXPENSE_FORM_CUREENCIES_ATTEMPT,
    payload: data,
  };
};

export const fetchCurrenciesInExpenseFormSuccess = (data) => {
  return {
    type: Types.FETCH_EXPENSE_FORM_CUREENCIES_SUCCESS,
    payload: data,
  };
};

export const fetchCurrenciesInExpenseFormFailed = () => {
  return {
    type: Types.FETCH_EXPENSE_FORM_CUREENCIES_FAILED,
  };
};
// End of fetch currencies options in expense form actions

// Start of fetch employees options in expense form actions
export const fetchEmployeesInExpenseFormAction = (data) => {
  return {
    type: Types.FETCH_EXPENSE_FORM_EMPLOYEES_ATTEMPT,
    payload: data,
  };
};

export const fetchEmployeesInExpenseFormSuccess = (data) => {
  return {
    type: Types.FETCH_EXPENSE_FORM_EMPLOYEES_SUCCESS,
    payload: data,
  };
};

export const fetchEmployeesInExpenseFormFailed = () => {
  return {
    type: Types.FETCH_EXPENSE_FORM_EMPLOYEES_FAILED,
  };
};
// End of fetch employees options in expense form actions

// Start of delete company expense actions
export const deleteCompanyExpenseAction = (data) => ({
  type: Types.DELETE_COMPANY_EXPENSE_ATTEMPT,
  payload: data,
});

export const deleteCompanyExpenseSuccess = () => ({
  type: Types.DELETE_COMPANY_EXPENSE_SUCCESS,
});

export const deleteCompanyExpenseFailed = () => ({
  type: Types.DELETE_COMPANY_EXPENSE_FAILED,
});
// End of delete company expense actions

// Start of delete company expense attachment file actions
export const deleteCompanyExpenseAttachedFileAction = (
  file_id,
  pettyCashId
) => ({
  type: Types.DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_ATTEMPT,
  payload: {
    file_id,
    pettyCashId,
  },
});

export const deleteCompanyExpenseAttachedFileSuccess = (data) => ({
  type: Types.DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_SUCCESS,
  payload: data,
});

export const deleteCompanyExpenseAttachedFileFailed = () => ({
  type: Types.DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_FAILED,
});
// End of delete company expense attachment file

// Start of delete petty Cash attachment file actions
export const deletePettyCashAttachedFileAction = (file_id, pettyCashId) => ({
  type: Types.DELETE_PETTY_CASH_ATTACHMENT_FILE_ATTEMPT,
  payload: {
    file_id,
    pettyCashId,
  },
});

export const deletePettyCashAttachedFileSuccess = (data) => ({
  type: Types.DELETE_PETTY_CASH_ATTACHMENT_FILE_SUCCESS,
  payload: data,
});

export const deletePettyCashAttachedFileFailed = () => ({
  type: Types.DELETE_PETTY_CASH_ATTACHMENT_FILE_FAILED,
});
// End of delete petty Cash attachment file actions

// Start of fetch user petty cash list actions
export const fetchUserPettyCashListAction = (data) => {
  return {
    type: Types.FETCH_USER_PETTY_CASH_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchUserPettyCashListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_USER_PETTY_CASH_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchUserPettyCashListFailed = (ref, list) => {
  return {
    type: Types.FETCH_USER_PETTY_CASH_FAILED,
    payload: {
      ref,
      list,
    },
  };
};
// End of fetch user petty cash list actions

// Start of fetch current balance of user profile actions
export const fetchUserProfileCurrentBalanceAction = (data) => {
  return {
    type: Types.FETCH_USER_PROFILE_CURRENT_BALANCE_ATTEMPT,
    payload: data,
  };
};

export const fetchUserProfileCurrentBalanceSuccess = (data) => {
  return {
    type: Types.FETCH_USER_PROFILE_CURRENT_BALANCE_SUCCESS,
    payload: data,
  };
};

export const fetchUserProfileCurrentBalanceFailed = () => {
  return {
    type: Types.FETCH_USER_PROFILE_CURRENT_BALANCE_FAILED,
  };
};
// End of fetch current balance of user profile actions

// Start of fetch petty cash currencies actions
export const fetchPettyCashCurrenciesAction = (data) => {
  return {
    type: Types.FETCH_PETTY_CASH_CURRENCIES_ATTEMPT,
    payload: data,
  };
};

export const fetchPettyCashCurrenciesSuccess = (data) => {
  return {
    type: Types.FETCH_PETTY_CASH_CURRENCIES_SUCCESS,
    payload: data,
  };
};

export const fetchPettyCashCurrenciesFailed = () => {
  return {
    type: Types.FETCH_PETTY_CASH_CURRENCIES_FAILED,
  };
};
// End of fetch petty cash currencies actions

// Start Init Suspension Modal

export const initSuspensionAction = ({ suspend_from, suspended_employee }) => ({
  type: Types.INIT_SUSPENSION_USER_ATTEMPT,
  payload: {
    suspend_from,
    suspended_employee,
  },
});

export const initSuspensionSuccess = (data) => ({
  type: Types.INIT_SUSPENSION_USER_SUCCESS,
  payload: data,
});

export const initSuspensionFailed = (errorMessage = null) => ({
  type: Types.INIT_SUSPENSION_USER_FAILED,
  payload: errorMessage,
});

// End Init Suspension Modal
// Start of resend credentails actions
export const resendCredentailsAction = (user_id) => ({
  type: Types.RESEND_CREDENTAILS_ATTEMPT,
  payload: { user_id },
});

export const resendCredentailsSuccess = () => ({
  type: Types.RESEND_CREDENTAILS_SUCCESS,
});

export const resendCredentailsFailed = () => ({
  type: Types.RESEND_CREDENTAILS_FAILED,
});
// End of resend credentails actions

// Start of fetch all managers action
export const fetchAllManagersAction = (allManagers) => {
  return {
    type: Types.FETCH_ALL_MANAGERS_ACTION,
    payload: allManagers,
  };
};
// End of fetch all managers action

// Start employee documents tab
export const fetchEmployeeDocumnetsAction = (id) => {
  return {
    type: Types.FETCH_EMPLOYEE_DOCUMENTS_ATTEMPT,
    payload: { id },
  };
};

export const fetchEmployeeDocumnetsSuccess = (data) => {
  return {
    type: Types.FETCH_EMPLOYEE_DOCUMENTS_SUCCESS,
    payload: data,
  };
};

export const fetchEmployeeDocumnetsFailed = () => {
  return {
    type: Types.FETCH_EMPLOYEE_DOCUMENTS_FAILED,
  };
};
// End employee documents tab

// start add User doc

export const fetchUpsertDocModalAction = () => {
  return {
    type: Types.FETCH_UPSERT_DOC_MODAL_ATTEMPT,
  };
};

export const fetchUpsertDocModalSuccess = (data) => {
  return {
    type: Types.FETCH_UPSERT_DOC_MODAL_SUCCESS,
    payload: data,
  };
};

export const fetchUpsertDocModalFailed = () => {
  return {
    type: Types.FETCH_UPSERT_DOC_MODAL_FAILED,
  };
};

export const addUserDocumentAction = (documnet, hasEmployeesPrivileges) => {
  return {
    type: Types.ADD_USER_DOCUMENT_ATTEMPT,
    payload: { documnet, hasEmployeesPrivileges },
  };
};

export const addUserDocumentSuccess = (res) => {
  return {
    type: Types.ADD_USER_DOCUMENT_SUCCESS,
    payload: res,
  };
};

export const addUserDocumentFailed = (error) => {
  return {
    type: Types.ADD_USER_DOCUMENT_FAILED,
    payload: error,
  };
};

export const deleteUserDocumentAttachmentAction = (
  file_id,
  user_document_id
) => {
  return {
    type: Types.DELETE_USER_DOCUMENT_ATTACHMENT_ATTEMPT,
    payload: {
      file_id,
      user_document_id,
    },
  };
};

export const deleteUserDocumentAttachmentSuccess = (file_id) => {
  return {
    type: Types.DELETE_USER_DOCUMENT_ATTACHMENT_SUCCESS,
    payload: {
      file_id,
    },
  };
};

export const deleteUserDocumentAttachmentFailed = () => {
  return {
    type: Types.DELETE_USER_DOCUMENT_ATTACHMENT_FAILED,
  };
};
// end add User doc

// Start of change data view for employee data actions
export const changeToListDataViewAction = (data) => {
  return {
    type: Types.CHANGE_TO_LIST_DATA_VIEW,
    payload: data,
  };
};

export const changeToGridDataViewAction = (data) => {
  return {
    type: Types.CHANGE_TO_GRID_DATA_VIEW,
    payload: data,
  };
};
// End of change data view for employee data actions

// Start of Export employee list actions
export const showExportEmployeeListModal = ({ ref }) => {
  return {
    type: Types.SHOW_EXPORT_EMPLOYEE_LIST_MODAL,
    payload: { ref },
  };
};

export const dismissExportEmployeeListModal = ({ ref }) => {
  return {
    type: Types.HIDE_EXPORT_EMPLOYEE_LIST_MODAL,
    payload: { ref },
  };
};

export const exportEmployeeListAction = ({
  ref,
  exportColumns,
  filterOptions,
}) => {
  return {
    type: Types.EXPORT_EMPLOYEE_LIST_ATTEMPT,
    payload: {
      ref,
      exportColumns,
      filterOptions,
    },
  };
};

export const exportEmployeeListSuccess = (ref) => {
  return {
    type: Types.EXPORT_EMPLOYEE_LIST_SUCCESS,
    payload: { ref },
  };
};

export const exportEmployeeListFailed = (ref) => {
  return {
    type: Types.EXPORT_EMPLOYEE_LIST_FAILED,
    payload: { ref },
  };
};
export const setIsEmployeeImportModalOpen = (payload) => {
  return {
    type: Types.SET_IS_EMPLOYEE_IMPORT_MODAL_OPEN,
    payload,
  };
};
export const downloadEmployeeTemplateAttempt = (payload) => {
  return {
    type: Types.DOWNLOAD_EMPLOYEE_TEMPLATE_ATTEMPT,
    payload,
  };
};
export const downloadEmployeeTemplateSuccess = () => {
  return {
    type: Types.DOWNLOAD_EMPLOYEE_TEMPLATE_SUCCESS,
  };
};
export const downloadEmployeeTemplateFailed = () => {
  return {
    type: Types.DOWNLOAD_EMPLOYEE_TEMPLATE_FAILED,
  };
};
export const setIsEmployeeImportModalErrorsOpen = (payload) => {
  return {
    type: Types.SET_IS_EMPLOYEE_IMPORT_MODAL_ERRORS_OPEN,
    payload,
  };
};
export const uploadBulkEmployeesCsvAttempt = ({
  file,
  company_id,
  resetFile,
}) => {
  return {
    type: Types.UPLOAD_BULK_EMPLOYEES_CSV_ATTEMPT,
    payload: {
      file,
      company_id,
      resetFile,
    },
  };
};
export const uploadBulkEmployeesCsvSuccess = (payload) => {
  return {
    type: Types.UPLOAD_BULK_EMPLOYEES_CSV_SUCCESS,
    payload,
  };
};
export const uploadBulkEmployeesCsvFailed = (payload) => {
  return {
    type: Types.UPLOAD_BULK_EMPLOYEES_CSV_FAILED,
    payload,
  };
};
export const uploadBulkEmployeesCsvRequestFailed = () => {
  return {
    type: Types.UPLOAD_BULK_EMPLOYEES_CSV_REQUEST_FAILED,
  };
};
// End of Export employee list actions

// Handle updated total List //plan feature\\  count
export const updateTotalFeatureCount = (refList, total) => {
  return {
    type: Types.UPDATE_TOTAL_lIST_COUNT,
    payload: { refList, total },
  };
};

//START  PLAN ROLES PRESET //

export const fetchPlanRolesOptsAction = (planRoles) => {
  return {
    type: Types.FETCH_PLAN_ROLES_OPTS,
    payload: planRoles,
  };
};

export const fetchRolePresetPrivilegesAction = (roleId) => {
  return {
    type: Types.FETCH_ROLE_PRESET_PRIVILEGS_ATTEMPT,
    payload: { roleId },
  };
};

export const fetchRolePresetPrivilegesSuccess = (main, all, managed) => {
  return {
    type: Types.FETCH_ROLE_PRESET_PRIVILEGS_SUCCESS,
    payload: { main, all, managed },
  };
};

export const fetchRolePresetPrivilegesFailed = () => {
  return {
    type: Types.FETCH_ROLE_PRESET_PRIVILEGS_FAILED,
  };
};

//END  PLAN ROLES PRESET //

// Start of fetch roles privilages preview modal actions
export const showPreviewRolePrivilagesModalAction = (data) => {
  return {
    type: Types.SHOW_PREVIEW_ROLE_PRIVILEGES_MODAL,
    payload: data,
  };
};

export const dismissPreviewRolePrivilagesModalAction = (data) => {
  return {
    type: Types.HIDE_PREVIEW_ROLE_PRIVILEGES_MODAL,
    payload: data,
  };
};

export const fetchPreviewRolePrivilagesAction = (planId, roleId) => {
  return {
    type: Types.FETCH_PREVIEW_ROLE_PRIVILEGES_ATTEMPT,
    payload: { planId, roleId },
  };
};

export const fetchPreviewRolePrivilagesSuccess = (data) => {
  return {
    type: Types.FETCH_PREVIEW_ROLE_PRIVILEGES_SUCCESS,
    payload: data,
  };
};

export const fetchPreviewRolePrivilagesFailed = () => {
  return {
    type: Types.FETCH_PREVIEW_ROLE_PRIVILEGES_FAILED,
  };
};
// End of fetch roles privilages preview modal actions

// handle fetch setup wizard data while aut
export const fetchSetupWizardDataInAuth = () => {
  return {
    type: Types.FETCH_SETUP_WIZARD_DATA_IN_AUTH,
  };
};

//start of fetch open payroll months
export const fetchOpenPayrollMonthsAttempt = (payload) => {
  return {
    type: Types.FETCH_OPEN_PAYROLL_MONTHS_ATTEMPT,
    payload,
  };
};
export const fetchOpenPayrollMonthsSuccess = (payload) => {
  return {
    type: Types.FETCH_OPEN_PAYROLL_MONTHS_SUCCESS,
    payload,
  };
};
export const fetchOpenPayrollMonthsFailed = (payload) => {
  return {
    type: Types.FETCH_OPEN_PAYROLL_MONTHS_FAILED,
    payload,
  };
};
export const AddCompanyCurrencies = (payload) => {
  return {
    type: Types.ADD_COMPANY_CURRENCIES,
    payload,
  };
};

export const editOpenPayrollMonthsOfficesAccordingToScope = (payload) => {
  return {
    type: Types.EDIT_OPEN_PAYROLL_MONTHS_OFFICES_ACCORDING_TO_SCOPE,
    payload,
  };
};
// start of fetching expenses charts==============//

export const fetchDashboardCurrenciesSuccess = (payload) => {
  return {
    type: Types.FETCH_DASHBOARD_CURRENCIES_SUCCESS,
    payload,
  };
};
export const fetchTotalExpensesAttempt = (payload) => {
  return {
    type: Types.FETCH_TOTAL_EXPENSES_ATTEMPT,
    payload,
  };
};
export const fetchTotalExpensesSuccess = (payload) => {
  return {
    type: Types.FETCH_TOTAL_EXPENSES_SUCCESS,
    payload,
  };
};
export const fetchTotalExpensesFailed = (payload) => {
  return {
    type: Types.FETCH_TOTAL_EXPENSES_FAILED,
    payload,
  };
};
export const fetchSubCategoryExpensesAttempt = (payload) => {
  return {
    type: Types.FETCH_SUB_CATEGORY_EXPENSES_ATTEMPT,
    payload,
  };
};
export const fetchSubCategoryExpensesSuccess = (payload) => {
  return {
    type: Types.FETCH_SUB_CATEGORY_EXPENSES_SUCCESS,
    payload,
  };
};
export const fetchSubCategoryExpensesFailed = (payload) => {
  return {
    type: Types.FETCH_SUB_CATEGORY_EXPENSES_FAILED,
    payload,
  };
};
export const setIsThisQueryTotalExpenses = (payload) => {
  return {
    type: Types.SET_IS_THIS_QUERY_TOTAL_EXPENSES,
    payload,
  };
};
export const switchDashboardLoadingOff = () => {
  return {
    type: Types.SWITCH_DASHBOARD_LOADING_OFF,
  };
};

//start of employees turnover chart Actions.
export const fetchEmployeesTurnoverAttempt = (payload) => {
  return {
    type: Types.FETCH_EMPLOYEES_TURNOVER_CHART_ATTEMPT,
    payload,
  };
};
export const fetchEmployeesTurnoverSuccess = (payload) => {
  return {
    type: Types.FETCH_EMPLOYEES_TURNOVER_CHART_SUCCESS,
    payload,
  };
};
export const fetchEmployeesTurnoverFailed = () => {
  return {
    type: Types.FETCH_EMPLOYEES_TURNOVER_CHART_FAILED,
  };
};

// Start of Request Comments modal actions
export const showRequestCommentsModalAction = (data) => {
  return {
    type: Types.SHOW_REQUEST_COMMENTS_MODAL,
    payload: data,
  };
};

export const dismissRequestCommentsModalAction = (data) => {
  return {
    type: Types.HIDE_REQUEST_COMMENTS_MODAL,
    payload: data,
  };
};

export const fetchRequestCommentsAction = (requestId) => {
  return {
    type: Types.FETCH_REQUEST_COMMENTS_ATTEMPT,
    payload: { requestId },
  };
};

export const fetchRequestCommentsSuccess = (data) => {
  return {
    type: Types.FETCH_REQUEST_COMMENTS_SUCCESS,
    payload: data,
  };
};

export const fetchRequestCommentsFailed = () => {
  return {
    type: Types.FETCH_REQUEST_COMMENTS_FAILED,
  };
};

export const upsertRequestCommentAction = (
  request_id,
  comment,
  refetchQueries
) => ({
  type: Types.UPSERT_REQUEST_COMMENT_ATTEMPT,
  payload: {
    request_id,
    comment,
    refetchQueries,
  },
});

export const upsertRequestCommentSuccess = () => ({
  type: Types.UPSERT_REQUEST_COMMENT_SUCCESS,
});

export const upsertRequestCommentFailed = (error) => ({
  type: Types.UPSERT_REQUEST_COMMENT_FAILED,
  payload: {
    ...error,
  },
});
// End of Request Comments modal actions

// start of dashboard ui checkbox action
export const handleDashbaordUiToggleCheckboxAction = (formName, event) => {
  return {
    type: Types.HANDLE_DASHBOARD_UI_CHECKBOX_TOGGLE_ACTION,
    payload: {
      formName,
      event,
    },
  };
};
// End of dashboard ui checkbox action

// Start of Penalties actions
export const fetchPenaltiesSuccess = (ref, list) => {
  return {
    type: Types.FETCH_PENALTIES_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchPenaltiesEmployeesFilter = (list) => {
  return {
    type: Types.FETCH_PENALTIES_EMPLOYEES_FILTER,
    payload: {
      list,
    },
  };
};
// End of Penalties actions

// Start of auto suggest holidays actions
export const autoSuggestHolidaysAction = (data) => {
  return {
    type: Types.AUTO_SUGGEST_HOLIDAYS_ATTEMPT,
    payload: data,
  };
};

export const autoSuggestHolidaysSuccess = () => {
  return {
    type: Types.AUTO_SUGGEST_HOLIDAYS_SUCCESS,
  };
};

export const autoSuggestHolidaysFailed = () => {
  return {
    type: Types.AUTO_SUGGEST_HOLIDAYS_FAILED,
  };
};
// End of auto suggest holidays actions

// Start of accept suggested holiday actions
export const acceptSuggestedHolidayAction = (data) => {
  return {
    type: Types.ACCEPT_SUGGESTED_HOLIDAY_ATTEMPT,
    payload: data,
  };
};

export const acceptSuggestedHolidaySuccess = () => {
  return {
    type: Types.ACCEPT_SUGGESTED_HOLIDAY_SUCCESS,
  };
};

export const acceptSuggestedHolidayFailed = () => {
  return {
    type: Types.ACCEPT_SUGGESTED_HOLIDAY_FAILED,
  };
};
// End of accept suggested holiday actions

// Start of Delete suggested holiday action
export const deleteSuggestedHolidayAction = (data) => ({
  type: Types.DELETE_SUGGESTED_HOLIDAYS_ATTEMPT,
  payload: data,
});

export const deleteSuggestedHolidaySuccess = () => ({
  type: Types.DELETE_SUGGESTED_HOLIDAYS_SUCCESS,
});

export const deleteSuggestedHolidayFailed = () => ({
  type: Types.DELETE_SUGGESTED_HOLIDAYS_FAILED,
});
// End of Delete suggested holiday action

// Start of Delete Salary config action
export const deleteSalaryConfigAction = (data, userId) => ({
  type: Types.DELETE_SALARY_CONFIG_ATTEMPT,
  payload: data,
  userId,
});

export const deleteSalaryConfigSuccess = () => ({
  type: Types.DELETE_SALARY_CONFIG_SUCCESS,
});

export const deleteSalaryConfigFailed = () => ({
  type: Types.DELETE_SALARY_CONFIG_FAILED,
});
// End of Delete Salary config action

// Start of upsert assignment actions

export const toggleModalDisplayAction = (value) => {
  return {
    type: Types.TOGGLE_MODAL_DISPLAY,
    payload: value,
  };
};

export const fetchAssignmentFormAction = (directives) => {
  return {
    type: Types.FETCH_ASSIGNMENT_FORM_ATTEMPT,
    payload: directives,
  };
};

export const fetchAssignmentFormSuccessAction = (value, attemptPayload) => {
  return {
    type: Types.FETCH_ASSIGNMENT_FORM_SUCCESS,
    payload: value,
    attemptPayload,
  };
};

export const fetchAssignmentFormFailedAction = (value) => {
  return {
    type: Types.FETCH_ASSIGNMENT_FORM_FAILED,
    payload: value,
  };
};

export const upsertAssignmentSuccess = (value) => {
  return {
    type: Types.UPSERT_ASSIGNMENT_SUCCESS,
    payload: value,
  };
};

export const upsertAssignmentFailed = (type, error) => {
  return {
    type: Types.UPSERT_ASSIGNMENT_FAILED,
    payload: {
      type,
      error,
    },
  };
};

export const fetchAssignmentsAttempt = (filters) => {
  return {
    type: Types.FETCH_ASSIGNMENTS_ATTEMPT,
    payload: filters,
  };
};

export const fetchAssignmentsSuccess = (data) => {
  return {
    type: Types.FETCH_ASSIGNMENTS_SUCCESS,
    payload: {
      assignments: data.Assignments,
      inputs: {
        users_by_role: data.users_by_role,
        work_timings: data.work_timings,
        locations: data.locations,
      },
    },
  };
};

export const fetchAssignmentsFailed = () => {
  return {
    type: Types.FETCH_ASSIGNMENTS_FAILED,
  };
};

export const changeAssignmentDateAction = (date) => {
  return {
    type: Types.CHANGE_ASSIGNMENT_DATE_ACTION,
    payload: date,
  };
};

export const deleteAssignmentAttempt = (id, force_delete = false) => {
  return {
    type: Types.DELETE_ASSIGNMENT_ATTEMPT,
    payload: { id, force_delete },
  };
};

export const deleteAssignmentSuccess = () => {
  return {
    type: Types.DELETE_ASSIGNMENT_SUCCESS,
  };
};

export const deleteAssignmentFailed = () => {
  return {
    type: Types.DELETE_ASSIGNMENT_FAILED,
  };
};

export const toggleAssignmentPreviewModal = (status) => {
  return {
    type: Types.TOGGLE_ASSIGNMENT_PREVIEW_MODAL,
    payload: status,
  };
};

export const fetchChangeAssignments = (data) => {
  return {
    type: Types.FETCH_CHANGE_ASSIGNMENTS,
    payload: data,
  };
};

// End of upsert assignment actions

// start of fetch all offices options
export const fetchAllOfficesOptionsAction = (data) => ({
  type: Types.FETCH_ALL_OFFICE_OPTIONS,
  payload: data,
});

export const fetchAllWorkTimingsMenu = (data) => ({
  type: Types.FETCH_ALL_WORK_TIMINGS_MENU,
  payload: data,
});
// end of fetch all offices options

// Start of fetch loans list action
export const fetchLoansListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_LOANS_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};
// End of fetch loans list action

// Start of fetch my loans list action
export const fetchMyLoansListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_MY_LOANS_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};
// End of fetch my loans list action

// Start of fetch loans employee options action
export const fetchLoansEmployeeOptionsAction = (employees) => {
  return {
    type: Types.FETCH_LOANS_EMPLOYEE_OPTIONS,
    payload: employees,
  };
};
// End of fetch loans employee options action

// Start of fetch loans currency options action
export const fetchLoansCurrencyOptionsAction = (currency) => {
  return {
    type: Types.FETCH_LOANS_CURRENCY_OPTIONS,
    payload: currency,
  };
};
// End of fetch loans currency options action

// start of loan modal actions
export const showLoansModalAction = (data) => {
  return {
    type: Types.SHOW_LOAN_MODAL,
    payload: data,
  };
};

export const dismissLoansModalAction = (data) => {
  return {
    type: Types.HIDE_LOAN_MODAL,
    payload: data,
  };
};

export const addInstallmentsInputAction = () => {
  return {
    type: Types.ADD_INSTALLMENTS_INPUT,
  };
};

export const removeInstallmentsInputAction = (index) => {
  return {
    type: Types.REMOVE_INSTALLMENTS_INPUT,
    payload: {
      index,
    },
  };
};

export const generateInstallmentInputAction = (
  input_index,
  payment_date,
  loanAmount,
  payment_interval
) => {
  return {
    type: Types.GENERATE_INSTALLMENT_INPUT,
    payload: {
      input_index,
      payment_date,
      loanAmount,
      payment_interval,
    },
  };
};

export const setLoanCurrencyAccordingToSalaryConfigAction = (currency_id) => {
  return {
    type: Types.SET_lOAN_CURRENCY_DUE_TO_SALARY_CONFIG,
    payload: currency_id,
  };
};

export const upsertLoansAction = () => ({
  type: Types.UPSERT_LOAN_ATTEMPT,
});

export const upsertLoansSuccess = () => ({
  type: Types.UPSERT_LOAN_SUCCESS,
});

export const upsertLoansFailed = (error) => ({
  type: Types.UPSERT_LOAN_FAILED,
  payload: {
    ...error,
  },
});
// End of loan modal actions

// start of handle notification drawer
export const showNotificationDrawer = () => ({
  type: Types.SHOW_NOTIFICATION_DRAWER,
});
export const hideNotificationDrawer = () => ({
  type: Types.HIDE_NOTIFICATION_DRAWER,
});
// end of handle notification drawer

//start of get notification's history
export const getNotificationHistoryAttempt = (payload) => ({
  type: Types.GET_NOTIFICATION_HISTORY_ATTEMPT,
  payload,
});
export const getNotificationHistorySuccess = (payload) => ({
  type: Types.GET_NOTIFICATION_HISTORY_SUCCESS,
  payload,
});
export const getNotificationHistoryFailed = () => ({
  type: Types.GET_NOTIFICATION_HISTORY_FAILED,
});
export const NotificationHistoryReset = () => ({
  type: Types.NOTIFICATION_HISTORY_RESET,
});
//end  of get notification's history

// start of check all notification status to read
export const checkAllNotificationToReadAttempt = () => ({
  type: Types.CHECK_NOTIFICATION_READ_ATTEMPT,
});
export const checkAllNotificationToReadSuccess = () => ({
  type: Types.CHECK_NOTIFICATION_READ_SUCCESS,
});
export const checkAllNotificationToReadFailed = () => ({
  type: Types.CHECK_NOTIFICATION_READ_FAILED,
});
// end of check all notification status to read

export const setNotificationsPageAction = (page) => {
  return {
    type: Types.SET_NOTIFICATION_PAGE_ACTION,
    payload: page,
  };
};

export const fetchAllWorkTimingsOptionsAction = (data) => ({
  type: Types.FETCH_ALL_WORK_TIMINGS_OPTIONS,
  payload: {
    data,
  },
});

export const fetchAllEmployeesOptionsAction = (data) => ({
  type: Types.FETCH_ALL_EMPLOYEES_OPTIONS,
  payload: {
    data,
  },
});

export const fetchAllLocationOptionsAction = (data) => ({
  type: Types.FETCH_ALL_LOCATION_OPTIONS,
  payload: {
    data,
  },
});

export const setAllPositionOptionsAction = (data) => ({
  type: Types.SET_ALL_POSITION_OPTIONS,
  payload: data,
});

// Penalty Modal
export const showPenaltyModal = (payload) => ({
  type: Types.SHOW_PENALTY_MODAL,
  payload,
});
export const hidePenaltyModal = (payload) => ({
  type: Types.HIDE_PENALTY_MODAL,
  payload,
});

export const setSalaryCalculationToFixed = () => {
  return {
    type: Types.SET_SALARY_CONFIG_CALCULATIONS_FIXED,
  };
};

export const addOverTimeSettingAction = () => {
  return {
    type: Types.ADD_OVERTIME_SETTING,
  };
};

export const removeOverTimeSettingAction = (index) => {
  return {
    type: Types.REMOVE_OVERTIME_SETTING,
    payload: {
      index,
    },
  };
};

export const resetOvertimeSettingsAction = () => {
  return {
    type: Types.RESET_OVERTIME_SETTINGS,
  };
};

export const setShouldExitCalendarPage = (status) => {
  return {
    type: Types.SET_SHOULD_EXIT_SCHEDULE_PAGE,
    payload: status,
  };
};

export const resetModalMessage = (formname) => {
  return {
    type: Types.RESET_MODAL_MESSAGE,
    payload: formname,
  };
};

export const setServerValidationAction = (payload) => ({
  type: Types.SET_SERVER_VALIDATION,
  payload,
});

export const setFormServerValidationAction = (payload) => ({
  type: Types.SET_FORM_SERVER_VALIDATION,
  payload,
});

export const toggleSignInBreaksModal = ({
  isOpen,
  breakInOuts,
  name,
  date,
}) => ({
  type: Types.TOGGLE_SIGN_IN_BREAKS_MODAL,
  payload: { isOpen, breakInOuts, name, date },
});

export const toggleLogsModal = ({ isOpen, data, name, date }) => ({
  type: Types.TOGGLE_LOGS_MODAL,
  payload: { isOpen, data, name, date },
});

export const adjustSignInOutIds = ({ id, operation }) => ({
  type: Types.ADJUST_SIGN_IN_OUT_IDS,
  payload: { id, operation },
});

export const resetAssignmentModalMessage = () => ({
  type: Types.RESET_ASSIGNMENT_MODAL_MESSAGE,
});
