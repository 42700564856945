import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  setWizardAttType,
  setSetupWizardRemainingSteps,
  fetchSetupWizardEssentialDataAttempt,
} from "../../Store/Actions";
import {
  getProgressSteps,
  getWizardAttType,
  getWizardDataStepsCounts,
} from "../../Helpers/HelperFns/SetupWizard";
import clsx from "clsx";

import { Link } from "react-router-dom";

const PROGRESS_STEPS_UI = getProgressSteps();

const SetupWizardComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Local State
  const [isShow, setIsShow] = React.useState(false);

  // Reducer State
  const {
    setupWizardLoading,
    setupWizardEssentialInfo,
    setupWizardRemainingSteps,
  } = useSelector((state) => state.wizard);

  /* ↓ State Effects ↓ */

  useEffect(() => {
    dispatch(fetchSetupWizardEssentialDataAttempt());
  }, []);

  useEffect(() => {
    // Set initial states
    if (!setupWizardLoading) {
      // Set wizardAttType
      const wizardAttType = getWizardAttType(setupWizardEssentialInfo);
      dispatch(setWizardAttType(wizardAttType));

      // Set component show && remaining steps
      const counts = getWizardDataStepsCounts(setupWizardEssentialInfo);
      setIsShow(!counts.every((count) => Boolean(count)));
      dispatch(
        setSetupWizardRemainingSteps(
          counts.filter((count) => !Boolean(count)).length
        )
      );
    }
  }, [setupWizardLoading]);

  /* ↓ State Effects ↓ */

  return isShow ? (
    <div className="d-flex align-items-center setup-wizard">
      <div
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax={PROGRESS_STEPS_UI}
        aria-valuenow={setupWizardRemainingSteps}
        aria-label="Remaining wizard steps"
        className={clsx("setup-wizard-progress-bar", {
          hidden: setupWizardLoading,
        })}
      >
        <span
          aria-hidden="true"
          className="setup-wizard-progress-bar__progress"
          style={{
            width: `${
              100 - (setupWizardRemainingSteps * 100) / PROGRESS_STEPS_UI
            }%`,
          }}
        />
      </div>

      <Link
        to="/setup-wizard"
        className="setup-wizard-btn-continue d-flex justify-content-center align-items-center"
      >
        <span className="setup-wizard-link">{t("continue setup")}</span>
      </Link>
    </div>
  ) : null;
};

export default SetupWizardComponent;
