import React from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ADMIN_PRIVILEGES } from "../../Constants/Nav";
import Privilages from "../../Constants/Privilages";

const ProtectedRouter = ({
  authPrivileges,
  privileges = [],
  children,
  allowBP,
  userPrivileges,
  planPrivileges,
  ...props
}) => {
  const avaliablePrivileges = planPrivileges?.map(({ id }) => id);

  if (
    allowBP &&
    !privileges?.length &&
    authPrivileges?.indexOf(Privilages.BUSINESS_PARTNER_PRIVILEGES) !== -1
  ) {
    return <Route {...props}>{children}</Route>;
  } else if (
    allowBP &&
    authPrivileges?.indexOf(Privilages.BUSINESS_PARTNER_PRIVILEGES) !== -1 &&
    privileges?.some((id) => avaliablePrivileges?.indexOf(id) !== -1)
  ) {
    return <Route {...props}>{children}</Route>;
  } else if (
    privileges?.some((prev) => authPrivileges?.indexOf(prev) !== -1) &&
    privileges?.some((id) => avaliablePrivileges?.indexOf(id) !== -1)
  ) {
    return <Route {...props}>{children}</Route>;
  } else if (
    privileges?.some((prev) => ADMIN_PRIVILEGES?.indexOf(prev) !== -1)
  ) {
    return <Route {...props}>{children}</Route>;
  } else {
    return (
      <Route {...props}>
        <Redirect to="/unauthorized" />
      </Route>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    authPrivileges: state.auth?.userProfile?.prviliege_ids,
    planPrivileges:
      state.auth?.userProfile?.company?.activeSubscription?.plan?.privileges,
  };
};

export default connect(mapStateToProps)(ProtectedRouter);
