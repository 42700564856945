import Types from "../Actions/types";

const initState = {
  monthlyReportColumns: {
    department: 0,
    position: 0,
    emp_code: 0,
    month_year: 1,
    holidays: 0,
    weekends: 0,
    attended_days: 1,

    ontime: 1,

    ontime_total: 1,
    ontime_normal: 1,
    ontime_half: 1,

    late: 1,

    late_total: 1,
    late_normal: 0,
    late_half: 0,
    late_duration: 1,

    absent_total: 1,

    leaves: 1,

    normal_leaves: 1,
    emergency_leaves: 1,
    sick_leaves: 1,
    unpaid_leaves: 1,
    half_days: 1,

    total_checkIn_hours_secs: 0,

    overtime: 1,

    overtime_days: 0,
    overtime_hours: 1,

    permissionsColumn: 1,

    permissions: 0,
    permissions_time: 1,

    breaksColumn: 1,

    breaks: 0,
    breaks_time: 1,

    workHours: 1,

    expected_hours_secs: 0,
    total_hours_secs: 1,
    total_early_sign_out_time: 1,
    total_penalties_as_days: 1,
    total_penalties_as_fixed_amount: 1,
    total_penalties_as_hours: 1,
    total_over_time_hours: 0,
  },

  yearlyReportColumns: {
    department: 0,
    position: 0,
    emp_code: 0,
    month_year: 0,
    holidays: 0,
    weekends: 0,
    attended_days: 1,

    ontime: 1,

    ontime_total: 1,
    ontime_normal: 1,
    ontime_half: 1,

    late: 1,

    late_total: 1,
    late_normal: 0,
    late_half: 0,
    late_duration: 1,

    absent_total: 1,

    leaves: 1,

    normal_leaves: 1,
    emergency_leaves: 1,
    sick_leaves: 1,
    unpaid_leaves: 1,
    half_days: 1,

    remaningLeaves: 1,

    remaining_annual_leaves: 1,
    remaining_emergency_credit: 1,

    total_checkIn_hours_secs: 0,

    overtime: 1,

    overtime_days: 0,
    overtime_hours: 1,

    permissionsColumn: 1,

    permissions: 0,
    permissions_time: 1,

    breaksColumn: 1,

    breaks: 0,
    breaks_time: 1,

    workHours: 1,

    expected_hours_secs: 0,
    total_hours_secs: 1,
    total_early_sign_out_time: 1,
    total_penalties_as_days: 1,
    total_penalties_as_fixed_amount: 1,
    total_penalties_as_hours: 1,
    total_over_time_hours: 0,
  },
};

export const MonthlyReportReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.ON_CHECKBOX_BOOLEAN_INPUT_CHANGE:
      if (state[action.payload?.formName]) {
        return {
          ...state,
          [action.payload?.formName]: {
            ...state[action.payload?.formName],
            [action.payload?.event.target.name]: action.payload?.event.target
              .checked
              ? 1
              : 0,
          },
        };
      }
      return state;

    case Types.TOGGLE_MONTHLY_REPORT_CHECKBOX:
      return {
        ...state,
        monthlyReportColumns: {
          ...state.monthlyReportColumns,
          [action.payload?.ref]: action.payload?.value,
        },
      };

    case Types.TOGGLE_YEARLY_REPORT_CHECKBOX:
      return {
        ...state,
        yearlyReportColumns: {
          ...state.yearlyReportColumns,
          [action.payload?.ref]: action.payload?.value,
        },
      };

    case Types.ON_INPUT_RESET_WITH_VALUE:
      if ([action.payload.formName] in state) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]: action.payload?.resetValue,
          },
        };
      } else {
        return state;
      }

    case Types.LOG_OUT:
      return initState;

    default:
      return state;
  }
};
