import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";

import {
  addOfficeLocationAction,
  resetOfficeLocationAction,
  dismissOfficeLocationModalAction,
  addEmployeeLocationAction,
  setLocationsServerValidation,
  showErrorToast,
  showSuccessToast,
} from "../../Store/Actions";
import { InputForm } from "form-builder";
import LocationMarker from "./LocationMarker";
import { useTranslation } from "react-i18next";

import { useMutation } from "@apollo/client";
import { upsertLocationMutation } from "../../Graphql/mutation";

import PlacesInput from "./PlacesInput";
import { Config } from "../../Config";

const formName = `locationForm`;
const formNameValidation = `locationFormValidation`;

const AddLocationModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [upsertLocation, { loading }] = useMutation(upsertLocationMutation);

  // Reducer State
  const data = useSelector((state) => state.super[formName]);
  const modalOptions = useSelector(
    (state) => state.super.officeLocationModalActions
  );
  const locationValidation = useSelector(
    (state) => state.super.locationFormValidation
  );

  /* ↓ Helpers ↓ */

  const toggleModal = () => {
    dispatch(
      dismissOfficeLocationModalAction({
        ref: "officeLocationModalActions",
        formName,
      })
    );
    setFormSubmitting(false);
    dispatch(resetOfficeLocationAction());
  };

  const handleSubmitLocationForm = async () => {
    setFormSubmitting(true);

    if (locationValidation.length === 0) {
      dispatch(setLocationsServerValidation({}));

      try {
        const response = await upsertLocation({
          variables: {
            id: data?.id,
            name: data?.name,
            lng: data?.lng,
            lat: data?.lat,
            address: data?.address,
            max_distance: data?.max_distance,
            is_office: data?.office?.id ? 1 : 0,
            // office_id: data?.office?.id ? data?.office?.id : null,
          },
        });

        if (response?.errors?.[0]?.extensions?.category === "validation") {
          dispatch(
            setLocationsServerValidation(
              response?.errors?.[0]?.extensions?.validation
            )
          );
        }

        if (response?.errors?.[0].extensions?.category === "graphql") {
          dispatch(showErrorToast(response?.errors?.[0]?.message));
        }

        if (response.data.upsert_location.id) {
          toggleModal();

          if (props?.isListPage) {
            dispatch(showSuccessToast());

            props?.fetchLocations();
          }

          if (props.formName === "officeForm") {
            dispatch(
              addOfficeLocationAction(props.formName, {
                ...response?.data?.upsert_location,
              })
            );
          } else if (props.formName === "editEmployeeFormData") {
            dispatch(
              addEmployeeLocationAction(props.formName, {
                ...response?.data?.upsert_location,
              })
            );
          }
        }

        setFormSubmitting(false);
      } catch (error) {
        dispatch(showErrorToast(error?.message));
      }
    }
  };

  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
  };

  const modalTitle = () => {
    if (modalOptions?.isViewOnly) {
      return t("view location");
    }

    if (data?.id) {
      return t("edit location");
    }

    return t("new location");
  };

  return (
    <Modal
      isOpen={modalOptions.isVissible}
      toggle={toggleModal}
      centered
      fade={false}
    >
      <ModalHeader toggle={toggleModal}>{modalTitle()}</ModalHeader>

      <ModalBody>
        {!modalOptions?.isViewOnly ? (
          <>
            <div className="row">
              <div className="col-12 col-md-6">
                <InputForm
                  {...FormProps}
                  validateBy="textRequired"
                  name="name"
                  placeholder={t("name")}
                  label="name"
                  labelStyle="mb-2"
                  containerStyle="mt-0 mb-3"
                  inputContainerStyle=" "
                  icon="globe"
                />
              </div>

              <div className="col-12 col-md-6">
                <InputForm
                  {...FormProps}
                  validateBy="textRequired"
                  name="max_distance"
                  type="number"
                  placeholder={t("Maximum Distance (Meters)")}
                  label="maximum distance"
                  labelStyle="mb-2"
                  containerStyle="mt-0 mb-3"
                  inputContainerStyle=" "
                  icon="gps"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <InputForm
                  {...FormProps}
                  validateBy="textRequired"
                  name="lng"
                  placeholder={t("longitude")}
                  label={t("longitude")}
                  labelStyle="mb-2"
                  containerStyle="mt-0 mb-3"
                  inputContainerStyle=" "
                  autoComplete="off"
                  icon="location"
                />
              </div>

              <div className="col-12 col-md-6">
                <InputForm
                  {...FormProps}
                  validateBy="textRequired"
                  name="lat"
                  placeholder={t("latitude")}
                  label={t("latitude")}
                  labelStyle="mb-2"
                  containerStyle="mt-0 mb-3"
                  inputContainerStyle=" "
                  autoComplete="off"
                  icon="location"
                />
              </div>
            </div>

            <PlacesInput
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Config.googleMapKey}&libraries=places`}
              formProps={FormProps}
              name="address"
              loadingElement={
                <InputForm
                  {...FormProps}
                  validateBy="textRequired"
                  name="address"
                  type="text"
                  placeholder={t("address")}
                  label="address"
                  labelStyle="mb-2"
                  containerStyle="mt-0 mb-3"
                  inputContainerStyle=" "
                  icon="map"
                />
              }
            />
          </>
        ) : null}

        <LocationMarker />

        {!modalOptions?.isViewOnly ? (
          <div className="mt-3 mb-0 text-right">
            <button
              type="submit"
              onClick={handleSubmitLocationForm}
              className="btn btn-primary submit_btn_style"
            >
              {loading ? (
                <Spinner style={{ width: "1rem", height: "1rem" }} />
              ) : (
                t("save")
              )}
            </button>
          </div>
        ) : null}
      </ModalBody>
    </Modal>
  );
};

export default AddLocationModal;
