import React from "react";
import AdminUsers from "../../Containers/AdminUsers";

const ViewAdminUsers = (props) => {
  return (
    <>
      <AdminUsers />
    </>
  );
};

export default ViewAdminUsers;
