import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  deleteCompaniesAction,
  activateCompaniesAction,
  suspendCompaniesAction,
  showEditCompanyNameModalAction,
  showEditAccountManagersModalAction,
  showEditBussPartnersModalAction,
  deleteCompanyBussPartnersAction,
  showUpdateBussPartnerModalAction,
  resendCredentailsAction,
  setIsEmployeeImportModalOpen,
  sendEmailVerificationAction,
  sendPhoneVerificationAction,
} from "../../Store/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTimes,
  faPowerOff,
  faPauseCircle,
  faUserTie,
  faUserPlus,
  faMailBulk,
  faEnvelope,
  faCheck,
  faKey,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import EditCompanyNameModal from "./EditCompanyNameModal";
import EditAccountManagersModal from "./EditAccountManagersModal";
import AddBussPartnersModal from "./AddBussPartnersModal";
import EditBussPartnersModal from "./EditBussPartnersModal";
import Loader from "../../Components/Loader";
import Tooltip from "@mui/material/Tooltip";
import AddBulkEmployeesModalContent from "./AddBulkEmployeesModalContent";
import ErrosModalAddBulkEmployees from "./ErrosModalAddBulkEmployees";
import { Email, LocalPhone, Sms, WhatsApp } from "@mui/icons-material";

const CompanyProfileCard = (props) => {
  // const employeesLimit =
  //   props.currentSubscription?.plan?.features?.filter(
  //     (feature) => feature?.id === "1"
  //   )[0]?.pivot?.limits ?? Infinity;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // handling edit company name modal
  const handleEditCompanyName = () => {
    let data = {
      id: props?.id,
      company_name: props?.name,
    };

    props.showEditCompanyNameModalAction({
      data,
      ref: "editCompanyNameModalActions",
      formName: "editCompanyNameModal",
    });
  };

  // to activate company
  const handleActivateCompany = () => {
    swal({
      title: t("are you sure"),
      text: t("default_warning_activation_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("OK")],
    }).then((willActivate) => {
      if (willActivate) {
        props.activateCompaniesAction(props?.id);
      }
    });
  };

  // to suspend company
  const handleSuspendCompany = () => {
    swal({
      title: t("are you sure"),
      text: t("default_warning_suspension_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("OK")],
    }).then((willSuspend) => {
      if (willSuspend) {
        props.suspendCompaniesAction(props?.id);
      }
    });
  };

  // to delete company record
  const handleDeleteCompany = () => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteCompaniesAction(props?.id);
      }
    });
  };

  // handling edit company account managers modal
  const handleEditCompanyAccountManagers = () => {
    let data = {
      id: props?.id,
      company_name: props?.name,
      user_id: props?.saasUsers?.map((acm) => acm?.id),
    };

    props.showEditAccountManagersModalAction({
      data,
      ref: "editAccountManagersModalActions",
      formName: "editAccountManagersModal",
    });
  };

  // handling Add company bussiness partners modal
  const handleAddCompanyBussinessPartners = () => {
    let data = {
      id: props?.id,
      company_name: props?.name,
      partners_input: [{ id: null, company_id: props?.id }],
    };
    props.showEditBussPartnersModalAction({
      data,
      ref: "editCompanyBussPartnersModalActions",
      formName: "editCompanyBussPartnersModal",
    });
  };

  // handling edit company bussiness partners modal
  const handleEditCompanyBussinessPartner = (bp) => {
    let data = {
      id: bp?.id,
      company_id: props?.id,
      name: bp?.name,
      email: bp?.email,
      phone: bp?.phone,
      phone_country_code: bp?.phone_country_code,
    };

    props.showUpdateBussPartnerModalAction({
      data,
      ref: "updateBussPartnerModalActions",
      formName: "updateBussPartnerModal",
    });
  };

  // to delete company bussiness partner
  const handleDeleteCompanyBussPartner = (bp_id) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteCompanyBussPartnersAction(bp_id);
      }
    });
  };

  const hanldResendActivationEmail = (id) => {
    props.resendCredentailsAction(id);
  };


  const handleVerifyEmailBtn = (user) => {
    if (user?.email_verified) {
      return;
    }
    dispatch(sendEmailVerificationAction(null, user?.id, false));
  };

  const handleVerifyPhoneBtn = (send_via, user) => {
    if (user.phone_verified) {
      return;
    }
    dispatch(sendPhoneVerificationAction(
      null,
      user?.id,
      false,
      send_via
    ));
  };

  return (
    <>
      <div className="col-12 company_profile_card_wrapper_style employee-form px-0">
        <div className="boxContainer my-0">
          <div className="d-flex align-items-baseline justify-content-between">
            <div className="mb-2">
              <h4 className="sub-title-style mb-1">{props?.name}</h4>
              <hr />
            </div>

            <div className="d-flex align-items-center">
              <HasPrivileges
                reqireMain={[
                  Privilages.IMPORT_COMPANY_EMPLOYEES,
                  Privilages.SUPER_PRIVILEGE,
                ]}
              >
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={
                    props.hasActiveSubscription
                      ? !props.canAddNewEmployee
                        ? t("you have reached the maximum number of employees")
                        : ""
                      : t("Your company subscription has expired")
                  }
                >
                  <div className="d-inline-block">
                    <button
                      className={`employee_list_export_btn_style min-w-101 add_new_employee_btn_style align-items-center btn d-flex justify-content-center float-right ml-3 mr-3 ${!props.canAddNewEmployee ? "pe-none" : ""
                        }`}
                      type="button"
                      disabled={!props.canAddNewEmployee}
                      onClick={() => {
                        props.setIsEmployeeImportModalOpen(true);
                      }}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faFileUpload} />
                      <span>{t("import employee")}</span>
                    </button>
                  </div>
                </Tooltip>
              </HasPrivileges>
              <HasPrivileges
                reqireMain={[
                  Privilages.SUPER_PRIVILEGE,
                  Privilages.ADD_EDIT_COMPANY,
                ]}
              >
                <div className="cursor-pointer">
                  <FontAwesomeIcon
                    icon={faPen}
                    onClick={handleEditCompanyName}
                  />
                </div>
              </HasPrivileges>

              <HasPrivileges
                reqireMain={[
                  Privilages.SUPER_PRIVILEGE,
                  Privilages.SUSPEND_ACTIVATE_COMPANY,
                ]}
              >
                {props?.status == "active" ? (
                  <div className="mx-3 cursor-pointer">
                    <FontAwesomeIcon
                      icon={faPauseCircle}
                      onClick={handleSuspendCompany}
                    />
                  </div>
                ) : (
                  <div className="mx-3 cursor-pointer">
                    <FontAwesomeIcon
                      icon={faPowerOff}
                      onClick={handleActivateCompany}
                    />
                  </div>
                )}
              </HasPrivileges>

              <HasPrivileges
                reqireMain={[
                  Privilages.SUPER_PRIVILEGE,
                  Privilages.DELETE_COMPANY,
                ]}
              >
                <div className="cursor-pointer">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={handleDeleteCompany}
                  />
                </div>
              </HasPrivileges>
            </div>
          </div>

          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.VIEW_ASSIGNED_ACCOUNT_MANAGERS,
            ]}
          >
            <div className="d-flex my-2 align-items-baseline flex-wrap">
              <div>
                <h2>{t("account managers")}</h2>
              </div>

              {props?.saasUsers?.map((profileAccManagers) => (
                <div key={`${profileAccManagers?.id}`} className="mx-3">
                  <p>{profileAccManagers?.name}</p>
                </div>
              ))}

              <div className="cursor-pointer ml-2">
                <FontAwesomeIcon
                  icon={faPen}
                  onClick={handleEditCompanyAccountManagers}
                />
              </div>
            </div>

            <hr className="hr_custom_style my-2" />
          </HasPrivileges>

          <div className="row">
            <div className="col-12 d-flex align-items-baseline">
              <div className="mt-2">
                <h2>{t("business partners")}</h2>
              </div>

              <div className="ml-3">
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={
                    !props.canAddNewEmployee
                      ? t("you have reached the maximum number of employees")
                      : ""
                  }
                >
                  <HasPrivileges reqireMain={[Privilages.ADD_EDIT_BUSINESS_PARTNERS]}>
                    <div className="d-inline-block">
                      <button
                        className={`btn add_new_btn_style ${!props.canAddNewEmployee ? "pe-none" : ""
                          }`}
                        type="button"
                        onClick={handleAddCompanyBussinessPartners}
                        disabled={!props.canAddNewEmployee}
                      >
                        <FontAwesomeIcon icon={faUserPlus} />
                      </button>
                    </div>
                  </HasPrivileges>
                </Tooltip>
              </div>
            </div>
          </div>

          {props?.businessPartners?.map((profileBussinessPartner) => (
            <div
              key={`${profileBussinessPartner?.user?.id}`}
              className="my-1 px-3"
            >
              <div className="row align-items-center">
                <div className="d-flex align-items-baseline col-12 col-md-3 col-lg-2 col-xl-3 px-0">
                  <FontAwesomeIcon icon={faUserTie} />
                  <p className="mx-2">{profileBussinessPartner?.user?.name}</p>
                  <HasPrivileges
                    reqireMain={[Privilages.SEND_VERIFICATION_LINK]}
                  >
                    {(!profileBussinessPartner?.user?.checkPassword ||
                      !profileBussinessPartner?.user?.phone_verified ||
                      !profileBussinessPartner?.user?.email_verified) && (
                        <FontAwesomeIcon
                          icon={faKey}
                          color="#999"
                          className="cursor-pointer mr-3"
                          onClick={() =>
                            hanldResendActivationEmail(
                              profileBussinessPartner?.user?.id
                            )
                          }
                        />
                      )}
                  </HasPrivileges>
                </div>

                <div className="col-12 col-md-4 col-lg-3 col-xl-3 row">
                  <p className="text-lowercase text-break">
                    {profileBussinessPartner?.user?.email ?
                      <HasPrivileges
                        reqireMain={[
                          Privilages.ADD_EDIT_BUSINESS_PARTNERS,
                          Privilages.SUSPEND_ACTIVATE_BUSINESS_PARTNERS,
                          Privilages.SUPER_PRIVILEGE,
                        ]}
                        altChildren={<Email
                          sx={{
                            fontSize: 16,
                            color:
                              profileBussinessPartner?.user?.email_verified
                                ? "#27b40c"
                                : "#313030",
                          }}
                        />}
                      >
                        <button
                          disabled={profileBussinessPartner?.user?.email_verified}
                          onClick={() => handleVerifyEmailBtn(profileBussinessPartner?.user)}
                          className="border-0 btn"
                        >
                          <Email
                            sx={{
                              fontSize: 16,
                              color:
                                profileBussinessPartner?.user?.email_verified
                                  ? "#27b40c"
                                  : "#313030",
                            }}
                          />
                        </button>
                      </HasPrivileges>
                      : null}
                    {profileBussinessPartner?.user?.email}
                  </p>
                </div>

                <div className="col-12 col-md-3 col-lg-2 col-xl-3">
                  {profileBussinessPartner?.user?.phone == "" ? (
                    ""
                  ) : (
                    <p>

                      <HasPrivileges
                        reqireMain={[
                          Privilages.ADD_EDIT_BUSINESS_PARTNERS,
                          Privilages.SUSPEND_ACTIVATE_BUSINESS_PARTNERS,
                          Privilages.SUPER_PRIVILEGE,
                        ]}
                        altChildren={
                          <LocalPhone
                            sx={{
                              fontSize: 16,
                              marginInlineEnd: 2.5,
                              color:
                                profileBussinessPartner?.user?.phone_verified
                                  ? "#27b40c"
                                  : "#313030",
                            }}
                          />
                        }
                      >
                        <Tooltip
                          classes={{
                            tooltip: "custom_tooltip_style p-0",
                            arrow: "custom_arrow_tooltip_style",
                          }}
                          interactive={true}
                          arrow={true}
                          title={profileBussinessPartner?.user?.phone_verified ? undefined :
                            <>
                              <div className="click-to-verify-message">
                                {t("verify via")}
                              </div>
                              <div className="veriy-phone-buttons-container">
                                <Tooltip title={t("sms")}>
                                  <button
                                    onClick={() => handleVerifyPhoneBtn("sms", profileBussinessPartner?.user)}
                                    className="verify-phone-button"
                                  >
                                    <Sms className="verify-phone-sms-icon" />
                                  </button>
                                </Tooltip>
                                <span className="verify-phone-icons-separator"></span>
                                <Tooltip title={t("whatsapp")}>
                                  <button
                                    onClick={() => handleVerifyPhoneBtn("whatsapp", profileBussinessPartner?.user)}
                                    className="verify-phone-button"
                                  >
                                    <WhatsApp className="verify-phone-whatsapp-icon" />
                                  </button>
                                </Tooltip>
                              </div>
                            </>
                          }>
                          <LocalPhone
                            sx={{
                              fontSize: 16,
                              marginInlineEnd: 2.5,
                              color:
                                profileBussinessPartner?.user?.phone_verified
                                  ? "#27b40c"
                                  : "#313030",
                            }}
                          />
                        </Tooltip>
                      </HasPrivileges>

                      ({profileBussinessPartner?.user?.phoneCode})
                      {profileBussinessPartner?.user?.phone}
                    </p>
                  )}
                </div>

                <div className="col-12 col-md-2 col-lg-5 col-xl-3">
                  <HasPrivileges reqireMain={[Privilages.ADD_EDIT_BUSINESS_PARTNERS]}>

                    <FontAwesomeIcon
                      icon={faPen}
                      className="cursor-pointer"
                      onClick={() =>
                        handleEditCompanyBussinessPartner(
                          profileBussinessPartner?.user
                        )
                      }
                    />
                  </HasPrivileges>

                  <HasPrivileges reqireMain={[Privilages.DELETE_BUSINESS_PARTNERS]}>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="mx-3 cursor-pointer"
                      onClick={() =>
                        handleDeleteCompanyBussPartner(
                          profileBussinessPartner?.user?.id
                        )
                      }
                    />
                  </HasPrivileges>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Start of Edit Company Name Modal */}
      <EditCompanyNameModal />
      {/* End of Edit Company Name Modal */}

      {/* Start of Edit Company Account Managers Modal */}
      <EditAccountManagersModal />
      {/* End of Edit Company Account Managers Modal */}

      {/* Start of Add Company Bussiness Partners Modal */}
      <AddBussPartnersModal />
      {/* End of Add Company Bussiness Partners Modal */}

      {/* Start of Edit Company Bussiness Partners Modal */}
      <EditBussPartnersModal />
      {/* End of Edit Company Bussiness Partners Modal */}

      {props.resendCredentailsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}
      <AddBulkEmployeesModalContent />
      <ErrosModalAddBulkEmployees />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    resendCredentailsLoading: state.super?.resendCredentailsLoading,
  };
};

export default connect(mapStateToProps, {
  deleteCompaniesAction,
  activateCompaniesAction,
  suspendCompaniesAction,
  showEditCompanyNameModalAction,
  showEditAccountManagersModalAction,
  showEditBussPartnersModalAction,
  deleteCompanyBussPartnersAction,
  showUpdateBussPartnerModalAction,
  resendCredentailsAction,
  setIsEmployeeImportModalOpen,
})(CompanyProfileCard);
