import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { childInputChange } from "../../Store/Actions";
import PaymentFactors from "../../Constants/PaymentFactors";
import { RegulationTypes } from "../../Constants/RegulationTypes";

import { InputForm } from "form-builder";

const SocialSection = (props) => {
  const {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
    deductible,
    index,
  } = props;
  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userRegulationTypesOptions = useSelector(
    (state) => state.super?.userRegulationTypesOptions
  );
  useEffect(() => {
    calculatedInsurance();

    return () => {};
  }, [props.totalGross, props.type, props.relevantTaxInfo]);

  const taxInfo = props?.relevantTaxInfo;

  const handleChildInputChange = (event, childName, index) => {
    dispatch(
      childInputChange(
        formName,
        childName,
        event.target.name,
        index,
        event.target.value
      )
    );
  };

  const calculatedInsurance = () => {
    if (props.type === RegulationTypes.MAWARED_CUSTOM) {
      // update payemt factor
      let e = {
        target: {
          name: "payment_factor_id",
          value: PaymentFactors.FIXED_AMOUNT,
        },
      };
      handleChildInputChange(e, "salary_deductibles", index);
      return;
    }
    if (
      props.type === RegulationTypes.REGULATION_EGP &&
      props?.relevantTaxInfo
    ) {
      let insuredSalary = Math.min(
        Math.max(
          (props.totalGross * taxInfo?.insurance_salary_percentage) / 100,
          taxInfo?.min_insurance_salary
        ),
        taxInfo?.max_insurance_salary
      );

      // set insurance value
      let amount = (
        (insuredSalary * taxInfo?.employee_insurance_percentage) /
        100
      ).toFixed(2);

      let e = {
        target: {
          name: "value",
          value: isNaN(amount) ? 0 : amount,
        },
      };
      handleChildInputChange(e, "salary_deductibles", index);
      // update payemt factor
      let paymentFactorEvent = {
        target: {
          name: "payment_factor_id",
          value: PaymentFactors.PERCENT_OF_INSURED_SALARY,
        },
      };
      handleChildInputChange(paymentFactorEvent, "salary_deductibles", index);
      return;
    }
  };

  return (
    <div className="flex-fill order-one">
      <InputForm
        name="value"
        type="number"
        labelStyle="mb-2"
        label={t("social insurance")}
        onWheelCapture={(e) => {
          e.target.blur();
        }}
        placeholder={t("amount")}
        {...FormProps}
        // labelStyle="mb-0"
        containerStyle=""
        inputContainerStyle="w-100 text-center"
        validateBy="textRequired"
        value={deductible.value}
        onChange={(e) => handleChildInputChange(e, "salary_deductibles", index)}
        disabled={props.type === RegulationTypes.REGULATION_EGP}
        validationName={`input.user_salary_config_input.salary_deductibles.${index}.value`}
        hasTooltip={props.type === RegulationTypes.REGULATION_EGP}
        tooltipTitle={
          <ul>
            <li>min insurance = {taxInfo?.min_insurance_salary}</li>
            <li>max insurance = {taxInfo?.max_insurance_salary}</li>
            <li>
              insurance Salary Percentage ={" "}
              {taxInfo?.insurance_salary_percentage} %
            </li>
            <li>
              employee Insurance Percentage ={" "}
              {taxInfo?.employee_insurance_percentage} %
            </li>
            <li>
              min InsuredSalary = max(gross salary x insurance Salary
              Percentage, min insurance Salary)
            </li>
            <li>
              insuredSalary = min (min InsuredSalary , max insurance salary){" "}
            </li>
            <li>insurance = insuredSalary x employee Insurance Percentage </li>
          </ul>
        }
        icon="money"
      />
    </div>
  );
};

export default SocialSection;
