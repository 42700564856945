import React from "react";

import Privilages from "../../../Constants/Privilages";

import RemindersDashboard from "./RemindersDashboard";
import ActivitiesDashboard from "./ActivitiesDashboard";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";

const Dashboard = () => {
  return (
    <>
      <HasPrivileges reqireMain={[Privilages.VIEW_REMINDERS]}>
        <RemindersDashboard />
      </HasPrivileges>
      <HasPrivileges reqireMain={[Privilages.VIEW_ACTIVITIES]}>
        <ActivitiesDashboard />
      </HasPrivileges>
    </>
  );
};

export default Dashboard;
