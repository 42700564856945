import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const BarchartPlaceholder = () => {
  return (
    <div className="card-body">
      <Grid
        container
        spacing={2}
        direction="row"
        justify="space-evenly"
        alignItems="flex-end"
      >
        {[...Array(12).keys()]?.map((_, value) => (
          <Grid key={value} item>
            <Skeleton
              variant="rectangular"
              width={15}
              animation="wave"
              height={Math.min(Math.ceil(Math.random() * 7.5) * 50, 280)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default BarchartPlaceholder;
