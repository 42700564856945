import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import moment from "moment";
import HelperFns from "../../Helpers/HelperFns";

import { Box, Divider } from "@mui/material";
import MainModal from "../../Components/MainModal";

const OVERTIME_CALC_ID = "3";

const ViewSalaryConfigModal = (props) => {
  const { t } = useTranslation();

  // Constants
  const overtimeCalc = props?.userSalaryConfigCalculations?.find(
    (c) => c?.calculation?.id === OVERTIME_CALC_ID
  );

  /* ↓ Helpers ↓ */

  const formatCost = (cost) =>
    cost + " " + t(props?.userSalaryConfigDetail?.currency?.name);

  const getSum = (arr = []) =>
    formatCost(arr.reduce((acc, val) => acc + +val.amount, 0));

  return (
    <MainModal
      hasModalFooter={false}
      isOpen={props?.modal_isOpen}
      toggle={props?.modal_toggle}
      modalTitle={t("salary configuration")}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <time datetime={props?.start_at}>
            {moment(props?.start_at).format("Do MMM YYYY")}
          </time>{" "}
          -{" "}
          {props?.end_at ? (
            <time datetime={props?.end_at}>
              {moment(props?.end_at).format("Do MMM YYYY")}
            </time>
          ) : (
            t("now")
          )}
        </div>

        <div className="d-flex gap-10">
          <span
            className={clsx({
              "blue-color": props?.payment_interval !== "monthly",
            })}
          >
            {t("interval")}
          </span>
          <span
            className={clsx({
              "blue-color": props?.payment_interval === "monthly",
            })}
          >
            {t(props?.payment_interval)}
          </span>
        </div>
      </div>

      {/* Full/Part Time */}
      {props?.userSalaryConfigDetail?.calculate_salary_by === "full_time" ||
      props?.userSalaryConfigDetail?.calculate_salary_by === "part_time" ? (
        <div className="d-flex gap-20 mb-3">
          <strong className="dark-color text-16">{t("base_salary")}</strong>
          {formatCost(props?.userSalaryConfigDetail?.base_salary)}
        </div>
      ) : null}

      {/* Daily Rate */}
      {props?.userSalaryConfigDetail?.calculate_salary_by === "daily" ? (
        <div className="d-flex gap-20 mb-3">
          <strong className="dark-color text-16">{t("rate per day")}</strong>
          {formatCost(props?.userSalaryConfigDetail?.rate_per_day)}
        </div>
      ) : null}

      {/* Hourly Rate */}
      {props?.userSalaryConfigDetail?.calculate_salary_by === "hourly" ? (
        <div className="mb-3">
          <strong
            style={{ backgroundColor: "#f3f9ff" }}
            className="d-block dark-color text-16 mb-1 py-2 px-1"
          >
            {t("rate per hour")}
          </strong>
          <PerHourRates
            rates={props?.userSalaryConfigDetail?.hourlyRate}
            unit={
              t(props?.userSalaryConfigDetail?.currency?.name) + "/" + t("hour")
            }
            isEveryday={
              props?.userSalaryConfigDetail?.apply_hourly_rate_on_every_day
            }
          />
          <Divider sx={{ my: 1 }} />
          <RowUnit
            label={t("hours_per_paid_leave")}
            txt={props?.userSalaryConfigDetail?.hours_per_paid_leave}
          />
          <RowUnit
            label={t("paid_leave_hourly_rate")}
            txt={props?.userSalaryConfigDetail?.paid_leave_hourly_rate}
          />
        </div>
      ) : null}

      {/* Allowances */}
      <Unit
        name={t("allowances")}
        arr={props?.userSalaryConfigAllowances}
        txt={
          <div className="d-flex gap-10">
            total
            <b>{getSum(props?.userSalaryConfigAllowances)}</b>
          </div>
        }
        cb={(allowance) => (
          <RowUnit
            key={allowance?.id}
            label={allowance?.allowance?.name}
            txt={formatCost(allowance?.amount)}
          />
        )}
      />

      {/* Deductibles */}
      <Unit
        name={t("deductibles")}
        arr={props?.userSalaryConfigDeductibles}
        txt={
          <div className="d-flex gap-10">
            total
            <b>{getSum(props?.userSalaryConfigDeductibles)}</b>
          </div>
        }
        cb={(deductible) => (
          <RowUnit
            key={deductible?.id}
            label={deductible?.deductible?.name}
            txt={formatCost(deductible?.amount)}
          />
        )}
      />

      {/* Calculations */}
      <Unit
        name={t("calculations")}
        arr={props?.userSalaryConfigCalculations}
        cb={(calc, i) => {
          console.log("calc", calc);
          return calc?.calculation?.id !== OVERTIME_CALC_ID ? (
            <>
              <RowUnit
                label={calc?.calculation?.name.replace("1 ", "")}
                txt={
                  calc?.MonthOption?.id == 1 // if it's calendar month
                    ? t("Calendar Month")
                    : formatCost(
                        !!calc?.amount
                          ? parseFloat(calc?.amount)?.toFixed(2)
                          : 0
                      )
                }
                extra={t(calc?.PaymentFactor?.factor)}
              />
              {i === 1 ? (
                <>
                  <RowUnit
                    label={t("one_hour_bonus")}
                    txt={props?.userSalaryConfigDetail?.one_hour_bonus}
                  />
                  <RowUnit
                    label={t("one_hour_penalty")}
                    txt={props?.userSalaryConfigDetail?.one_hour_penalty}
                  />
                </>
              ) : null}
            </>
          ) : null;
        }}
        extra={
          <div className="mt-2">
            {overtimeCalc?.calculation?.name.replace("1 ", "")}: <br />
            {overtimeCalc?.userSalaryConfigCalculationOvertimeSettings?.map(
              (setting) => (
                <div
                  style={{ rowGap: 10 }}
                  className="d-flex flex-wrap gap-20 mt-1 px-1"
                >
                  <p className="font-weight-bold">{t("range")}</p>
                  <p>{`${moment(setting?.from, "hh:mm:ss").format(
                    "hh:mm A"
                  )} - ${moment(setting?.to, "hh:mm:ss").format(
                    "hh:mm A"
                  )}`}</p>
                  <p className="font-weight-bold">{t("factor")}</p>
                  <p>
                    {
                      <span className="blue-color">
                        {setting?.overtime_payment_factor}
                      </span>
                    }
                  </p>
                  <p className="m-0">
                    <b>{t("amount")}: </b>
                    {formatCost(
                      !!setting?.amount
                        ? parseFloat(setting?.amount)?.toFixed(2)
                        : 0
                    )}
                  </p>
                </div>
              )
            )}
          </div>
        }
      />
    </MainModal>
  );
};

const PerHourRates = ({ isEveryday, rates, unit }) => {
  const { t } = useTranslation();

  const formattedRates = HelperFns.serializeFetchedHourlyRating(rates);

  return isEveryday ? (
    <div className="d-flex gap-20">
      <span>{t("range")}: </span>
      <Rates rates={formattedRates?.Everyday} unit={unit} />
    </div>
  ) : (
    <>
      {Object?.keys(formattedRates)?.map((day) => (
        <div className="d-flex align-items-center gap-20 border-bottom py-2">
          <strong className="blue-color" style={{ width: 60 }}>
            {t(day.toLowerCase())}
          </strong>
          <Rates rates={formattedRates?.[day]} unit={unit} />
        </div>
      ))}
    </>
  );
};

const Rates = ({ rates, unit }) => {
  const getFormattedTime = (time) => moment(time, "hh:mm").format("hh:mm A");

  return (
    <div
      style={{ rowGap: 10 }}
      className="flex-1 d-flex flex-wrap gap-20 justify-content-between dark-color"
    >
      {rates?.map((rate, i) => (
        <div key={i} className="d-flex gap-20 justify-content-between">
          <b>
            {getFormattedTime(rate?.from)} - {getFormattedTime(rate?.to)}
          </b>
          <b>
            {rate?.rate} {unit}
          </b>
        </div>
      ))}
    </div>
  );
};

const Unit = ({ arr = [], name, txt = "", cb, extra = "" }) =>
  arr?.length ? (
    <div className="mb-2">
      <div
        style={{ backgroundColor: "#f3f9ff" }}
        className="d-flex align-items-center gap-20 mb-1 py-2 px-1"
      >
        <strong className="dark-color text-16">{name}</strong>
        {txt}
      </div>

      <Box
        sx={{
          rowGap: 1.25,
          columnGap: 2.5,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          justifyContent: "space-between",
        }}
      >
        {arr.map(cb)}
      </Box>

      {extra}
    </div>
  ) : null;

const RowUnit = ({ label, txt, extra = "" }) => (
  <div className="d-flex gap-20">
    <p className="m-0 w-100">{label}</p>
    <p className="m-0 w-100 font-weight-bold">{txt}</p>
    <p className="m-0 w-100">{extra}</p>
  </div>
);

export default ViewSalaryConfigModal;
