import gql from "graphql-tag";

export const fetchCompanyDocumentsQuery = gql`
  query fetchCompanyDocuments($hasEmployeesPrivileges: Boolean!) {
    profile {
      company {
        documentPresets {
          id
          name
          fields {
            id
            name
          }
          country_id
          country {
            short_name
          }
        }
        requiredDocuments {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
        optionalDocuments {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }

        employees {
          employee {
            requiredDocuments {
              id
              name
              country_id
              country {
                short_name
              }
              fields {
                id
                name
              }
            }
            optionalDocuments {
              id
              name
              country_id
              country {
                short_name
              }
              fields {
                id
                name
              }
            }
          }
        }
      }
    }
    countries {
      id
      name
      short_name
    }
    company_offices(first: 999) {
      data {
        id
        name
        requiredDocuments {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
        optionalDocuments {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
      }
    }
    company_departments(first: 999) {
      data {
        id
        name
        requiredDocuments {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
        optionalDocuments {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
      }
    }
    company_positions(first: 999) {
      data {
        id
        name
        requiredDocuments {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
        optionalDocuments {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
      }
    }
    employees_for_document(first: 999) @include(if: $hasEmployeesPrivileges) {
      data {
        user {
          id
          name
        }
        requiredDocuments {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
        optionalDocuments {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
      }
    }
  }
`;

export const fetchRequiredDocumentsQuery = gql`
  query fetchRequiredDocuments(
    $inputType: CompanyProfileMissingOrExpiredDocumentsInput
    $hasEmployeesPrivileges: Boolean!
  ) {
    company_profile_missing_documents(input: $inputType) {
      number_of_employees
      number_of_documents
      employees_with_missing_documents {
        documents {
          name
          id
          country {
            id
            short_name
          }
          fields {
            id
            name
          }
        }
        employee {
          user {
            id
            name
            company {
              id
            }
          }
        }
      }
    }
    company_profile_expired_user_documents(input: $inputType) {
      number_of_employees
      number_of_documents
      employees_with_Expired_user_documents {
        documents: user_documents {
          document {
            id
            name
            country {
              id
              short_name
            }
            fields {
              id
              name
            }
          }
        }
        employee {
          user {
            id
            name
            company {
              id
            }
          }
        }
      }
    }
    employees_for_document(first: 999) @include(if: $hasEmployeesPrivileges) {
      data {
        user {
          id
          name
        }
      }
    }
    profile {
      company {
        documentPresets {
          id
          name
          country {
            id
            short_name
          }
        }
      }
    }
  }
`;

export const fetchHistoryDocModalQuery = gql`
  query fetchHistoryDocModal($employee_id: ID!, $document_id: ID) {
    user_document_history(
      input: { employee_id: $employee_id, document_id: $document_id }
    ) {
      id
      files {
        id
        path
      }
      data
      isExpired
      created_at
      document {
        id
        name
        country {
          id
          short_name
        }
        fields {
          id
          name
        }
      }
    }
  }
`;
