const FETCH_REQUEST_RULES_LIST_ATTEMPT = "FETCH_REQUEST_RULES_LIST_ATTEMPT";
const FETCH_REQUEST_RULES_LIST_SUCCESS = "FETCH_REQUEST_RULES_LIST_SUCCESS";
const FETCH_REQUEST_RULES_LIST_FAILED = "FETCH_REQUEST_RULES_LIST_FAILED";

const SHOW_REQUEST_RULE_MODAL = "SHOW_REQUEST_RULE_MODAL";
const DISMISS_REQUEST_RULE_MODAL = "DISMISS_REQUEST_RULE_MODAL";

const FETCH_REQUEST_RULES_USERS_OPTS_SUCCESS =
  "FETCH_REQUEST_RULES_USERS_OPTS_SUCCESS";
const FETCH_REQUEST_RULES_USERS_OPTS_FAILED =
  "FETCH_REQUEST_RULES_USERS_OPTS_FAILED";

const FETCH_REQUEST_RULES_OPTS_ATTEMPT = "FETCH_REQUEST_RULES_OPTS_ATTEMPT";
const FETCH_DEPARTMENTS_OPTS_SUCCESS = "FETCH_DEPARTMENTS_OPTS_SUCCESS";
const FETCH_DEPARTMENTS_OPTS_FAILED = "FETCH_DEPARTMENTS_OPTS_FAILED";

const UPSERT_REQUEST_RULES_ATTEMPT = "UPSERT_REQUEST_RULES_ATTEMPT";
const UPSERT_REQUEST_RULES_SUCCESS = "UPSERT_REQUEST_RULES_SUCCESS";
const UPSERT_REQUEST_RULES_FAILED = "UPSERT_REQUEST_RULES_FAILED";

const DELETE_REQUEST_RULES_ATTEMPT = "DELETE_REQUEST_RULES_ATTEMPT";
const DELETE_REQUEST_RULES_SUCCESS = "DELETE_REQUEST_RULES_SUCCESS";
const DELETE_REQUEST_RULES_FAILED = "DELETE_REQUEST_RULES_FAILED";

export default {
  FETCH_REQUEST_RULES_LIST_ATTEMPT,
  FETCH_REQUEST_RULES_LIST_SUCCESS,
  FETCH_REQUEST_RULES_LIST_FAILED,
  SHOW_REQUEST_RULE_MODAL,
  DISMISS_REQUEST_RULE_MODAL,

  FETCH_REQUEST_RULES_USERS_OPTS_SUCCESS,
  FETCH_REQUEST_RULES_USERS_OPTS_FAILED,

  FETCH_REQUEST_RULES_OPTS_ATTEMPT,
  FETCH_DEPARTMENTS_OPTS_SUCCESS,
  FETCH_DEPARTMENTS_OPTS_FAILED,

  UPSERT_REQUEST_RULES_ATTEMPT,
  UPSERT_REQUEST_RULES_SUCCESS,
  UPSERT_REQUEST_RULES_FAILED,

  DELETE_REQUEST_RULES_ATTEMPT,
  DELETE_REQUEST_RULES_SUCCESS,
  DELETE_REQUEST_RULES_FAILED,
};
