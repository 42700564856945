import React from "react";
import { EmployeesTabs } from "../../Routes/EmployeesPage";
import LogsTabs from "../../Routes/LogsPage/LogsTabs";
import CRMTabs from "../../Routes/CRMPage/CRMTabs";
import SettingsTabs from "../../Routes/SettingsPage/SettingsTabs";
import AdjustmentsTabs from "../../Routes/ViewAdjustments/AdjustmentsTabs";
import ExpensesTabs from "../../Routes/ViewExpenses/ExpensesTabs";
import RecruitmentTabs from "../../Routes/ViewRecruitment/RecruitmentTabs";
import RequestsTabs from "../../Routes/ViewRequests/RequestsTabs";

function RouteSidebar(props) {
  if (props?.route === "employees") {
    return <EmployeesTabs isRouteSidebar={true} />;
  }
  if (props.route === "requests") {
    return <RequestsTabs isRouteSidebar={true} />;
  }
  if (props.route === "attendance") {
    return <LogsTabs isRouteSidebar={true} />;
  }
  if (props.route === "adjustments") {
    return <AdjustmentsTabs isRouteSidebar={true} />;
  }
  if (props.route === "expenses") {
    return <ExpensesTabs isRouteSidebar={true} />;
  }
  if (props.route === "recruitment") {
    return <RecruitmentTabs isRouteSidebar={true} />;
  }
  if (props.route === "settings") {
    return <SettingsTabs isRouteSidebar={true} />;
  }
  if (props.route === "crm") {
    return <CRMTabs isRouteSidebar={true} />;
  } else {
    return null;
  }
}

export default RouteSidebar;
