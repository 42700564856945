import React, { useState, useEffect } from "react";
import MyRequests from "../../Containers/MyRequests";
import MyBalance from "../../Containers/MyBalance";
import MyClaims from "../../Containers/MyClaims";
import { connect, useDispatch } from "react-redux";
import {
  setDataTableSortingAction,
  fetchProfileSuccess,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import HelperFns from "../../Helpers/HelperFns";
import {
  myBalanceQuery,
  myClaimsQuery,
  myProfileQuery,
  myRequestsQuery,
} from "../../Graphql/query";

const requestsRef = "myRequestsList";
const balanceRef = "myBalanceList";
const claimRef = `myClaimsList`;

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const MyGeneralTab = (props) => {
  const dispatch = useDispatch();

  const myRequestsList = useSelector((state) => state.user.myRequestsList);
  const myBalanceList = useSelector((state) => state.user.myBalanceList);
  const myClaimsList = useSelector((state) => state.user.myClaimsList);

  const [requestsPagination, setRequestsPagination] =
    useState(paginationInitState);
  const [balancePagination, setBalancePagination] =
    useState(paginationInitState);
  const [claimsPagination, setClaimsPagination] = useState(paginationInitState);

  const { loading: employeeProfileLoading } = useQuery(myProfileQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      if ("profile" in data) {
        dispatch(fetchProfileSuccess(data.profile));
      }
    },
  });

  const { data: employeeRequestsData, loading: employeeRequestsLoading } =
    useQuery(myRequestsQuery, {
      skip: !HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_REQUESTS],
        allowBP: true,
      }),
      variables: {
        rows: 10,
        page: requestsPagination.currentPage,
        field: myRequestsList.sorting.key,
        order: myRequestsList.sorting.dir.toUpperCase(),
        status: myRequestsList.statusFilter,
        month: myRequestsList.monthFilter ? myRequestsList.monthFilter : null,
        year: myRequestsList.yearFilter ? myRequestsList.yearFilter : null,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

  const { data: employeeBalanceData, loading: employeeBalanceLoading } =
    useQuery(myBalanceQuery, {
      skip: !HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_LEAVE_BALANCE_HISTORY],
        allowBP: true,
      }),
      variables: {
        rows: 10,
        page: balancePagination.currentPage,
        field: myBalanceList.sorting.key,
        order: myBalanceList.sorting.dir.toUpperCase(),
        type: myBalanceList.type,
        year: myBalanceList.yearFilter,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
    });

  const { data: employeeClaimsData, loading: employeeClaimsLoading } = useQuery(
    myClaimsQuery,
    {
      skip: !HelperFns.checkPrivileges({
        privileges: [Privilages.APPLY_FOR_CLAIM_REQUESTS],
        allowBP: true,
      }),
      variables: {
        claimRows: 10,
        claimPage: claimsPagination.currentPage,
        claimStatus: myClaimsList.status,
        claimMonth: myClaimsList.month ? myClaimsList.month : null,
        claimYear: myClaimsList.year ? myClaimsList.year : null,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
    }
  );

  useEffect(() => {
    return () => {
      setRequestsPagination(paginationInitState);
      setBalancePagination(paginationInitState);
      setClaimsPagination(paginationInitState);
    };
  }, []);

  const handleRequestsSorting = (field, dir) => {
    props.setDataTableSortingAction(requestsRef, field.selector, dir);
  };

  const handleRequestsPaginate = (
    page = props.data[requestsRef].pagination.currentPage
  ) => {
    setRequestsPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleBalanceSorting = (field, dir) => {
    props.setDataTableSortingAction(balanceRef, field.selector, dir);
  };

  const handleBalancePaginate = (
    page = props.data[balanceRef].pagination.currentPage
  ) => {
    setBalancePagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleClaimsPaginate = (
    page = props.data[claimRef].pagination.currentPage
  ) => {
    setClaimsPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const refetchQueries = [
    {
      query: myProfileQuery,
    },
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: myRequestsQuery,
            variables: {
              rows: 10,
              page: employeeRequestsData?.requests?.paginatorInfo?.currentPage,
              field: myRequestsList.sorting.key,
              order: myRequestsList.sorting.dir.toUpperCase(),
              status: myRequestsList.statusFilter,
              month: myRequestsList.monthFilter
                ? myRequestsList.monthFilter
                : null,
              year: myRequestsList.yearFilter
                ? myRequestsList.yearFilter
                : null,
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_LEAVE_BALANCE_HISTORY],
      allowBP: true,
    })
      ? [
          {
            query: myBalanceQuery,
            variables: {
              rows: 10,
              page: employeeBalanceData?.users_transactions?.paginatorInfo
                ?.currentPage,
              field: myBalanceList.sorting.key,
              order: myBalanceList.sorting.dir.toUpperCase(),
              type: myBalanceList.type,
              year: myBalanceList.yearFilter,
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.APPLY_FOR_CLAIM_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: myClaimsQuery,
            variables: {
              claimRows: 10,
              claimPage:
                employeeClaimsData?.myClaims?.paginatorInfo?.currentPage,
              claimStatus: myClaimsList.status,
              claimMonth: myClaimsList.month ? myClaimsList.month : null,
              claimYear: myClaimsList.year ? myClaimsList.year : null,
            },
          },
        ]
      : []),
  ];

  return (
    <div className="profile-detials">
      <div className="row align-items-start">
        <HasPrivileges reqireMain={[Privilages.VIEW_REQUESTS]}>
          <MyRequests
            reducer="user"
            dataTableRef={requestsRef}
            onSorting={handleRequestsSorting}
            onPaginate={handleRequestsPaginate}
            employeeRequestsData={employeeRequestsData?.requests?.data ?? []} // should provide
            pagination={employeeRequestsData?.requests?.paginatorInfo ?? {}} // should provide
            isLoading={employeeRequestsLoading}
            refetchQueries={refetchQueries} // should provide
          />
        </HasPrivileges>

        <HasPrivileges reqireMain={[Privilages.VIEW_LEAVE_BALANCE_HISTORY]}>
          <MyBalance
            reducer="user"
            dataTableRef={balanceRef}
            onSorting={handleBalanceSorting}
            onPaginate={handleBalancePaginate}
            user={props.profile}
            employeeBalanceData={
              employeeBalanceData?.users_transactions?.data ?? []
            } // should provide
            pagination={employeeBalanceData?.users_transactions?.paginatorInfo} // should provide
            isLoading={employeeBalanceLoading}
          />
        </HasPrivileges>
      </div>

      <HasPrivileges reqireMain={[Privilages.APPLY_FOR_CLAIM_REQUESTS]} allowBP>
        <div className="row">
          <MyClaims
            reducer="user"
            dataTableRef={claimRef}
            onPaginate={handleClaimsPaginate}
            employeeClaimsData={employeeClaimsData?.myClaims?.data ?? []}
            pagination={employeeClaimsData?.myClaims?.paginatorInfo ?? {}}
            isLoading={employeeClaimsLoading}
          />
        </div>
      </HasPrivileges>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.user.userProfile,
    data: state.user,
    isLoading: state?.user?.userProfileLoading,
  };
};

export default connect(mapStateToProps, {
  setDataTableSortingAction,
})(MyGeneralTab);
