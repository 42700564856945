import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import {
  ErrorOutline,
  Alarm,
  ArrowUpward,
  ArrowDownward,
  Home,
  Edit,
  GpsOff,
  Warning,
  FreeBreakfast,
  AccessTime,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  showViewCheckInsOutsLocationModalAction,
  handleAdjustSignOut,
  showEditHistoryModal,
  showEditAttRequestModal,
  showViewAttRequestModal,
  viewAttRequestModalAttempt,
  showPenaltyModal,
  toggleSignInBreaksModal,
  toggleLogsModal,
  adjustSignInOutIds,
} from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Swal from "sweetalert2";
import { Spinner } from "reactstrap";
import * as REQUESTS from "../../Constants/Requests";
import CheckInsCard from "./CheckInsCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  InfoIconButton,
  EditIconButton,
  PreviewIconButton,
} from "../IconButtonWithTooltip";
import CheckInsModal from "./CheckInsModal";
import { useLazyQuery } from "@apollo/client";
import { GET_SIGN_IN_LOGS } from "../../Graphql/query";

const InputTooltip = ({ title = "", tooltipStyle }) => {
  // To handle tooltip on click for mobile browsers
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  return (
    <div className={`${tooltipStyle ? tooltipStyle : "card-tooltip-style"}`}>
      <Tooltip
        arrow
        placement="top"
        open={tooltipIsOpen}
        onOpen={() => setTooltipIsOpen(true)}
        onClose={() => setTooltipIsOpen(false)}
        classes={{
          popper: "attendance_history_tooltip_style",
        }}
        title={title}
      >
        <ErrorOutline
          fontSize="inherit"
          color="inherit"
          onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
        />
      </Tooltip>
    </div>
  );
};

const checkInsModalInitState = { isOpen: false, data: null };

const EmployeeSignInRecordInfo = ({
  id,
  employeeRecord,
  signOutTooltipTitle,
  signOutTooltipStyle,
  durationTooltipTitle,
  durationTooltipStyle,
  isMyProfileHistory,
  isEmpProfileHistory,
  hideName = false,
  recordOrder,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checkInsOpened, setCheckInsOpened] = useState(false);
  const [checkInsModalState, setCheckInsModalState] = useState(
    checkInsModalInitState
  );
  const {
    isToday,
    from_home,
    date,
    status,
    sign_in_time,
    sign_out_time,
    auto_signed_out,
    created_at = null,
    creator = null,
    hasPendingEditAttendanceRequests = false,
    multiple_sign_ins,
    early_late,
    early_late_time,
    employee,
    penalties,
    signInOnBehalfBy,
    signOutOnBehalfBy,
    breakInOuts = [],
  } = employeeRecord;
  const superReducer = useSelector((state) => state.super);
  const isTablet = useMediaQuery("(max-width: 1024px)");

  const signInOutAdjustIds = useSelector(
    (state) => state?.super?.adjustSignInOutIds
  );

  const [attemptGetSignInLogs, { loading: signInLogsLoading }] = useLazyQuery(
    GET_SIGN_IN_LOGS,
    {
      onCompleted: (data) => {
        dispatch(
          toggleLogsModal({
            isOpen: true,
            data,
            name: employeeRecord?.employee?.name,
            date: employeeRecord?.date,
          })
        );
      },
    }
  );

  const handleOpenModal = () => {
    setCheckInsModalState({
      isOpen: true,
      data: { checkIns: employeeRecord.checkIns },
    });
  };
  const handleCloseModal = () => {
    setCheckInsModalState(checkInsModalInitState);
  };

  const statusDetails = () => {
    switch (status) {
      case "Ontime":
        return {
          title: t("ontime"),
          color: employeeRecord?.penalties?.length
            ? "red-color"
            : "green-color",
        };
      case "Absent":
        return { title: t("absent"), color: "red-color" };
      case "Major Tardiness":
        return { title: t("major late", { tier: 2 }), color: "orange-color" };
      case "Major Tardiness1":
        return { title: t("major late", { tier: 3 }), color: "orange-color" };
      case "Major Tardiness2":
        return { title: t("major late", { tier: 4 }), color: "orange-color" };
      case "Major Tardiness3":
        return { title: t("major late", { tier: 5 }), color: "orange-color" };
      case "Major Tardiness4":
        return { title: t("major late", { tier: 6 }), color: "orange-color" };
      case "Major Tardiness5":
        return { title: t("major late", { tier: 7 }), color: "orange-color" };
      case "Major Tardiness6":
        return { title: t("major late", { tier: 8 }), color: "orange-color" };
      case "Major Tardiness7":
        return { title: t("major late", { tier: 9 }), color: "orange-color" };
      case "Major Tardiness8":
        return { title: t("major late", { tier: 10 }), color: "orange-color" };
      case "Major Tardiness9":
        return { title: t("major late", { tier: 11 }), color: "orange-color" };
      case "Major Tardiness10":
        return { title: t("major late", { tier: 12 }), color: "orange-color" };
      case "Minor Tardiness":
        return { title: t("major late", { tier: 1 }), color: "orange-color" };
      case "commission":
        return { title: "commission", color: "blue-color" };
      default:
        return {
          title: t(status),
          color: employeeRecord?.penalties?.length ? "red-color" : "blue-color",
        };
    }
  };

  const showEditAttBtn =
    status == "Ontime" ||
    status == "Absent" ||
    status == "Major Tardiness" ||
    status == "Major Tardiness1" ||
    status == "Major Tardiness2" ||
    status == "Major Tardiness3" ||
    status == "Major Tardiness4" ||
    status == "Major Tardiness5" ||
    status == "Major Tardiness6" ||
    status == "Major Tardiness7" ||
    status == "Major Tardiness8" ||
    status == "Major Tardiness9" ||
    status == "Major Tardiness10" ||
    status == "Minor Tardiness";

  const duration = moment.duration(
    moment(sign_out_time).diff(moment(sign_in_time))
  );
  const durationHours =
    duration.hours() < 10 ? `0${duration.hours()}` : duration.hours();
  const durationMinutes =
    duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();

  const signOutValue = sign_out_time
    ? moment(sign_out_time).format("hh:mm A")
    : "--------";

  // handle view sign in location modal
  const handleViewSignInLocationModal = (e) => {
    // to pervent checkin section from toggling
    e.stopPropagation();
    let data = {
      lng: employeeRecord?.sign_in_lng,
      lat: employeeRecord?.sign_in_lat,
      face: employeeRecord?.sign_in_face?.path,
      location_name: employeeRecord?.sign_in_location?.name,
      name: employeeRecord?.employee?.name,
      date: employeeRecord?.date,
      type: "sign_in",
      signinOutLocationsModal: true,
    };
    dispatch(
      showViewCheckInsOutsLocationModalAction({
        data,
        ref: "viewCheckInOutLocationModalActions",
        formName: "viewCheckInOutLocationModal",
      })
    );
  };

  // handle view sign out location modal
  const handleViewSignOutLocationModal = (e) => {
    // to pervent checkin section from toggling
    e.stopPropagation();
    let data = {
      lng: employeeRecord?.sign_out_lng,
      lat: employeeRecord?.sign_out_lat,
      face: employeeRecord?.sign_out_face?.path,
      location_name: employeeRecord?.sign_out_location?.name,
      name: employeeRecord?.employee?.name,
      date: employeeRecord?.date,
      type: "sign_out",
      signinOutLocationsModal: true,
    };
    dispatch(
      showViewCheckInsOutsLocationModalAction({
        data,
        ref: "viewCheckInOutLocationModalActions",
        formName: "viewCheckInOutLocationModal",
      })
    );
  };

  const handleAdjustSignOutBtn = (e) => {
    e.stopPropagation();

    Swal.fire({
      icon: "warning",
      text: t("adjust sign out modal", {
        name: employeeRecord?.employee?.name,
      }),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        // container: "swal-title",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
      },
    }).then((values) => {
      if (values.isConfirmed) {
        dispatch(handleAdjustSignOut([employeeRecord?.id]));
      }
    });
  };

  const RenderEditActions = () =>
    !isMyProfileHistory ? (
      <div className="d-flex gap-10">
        {penalties?.length &&
          (employeeRecord?.canEdit ||
            employeeRecord?.getSignInConfigType == null) ? (
          <InfoIconButton
            onClick={() =>
              dispatch(
                showPenaltyModal({
                  formName: "editPenaltyModal",
                  ref: "editPenaltyModalActions",
                  data: {
                    date,
                    penalties,
                    sign_in_time,
                    sign_out_time,
                    balance: employee.balance,
                    employeeName: employee.name,
                  },
                })
              )
            }
          />
        ) : null}

        {employeeRecord?.logs?.length ? (
          <div className="sign-in-logs-button-container">
            {signInLogsLoading ? (
              <Spinner
                style={{
                  width: "1rem",
                  height: "1rem",
                  color: "#333",
                }}
              />
            ) : (
              <Tooltip title={t("view logs")}>
                <button
                  className="show-sign-in-logs-button-style"
                  onClick={handleShowLogs}
                >
                  <AccessTime
                    {...(employeeRecord?.edited_by_action?.length
                      ? {
                        style: {
                          color:
                            employeeRecord?.edited_by_action ==
                              "by_permission"
                              ? "#ee0"
                              : "red",
                        },
                      }
                      : {})}
                  />
                </button>
              </Tooltip>
            )}
          </div>
        ) : null}

        {employeeRecord?.canEdit ||
          employeeRecord?.getSignInConfigType == null ? ( // to update later if can edit or has no config means day off and shift ? (
          <div>
            <HasPrivileges
              reqireMain={[
                Privilages.EDIT_EMPLOYEE_ATTENDANCE,
                Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
              ]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={
                <EditIconButton iconProps={{ color: "#212529" }} />
              }
            >
              <EditIconButton
                onClick={handleEditEmployeeBtn}
                iconProps={{ color: "#212529" }}
              />
            </HasPrivileges>
          </div>
        ) : null}
      </div>
    ) : showEditAttBtn &&
      employeeRecord?.work_timing?.sign_in_req &&
      employeeRecord?.canEdit ? (
      <HasPrivileges
        reqireMain={[Privilages.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS]}
        avalibleOnExpire={false}
      >
        <button
          title={!employeeRecord?.canEdit ? t("can't edit") : null}
          disabled={!employeeRecord?.canEdit}
          type="button"
          className={`btn ${!isTablet && "edit_att_request_btn"} ${hasPendingEditAttendanceRequests && "has-pending"
            }`}
          onClick={
            hasPendingEditAttendanceRequests
              ? handleViewAttRequest
              : handleEditAttRequest
          }
        >
          {hasPendingEditAttendanceRequests ? (
            isTablet ? (
              <VisibilityIcon className="attendance_status_icon" />
            ) : (
              <span>{t("view request")}</span>
            )
          ) : isTablet ? (
            <Edit className="attendance_status_icon" />
          ) : (
            <span>{t("edit request")}</span>
          )}
        </button>
      </HasPrivileges>
    ) : null;

  const leavesTypes = [
    "Normal Leaves",
    "Emergency Leaves",
    "Unpaid Leaves",
    "Sick Leaves",
    "Bereavement",
    "Maternity_Paternity Leave",
    "Hajj Leave",
    "Study Leave",
    "Sabbatical Leave",
    "Marriage Leave",
  ];

  const handleEditEmployeeBtn = (e) => {
    // to pervent checkin section from toggling
    const { employee, date, id } = employeeRecord;
    e.stopPropagation();
    dispatch(showEditHistoryModal({ employee, date, id }));
  };

  const handleEditAttRequest = (e) => {
    // to pervent checkin section from toggling
    e.stopPropagation();
    dispatch(showEditAttRequestModal(employeeRecord));
  };

  const handleViewAttRequest = (e) => {
    // to pervent checkin section from toggling
    e.stopPropagation();
    dispatch(showViewAttRequestModal({}));
    dispatch(
      viewAttRequestModalAttempt({
        emp_id: employeeRecord?.employee?.id,
        date: employeeRecord?.date,
        type: REQUESTS.EDIT_ATTENDANCE,
      })
    );
  };

  const handleStatusDetails = () => {
    return employeeRecord?.penalties?.length
      ? employeeRecord?.penalties
        ?.map((penalty) => t(penalty?.penalty_reason))
        ?.join(" - ")
      : statusDetails().title;
  };

  const handleShowBreaks = () => {
    dispatch(
      toggleSignInBreaksModal({
        isOpen: true,
        breakInOuts,
        name: employeeRecord?.employee?.name,
        date: employeeRecord?.date,
      })
    );
  };

  const handleShowLogs = () => {
    attemptGetSignInLogs({
      variables: {
        signInId: employeeRecord?.id,
      },
    });
  };

  return (
    <>
      <div
        className={
          !(sign_in_time != null && sign_out_time == null && isToday == false)
            ? `sign-in-and-check-in-container status-${statusDetails().color} `
            : `sign-in-and-check-in-container missing_sign_out_style`
        }
      >
        <div
          className={`attendance-logs-card-container ${employeeRecord?.checkIns?.length > 0 ? "cursor-pointer" : ""
            }`}
        >
          {/* (Start) Employee Name */}
          <div
            className={`att_name_date_container ${!hideName && "align-items-center"
              }`}
            data-isprofile={isMyProfileHistory}
          >
            {employeeRecord?.employee?.name && !hideName ? (
              <CanViewEmployeeProfile
                allowBP
                directManger={employeeRecord?.employee?.manager?.id}
                copiedManagers={employeeRecord?.employee?.copied_managers?.map(
                  (cp) => cp?.id
                )}
                altChildren={
                  <div className="attendance_name">
                    {employeeRecord?.employee?.name.toLowerCase()}
                  </div>
                }
              >
                <div
                  className="attendance_name"
                  title={employeeRecord?.employee?.name}
                >
                  <Link
                    to={`/employees/employee-profile/${employeeRecord?.employee?.id}`}
                  >
                    {employeeRecord?.employee?.name.toLowerCase()}
                  </Link>
                </div>
              </CanViewEmployeeProfile>
            ) : (
              <div
                className={`attendance_name ${(isMyProfileHistory || isEmpProfileHistory) && "d-none"
                  }`}
              ></div>
            )}

            <HasPrivileges
              reqireMain={[
                Privilages.EDIT_EMPLOYEE_ATTENDANCE,
                Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
              ]}
              allowBP
              avalibleOnExpire={false}
            >
              {!isToday && sign_in_time && !sign_out_time ? (
                <input
                  type="checkbox"
                  className="mr-2"
                  onChange={(e) =>
                    e.target.checked
                      ? dispatch(adjustSignInOutIds({ operation: "add", id }))
                      : dispatch(
                        adjustSignInOutIds({ operation: "remove", id })
                      )
                  }
                  checked={signInOutAdjustIds?.includes(id)}
                />
              ) : null}
            </HasPrivileges>

            {/* DATE */}
            <div className={`attendance_date`}>
              {!isTablet
                ? moment(date).format("dddd DD-MM-YYYY")
                : moment(date).format("ddd DD-MM-YYYY")}

              {Boolean(from_home) && <Home className="home_icon" />}
            </div>
          </div>
          {/* (End) Employee Name */}

          {/* (Start) shift */}
          <div className="attendance_shift_container">
            <span className="attendance_shift_text">
              {multiple_sign_ins?.length || recordOrder > 1
                ? recordOrder == 1
                  ? t("sign-in-index", {
                    index: 1,
                    suffix: "st",
                    order: t("1st"),
                  })
                  : recordOrder == 2
                    ? t("sign-in-index", {
                      index: 2,
                      suffix: "nd",
                      order: t("2nd"),
                    })
                    : recordOrder == 3
                      ? t("sign-in-index", {
                        index: 3,
                        suffix: "rd",
                        order: t("3rd"),
                      })
                      : t("sign-in-index", {
                        index: recordOrder + 1,
                        suffix: "th",
                      })
                : null}
            </span>
          </div>
          {/* (End) shift */}

          {!sign_in_time ? (
            <div className="no_sign_in_time_status_container">
              {status?.toLowerCase() === "ontime"
                ? t("present")
                : t(status?.toLowerCase())}
            </div>
          ) : (
            <div className="signin-out-container">
              {/* Signin */}
              <div className="sign-in-check-in-container">
                <div className="icon_time_container">
                  <ArrowDownward className="sign_in_icon" />

                  <Tooltip
                    arrow
                    placement="top"
                    title={moment(sign_in_time)
                      .subtract(
                        early_late && early_late === "Before"
                          ? early_late_time
                          : "00:00:00",
                        "HH:mm:ss"
                      )
                      .format("hh:mm:ss A")}
                  >
                    {moment(date).isSame(moment(sign_in_time), 'day') ? <span className="sign_in_time">
                      {moment(sign_in_time).format("hh:mm A")}
                    </span> : <span className="sign_in_time">
                      {moment(sign_in_time).format("DD/MM/YYYY hh:mm A")}
                    </span>}

                  </Tooltip>
                </div>

                <div className="sign_in_location_container">
                  {employeeRecord?.sign_in_location?.name ? (
                    <p
                      className="sign_in_location mb-0"
                      onClick={handleViewSignInLocationModal}
                    >
                      {employeeRecord?.sign_in_location?.name}
                    </p>
                  ) : employeeRecord?.sign_in_lng &&
                    employeeRecord?.sign_in_lat ? (
                    <p
                      className="sign_in_location mb-0"
                      onClick={handleViewSignInLocationModal}
                    >
                      {t("unspecified location")}
                    </p>
                  ) : null}

                  {employeeRecord?.signed_in_outside_specified_locations && (
                    <Tooltip
                      placement="top"
                      title={t("Signd In Outside Specified Locations")}
                    >
                      <div>
                        <GpsOff
                          fontSize="inherit"
                          className="unspecified_location_icon"
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>

              {/* Signout */}
              <div className="sign-out-check-out-container">
                {signOutTooltipTitle || Boolean(auto_signed_out) ? (
                  <Tooltip
                    placement="top"
                    title={
                      signOutTooltipTitle
                        ? signOutTooltipTitle
                        : t("Auto Sign Out")
                    }
                  // className={`${
                  //   signOutTooltipStyle ? signOutTooltipStyle : ""
                  // }`}
                  >
                    <>
                      <div className="icon_time_container">
                        <ArrowUpward className="sign_out_icon" />

                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            sign_out_time
                              ? moment(sign_out_time).format("hh:mm:ss A")
                              : ""
                          }
                        >
                          <span className="sign_out_time">{signOutValue}</span>
                        </Tooltip>
                      </div>

                      <div className="sign_out_location_container">
                        <div className="sign_out_location">
                          {t("Auto Sign Out")}
                        </div>
                      </div>
                    </>
                  </Tooltip>
                ) : (
                  <>
                    <div className="icon_time_container">
                      <ArrowUpward className="sign_out_icon" />

                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          sign_out_time
                            ? moment(sign_out_time).format("hh:mm:ss A")
                            : ""
                        }
                      >
                        <span className="sign_out_time">{signOutValue}</span>
                      </Tooltip>
                    </div>

                    <div className="sign_out_location_container">
                      {sign_out_time ? (
                        employeeRecord?.sign_out_location?.name ? (
                          <p
                            className="sign_out_location mb-0"
                            onClick={handleViewSignOutLocationModal}
                          >
                            {employeeRecord?.sign_out_location?.name}
                          </p>
                        ) : employeeRecord?.auto_signed_out ? null : employeeRecord?.sign_out_lng &&
                          employeeRecord?.sign_out_lat ? (
                          <p
                            className="sign_out_location mb-0"
                            onClick={handleViewSignOutLocationModal}
                          >
                            {t("unspecified location")}
                          </p>
                        ) : null
                      ) : null}

                      {employeeRecord?.signed_out_outside_specified_locations ? (
                        <Tooltip
                          placement="top"
                          title={t("Signd out Outside Specified Locations")}
                        >
                          <div>
                            <GpsOff
                              fontSize="inherit"
                              className="unspecified_location_icon"
                            />
                          </div>
                        </Tooltip>
                      ) : null}
                    </div>
                  </>
                )}
              </div>

              {/* duration */}
              <div className="attendance_duration">
                <Alarm className="attendance_duration_icon" />

                <span className="attendance_duration_time">
                  {duration?.isValid()
                    ? `${durationHours}:${durationMinutes}`
                    : "-------"}
                </span>

                {(durationTooltipTitle || creator?.name) && (
                  <InputTooltip
                    title={
                      durationTooltipTitle ? (
                        durationTooltipTitle
                      ) : (
                        <>
                          {!signInOnBehalfBy?.name ? (
                            <span>
                              {t("signInOutInfo", {
                                user: creator?.name,
                                time: moment(created_at).format(
                                  "dddd DD-MMM-yyyy hh:mm A"
                                ),
                              })}
                              <br />
                            </span>
                          ) : null}
                          {signInOnBehalfBy?.name ? (
                            <>
                              {t("signInOnBehalf", {
                                user: signInOnBehalfBy?.name,
                                time: moment(
                                  signInOnBehalfBy?.sign_in_time
                                ).format("dddd DD-MMM-yyyy hh:mm A"),
                                employee: employeeRecord?.employee?.name,
                              })}
                              <br />
                            </>
                          ) : (
                            ""
                          )}
                          {signOutOnBehalfBy?.name
                            ? t("signOutOnBehalf", {
                              user: signOutOnBehalfBy?.name,
                              time: moment(
                                signOutOnBehalfBy?.sign_out_time
                              ).format("dddd DD-MMM-yyyy hh:mm A"),
                              employee: employeeRecord?.employee?.name,
                            })
                            : ""}
                        </>
                      )

                      // `${creator?.name} created this sign in on ${moment(
                      //     created_at
                      //   ).format("dddd DD-MMM-yyyy hh:mm A")}`
                    }
                    tooltipStyle={`attendance_duration_tooltip ${durationTooltipStyle}`}
                  />
                )}

                {!!sign_in_time && breakInOuts?.length ? (
                  <Tooltip title={t("show breaks")}>
                    <button
                      className="sign-in-show-breaks-button"
                      onClick={handleShowBreaks}
                    >
                      <FreeBreakfast />
                    </button>
                  </Tooltip>
                ) : null}
              </div>
            </div>
          )}

          <div className="attendance_checkin_container">
            {employeeRecord?.checkIns?.length > 0 ? (
              <div className="attendance_checkin d-flex align-items-center gap-10">
                <PreviewIconButton onClick={handleOpenModal} />
                <div
                  className={`attendance_checkin_btn ${checkInsOpened ? "attendance_checkin_btn_active" : ""
                    }`}
                  onClick={() => setCheckInsOpened((prev) => !prev)}
                >{`${employeeRecord?.checkIns?.length} ${t("Check-ins")}`}</div>
              </div>
            ) : null}
          </div>

          {/* <div
            className="adjust_signout_btn_container"
            data-isprofile={isMyProfileHistory}
          >
            {sign_in_time != null &&
              sign_out_time == null &&
              isToday == false ? (
              <HasPrivileges
                reqireMain={[
                  Privilages.EDIT_EMPLOYEE_ATTENDANCE,
                  Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
                ]}
                allowBP
                avalibleOnExpire={false}
              >
                <button
                  className="adjust_signout_btn text-nowrap px-0"
                  onClick={handleAdjustSignOutBtn}
                >
                  {superReducer?.adjustSignOutState?.id ===
                    employeeRecord?.id &&
                    superReducer?.adjustSignOutState?.isLoading ? (
                    <Spinner
                      style={{
                        width: "1rem",
                        height: "1rem",
                        color: "#ff6a6a",
                      }}
                    />
                  ) : (
                    t("adjust sign out")
                  )}
                </button>
              </HasPrivileges>
            ) : null}
          </div> */}

          {/* (Start) Status */}
          <div
            className="attendance_status"
            data-isprofile={isMyProfileHistory}
          >
            {sign_in_time ? (
              <div
                className={`attendance_status_title ${statusDetails().color}`}
                title={`${handleStatusDetails()} ${employeeRecord?.day_type?.toLowerCase() !== "normal"
                  ? `(${t(employeeRecord?.day_type)})`
                  : ""
                  }`}
              >
                {handleStatusDetails()}

                {employeeRecord?.day_type?.toLowerCase() !== "normal" &&
                  `(${t(employeeRecord?.day_type)})`}
              </div>
            ) : (
              <div
                className={`attendance_status_title ${statusDetails().color}`}
              />
            )}

            {employeeRecord?.attendance_configuration_conflict ? (
              <Tooltip
                arrow
                title={t(
                  "This record was not recalculated when the work timing was updated in the relevant configuration"
                )}
              >
                <Warning fontSize="small" sx={{ color: "#f2cf1b" }} />
              </Tooltip>
            ) : null}
            <RenderEditActions />
          </div>
        </div>

        {checkInsOpened ? (
          <CheckInsCard
            employeeRecord={employeeRecord}
            checkIns={employeeRecord?.checkIns ?? []}
            isMyProfileHistory={isMyProfileHistory}
          />
        ) : null}

        {checkInsModalState.isOpen ? (
          <CheckInsModal
            onClose={handleCloseModal}
            data={checkInsModalState.data}
          />
        ) : null}
      </div>
    </>
  );
};

export default EmployeeSignInRecordInfo;
