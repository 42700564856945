import React from "react";
import MainModal from "../../Components/MainModal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toggleLogsModal } from "../../Store/Actions";
import moment from "moment";
import DataTable from "react-data-table-component";
import Loader from "../../Components/Loader";

const LogsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const logsModalData = useSelector((state) => state.super?.logsModalData);

  const handleCloseModal = () => {
    dispatch(
      toggleLogsModal({ isOpen: false, data: [], name: null, date: null })
    );
  };

  const columns = [
    {
      name: t("on"),
      wrap: false,
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <div>
          <p className="m-0">{moment(row?.created_at).format("DD-MM-YYYY")}</p>
          <p className="m-0">{moment(row?.created_at).format("hh:mm:ss A")}</p>
        </div>
      ),
    },
    {
      name: t("operation"),
      wrap: false,
      sortable: false,
      grow: 1.2,
      cell: (row) => t(row.event.toLowerCase()),
    },
    {
      name: t("details"),
      wrap: false,
      sortable: false,
      grow: 7,
      cell: (row) => (
        <div className="py-1">
          <p>{JSON.parse(row?.description)?.msg}</p>

          <div>
            {Object.keys(JSON.parse(row?.description)?.attributes)?.map(
              (attributeName) => (
                <div className="d-flex align-items-center gap-10">
                  <p className="mb-0 attribute-name">{attributeName}</p>
                  {row?.event?.toLowerCase() === "created" ? (
                    <p className="mb-0 attribute-value">
                      {
                        JSON.parse(row?.description)?.attributes?.[
                          attributeName
                        ]
                      }
                    </p>
                  ) : (
                    <>
                      <p className="mb-0 attribute-property">{t("from")}</p>
                      <p className="mb-0 attribute-value">
                        {
                          JSON.parse(
                            JSON.parse(row?.description)?.attributes?.[
                              attributeName
                            ]
                          )?.from
                        }
                      </p>

                      <p className="mb-0 attribute-property">{t("to")}</p>
                      <p className="mb-0 attribute-value">
                        {
                          JSON.parse(
                            JSON.parse(row?.description)?.attributes?.[
                              attributeName
                            ]
                          )?.to
                        }
                      </p>
                    </>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      ),
    },
  ];

  const getDate = () => {
    return moment(logsModalData?.date ?? undefined).format("dddd DD-MM-YYYY");
  };

  return (
    <MainModal
      isOpen={logsModalData?.isOpen}
      toggle={handleCloseModal}
      hasModalFooter={false}
      modalTitle={t("change logs")}
      className="sign-in-logs-modal-container"
    >
      <div className="d-flex align-items-center gap-10 my-2">
        <h4 className="change-logs-user-name">{logsModalData?.name}</h4>
        <h5>{getDate()}</h5>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={logsModalData?.data?.signInOut?.logs}
        noHeader
        persistTableHead
        pagination={false}
        paginationServer={false}
        progressPending={false}
        progressComponent={<Loader />}
      />
    </MainModal>
  );
};

export default LogsModal;
