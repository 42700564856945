import React from "react";
import Skeleton from "@mui/material/Skeleton";

const WidgetPlaceholder = (props) => {
  const {
    dashContainerStyle = "dash-widget",
    dashInfoStyle = "dash-widget-info",
    btnStyle = "my-2",
  } = props;
  return (
    <div
      className={`${dashContainerStyle}`}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.15)",
        animation: "MuiSkeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite",
      }}
    >
      <div className={dashInfoStyle}>
        <h3 className="mb-2">
          <Skeleton variant="rectangular" width={50} height={50} />
        </h3>
        <Skeleton variant="rectangular" width={90} height={15} />
        <div className={btnStyle} onClick={props?.handleShowAllModal}>
          <Skeleton variant="rectangular" width={120} height={20} />
        </div>
      </div>
    </div>
  );
};

export default WidgetPlaceholder;
