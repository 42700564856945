import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import RequestRulesList from "../../Containers/RequestRulesList";
import Claims from "../../Containers/Claims";
import ViewRequests from "./ViewRequests";
import RequestsTabs from "./RequestsTabs";
import RequestsApprovalLayersList from "../../Containers/RequestApprovalLayers/RequestsApprovalLayersList";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const RequestsPage = (props) => {
  const history = useHistory();
  let query = useQuery();
  const activeValue = query.get("tab");

  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_EMPLOYEE_REQUESTS],
        allowBP: true,
      })
    ) {
      return history.push(`/requests?tab=inbound-requests`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_EMPLOYEE_CLAIMS],
        allowBP: true,
      })
    ) {
      return history.push(`/requests?tab=claims`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS,
          Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_MY_DEPARTMENT,
        ],
        allowBP: true,
      })
    ) {
      return history.push(`/requests?tab=request-rules`);
    }

    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_APPROVAL_LAYERS],
        allowBP: true,
      })
    ) {
      return history.push(`/requests?tab=approval-layers`);
    }
    return null;
  };

  const handleChange = (event, newValue) => {
    history.push(`/requests?tab=${newValue}`);
  };

  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "inbound-requests":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_REQUESTS]}
            allowBP
          >
            <ViewRequests />
          </HasPrivileges>
        );
      case "claims":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_EMPLOYEE_CLAIMS]} allowBP>
            <Claims />
          </HasPrivileges>
        );
      case "request-rules":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS,
              Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_MY_DEPARTMENT,
            ]}
            allowBP
          >
            <RequestRulesList />
          </HasPrivileges>
        );

      case "approval-layers":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_APPROVAL_LAYERS]} allowBP>
            <RequestsApprovalLayersList />
          </HasPrivileges>
        );
      default:
        break;
    }
  };
  return (
    <div className="content pt-3">
      <RequestsTabs />
      <div className="pt-4">{renderSelectedTab()}</div>
    </div>
  );
};

export default RequestsPage;
