import { SHOW_TOAST, HIDE_TOAST } from "./types";

/* Toast */

export const showSuccessToast = (msg, duration = 3000) => {
  return {
    type: SHOW_TOAST,
    payload: { msg, duration, type: "success" },
  };
};

export const showErrorToast = (msg, duration = 3000) => {
  return {
    type: SHOW_TOAST,
    payload: { msg, duration, type: "error" },
  };
};

export const showToast = (payload) => {
  return {
    type: SHOW_TOAST,
    payload,
  };
};

export const hideToast = () => {
  return {
    type: HIDE_TOAST,
  };
};
