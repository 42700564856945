import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import useCustomQuery from "../../Helpers/Hooks/useCustomQuery";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import {
  storeReminderMutation,
  changeReminderMutation,
} from "../../Graphql/mutation";
import moment from "moment";
import { activityTypeOptions } from "../../Constants/CRM";
import { buildOption, formatOptions } from "../../Helpers/HelperFns";
import { fetchLeadQuery, reminderFormQuery } from "../../Graphql/query";

import Loader from "../Loader";
import { Box } from "@mui/material";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import ContactModal from "./ContactModal";
import Dropzone from "react-dropzone-uploader";
import { BSelect, DateTimePickerForm } from "form-builder";
import { EditIconButton, TimesIconButton } from "../IconButtonWithTooltip";
import { clearCRMValidations, setCRMValidations } from "../../Store/Actions";

const utcOffsetMin = moment().utcOffset();
const formNameValidation = "reminderClientValidation";
const formServerValidation = "reminderServerValidation";
const contactModalInitState = { isOpen: false, isEdit: false, data: null };

const ReminderModal = ({
  data,
  isLeadList,
  isLeadProfile,
  leadProfileData,
  onClose,
  refetchList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEdit = Boolean(data) && !isLeadList;

  // Local State
  const [options, setOptions] = useState({ leads: [] });
  const [contactModalState, setContactModalState] = useState(
    contactModalInitState
  );
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isFetchingInputDepend, setIsFetchingInputDepend] = useState(isEdit);
  const [formData, setFormData] = useState({
    id: null,
    lead: isLeadList ? data : leadProfileData || null,
    type: null,
    contact: null,
    due_date: null,
    notes: "",
    attachments: [],
  });

  // Reducer State
  const reminderClientValidation = useSelector(
    (state) => state.crm[formNameValidation]
  );

  // Server State
  const [getLead, { loading: isLeadUpdate }] = useLazyQuery(fetchLeadQuery);
  const [storeReminder, { loading: isStoreLoading }] = useCustomMutation(
    storeReminderMutation
  );
  const [changeReminder, { loading: isChangeLoading }] = useCustomMutation(
    changeReminderMutation
  );
  const { loading: isDataLoading } = useCustomQuery(reminderFormQuery, {
    variables: {
      id: isLeadList ? undefined : data?.id,
      isReminderSkip: !isEdit,
      isLeadList,
    },
    onCompleted: ({ reminder, leads }) => {
      // Data
      if (isEdit) {
        setFormData({
          id: reminder?.id,
          lead: reminder?.lead,
          contact: reminder?.contact,
          savedAttachments: reminder.attachments,
          due_date: reminder?.due_date
            ? moment(reminder.due_date).add(utcOffsetMin, "minutes")
            : null,
          type: reminder.type
            ? buildOption(JSON.parse(reminder.type)?.name)
            : null,
        });
        setIsFetchingInputDepend(false);
      }

      // Options
      setOptions((prev) => ({ ...prev, leads: leads?.data || [] }));
    },
  });

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    !isFetchingInputDepend &&
      setFormData((prev) => ({ ...prev, contact: null }));
  }, [formData.lead?.id]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCRMValidations(formServerValidation));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleAddContactModal = () => {
    setContactModalState({
      isOpen: true,
      isEdit: false,
      data: { lead_id: formData.lead?.id },
    });
  };
  const handleEditContactModal = () => {
    setContactModalState({
      isOpen: true,
      isEdit: true,
      data: { lead_id: formData.lead?.id, ...formData.contact },
    });
  };

  const handleTextChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAttachmentChange = ({ file }, status) => {
    if (status === "done") {
      setFormData((prev) => ({
        ...prev,
        attachments: [...prev?.attachments, file],
      }));
    }
  };

  const handleSavedAttachmentRemove = (idx) => {
    setFormData((prev) => ({
      ...prev,
      savedAttachments: prev.savedAttachments.filter((_, i) => i !== idx),
    }));
  };

  const onUpsertContact = () => {
    getLead({
      variables: { id: formData.lead.id },
      onCompleted: ({ lead = {} }) => {
        setFormData((prev) => ({
          ...prev,
          lead,
          contact: prev.contact
            ? lead.contacts.find((contact) => contact.id === prev.contact.id)
            : null,
        }));
        setOptions((prev) => ({
          ...prev,
          leads: prev.leads.map((l) => (l.id === lead.id ? lead : l)),
        }));
      },
    });
  };

  const handleSelectChange = (val, { name: key }) => {
    setFormData((prev) => ({ ...prev, [key]: val }));
  };

  const handleDateChange = (name, val) => {
    setFormData((prev) => ({ ...prev, [name]: val }));
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (reminderClientValidation.length) return;

    const upsert = isEdit ? changeReminder : storeReminder;
    const {
      id,
      type,
      lead,
      contact,
      due_date,
      notes,
      attachments,
      savedAttachments,
    } = formData;

    upsert({
      refetchList,
      onCloseModal: onClose,
      variables: {
        id,
        lead_id: lead?.id || undefined,
        contact_id: contact?.id || undefined,
        due_date: due_date
          ? due_date
              .subtract(utcOffsetMin, "minutes")
              .format("YYYY-MM-D HH:mm:ss")
          : undefined,
        type: type?.name ? JSON.stringify({ name: type.name }) : undefined,
        notes: notes || undefined,
        attachments: attachments?.length ? attachments : undefined,
        ...(isEdit && {
          attachmentIds: savedAttachments.map((a) => +a.id),
        }),
      },
      onError: (err) => {
        err[0]?.extensions?.validation &&
          dispatch(
            setCRMValidations({
              ref: formServerValidation,
              validations: err[0]?.extensions?.validation,
            })
          );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      modalTitle={t(`${isEdit ? "edit" : "new"} reminder`)}
      btnSubmitLoading={isStoreLoading || isChangeLoading}
    >
      {(isEdit && isDataLoading) || isLeadUpdate ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex flex-column gap-10">
        {isLeadList || isLeadProfile ? (
          <>
            <strong>{t("lead")}</strong>
            <span>{formData.lead.name}</span>
          </>
        ) : (
          <BSelect
            name="lead"
            label="lead"
            icon="employee"
            keepDefaultStyle
            value={formData.lead}
            options={options?.leads}
            onChange={handleSelectChange}
            isLoading={isDataLoading}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        )}
        <div>
          <div className="d-flex align-items-center gap-20 mb-2">
            {t("lead contact")}
            <AddButton
              isIconOnly
              onClick={handleAddContactModal}
              disabled={!Boolean(formData.lead?.id)}
            />
            <EditIconButton
              onClick={handleEditContactModal}
              disabled={!Boolean(formData.contact?.id)}
              tooltipProps={{ isDisable: !Boolean(formData.contact?.id) }}
            />
          </div>
          <BSelect
            name="contact"
            icon="employees"
            keepDefaultStyle
            value={formData.contact}
            onChange={handleSelectChange}
            options={formData.lead?.contacts || []}
            isDisabled={!Boolean(formData.lead?.name)}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        </div>
        <BSelect
          label="reminder type"
          name="type"
          icon="employee"
          keepDefaultStyle
          labelStyle="w-100"
          value={formData.type}
          onChange={handleSelectChange}
          options={formatOptions(activityTypeOptions)}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
        />
        <DateTimePickerForm
          hasIcon
          dateTime
          label="due date"
          labelStyle="w-100"
          value={formData.due_date}
          datePickerContainer="w-100"
          onSelect={(val) => handleDateChange("due_date", val)}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          validationName="due_date"
          reducer="crm"
        />

        <label className="w-100 mt-2">
          <span>{t("Notes")}</span>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleTextChange}
            style={{ resize: "none" }}
          />
        </label>
        <span>{t("Attachments")}</span>
        {isEdit ? (
          <div>
            {formData?.savedAttachments?.map((attachment, i) => (
              <div key={i} className="d-flex gap-20 align-items-center py-2">
                {attachment?.name}
                <TimesIconButton
                  onClick={() => handleSavedAttachmentRemove(i)}
                />
              </div>
            ))}
          </div>
        ) : null}
        <Box
          className="documents_dropzone_style"
          sx={{
            ".dzu-dropzone .dzu-previewContainer": {
              height: 60,
              justifyContent: "center",
              minHeight: "initial !important",
              ".dzu-previewStatusContainer": { position: "static" },
            },
          }}
        >
          <Dropzone onChangeStatus={handleAttachmentChange} />
        </Box>
      </div>

      {contactModalState.isOpen ? (
        <ContactModal
          state={contactModalState}
          onUpsertContact={onUpsertContact}
          onClose={() => setContactModalState(contactModalInitState)}
        />
      ) : null}
    </MainModal>
  );
};

export default ReminderModal;
