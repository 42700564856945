import React, { useState } from "react";
import { connect } from "react-redux";

import { OutlinedInput } from "@mui/material";

import { verifyUserEmailAction } from "../../Store/Actions";
import { Spinner } from "reactstrap";
import LoginLayout from "../../Components/LoginLayout";
import { useParams, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const VerifyEmailPage = (props) => {
  const { t } = useTranslation();
  // Data from sent email
  let query = useQuery();

  const name = query.get("name").replaceAll(`"`, "");
  const email = query.get("email");
  const email_token = query.get("email_token");

  const handleSubmitBtn = (e) => {
    e.preventDefault();

    props.verifyUserEmailAction(email_token);
  };

  return (
    <LoginLayout>
      <form>
        <h4 className="text-capitalize">{t("verify email")}</h4>
        <div className="d-flex flex-column login_input_wrapper_style my-2">
          <label>{t("name")}</label>
          <OutlinedInput
            type="text"
            value={name}
            disabled
            classes={{
              root: "custom_outlined_input_style",
            }}
          />
        </div>
        <div className="d-flex flex-column login_input_wrapper_style my-2">
          <label>{t("email")}</label>
          <OutlinedInput
            type="text"
            value={email}
            disabled
            classes={{
              root: "custom_outlined_input_style",
            }}
          />
        </div>

        <div className="d-flex mt-4 pt-2">
          <button
            className="btn btn-block login_btn_style mx-1 mt-0 row justify-content-between text-capitalize"
            onClick={handleSubmitBtn}
            type="submit"
          >
            {props.verifyEmailLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                size="sm"
                color="#fff"
              />
            ) : (
              t("verify")
            )}
          </button>

          <Link
            to={`/login`}
            style={{
              pointerEvents: props.verifyEmailLoading ? "none" : "auto",
            }}
            className="btn btn-block cancel_btn_style mx-1 mt-0 text-capitalize"
          >
            {t("Cancel")}
          </Link>
        </div>
      </form>
    </LoginLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    verifyEmailLoading: state.user.verifyEmailLoading,
  };
};

export default connect(mapStateToProps, {
  verifyUserEmailAction,
})(VerifyEmailPage);
