import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { BSelect, InputForm, DateTimePickerForm } from "form-builder";
import { connect } from "react-redux";
import {
  dismissAddSubscriptionModalAction,
  upsertCompanySubscriptionAction,
  onInputResetAction,
  updateEmployeeLimitValueAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import usePrevious from "../../Helpers/Hooks/usePrevious";

const formName = "addSubscriptionModal";
const formNameValidation = "addSubscriptionModalValidation";
const formServerValidation = "addSubscriptionModalServerValidation";

const AddSubscriptionModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    props.upsertCompanySubscriptionAction({
      data: props.addSubscriptionModal,
      ref: "addSubscriptionModalActions",
    });
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissAddSubscriptionModalAction({
      ref: "addSubscriptionModalActions",
      formName,
    });
  };

  // to get the previous values using usePrevious hook
  const prevPlanId = usePrevious(props?.addSubscriptionModal?.plan_id);

  // handle employees limit according to plan selected
  useEffect(() => {
    if (prevPlanId && prevPlanId !== props.addSubscriptionModal?.plan_id) {
      props.updateEmployeeLimitValueAction(
        props?.data?.companySubscriptionPlans
          ?.filter(
            (plan) => plan?.id === props?.addSubscriptionModal?.plan_id
          )?.[0]
          ?.features?.filter((feature) => feature?.id === "1")[0]?.pivot
          ?.limits,
        formName
      );
    }
  }, [props?.addSubscriptionModal?.plan_id]);

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  // useEffect(() => {
  //   if (
  //     props?.addSubscriptionModal?.plan_id &&
  //     props?.data?.companySubscriptionPlans?.filter(
  //       (subscribtion) =>
  //         subscribtion?.id == props?.addSubscriptionModal?.plan_id
  //     )[0]?.trial_value == null
  //   ) {
  //     props.onInputResetAction(formName, "currency_id");
  //     props.onInputResetAction(formName, "payment_amount");
  //   }

  //   return () => {};
  // }, [props?.addSubscriptionModal?.plan_id]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t(
        `${
          props?.addSubscriptionModal?.id != null ? "Edit" : "Add"
        } Subscription`
      )}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="company_form_wrapper_style"
    >
      <>
        <div className="row employee-form px-3">
          <div className="col-12 my-0">
            <h4 className="sub-title-style mb-1">{t("Subscription")}</h4>
            <hr />

            <div className="row mt-3 mb-2">
              <div className="col-12 col-md-6 mb-2">
                <BSelect
                  {...FormProps}
                  name="plan_id"
                  validateBy="textRequired"
                  validationName={`input.plan_id`}
                  label="Plan"
                  labelStyle="mb-2"
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  options={props?.data?.companySubscriptionPlans}
                  inputContainerStyle=""
                  placeholder={t("Select Plan")}
                  getOptionLabel={(option) =>
                    `${option?.name} - ${
                      option?.trial_value == null ? t("free") : t("paid")
                    }`
                  }
                  icon="type"
                />
              </div>

              <div className="col-12 col-md-6 mb-2">
                <InputForm
                  {...FormProps}
                  type="number"
                  validateBy="textRequired"
                  name="employees_limit"
                  validationName={`input.employees_limit`}
                  placeholder={t("Enter limit")}
                  label="Employees limit"
                  labelStyle="mb-2"
                  containerStyle="mt-0"
                  inputContainerStyle=" "
                />
              </div>

              {
                // props?.addSubscriptionModal?.plan_id &&
                // props?.data?.companySubscriptionPlans?.filter(
                //   (subscribtion) =>
                //     subscribtion?.id == props?.addSubscriptionModal?.plan_id
                // )[0]?.trial_value == null
                false ? null : (
                  <>
                    <div className="col-12 col-md-6 mb-2">
                      <BSelect
                        {...FormProps}
                        name="currency_id"
                        validateBy="textRequired"
                        validationName={`input.currency_id`}
                        label="Currency"
                        labelStyle="mb-2"
                        keepDefaultStyle
                        optionLabel="name"
                        optionValue="id"
                        options={props?.data?.companyCurrency}
                        inputContainerStyle=""
                        placeholder={t("select currency")}
                        icon="currency"
                      />
                    </div>

                    <div className="col-12 col-md-6 mb-2">
                      <InputForm
                        {...FormProps}
                        type="number"
                        validateBy="textRequired"
                        name="payment_amount"
                        validationName={`input.payment_amount`}
                        placeholder={t("Enter Amount")}
                        label="Payable Amount"
                        labelStyle="mb-2"
                        containerStyle="mt-0"
                        inputContainerStyle=" "
                        icon="money"
                      />
                    </div>
                  </>
                )
              }

              <div className="col-12 col-md-6 mb-2">
                <DateTimePickerForm
                  {...FormProps}
                  containerStyle="custom_datepicker_wrapper_style"
                  name="start_date"
                  validateBy="textRequired"
                  validationName={`input.start_date`}
                  label="start date"
                  labelStyle="mb-2"
                  hasIcon
                  minDate={new Date()}
                  requestFormat="yyyy-MM-DD"
                />
              </div>

              <div className="col-12 col-md-6 mb-2">
                <DateTimePickerForm
                  {...FormProps}
                  containerStyle="custom_datepicker_wrapper_style"
                  name="end_date"
                  label="end date"
                  labelStyle="mb-2"
                  validationName={`input.end_date`}
                  validateBy="end_date"
                  dependTime="start_date"
                  hasIcon
                  minDate={new Date()}
                  requestFormat="yyyy-MM-DD"
                />
              </div>
            </div>
          </div>

          {/* (Start) Error Message */}
          {modalMessage && isSubmitting && (
            <div className="warnig-msg-style">{modalMessage}</div>
          )}
          {/* (End) Error Message */}
        </div>
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.addSubscriptionModalActions,
  modalValidation: state.super[formNameValidation],
  addSubscriptionModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
  data: state.super,
});

export default connect(mapStateToProps, {
  dismissAddSubscriptionModalAction,
  upsertCompanySubscriptionAction,
  onInputResetAction,
  updateEmployeeLimitValueAction,
})(AddSubscriptionModal);
