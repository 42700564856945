import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";

import MainModal from "../MainModal";
import { Avatar } from "@mui/material";
import MultiMarkersMap from "../Maps/MultiMarkersMap";

const CheckInsModal = ({ data, onClose }) => {
  const { t } = useTranslation();

  const getMarkers = React.useCallback(() => {
    const markers = [];

    data?.checkIns.forEach((c, i) => {
      markers.push({
        name: `${i + 1}- ${t("Check In")} - ${moment(c?.check_in_time).format(
          "hh:mm A"
        )}`,
        position: [c?.check_in_lat, c?.check_in_lng],
        label: (
          <>
            <Avatar
              src={c?.checkInFace?.path}
              sx={{ width: 30, height: 30, mx: "auto", mb: 1 }}
            />
            <span className="d-block mb-1">order: {i + 1}</span>
            {t("Check In")} -{" "}
            {c?.checkInLocation?.name ? c?.checkInLocation?.name + " - " : null}
            {moment(c?.check_in_time).format("hh:mm A")}
            <span className="d-block px-2 mt-1">{c?.check_in_description}</span>
          </>
        ),
      });

      c?.check_out_lat &&
        markers.push({
          name: `${i + 1}- ${t("Check out")} - ${moment(
            c?.check_out_time
          ).format("hh:mm A")}`,
          position: [c?.check_out_lat, c?.check_out_lng],
          label: (
            <>
              <Avatar
                src={c?.checkOutFace?.path}
                sx={{ width: 30, height: 30, mx: "auto", mb: 1 }}
              />
              <span className="d-block mb-1">order: {i + 1}</span>
              {t("Check out")} -{" "}
              {c?.checkOutLocation?.name
                ? c?.checkOutLocation?.name + " - "
                : null}
              {moment(c?.check_out_time).format("hh:mm A")}
              <span className="d-block px-2 mt-1">
                {c?.check_out_description}
              </span>
            </>
          ),
        });
    });

    return markers;
  }, [JSON.stringify(data?.checkIns)]);

  return (
    <MainModal
      isOpen
      size="lg"
      toggle={onClose}
      modalTitle={t("Check-ins location")}
    >
      <MultiMarkersMap markers={getMarkers()} />
    </MainModal>
  );
};

export default CheckInsModal;
