import React from "react";

import PropTypes from "prop-types";

import "./style.scss";

const loader = require("../../Assets/img/circule.gif");

const Loader = ({ fixed = false, absolute = false, inner = false }) => {
  switch (true) {
    case inner:
      return (
        <div className="loader inner">
          <img src={loader} alt="Loading..." />
        </div>
      );

    case absolute:
      return (
        <div className="loader absolute">
          <img src={loader} alt="Loading..." />
        </div>
      );

    case fixed:
      return (
        <div className="loader fixed">
          <img src={loader} alt="Loading..." />
        </div>
      );

    default:
      return (
        <div>
          <div className="site-loader-inner">
            <img src={loader} alt="Loading..." />
          </div>
        </div>
      );
  }
};

Loader.propTypes = {
  inner: PropTypes.bool,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
};

export default Loader;
