import { client } from "./Client";
import {
  fetchCompanyDocumentsQuery,
  fetchHistoryDocModalQuery,
  fetchRequiredDocumentsQuery,
} from "../Graphql/query/DocumentsQueries";
import {
  storeDocumentableMutation,
  upsertDocumentMutation,
} from "../Graphql/mutation/DocumentsMuatations";

export const upsertDocument = ({ input }) => {
  return client.mutate({
    mutation: upsertDocumentMutation,
    variables: {
      input,
    },
  });
};

export const storeDocumentable = ({ input }) => {
  return client.mutate({
    mutation: storeDocumentableMutation,
    variables: {
      input,
    },
  });
};

export const fetchCompanyDocuments = ({
  company_id,
  hasEmployeesPrivileges,
}) => {
  return client.query({
    query: fetchCompanyDocumentsQuery,
    variables: {
      id: company_id,
      hasEmployeesPrivileges,
    },
  });
};

export const fetchRequiredDocuments = ({
  inputType,
  hasEmployeesPrivileges,
}) => {
  return client.query({
    query: fetchRequiredDocumentsQuery,
    variables: {
      inputType,
      hasEmployeesPrivileges,
    },
  });
};

export const fetchHistoryDocModal = ({ employee_id, document_id }) => {
  return client.query({
    query: fetchHistoryDocModalQuery,
    variables: {
      employee_id,
      document_id,
    },
  });
};
