import {
  SET_CRM_VALIDATION,
  CLEAR_CRM_VALIDATION,
} from "../Actions/CRMActions/types";

export const initState = {
  // Leads
  leadClientValidation: [],
  leadServerValidation: {},
  leadStatusClientValidation: [],
  assignLeadsClientValidation: [],

  // Activity
  activityClientValidation: [],
  activityServerValidation: {},

  // Reminders
  reminderClientValidation: [],
  reminderServerValidation: {},

  // Trials
  trialClientValidation: [],
  trialServerValidation: {},

  // Contacts
  contactServerValidation: [],
};

const CRMReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_CRM_VALIDATION: {
      return {
        ...state,
        [action.payload.ref]: action.payload.validations,
      };
    }
    case CLEAR_CRM_VALIDATION: {
      return {
        ...state,
        [action.payload.ref]: initState[action.payload.ref],
      };
    }

    default:
      return state;
  }
};

export default CRMReducer;
