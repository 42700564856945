import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { useDispatch, useSelector } from "react-redux";
import {
  dismissAddDocumentsModalAction,
  addUserDocumentAction,
  fetchUpsertDocModalAction,
  addUserDocumentFailed,
} from "../../Store/Actions";
import "react-dropzone-uploader/dist/styles.css";
import Loader from "../../Components/Loader";
import UpsertDocumentsModalForm from "./UpsertDocumentsModalForm";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";

const selectFields = ["6", "8"];
const formName = "upsertDocumentsModal";
const formNameValidation = "upsertDocumentsModalValidation";

const UpsertDocumentsModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reducer State
  const docFields = useSelector((state) => state.super?.docFields);
  const countryOpts = useSelector((state) => state.super?.countryOpts);
  const upsertDocumentsModal = useSelector((state) => state.super[formName]);
  const fetchDocLoading = useSelector((state) => state.super?.fetchDocLoading);
  const modalValidation = useSelector(
    (state) => state.super[formNameValidation]
  );
  const {
    isVissible,
    modalMessage,
    isLoading,
    document_name,
    document_id,
    user_document_id,
    country,
    userId,
  } = useSelector((state) => state.super.upsertDocumentsModalActions);

  /* ↓ Helpers ↓ */

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (modalValidation.length) {
      return;
    }

    if ("3" in upsertDocumentsModal && !upsertDocumentsModal["3"]) {
      return dispatch(
        addUserDocumentFailed("This document requires Attachements")
      );
    }

    let data = Object.keys(upsertDocumentsModal)
      // ?.filter((key) => key !== "3")
      ?.filter((key) => !["3", "files", 3].includes(key))
      .map((key) => ({
        key,
        value: upsertDocumentsModal[key],
      }));

    let req = {
      data,
      document_id,
      user_id: userId,
      files: !!upsertDocumentsModal["3"] ? [upsertDocumentsModal["3"]] : [],
      id: user_document_id,
      page_flag: props?.page_flag,
    };
    dispatch(
      addUserDocumentAction(
        req,
        HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_MISSING_EXPIRED_DOCUMENT],
        })
      )
    );
  };

  const toggleModal = () => {
    dispatch(
      dismissAddDocumentsModalAction({
        ref: "upsertDocumentsModalActions",
        formName,
      })
    );
  };

  /* ↓ State Effects ↓ */

  useEffect(() => {
    setIsSubmitting(false);
    const fetchCountries = Object.keys(upsertDocumentsModal)?.some((item) =>
      selectFields?.includes(item)
    );

    if (isVissible && fetchCountries) {
      dispatch(fetchUpsertDocModalAction());
    }
  }, [isVissible]);

  return (
    <MainModal
      isOpen={isVissible}
      btnLabelCancel="Cancel"
      btnOnCancelClick={toggleModal}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="documents_modal_wrapper_style"
      modalTitle={HelperFns.getCountryShortName({
        name: t(document_name?.toLowerCase()),
        country_id: country?.id,
        country: {
          short_name: country?.short_name,
        },
      })}
    >
      <div>
        {fetchDocLoading ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}

        {/* (Start) Modal Form */}
        <UpsertDocumentsModalForm
          formName={formName}
          formNameValidation={formNameValidation}
          isSubmitting={isSubmitting}
          selectFields={selectFields}
          docFields={docFields}
          countryOpts={countryOpts}
        />
        {/* (End) Modal Form */}

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style mt-3">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

export default UpsertDocumentsModal;
