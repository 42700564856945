import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomQuery from "../../Helpers/Hooks/useCustomQuery";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import HelperFns, {
  formatOptions,
  openSwalConfirm,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import {
  leadStatusOptions,
  activityTypeOptions,
  dateTimeListFormat,
} from "../../Constants/CRM";
import moment from "moment";
import Privilages from "../../Constants/Privilages";
import { activitiesListQuery } from "../../Graphql/query";
import { removeActivityMutation } from "../../Graphql/mutation";

import {
  CommentCell,
  LeadNameCell,
  ContactTooltip,
} from "../../Components/CRM";
import {
  ActivityModal,
  CommentModal,
  ReminderModal,
} from "../../Components/CRMFormModals";
import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
  DownloadFileIconButton,
} from "../../Components/IconButtonWithTooltip";
import Loader from "../../Components/Loader";
import Box from "@mui/material/Box";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import ReactShowMoreText from "react-show-more-text";
import { NotificationAdd } from "@mui/icons-material";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { BSelect, DateTimePickerForm, RadioboxForm } from "form-builder";

const utcOffsetMin = moment().utcOffset();
const modalInitState = { isOpen: false, data: null };
const modals = [
  { name: "activity", Modal: ActivityModal },
  { name: "comment", Modal: CommentModal },
  { name: "reminder", Modal: ReminderModal },
];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const ActivitiesList = ({ isLeadProfile, leadProfileData }) => {
  const { t } = useTranslation();

  // Local State
  const [activities, setActivities] = useState([]);
  const [options, setOptions] = useState({ leads: [], users: [] });
  const [modalsState, setModalsState] = useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );
  const [pagination, setPagination] = useState(paginationInitState);
  const [filters, setFilters] = useState({
    to: "",
    from: "",
    type: null,
    status: null,
    f_type: "All",
    assigned_to: null,
    lead: leadProfileData?.id || null,
  });

  // Server State
  const [removeActivity] = useCustomMutation(removeActivityMutation);
  const { refetch: refetchList, loading: isListLoading } = useCustomQuery(
    activitiesListQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        first: pagination.perPage,
        page: pagination.currentPage,
        ...formatSendingInputs({ ...filters, lead_id: filters.lead }),
        isOptionsSkip: Object.values(options).every((l) => l.length),
      },
      onCompleted: ({ activities, leads, users }) => {
        // Data
        setActivities(activities?.data);
        setPagination(activities?.paginatorInfo);

        // Options
        !Object.values(options).every((l) => l.length) &&
          setOptions({ users: users?.data || [], leads: leads?.data || [] });
      },
    }
  );

  // Constants
  const columns = [
    { name: t("type"), cell: ({ type }) => JSON.parse(type)?.name },
    {
      name: t("name"),
      cell: ({ lead }) => (
        <LeadNameCell lead={lead} isLeadProfile={isLeadProfile} />
      ),
    },
    { name: t("contact"), cell: ({ contact = {} }) => ContactTooltip(contact) },
    {
      name: t("date"),
      cell: ({ date }) =>
        moment(date).add(utcOffsetMin, "minutes").format(dateTimeListFormat),
    },
    {
      name: t("comments"),
      cell: ({ id, comments = [] }) => (
        <HasPrivileges reqireMain={[Privilages.VIEW_COMMENTS_ON_ACTIVITIES]}>
          <CommentCell
            comments={comments}
            onClick={() =>
              handleOpenModal("comment", { id, comments, type: "Activity" })
            }
          />
        </HasPrivileges>
      ),
    },
    {
      name: t("status"),
      cell: ({ status = [] }) => (
        <div>
          <strong className="d-block">{JSON.parse(status)?.[0]}</strong>
          {JSON.parse(status)?.[1]}
        </div>
      ),
    },
    { name: t("created by"), selector: (row) => row?.user?.name },
    {
      name: t("notes"),
      cell: ({ notes }) => (
        <ReactShowMoreText lines={1}>{notes}</ReactShowMoreText>
      ),
    },
    {
      name: t("attachments"),
      cell: ({ attachments = [] }) => (
        <div className="cards_table_actions">
          {attachments?.map((attachment) => (
            <DownloadFileIconButton
              key={attachment?.file?.id}
              href={attachment?.file?.path}
            />
          ))}
        </div>
      ),
    },
    {
      cell: (data) => (
        <>
          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_REMINDERS]}>
            <IconButtonWithTooltip
              label="add reminder"
              icon={<NotificationAdd fontSize="small" className="blue-color" />}
              onClick={() =>
                handleOpenModal("reminder", {
                  id: data?.lead?.id,
                  name: data?.lead?.name,
                  contacts: data?.lead?.contacts,
                })
              }
            />
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_ACTIVITIES]}>
            <EditIconButton
              onClick={() => handleOpenModal("activity", { id: data.id })}
            />
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.DELETE_ACTIVITIES]}>
            <RemoveIconButton onClick={() => handleRemoveActivity(data)} />
          </HasPrivileges>
        </>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handleOpenModal = (name, data = null) => {
    const modalState = { name, data, isOpen: true };

    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? modalState : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleRadioFilters = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleRemoveActivity = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeActivity({
          refetchList,
          isShowErrorModal: true,
          variables: { id: data.id },
        });
      }
    });
  };

  return (
    <>
      {/* Filters */}
      <div className="my-4">
        <Box
          className="align-items-end gap-20"
          sx={{
            display: { sm: "grid" },
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          {isLeadProfile ? null : (
            <BSelect
              isClearable
              name="lead"
              label="lead"
              icon="employee"
              keepDefaultStyle
              value={filters.lead}
              options={options.leads}
              onChange={handleSelectFilters}
              isLoading={!options.leads.length}
            />
          )}
          <BSelect
            isClearable
            name="status"
            label="lead status"
            icon="employee"
            keepDefaultStyle
            value={filters.status}
            onChange={handleSelectFilters}
            options={formatOptions(leadStatusOptions)}
          />
          <BSelect
            isClearable
            name="type"
            label="type"
            icon="employee"
            keepDefaultStyle
            value={filters.type}
            onChange={handleSelectFilters}
            options={formatOptions(activityTypeOptions)}
          />
          <BSelect
            isClearable
            icon="employee"
            name="assigned_to"
            label="assigned to"
            keepDefaultStyle
            options={options.users}
            value={filters.assigned_to}
            onChange={handleSelectFilters}
            isLoading={!options.users.length}
          />
          <DateTimePickerForm
            hasIcon
            isClearable
            label="from"
            labelStyle="w-100"
            value={filters.from}
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("from", val)}
          />
          <DateTimePickerForm
            hasIcon
            label="to"
            isClearable
            labelStyle="w-100"
            value={filters.to}
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("to", val)}
          />
        </Box>

        <div
          className={`d-flex align-items-end mt-4 ${
            !isLeadProfile &&
            HelperFns.checkPrivileges({
              allowBP: false,
              privileges: [
                Privilages.VIEW_ACTIVITIES_OF_ASSIGNED_LEAD,
                Privilages.VIEW_ACTIVITIES_OF_OTHER_AGENTS,
              ],
            })
              ? "justify-content-between"
              : "justify-content-end"
          }`}
        >
          {isLeadProfile ? null : (
            <HasPrivileges
              reqireMain={[
                Privilages.VIEW_ACTIVITIES_OF_ASSIGNED_LEAD,
                Privilages.VIEW_ACTIVITIES_OF_OTHER_AGENTS,
              ]}
            >
              <RadioboxForm
                name="f_type"
                value={filters.f_type}
                optionInputStyle=" "
                containerStyle="mb-0"
                optionItemStyle="d-inline-block mx-2"
                optionsContainerStyle="d-flex gap-10"
                options={[
                  {
                    label: "mine",
                    value: "Mine",
                    optProps: { onChange: handleRadioFilters },
                  },
                  {
                    label: "managed",
                    value: "Managed",
                    optProps: { onChange: handleRadioFilters },
                  },
                  {
                    label: "all",
                    value: "All",
                    optProps: { onChange: handleRadioFilters },
                  },
                ]}
              />
            </HasPrivileges>
          )}

          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_ACTIVITIES]}>
            <AddButton
              label="activity"
              onClick={() => handleOpenModal("activity")}
            />
          </HasPrivileges>
        </div>
      </div>

      {/* List */}
      <DataTable
        noHeader
        data={activities}
        columns={columns}
        className="cards_table"
        progressPending={isListLoading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetchList}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
            {...(name === "reminder" && { isLeadList: true })}
            {...(name === "activity" && { isLeadProfile, leadProfileData })}
            {...(name === "comment" && {
              canCommentPrivileges: [Privilages.COMMENT_ON_ACTIVITIES],
            })}
          />
        ) : null
      )}
    </>
  );
};

export default ActivitiesList;
