import React from "react";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Edit,
  PlayCircleOutline,
  PauseCircleOutline,
  DeleteForever,
  VpnKey,
  Email,
  LocalPhone,
  Home,
  ArrowDownward,
  ArrowUpward,
} from "@mui/icons-material";
import {
  toggleActivationModal,
  addEmployeeIdToActivation,
  editEmployeeAction,
  editEmployeePrivilegesAction,
  sendPhoneVerificationAction,
  sendEmailVerificationAction,
  resendCredentailsAction,
  fetchInitDeleteManagerAction,
  fetchInitSuspendManagerAction,
} from "../../Store/Actions";
import { connect } from "react-redux";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Pagination from "../../Components/Pagination";
import { faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "rc-table";
import HelperFns from "../../Helpers/HelperFns";
import { Sms, WhatsApp } from "@mui/icons-material";
import ExportModal from "./ExportModal";

const HrEmployeesList = (props) => {
  const { t } = useTranslation();

  /* ↓ Helpers ↓ */

  // handle render component
  const shouldRenderComponent = (reqirePrivileges, employee) => {
    if (
      (HelperFns.checkPrivileges({
        allowBP: false,
        scope: "managed",
        privileges: reqirePrivileges,
      }) &&
        HelperFns.isManagedByAuth(
          employee?.manager?.id,
          employee?.copied_managers?.map((user) => user?.id)
        )) ||
      HelperFns.checkPrivileges({
        allowBP: true,
        scope: "all",
        privileges: reqirePrivileges,
      })
    ) {
      return true;
    }
    return false;
  };

  // to approximate the numbers of remaning balance
  function remaningBalanceCalcualtion(num) {
    let roundNumToSeven = (+num)?.toFixed(7);
    let roundNumToTwo = (+roundNumToSeven)?.toFixed(2);
    return Math.abs(roundNumToTwo) == 0 ? 0 : roundNumToTwo;
  }

  const handleSuspensionBtn = (row) => {
    props.fetchInitSuspendManagerAction(row?.id);
  };

  const handleActivationBtn = (row) => {
    props.toggleActivationModal(
      row?.attendance_profile?.permission_leaves_break_setting.sickLeaveSetting
        ?.sickLeaveSetting,
      row?.attendance_profile?.activeWorkTiming?.overtime_permissible,
      row?.attendance_profile?.permission_leaves_break_setting
        ?.annualLeaveSetting.allowed_emergency_leaves
    );
    props.addEmployeeIdToActivation(
      row?.id,
      row?.office?.id,
      row?.office?.country?.id
    );
  };

  const handleDeleteUser = (row) => {
    props.fetchInitDeleteManagerAction(row?.id);
  };

  const handleEditBtn = (row) => {
    props.editEmployeeAction(row?.id);
  };

  const handleVerifyPhoneBtn = (row, send_via) => {
    if (
      row?.phone_verified ||
      row?.user_type == "Owner" ||
      !props.hasActiveSubscription
    ) {
      return;
    }
    props.sendPhoneVerificationAction(null, row?.id, false, send_via);
  };

  const handleVerifyEmailBtn = (row) => {
    if (
      row?.email_verified ||
      row?.user_type === "Owner" ||
      !props.hasActiveSubscription
    ) {
      return;
    }
    props.sendEmailVerificationAction(null, row?.id, false);
  };

  // for resend credentails action
  const handleResendCredentials = (row) => {
    props.resendCredentailsAction(row?.id);
  };

  // for manage privilages modal
  const handleManagePrivilagesModal = (row) => {
    props.editEmployeePrivilegesAction(row?.id, props?.planId);
  };

  const columns = [
    {
      width: 600,
      title: t("name"),
      wrap: true,
      align: "center",
      dataIndex: "name",
      key: "name",
      className: "name-col",
      render: (value, row) => (
        <div className="d-flex flex-column align-items-center">
          <div>
            <HasPrivileges
              reqireMain={[
                Privilages.VIEW_EMPLOYEE_PROFILES,
                Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
                Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
                Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
                Privilages.VIEW_EMPLOYEE_DOCUMENTS,
                Privilages.VIEW_EMPLOYEE_SCHEDULES,
              ]}
              allowBP
              altChildren={<>{row?.name?.toLowerCase()}</>}
            >
              {row?.user_type == "Owner" ? (
                <>{row?.name?.toLowerCase()}</>
              ) : (
                <Link
                  to={`/employees/employee-profile/${row.id}`}
                  className="cursor-pointer"
                >
                  {row?.name?.toLowerCase()}
                </Link>
              )}
            </HasPrivileges>
          </div>

          {row?.emp_code ? (
            <span className="mt-1 employee_code">{row?.emp_code}</span>
          ) : null}

          <div className="mx-4 mt-1">
            {row?.sign_in_time ? (
              <div className="d-flex employee_time_info_style align-items-baseline">
                {row?.from_home ? (
                  <div className="mr-1">
                    <Home sx={{ fontSize: 12, color: "#313030" }} />
                  </div>
                ) : null}

                <div className="employee_info_style">
                  {row?.status == "Day Off" ? (
                    <div className="weekend_status_style pt-0">
                      {row?.active
                        ? t(row?.status?.toLowerCase())
                        : t("suspended")}
                    </div>
                  ) : (
                    <>
                      {+row?.attendance_profile?.activeWorkTiming
                        ?.sign_in_req ? (
                        <>
                          <span>
                            <ArrowDownward
                              sx={{ fontSize: 12, color: "#27b40c" }}
                            />

                            {row?.sign_in_time
                              ? moment(row?.sign_in_time).format("hh:mm A")
                              : " _ _ : _ _"}
                          </span>
                          <span className="px-2">-</span>
                          <span>
                            <ArrowUpward
                              sx={{ fontSize: 12, color: "#ff6a6a" }}
                            />

                            {row?.sign_out_time
                              ? moment(row?.sign_out_time).format("hh:mm A")
                              : "_ _ : _ _"}
                          </span>
                        </>
                      ) : (
                        <span>{t("present")}</span>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div
                className={
                  row.active !== undefined
                    ? +row.active
                      ? `${row?.working_status}_status_style pb-0`
                      : "suspend_status_style pb-0"
                    : ""
                }
              >
                {row?.user_type == "Owner"
                  ? t("present")
                  : row.active !== undefined
                  ? row.active
                    ? t(row?.working_status?.toLowerCase())
                    : t("suspended")
                  : ""}
              </div>
            )}
          </div>
        </div>
      ),
    },
    ...(props?.employeeListColumns?.phone
      ? [
          {
            width: 50,
            title: "",
            wrap: true,
            align: "center",
            dataIndex: "phone",
            key: "phone",
            className: "phone-col",
            render: (value, row) => (
              <>
                {row?.has_credentials ? (
                  <>
                    {row?.phone ? (
                      <div className="employee_card_btns_wrapper_style fit-width">
                        <Tooltip
                          classes={{
                            tooltip: "custom_tooltip_style",
                            arrow: "custom_arrow_tooltip_style",
                          }}
                          interactive
                          arrow={true}
                          title={
                            <>
                              <div className="pb-0 text-center pt-2">
                                {/* <div className="col">
                                    <label>{t("email")}</label>
                                  </div> */}
                                <div>
                                  <p className="other_employee_info_style mb-1 text-lowercase px-2">
                                    {/* <label className="mr-2">
                                        {t("email")}
                                      </label> */}
                                    {row?.phone}
                                  </p>
                                </div>
                              </div>
                              <HasPrivileges
                                allowBP
                                reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}
                              >
                                {!row?.phone_verified &&
                                row?.active &&
                                row?.user_type !== "Owner" &&
                                row?.user_type !== "Office" &&
                                props?.hasActiveSubscription ? (
                                  <>
                                    <div className="click-to-verify-message">
                                      {t("verify via")}
                                    </div>
                                    <div className="veriy-phone-buttons-container">
                                      <Tooltip title={t("sms")}>
                                        <button
                                          onClick={() =>
                                            handleVerifyPhoneBtn(row, "sms")
                                          }
                                          className="verify-phone-button"
                                        >
                                          <Sms className="verify-phone-sms-icon" />
                                        </button>
                                      </Tooltip>
                                      <span className="verify-phone-icons-separator"></span>
                                      <Tooltip title={t("whatsapp")}>
                                        <button
                                          onClick={() =>
                                            handleVerifyPhoneBtn(
                                              row,
                                              "whatsapp"
                                            )
                                          }
                                          className="verify-phone-button"
                                        >
                                          <WhatsApp className="verify-phone-whatsapp-icon" />
                                        </button>
                                      </Tooltip>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </HasPrivileges>
                            </>
                          }
                        >
                          <div className="d-flex">
                            <HasPrivileges
                              allowBP
                              reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}
                              altChildren={
                                <div className="mx-2 tooltip_icon_btn_wrapper_style">
                                  <LocalPhone
                                    sx={{
                                      fontSize: 12,
                                      color:
                                        row?.phone_verified &&
                                        row?.user_type !== "Owner"
                                          ? "#27b40c"
                                          : "#313030",
                                    }}
                                  />
                                </div>
                              }
                            >
                              <div className="mx-2 tooltip_icon_btn_wrapper_style">
                                <LocalPhone
                                  sx={{
                                    fontSize: 12,
                                    color:
                                      row?.phone_verified &&
                                      row?.user_type !== "Owner"
                                        ? "#27b40c"
                                        : "#313030",
                                  }}
                                />
                              </div>
                            </HasPrivileges>
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ),
          },
        ]
      : []),
    ...(props?.employeeListColumns?.email
      ? [
          {
            width: 50,
            title: "",
            wrap: true,
            align: "center",
            dataIndex: "email",
            key: "email",
            className: "email-col",
            render: (value, row) => (
              <>
                {row?.has_credentials ? (
                  <>
                    {row?.email ? (
                      <div className="employee_card_btns_wrapper_style fit-width">
                        <Tooltip
                          classes={{
                            tooltip: "custom_tooltip_style",
                            arrow: "custom_arrow_tooltip_style",
                          }}
                          arrow={true}
                          title={
                            <React.Fragment>
                              <div className="row pb-0">
                                <div className="col-4">
                                  <label>{t("email")}</label>
                                </div>
                                <div className="col-8">
                                  <p className="other_employee_info_style mb-2 text-lowercase">
                                    {row?.email}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          }
                        >
                          <div className="d-flex">
                            <HasPrivileges
                              allowBP
                              reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}
                              altChildren={
                                <div className="tooltip_icon_btn_wrapper_style">
                                  <Email
                                    sx={{
                                      fontSize: 12,
                                      color:
                                        row?.email_verified &&
                                        row?.user_type !== "Owner"
                                          ? "#27b40c"
                                          : "#313030",
                                    }}
                                  />
                                </div>
                              }
                            >
                              <div
                                className="tooltip_icon_btn_wrapper_style"
                                onClick={() => handleVerifyEmailBtn(row)}
                              >
                                <Email
                                  sx={{
                                    fontSize: 12,
                                    color:
                                      row?.email_verified &&
                                      row?.user_type !== "Owner"
                                        ? "#27b40c"
                                        : "#313030",
                                  }}
                                />
                              </div>
                            </HasPrivileges>
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ),
          },
        ]
      : []),
    ...(props?.employeeListColumns?.office
      ? [
          {
            width: 550,
            title: t("office"),
            className: "office-col",
            wrap: true,
            align: "center",
            dataIndex: "office",
            key: "office",
            render: (value, row) => <div>{row?.office?.name}</div>,
          },
        ]
      : []),
    ...(props?.employeeListColumns?.department
      ? [
          {
            width: 600,
            title: t("department"),
            wrap: true,
            align: "center",
            dataIndex: "department",
            key: "department",
            className: "department-col",
            render: (value, row) => <div>{row?.department?.name}</div>,
          },
        ]
      : []),
    ...(props?.employeeListColumns?.position
      ? [
          {
            width: 600,
            title: t("position"),
            wrap: true,
            align: "center",
            dataIndex: "position",
            key: "position",
            className: "position-col",
            render: (value, row) => <div>{row?.position?.name}</div>,
          },
        ]
      : []),
    ...(props?.employeeListColumns?.attendance_profile
      ? [
          {
            width: 600,
            title: t("profile"),
            wrap: true,
            align: "center",
            dataIndex: "attendance_profile",
            key: "attendance_profile",
            className: "profile-col",
            render: (value, row) => (
              <div dir="auto">{row?.attendance_profile?.name}</div>
            ),
          },
        ]
      : []),
    ...(props?.employeeListColumns?.direct_manager ||
    props?.employeeListColumns?.copied_manager
      ? [
          {
            width: 600,
            title: t("Managers"),
            wrap: true,
            align: "center",
            dataIndex: "manager",
            key: "manager",
            className: "managers-col",
            render: (value, row) => (
              <div>
                {props?.employeeListColumns?.direct_manager ? (
                  <div>{row?.manager?.name}</div>
                ) : (
                  ""
                )}

                {props?.employeeListColumns?.copied_manager ? (
                  <>
                    {row?.copied_managers?.map((copiedManagers) => (
                      <div>{copiedManagers?.name}</div>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </div>
            ),
          },
        ]
      : []),
    ...(props?.employeeListColumns?.joining_date
      ? [
          {
            width: 550,
            title: t("joining date"),
            className: "joining-col",
            wrap: true,
            align: "center",
            dataIndex: "joining_date",
            key: "joining_date",
            render: (value, row) => (
              <div>{moment(row?.joining_date).format("DD - MM - YYYY")}</div>
            ),
          },
        ]
      : []),
    ...(props?.employeeListColumns?.remaining_annual_leaves ||
    props?.employeeListColumns?.remaining_emergency_leaves ||
    props?.employeeListColumns?.remaining_sick_leaves
      ? [
          {
            title: t("leaves"),
            wrap: true,
            align: "center",
            className: "leaves-col col-group",
            children: [
              ...(props?.employeeListColumns?.remaining_annual_leaves
                ? [
                    {
                      width: 300,
                      title: t("annual"),
                      key: "remaining_annual_leaves",
                      align: "center",
                      className: "normal-col",
                      render: (value, row) => (
                        <div>
                          {row?.balance?.remaining_annual_leaves !== undefined
                            ? remaningBalanceCalcualtion(
                                row?.balance?.remaining_annual_leaves
                              )
                            : "----"}
                        </div>
                      ),
                    },
                  ]
                : []),
              ...(props?.employeeListColumns?.remaining_emergency_leaves
                ? [
                    {
                      width: 300,
                      title: t("emergency"),
                      key: "remaining_emergency_leaves",
                      align: "center",
                      className: "emergency-col",
                      render: (value, row) => (
                        <div>
                          {row?.balance?.remaining_emergency_leaves !==
                          undefined
                            ? remaningBalanceCalcualtion(
                                Math.max(
                                  Math.min(
                                    +row?.balance?.remaining_emergency_leaves,
                                    +row?.balance?.remaining_annual_leaves
                                  ),
                                  0
                                )
                              )
                            : "----"}
                        </div>
                      ),
                    },
                  ]
                : []),
              ...(props?.employeeListColumns?.remaining_sick_leaves
                ? [
                    {
                      width: 300,
                      title: t("sick"),
                      key: "remaining_sick_leaves",
                      align: "center",
                      className: "sick-col",
                      render: (value, row) => (
                        <div>
                          {row?.balance?.remaining_sick_leaves !== undefined
                            ? remaningBalanceCalcualtion(
                                row?.balance?.remaining_sick_leaves
                              )
                            : "----"}
                        </div>
                      ),
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(props?.employeeListColumns?.remaining_permissions
      ? [
          {
            width: 300,
            title: t("permissions"),
            className: "permissions-col",
            wrap: true,
            align: "center",
            dataIndex: "balance",
            key: "balance",
            render: (value, row) => (
              <div>
                {row?.balance?.remaining_permissions !== undefined
                  ? remaningBalanceCalcualtion(
                      row?.balance?.remaining_permissions
                    )
                  : "----"}
              </div>
            ),
          },
        ]
      : []),
    {
      width: 650,
      title: "",
      wrap: true,
      align: "center",
      dataIndex: "id",
      key: "action",
      right: true,

      render: (value, row) =>
        shouldRenderComponent([Privilages.VIEW_EMPLOYEES], row) && (
          <div className="dropdown hr_employee_icons_wrapper_style mx-3">
            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEES]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={<Edit className="edit-icon-style" />}
            >
              {row?.user_type == "Owner" ? null : !row?.checkPassword &&
                row?.has_credentials ? (
                <VpnKey
                  className="resend-icon-style"
                  onClick={() => handleResendCredentials(row)}
                />
              ) : null}

              {row?.user_type === "Owner" ? (
                ""
              ) : (
                <Edit
                  className="edit-icon-style"
                  onClick={() => handleEditBtn(row)}
                />
              )}
            </HasPrivileges>

            <HasPrivileges
              reqireMain={[Privilages.ACTIVATE_SUSPEND_EMPLOYEES]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={
                row?.user_type === "Owner" ? (
                  ""
                ) : row.active ? (
                  <PauseCircleOutline className="pause-icon-style" />
                ) : (
                  <PlayCircleOutline className="play-icon-style" />
                )
              }
            >
              {row?.user_type == "Owner" ? (
                ""
              ) : row.active ? (
                <PauseCircleOutline
                  className="pause-icon-style"
                  onClick={() => handleSuspensionBtn(row)}
                />
              ) : (
                <PlayCircleOutline
                  className="play-icon-style"
                  onClick={() => handleActivationBtn(row)}
                />
              )}
            </HasPrivileges>

            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEES]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={
                <DeleteForever className="delete-icon-style" />
              }
            >
              {row?.user_type === "Owner" ? (
                ""
              ) : (
                <DeleteForever
                  className="delete-icon-style"
                  onClick={() => handleDeleteUser(row)}
                />
              )}
            </HasPrivileges>

            <HasPrivileges
              reqireMain={[Privilages.EDIT_USER_PRIVILEGE]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={
                <span>
                  <FontAwesomeIcon
                    className="manage-icon-style"
                    icon={faLockOpen}
                  />
                </span>
              }
            >
              {row?.user_type === "Owner" ? (
                ""
              ) : (
                <FontAwesomeIcon
                  className="manage-icon-style"
                  onClick={() => handleManagePrivilagesModal(row)}
                  icon={faLockOpen}
                />
              )}
            </HasPrivileges>
          </div>
        ),
      ignoreRowClick: true,
    },
  ];

  return (
    <>
      <div className="content requests-profile-data-list">
        <Table
          className="employee_list_datatabel_wrapper_style datatable_shadow_style"
          prefixCls="employees-list"
          columns={columns}
          data={props?.data}
          useFixedHeader
          tableLayout
          emptyText={
            <div className="text-center">{t("no data available")}</div>
          }
          scroll={{ x: 1500 }}
          style={{ width: "auto" }}
          onRow={(record, index) => ({
            className: `row-${index}`,
          })}
          footer={() => (
            <Pagination
              tableRef={props?.dataTableRef}
              styleWraper=""
              onPaginate={props?.handlePaginate}
              reducer="super"
            />
          )}
        />
      </div>

      {/* Modals */}
      <ExportModal />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    planId: state.auth.userProfile?.company?.activeSubscription?.plan?.id,
    hasActiveSubscription:
      state.auth.userProfile?.company?.hasActiveSubscription,
    data: state.super?.allEmployeesList?.data,
    employeeListColumns: state.super.employeeListColumns,
  };
};
export default connect(mapStateToProps, {
  toggleActivationModal,
  addEmployeeIdToActivation,
  editEmployeeAction,
  editEmployeePrivilegesAction,
  sendPhoneVerificationAction,
  sendEmailVerificationAction,
  resendCredentailsAction,
  fetchInitDeleteManagerAction,
  fetchInitSuspendManagerAction,
})(HrEmployeesList);
