import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomQuery from "../../Helpers/Hooks/useCustomQuery";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import {
  formatOptions,
  openSwalConfirm,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import {
  revertReminderMutation,
  removeReminderMutation,
  completeReminderMutation,
} from "../../Graphql/mutation";
import moment from "moment";
import Privilages from "../../Constants/Privilages";
import { remindersListQuery } from "../../Graphql/query";
import { activityTypeOptions, dateTimeListFormat } from "../../Constants/CRM";

import {
  CommentCell,
  LeadNameCell,
  ContactTooltip,
} from "../../Components/CRM";
import {
  CommentModal,
  ActivityModal,
  ReminderModal,
} from "../../Components/CRMFormModals";
import IconButtonWithTooltip, {
  EditIconButton,
  AcceptIconButton,
  RemoveIconButton,
  DownloadFileIconButton,
} from "../../Components/IconButtonWithTooltip";
import { Event } from "@mui/icons-material";
import Loader from "../../Components/Loader";
import UndoIcon from "@mui/icons-material/Undo";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { BSelect, DateTimePickerForm, RadioboxForm } from "form-builder";
import ReactShowMoreText from "react-show-more-text";

const utcOffsetMin = moment().utcOffset();
const modalInitState = { isOpen: false, data: null };
const modals = [
  { name: "comment", Modal: CommentModal },
  { name: "activity", Modal: ActivityModal },
  { name: "reminder", Modal: ReminderModal },
];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const RemindersList = ({ isLeadProfile, leadProfileData }) => {
  const { t } = useTranslation();

  // Local State
  const [reminders, setReminders] = useState([]);
  const [options, setOptions] = useState({ users: [] });
  const [pagination, setPagination] = useState(paginationInitState);
  const [modalsState, setModalsState] = useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );
  const [filters, setFilters] = useState({
    to: "",
    from: "",
    type: null,
    f_type: null,
    ff_type: "All",
    assigned_to: null,
    lead_ids: isLeadProfile ? [leadProfileData?.id] : null,
  });

  // Server State
  const [removeReminder] = useCustomMutation(removeReminderMutation);
  const [revertReminder] = useCustomMutation(revertReminderMutation);
  const [completeReminder] = useCustomMutation(completeReminderMutation);
  const { refetch: refetchList, loading: isListLoading } = useCustomQuery(
    remindersListQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        first: pagination.perPage,
        page: pagination.currentPage,
        ...formatSendingInputs(filters),
        isOptionsSkip: Object.values(options).every((l) => l.length),
      },
      onCompleted: ({ reminders, users }) => {
        // Data
        setReminders(reminders?.data);
        setPagination(reminders?.paginatorInfo);

        // Options
        !Object.values(options).every((l) => l.length) &&
          setOptions({ users: users?.data || [] });
      },
    }
  );

  // Constants
  const columns = [
    { name: t("type"), cell: ({ type }) => JSON.parse(type)?.name },
    {
      name: t("name"),
      cell: ({ lead }) => (
        <LeadNameCell lead={lead} isLeadProfile={isLeadProfile} />
      ),
    },
    { name: t("contact"), cell: ({ contact = {} }) => ContactTooltip(contact) },
    {
      name: t("due on"),
      cell: ({ due_date }) =>
        due_date
          ? moment(due_date)
              .add(utcOffsetMin, "minutes")
              .format(dateTimeListFormat)
          : "",
    },
    { name: t("created by"), cell: ({ user = {} }) => user?.name },
    {
      name: t("comments"),
      cell: ({ id, comments = [] }) => (
        <HasPrivileges reqireMain={[Privilages.VIEW_COMMENTS_ON_REMINDERS]}>
          <CommentCell
            comments={comments}
            onClick={() =>
              handleOpenModal("comment", { id, comments, type: "Reminder" })
            }
          />
        </HasPrivileges>
      ),
    },
    {
      name: t("notes"),
      cell: ({ notes }) => (
        <ReactShowMoreText lines={1}>{notes}</ReactShowMoreText>
      ),
    },
    {
      name: t("attachments"),
      cell: ({ attachments = [] }) => (
        <div className="cards_table_actions">
          {attachments?.map((attachment) => (
            <DownloadFileIconButton
              key={attachment?.file?.id}
              href={attachment?.file?.path}
            />
          ))}
        </div>
      ),
    },
    { name: t("status"), selector: "status" },
    {
      cell: (data) => (
        <>
          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_ACTIVITIES]}>
            <IconButtonWithTooltip
              label="add activity"
              icon={<Event fontSize="small" className="text-primary" />}
              onClick={() =>
                handleOpenModal("activity", {
                  id: data?.lead?.id,
                  name: data?.lead?.name,
                  contacts: data?.lead?.contacts,
                })
              }
            />
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.SET_REMINDERS_AS_COMPLETED]}>
            {data.status.toLowerCase() !== "completed" ? (
              <AcceptIconButton
                label="complete"
                iconProps={{ color: "#23aaeb" }}
                onClick={() => handleCompleteReminder(data)}
              />
            ) : (
              <IconButtonWithTooltip
                label="revert"
                icon={<UndoIcon fontSize="small" />}
                onClick={() => handleRevertReminder(data)}
              />
            )}
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_REMINDERS]}>
            <EditIconButton
              onClick={() => handleOpenModal("reminder", { id: data?.id })}
            />
          </HasPrivileges>
          <HasPrivileges reqireMain={[Privilages.DELETE_REMINDERS]}>
            <RemoveIconButton onClick={() => handleRemoveReminder(data)} />
          </HasPrivileges>
        </>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handleOpenModal = (name, data = null) => {
    const modalState = { name, data, isOpen: true };

    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? modalState : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleRadioFilters = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleRemoveReminder = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeReminder({
          refetchList,
          isShowErrorModal: true,
          variables: { id: data.id },
        });
      }
    });
  };

  const handleRevertReminder = (data) => {
    const options = { text: "" };
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        revertReminder({
          refetchList,
          isShowErrorModal: true,
          variables: { id: data.id },
        });
      }
    }, options);
  };

  const handleCompleteReminder = (data) => {
    const options = { text: "" };
    openSwalConfirm((wilComplete) => {
      if (wilComplete) {
        completeReminder({
          refetchList,
          isShowErrorModal: true,
          variables: { id: data.id },
        });
      }
    }, options);
  };

  return (
    <>
      {/* Filters */}
      <div className="my-4">
        <div className="d-flex gap-20">
          <BSelect
            isClearable
            name="assigned_to"
            label="assigned to"
            icon="employee"
            keepDefaultStyle
            rootStyle="flex-fill"
            options={options.users}
            value={filters.assigned_to}
            onChange={handleSelectFilters}
            isLoading={!options.users.length}
          />
          <BSelect
            isClearable
            name="type"
            label="type"
            icon="employee"
            keepDefaultStyle
            rootStyle="flex-fill"
            value={filters.type}
            onChange={handleSelectFilters}
            options={formatOptions(activityTypeOptions)}
          />
          <BSelect
            isClearable
            name="f_type"
            label="status"
            icon="document"
            keepDefaultStyle
            rootStyle="flex-fill"
            value={filters.f_type}
            onChange={handleSelectFilters}
            options={formatOptions(["Upcoming", "Overdue", "Completed"])}
          />
        </div>

        <div className="d-flex gap-20 mt-4">
          <DateTimePickerForm
            hasIcon
            isClearable
            label="from"
            labelStyle="w-100"
            value={filters.from}
            rootStyle="flex-fill"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("from", val)}
          />
          <DateTimePickerForm
            hasIcon
            label="to"
            isClearable
            labelStyle="w-100"
            value={filters.to}
            rootStyle="flex-fill"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("to", val)}
          />
        </div>

        <div
          className={`d-flex align-items-end mt-4 ${
            isLeadProfile ? "justify-content-end" : "justify-content-between"
          }`}
        >
          {isLeadProfile ? null : (
            <RadioboxForm
              name="ff_type"
              value={filters.ff_type}
              optionInputStyle=" "
              containerStyle="mb-0"
              optionItemStyle="d-inline-block mx-2"
              optionsContainerStyle="d-flex gap-10"
              options={[
                {
                  label: "mine",
                  value: "Mine",
                  optProps: { onChange: handleRadioFilters },
                },
                {
                  label: "managed",
                  value: "Managed",
                  optProps: { onChange: handleRadioFilters },
                },
                {
                  label: "all",
                  value: "All",
                  optProps: { onChange: handleRadioFilters },
                },
              ]}
            />
          )}

          <HasPrivileges reqireMain={[Privilages.ADD_EDIT_REMINDERS]}>
            <AddButton
              label="reminder"
              onClick={() => handleOpenModal("reminder")}
            />
          </HasPrivileges>
        </div>
      </div>

      {/* List */}
      <DataTable
        noHeader
        data={reminders}
        columns={columns}
        className="cards_table"
        progressPending={isListLoading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetchList}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
            {...(name === "activity" && { isLeadList: true })}
            {...(name === "reminder" && { isLeadProfile, leadProfileData })}
            {...(name === "comment" && {
              canCommentPrivileges: [Privilages.COMMENT_ON_REMINDERS],
            })}
          />
        ) : null
      )}
    </>
  );
};

export default RemindersList;
