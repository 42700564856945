import Types from "../types";

// Start Fetch System Plans List
export const fetchSystemPlansListAction = (args) => {
  return {
    type: Types.FETCH_SYSTEM_PLANS_LIST_ATTEMPT,
    payload: {
      ...args,
    },
  };
};

export const fetchSystemPlansListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_SYSTEM_PLANS_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchSystemPlansListFailed = (ref) => {
  return {
    type: Types.FETCH_SYSTEM_PLANS_LIST_FAILED,
    payload: {
      ref,
    },
  };
};
//End Fetch System Plans List

export const updatePlanFeatureInput = (event, index) => {
  return {
    type: Types.UPDTAE_PLAN_FETAURE_INPUT,
    payload: {
      event,
      index,
    },
  };
};

export const resetSystemPlanForm = () => {
  return {
    type: Types.RESET_SYSTEM_PLAN_FORM,
  };
};

export const fetchSystemPrivilegesAction = () => {
  return {
    type: Types.FETCH_SYSTEM_PRIVILEGES_ATTEMPT,
  };
};

export const fetchSystemPrivilegesSuccess = (system_privileges) => {
  return {
    type: Types.FETCH_SYSTEM_PRIVILEGES_SUCCESS,
    payload: system_privileges,
  };
};

export const fetchSystemPrivilegesFailed = () => {
  return {
    type: Types.FETCH_SYSTEM_PRIVILEGES_FAILED,
  };
};

// handle submit System Plan

export const upsertSystemPlanAction = (plan, cb) => {
  return {
    type: Types.UPSERT_SYSTEM_PLAN_ATTEMPT,
    payload: {
      plan,
    },
    cb,
  };
};

export const upsertSystemPlanSuccess = (plan) => {
  return {
    type: Types.UPSERT_SYSTEM_PLAN_SUCCESS,
    payload: {
      plan,
    },
  };
};

export const upsertSystemPlanFailed = (error = {}) => {
  return {
    type: Types.UPSERT_SYSTEM_PLAN_FAILED,
    payload: error,
  };
};
// handle Roles

export const fetchPlanRolesAction = (planId) => {
  return {
    type: Types.FETCH_PLAN_ROLES_ATTEMPT,
    payload: { planId },
  };
};

export const fetchPlanRolesSuccess = (roles) => {
  return {
    type: Types.FETCH_PLAN_ROLES_SUCCESS,
    payload: { roles },
  };
};

export const fetchPlanRolesFailed = () => {
  return {
    type: Types.FETCH_PLAN_ROLES_FAILED,
  };
};

export const showSystemRolePlanModal = (roleData = null) => {
  return {
    type: Types.SHOW_SYSTEM_ROLE_MODAL,
    payload: {
      roleData,
    },
  };
};
export const dismissSystemRolePlanModal = () => {
  return {
    type: Types.DISMISS_SYSTEM_ROLE_MODAL,
  };
};

export const fetchPlanPrivilegesAction = (planId) => {
  return {
    type: Types.FETCH_PLAN_PRIVILEGES_ATTEMPT,
    payload: { planId },
  };
};

export const fetchPlanPrivilegesSuccess = (PLAN_privileges) => {
  return {
    type: Types.FETCH_PLAN_PRIVILEGES_SUCCESS,
    payload: PLAN_privileges,
  };
};

export const fetchPlanPrivilegesFailed = () => {
  return {
    type: Types.FETCH_PLAN_PRIVILEGES_FAILED,
  };
};

export const upsertRoleAction = (role) => {
  return {
    type: Types.UPSERT_ROLE_ATTEMPT,
    payload: {
      role,
    },
  };
};

export const upsertRoleSuccess = (role) => {
  return {
    type: Types.UPSERT_ROLE_SUCCESS,
    payload: {
      role,
    },
  };
};

export const upsertRoleFailed = (error = {}) => {
  return {
    type: Types.UPSERT_ROLE_FAILED,
    payload: error,
  };
};

export const editSystemPlanAction = (planId) => {
  return {
    type: Types.EDIT_SYSTEM_PLAN_ATTEMPT,
    payload: {
      planId,
    },
  };
};
export const editSystemPlanSuccess = (plan, system_privileges) => {
  return {
    type: Types.EDIT_SYSTEM_PLAN_SUCCESS,
    payload: {
      plan,
      system_privileges,
    },
  };
};
export const editSystemPlanFailed = () => {
  return {
    type: Types.EDIT_SYSTEM_PLAN_FAILED,
  };
};

// FETCH SYSTEM PLAN PAGE

export const fetchSystemPlanPage = (planId) => {
  return {
    type: Types.FETCH_SYSTEM_PLANE_PAGE_ATTEMPT,
    payload: {
      planId,
    },
  };
};
export const fetchSystemPlanPageSuccess = (plan) => {
  return {
    type: Types.FETCH_SYSTEM_PLANE_PAGE_SUCCESS,
    payload: {
      plan,
    },
  };
};
export const fetchSystemPlanPageFailed = () => {
  return {
    type: Types.FETCH_SYSTEM_PLANE_PAGE_FAILED,
  };
};

export const toogleViewPlanPrivileges = () => {
  return {
    type: Types.TOOGLE_VIEW_PLAN_PRIVILEGES_MODAL,
  };
};

// UPDATE PLAN STATUS

export const updatePlanStatusAction = (planId, status, listView = false) => {
  return {
    type: Types.UPDTAE_PLAN_STATUS_ATTEMPT,
    payload: {
      planId,
      status,
      listView,
    },
  };
};
export const updatePlanStatusSuccess = () => {
  return {
    type: Types.UPDTAE_PLAN_STATUS_SUCCESS,
  };
};
export const updatePlanStatusFailed = () => {
  return {
    type: Types.UPDTAE_PLAN_STATUS_FAILED,
  };
};

export const deletePlanAction = (planId, listView = false) => {
  return {
    type: Types.DELETE_SYSTEM_PLAN_ATTEMPT,
    payload: {
      planId,
      listView,
    },
  };
};
export const deletePlanSuccess = () => {
  return {
    type: Types.DELETE_SYSTEM_PLAN_SUCCESS,
  };
};
export const deletePlanFailed = () => {
  return {
    type: Types.DELETE_SYSTEM_PLAN_FAILED,
  };
};

export const deleteRoleAction = (role_id, planId, isNewPlan = false) => {
  return {
    type: Types.DELETE_ROLE_ATTEMPT,
    payload: {
      role_id,
      planId,
      isNewPlan,
    },
  };
};
export const deleteRoleSuccess = () => {
  return {
    type: Types.DELETE_ROLE_SUCCESS,
  };
};
export const deleteRoleFailed = () => {
  return {
    type: Types.DELETE_ROLE_FAILED,
  };
};

export const fetchEditRoleAction = (planId, roleId) => {
  return {
    type: Types.FETCH_EDIT_ROLE_ATTEMPT,
    payload: {
      roleId,
      planId,
    },
  };
};
export const fetchEditRoleSuccess = (plan_privileges, role) => {
  return {
    type: Types.FETCH_EDIT_ROLE_SUCCESS,
    payload: {
      plan_privileges,
      role,
    },
  };
};
export const fetchEditRoleFailed = () => {
  return {
    type: Types.FETCH_EDIT_ROLE_FAILED,
  };
};

export const dismissEditRoleAction = () => {
  return {
    type: Types.DISMISS_EDIT_ROLE_MODAL,
  };
};

// Start of fetch half work timing template actions
export const fetchSuggestedHalfWorkTimingData = (id, which_half) => {
  return {
    type: Types.FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_ATTEMPT,
    payload: {
      id,
      which_half,
    },
  };
};

export const fetchSuggestedHalfWorkTimingDataSuccess = (data) => {
  return {
    type: Types.FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_SUCCESS,
    payload: data,
  };
};

export const fetchSuggestedHalfWorkTimingDataFailed = () => {
  return {
    type: Types.FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_FAILED,
  };
};
// End of fetch half work timing template actions

// Start of fetch admin holidays list actions
export const fetchAdminHolidaysAction = (data) => {
  return {
    type: Types.FETCH_ADMIN_HOLIDAYS_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchAdminHolidaysSuccess = (ref, list) => {
  return {
    type: Types.FETCH_ADMIN_HOLIDAYS_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetcAdminHolidaysFailed = (ref) => {
  return {
    type: Types.FETCH_ADMIN_HOLIDAYS_LIST_FAILED,
    payload: {
      ref,
    },
  };
};
// End of fetch admin holidays list actions

// Start of holidays countries filter action
export const fetchAdminHoildaysCountriesOptionsAction = (
  holidayCountriesOptions
) => {
  return {
    type: Types.FETCH_ADMIN_HOLIDAYS_COUNTRIES_OPTIONS,
    payload: holidayCountriesOptions,
  };
};
// End of holidays countries filter action

// start of admin holidays modal actions
export const showAdminHolidaysModalAction = (data) => {
  return {
    type: Types.SHOW_ADMIN_HOLIDAYS_MODAL,
    payload: data,
  };
};

export const dismissAdminHolidaysModalAction = (data) => {
  return {
    type: Types.HIDE_ADMIN_HOLIDAYS_MODAL,
    payload: data,
  };
};

export const upsertAdminHolidaysAction = (data) => ({
  type: Types.UPSERT_ADMIN_HOLIDAYS_ATTEMPT,
  payload: data,
});

export const upsertAdminHolidaysSuccess = (ref) => ({
  type: Types.UPSERT_ADMIN_HOLIDAYS_SUCCESS,
  payload: { ref },
});

export const upsertAdminHolidaysFailed = (error) => ({
  type: Types.UPSERT_ADMIN_HOLIDAYS_FAILED,
  payload: {
    ...error,
  },
});
// End of admin holidays modal actions

// Start of Delete admin holiday action
export const deleteAdminHolidayAction = (data) => ({
  type: Types.DELETE_ADMIN_HOLIDAYS_ATTEMPT,
  payload: data,
});

export const deleteAdminHolidaySuccess = () => ({
  type: Types.DELETE_ADMIN_HOLIDAYS_SUCCESS,
});

export const deleteAdminHolidayFailed = () => ({
  type: Types.DELETE_ADMIN_HOLIDAYS_FAILED,
});
// End of Delete admin holiday action

// generate Demo
export const generateDemoAction = () => {
  return {
    type: Types.GENERATE_DEMO_ATTEMPT,
  };
};

export const generateDemoSuccess = () => {
  return {
    type: Types.GENERATE_DEMO_SUCCESS,
  };
};

export const generateDemoFailed = () => {
  return {
    type: Types.GENERATE_DEMO_FAILED,
  };
};

export const fetchAccessLevelsAttempt = () => {
  return {
    type: Types.FETCH_COMPANY_ACCESS_LEVELS_ATTEMPT,
  };
};

export const fetchAccessLevelsSuccess = (value) => {
  return {
    type: Types.FETCH_COMPANY_ACCESS_LEVELS_SUCCESS,
    payload: value,
  };
};

export const fetchAccessLevelsFailed = () => {
  return {
    type: Types.FETCH_COMPANY_ACCESS_LEVELS_FAILED,
  };
};

export const dismissAddNewAccessLevelModalAction = () => {
  return {
    type: Types.DISMISS_ADD_NEW_ACCESS_LEVEL_MODAL_ACTION,
  };
};

export const upsertRoleToCompanyAttempt = (input) => {
  return {
    type: Types.UPSERT_ROLE_TO_COMPANY_ATTEMPT,
    payload: input,
  };
};

export const upsertRoleToCompanySuccess = () => {
  return {
    type: Types.UPSERT_ROLE_TO_COMPANY_SUCCESS,
  };
};

export const upsertRoleToCompanyFailed = (error) => {
  return {
    type: Types.UPSERT_ROLE_TO_COMPANY_FAILED,
    payload: { ...error },
  };
};

export const editRolePrivilegesAttempt = (planId, roleId, label) => {
  return {
    type: Types.EDIT_ROLE_PRIVILEGES_ATTEMPT,
    payload: { planId, roleId, label },
  };
};

export const editRolePrivilegesSuccess = (value) => {
  return {
    type: Types.EDIT_ROLE_PRIVILEGES_SUCCESS,
    payload: value,
  };
};

export const editRolePrivilegesFailed = () => {
  return {
    type: Types.EDIT_ROLE_PRIVILEGES_FAILED,
  };
};

export const deleteCompanyRoleAttmpet = (role_id) => {
  return {
    type: Types.DELETE_COMPANY_ROLE_ATTEMPT,
    payload: role_id,
  };
};

export const deleteCompanyRoleSuccess = () => {
  return {
    type: Types.DELETE_COMPANY_ROLE_SUCCESS,
  };
};

export const deleteCompanyRoleFailed = () => {
  return {
    type: Types.DELETE_COMPANY_ROLE_FAILED,
  };
};
