import React, { useState, useEffect } from "react";
import { BSelect } from "form-builder";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTimePickerForm } from "form-builder";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// Ui
import MainModal from "../MainModal";
import BlockUi from "react-block-ui";

// Helpers
import { fetchMoveWorkTeamMembersModalQuery } from "../../Graphql/query";
import { moveWorkTeamMembersMutation } from "../../Graphql/mutation";
import { onFormResetAction } from "../../Store/Actions";
import { handleActionMutation } from "../../Helpers/HelperFns";

//   form props
const formName = "moveWorkTeamMembersForm";
const formNameValidation = "moveWorkTeamMembersFormValidation";
const formServerValidation = "moveWorkTeamMembersFormServerValidation";
const reducer = "shifts";

const MoveWorkTeamMembersModal = ({
  isModalOpen,
  setIsModalOpen,
  fetchWokTeamEmployees,
  checkedValues,
  isAllChecked,
  workGroupId,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const { workTeamId } = useParams();

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
    reducer,
  };

  //   Redux hooks
  const dispatch = useDispatch();
  const moveWorkTeamMembersForm = useSelector(
    (state) => state?.shifts?.[formName]
  );
  const formClientValidation = useSelector(
    (state) => state?.shifts?.[formNameValidation]
  );

  // Apollo hooks
  const { data: modalOptionsData, loading: fetchModalLoading } = useQuery(
    fetchMoveWorkTeamMembersModalQuery,
    {
      variables: {
        workGroupId,
      },
    }
  );
  const [moveWorkTeamMember, { loading: upsertWorkTeamLoading }] = useMutation(
    moveWorkTeamMembersMutation
  );

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
      dispatch(onFormResetAction(formServerValidation));
    };
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (formClientValidation?.length === 0) {
      setIsSubmitting(false);
      const { error } = await handleActionMutation(moveWorkTeamMember, {
        variables: {
          input: {
            fromId: workTeamId,
            toId: moveWorkTeamMembersForm?.toId,
            employeesIds: isAllChecked ? null : checkedValues,
            start: moment(moveWorkTeamMembersForm?.start).format("YYYY-MM-DD"),
            allEmployees: isAllChecked ? true : false,
          },
        },
        isReturnUnion: true,
        refetchQuery: fetchWokTeamEmployees,
      });

      if (!error) {
        setIsModalOpen(false);
      }
    }
  };

  return (
    <MainModal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(false)}
      modalTitle={t("move work team members")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={upsertWorkTeamLoading}
    >
      <BlockUi tag="div" blocking={fetchModalLoading}>
        <div className="mb-2">
          <BSelect
            {...formProps}
            name="toId"
            validateBy="textRequired"
            validationName="input.toId"
            label={"team name"}
            keepDefaultStyle
            options={
              modalOptionsData?.workTeams?.data.filter(
                (team) => team?.id !== workTeamId
              ) ?? []
            }
            optionLabel="name"
            optionValue="id"
            containerStyle="containerStyle flex-column align-items-start"
            inputContainerStyle="flex-grow-1 mr-0 w-100"
            skipLocalization
            icon="people"
          />
        </div>

        <DateTimePickerForm
          {...formProps}
          datePickerContainer="w-100"
          label="start date"
          containerStyle="flex-column align-items-start"
          labelStyle="custom-label-style mb-2"
          name="start"
          validateBy="textRequired"
          validationName="input.start"
        />
      </BlockUi>
    </MainModal>
  );
};

export default MoveWorkTeamMembersModal;
