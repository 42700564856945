import React, { useState, useEffect } from "react";
import MainModal from "../../Components/MainModal";
import { connect, useSelector } from "react-redux";
import SalaryConfigForm from "../../Components/SalaryConfigForm";

import {
  dismissUpsertSalaryConfig,
  upsertSalaryConfigAction,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";

import { useParams } from "react-router-dom";
import moment, { weekdays } from "moment";

import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { RegulationTypes } from "../../Constants/RegulationTypes";
import HelperFns from "../../Helpers/HelperFns";

const formName = "salaryConfigForm";
const formServerValidation = "upsertSalaryConfigServerValidation";

const UpsertSalaryConfigModal = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userOffice = useSelector(
    (state) => state?.super?.selectedEmployeeProfile?.office
  );

  const userCountryId = useSelector(
    (state) => state?.super?.selectedEmployeeProfile?.office?.country?.id
  );

  const { t } = useTranslation();

  const { userId } = useParams();

  useEffect(() => {
    setIsSubmitting(false);
    return () => {
      setIsSubmitting(false);
    };
  }, [props.modalActions.isVissible]);

  const isModalVissible = props.modalActions.isVissible;
  const modalMessage = props.modalActions.modalMessage;
  const isLoading = props.modalActions.isLoading;

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    const zeroHourlySalaryConfig = (overtime_payment_factor) => {
      if (
        props?.salaryConfigForm?.calculate_all_work_hours &&
        props?.salaryConfigForm?.calculate_salary_by == "hourly"
      ) {
        return "0";
      } else {
        return overtime_payment_factor?.toString();
      }
    };

    let overtimeSettings = props.salaryConfigForm?.overtimeSettings?.map(
      (overtimeSetting, i, data) => ({
        id: overtimeSetting?.id || null,
        from:
          props.salaryConfigForm?.overtimeSettings?.length > 1
            ? i == 0
              ? overtimeSetting?.from
              : data[i - 1]?.to
            : "00:00",
        to:
          props.salaryConfigForm?.overtimeSettings?.length > 1
            ? overtimeSetting?.to
            : "00:00",
        overtime_payment_factor: zeroHourlySalaryConfig(
          overtimeSetting?.overtime_payment_factor?.toString()
        ),
        order: i + 1,
      })
    );

    let hourly_rating_setting = HelperFns.serializeSubmitHourlyRating(
      props.salaryConfigForm
    );

    let user_salary_config_input = {
      user_id: userId,
      salary_config: {
        id: props.salaryConfigForm.salary_config_id ?? null,
        salary_management_type:
          props.salaryConfigForm.salary_management_type == 1 ? 2 : 1,
        start_at:
          props.salaryConfigForm.salary_config_id &&
          !props?.salaryConfigForm?.canEditStartAt
            ? props.salaryConfigForm.preserved_start_at
            : props.salaryConfigForm?.payment_interval == "weekly"
            ? props?.salaryConfigForm?.week_start
            : moment(props.salaryConfigForm.start_at, "MM-YYYY").format(
                "YYYY-MM"
              ),
        regulation_type_id:
          props.salaryConfigForm.salary_management_type == 1
            ? null
            : props?.salaryConfigForm?.regulation_type_id,
        tax_info_id:
          props.salaryConfigForm.salary_management_type == 1
            ? null
            : props.salaryConfigForm?.tax_info_id,
        payment_interval: props.salaryConfigForm?.payment_interval,
      },
      ...(props.salaryConfigForm.salary_management_type == 0 && {
        salary_allowances: props.salaryConfigForm.salary_allowances,
        salary_deductibles: props.salaryConfigForm.salary_deductibles,
        salary_calculations: props.salaryConfigForm.salary_calculations?.map(
          (calc) =>
            calc.calculation_id == "3"
              ? {
                  ...calc,
                  overtimeSettings,
                }
              : calc
        ),
        salary_detail: {
          base_salary: props.salaryConfigForm.base_salary,
          currency_id: props.salaryConfigForm.currency_id,
          id: props.salaryConfigForm.salary_detail_id ?? null,
          temporary_employee: Boolean(
            props.salaryConfigForm?.temporary_employee
          ),
          calculate_salary_by:
            props.salaryConfigForm?.calculate_salary_by == "payment_interval"
              ? "full_time"
              : props.salaryConfigForm?.calculate_salary_by,
          rate_per_day: props.salaryConfigForm?.rate_per_day?.toString(),
          apply_hourly_rate_on_every_day:
            !!props.salaryConfigForm?.apply_hourly_rate_on_every_day,
          hourly_rating_setting,
          calculate_all_work_hours:
            props.salaryConfigForm?.calculate_all_work_hours,
          hours_per_paid_leave:
            props.salaryConfigForm?.hours_per_paid_leave?.toString(),
          paid_leave_hourly_rate:
            props.salaryConfigForm?.paid_leave_hourly_rate?.toString(),
          one_hour_penalty: parseFloat(
            props?.salaryConfigForm?.one_hour_penalty
          ),
          one_hour_bonus: parseFloat(props?.salaryConfigForm?.one_hour_bonus),
        },
      }),
      company_salary_config_preset_id:
        props?.salaryConfigForm?.company_salary_config_preset_id ?? null,
      ...(props?.salaryConfigForm?.update == 1 && {
        update_or_create_flag: 1,
        name: props?.companySalaryConfigPresets?.find(
          (preset) =>
            preset.id ==
            props?.salaryConfigForm?.company_salary_config_preset_id
        ).name,
      }),
      ...(props?.salaryConfigForm?.create == 1 && {
        name: props?.salaryConfigForm.name,
        update_or_create_flag: 2,
      }),
    };

    if (!props.salaryConfigFormValidation?.length) {
      if (
        !props.modalActions.prevSalaryType &&
        props.salaryConfigForm.salary_management_type
      ) {
        swal({
          title: t("are you sure"),
          text: t("external_salary_note"),
          icon: "warning",
          className: "swal-warning-style",
          dangerMode: true,
          buttons: true,
          buttons: [t("Cancel"), t("OK")],
        }).then((willDelete) => {
          if (willDelete) {
            props.upsertSalaryConfigAction(user_salary_config_input);
          }
        });
        return;
      }
      props.upsertSalaryConfigAction(user_salary_config_input);
    }
    return;
  };

  const dismissUpsertSalaryConfig = () => {
    setIsSubmitting(false);
    props.dismissUpsertSalaryConfig();
  };
  if (props?.modalActions?.loadingStatus) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={dismissUpsertSalaryConfig}
      modalTitle={t(modalMessage)}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      size="lg"
    >
      {isModalVissible ? (
        <SalaryConfigForm
          formName={formName}
          isFirst={false}
          formSubmitting={isSubmitting}
          formServerValidation={formServerValidation}
          countryId={userCountryId}
          userId={userId}
          office={userOffice}
        />
      ) : null}
    </MainModal>
  );
};
const mapStateToProps = (state) => {
  return {
    salaryConfigFormValidation: state.super.salaryConfigFormValidation,
    salaryConfigForm: state.super.salaryConfigForm,
    serverValidation: state.super.upsertSalaryConfigServerValidation,
    modalActions: state.super.upsertSalaryConfigModalActions,
    companySalaryConfigPresets: state.super.companySalaryConfigPresets,
  };
};

export default connect(mapStateToProps, {
  dismissUpsertSalaryConfig,
  upsertSalaryConfigAction,
})(UpsertSalaryConfigModal);
