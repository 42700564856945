import React, { useEffect } from "react";
import { connect } from "react-redux";
import Constants from "../../Helpers/Constants";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  fetchApplicantsAction,
  onInputResetAction,
  deleteApplicantsAction,
} from "../../Store/Actions";
import { InputForm, RadioboxForm, BSelect } from "form-builder";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import swal from "sweetalert";
import ShowMoreText from "react-show-more-text";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RemoveIconButton } from "../../Components/IconButtonWithTooltip";

const dataTableRef = "applicantsList";

const ApplicantsList = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchApplicantsAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
    return () => {
      props.onInputResetAction(dataTableRef, "job_post_id");
      props.onInputResetAction(dataTableRef, "status");
      props.onInputResetAction(dataTableRef, "name");
      props.onInputResetAction(dataTableRef, "stage_id");
    };
  }, []);

  const handleFilter = () => {
    props.fetchApplicantsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].job_post_id,
    props.data[dataTableRef].status,
    props.data[dataTableRef].name,
    props.data[dataTableRef].stage_id,
  ]);

  // start of handle job post filter and stage filter
  const handleJobPostFilter = () => {
    props.onInputResetAction(dataTableRef, "stage_id");
  };

  const useDidJobPostFilterUpdate = useDidUpdateEffect(handleJobPostFilter, [
    props.data[dataTableRef].job_post_id,
  ]);
  // end of handle job post filter and stage filter

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    console.log("@pagination effect");
    props.fetchApplicantsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  // to delete Applicant
  const handleDeleteApplicant = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteApplicantsAction(row?.applicant_id);
      }
    });
  };

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "applicant_name",
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.VIEW_APPLICANT_PROFILES]}
          allowBP
          altChildren={<>{row?.applicant_name}</>}
          avalibleOnExpire={false}
          altExpireChildren={<>{row?.applicant_name}</>}
        >
          <Link
            to={`/recruitment/applicant-profile/${row?.applicant_id}`}
            className="cursor-pointer"
          >
            {row?.applicant_name}
          </Link>
        </HasPrivileges>
      ),
    },
    {
      name: t("Job Posts"),
      wrap: true,
      selector: "job_posts_names",
      sortable: false,
      omit: !!props.data[dataTableRef].job_post_id,
      grow: 1.5,
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={280}
        >
          {row?.job_posts_names}
        </ShowMoreText>
      ),
    },
    {
      name: t("email"),
      wrap: true,
      selector: "email",
      sortable: false,
      grow: 1,
      style: {
        textTransform: "none",
      },
    },
    {
      name: t("phone"),
      wrap: true,
      selector: "phone_numbers",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={280}
        >
          {row?.phone_numbers}
        </ShowMoreText>
      ),
    },
    {
      name: t("no. of applications"),
      wrap: true,
      center: true,
      selector: "count_of_applications_per_applicant",
      sortable: false,
      grow: 1,
    },
    {
      name: "",
      wrap: true,
      center: true,
      sortable: false,
      grow: 1,
      cell: (row) => (
        <HasPrivileges
          //   reqireMain={[Privilages.ADD_EDIT_DELETE_INTAKE_FORMS]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="cards_table_actions">
              <RemoveIconButton />
            </div>
          }
        >
          <div className="cards_table_actions">
            <RemoveIconButton onClick={() => handleDeleteApplicant(row)} />
          </div>
        </HasPrivileges>
      ),
    },
  ];

  return (
    <div className="applicants_wrapper_style">
      {props?.isDeleteApplicantLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="row my-3 align-items-baseline">
        <div className="col-12 col-lg-4">
          <RadioboxForm
            reducer="recruitment"
            formName={dataTableRef}
            options={Constants.recruitmentProcessStatusFilter?.map(
              ({ label, value }) => ({
                label: t(label),
                value,
              })
            )}
            name="status"
            type="radio"
            containerStyle="my-2 my-lg-0"
            labelStyle="label-style"
            optionsContainerStyle="optionsContainerStyle"
            optionItemStyle="optionItemStyle"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
          />
        </div>

        <div className="col-12 col-lg-8">
          <div className="row align-items-baseline">
            <div
              className={
                props.data[dataTableRef].job_post_id
                  ? "col-12 col-lg-4"
                  : "col-12 col-lg-4 px-lg-0"
              }
            >
              <BSelect
                name="job_post_id"
                optionLabel="title"
                optionValue="id"
                reducer="recruitment"
                options={props?.data?.jobPostsOptions}
                formName={dataTableRef}
                keepDefaultStyle
                containerStyle=""
                isClearable
                placeholder={t("select job post")}
              />
            </div>

            {props.data[dataTableRef].job_post_id ? (
              <div className="col-12 col-lg-4 my-2 my-lg-0 px-lg-0">
                <BSelect
                  name="stage_id"
                  optionLabel="name"
                  optionValue="id"
                  reducer="recruitment"
                  options={props?.data?.stagesOptions}
                  formName={dataTableRef}
                  keepDefaultStyle
                  containerStyle=""
                  isClearable
                  placeholder={t("select status")}
                />
              </div>
            ) : null}

            <div className="col my-2 my-lg-0">
              <InputForm
                reducer="recruitment"
                name="name"
                formName={dataTableRef}
                placeholder={t("search")}
                inputContainerStyle=" "
                containerStyle=" "
              />
            </div>
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={props.data[dataTableRef].data}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="recruitment"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data[dataTableRef].isLoading}
        progressComponent={<Loader />}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state?.recruitment,
    isDeleteApplicantLoading: state?.recruitment?.deleteApplicantLoading,
  };
};

export default connect(mapStateToProps, {
  fetchApplicantsAction,
  onInputResetAction,
  deleteApplicantsAction,
})(ApplicantsList);
