import React from "react";
import { connect } from "react-redux";
import { InputForm, BSelect, RadioboxForm } from "form-builder";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";
import {
  childInputChange,
  addViolationAction,
  removeViolationAction,
} from "../../Store/Actions";

import { useDispatch } from "react-redux";
import { decrementNumberAtEnd } from "../../Helpers/HelperFns";
import { tardinessViolations } from "../../Constants";

const AttViolationSection = (props) => {
  const { formProps, childName, penalty_type } = props;
  const tardinessType = props?.parent
    ? props.data[formProps.formName][`tardiness_type_${props?.parent}`]
    : "time_interval";

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleViolation = (value, index) => {
    if (value >= 0) {
      dispatch(
        childInputChange(
          [formProps?.formName],
          childName,
          "value",
          index,
          `${value}`
        )
      );
    }
    return;
  };

  const handleChangeDeductMinutesAs = (value, index) => {
    dispatch(
      childInputChange(
        formProps?.formName,
        childName,
        "deduct_minutes_by",
        index,
        `${value}`
      )
    );

    // make it the last fixed tier (change all of the next tiers to be fixed as well)
    if (value !== "minutes") {
      const indexOfTierThatChanged = tardinessViolations.indexOf(childName);
      for (
        let i = indexOfTierThatChanged + 1;
        i < tardinessViolations.length;
        i++
      ) {
        dispatch(
          childInputChange(
            formProps?.formName,
            tardinessViolations[i], // child name
            "deduct_minutes_by",
            index,
            `${value}`
          )
        );
      }
    }
  };

  const handleAddViolation = () => {
    dispatch(
      addViolationAction([formProps?.formName], childName, penalty_type)
    );
  };

  const handleRemoveViolation = (i) => {
    dispatch(removeViolationAction([formProps?.formName], childName, i));
  };

  // in case of minutes the options deduct as is disabled if the previous tier is not minutes (the last tier)
  const isOptionsDisabled =
    childName != "minor_violations" &&
    props?.data[formProps.formName][
      childName == "major_violations"
        ? "minor_violations"
        : childName == "major_violations1"
        ? "major_violations"
        : decrementNumberAtEnd(childName)
    ]?.[0]?.deduct_minutes_by != "minutes";

  return (
    <div>
      <div className="add_violations_wrapper_style">
        {tardinessType === "time_interval" ? (
          <div className="d-flex align-items-baseline mt-1">
            <div className="mr-3 mb-0">
              <h5>
                <b>{t("Violations")} </b>
              </h5>
            </div>

            <div className="">
              <button
                className="btn add_new_btn_style"
                type="button"
                onClick={handleAddViolation}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>
        ) : null}

        {props?.data[formProps.formName][childName]?.map((violation, i) => {
          // only view one violation in case of minutes
          if (tardinessType && tardinessType === "minutes" && i) return <></>;
          return (
            <>
              {tardinessType === "minutes" ? (
                props?.parent == "sign_in" ? (
                  <div>
                    <RadioboxForm
                      {...formProps}
                      containerStyle="custom-tardiness-container justify-content-start"
                      label="deduct by"
                      name="deduct_minutes_by"
                      options={[
                        {
                          label: "multiplying late minutes",
                          value: "minutes",
                          optProps: {
                            disabled: isOptionsDisabled,
                          },
                        },
                        {
                          label: "setting fixed amount",
                          value: "fixed",
                          optProps: {
                            disabled: isOptionsDisabled,
                          },
                        },
                      ]}
                      onChange={(e) =>
                        handleChangeDeductMinutesAs(e.target.value, i)
                      }
                      value={violation?.deduct_minutes_by}
                      validateBy="textRequired"
                      optionsContainerStyle="d-flex justify-content-end "
                      optionItemStyle="option-item"
                      optionInputStyle=" "
                      optionLabelStyle="optionLabelStyle m-0"
                    />
                    <InputForm
                      {...formProps}
                      validateBy="textRequired"
                      validationName={`input.${childName}${i}.value`}
                      rootStyle="flex-grow-1"
                      label={
                        violation?.deduct_minutes_by == "minutes"
                          ? `Multiply ${
                              penalty_type.includes("early") ? "Early" : "Late"
                            } minutes by`
                          : "fixed amount"
                      }
                      name="value"
                      type="number"
                      stepInput
                      childInput
                      onChange={(e) => handleViolation(e.target.value, i)}
                      value={violation.value}
                      childInputIncrement={() =>
                        handleViolation(+violation.value + 1, i)
                      }
                      childInputDecrement={() =>
                        handleViolation(+violation.value - 1, i)
                      }
                      inputContainerStyle="m-start-auto"
                    />
                  </div>
                ) : (
                  <InputForm
                    {...formProps}
                    validateBy="textRequired"
                    validationName={`input.${childName}${i}.value`}
                    rootStyle="flex-grow-1"
                    // rootStyle=""
                    label={`Multiply ${
                      penalty_type.includes("early") ? "Early" : "Late"
                    } minutes by`}
                    name="value"
                    type="number"
                    stepInput
                    childInput
                    onChange={(e) => handleViolation(e.target.value, i)}
                    value={violation.value}
                    childInputIncrement={() =>
                      handleViolation(+violation.value + 1, i)
                    }
                    childInputDecrement={() =>
                      handleViolation(+violation.value - 1, i)
                    }
                    inputContainerStyle="m-start-auto"
                  />
                )
              ) : (
                <div className="d-flex align-items-center justify-content-betnween">
                  <InputForm
                    {...formProps}
                    validateBy="textRequired"
                    validationName={`input.${childName}${i}.value`}
                    rootStyle="flex-grow-1"
                    // rootStyle=""
                    label={`${
                      i + 1 == 1
                        ? "1st"
                        : i + 1 == 2
                        ? "2nd"
                        : i + 1 == 3
                        ? "3rd"
                        : `${i + 1}th`
                    } ${t("Violation")}`}
                    name="value"
                    type="number"
                    stepInput
                    childInput
                    onChange={(e) => handleViolation(e.target.value, i)}
                    value={violation.value}
                    childInputIncrement={() =>
                      handleViolation(+violation.value + 1, i)
                    }
                    childInputDecrement={() =>
                      handleViolation(+violation.value - 1, i)
                    }
                    inputContainerStyle="m-start-auto"
                  />
                  {/* <span className="m-l-16">{t("days")}</span> */}
                  {props.data[formProps.formName][childName]?.length > 1 && (
                    <div className="text-intial ml-2">
                      <button
                        className="btn delete_btn_style"
                        type="button"
                        onClick={() => handleRemoveViolation(i)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
  };
};

export default connect(mapStateToProps)(AttViolationSection);
