import React from "react";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { showToast } from "../../Helpers/HelperFns";
import { wizardAttProfileQuery } from "../../Graphql/query";

import Loader from "../../Components/Loader";
import AttendanceProfileForm from "../AttendanceProfileForm";

const SetupWizardAttendanceProfile = () => {
  const { t } = useTranslation();

  // Local State
  const [isLoading, setIsLoading] = React.useState(true);
  const [profileId, setProfileId] = React.useState(null);

  // Reducer State
  const isAttProfileLoading = useSelector(
    (state) => state.super.editAttProfileLoading
  );

  // Server State
  const [getAttProfile] = useLazyQuery(wizardAttProfileQuery);

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    getAttProfile({
      onCompleted: ({ attendanceProfiles }) => {
        attendanceProfiles?.data?.length &&
          setProfileId(attendanceProfiles?.data[0]?.id);
        setIsLoading(false);
      },
      onError: (err) => {
        setIsLoading(false);
        showToast("error", err?.message);
      },
    });
  }, []);

  return (
    <section className="setup-wizard-step">
      <h2 className="setup-wizard-title mb-4">
        {t("create your attendance profile")}
      </h2>

      {isLoading ? (
        <Loader fixed />
      ) : (
        <>
          {isAttProfileLoading ? <Loader fixed /> : null}
          <AttendanceProfileForm isSetupWizard wizardProfileId={profileId} />
        </>
      )}
    </section>
  );
};

export default SetupWizardAttendanceProfile;
