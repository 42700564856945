import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BSelect } from "form-builder";
import {
  fetchEmployeesTurnoverAttempt,
  onFormResetAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Chart from "chart.js";
import Skeleton from "@mui/material/Skeleton";
import HelperFns from "../../Helpers/HelperFns";

// Icons
import BusinessIcon from "@mui/icons-material/Business";
import LocationCityIcon from "@mui/icons-material/LocationCity";

let Line;
const EmployeesTurnover = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      props.onFormResetAction("employeesTurnOverFilter");
    };
  }, []);
  useEffect(() => {
    props.fetchEmployeesTurnoverAttempt({
      office_id: props.employeesTurnOverFilter.office_id,
      department_id: props?.employeesTurnOverFilter?.department_id,
    });
  }, [
    props.employeesTurnOverFilter.office_id,
    props?.employeesTurnOverFilter?.department_id,
  ]);

  useEffect(() => {
    if (!props.employeesTurnOverLoading && !props.dashboardViewLoading) {
      var ctx = document
        .getElementById("employeesTurnoverChart")
        .getContext("2d");
      if (typeof Line !== "undefined") Line.destroy();
      ctx.height = 500;
      // intiliazing Line Chart and its options
      Line = new Chart(ctx, {
        type: "line",
        PositionType: "chartArea",
        data: {
          labels: props?.EmployeesTurnOverChartData?.map(
            (element) => element?.year
          ),
          datasets: [
            {
              label: HelperFns.localizeFns("percentage %"),
              data: props?.EmployeesTurnOverChartData?.map((element) =>
                Number(element?.turnoverPercentage)?.toFixed(2)
              ),
              borderColor: "#dc3545",
              backgroundColor: "#dc3545",
              fill: false,
            },
          ],
        },
        aspectRatio: 1,
        options: {
          tooltips: {
            callbacks: {
              title: function (context) {
                return `${t("turnover")} : ${Number(
                  props.EmployeesTurnOverChartData[context[0]?.index]
                    ?.turnoverPercentage
                ).toFixed(2)} %`;
              },
              label: function () {
                return null;
              },
              beforeBody: function (context) {
                // rows of text should be as elements in an array becasue the tooltip doesn't support line breaking - mohamed ali
                const contentArray = [
                  `${t("total")} : ${
                    props.EmployeesTurnOverChartData[context[0]?.index]
                      ?.totalNoOfEmployees
                  }`,
                  `${t("hired")} : ${
                    props.EmployeesTurnOverChartData[context[0]?.index]
                      ?.currentlyHiredEmployees
                  }`,
                  `${t("suspended")} : ${
                    props.EmployeesTurnOverChartData[context[0]?.index]
                      ?.suspendedEmployees
                  }`,
                ];

                return contentArray;
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "#5c6974",
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "#313030",
                },
                gridLines: {
                  display: true,
                },
                scaleLabel: {
                  display: true,
                  labelString: t("percentage %"),
                },
              },
            ],
          },
        },
      });
    }
  }, [props.employeesTurnOverLoading, props.dashboardViewLoading]);
  var arr = [2, 1, 4, 2, 4, 6, 5, 6, 2, 3, 5, 6, 6, 2];
  if (props.dashboardViewLoading) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="employees-turnover-outer-skeleton d-flex flex-row justify-content-evenly align-items-end">
            {arr?.map((el) => (
              <Skeleton
                className="mr-5"
                variant=""
                width="3.5%"
                height={el * 50}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="employee-turnover-container card">
      <div className="card-body">
        <div className="chart-title w-100 d-flex flex-column flex-md-row  flex-lg-column flex-xl-row justify-content-between align-items-start align-items-md-center align-items-lg-start align-items-xl-center">
          <h4>{t("employees turnover")}</h4>
          <div className="gap-10 d-flex flex-row justify-content-center align-items-center">
            <BSelect
              name="office_id"
              optionLabel="name"
              optionValue="id"
              options={[
                { name: t("all offices"), id: null },
                ...props?.companyTurnoverOffices,
              ]}
              formName="employeesTurnOverFilter"
              keepDefaultStyle
              rootStyle="w-100"
              containerStyle="turnover-filter-style"
              icon="office"
            />
            <BSelect
              name="department_id"
              optionLabel="name"
              optionValue="id"
              options={[
                { name: t("all departments"), id: null },
                ...props?.companyDepartments,
              ]}
              formName="employeesTurnOverFilter"
              keepDefaultStyle
              rootStyle="w-100"
              containerStyle="turnover-filter-style"
              icon="department"
            />
          </div>
        </div>
        <div className="overflow-auto">
          {props.employeesTurnOverLoading ? (
            <div className="inner-skeleton d-flex flex-row justify-content-evenly align-items-end">
              {arr?.map((el) => (
                <Skeleton
                  className="mr-5"
                  variant=""
                  width="3.5%"
                  height={el * 40}
                />
              ))}
            </div>
          ) : (
            <canvas id="employeesTurnoverChart"></canvas>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    companyDepartments: state?.super?.companyDepartments || [],
    companyTurnoverOffices: state?.super?.companyTurnoverOffices || [],
    employeesTurnOverFilter: state?.super?.employeesTurnOverFilter,
    EmployeesTurnOverChartData: state?.super?.EmployeesTurnOverChartData,
    employeesTurnOverLoading: state?.super?.employeesTurnOverLoading,
    dashboardViewLoading: state?.super?.dashboardViewLoading,
  };
};

export default connect(mapStateToProps, {
  fetchEmployeesTurnoverAttempt,
  onFormResetAction,
})(EmployeesTurnover);
