import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useLazyQuery, useQuery } from "@apollo/client";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import {
  attendanceLogFragment,
  simplePaginatorFragment,
} from "../../Graphql/fragments";
import {
  showErrorToast,
  onFormResetAction,
  adjustSignInOutIds,
} from "../../Store/Actions";
import Const from "../../Constants";
import { gql } from "@apollo/client";
import Privilages from "../../Constants/Privilages";
import { exportLogsQuery } from "../../Graphql/query";
import { adjustSignOutMutation } from "../../Graphql/mutation";
import HelperFns, { openSwalConfirm } from "../../Helpers/HelperFns";

import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import HistoryList from "../../Containers/HistoryList";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import EditPenaltyModal from "../../Components/EditPenaltyModal";
import { ColorButton, ExportButton } from "../../Components/Buttons";
import { DateTimePickerForm, BSelect, CheckboxBooleanForm } from "form-builder";
import EditAttHistoryModal from "../../Components/HistoryEmployeeCard/EditAttHistoryModal";

const dataTableRef = `historyList`;
const lateKeys = ["Minor Tardiness", "Major Tardiness"];
const paginationInitState = {
  perPage: 20,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const GET_HISTORY = gql`
  query getHistory(
    $rows: Int!
    $page: Int
    $to: String
    $from: String
    $emp_id: [ID]
    $status: [String]
  ) {
    attendanceLogs: users_sign_in_outs_by_role(
      first: $rows
      page: $page
      input: {
        emp_id: $emp_id
        status: $status
        page: "history_logs"
        date_range: { from: $from, to: $to }
      }
    ) {
      data {
        ...attendanceLog
        conflict_info
        attendance_configuration_conflict
        __typename
        multiple_sign_ins {
          ...attendanceLog
          __typename
        }
      }
      paginatorInfo {
        ...simplePaginator
      }
    }
  }
  ${attendanceLogFragment}
  ${simplePaginatorFragment}
`;

const HistoryTab = ({ adjustIDs, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId: emp_id } = useParams();
  const status = React.useMemo(
    () =>
      props.data.status?.includes("Late")
        ? [...props.data.status, ...lateKeys]
        : props.data.status,
    [props.data.status.length]
  );

  // Server State
  const { loading: fetchListLoading, refetch: refetchList } = useQuery(
    GET_HISTORY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        emp_id,
        to: props.data.to,
        from: props.data.from,
        rows: props.data.pagination.perPage,
        page: props.data.pagination.currentPage,
        attendance_configuration_conflict:
          props.data.attendance_configuration_conflict,
        status: props.data.missing_sign_out
          ? [...status, "Missing sign out"]
          : status,
      },
      onCompleted: ({ attendanceLogs = {} }) => {
        dispatch(
          onFormResetAction(dataTableRef, {
            ...props.data,
            data: attendanceLogs?.data || [],
            pagination: attendanceLogs?.paginatorInfo || paginationInitState,
          })
        );
      },
      onError: () => dispatch(showErrorToast()),
    }
  );
  const [exportLogs, { loading }] = useLazyQuery(exportLogsQuery, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (
        response?.exportAttendanceHistory &&
        response?.exportAttendanceHistory?.status === "success"
      ) {
        HelperFns.downloadFile(response?.exportAttendanceHistory?.file);
      } else {
        dispatch(showErrorToast(response?.exportAttendanceHistory?.message));
      }
    },
    onError: (error) => {
      dispatch(showErrorToast(error?.message));
    },
  });
  const [adjustSignOut, { loading: adjustSignOutLoading }] = useCustomMutation(
    adjustSignOutMutation,
    {
      isShowErrorModal: true,
      variables: {
        to: props.data.to,
        from: props.data.from,
        filtered_emp_id: emp_id,
        status: props.data.status,
        auto_sign_out_all: adjustIDs.length ? 0 : 1,
        sign_in_out_ids: adjustIDs.length ? adjustIDs : null,
      },
      onCompleted: () => {
        refetchList();
        dispatch(adjustSignInOutIds({ operation: "clear" }));
      },
    }
  );

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    for (let i = 1; i < 11; i++) {
      lateKeys.push(`Major Tardiness${i}`);
    }
    return () => {
      dispatch(onFormResetAction(dataTableRef));
      dispatch(adjustSignInOutIds({ operation: "clear" }));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleExportAttendanceLogs = () => {
    if (loading || !(props.data?.from && props.data?.to)) return;
    exportLogs({
      variables: {
        input: {
          auth_records_history: true,
          filter_data: {
            emp_id,
            page: "history_logs",
            status: props.data?.status,
            date_range: {
              from: props.data?.from,
              to: props.data?.to,
            },
          },
        },
      },
    });
  };

  const handleFilter = () => {
    dispatch(
      onFormResetAction(dataTableRef, {
        ...props.data,
        pagination: { ...props.data.pagination, currentPage: 1 },
      })
    );
  };

  const handlePaginate = (page = props.data.pagination.currentPage) => {
    dispatch(
      onFormResetAction(dataTableRef, {
        ...props.data,
        pagination: { ...props.data.pagination, currentPage: page },
      })
    );
  };

  const handleBulkAdjustSignOut = () => {
    const options = {
      text: adjustIDs.length
        ? "defaut_warning_messsage"
        : "bulk adjust sign out modal",
    };

    openSwalConfirm((isConfirmed) => {
      isConfirmed && adjustSignOut();
    }, options);
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex gap-10">
        <BSelect
          reducer="user"
          name="status"
          label="status"
          isClearable
          formName={dataTableRef}
          options={Const.signInStatusOptions}
          keepDefaultStyle
          getOptionLabel={(option) => t(option.value.toLowerCase())}
          optionValue="value"
          isMulti={true}
          containerStyle="m-0"
          rootStyle="flex-1"
          onInterceptInputOnChange={handleFilter}
        />
        <DateTimePickerForm
          name="from"
          formName={dataTableRef}
          label={t("from")}
          hasIcon
          isClearable
          reducer="user"
          containerStyle=" "
          labelStyle="mb-2"
          rootStyle="flex-1"
          onIntercept={handleFilter}
        />
        <DateTimePickerForm
          name="to"
          formName={dataTableRef}
          label={t("to")}
          hasIcon
          isClearable
          reducer="user"
          containerStyle=" "
          labelStyle="mb-2"
          rootStyle="flex-1"
          onIntercept={handleFilter}
        />
      </div>
      <div className="d-flex align-items-center justify-content-between my-2">
        <div className="d-flex gap-20">
          <CheckboxBooleanForm
            reducer="user"
            formName={dataTableRef}
            options={[t("Only show records with no sign-out time")]}
            name="missing_sign_out"
            labelStyle="checkbox"
          />
          <CheckboxBooleanForm
            reducer="user"
            formName={dataTableRef}
            labelStyle="checkbox"
            options={["Attention Required!"]}
            name="attendance_configuration_conflict"
            setWithValue
          />
        </div>

        <div className="d-flex gap-10">
          {!fetchListLoading &&
          (adjustIDs.length || props.data?.missing_sign_out) &&
          props.data?.data.length > 0 ? (
            <HasPrivileges
              reqireMain={[
                Privilages.EDIT_EMPLOYEE_ATTENDANCE,
                Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
              ]}
              allowBP
              avalibleOnExpire={false}
            >
              <ColorButton
                loadingButton
                color="#ff6a6a"
                variant="outlined"
                label="adjust sign out"
                loading={adjustSignOutLoading}
                onClick={handleBulkAdjustSignOut}
              />
            </HasPrivileges>
          ) : null}

          <ExportButton
            loadingButton
            loading={loading}
            onClick={handleExportAttendanceLogs}
            disabled={!props.data?.from || !props.data?.to}
            disabledLabel={t("Please select a date range to export")}
          />
        </div>
      </div>

      {/* List */}
      {fetchListLoading ? (
        <Loader />
      ) : (
        <>
          <HistoryList isEmpProfileHistory />
          <Pagination
            tableRef={dataTableRef}
            styleWraper="mt-2"
            onPaginate={handlePaginate}
            reducer="user"
          />
          <EditPenaltyModal refetchList={refetchList} />
          <EditAttHistoryModal isEmp refetch={refetchList} />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.user.historyList,
  adjustIDs: state?.super?.adjustSignInOutIds,
});
export default connect(mapStateToProps)(HistoryTab);
