import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RecruitmentProcessForm from "../../Containers/RecruitmentProcessForm";
import { useDispatch, useSelector } from "react-redux";
import { initRecruitmentProcessAction } from "../../Store/Actions";
import { KeyboardBackspace } from "@mui/icons-material";
import { Link } from "react-router-dom";

const RecruitmentProcessPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initRecruitmentProcessAction());
  }, []);
  return (
    <div className="content pt-3">
      <Link to="/recruitment?tab=recruitment-process">
        <div className="back-btn d-flex align-items-center position-sticky mb-3">
          <KeyboardBackspace className="back-icon" />
          <span>{t("back")}</span>
        </div>
      </Link>
      <div className="col-12">
        <h3 className="page-title-bold">{t("new hiring process")}</h3>
      </div>
      <RecruitmentProcessForm />
    </div>
  );
};

export default RecruitmentProcessPage;
