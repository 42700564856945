import React, { useState } from "react";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { forgotPasswordAttempt } from "../../Store/Actions";
import { Spinner } from "reactstrap";
import LoginLayout from "../../Components/LoginLayout";
import { useTranslation } from "react-i18next";

import Switch from "@mui/material/Switch";
import IntlTelInput from "react-intl-tel-input";
// import "../../Assets/sass/phone-input.scss";
import Grid from "@mui/material/Grid";

const ForgotPasswordPage = (props) => {
  const { t } = useTranslation();
  const [userMail, setUserMail] = useState(null);
  const [userPhone, setUserPhone] = useState("");

  const userPhoneRef = React.useRef(null);

  const [validationMsg, setValidationMsg] = useState("");
  const [isPhoneLogin, setIsPhoneLogin] = useState(false);

  const handleSwitchChange = (event) => {
    setIsPhoneLogin(event.target.checked);
    setUserMail(null);
    setUserPhone("");
    setValidationMsg("");
  };

  const handleEmailInputChange = (e) => {
    setValidationMsg("");
    setUserMail(e.target.value);
  };

  const handleSubmitBtn = (e) => {
    e.preventDefault();
    if (isPhoneLogin) {
      if (userPhoneRef?.current?.isValidNumber(userPhone)) {
        let actualPhone =
          userPhoneRef?.current?.selectedCountryData?.dialCode == "20"
            ? userPhone?.replace(/^0/, "")
            : userPhone;

        setValidationMsg("");
        props.forgotPasswordAttempt(
          null,
          actualPhone,
          `+${userPhoneRef?.current?.selectedCountryData?.dialCode}`
        );
        return;
      }
      setValidationMsg(t("validation.phone"));
    } else {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          userMail
        )
      ) {
        setValidationMsg("");
        props.forgotPasswordAttempt(userMail);
        return;
      }
      setValidationMsg(t("validation.email"));

      // props.loginAction(userMail);
    }
  };

  return (
    <LoginLayout>
      <form method="POST" onSubmit={handleSubmitBtn}>
        <div className="flex-row d-flex justify-content-between">
          <h4 className="text-capitalize">{t("forgot password")}</h4>
          <div dir="ltr">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>{t("email")}</Grid>
              <Grid item>
                <Switch
                  checked={isPhoneLogin}
                  onChange={handleSwitchChange}
                  name="isPhoneLogin"
                  classes={{ switchBase: "switchBase" }}
                />
              </Grid>
              <Grid item>{t("phone")}</Grid>
            </Grid>
          </div>
        </div>

        {isPhoneLogin ? (
          <div
            dir="ltr"
            className="d-flex flex-column login_input_wrapper_style"
          >
            <label className="mb-2 validity-label-style">{t("phone")}</label>
            <IntlTelInput
              ref={userPhoneRef}
              inputClassName="input-style-default login-body-wrapper custom_outlined_input_style input"
              style={{ display: "flex" }}
              fieldName={"userPhone"}
              telInputProps={{
                dir: "ltr",
              }}
              preferredCountries={["eg", "ae", "sa"]}
              onPhoneNumberChange={(isValid, value) => {
                setUserPhone(value);
                setValidationMsg("");
              }}
              separateDialCode
              format={false}
              formatOnInit={false}
              value={userPhone}
            />
          </div>
        ) : (
          <div className="d-flex flex-column login_input_wrapper_style">
            <label>{t("email")}</label>
            <OutlinedInput
              type="text"
              name="username"
              onChange={handleEmailInputChange}
              value={userMail}
              placeholder={t("email")}
              classes={{
                root: "custom_outlined_input_style",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton>
                    <EmailOutlined />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        )}

        {validationMsg && (
          <div className="warnig-msg-style mt-2">{validationMsg}</div>
        )}

        <div className="d-flex mt-4 pt-2">
          <button
            className="btn btn-block login_btn_style mt-0"
            onClick={handleSubmitBtn}
            type="submit"
            disabled={props.forgotPasswordLoading}
          >
            {props.forgotPasswordLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                size="sm"
                color="#fff"
              />
            ) : (
              t("submit")
            )}
          </button>
        </div>
      </form>
    </LoginLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    forgotPasswordLoading: state.user.forgotPasswordLoading,
  };
};

export default connect(mapStateToProps, {
  forgotPasswordAttempt,
})(ForgotPasswordPage);
