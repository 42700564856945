import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CheckboxForm from "../../Builder/Form/CheckboxForm";
import Masonry from "react-masonry-component";
import { useTranslation } from "react-i18next";

const formName = "previewRolePrivilagesModal";

const employeesOptions = [
  { label: "all employees", value: "all" },
  { label: "managed employees", value: "managed" },
];

const PreviewRolePrivilegsTreeView = (props) => {
  return (
    <Masonry>
      <OptionsList options={props.parents} isFirst={true} />
    </Masonry>
  );
};

// Recursive component
const OptionsList = ({ options, isFirst }) => {
  const privileges = useSelector((state) => state.super[formName]);

  return (
    <>
      {options?.map((option) => (
        <div className={`${isFirst ? "col-12 col-xl-6" : ""}`}>
          <div
            className={`${isFirst ? "boxContainer py-2 my-2 pl-3 pr-2" : ""}`}
          >
            <ul className={`${isFirst ? "pl-0 mb-0" : "p-l-22"}`}>
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <Checkbox {...option} />
                {option?.flag && privileges?.main?.includes(option?.id) ? (
                  <div className="d-flex flex-wrap align-items-center justify-content-between py-1">
                    <form className="d-flex justify-content-end">
                      {employeesOptions?.map((opt) => (
                        <Radiobox {...opt} {...option} />
                      ))}
                    </form>
                  </div>
                ) : null}
              </div>

              {/* Base Case */}
              {option?.children?.length > 0 &&
                privileges?.main?.includes(option?.id) && (
                  <OptionsList options={option.children} />
                )}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

// checkbox component, completly controlled by parent
const Checkbox = ({ name, id, descendants, flag }) => {
  const { t } = useTranslation();
  const privileges = useSelector((state) => state.super[formName]);
  const handleCheckboxChange = (e) => {
    return;
  };
  return (
    <CheckboxForm
      options={[{ value: id, label: t(name) }]}
      name="main"
      formName={formName}
      type="checkbox"
      containerStyle=" "
      labelStyle="label-style"
      optionsContainerStyle="my-1"
      optionItemStyle=" "
      optionInputStyle=" "
      optionLabelStyle=" "
      optionLabelActiveStyle="optionLabelActiveStyle"
      onChange={handleCheckboxChange}
      disabled={true}
    />
  );
};

// Radiobox component, completly controlled by parent
const Radiobox = (props) => {
  const { t } = useTranslation();
  const handleRadioChange = (e) => {
    return;
  };
  const privileges = useSelector((state) => state.super[formName]);

  return (
    <div className="option-item">
      <label className="radio">
        <input
          type="radio"
          name={props.value}
          value={props.id}
          onChange={handleRadioChange}
          checked={privileges[props.value]?.includes(props.id)}
          disabled={true}
        />
        <span></span>

        <div className="optionLabelStyle">{t(props.label)}</div>
      </label>
    </div>
  );
};

export default PreviewRolePrivilegsTreeView;
