import React from "react";
import { InputForm } from "form-builder";
import { useDispatch } from "react-redux";
import { childInputChange } from "../../Store/Actions";
import { useTranslation } from "react-i18next";

const MedicalSection = (props) => {
  const {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
    deductible,
    index,
  } = props;
  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChildInputChange = (event, childName, index) => {
    dispatch(
      childInputChange(
        formName,
        childName,
        event.target.name,
        index,
        event.target.value
      )
    );
  };

  return (
    <div className="flex-fill order-two">
      <InputForm
        name="value"
        type="number"
        label="medical insurance"
        onWheelCapture={(e) => {
          e.target.blur();
        }}
        placeholder={t("amount")}
        {...FormProps}
        labelStyle="mb-2"
        containerStyle=""
        inputContainerStyle="w-100"
        validateBy="textRequired"
        value={deductible.value}
        onChange={(e) => handleChildInputChange(e, "salary_deductibles", index)}
        validationName={`input.user_salary_config_input.salary_deductibles.${index}.value`}
        icon="money"
      />
    </div>
  );
};

export default MedicalSection;
