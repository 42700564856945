import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useQuery } from "@apollo/client";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import {
  handleAdjustSignOut,
  fetchAllWorkTimingsOptionsAction,
  fetchAllOfficesOptionsAction,
  fetchAllDepartmentsByCompany,
  setAllPositionOptionsAction,
  updateEmployeesLogsFilter,
  fetchAttendanceLogsSuccess,
  fetchAttendanceLogsAction,
  showErrorToast,
  adjustSignInOutIds,
} from "../../Store/Actions";
import Swal from "sweetalert2";
import Const from "../../Constants";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { exportLogsQuery, attendanceLogsQuery } from "../../Graphql/query";

import { Spinner } from "reactstrap";
import { Tooltip } from "@mui/material";
import HistoryList from "../HistoryList";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditPenaltyModal from "../../Components/EditPenaltyModal";
import { DateTimePickerForm, BSelect, CheckboxBooleanForm } from "form-builder";
import {
  faFileExport,
  faUser,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import { components } from "react-select";
import IconButtonWithTooltip from "../../Components/IconButtonWithTooltip";

const dataTableRef = `attendanceLogsList`;
let latkeys = ["Minor Tardiness", "Major Tardiness"];
for (let i = 1; i < 11; i++) {
  latkeys.push(`Major Tardiness${i}`);
}

const AttendanceLogsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State

  const signInAdjustIds = useSelector(state => state?.super?.adjustSignInOutIds)
  const [isActiveEmp, setIsActiveEmp] = React.useState(true);

  // Reducer State
  const allOffices = useSelector((state) => state.super.allOffices);
  const allWorkTimings = useSelector((state) => state.super.allWorkTimings);
  const attendanceLogsList = useSelector((state) => state.super[dataTableRef]);
  const adjustSignOutState = useSelector(
    (state) => state.super.adjustSignOutState
  );
  const logsEmployeesFilter = useSelector(
    (state) => state.super.logsEmployeesFilter
  );
  const allDepartmentsByCompany = useSelector(
    (state) => state.super.allDepartmentsByCompany
  );

  const allPositions = useSelector(
    (state) => state.super.allPositions
  );

  let updatedStatus = attendanceLogsList.status?.includes("Late")
    ? [...attendanceLogsList.status, ...latkeys]
    : [...attendanceLogsList.status];


  // Server State
  const [fetchAttLogs, {
    loading: fetchListLoading,
    data: fetchData,
    refetch: refetchData,
  }] = useLazyQuery(attendanceLogsQuery, {
    onError: () => dispatch(showErrorToast()),
    notifyOnNetworkStatusChange: true,
    variables: {
      rows: attendanceLogsList.pagination.perPage,
      page: 1,
    },
  });

  useEffect(() => {
    refetchData();
  }, [])


  const [exportLogs, { loading }] = useLazyQuery(exportLogsQuery, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (
        response?.exportAttendanceHistory &&
        response?.exportAttendanceHistory?.status === "success"
      ) {
        HelperFns.downloadFile(response?.exportAttendanceHistory?.file);
      } else {
        dispatch(showErrorToast(response?.exportAttendanceHistory?.message));
      }
    },
    onError: (error) => {
      dispatch(showErrorToast(error?.message));
    },
  });

  /* ↓ State Effects ↓ */

  useEffect(() => {
    dispatch(adjustSignInOutIds({ operation: "clear" }))
    return () => {
      dispatch(adjustSignInOutIds({ operation: "clear" }))
    };
  }, [
    attendanceLogsList.to,
    attendanceLogsList.from,
    attendanceLogsList.offices,
    attendanceLogsList.employees,
    attendanceLogsList.departments,
    attendanceLogsList.work_timings,
    attendanceLogsList.missing_sign_out,
    attendanceLogsList.direct_managed,
    attendanceLogsList.pagination.currentPage,
  ]);

  useEffect(() => {
    if (fetchData?.attendanceLogs) {
      dispatch(
        fetchAttendanceLogsSuccess(dataTableRef, fetchData?.attendanceLogs)
      );
    }
    if (fetchData?.employees) {
      dispatch(updateEmployeesLogsFilter(fetchData?.employees?.data));
    }

    if (fetchData?.company_departments) {
      dispatch(
        fetchAllDepartmentsByCompany({
          ref: "allDepartmentsByCompany",
          data: fetchData?.company_departments?.data,
        })
      );
    }

    if (fetchData?.company_offices) {
      dispatch(fetchAllOfficesOptionsAction(fetchData?.company_offices?.data));
    }

    if (fetchData?.company_positions) {
      dispatch(setAllPositionOptionsAction(fetchData?.company_positions?.data));
    }

    if (fetchData?.work_timings) {
      dispatch(fetchAllWorkTimingsOptionsAction(fetchData?.work_timings?.data));
    }
  }, [fetchData]);


  // const useDidFilterUpdate = useDidUpdateEffect(
  //   () => handleFilter(),
  //   [
  //     attendanceLogsList.from,
  //     attendanceLogsList.to,
  //     attendanceLogsList.status,
  //     attendanceLogsList.missing_sign_out,
  //     attendanceLogsList.direct_managed,
  //     attendanceLogsList.employees,
  //     attendanceLogsList.offices,
  //     attendanceLogsList.departments,
  //     attendanceLogsList.work_timings,
  //     attendanceLogsList?.attendance_configuration_conflict,
  //     isActiveEmp
  //   ]
  // );


  const onFilterSubmit = () => {
     handleFilter()
  }

  /* ↓ Helpers ↓ */

  const handleExportAttendanceLogs = () => {
    if (loading || !(attendanceLogsList?.from && attendanceLogsList?.to)) {
      return;
    }
    exportLogs({
      variables: {
        input: {
          filter_data: {
            emp_id: attendanceLogsList?.employees,
            office_id: attendanceLogsList?.offices,
            department_id: attendanceLogsList?.departments,
            work_timing_id: attendanceLogsList?.work_timings,
            status: attendanceLogsList?.status,
            date_range: {
              from: attendanceLogsList?.from,
              to: attendanceLogsList?.to,
            },
            page: "history_logs",
          },
        },
      },
    });
  };

  const handleBulkAdjustSignout = async () => {
    const filterValues = attendanceLogsList;
    const totalRecords = filterValues?.pagination?.total;

    Swal.fire({
      icon: "warning",
      text: signInAdjustIds.length
        ? t("defaut_warning_messsage")
        : t("bulk adjust sign out modal", {
          records: totalRecords,
        }),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        // container: "swal-title",
        icon: "swal-icon",
        denyButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((values) => {
      if (values.isConfirmed) {
        signInAdjustIds.length
          ? dispatch(handleAdjustSignOut(signInAdjustIds))
          : dispatch(handleAdjustSignOut(null, filterValues));
      }
    });
  };

  const handleFilter = () => {
    refetchData({
        empStatus: isActiveEmp ? "Active" : "Suspended",
        rows: attendanceLogsList.pagination.perPage,
        emp_id: attendanceLogsList.employees,
        office_id: attendanceLogsList.offices,
        department_id: attendanceLogsList.departments,
        position_id: attendanceLogsList.positions,
        work_timing_id: attendanceLogsList.work_timings,
        direct_managed: attendanceLogsList.direct_managed,
        status: attendanceLogsList.missing_sign_out
          ? [...updatedStatus, "Missing sign out"]
          : updatedStatus,
        from: attendanceLogsList.from,
        to: attendanceLogsList.to,
        attendance_configuration_conflict: attendanceLogsList?.attendance_configuration_conflict,
        page:1
      })

  };

  return (
    <div className="content ml-0">
      <div style={{
        marginBlock: "7.5px",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))", /* Repeat auto times with equal width (250 to ifr) */
        rowGap: "7.5px",
        columnGap: "25px",
        alignItems: "center"
      }}>


        {/* (Start) History Employee Header */}

        <BSelect
          reducer="super"
          name="offices"
          formName={dataTableRef}
          options={allOffices}
          isLoading={attendanceLogsList.isLoading}
          keepDefaultStyle
          containerStyle="att-history-select"
          optionLabel="name"
          optionValue="id"
          isMulti={true}
          placeholder={t("offices")}
          skipLocalization
          icon="office"
        />

        <BSelect
          reducer="super"
          name="departments"
          formName={dataTableRef}
          options={allDepartmentsByCompany}
          isLoading={attendanceLogsList.isLoading}
          keepDefaultStyle
          containerStyle="att-history-select"
          optionLabel="name"
          optionValue="id"
          isMulti={true}
          placeholder={t("departments")}
          skipLocalization
          icon="department"
        />

        <BSelect
          reducer="super"
          name="positions"
          formName={dataTableRef}
          options={allPositions || []}
          isLoading={attendanceLogsList.isLoading}
          keepDefaultStyle
          containerStyle="att-history-select"
          optionLabel="name"
          optionValue="id"
          isMulti={true}
          placeholder={t("positions")}
          skipLocalization
          icon={"person"}
        />

        <BSelect
          reducer="super"
          name="work_timings"
          formName={dataTableRef}
          options={allWorkTimings}
          isLoading={attendanceLogsList.isLoading}
          keepDefaultStyle
          containerStyle="att-history-select"
          optionLabel="name"
          optionValue="id"
          isMulti={true}
          placeholder={t("work timings")}
          skipLocalization
          icon="calendar"
        />

        <BSelect
          reducer="super"
          name="employees"
          formName={dataTableRef}
          isLoading={attendanceLogsList.isEmpLoading}
          options={attendanceLogsList.isEmpLoading ? [] : logsEmployeesFilter}
          keepDefaultStyle
          containerStyle="att-history-select"
          optionLabel="name"
          optionValue="id"
          isMulti={true}
          placeholder={t("employees")}
          isActive={isActiveEmp}
          onEmpDataToggle={() => setIsActiveEmp(!isActiveEmp)}
          components={{ Control: DataControl }}
        />

        <BSelect
          reducer="super"
          name="status"
          placeholder={t("select status")}
          formName={dataTableRef}
          options={Const.signInStatusOptions}
          keepDefaultStyle
          optionLabel="value"
          optionValue="value"
          containerStyle="att-history-select"
          isMulti={true}
          getOptionLabel={(option) => t(option.value?.toLowerCase())}
          icon="type"
        />


        <CheckboxBooleanForm
          formName={dataTableRef}
          labelStyle="checkbox"
          options={[t("Only show employees I manage directly")]}
          name="direct_managed"
          containerStyle="att-history-missing-checkbox"
          optionLabelStyle="booleanInputLabelStyle missing-checkbox-label"
          setWithValue
        />
        <CheckboxBooleanForm
          formName={dataTableRef}
          options={[t("Only show records with no sign-out time")]}
          name="missing_sign_out"
          type="checkbox"
          labelStyle="checkbox"
          containerStyle="att-history-missing-checkbox"
          optionLabelStyle="booleanInputLabelStyle missing-checkbox-label"
        />
        <CheckboxBooleanForm
          formName={dataTableRef}
          labelStyle="checkbox"
          options={["Attention Required!"]}
          name="attendance_configuration_conflict"
          containerStyle="att-history-missing-checkbox"
          optionLabelStyle="booleanInputLabelStyle missing-checkbox-label"
          setWithValue
        />

        <DateTimePickerForm
          name="from"
          formName={dataTableRef}
          label={t("from")}
          hasIcon
          reducer="super"
          rootStyle="flex-fill"
          datePickerContainer="w-100"
          labelStyle="date_picker_label"
          placeholder={t("select date")}
          isLoading={attendanceLogsList.isLoading}
          allowClear
        />

        <DateTimePickerForm
          name="to"
          formName={dataTableRef}
          label={t("to")}
          hasIcon
          reducer="super"
          rootStyle="flex-fill"
          datePickerContainer="w-100"
          labelStyle="date_picker_label"
          placeholder={t("select date")}
          isLoading={attendanceLogsList.isLoading}
          allowClear
        />

        <div className="d-flex align-items-center w-100">
          <button 
            className="btn add_new_btn_style w-75  flex justify-content-center "
            type="button"
            onClick={onFilterSubmit}
            >
             {t("apply")}
            </button>
          </div>

        <div style={{ gridColumnEnd: -1 }} className="att-history-list-actions text-right">
          {!fetchListLoading &&
            (signInAdjustIds.length || attendanceLogsList?.missing_sign_out) &&
            attendanceLogsList?.data.length > 0 || adjustSignOutState?.isLoading ? (
            <HasPrivileges
              reqireMain={[
                Privilages.EDIT_EMPLOYEE_ATTENDANCE,
                Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
              ]}
              allowBP
              avalibleOnExpire={false}
            >
              <button
                className="adjust_signout_btn"
                onClick={handleBulkAdjustSignout}
              >
                {adjustSignOutState?.isLoading ? (
                  <Spinner
                    style={{
                      width: "1rem",
                      height: "1rem",
                      color: "#ff6a6a",
                    }}
                  />
                ) : (
                  t("adjust sign out")
                )}
              </button>
            </HasPrivileges>
          ) : null}

          <Tooltip
            arrow
            placement="bottom"
            classes={{
              popper:
                attendanceLogsList?.from && attendanceLogsList?.to
                  ? "d-none"
                  : "",
            }}
            title={t("Please select a date range to export")}
          >
            <button
              className={`btn py-0 export-btn text-nowrap
                       ${loading ||
                  !(attendanceLogsList?.from && attendanceLogsList?.to)
                  ? "disabled"
                  : ""
                }`}
              type="button"
              onClick={handleExportAttendanceLogs}
            >
              {loading ? (
                <Spinner
                  style={{ width: "1rem", height: "1rem", color: "#fff" }}
                />
              ) : (
                <>
                  <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                  {t("export")}
                </>
              )}
            </button>
          </Tooltip>
        </div>
      </div>

      {/* (End) History Employee Header */}

      {fetchListLoading ? (
        <Loader />
      ) : (
        <>
          <HistoryList
            reducer="super"
            listRef={dataTableRef}
            refetchList={ () => refetchData({page: attendanceLogsList?.pagination?.currentPage})}
            isListLoading={fetchListLoading}
          />
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            reducer="super"
            onPaginate={(page = attendanceLogsList.pagination.currentPage) => {
              refetchData({ page });
            }}
          />
        </>
      )}

      <EditPenaltyModal refetchList={() => refetchData({page: attendanceLogsList?.pagination?.currentPage})} />
    </div>
  );
};

export default AttendanceLogsList;

const DataControl = ({ children, ...props }) => {
  const { isActive, onEmpDataToggle, isLoading } = props.selectProps;

  const handleClick = (e) => {
    if (!isLoading) {
      onEmpDataToggle();
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  return (
    <components.Control {...props}>
      <span onMouseDown={handleClick}>
        <IconButtonWithTooltip
          onClick={() => undefined}
          label={`show ${isActive ? "suspended" : "active"} employees`}
          buttonProps={{
            disabled: isLoading,
            onKeyDown: ({ keyCode }) => keyCode === 13 && handleClick(),
          }}
          icon={
            isActive ? (
              <FontAwesomeIcon icon={faUser} size="sm" color="#90979e" />
            ) : (
              <FontAwesomeIcon icon={faUserSlash} size="sm" color="#90979e" />
            )
          }
        />
      </span>

      {children}
    </components.Control>
  );
};
