import React from "react";
import EmployeePettyCash from "../../Containers/EmployeePettyCash";

const PettyCashTab = (props) => {
  return (
    <>
      <EmployeePettyCash />
    </>
  );
};

export default PettyCashTab;
