import Types from "./types";

export const editWorkGroupAction = (data) => {
  return {
    type: Types.EDIT_WORK_GROUP,
    payload: data,
  };
};

export const editWorkTeamAction = (name) => {
  return {
    type: Types.EDIT_WORK_TEAM,
    payload: name,
  };
};

export const setScheduleServerValidationAction = (data) => {
  return {
    type: Types.SET_SCHEDULE_SERVER_VALIDATION,
    payload: data,
  };
};

export const editEmployeeWorkScheduleAction = (data) => {
  return {
    type: Types.EDIT_EMPLOYEE_WORK_SCHEDULE,
    payload: data,
  };
};

export const setAttTypeServerValidationAction = (data) => {
  return {
    type: Types.SET_ATT_TYPE_SERVER_VALIDATION,
    payload: data,
  };
};

export const setWorkScheduleServerValidationAction = (data) => {
  return {
    type: Types.SET_WORK_SCHEDULE_SERVER_VALIDATION,
    payload: data,
  };
};
